import React, { useEffect, useState } from "react"
import useForm from "../../validations/useForm"
import validate from "../../validations/FormValidationRule"
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"

import axios from "axios"
import config from "../../config/config"
// import SimpleCircle from "../../components/Loaders/SimpleCircle"
import CustomizedSnackbars from "../../components/Loaders/Snuckbars"
import RegistrationForm from "./RegistrationForm"
import { SocialAppLogin } from "../../components"
import { setToken } from "../../helpers/network"
import { useDispatch } from "react-redux"
import { postRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import Pushnotification from "../../services/Pushnotification"

const getLanguageUrl = config.API_URL + "language"
const usersUrl = config.API_URL + "users/"
const countryCode = config.API_URL + "users/country"

function Registration(props) {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { code } = useParams()
  const [inputfocus, setInputfocus] = useState({})
  const [isLoginSuccess, setIsLoginSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errmsg, setErrMsg] = useState("")
  const [isDisplayName, setIsDisplayName] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState([])
  const [options, setOptions] = useState([])
  const [refCode] = useState(code || "")
  // const [refCode, setRefCode] = useState(code || "")
  const [refError, setRefError] = useState("")
  const [defaultCountryCode, setDefaultCountryCode] = useState("")
  const [isAgreed, setIsAgreed] = useState(false)
  const [isApproval, setIsApproval] = useState(false)
  const [isGdpr, setisGdpr] = useState(true)
  const [isSubmit, setIsSubmit] = useState(false)
  const [termsError, setTermsError] = useState({})
  const dispatch = useDispatch()
  const { values, errors, duplicate, handleChange, handleSubmit } = useForm(
    register,
    validate,
    "register"
  )

  // getting languages
  const getLanguage = async (e) => {
    const response = await axios.get(getLanguageUrl)
    // eslint-disable-next-line array-callback-return
    response.data.responseData.map((item, index) => {
      setOptions((options) => [
        ...options,
        { value: item.language, label: item.language },
      ])
    })
    //console.log("creator page languages", response)
  }

  const handleIsAgreed = (e) => {
    setIsAgreed(!isAgreed)
  }

  const handleSelectChange = (e) => {
    e.length === 0 ? (errors.languages = "Required") : delete errors.languages
    setSelectedOptions(e.map((item, i) => item.value))
  }

  const handleIsDisplayName = (e) => {
    setIsDisplayName(!isDisplayName)
  }

  // const handleRefCodeChange = (e) => {
  //   setRefCode(e.target.value.toUpperCase())
  // }

  useEffect(() => {
    const checkValidRefCode = async () => {
      const response = await axios.get(usersUrl)
      if (response.data.ack !== 1) return setRefError(response?.data?.message)
      setRefError("")
    }
    refCode ? checkValidRefCode() : setRefError("")
  }, [refCode])

  useEffect(() => {
    if (isApproval && isAgreed) return setTermsError({})
    if (!isAgreed)
      setTermsError((prev) => ({
        ...prev,
        isAgreed: "Needs to be checked before Registration",
      }))
    if (!isApproval)
      setTermsError((prev) => ({
        ...prev,
        isApproval: "Needs to be checked before Registration",
      }))

    // console.log(isApproval, isAgreed, termsError)
  }, [isApproval, isAgreed])

  const [country, setCountry] = useState([])

  useEffect(() => {
    const checkCountryCode = async () => {
      const response = await axios.get(countryCode)
      setCountry(response.data.responseData)
      // eslint-disable-next-line array-callback-return
      response?.data?.responseData.map((item) => {
        if (item?.dial_code === "+91") setDefaultCountryCode(item?.dial_code)
      })
    }
    checkCountryCode()
  }, [])

  useEffect(() => getLanguage(), [])
  useEffect(() => window.localStorage.clear(), [])

  async function register() {
    setIsSubmit(true)
    if (refError) return
    if (!isApproval || !isAgreed) return

    // console.log(isApproval, isAgreed, termsError)
    let terms = {
      isTermsAgreed: isAgreed,
    }
    let Lang = []
    let name = values.firstName + " " + values.lastName
    let showName = {
      showName: name,
      isShowName: isDisplayName,
    }
    if (isDisplayName)
      showName = {
        showName: values.userName,
        isShowName: isDisplayName,
      }

    if (selectedOptions.length > 0)
      Lang = {
        defaultLanguage: selectedOptions,
      }

    console.log("No errors, submit callback called!")

    let data = Object.assign(values, terms, showName, Lang, {
      userRole: "2543",
      countryDialCode: values.countryDialCode || defaultCountryCode,
      GDPR: isGdpr,
      loginType: 'normal'
    })
    let req = {
      data : data,
      url: 'signin'
    }
    // getRegisterUrl, data
    // return console.log(data)
    const response = await dispatch(postRequestHandler(req))
    setIsSubmit(false)
    if (response.data.status !== 200) {
      setIsError(true)
      return setErrMsg(response.data.message)
    }
    setToken(response?.data?.responseData?.token)
    localStorage.clear()
    localStorage.setItem("token", response.data.responseData.user.id)
    localStorage.setItem("accessToken", response.data.responseData.token)
    localStorage.setItem(
      "currentuser",
      JSON.stringify(response?.data?.responseData)
    )
    Pushnotification()
    if (!response?.data?.responseData?.user?.isEmailVerified) {
      navigate("/auth/verification/")
    } else {
      const redirect = searchParams.get("redirectTo")
      navigate(redirect || "/creations/")
    }
  }

  return (
    <>
      <div className="Login">
        <div className="container-xl">
          <div className="row align-items-center justify-content-center hxz-100">


            <div className="col-lg-5 order-lg-3 order-md-3 order-1">
              <div className="login_col_main">
                <Link to={"/"} className="main_logo m-hide">
                  <img className="img-responsive" src="/assets/web_img/footer_logo.png" alt="footer logo" />
                </Link>
                <Link to={"/"} className="main_logo m-show">
                  <img className="img-responsive" src="/assets/web_img/footer_logo.png" alt="footer logo" />
                </Link>
                <RegistrationForm
                  redirectTo={searchParams.get("redirectTo")}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  values={values}
                  errors={errors}
                  isSubmit={isSubmit}
                  inputfocus={inputfocus}
                  setInputfocus={setInputfocus}
                  country={country}
                  options={options}
                  selectedOptions={selectedOptions}
                  handleIsDisplayName={handleIsDisplayName}
                  isApproval={isApproval}
                  isError={isError}
                  isAgreed={isAgreed}
                  isGdpr={isGdpr}
                  setIsError={setIsError}
                  setisGdpr={setisGdpr}
                  setIsApproval={setIsApproval}
                  isDisplayName={isDisplayName}
                  termsError={termsError}
                  handleIsAgreed={handleIsAgreed}
                  errmsg={errmsg}
                  refError={refError}
                  handleSelectChange={handleSelectChange}
                  duplicate={duplicate}
                />
                <div className="d-flex align-items-center gap-2 mx-3 mb-3 mt-2">
                  <div className="line"></div>
                  <div>or</div>
                  <div className="line"></div>

                  {/* <Link to="/auth/social/login" className="btn btn-primary">Facebook Login</Link> */}
                </div>
                <SocialAppLogin />
                {/* <SocialLogins /> */}

                {/* error snackbar */}
                {isError && (
                  <CustomizedSnackbars
                    type={1}
                    msg={errmsg}
                    sclose={() => setIsError(false)}
                  />
                )}
                {isLoginSuccess && (
                  <CustomizedSnackbars
                    type={0}
                    msg={"login successfull"}
                    sclose={() => setIsLoginSuccess(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Registration
