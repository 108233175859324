import React, { useEffect, useState } from "react"
import { Card } from "react-bootstrap"
import axios from "axios"
import { config } from "../../constant"
// import CreateUser from "../../Controllers/Auth.controller"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { postRequestHandler } from "../../redux/reducers/requestHandler.reducer"
const USER_URL = config.url.API_URL + "users/"
const countryCode = config.url.API_URL + "users/country"

function SocialUserSignUp(props) {

    // const [data, setData] = useState({})
    // const [rawUserData, setRawUserData] = useState({})
    // const [isNewUser, setIsNewUser] = useState(false)
    const [userValues, setUserValues] = useState({})
    const [inputfocus, setInputfocus] = useState({})
    const [errors, setErrors] = useState({})
    const [backendError, setBackendError] = useState("")
    const [valid, setValid] = useState({})
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const dispatch = useDispatch()
    // const {photoUrl, firstName, lastName, email} = props

    const handleChange = async (e) => {
        if (e.target.name === "userName") {
            const k = e.target.value.toLowerCase()
            const l = k.replace(" ", ".")
            setUserValues((prev) => ({ ...prev, [e.target.name]: l }))

            const response = await axios.get(
                USER_URL + `getusers?userName=${l}&mode=0`
            )
            response.data.ack === 1
                ? setErrors((prev) => ({
                    ...prev,
                    [e.target.name]:
                        "username already exist. please continue with new one.",
                }))
                : setErrors((prev) => ({ ...prev, [e.target.name]: "" }))

            response.data.ack === 0
                ? setValid((prev) => ({
                    ...prev,
                    [e.target.name]: "valid: you can use this username",
                }))
                : setValid((prev) => ({ ...prev, [e.target.name]: "" }))
        } else if (e.target.name === "email") {
            setUserValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
            const response = await axios.get(
                USER_URL + `getusers?email=${e.target.value}&mode=0`
            )
            response.data.ack === 1
                ? setErrors((prev) => ({
                    ...prev,
                    [e.target.name]:
                        "email already exist. please continue with new one.",
                }))
                : setErrors((prev) => ({ ...prev, [e.target.name]: "" }))

            response.data.ack === 0
                ? setValid((prev) => ({
                    ...prev,
                    [e.target.name]: "valid: you can use this email",
                }))
                : setValid((prev) => ({ ...prev, [e.target.name]: "" }))
        } else if (e.target.name === "phone") {
            if (isNaN(e.target.value)) return false
            setUserValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
            const response = await axios.get(
                USER_URL + `getusers?phone=${e.target.value}&mode=0`
            )
            response.data.ack === 1
                ? setErrors((prev) => ({
                    ...prev,
                    [e.target.name]:
                        "phone already exist. please continue with new one.",
                }))
                : setErrors((prev) => ({ ...prev, [e.target.name]: "" }))

            response.data.ack === 0
                ? setValid((prev) => ({
                    ...prev,
                    [e.target.name]: "valid: you can use this phone",
                }))
                : setValid((prev) => ({ ...prev, [e.target.name]: "" }))
        } else {
            setUserValues((prev) => ({ ...prev, [e.target.name]: e.target.value }))
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const redirectUser = (user) => {
        localStorage.clear()
        localStorage.setItem("token", user.user.id)
        localStorage.setItem("accessToken", user.token)
        localStorage.setItem("currentuser", JSON.stringify(user))
        if (!user?.user?.isEmailVerified) {
            navigate("/auth/verification/")
        } else {
            const redirect = searchParams.get("redirectTo")
            navigate(redirect || "/creations/")
        }
    }


    const createSocialNewUser = async (e) => {
        e.preventDefault()
        // if (errors.userName) return
        const values = {
            ...userValues,
            userID: props?.rawUserData,
            loginType: props?.provider,
            firstName: props?.firstName,
            lastName: props?.lastName,
            showName: userValues?.userName || props?.showName,
            email: props?.email,
            profilePhoto: props?.photoUrl,
            isEmailVerified: true
        }

        let req = {
            data: values,
            url: 'signin'
        }
        const response = await dispatch(postRequestHandler(req))
        if (response.data.ack === 0) return setBackendError(response.data.message)
        if (response.data.ack === 1)
            return redirectUser(response.data.responseData)

        // console.log(values)
    }

    const [country, setCountry] = useState([])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        const checkCountryCode = async () => {
            const response = await axios.get(countryCode)
            setCountry(response.data.responseData)
        }
        checkCountryCode()
    }, [])

    return (
        <Card className="py-2">
            <div className="d-flex gap-2">
                <div className="fb_profile">
                    <img src={props?.photoUrl} alt="social profile" />
                </div>
                <div>
                    <div className="fb_pro_name">{props?.firstName + ' ' + props?.lastName}</div>
                    <div className="email cl_s">{props?.email}</div>
                </div>
            </div>
            <div className="mt-3 line"></div>

            <form
                onSubmit={createSocialNewUser}
                className="mt-1 form-group position-relative has-icon-left mb-2"
            >
                <label htmlFor="" className="ll_2">
                    User Name <span className="req">*</span>
                </label>

                <input
                    type="text"
                    className="form-control form-control-xl"
                    autoComplete="off"
                    placeholder="Please get an username to continue"
                    name="userName"
                    onFocus={(e) =>
                        setInputfocus((inputfocus) => ({
                            ...inputfocus,
                            [e.target.name]: true,
                        }))
                    }
                    onBlur={(e) =>
                        setInputfocus((inputfocus) => ({
                            ...inputfocus,
                            [e.target.name]: false,
                        }))
                    }
                    onChange={handleChange}
                    value={userValues.userName || ""}
                    required
                />
                <div className="valid">
                    {!inputfocus.userName && valid.userName}
                </div>

                <div className="errors">
                    {!inputfocus.userName && errors.userName}
                </div>


                <div className="form-group position-relative has-icon-left mt-2">
                    <label htmlFor="" className="ll_2">
                        Phone <span className="req">*</span>
                    </label>
                    <select
                        name="countryDialCode"
                        className="form-control"
                        onFocus={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: true,
                            }))
                        }
                        onBlur={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: false,
                            }))
                        }
                        placeholder="select country code"
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select country code</option>
                        {country.map((item) => {
                            return (
                                <option value={item?.dial_code}>
                                    {item?.name + "/" + item?.dial_code}
                                </option>
                            )
                        })}
                    </select>

                    <input
                        type="tel"
                        className="form-control form-control-xl mt-1"
                        autoComplete="off"
                        placeholder="Enter phone no. here"
                        name="phone"
                        onFocus={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: true,
                            }))
                        }
                        onBlur={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: false,
                            }))
                        }
                        onChange={handleChange}
                        value={userValues.phone || ""}
                        required
                    />
                    <div className="valid">
                        {!inputfocus.phone && valid.phone}
                    </div>
                    <div className="errors">
                        {!inputfocus.phone && errors.phone}
                    </div>
                </div>

                {backendError && (
                    <div className="errors my-2">{backendError}</div>
                )}

                <button className="btn btn-primary">
                    Register as {props?.showName}
                </button>
                {/* <div className="errors">{inputfocus.userName && duplicate.userName}</div> */}
            </form>
        </Card>
    )
}

export default SocialUserSignUp