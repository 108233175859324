import React, { useState, useEffect } from 'react'
import axios from "axios"
import { config } from '../../constant'
import { useNavigate } from 'react-router-dom'
import { SubmitButton } from '../../components/AppComponents/Helpers'
import { AccountsLogo } from '../FindAccount'
import { CustomizedSnackbars } from '../../components'

const updateUserUrl = config.url.API_URL + 'users/passwordset/'

function AddNewPassword() {
    const [values, setValues] = useState({})
    const [inputfocus, setInputfocus] = useState({})
    const [errmsg, setErrMsg] = useState({})
    const [passMatch, setPassMatch] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const token = typeof window !== "undefined" ? localStorage.getItem('resetuser') : ""
    const errors1 = {}
    const navigate = useNavigate()
    const [msg, setMsg] = useState('')

    const passwordValidation = (value, name) => {
        if (!value) return errors1[name] = 'Password is required'
        if (value.length < 8) return errors1[name] = 'Password must be 8 or more characters'
        if (!/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/.test(value))
            return errors1[name] = 'Password must be consist of 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special charecter'
        return delete errors1[name]
    }

    const handleChange = e => {
        e.persist()
        setValues(values => ({ ...values, [e.target.name]: e.target.value }))
        setErrMsg(errmsg => ({ ...errmsg, [e.target.name]: passwordValidation(e.target.value, e.target.name) }))
    }

    const handleMatch = () => {
        values.newPassword === values.confirmPassword ?
            setPassMatch(true)
            : setPassMatch(false)

    }


    const handleSubmit = async e => {
        try {
            e.preventDefault()
            if (!passMatch) return
            console.log(errors1.length)
            if (Object.keys(errors1).length > 0) return setIsSubmit(false)

            setIsSubmit(true)
            const data = {
                newPassword: values.newPassword,
                confirmPassword: values.confirmPassword,
            }

            const response = await axios.patch(updateUserUrl + token, data)
            setIsSubmit(false)
            if (response.data.responseData) {
                return setMsg('Your password changed succesfully. Redirecting to login...')
            }
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (!msg) return
        setTimeout(() => {
            setMsg("")
            navigate("/auth/login/")
        }, [3000])
    }, [msg, navigate])

    return (
        <div id="basic" className="accounts">
            <div className="flex_center_box">
                <div className="box p-4">
                    <AccountsLogo />
                    <h4 className='mt-2'>Add new password</h4>


                    <form onSubmit={handleSubmit} className="login-form" >

                        {/* last Name  */}
                        <div className='field'>

                            <input type="password"
                                autoComplete="off"
                                placeholder="Enter New Password here"
                                name="newPassword"
                                onFocus={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: true }))}
                                onBlur={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: false }))}
                                onChange={handleChange}
                                value={values.newPassword || ''}
                                onKeyUp={handleMatch}
                                required
                            />
                            <label htmlFor="new password">
                                New Password
                            </label>

                            <div className="errors">{!inputfocus.newPassword && errmsg.newPassword}</div>

                        </div>

                        {/* password  */}
                        <div className="field">
                            <div className='w-100'>

                                <input type="password"
                                    autoComplete="off"
                                    placeholder="Enter Confirm password"
                                    name="confirmPassword"
                                    onFocus={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: true }))}
                                    onBlur={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: false }))}
                                    onChange={handleChange}
                                    onKeyUp={handleMatch}
                                    value={values.confirmPassword || ''}
                                    required
                                />
                                <label htmlFor="confirm password">
                                    Confirm Password
                                </label>
                                {passMatch && <div className='text-success'>Matched</div>

                                }
                                {!passMatch && <div className="errors">Not Matched</div>}

                                <div className="errors">{!inputfocus.confirmPassword && errmsg.confirmPassword}</div>
                            </div>
                        </div>

                        <div className="save_btn mt-4 d-flex gap-2">
                            {/* <button className='btn btn-primary' type='submit'>Update Password</button> */}
                            <SubmitButton title={"Update password"} css={"btn btn-primary"} issubmit={isSubmit} />
                            {/* <button className='btn btn-primary' type='submit' onClick={()=>console.log('clikc')}>Save</button> */}
                        </div>

                        {/* {errmsg && <div className='mt-3'>{errmsg}</div>} */}

                    </form>
                </div>
            </div>
            {msg && <CustomizedSnackbars type={0} msg={msg} />}

        </div>
    )
}

export default AddNewPassword