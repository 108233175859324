import React from 'react'
import { orgMembersAction } from '../../redux/reducers/organizationReducer'
import { useDispatch } from 'react-redux'

function OrgMembers({ orgDetails, activeMembers,getMembers }) {
    const currentUserId = typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const dispatch = useDispatch()

    const membersAction = async (mid, request) => {
        try {

            const params = {
                body: {
                    "orgid": orgDetails?._id,
                    "memberId": mid,
                    "ownerId": currentUserId,
                    "permission": request
                },

                query: {
                    orgid: orgDetails?._id,
                    ownerid: currentUserId,
                    statustype: 'all'
                }
            }

            await dispatch(orgMembersAction(params))
            // await getMembers()

        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <div className="row">
                {activeMembers?.length > 0 && activeMembers?.map((items, index) => {
                    return (<>
                        <div className="col-lg-2 col-md-3 col-12 kk mt-3" key={"index"}>
                            <div className="follow_m_box">

                                <img height="200px" width="100%" src="/assets/web_img/choto_logo_1.png" alt="none" />

                                <div className="text-center py-2">
                                    <a href={`/${items?.user_info[0]?.userName}`} >{items?.user_info[0]?.showName}</a>
                                </div>
                                <div className='px-2 pb-2'>
                                    {currentUserId !== orgDetails?.userid && <a href={`/${items?.user_info[0]?.userName}`} className='btn btn-primary w-100 mb-2'>View Profile</a>}
                                    {currentUserId === orgDetails?.userid &&
                                        <>
                                            <button
                                                type='button'
                                                onClick={() => membersAction(items?.user_info[0]?._id, 'blocked')}
                                                className='mt-2 w-100 btn btn-warning'
                                            >Block
                                            </button>
                                            <button
                                                type='button'
                                                onClick={() => membersAction(items?.user_info[0]?._id, 'deleted')}
                                                className='mt-2 w-100 btn btn-danger'
                                            >Remove</button>
                                        </>
                                    }

                                </div>
                            </div>
                        </div>
                    </>)
                })
                }

            </div>
        </>

    )
}

export default OrgMembers