import React, { useState } from "react"
import {
  FacebookAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
  // signOut,
} from "firebase/auth"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect } from "react"
import { auth } from "../Auth/FireBase"
import SocialUserSignUp from "../Auth/SocialUserSignUp"
import { useDispatch } from "react-redux"
import { postRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import Pushnotification from "../../services/Pushnotification"

function SocialAppLogin() {
  // const auth = getAuth()
  const [rawUserData, setRawUserData] = useState('')
  const navigate = useNavigate()
  const [userData, setUserData] = useState({})
  const [userEmail, setuserEmail] = useState('')
  const [provider, setProvider] = useState("")
  const [searchParams] = useSearchParams()
  const [isRegistrationRequired, setIsRegistrationRequired] = useState(false)
  const dispatch = useDispatch()
  const googleSignIn = () => {
    const provider = new GoogleAuthProvider()
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("firebase", result)
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result)
        const token = credential.accessToken
        // The signed-in user info.
        const user = result.user
        const tokenResponse = result?._tokenResponse
        // console.log("firebase google user", user, token, tokenResponse)
        setProvider("google")
        setuserEmail(user?.email || tokenResponse?.email || user?.providerData[0]?.email)
        setUserData(result?._tokenResponse)
        // console.log("Fdfxdf", user.providerData)
        // console.log("Fdfxdffdfd", user?.providerData[0]?.uid)
        setRawUserData(user?.providerData?.length > 0 && user?.providerData[0]?.uid)

        // ...
      })
      .catch((error) => {
        console.log("firebase error", error)
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.customData.email
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error)

        console.error(errorCode, errorMessage, email, credential)
      })
  }

  const FacebookSignIn = () => {
    const provider = new FacebookAuthProvider()
    provider.addScope('email');
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result)
        // The signed-in user info.
        const user = result.user
        const tokenResponse = result?._tokenResponse
        console.log("firebase google user", user, tokenResponse)
        setProvider("facebook")
        setUserData(result?._tokenResponse)
        setuserEmail(tokenResponse?.email || user?.email || user?.providerData[0]?.email)

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result)
        const accessToken = credential.accessToken
        // console.log("Fdfxdf", user.providerData)
        // console.log("Fdfxdffdfd", user?.providerData[0]?.uid)
        setRawUserData(user?.providerData?.length > 0 && user?.providerData[0]?.uid)
        console.log(accessToken)
      })
      .catch((error) => {

        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.customData.email
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error)
        console.error(errorCode, errorMessage, email, credential)
      })
  }

  const redirectUser = (user) => {
    localStorage.clear()
    localStorage.setItem("token", user.user.id)
    localStorage.setItem("accessToken", user.token)
    localStorage.setItem("currentuser", JSON.stringify(user))
    Pushnotification()
    if (!user?.user?.isEmailVerified) {
      navigate("/auth/verification/")
    } else {
      const redirect = searchParams.get("redirectTo")
      navigate(redirect || "/creations/")
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!provider || !userEmail || !rawUserData) return console.log('userData')
    let data = {
      email: userEmail,
      loginType: provider,
      userID: rawUserData
    }
    const response = await dispatch(postRequestHandler({
      url: 'login',
      action: 'ACTION_AUTH_USER',
      data: data
    }))

    if (response.data.ack === 1)
      return redirectUser(response.data.responseData)
    if (response.data.ack === 0) setIsRegistrationRequired(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, provider, rawUserData, userEmail])

  return (
    <div className="container">
      <div className="row">
        <div className="social_login_btn_sec">
          {!isRegistrationRequired && (
            <>
              <button
                title="Note that logging in through other accounts may take time. Please be patient."
                className="btn btn-primary kep-login-facebook button"
                onClick={FacebookSignIn}
              >
                <i className="fa fa-facebook" aria-hidden="true"></i>
                Sign in with Facebook
              </button>
              <button
                title="Note that logging in through other accounts may take time. Please be patient."
                className="btn btn-primary kep-login-facebook button g_login"
                onClick={googleSignIn}
              >
                <i className="fa fa-google" aria-hidden="true"></i>
                Sign in with Google
              </button>
            </>
          )}
          {/* <button className='btn btn-primary' onClick={FacebookSignIn}>sign in with Facebook</button> */}
          {isRegistrationRequired && (
            <SocialUserSignUp
              userid={userData?.localId}
              firstName={userData?.firstName || ""}
              lastName={userData?.lastName || ""}
              showName={userData?.displayName}
              photoUrl={userData?.photoUrl}
              provider={provider}
              email={userEmail}
              rawUserData={rawUserData}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SocialAppLogin
