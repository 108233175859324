import OneSignal from "react-onesignal";
import { postReqHandlerWithOutDispatch } from "../redux/reducers/requestHandler.reducer";
export default async function Pushnotification(props) {
  await OneSignal.init({
    // appId: "40dedf3f-98a9-4ab1-b65f-55a094da6377",
    // appId: "6720d221-10b7-4b0f-9880-b7b5d69f2c87",
    appId: "d4eb0eff-d0cd-4dd5-b5c8-999801b2271d", //prod
    allowLocalhostAsSecureOrigin: true,
  })
  // OneSignal.showSlidedownPrompt().then(() => {
  //   // alert("loaded")
  // })
  // OneSignal.getUserId().then((id) => console.log("101 push id>>", id))

  OneSignal.getUserId().then(async (id) => {
    console.log("OneSignal>>>>>>>>", id);
    if (id)
      await postReqHandlerWithOutDispatch({
        url: "deviceToken",
        data: { notiToken: id },
      });
  });
  OneSignal.on("subscriptionChange", function (isSubscribed) {
    console.log(
      "OneSignal The user's subscription state is now:",
      isSubscribed
    );
    if (isSubscribed) Pushnotification();
  });
}
