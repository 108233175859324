import React, { useState } from 'react'
import { useEffect } from 'react'
import axios from "axios"
import { config } from "../../constant"

const seoUrl = config.url.API_URL + "admin/seo"

function SeoForm(props) {
    const { add, update, id, getReq, cancel } = props
    const [value, setValue] = useState({})

    const handleChange = e => setValue(value => ({ ...value, [e.target.name]: e.target.value }))

    const reject = () => {
        setValue({})
        cancel()
    }

    const handleSubmit = async e => {
        try {
            e.preventDefault()
            let response
            add ?
                response = await axios.post(seoUrl, value)
                : response = await axios.patch(seoUrl + `?_id=${id}`, value)
            if (response.data.ack === 1) getReq()
            reject() //clear after update
            console.log("handleSubmit", response)
        } catch (err) {
            console.error(err)
        }
    }

   
    useEffect(() => add ? setValue({}) : setValue(update), [add, id])
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group mt-4">
                <label htmlFor="FormControlInput1">Path</label>
                <input
                    type="text"
                    className="form-control"
                    id="FormControlInput1"
                    placeholder="add questions here"
                    name="path"
                    onChange={handleChange}
                    value={value.path || ''}
                    required
                />
            </div>
            <div className="form-group mt-4">
                <label htmlFor="FormControlInput1">Title</label>
                <input
                    type="text"
                    className="form-control"
                    id="FormControlInput1"
                    placeholder="add questions here"
                    name="title"
                    onChange={handleChange}
                    value={value?.title || ''}
                    required
                />
            </div>

            <div className="form-group mt-4">
                <label htmlFor="FormControlInput1">Meta Description</label>
                <textarea
                    className="form-control mt-2"
                    rows="2"
                    placeholder="meta description"
                    name="description"
                    onChange={handleChange}
                    value={value?.description || ""}
                ></textarea>
            </div>
           
            <div className="form-group mt-4">
                <label htmlFor="FormControlInput1">KeyWord</label>
                <textarea
                    className="form-control mt-2"
                    rows="2"
                    placeholder="Add keyword here"
                    name="metaKeyword"
                    onChange={handleChange}
                    value={value?.metaKeyword || ""}
                ></textarea>
            </div>


            <div className="form-group mt-4">
                <label htmlFor="FormControlInput1">Link</label>
                <input
                    type="text"
                    className="form-control"
                    id="FormControlInput1"
                    placeholder="add questions here"
                    name="link"
                    onChange={handleChange}
                    value={value?.link || ''}
                />
            </div>

            <div className="d-flex gap-3">
                <button type='submit' className="mt-4 btn btn-primary">Submit</button>
                <button type='button' className="mt-4 btn btn-danger" onClick={reject}>Cancel</button>
            </div>
        </form>
    )
}

export default SeoForm