import React, { useEffect, useState } from 'react'
// import {helloWorld, helloWorld1} from '../getFunctions'
import axios from "axios"
import { config } from '../../constant'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"


const getlanguageUrl = config.url.API_URL + 'language'

function Language() {
    const [language, setlanguage] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [editInputValue, setEditInputValue] = useState('')
    const [editSwitch, setEditSwitch] = useState(false)
    const [catId, setCatId] = useState('')

    const getCategory = async e => {
        const response = await axios.get(getlanguageUrl)
        console.log(language)
        setlanguage(await response.data.responseData)
    }

    const handleChange = e => {
        setInputValue(e.target.value)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const data = {
            language: inputValue
        }
        const response = await axios.post(getlanguageUrl + '/create', data)
        if (response) {
            getCategory()
            setInputValue('')
        }
    }

    const handleEditChange = e => {
        setEditInputValue(e.target.value)
    }
    const handleEdit = (id, name) => {
        setEditInputValue(name)
        setCatId(id)
        setEditSwitch(true)
    }

    const handleUpdate = async e => {
        e.preventDefault()
        if (!catId) return
        if (!editInputValue) return
        const data = {
            language: editInputValue
        }
        const response = await axios.patch(getlanguageUrl + '/' + catId, data)
        if (response) {
            getCategory()
            setInputValue('')
        }
    }



    const deleteCategory = async name => {
        const result = window.confirm("Are you sure to Delete this " + name + " Category? \n please note: if you delete this category, Subcategories under this category will be deleted. it will occer some problem in future")
        if (!result) return
        const response = await axios.delete(getlanguageUrl + '/' + name)
        getCategory()
    }

    useEffect(() => {
        getCategory()
    }, [])

    return (
        <div>
            <div className='mt-3 p-3 box_1 shadow_1'>
                <h4 className='cat_title'>Language</h4>
                {editSwitch ?
                    (
                        <div>
                            <label htmlFor="post category">Edit Language</label>
                            <div className="d-flex gap-3">
                                <input type="text" list="language"
                                    className="form-control form-control-xl mt-2"
                                    autoComplete="off"
                                    placeholder="Enter Language here"
                                    name="editlanguageName"
                                    onChange={handleEditChange}
                                    value={editInputValue || ''}
                                />
                                <button className='btn btn-primary' onClick={handleUpdate}>Submit</button>
                                <button className='btn btn-danger' onClick={()=>setEditSwitch(false)}>Cancel</button>
                            </div>
                        </div>
                    )
                    :
                    (
                        <div>
                            <label htmlFor="post category">Add Language</label>
                            <div className="d-flex gap-3">
                                <input type="text" list="language"
                                    className="form-control form-control-xl mt-2"
                                    autoComplete="off"
                                    placeholder="Enter Language here"
                                    name="languageName"
                                    onChange={handleChange}
                                    value={inputValue || ''}
                                />
                                <button className='btn btn-primary' onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    )

                }




                <datalist id="language">
                    {language.map((item, key) =>
                        <option key={key} value={item.language} />
                    )}
                </datalist>
                <div className='category_box1 mt-3'>
                    {language.map((item, index) => {
                        console.log(item.language)
                        return (

                            <div key={index} className="category_in_box shadow_1">
                                {index + 1 + '. ' + item.language}
                                <div className="action_icons">
                                    <FontAwesomeIcon icon={faEdit} className="post_icons text-success c_pointer" onClick={() => handleEdit(item._id, item.language)}></FontAwesomeIcon>
                                    <FontAwesomeIcon icon={faTrash} className="post_icons text-danger c_pointer" onClick={() => deleteCategory(item.language)}></FontAwesomeIcon>
                                </div>

                            </div>

                        )
                    })}
                </div>
            </div>
            <div className="box_1">

            </div>
        </div>
    )
}

export default Language
