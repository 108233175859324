import api from "../../config/api";
import Network from "../../helpers/network";

export const ACTION_ADD_CONTACT_REQUEST = "ACTION_ADD_CONTACT_REQUEST";
export const ACTION_ADD_CONTACT_SUCCESS = "ACTION_ADD_CONTACT_SUCCESS";
export const ACTION_ADD_CONTACT_FAILD = "ACTION_ADD_CONTACT_FAILD";

const initialState = {
    user: null,
    isLogin: false,
    isSubmitted: false,
    isProcessing: null,
    isAuthenticated: false,
    isError: false,
    message: "",
  };


  export const contactReducer = (state = initialState, action) => {
    switch (action.type) {
      case ACTION_ADD_CONTACT_REQUEST:
        return {
          ...state,
          isError: false,
          isProcessing: true,
          //    message :
        };
      case ACTION_ADD_CONTACT_SUCCESS:
        return {
          ...state,
          isError: false,
          isProcessing: false,
          data: action.payload,
          isSuccess: true,
          //    message :
        };
      case ACTION_ADD_CONTACT_FAILD:
        return {
          ...state,
          isError: true,
          isProcessing: false,
          data: null,
          isSuccess: false,
          //    message :
        };
      default:
        return state;
    }
  };

  export const addContact =
  (data = 0) =>
  (dispatch) => {
    console.log('check',api.contact)
    return new Promise((resolve, reject) => {
      try {
        dispatch({ type: ACTION_ADD_CONTACT_REQUEST });
        Network.post(api.contact,data)
          .then((res) => {
            dispatch({
              type: ACTION_ADD_CONTACT_SUCCESS,
              payload: res?.data?.responseData,
            });
            resolve({
              status: true,
              message: res?.data?.message,
            });
          })
          .catch((err) => {
            console.log("error", err);
            dispatch({ type: ACTION_ADD_CONTACT_FAILD });
            reject(err);
          });
      } catch (err) {
        console.log("error", err);
        dispatch({ type: ACTION_ADD_CONTACT_FAILD });
        reject(err);
      }
    });
  };