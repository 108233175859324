import axios from "axios"
import config from "./../config/config"
const http = axios.create({
  baseURL: config.API_URL,
  headers: {
    "Content-Type": "application/json",
  },
})
// console.log("network>>")
// Set the AUTH token for any request
http.interceptors.request.use(function (config) {
  const token = typeof window !== "undefined" && localStorage.getItem("accessToken")
  config.headers.Authorization = token ? `Bearer ${token}` : ""
  return config
})
export function setToken(token) {
  http.defaults.headers.common["Authorization"] = `Bearer ${token}`
}
console.log("uri>>", config)
const Network = {
  http: http,
  get: (url, params) => {
    return http.get(config.API_URL + url, { params: params })
  },
  post: (url, data, header = null) => {
    if (header) return http.post(config.API_URL + url, data, header)
    else return http.post(config.API_URL + url, data)
  },
  put: (url, data, header = null) => {
    if (header) return http.put(config.API_URL + url, data, header)
    else return http.put(config.API_URL + url, data)
  },
  patch: (url, data, header = null) => {
    if (header) return http.patch(config.API_URL + url, data, header)
    else return http.patch(config.API_URL + url, data)
  },
  delete: (url, params) => {
    return http.delete(config.API_URL + url, { params: params })
  },
  uploadx: (url, data, options = null) => {
    if (options) return http.post(config.API_URL + url, data, options)
    else return http.post(config.API_URL + url + "/util/file-upload", data)
  },
  upload: (data, options = null) => {
    if (options) return http.post(config.API_URL + "/util/file-upload", data, options)
    else return http.post(config.API_URL + "/util/file-upload", data)
  },
  uploads: (data, options = null) => {
    return options ?
      http.post(config.API_URL + "/util/file-uploads", data, options)
      : http.post(config.API_URL + "/util/file-uploads", data)
  },
}
export default Network
