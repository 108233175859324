import React from 'react'
import { Tab, Tabs } from "react-bootstrap"
import { Link } from 'react-router-dom'
import main_logo from "../../../assets/web_img/main_logo.png"
import welcome_arrow from "../../../assets/web_img/welcome_arrow.png"
import welcome_arrow1 from "../../../assets/web_img/welcome_arrow1.png"
import welcome_arrow2 from "../../../assets/web_img/welcome_arrow2.png"
import music_icon from "../../../assets/web_img/music_icon.png"
import dance_icon from "../../../assets/web_img/dance_icon.png"
import writing from "../../../assets/web_img/writing.png"
import drama from "../../../assets/web_img/drama.png"
import visual_ari from "../../../assets/web_img/visual_ari.png"

function CreatorPopup(props) {
  return (
    <div className="welcome_section_popup">
      <div onClick={props.onHide} className="bg-white close_pop">
        X
      </div>

      <div className="container">
        <h2>Welcome to Allphanes</h2>
        <div className="welcome_logo">
          <img className="img-responsive" src={main_logo} alt="main_logo" />
        </div>
        <div className="category_list">
          <div className="row">
            <Tabs
              defaultActiveKey={1}
              animation={false}
              id="noanim-tab-example"
            >
              <Tab eventKey={1} title="Looking for something?">
                <div className="row">
                  <div className="col-sm-2 welcome_icon">
                    <Link onClick={props.onHide} to="/search/post?dm=music">
                      <img src={music_icon} alt="main_logo" />
                      <h3>Music</h3>
                    </Link>
                  </div>
                  <div className="col-sm-3 wecome_categori_border">
                    <img
                      className="img-responsive"
                      src={welcome_arrow}
                      alt="main_logo"
                    />
                  </div>
                  <div className="col-sm-2 welcome_icon dance_section">
                    <Link onClick={props.onHide} to="/search/post?sd=dance">
                      <img src={dance_icon} alt="main_logo" />
                      <h3>Dance</h3>
                    </Link>
                  </div>
                  <div className="col-sm-3 wecome_categori_border">
                    <img
                      className="img-responsive"
                      src={welcome_arrow1}
                      alt="main_logo"
                    />
                  </div>
                  <div className="col-sm-2 welcome_icon">
                    <Link onClick={props.onHide} to="/search/post?dm=writing">
                      <img src={writing} alt="main_logo" />
                      <h3>Writing</h3>
                    </Link>
                  </div>
                  <div className="col-sm-4 welcome_icon">
                    <Link onClick={props.onHide} to="/search/post?dm=video">
                      <img src={drama} alt="main_logo" />
                      <h3>Video</h3>
                    </Link>
                  </div>
                  <div className="col-sm-4 wecome_categori_border">
                    <img
                      className="img-responsive"
                      src={welcome_arrow2}
                      alt="main_logo"
                    />
                  </div>
                  <div className="col-sm-4 welcome_icon">
                    <Link
                      onClick={props.onHide}
                      to="/search/post?dm=visual art"
                    >
                      <img src={visual_ari} alt="main_logo" />
                      <h3>Visual Art</h3>
                    </Link>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatorPopup;
