import React, { useEffect, useState } from "react"
import CropOriginalIcon from "@mui/icons-material/CropOriginal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import { ImageUploadDialog } from "../../../_helpers/Material"
import Tooltip from "@mui/material/Tooltip"

import { Button } from "react-bootstrap"
import { SpaceProfileImg } from "../../components/AppComponents/Helpers"
import { useDispatch } from "react-redux"
import {
    orgIsFollowManagement,
    orgIsLikeManagement,
} from "../../redux/reducers/organizationReducer"
import { postRequestHandler } from "../../redux/reducers/requestHandler.reducer"

export const OrganizationLike = ({ orgDetails, page }) => {
    const token = typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const dispatch = useDispatch()
    const handleLikeClick = async () => {
        const params = {
            apis: {
                userid: token,
                orgid: orgDetails?._id
            },
            local: {
                updatePage: page
            }
        }
        await dispatch(orgIsLikeManagement(params))
    }

    return (
        <>
            <Tooltip title={orgDetails?.isLiked ? "Click to remove like" : "Click to Like"} arrow>
                <Button
                    variant={orgDetails?.isLiked ? "like text-primary" : "btn-light"}
                    onClick={handleLikeClick}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                        <path d="m10 17-1.042-.938q-2.083-1.854-3.437-3.177-1.354-1.323-2.136-2.354Q2.604 9.5 2.302 8.646 2 7.792 2 6.896q0-1.854 1.271-3.125T6.396 2.5q1.021 0 1.979.438.958.437 1.625 1.229.667-.792 1.625-1.229.958-.438 1.979-.438 1.854 0 3.125 1.271T18 6.896q0 .896-.292 1.729-.291.833-1.073 1.854-.781 1.021-2.145 2.365-1.365 1.344-3.49 3.26Zm0-2.021q1.938-1.729 3.188-2.948 1.25-1.219 1.989-2.125.74-.906 1.031-1.614.292-.709.292-1.396 0-1.229-.833-2.063Q14.833 4 13.604 4q-.729 0-1.364.302-.636.302-1.094.844L10.417 6h-.834l-.729-.854q-.458-.542-1.114-.844Q7.083 4 6.396 4q-1.229 0-2.063.833-.833.834-.833 2.063 0 .687.271 1.364.271.678.989 1.573.719.896 1.98 2.125Q8 13.188 10 14.979Zm0-5.5Z" />
                    </svg>
                    {orgDetails?.isLiked ? "Liked" : "like"}
                </Button>
            </Tooltip>
        </>
    )
}

export const OrgFollowManagement = ({ orgDetails, page }) => {
    const dispatch = useDispatch()

    const userId =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""

    const handleClick = async ({ alert }) => {
        try {
            if (alert) {
                const result = window.confirm(
                    "Are you sure to unfollow this organization"
                )
                if (!result) return
            }
            const params = {
                apis: {
                    userid: userId,
                    orgid: orgDetails?._id,
                },
                local: {
                    updatePage: page,
                },
            }
            const response = await dispatch(orgIsFollowManagement(params))

            console.log("OrgFollow", response)
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            <Tooltip title={orgDetails?.isFollowing ? "click to unfollow" : "click to follow"} arrow>
                <button
                    className={orgDetails?.isFollowing ? "unfollow btn-white border border-light bg-white shadow-none text-primary" : "btn-white border border-light bg-white shadow-none"}
                    onClick={() => handleClick({ alert: orgDetails?.isFollowing ? true : false })}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                        <path d="M14.75 11V9.25H13v-1.5h1.75V6h1.5v1.75H18v1.5h-1.75V11ZM8 10q-1.25 0-2.125-.875T5 7q0-1.25.875-2.125T8 4q1.25 0 2.125.875T11 7q0 1.25-.875 2.125T8 10Zm-6 6v-1.917q0-.541.26-.989.261-.448.719-.719 1.146-.667 2.417-1.021Q6.667 11 8 11q1.333 0 2.604.354 1.271.354 2.417 1.021.458.271.719.719.26.448.26.989V16Zm1.5-1.5h9v-.417q0-.125-.062-.239-.063-.115-.167-.177-.979-.563-2.063-.865Q9.125 12.5 8 12.5q-1.125 0-2.208.302-1.084.302-2.063.865-.104.062-.167.177-.062.114-.062.239Zm4.5-6q.625 0 1.062-.438Q9.5 7.625 9.5 7t-.438-1.062Q8.625 5.5 8 5.5t-1.062.438Q6.5 6.375 6.5 7t.438 1.062Q7.375 8.5 8 8.5Zm0-1.521ZM8 14.5Z" />
                    </svg>
                    {orgDetails?.isFollowing ? "Unfollow" : "Follow"}
                </button>
            </Tooltip>
        </>
    )
}

export const JoinMember = (props) => {
    const { orgDetails } = props
    const [isMembers, setIsMember] = useState(orgDetails?.isMember[0] || "")
    const currentUserId =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const dispatch = useDispatch()
    useEffect(() => {
        setIsMember(orgDetails?.isMember[0])
    }, [orgDetails])

    const handleClick = async (e) => {
        try {
            if (e === 1) {
                const result = window.confirm(
                    "Note : Are you sure to leave this organization. If you leave you can't join again."
                )
                if (!result) return
            }
            let req = {
                data: {
                    memberId: currentUserId,
                    orgid: orgDetails?._id,
                },
                url: 'orgMembers'
            }
            await dispatch(postRequestHandler(req))
            window.location.reload()
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            {isMembers ? (
                isMembers?.status?.toLowerCase() === "deleted" ? (
                    ""
                ) : (
                    <Tooltip
                        title={
                            isMembers?.status?.toLowerCase() === "active"
                                ? "Click to leave Organization"
                                : "Click to remove Request"
                        }
                        arrow>
                        <Button variant="org_delete text-danger" onClick={() => handleClick(1)}>

                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                                <path d="M6.5 17q-.625 0-1.062-.438Q5 16.125 5 15.5v-10H4V4h4V3h4v1h4v1.5h-1v10q0 .625-.438 1.062Q14.125 17 13.5 17Zm7-11.5h-7v10h7ZM8 14h1.5V7H8Zm2.5 0H12V7h-1.5Zm-4-8.5v10Z" />
                            </svg>
                            {/* Join */}
                            {isMembers?.status?.toLowerCase() === "active"
                                ? "Leave"
                                : "Remove"}
                        </Button>
                    </Tooltip>
                )
            ) : (
                <Tooltip title="Click to send join request" arrow
                    className="">
                    <Button
                        variant="btn-light"

                        onClick={() => handleClick(2)}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                            <path d="M13.5 14q1.667 0 2.833-1.167Q17.5 11.667 17.5 10q0-1.667-1.167-2.833Q15.167 6 13.5 6q-.708 0-1.333.219t-1.146.635q.479.667.729 1.469T12 10q0 .875-.25 1.677t-.729 1.469q.521.416 1.146.635.625.219 1.333.219ZM10 11.938q.229-.438.365-.917.135-.479.135-1.021 0-.542-.135-1.021-.136-.479-.365-.917-.229.438-.365.917Q9.5 9.458 9.5 10q0 .542.135 1.021.136.479.365.917ZM6.5 14q.708 0 1.333-.219t1.146-.635q-.479-.667-.729-1.469T8 10q0-.875.25-1.677t.729-1.469q-.521-.416-1.146-.635Q7.208 6 6.5 6 4.833 6 3.667 7.167 2.5 8.333 2.5 10q0 1.667 1.167 2.833Q4.833 14 6.5 14Zm0 1.5q-2.292 0-3.896-1.604T1 10q0-2.292 1.604-3.896T6.5 4.5q1 0 1.885.323.886.323 1.615.927.729-.604 1.625-.927.896-.323 1.875-.323 2.292 0 3.896 1.604T19 10q0 2.292-1.604 3.896T13.5 15.5q-.979 0-1.875-.323T10 14.25q-.729.604-1.615.927Q7.5 15.5 6.5 15.5Z" />
                        </svg>
                        Join
                        {/* {isMembers?.status?.toLowerCase() === 'active' ? 'Leave Org' : 'Delete'} */}
                    </Button>
                </Tooltip>
            )}
        </>
    )
}

export const OrgProfileBar = (props) => {
    const { orgDetails, adminCount } = props
    const currentUserId =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""
    return (
        <>
            <div className="org_profile_name">
                <p className="text-primary">{orgDetails?.showName || orgDetails?.pageName}</p>
                <small>
                    {orgDetails?.totalFollowers} followers,{orgDetails?.totalLikes} likes,{" "}
                    {orgDetails?.totalActiveMembers} members{" "}
                </small>
            </div>
            <div className="btn_follow">
                {orgDetails?.userid === currentUserId ? <></> :
                    <>
                        <OrgFollowManagement orgDetails={orgDetails} page="detailspage" />
                        <JoinMember orgDetails={orgDetails} />
                        <OrganizationLike orgDetails={orgDetails} page="detailspage" />
                    </>
                }
            </div>
            {orgDetails?.userid === currentUserId ?
                <>
                    <div className="join_won_org">
                        <a href="/org/lists/#joined">
                            <p>
                                <b>Joined</b>
                                organization's
                                <span>{adminCount?.member || 0}</span>
                            </p>
                        </a>
                        <a href="/org/lists/#myorg">
                            <p>
                                <b>My</b>
                                organization's
                                <span>{adminCount?.owner || 0}</span>
                            </p>
                        </a>
                    </div>
                </>
                : <></>}
        </>
    )
}

function OrganizationFrontSection(props) {
    const { orgDetails, adminCount } = props
    const currentUserId =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const [modalShow, setModalShow] = useState(false)
    const [PathInfo, setPathInfo] = useState("")
    const [uploadedFor, setUploadedFor] = useState("")

    const handleModalClick = (path) => {
        setModalShow(true)
        setPathInfo(path)
        setUploadedFor(path === 1 ? "logo" : "background")
    }
    return (
        <>
            <section className="profile_hero" id="profileHero">
                <div
                    className="profile_cover"
                    style={{
                        backgroundImage: `url(${orgDetails?.BackgroundImage || "/assets/web_img/choto_logo_1.png"
                            })`,
                    }}
                >
                    {orgDetails?.userid === currentUserId && (
                        <button
                            type="button"
                            className="edit_cover_pic_btn shadow"
                            title="Change cover photo"
                            onClick={() => handleModalClick(0)}
                        >
                            <CropOriginalIcon />
                        </button>
                    )}
                </div>
                <div className="container org_cover">
                    <div className="profile_outer_photo bg_allp2">
                        <div className="profile_inner_photo">
                            <div className="img_box">
                                <SpaceProfileImg
                                    link={orgDetails?.organiztionLogo}
                                    clas={`img_box`}
                                />
                            </div>
                            {orgDetails?.userid === currentUserId && (
                                <div
                                    title="Change profile photo"
                                    className="edit_pro text-center mt-1"
                                    onClick={() => handleModalClick(1)}
                                >
                                    <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* <OrgProfileInfos orgDetails={orgDetails} token={currentUserId} /> */}

                    <OrgProfileBar orgDetails={orgDetails} adminCount={adminCount} token={currentUserId} />
                </div>
            </section>
            {modalShow && (
                <ImageUploadDialog
                    pathinfo={PathInfo}
                    close={() => setModalShow(false)}
                    uploadapi={uploadedFor}
                    orgid={orgDetails?._id}
                />
            )}
        </>
    )
}

export default OrganizationFrontSection
