import React, { useState, useEffect } from "react"
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from "react-redux"
import { getRequestHandler, postRequestHandler } from "../../redux/reducers/requestHandler.reducer"
function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export function ReportPost(props) {
    const { bottonText } = props
    const [open, setOpen] = React.useState(false)
    const [reportExist, setReportExist] = useState(false)
    const [values, setValues] = useState({})
    const dispatch = useDispatch()
    const token =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const ifReportExist = async () => {
        let req = {
            params: { pid: props.postid },
            url: 'postReport'
        }
        const response = await dispatch(getRequestHandler(req))
        response.data.ack === 1 ? setReportExist(true) : setReportExist(false)
    }

    const handleChange = e => {
        setValues(values => ({
            ...values,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const data = {
            referenceUserId: token,
            referencePostId: props.postid,
            reportText: values.reportText,
            attributes: values.reportAttribute,
        }
        let req = {
            data: data,
            url: 'postReport'
        }
        const response = await dispatch(postRequestHandler(req))
        if (response)
            handleClose()
    }


    useEffect(() => {
        if (props.show)
            ifReportExist()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show, props.postid])

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div>
            <Button className="w-100" onClick={handleClickOpen}>
                {bottonText || "Report"}
            </Button>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidths={'sm'}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Report Post
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit} encType="multipart/formdata">
                        {reportExist ?
                            (
                                <div className="form_image">
                                    <h4>You already reported this post</h4>
                                </div>
                            )
                            :
                            (
                                <>
                                    <div className="report_info">
                                        <label htmlFor="last name" className="log-input-title">Select one </label>
                                        <div className='mb-3 cl_sec fs_s'>Required</div>

                                        <div className="radio">

                                            <div className="form-check">
                                                <input className="form-check-input" value="spam" type="radio" name="reportAttribute" id="reportAttribute1" onChange={handleChange} required />
                                                <label className="form-check-label">
                                                    Spam
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" value="Misleading" type="radio" name="reportAttribute" id="reportAttribute2" onChange={handleChange} required />
                                                <label className="form-check-label">
                                                    Misleading
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" value="Inappropriate" type="radio" name="reportAttribute" id="reportAttribute3" onChange={handleChange} required />
                                                <label className="form-check-label">
                                                    Inappropriate content
                                                </label>
                                            </div>

                                        </div>

                                        <div className='mt-4' id="YourIntro">
                                            <div className='w-100'>
                                                <label htmlFor="last name" className="log-input-title">Your Feedback</label>
                                                <div className=' cl_sec fs_s'>Optional</div>

                                                <textarea
                                                    className="form-control form-control-xl mt-2"
                                                    placeholder='Want to write something about it, write here'
                                                    name='reportText'
                                                    rows="4"
                                                    onChange={handleChange}
                                                    value={values.reportText || ''}
                                                >
                                                </textarea>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )
                        }
                    </form>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>

                    <Button autoFocus onClick={handleSubmit}>
                        Save changes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ReportPost