import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Footer, IndexNavbar } from '../../components'
import { getAllCreation, getAllPostManagement } from '../../redux/reducers/postReducer'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import PostTestCard from '../../components/Postsk'

function AllCreations(props) {
    const { allCreations, topCreationPosts } = props
    const [domain, setDomain] = useState(0)
    const [subDomain, setSubDomain] = useState(0)
    const dispatch = useDispatch()
    const [apiHit, setApiHit] = useState(true)
    const [page, setPage] = useState(1)
    const [loadingPost, setLoadingPost] = useState(false)


    const getPost = async () => {
        try {
            // topCreationPosts
            setLoadingPost(true)
            setApiHit(true)
            let params = {
                topCreation: 'true',
                page: page,
                sort: 'ranking',
                perPage: 20
            }
            let options = {
                reqPage: 'topCreationPosts',

            }
            if (domain > 0)
                params.dm = allCreations[domain - 1]?.postCategory
            if (subDomain > 0)
                params.sd = allCreations[domain - 1]?.subDomain[subDomain - 1]?.postSubCategory
            const response = await dispatch(getAllPostManagement(params, options))
            if (response?.data?.responseData?.length < 1) setApiHit(false)
            setLoadingPost(false)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }

    const handleScroll = (e) => {
        let scrollTop = window.pageYOffset
        let clientHeight = window.document.documentElement.clientHeight
        let bodyHeight = window.document.body.clientHeight

        if (clientHeight + scrollTop > bodyHeight - 10 && !loadingPost) {
            window.removeEventListener("scroll", handleScroll)
            setPage(prev => prev + 1)

        }
    }

    useEffect(() => {
        if (subDomain > 0) getPost()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subDomain])

    useEffect(() => {
        console.log("fdfdfdfsubpage>>", subDomain, page, loadingPost)
        if (subDomain > 0 && page > 1)
            getPost()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subDomain, page])
    useEffect(() => console.log("apiHit", apiHit), [apiHit])

    useEffect(() => {
        if (topCreationPosts) window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topCreationPosts])

    return (
        <>
            <IndexNavbar />
            <div className='all_creation p-4' style={{ backgroundImage: `url("/assets/web_img/all_creation_bg.jpg")`, minHeight: '500px', }}>

                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-2'></div>
                        <div className='col-sm-6'>
                            <button type="button" class="btn btn-success btn_back p-2 ps-3 pe-4">
                                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 896 160 576l320-320 57 56-224 224h487v80H313l224 224-57 56Z" /></svg>
                                Back</button>
                            <h3 className='m-0 mt-2'>Select</h3>
                            < h1 className='m-0'>Top Creation</h1>
                            <div className='mt-4 breadcrumb_main' style={{
                                display: 'block',
                                width: 480, padding: 0
                            }}>
                                <Breadcrumb>
                                    <Breadcrumb.Item
                                        onClick={() => {
                                            setDomain(0)
                                            setSubDomain(0)
                                        }
                                        }
                                    >

                                        TopCreation
                                    </Breadcrumb.Item>
                                    {domain > 0 &&
                                        <Breadcrumb.Item>
                                            {allCreations[domain - 1]?.postCategory || ''}
                                        </Breadcrumb.Item>
                                    }
                                    {subDomain > 0 &&
                                        <Breadcrumb.Item active>
                                            {allCreations[domain - 1]?.subDomain[subDomain - 1]?.postSubCategory}
                                        </Breadcrumb.Item>
                                    }
                                </Breadcrumb>
                            </div>

                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='creation_top text-center m-0 p-0'>
                                <ul>
                                    <li>
                                        <a href='#' className='text-dark'>
                                            <label class="form-check-label">
                                                <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                                Audio
                                            </label>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' className='text-dark'>
                                            <label class="form-check-label">
                                                <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                                Blogs
                                            </label>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' className='text-dark'>
                                            <label class="form-check-label">
                                                <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                                Cooking
                                            </label>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' className='text-dark'>
                                            <label class="form-check-label">
                                                <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                                Music
                                            </label>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' className='text-dark'>
                                            <label class="form-check-label">
                                                <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                                Recitation
                                            </label>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' className='text-dark'>
                                            <label class="form-check-label">
                                                <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                                Script
                                            </label>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' className='text-dark'>
                                            <label class="form-check-label">
                                                <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                                Video
                                            </label>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' className='text-dark'>
                                            <label class="form-check-label" >
                                                <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                                Visual Art
                                            </label>
                                        </a>
                                    </li>
                                    <li>
                                        <a href='#' className='text-dark'>
                                            <label class="form-check-label">
                                                <input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" />
                                                Writing
                                            </label>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <ul className='catrgory_main m-0 p-0'>
                                {domain < 1 && allCreations?.length > 0 && allCreations?.map((item, index) =>
                                    <>
                                        <li
                                            className='cateory_boximg border-success border border-3 c_pointer'
                                            style={{ backgroundImage: `url("/assets/web_img/category_img.jpg")` }}
                                            key={item?._id + index}
                                            onClick={() => {
                                                setDomain(index + 1)
                                                setSubDomain(1)
                                                setPage(1)
                                            }}
                                        >
                                            <h4 className='text-white'>{item?.postCategory}</h4>
                                        </li>
                                    </>
                                )

                                }


                                {domain > 0 && allCreations[domain - 1]?.subDomain?.length > 0 && allCreations[domain - 1]?.subDomain?.map((item, index) =>
                                    <>
                                        <li
                                            className='cateory_boximg border-success border border-3 c_pointer'
                                            style={{ backgroundImage: `url("/assets/web_img/category_img.jpg")` }}
                                            key={item?._id + index}
                                            onClick={() => {
                                                setSubDomain(index + 1)
                                                setPage(1)
                                            }}

                                        >
                                            <h4 className='text-white'>{item?.postSubCategory}</h4>
                                        </li>
                                    </>
                                )

                                }
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="masonry_wrapper">
                            <div className="masonry">
                                {topCreationPosts?.length > 0 &&
                                    topCreationPosts?.map((item, index) => {
                                        return (
                                            <>
                                                <div
                                                    className="item"
                                                    key={index}
                                                // onClick={() => incrementPostViews(item)}
                                                >
                                                    <div className="item_in">
                                                        <PostTestCard
                                                            // isOrgAdmin={orgDetails?.userid === token ? true : false}
                                                            singlePost={item}
                                                            key={item._id}
                                                            indexinfo={index}
                                                            getpost={() => getPost()}
                                                            isViewDetails={true}
                                                            isPostActionSec={true}
                                                            isPostCommentSec={false}
                                                        // orgDetails={orgDetails}
                                                        // changeId={setSharePostId}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
        </>
    )
}

AllCreations.loadData = (params, search) => async dispath => {
    return await dispath(getAllCreation())
}

const mapStateToProps = state => {
    console.log("mapStateToProps", state)
    const { allCreations, topCreationPosts } = state.posts
    return { allCreations, topCreationPosts }
}

export default connect(mapStateToProps, {})(AllCreations)