import api from "../../config/api"
import Network from "../../helpers/network"
export const ACTION_GET_ADMIN_DATA_REQUEST = "ACTION_GET_ADMIN_DATA_REQUEST"
export const ACTION_GET_ADMIN_DATA_SUCCESS = "ACTION_GET_ADMIN_DATA_SUCCESS"
// export const ACTION_GET_ADMIN_POST_SUCCESS = "ACTION_GET_ADMIN_POST_SUCCESS"
export const ACTION_GET_ADMIN_DATAS_SUCCESS = "ACTION_GET_ADMIN_DATAS_SUCCESS"
export const ACTION_GET_ADMIN_DATA_FAILD = "ACTION_GET_ADMIN_DATA_FAILD"
const initialState = {
    adminPosts: [],
    data: [],
    isError: false,
    isProcessing: null,
    isSuccess: null,
    message: null,
}
export const AdminReducer = (state = initialState, action) => {
    let returnObj = {
        ...state
    }
    switch (action.type) {
        case ACTION_GET_ADMIN_DATAS_SUCCESS:
            returnObj[action?.state] = action?.page > 1 ? [...state[action?.state], ...action?.payload] : action?.payload
            return returnObj
        case ACTION_GET_ADMIN_DATA_REQUEST:
            return {
                ...state,
                isError: false,
                isProcessing: true,
                //    message :
            }

        case ACTION_GET_ADMIN_DATA_SUCCESS:
            // if(page > 1) 
            if (action?.page > 1 || action?.add) {
                const tempPost = [...state.data, ...action.payload]
                returnObj.data = tempPost
            } else if (action?.edit) {
                const tempData = [...state.data]
                const indexOftempData = tempData.findIndex(i => i?._id === action?.payload?._id)
                tempData.splice(indexOftempData, 1, action?.payload)
                returnObj.data = [...tempData]
            } else
                returnObj.data = action.payload
            return returnObj

        case ACTION_GET_ADMIN_DATA_FAILD:
            return {
                ...state,
                isError: true,
                isProcessing: false,
                data: null,
                isSuccess: false,
                //    message :
            }
        default:
            return state
    }
}

export const getSubscriptionPlan = params =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({ type: ACTION_GET_ADMIN_DATA_REQUEST })
                Network.get(api.adminSubscription, params)
                    .then((res) => {
                        dispatch({
                            type: ACTION_GET_ADMIN_DATA_SUCCESS,
                            payload: res?.data?.responseData,
                            page: params?.page
                        })
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log("error", err)
                        dispatch({ type: ACTION_GET_ADMIN_DATA_FAILD })
                        reject(err)
                    })
            } catch (err) {
                console.log("error", err)
                dispatch({ type: ACTION_GET_ADMIN_DATA_FAILD })
                reject(err)
            }
        })
    }

export const addSubscriptions = params =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({ type: ACTION_GET_ADMIN_DATA_REQUEST })
                Network.post(api.addSubscription, params)
                    .then((res) => {

                        resolve(res)
                    })
                    .catch((err) => {
                        console.log("error", err)
                        dispatch({ type: ACTION_GET_ADMIN_DATA_FAILD })
                        reject(err)
                    })
            } catch (err) {
                console.log("error", err)
                dispatch({ type: ACTION_GET_ADMIN_DATA_FAILD })
                reject(err)
            }
        })
    }

export const addSubscriptionPlan = params =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({ type: ACTION_GET_ADMIN_DATA_REQUEST })
                Network.post(api.adminSubscription, params)
                    .then((res) => {
                        dispatch(getSubscriptionPlan({ sort: "createdAt", stype: -1 }))
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log("error", err)
                        dispatch({ type: ACTION_GET_ADMIN_DATA_FAILD })
                        reject(err)
                    })
            } catch (err) {
                console.log("error", err)
                dispatch({ type: ACTION_GET_ADMIN_DATA_FAILD })
                reject(err)
            }
        })
    }

export const updateSubscriptionPlan = params =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({ type: ACTION_GET_ADMIN_DATA_REQUEST })
                Network.put(api.adminSubscription, params)
                    .then((res) => {
                        dispatch(getSubscriptionPlan({ sort: "createdAt", stype: -1 }))
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log("error", err)
                        dispatch({ type: ACTION_GET_ADMIN_DATA_FAILD })
                        reject(err)
                    })
            } catch (err) {
                console.log("error", err)
                dispatch({ type: ACTION_GET_ADMIN_DATA_FAILD })
                reject(err)
            }
        })
    }

export const deleteSubscriptionPlan = params =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({ type: ACTION_GET_ADMIN_DATA_REQUEST })
                Network.delete(api.adminSubscription, params)
                    .then((res) => {
                        dispatch({
                            type: ACTION_GET_ADMIN_DATA_SUCCESS,
                            payload: res?.data?.responseData,
                        })
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log("error", err)
                        dispatch({ type: ACTION_GET_ADMIN_DATA_FAILD })
                        reject(err)
                    })
            } catch (err) {
                console.log("error", err)
                dispatch({ type: ACTION_GET_ADMIN_DATA_FAILD })
                reject(err)
            }
        })
    }
