import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { postRequestHandler } from "../../redux/reducers/requestHandler.reducer"


function AdminLogin() {
    const [values, setValues] = useState({})
    const [errmsg, setErrMsg] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleChange = e => setValues(values => ({ ...values, [e.target.name]: e.target.value }))

    const handleSubmit = async e => {
        try {
            e.preventDefault()
            const response = await dispatch(postRequestHandler({
                url: 'adminLogin',
                action: 'ACTION_AUTH_USER',
                data: values
            }))
            // const response = await axios.post(loginUrl, values)
            if (response?.data?.ack === 0) return setErrMsg(response.data.message)
            localStorage.clear()
            localStorage.setItem("token", response.data.responseData.user.id)
            localStorage.setItem("accessToken", response.data.responseData.token)
            localStorage.setItem(
                "currentuser",
                JSON.stringify(response?.data?.responseData)
            )
            navigate("/admin/dashboard/")
        } catch (err) {
            setErrMsg(err)
        }
    }

    return (
        <>
            <form className="container" onSubmit={handleSubmit}>
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-6 admin_login">
                        <div className="admin_log_box bg_2 items-center shadow_1">
                            <h4 className="">Admin</h4>
                            {errmsg && <div className="err-msg">{errmsg}</div>}
                            <div className="phone-tag mb-2 ">
                                {/* email  */}
                                <div className="login-form mt-4">
                                    <label htmlFor="email">admin :</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-xl login mt-1"
                                        autoComplete="off"
                                        placeholder="admin"
                                        name="admin"
                                        onChange={handleChange}
                                        value={values.admin || ""}
                                    />
                                    {/* <div className="errors">{!inputfocus.email && errors.email}</div> */}
                                </div>

                                {/* password */}
                                <div className="login-form mt-2">
                                    <label htmlFor="password">Password :</label>
                                    <input
                                        type="password"
                                        className="login form-control form-control-xl"
                                        autoComplete="off"
                                        placeholder="Enter password here"
                                        name="password"

                                        onChange={handleChange}
                                        value={values.password || ""}
                                    />
                                    <div className="errors"></div>
                                </div>
                            </div>
                            <button className="btn btns mt-4">Continue</button>
                            {/* <div className="mt-4">
					            <Link to="/auth/forgotPassword">Forgot password ?</Link>
				            </div> */}
                        </div>
                    </div>
                </div>

            </form>
        </>
    )
}

export default AdminLogin