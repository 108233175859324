import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ShareLink from "../../components/ShareLink/ShareLink";
import PostTestCard from "../../components/Postsk";
import { IndexNavbar, NewsList, Footer } from "../../components";
import CreatePost from "../../components/Post/CreatePost";
import RightBar from "../../components/Navbars/RightBar";
import { connect, useDispatch } from "react-redux";
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer";
import { LDS } from "../../components/Loaders/SimpleCircle";

function MyCanvas(props) {
  const { myCanvas, user, trendingCreations } = props;
  const [searchParams] = useSearchParams();
  const [postTag, setPostTag] = useState(0);
  // const [endApiHit, setEndApiHit] = useState(false)
  const [loadingPost, setLoadingPost] = useState(false);
  const scrollContainer = useRef(null);
  const [page, setPage] = useState(1);
  const [load, setLoad] = useState(true);
  // const token = typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const [sharePostId, setSharePostId] = useState(null);
  const dispatch = useDispatch();

  const getPosts = async () => {
    setLoadingPost(true);
    let params = {
      private: true,
      status: "active",
      page: page,
    };
    let req = {
      params: params,
      state: "myCanvas",
      action: "ACTION_GET_POSTS_MANAGEMENT",
      url: "myCanvas",
    };
    await dispatch(getRequestHandler(req));
    setLoadingPost(false);
  };

  useEffect(() => {
    if (myCanvas?.length > 0) setLoad(false);
  }, [myCanvas]);
  useEffect(() => {
    if (load) setTimeout(() => setLoad(false), [3000]);
  }, [load]);

  const handleScroll = (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    if (offsetHeight + scrollTop > scrollHeight - 150) {
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (page > 1) getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    console.log("searchParams", searchParams.get("v"));
    if (searchParams.get("v")) setSharePostId(searchParams.get("v"));
  }, [searchParams]);

  return (
    <main className="bg_allp">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`Allphanes | Mycanvas`}</title>
        </Helmet>
      <IndexNavbar />
      <div
        id="profile"
        className="bg_allp my_canvas_section container"
        ref={scrollContainer}
      >
        
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <CreatePost
              userinfo={user?.user}
              getpost={() => getPosts()}
              spinner={false}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 mb_none">
            <div className="py-3">
              <NewsList />
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="profile_info_sec o-hid bg-white shadow_1 px-3 mt-3 br_5">
              <div className="user_profile_tag_sec">
                <div
                  className={
                    postTag === 0 ? "pro_info_tag active" : "pro_info_tag"
                  }
                  onClick={() => {
                    setPostTag(0);
                  }}
                >
                  Posts
                </div>

                <div className="d-flex gap-2">
                  <Link
                    to="/search/post/"
                    className="btn cmn_btn my-2 items_center"
                  >
                    Advance Search
                  </Link>
                </div>
              </div>
            </div>

            {/* view post section */}
            <div
              className="post-area-section post_area_infinite scrollBar"
              onScroll={handleScroll}
            >
              {/* //loop this  */}
              {myCanvas?.length > 0
                ? myCanvas?.map((item, index) => (
                    <PostTestCard
                      currentuser={user?.user}
                      singlePost={item}
                      key={item._id}
                      indexinfo={index}
                      getpost={() => getPosts()}
                      isPostCommentSec={true}
                      isPostActionSec={true}
                      // changeId={setSharePostId}
                    />
                  ))
                : !loadingPost &&
                  !load &&
                  myCanvas?.length === 0 && (
                    <div className="mt-3 items_center bg-white shadow_1 br_5 no_post">
                      No Post Found
                    </div>
                  )}
              {(loadingPost || load) && (
                <div className="d-flex justify-content-center">
                  <LDS />
                </div>
              )}

              {/* end post loop  */}
            </div>
          </div>
          <div className="col-lg-3 col-md-3 mb_none">
            <RightBar
                trendingCreations={trendingCreations}
                title="Trending posts"
              />
          </div>
        </div>

        {sharePostId && (
          <ShareLink id={sharePostId} cls={() => setSharePostId(null)} />
        )}
      </div>
      <Footer />
    </main>
  );
}
// export default
MyCanvas.loadData = (params, search) => async (dispatch) => {
  const params = {
    private: true,
    status: "active",
    page: 1,
  };

  let params2 = {
    // token: token,
    // sort: "ml",
    private: true,
    page: 1,
    limit: 10,
  };

  let req = {
    params: params,
    state: "myCanvas",
    action: "ACTION_GET_POSTS_MANAGEMENT",
    url: "myCanvas",
  };

  let req2 = {
    params: params2,
    state: "trendingCreations",
    action: "ACTION_GET_POSTS_MANAGEMENT",
    // url: "recentPost",
    url: "getTranding",
  };

  await dispatch(getRequestHandler(req));
  return await dispatch(getRequestHandler(req2));
};
const mapStateToProps = (state) => {
  // console.log("stateoRgpostcreationposts", state)
  const { isSuccess, isError, isProcessing, myCanvas, trendingCreations } = state.posts;
  const { user } = state.auth;
  return { user, isSuccess, isError, isProcessing, myCanvas, trendingCreations };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(MyCanvas);
