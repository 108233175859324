import React, { useState, useEffect } from "react";
import DenseTable from "./DenseTable";
import moment from "moment";
import SimpleCircle from "../Loaders/SimpleCircle";
import { connect, useDispatch } from "react-redux";
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer";

function ProfileAnalytics(props) {
  const { analyticsPosts, myAnalyticsPosts } = props;
  const dispatch = useDispatch();
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const [isLoading, setIsLoading] = useState(false);
  const [selectValue, setSelectValue] = useState({});
  const [monthY, setMonthY] = useState(moment().format("YYYY-MM"));

  const handleSelectChange = (e) =>
    setSelectValue((prev) => ({ ...prev, [e.target.name]: e.target.value }));

  const getRankingPost = async () => {
    try {
      setIsLoading(true);
      let params = {
        page: 1,
        perPage: 11,
        mon: true,
        gm: monthY,
        sort: selectValue?.allPostSort || "rankings",
        sorder: selectValue?.allPostSortOrder || -1,
      };
      let req = {
        params: params,
        url: "analyticsPosts",
        action: "ACTION_GET_ANALYTICS_POSTS",
      };
      await dispatch(getRequestHandler(req));
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getMyRankingPost = async () => {
    try {
      let params = {
        page: 1,
        perPage: 11,
        mon: true,
        gm: monthY,
        sort: selectValue?.myPostSort || "rankings",
        sorder: selectValue?.myPostSortOrder || -1,
        // refToken: token,
      };
      let req = {
        params: params,
        url: "getPost",
        action: "ACTION_GET_MY_ANALYTICS_POSTS",
      };
      await dispatch(getRequestHandler(req));
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = async (e) => setMonthY(e.target.value);

  useEffect(() => {
    getRankingPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue?.allPostSort, selectValue?.allPostSortOrder, monthY]);
  useEffect(() => {
    getMyRankingPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue?.myPostSort, selectValue?.myPostSortOrder, monthY]);

  return (
    <div>
      <div className="row mt-4">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h3 className="all_heading">Post rankings</h3>
          </div>
          <div class="">
            <input
              type="month"
              name="month"
              className="form-control rounded-pill"
              style={{ width: "200px" }}
              min="2022-11"
              max={moment().format("YYYY-MM")}
              value={monthY}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="mb-4 br_5">
        <div className="row">
          <div className="col-lg-12 ">
            <div class="d-flex">
              <div class="pt-3 flex-grow-1">
                <h5 className="all_heading">All Posts</h5>
              </div>
              <div class="d-flex gap-2">
                <div class="pt-3 pe-2">Sort By</div>
                <div class="">
                  <select
                    name="allPostSort"
                    className="form-control form-control-sm text-center rounded-pill mt-3"
                    placeholder="sort by"
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="rankings">Rankings</option>
                    <option value="totalViews">Views</option>
                    <option value="totalLikes">Likes</option>
                    <option value="totalComment">Comments</option>
                    <option value="createdAt">Time</option>
                  </select>
                </div>
                <div class="pt-3 pe-2">Order</div>
                <div class="">
                  <select
                    name="allPostSortOrder"
                    className="form-control form-control-sm text-center rounded-pill mt-3"
                    placeholder="sort by"
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="-1">Asc</option>
                    <option value="1">Dsc</option>
                  </select>
                </div>
              </div>
            </div>
            {isLoading ? (
              <div className="d-flex justify-content-center bg_allp align-items-center">
                <SimpleCircle />
              </div>
            ) : (
              analyticsPosts?.length > 0 && (
                <DenseTable
                  data={analyticsPosts}
                  headings={[
                    "Rank",
                    "Title",
                    "Posted At",
                    "Name",
                    "Views",
                    "Likes",
                    "Comments",
                    "Actions",
                  ]}
                />
              )
            )}
          </div>
          <div className="col-lg-12 mt-3">
            <div class="d-flex gap-2">
              <div class="pt-3 flex-grow-1">
                <h5 className="all_heading">My Posts</h5>
              </div>
              <div className="d-flex">
                <div class="pt-3 pe-2">Sort By</div>
                <div class="">
                  <select
                    name="myPostSort"
                    className="form-control form-control-sm text-center rounded-pill mt-3"
                    placeholder="sort by"
                    onChange={handleSelectChange}
                    required
                  >
                    <option value="ranking">Rankings</option>
                    <option value="tva">Views</option>
                    <option value="ml">Likes</option>
                    <option value="mc">Comments</option>
                    <option value="oldf">Oldest First</option>
                    <option value="newf">Newest First</option>
                  </select>
                </div>
                {/* <div class="pt-3 pe-2">Order :</div>
                                <div class="">
                                    <select
                                        name="myPostSortOrder"
                                        className="form-control form-control-sm text-center rounded-pill mt-3"
                                        placeholder="sort by"
                                        onChange={handleSelectChange}
                                        required
                                    >
                                        <option value="-1">Asc</option>
                                        <option value="1">Dsc</option>
                                    </select>
                                </div> */}
              </div>
            </div>
            {myAnalyticsPosts?.length > 0 && (
              <DenseTable
                data={myAnalyticsPosts}
                headings={[
                  "Rank",
                  "Title",
                  "Posted At",
                  "Views",
                  "Likes",
                  "Comments",
                  "Actions",
                ]}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { analyticsPosts, myAnalyticsPosts } = state.settings;
  return { analyticsPosts, myAnalyticsPosts };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileAnalytics);
