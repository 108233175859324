import React, { useEffect, useState } from 'react'
import MembarNav from '../../components/Members/MembarNav'
import { Footer, IndexNavbar } from '../../components'
import { connect, useDispatch } from 'react-redux'
import { getRequestHandler } from '../../redux/reducers/requestHandler.reducer'
import { LDS } from '../../components/Loaders/SimpleCircle'
import MembersCard from '../Members/MembersCard'

function RequestList(props) {
    const { requestList } = props
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const [initialLoad, setInitialLoad] = useState(true)
    const [loading, setLoading] = useState(false)
    const [endApiHit, setEndApiHit] = useState(false)
    const getRequestList = async () => {
        setLoading(true)
        let req = {
            params: {
                page: page,
                fndreq: true,
            },
            url: 'members',
            action: 'ACTION_USER_GET_STATE_MANAGEMENT',
            state: 'requestList'
        }
        const response = await dispatch(getRequestHandler(req))
        setLoading(false)
        if (response.data.responseData.length === 0) setEndApiHit(true)
    }

    const handleScroll = (e) => {
        let scrollTop = window.pageYOffset
        let clientHeight = window.document.documentElement.clientHeight
        let bodyHeight = window.document.body.clientHeight
        if (clientHeight + scrollTop > bodyHeight - 50 && !endApiHit) {
            window.removeEventListener("scroll", handleScroll)
            setPage(prev => prev + 1)
        }
    }
    useEffect(() => {
        if (requestList?.length > 0)
            setInitialLoad(false)
        if (requestList?.length > 10)
            window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [requestList])
    useEffect(() => {
        if (initialLoad) setTimeout(() => setInitialLoad(false), [1000])
    }, [initialLoad])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => getRequestList(), [])


    useEffect(() => {
        if (page > 1) getRequestList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])



    return (
        <>
            <IndexNavbar />
            <div className='container-fluid bg_allp' style={{ minHeight: "100vh" }}>
                <div className="container">
                    <div className="container background_opacity">
                        <div className="row">
                            <div className="d-flex justify-content-between align-items-center flex_wrap mb-4 mt-3">
                                <MembarNav />
                            </div>
                            <div className='row'>
                                {initialLoad ?
                                    <div className="load_body">
                                        <LDS />
                                    </div> :
                                    <>
                                        {requestList?.length > 0 &&
                                            <MembersCard
                                                members={requestList}
                                            />
                                        }
                                        {loading &&
                                            <div className="d-flex justify-content-center">
                                                <LDS />
                                            </div>
                                        }
                                    </>

                                }

                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

const mapStateToProps = (state) => {
    console.log("stateoRgpostcreationposts", state)
    const { requestList } = state.usersData
    return {
        requestList
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(RequestList)