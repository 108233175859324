import api from "../../config/api";
import Network from "../../helpers/network";
export const ACTION_GET_NEWS_REQUEST = "ACTION_GET_NEWS_REQUEST";
export const ACTION_GET_NEWS_SUCCESS = "ACTION_GET_NEWS_SUCCESS";
export const ACTION_GET_SINGLE_NEWS_SUCCESS = "ACTION_GET_SINGLE_NEWS_SUCCESS";
export const ACTION_GET_NEWS_FAILD = "ACTION_GET_NEWS_FAILD";
const initialState = {
  data: null,
  isError: false,
  isProcessing: null,
  isSuccess: null,
  message: null,
  selectedNews: null,
};
export const NewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_NEWS_REQUEST:
      return {
        ...state,
        isError: false,
        isProcessing: true,
        //    message :
      };
    case ACTION_GET_NEWS_SUCCESS:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        data: action.payload,
        isSuccess: true,
        //    message :
      };
    case ACTION_GET_NEWS_FAILD:
      return {
        ...state,
        isError: true,
        isProcessing: false,
        data: null,
        isSuccess: false,
        //    message :
      };
    case ACTION_GET_SINGLE_NEWS_SUCCESS:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        selectedNews: action.payload,
        isSuccess: true,
        //    message :
      };
    default:
      return state;
  }
};

export const getNews =
  (page = 0) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          dispatch({ type: ACTION_GET_NEWS_REQUEST });
          Network.get(api.getNews)
            .then((res) => {
              dispatch({
                type: ACTION_GET_NEWS_SUCCESS,
                payload: res?.data?.responseData,
              });
              resolve({
                status: true,
                message: res?.data?.message,
              });
            })
            .catch((err) => {
              console.log("error", err);
              dispatch({ type: ACTION_GET_NEWS_FAILD });
              reject(err);
            });
        } catch (err) {
          console.log("error", err);
          dispatch({ type: ACTION_GET_NEWS_FAILD });
          reject(err);
        }
      });
    };

export const getNewsData =
  (slug = null) =>
    (dispatch) => {
      console.log("loadData>>", slug);
      return new Promise(async (resolve, reject) => {
        try {
          //   let kk;
          //   const tq = token ? `token=${token}` : "";

          //   split[1] === "news"
          //     ? (kk = NEWS_URL + `get?slug=${slug}&${tq}`)
          //     : (kk = API_URL + `getblog?slug=${slug}&${tq}`);
          const response = await Network.get(api.getNews, { slug: slug });
          dispatch({
            type: ACTION_GET_SINGLE_NEWS_SUCCESS,
            payload: response.data?.responseData[0],
          });
          resolve(response);
          // setBlog(response.data?.responseData[0]);
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    };
