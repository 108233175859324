import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import EditPosts from './EditPosts';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export const UserProfilePhoto = ({ photo }) => {
    return <>
        <img
            id={photo}

            className='user_profile_image'
            alt={photo}
            src={photo || '/assets/web_img/choto_logo_1.png'}
            onError={e => {
                e.target.onerror = null
                e.target.src = '/assets/web_img/choto_logo_1.png'
            }}
            width="50px"
            height="50px" />
    </>
}

export default function EditPost(props) {
    const { component } = props
    const [post] = useState(props?.post)
    const [open, setOpen] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false)

    const handleClickOpen = () => {
        setOpen(true);
        setIsSubmit(false)
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmitBtn = () => {
        setIsSubmit(true)
        // handleClose()
    }

    return (
        <div>
            {component ?
                <div
                    className='c_pointer'
                    onClick={handleClickOpen}
                >
                    {component}
                </div> :
                <Button onClick={handleClickOpen} className='eclipse_btn'>
                    Edit Post
                </Button>
            }
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    Edit Post
                </BootstrapDialogTitle>
                <DialogContent dividers>

                    <div>
                        <UserProfilePhoto photo={post?.user_info[0]?.profilePhoto} />
                        <span className='px-2'>{post?.user_info[0]?.showName}</span>
                    </div>
                    <EditPosts
                        editPost={props?.post}
                        close={() => handleClose()}
                        issubmit={isSubmit}
                        clickSubmit={() => handleSubmitBtn()}
                    />

                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleSubmitBtn}>
                        Save changes
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}