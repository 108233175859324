import api from "../../config/api"
import Network from "../../helpers/network"
export const ACTION_GET_POST_REQUEST = "ACTION_GET_POST_REQUEST"
export const ACTION_GET_POST_SUCCESS = "ACTION_GET_POST_SUCCESS"
export const ACTION_GET_POST_SUCCESS_S = "ACTION_GET_POST_SUCCESS_S"
export const ACTION_CREATE_POST_REQUEST = "ACTION_CREATE_POST_REQUEST"
export const ACTION_CREATE_POST_SUCCESS = "ACTION_CREATE_POST_SUCCESS"
export const ACTION_CREATE_POST_FAILED = "ACTION_CREATE_POST_FAILED"
export const ACTION_GET_WINNERS_POST = "ACTION_GET_WINNERS_POST"
export const ACTION_GET_POST_FAILD = "ACTION_GET_POST_FAILD"
export const ACTION_GET_HOME_POST = "ACTION_GET_HOME_POST"
export const ACTION_GET_POST = "ACTION_GET_POST"
export const ACTION_GET_POSTS = "ACTION_GET_POSTS"
export const ACTION_GET_POSTS_MANAGEMENT = "ACTION_GET_POSTS_MANAGEMENT"
export const ACTION_GET_POST_DETAILS = "ACTION_GET_POST_DETAILS"
export const ACTION_GET_POST_DETAILS_ANL = "ACTION_GET_POST_DETAILS_ANL"
export const ACTION_POSTS_LIKE_MANAGEMENT = "ACTION_POSTS_LIKE_MANAGEMENT"
export const ACTION_GET_ALLCREATION = "ACTION_GET_ALLCREATION"
export const ACTION_GET_TOPCREATION = "ACTION_GET_TOPCREATION"
export const ACTION_GET_DOMAINS = "ACTION_GET_DOMAINS"
export const ACTION_GET_SINGLEPOST = "ACTION_GET_SINGLEPOST"
export const ACTION_GET_SINGLEPOST_COMMENTS = "ACTION_GET_SINGLEPOST_COMMENTS"
export const ACTION_GET_SINGLEPOST_LIKES_USER = "ACTION_GET_SINGLEPOST_LIKES_USER"
export const ACTION_ADD_UPLOADFILE_SIZE = "ACTION_ADD_UPLOADFILE_SIZE"
export const ACTION_ADD_UPLOADFILE_PROGRESS_SIZE = "ACTION_ADD_UPLOADFILE_PROGRESS_SIZE"
export const ACTION_ADD_THUMBNAIL = "ACTION_ADD_THUMBNAIL"


const initialState = {
  createPostThubnails: [
    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2023-07-19T10%3A14%3A07.869Z-video_thumbnil2.jpg",
    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2023-07-19T10%3A15%3A03.543Z-video_thumbnil3.jpg",
    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2023-07-19T10%3A15%3A53.593Z-video_thumbnil4.jpg",
    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2023-07-19T10%3A16%3A30.335Z-video_thumbnil5.jpg"
  ],
  singlePost: null,
  singlePostInfo: { comments: [], likesUser: [] },
  advanceSearch: [],
  domains: null,
  topCreationPosts: [],
  allCreations: null,
  creationPosts: [],
  myCanvas: [],
  mySpace: [],
  trendingCreations: [],
  postAnl: null,
  postDetails: null,
  privatePosts: [],
  data: null,
  homePromotionPost: [],
  winners: [],
  isError: false,
  isProcessing: null,
  isSuccess: null,
  message: null,
  request: null,
  createPostStatus: null,
  uploadFileSize: null,
  progressSize: null,
}

export const postReducer = (state = initialState, action) => {
  let returnObj = {
    ...state
  }
  switch (action.type) {
    case ACTION_ADD_THUMBNAIL:
      returnObj.createPostThubnails = [action?.payload,...state?.createPostThubnails]
      return returnObj
    case ACTION_GET_SINGLEPOST:
      returnObj.singlePost = action?.payload[0]
      return returnObj
    case ACTION_GET_SINGLEPOST_COMMENTS:
      returnObj.singlePostInfo.comments = action?.page > 1 ? [...state?.singlePostInfo.comments, ...action?.payload] : action?.payload
      return returnObj
    case ACTION_GET_SINGLEPOST_LIKES_USER:
      returnObj.singlePostInfo.likesUser = action?.page > 1 ? [...state?.singlePostInfo.likesUser, ...action?.payload] : action?.payload
      return returnObj
    case ACTION_GET_TOPCREATION:
      returnObj.topCreationPosts = action?.page > 1 ? [...state?.topCreationPosts, ...action?.payload] : action?.payload
      return returnObj
    case ACTION_ADD_UPLOADFILE_PROGRESS_SIZE:
      returnObj.progressSize = action?.payload
      return returnObj
    case ACTION_ADD_UPLOADFILE_SIZE:
      returnObj.uploadFileSize = action?.payload
      return returnObj
    case ACTION_GET_ALLCREATION:
      return {
        ...state,
        allCreations: action.payload
      }
    case ACTION_GET_DOMAINS:
      returnObj.domains = action.payload
      return returnObj
    case ACTION_GET_POST_DETAILS_ANL:
      return {
        ...state,
        postAnl: action.payload.length > 0 ? action.payload[0] : {}
      }
    case ACTION_GET_POST_DETAILS:
      return {
        ...state,
        postDetails: action.payload
      }
    case ACTION_GET_POST_REQUEST:
      return {
        ...state,
        isError: false,
        isProcessing: true,
        request: "getTrendingPost"
      }
    case ACTION_GET_POST:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        privatePosts: action.payload,
        isSuccess: true,
        request: action.mode
      }
    case ACTION_GET_POSTS:
      returnObj[action?.reqPage] = action?.page > 1 ? [...returnObj[action?.reqPage], ...action?.payload] : action?.payload
      return returnObj

    case ACTION_GET_POSTS_MANAGEMENT:
      console.log("dfd", action.payload)
      returnObj[action?.state] = action?.page > 1 ? [...returnObj[action?.state], ...action?.payload] : action?.payload
      return returnObj

    case ACTION_GET_POST_SUCCESS_S:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        isSuccess: true,
      }
    case ACTION_GET_POST_SUCCESS:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        trendingCreations: action.payload,
        isSuccess: true,
        request: action.mode
      }
    case ACTION_GET_POST_FAILD:
      return {
        ...state,
        isError: true,
        isProcessing: false,
        trendingCreations: null,
        isSuccess: false,
      }

    case ACTION_CREATE_POST_SUCCESS:
      return {
        ...state,
        createPostStatus: action.status
      }

    case ACTION_GET_WINNERS_POST:
      return {
        ...state,
        winners: action.payload
      }

    case ACTION_GET_HOME_POST:
      return {
        ...state,
        homePromotionPost: action.payload
      }

    case ACTION_POSTS_LIKE_MANAGEMENT:
      console.log("ACTION_POSTS_LIKE_MANAGEMENT", action)
      const tempList = [...returnObj[action?.reqPage]]
      const indexOfOrgList = tempList.findIndex((e) => e._id === action?._id);
      tempList[indexOfOrgList].isLiked[0].isLike = action?.payload?.isLike;
      tempList[indexOfOrgList].totalLikes = action?.payload?.totalLikes;
      returnObj[action?.reqPage] = [...tempList]

      return returnObj
    default:
      return state
  }
}


export const getAllPostManagement =
  (params, options) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          dispatch({ type: ACTION_GET_POST_REQUEST })
          Network.get(api.getPost, params)
            .then((res) => {
              dispatch({
                type: ACTION_GET_POSTS,
                page: params?.page,
                reqPage: options?.reqPage,
                payload: res?.data?.responseData,
              })
              resolve(res)
            })
            .catch((err) => {
              console.log("error", err)
              dispatch({ type: ACTION_GET_POST_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          // dispatch({ type: ACTION_GET_POST_FAILD })
          reject(err)
        }
      })
    }

export const getAllCreation =
  (params) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          Network.get(api.allCreation, params)
            .then((res) => {
              dispatch({
                type: ACTION_GET_ALLCREATION,
                payload: res?.data?.responseData,
              })
              resolve(res)
            })
            .catch((err) => {
              console.log("error", err)
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          reject(err)
        }
      })
    }

export const postLikeManagement = (params, options) => dispatch => {
  return new Promise((resolve, reject) => {
    try {
      Network.post(api.postLike, params)
        .then((res) => {
          dispatch({
            type: ACTION_POSTS_LIKE_MANAGEMENT,
            _id: params?.referencePostId,
            reqPage: options?.reqPage,
            payload: res?.data?.responseData,
          })
          resolve(res)
        })
        .catch((err) => reject(err))
    } catch (err) {
      console.log("error", err)
      reject(err)
    }
  })
}

export const createNewPost = data =>
  dispatch => {
    return new Promise((resolve, reject) => {
      try {
        Network.post(api.createPost, data)
          .then((res) => resolve(res))
          .catch((err) => reject(err))
      } catch (err) {
        console.log("error", err)
        reject(err)
      }
    })
  }

export const createEvents = data =>
  dispatch => {
    return new Promise((resolve, reject) => {
      try {
        Network.post(api.events, data)
          .then((res) => resolve(res))
          .catch((err) => reject(err))
      } catch (err) {
        console.log("error", err)
        reject(err)
      }
    })
  }

export const updateEvents = data =>
  dispatch => {
    return new Promise((resolve, reject) => {
      try {
        Network.post(api.eventsUpdate, data)
          .then((res) => resolve(res))
          .catch((err) => reject(err))
      } catch (err) {
        console.log("error", err)
        reject(err)
      }
    })
  }

export const deletePostManagement =
  (params) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          // dispatch({ type: ACTION_GET_POST_REQUEST })
          Network.delete(api.deletePost, params)
            .then((res) => {
              console.log(res)
              resolve(res)
            })
            .catch((err) => {
              console.log("error", err)
              // dispatch({ type: ACTION_GET_POST_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          // dispatch({ type: ACTION_GET_POST_FAILD })
          reject(err)
        }
      })
    }

export const getTrendingPost =
  (params) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          dispatch({ type: ACTION_GET_POST_REQUEST })
          Network.get(api.getPost, params)
            .then((res) => {
              dispatch({
                type: ACTION_GET_POST_SUCCESS,
                payload: res?.data?.responseData,
              })
              resolve(res)
            })
            .catch((err) => {
              console.log("error", err)
              dispatch({ type: ACTION_GET_POST_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          dispatch({ type: ACTION_GET_POST_FAILD })
          reject(err)
        }
      })
    }

export const getPostDetails =
  params =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          Network.get(api.getPostDetails, params)
            .then((res) => {
              dispatch({
                type: ACTION_GET_POST_DETAILS,
                payload: res?.data?.responseData,
              })
              resolve(res)
            })
            .catch((err) => {
              console.log("error", err)
              dispatch({ type: ACTION_GET_POST_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          dispatch({ type: ACTION_GET_POST_FAILD })
          reject(err)
        }
      })
    }

export const getPostDetailsAnl =
  params =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          Network.get(api.getPost, params)
            .then((res) => {
              dispatch({
                type: ACTION_GET_POST_DETAILS_ANL,
                payload: res?.data?.responseData,
              })
              resolve(res)
            })
            .catch((err) => {
              console.log("error", err)
              dispatch({ type: ACTION_GET_POST_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          dispatch({ type: ACTION_GET_POST_FAILD })
          reject(err)
        }
      })
    }

export const getHomeData =
  (params) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          // dispatch({ type: ACTION_GET_POST_REQUEST })
          Network.get(api.getHomeData, params)
            .then((res) => {
              dispatch({
                type: ACTION_GET_WINNERS_POST,
                payload: res?.data?.responseData?.winners,
              })
              dispatch({
                type: ACTION_GET_HOME_POST,
                payload: res?.data?.responseData?.homePost,
              })
              resolve({
                status: true,
                message: res?.data?.ack,
              })
            })
            .catch((err) => {
              console.log("error", err)
              // dispatch({ type: ACTION_GET_POST_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          // dispatch({ type: ACTION_GET_POST_FAILD })
          reject(err)
        }
      })
    }

export const getPostManagement =
  (params) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          // dispatch({ type: ACTION_GET_POST_REQUEST })
          Network.get(api.getPost, params)
            .then((res) => {
              dispatch({
                type: ACTION_GET_POST,
                payload: res?.data?.responseData,
              })
              // dispatch({
              //   type: ACTION_GET_HOME_POST,
              //   payload: res?.data?.responseData?.homePost,
              // })
              resolve({
                status: true,
                message: res?.data?.ack,
              })
            })
            .catch((err) => {
              console.log("error", err)
              // dispatch({ type: ACTION_GET_POST_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          // dispatch({ type: ACTION_GET_POST_FAILD })
          reject(err)
        }
      })
    }

export const getWinnersPost =
  (params) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          // dispatch({ type: ACTION_GET_POST_REQUEST })
          Network.get(api.getwinner, params)
            .then((res) => {
              dispatch({
                type: ACTION_GET_WINNERS_POST,
                payload: res?.data?.responseData,
              })
              resolve({
                status: true,
                message: res?.data?.ack,
              })
            })
            .catch((err) => {
              console.log("error", err)
              // dispatch({ type: ACTION_GET_POST_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          // dispatch({ type: ACTION_GET_POST_FAILD })
          reject(err)
        }
      })
    }

export const getHomePosts =
  (params) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          console.log("getHomePosts", params)
          // dispatch({ type: ACTION_GET_POST_REQUEST })
          Network.get(api.getHomePagePosts, params)
            .then((res) => {
              dispatch({
                type: ACTION_GET_HOME_POST,
                payload: res?.data?.responseData,
              })
              resolve({
                status: true,
                message: res?.data?.ack,
              })
            })
            .catch((err) => {
              console.log("error", err)
              // dispatch({ type: ACTION_GET_POST_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          // dispatch({ type: ACTION_GET_POST_FAILD })
          reject(err)
        }
      })
    }

export const updateHomePosts =
  (data) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          // dispatch({ type: ACTION_GET_POST_REQUEST })
          Network.post(api.updateHomePagePosts, data)
            .then((res) => {
              console.log("homepost", data)
              dispatch({
                type: ACTION_GET_HOME_POST,
                payload: res?.data?.responseData,
              })
              resolve({
                status: true,
                message: res?.data?.ack,
              })
            })
            .catch((err) => {
              console.log("error", err)
              // dispatch({ type: ACTION_GET_POST_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          // dispatch({ type: ACTION_GET_POST_FAILD })
          reject(err)
        }
      })
    }