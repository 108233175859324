import React from 'react'
import { Footer, IndexNavbar } from "../../components";

function Piechart() {
  return (
    <>
    <IndexNavbar />
      <section className=''>
      <div className='container  mt-5 mb-5'>
        <div className='row'>
          <div className='col-sm-12 text-center'>
              <h1 className='text-success'><b>Free Space 1GB</b></h1>
              <h3 className='text-info'>Lorem ipsum dolor sit amet</h3>
              <h5>Cum sociis natoque penatibus et magnis dis parturient montes</h5>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. 
                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, 
                pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, 
                vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede 
                mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.</p>
          </div>
          <div className='col-sm-2'></div>
          <div className='col-sm-4'>
            <div className='row'>
              <div className='col-sm-8'>
                  <img className='img-responsive' src='/assets/web_img/pie_chart.png' />
              </div>
              <div className='col-sm-4 text-center mt-5 mb-4'>
                <div class="bg-primary pie_chart_vlw rounded-circle"></div> 
                <span><b>Free space 1 Gb</b></span>
                <div class="bg-danger pie_chart_vlw rounded-circle mt-3"></div> 
                <span><b>Use space 75%</b></span>
              </div>
            </div>
          </div>
          <div className='col-sm-4'>
          <div className='row'>
              <div className='col-sm-8'>
                  <img className='img-responsive' src='/assets/web_img/pie_chart.png' />
              </div>
              <div className='col-sm-4 text-center mt-5 mb-4'>
                <div class="bg-primary pie_chart_vlw rounded-circle"></div> 
                <span><b>Free space 1 Gb</b></span>
                <div class="bg-danger pie_chart_vlw rounded-circle mt-3"></div> 
                <span><b>Use space 75%</b></span>
              </div>
            </div>
          </div>
          <div className='col-sm-2'></div>
          <div className='text-center mt-4 mb-4 mb-4'><button type="button" class="btn btn-success p-5 pt-2 pb-2"><h4>Buy Space</h4></button></div>
        </div>
      </div>
      </section>
    <Footer />
    </>
  )
}

export default Piechart