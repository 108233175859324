export const ACTION_GET_MEMBERS_REQUEST = "ACTION_GET_MEMBERS_REQUEST"
export const ACTION_GET_MEMBERS_SUCCESS = "ACTION_GET_MEMBERS_SUCCESS"
const initialState = {
    members: [],
    isError: false,
    isProcessing: null,
    isSuccess: null,
    message: null,
    request: null,
}
export const membersReducer = (state = initialState, action) => {
    let returnObj = {
        ...state
    }
    switch (action.type) {
        case ACTION_GET_MEMBERS_SUCCESS:
            returnObj.members = action?.page > 1 ? [...state?.members, ...action?.payload] : action?.payload
            return returnObj

       

        // case ACTION_POSTS_LIKE_MANAGEMENT:
        //     console.log("ACTION_POSTS_LIKE_MANAGEMENT", action)
        //     const tempList = [...returnObj[action?.reqPage]]
        //     const indexOfOrgList = tempList.findIndex((e) => e._id === action?._id);
        //     tempList[indexOfOrgList].isLiked[0].isLike = action?.payload?.isLike;
        //     tempList[indexOfOrgList].totalLikes = action?.payload?.totalLikes;
        //     returnObj[action?.reqPage] = [...tempList]
        //     return returnObj
        default:
            return state
    }
}