import React from 'react'
import { Footer, IndexNavbar } from "../../components";

function SubscriptionsDetails() {
  return (
    <>
    <IndexNavbar />
    <section className='subscriptionsplan'>
      <div className='container mt-5 mb-5'>
        <div className='row'>
          <div className='col-sm-12 text-center'>
              <h1 className='text-success'><b>Subscriptions Plan</b></h1>
              <h3 className='text-info'>Lorem ipsum dolor sit amet</h3>
              <h5>Cum sociis natoque penatibus et magnis dis parturient montes</h5>
              <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. 
                Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, 
                pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, 
                vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu pede 
                mollis pretium. Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. Aenean vulputate eleifend tellus.</p>
          </div>
          <ul class="analytics_list text-center m-0">
            <li class="analytics_list_li border-success">
              <h5 class="mt-4 text-success">
                <b>Monthly <span className='d-block'>Subscription</span></b>
              </h5>
              <div class="analytics_number bg-success text-white w-50 h-50 rounded-circle">
                <h3 class="last-child">
                  <small class="d-block">
                    <small><b>Rs</b></small>
                  </small>100
                </h3>
              </div>
              <div className='text-center mt-4 mb-4 mb-4'><button type="button" class="btn btn-success p-3 pt-2 pb-2"><h6 className='mb-0'>Buy Plan</h6></button></div>
            </li>
           
            <li class="analytics_list_li border-info">
              <h5 class="mt-4 text-info">
                <b>Pe-Per-View <span className='d-block'>Subscription</span></b>
              </h5>
              <div class="analytics_number bg-info text-white w-50 h-50 rounded-circle">
                <h3 class="last-child">
                  <small class="d-block">
                  <small><b>Rs</b></small>
                  </small>10
                </h3>
              </div>
              <div className='text-center mt-4 mb-4 mb-4'><button type="button" class="btn btn-success p-3 pt-2 pb-2"><h6 className='mb-0'>Buy Plan</h6></button></div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <Footer />
    </>
  )
}

export default SubscriptionsDetails