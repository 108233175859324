import React, { useState } from "react"
import { useParams, useLocation, Link, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import LoginStatus from "../../components/DesignComponents/LoginStatus"
import { Footer, IndexNavbar } from "../../components"
import { connect, useDispatch } from "react-redux"
import {
  getOrganizationDetails,
  getOrganizationFollowers,
  getOrganizationMembers,
  getOrganizationPosts,
  getOrgAnl,
  getOrgDetailsCount,
  getOrgPhotosVideos,
  OrgStorageDetails,
} from "../../redux/reducers/organizationReducer"
import OrganizationFrontSection from "./OrganizationFrontSection"
import OrganizationPosts from "./OrganizationPosts"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { useEffect } from "react"
import CreatePost from "../../components/Post/CreatePost"
import OrgAllMembers from "./OrgAllMembers"
import { FileViewer } from "../../components/AppComponents/Helpers"
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import CreateEvent from "../../components/Post/CreateEvent"
import StorageAnalysis from "./StorageAnalysis"
import OrgAbout from "./OrgAbout"
import { YouTubeEmbed } from "react-social-media-embed"


export function StandardImageList({ itemData }) {
  return (
    <ImageList cols={4} rowHeight={400}>
      {itemData?.map((item) => (
        <ImageListItem key={item?.postInfo[0]?.postImagePath}>
          <img
            src={`${item?.postInfo[0]?.postImagePath}?w=164&h=164&fit=crop&auto=format`}
            srcSet={`${item?.postInfo[0]?.postImagePath}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
            alt={item?.postInfo[0]?.postImagePath}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}

function OrgHtml(props) {
  const { storage, members, getOrgStorageAnalytics, followers, photos, adminCount, posts, getOrgPosts, videos, orgDetails, orgAnl } = props
  // const [key, setKey] = useState("post/")
  const [pages, setPages] = useState(1)
  const param = useParams()
  const path = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isCreateShow, setIsCreateShow] = useState(false)
  const [currentPage, setCurrentPage] = useState({ parent: "post/", child: "active/" })
  const [endApiHit, setEndApiHit] = useState(false)
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const currentUser =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("currentuser"))
      : ""
  const Kelmet = () => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Allphanes | ${param.name}`}</title>
        <link rel="canonical" href={`https://allphanes.com${path.pathname}`} />
      </Helmet>
    )
  }
  const getFunctions = {
    getOrgSub: async (params) => {
      await dispatch(getOrgAnl({
        pageId: orgDetails?._id,
      }))
    },
    getStorage: async (params) => {
      await getOrgStorageAnalytics({
        pageId: orgDetails?._id,
      })
    },
    getPost: async (params) =>
      await getOrgPosts({
        ...params,
        orgid: orgDetails?._id,
      }),
    getOrgMembers: (params) => dispatch(getOrganizationMembers(params)),
    getPhotos: params => {
      if (/photos|aboutinstitution|videos/.test(params?.currPage))
        return dispatch(
          getOrgPhotosVideos({
            ...params,
            orgid: orgDetails?._id,
            status: 'active',
            gtype: /photos|aboutinstitution/.test(params?.currPage) ? "photos" : "video",
          })
        )
      return
    },
    getCount: () => dispatch(getOrgDetailsCount()),
    getFollowers: params => dispatch(getOrganizationFollowers(params)),
    managePath: () => {
      const { hash, pathname } = path
      const initialHash = hash.split('#')
      if (!hash) navigate(pathname + '#post/#active/')
      // if (initialHash && initialHash !== "") setKey(() => initialHash)
      setPages(1)
      setEndApiHit(false)
      setCurrentPage(prev => ({ ...prev, parent: initialHash[1] || '', child: initialHash[2] || '' }))
    },
    manageApiCall: (params) => {
      const { hash } = path
      const initialHash = hash.split('#')
      if (initialHash[1] && initialHash[2]) {
        if (initialHash[1] === 'post/')
          getFunctions.getPost({ ...params, status: initialHash[2].split("/")[0] })
        if (/members/.test(initialHash[1]))
          getFunctions.getOrgMembers({ ...params, status: initialHash[2].split("/")[0] })
      } else {

        if (/storage/.test(initialHash[1]))
          getFunctions.getStorage({ ...params })
        if (/analytics/.test(initialHash[1]))
          getFunctions.getOrgSub({ ...params })

        if (/aboutinstitution|photos|videos/.test(initialHash[1]))
          getFunctions.getPhotos({ ...params, currPage: initialHash[1], postType: "Normal" })
        if (initialHash[1].split('/')[0] === 'followers')
          getFunctions.getFollowers({ ...params, orgid: orgDetails?._id })
      }
    },
  }
  const onLinkClick = (e) => {
    // setKey(e)
    navigate(/post|members/.test(e) ? `#${e}#active/` : `#${e}`)
  }
  const handleScroll = (e) => {
    let scrollTop = window.pageYOffset
    let clientHeight = window.document.documentElement.clientHeight
    let bodyHeight = window.document.body.clientHeight

    if (clientHeight + scrollTop > bodyHeight - 200) {
      if (endApiHit) return window.removeEventListener("scroll", handleScroll)
      setPages((pages) => pages + 1)
    }
  }
  useEffect(() => {
    if (orgDetails?.mode && orgDetails?.mode !== 'org') return navigate('/org/lists/#all')
  }, [navigate, orgDetails])
  useEffect(() => {
    getFunctions.managePath()
    if (orgDetails?._id) getFunctions.manageApiCall({ orgid: orgDetails?._id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, orgDetails?._id])
  useEffect(() => {
    if (orgDetails?.userid === token) getFunctions.getCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgDetails?.userid, token])
  useEffect(() => {
    setIsCreateShow(
      orgDetails?.userid === token ||
        orgDetails?.isMember[0]?.status === "active"
        ? true
        : false
    )
  }, [orgDetails?.userid, orgDetails?.isMember, token])
  useEffect(() => {
    const hitStatusChange = {
      start: () => {
        setEndApiHit(false)
        window.addEventListener("scroll", handleScroll)
      },
      stop: () => {
        setEndApiHit(true)
        window.removeEventListener("scroll", handleScroll)
      }
    }
    if (currentPage?.parent === 'post/')
      posts?.length > 0 && posts?.length < posts[0]?.totalValue ?
        hitStatusChange.start() : hitStatusChange.stop()
    if (currentPage?.parent === 'photos/')
      photos?.length > 0 && photos?.length < photos[0]?.totalValue ?
        hitStatusChange.start() : hitStatusChange.stop()
    if (currentPage?.parent === 'videos/')
      videos?.length > 0 && videos?.length < videos[0]?.totalValue ?
        hitStatusChange.start() : hitStatusChange.stop()
    if (currentPage?.parent === 'followers/')
      followers?.length > 0 && followers?.length < orgDetails?.totalFollowers ?
        hitStatusChange.start() : hitStatusChange.stop()
    if (/members/.test(currentPage?.parent))
      members?.members?.length > 0 && members?.members?.length < members?.responseDetails?.totalMember ?
        hitStatusChange.start() : hitStatusChange.stop()

    return () => window.removeEventListener("scroll", handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts, photos, videos, followers, members])
  useEffect(() => {
    if (pages > 1) getFunctions.manageApiCall({ page: pages, orgid: orgDetails._id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages])

  return (
    <>
      <Kelmet />
      <IndexNavbar />

      <div>
        <OrganizationFrontSection orgDetails={orgDetails} adminCount={adminCount} />

        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="org_tab">
                <Tabs defaultActiveKey="post/"
                  activeKey={currentPage?.parent}
                  id="uncontrolled-tab-example"
                  onSelect={(e) => onLinkClick(e)}
                  className="mb-3"
                >
                  <Tab eventKey="post/"
                    title="Post"
                  >
                    {isCreateShow && (
                      <CreatePost
                        isMember={orgDetails?.isMember[0]?.status === "active"}
                        userinfo={currentUser}
                        spinner={false}
                        pageid={orgDetails?._id}
                        getpost={() => getFunctions?.getPost({ status: path?.hash?.split('#')[2]?.split("/")[0] })}
                      />
                    )}

                    <div className="row justify-content-center">
                      {/* Middle section  */}
                      <div className="col-lg-12 col-md-10">
                        <section id="profile" className="mt-3">
                          {orgDetails?.userid && (
                            <>
                              <OrganizationPosts
                                orgDetails={orgDetails}
                                posts={posts}
                                setSelectedChildPage={setCurrentPage}
                                getpost={() => getFunctions?.getPost({ status: path?.hash?.split('#')[2]?.split("/")[0] })}
                              />
                            </>
                          )}
                        </section>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    eventKey="aboutinstitution/"
                    id="#aboutinstitution"
                    title="About Institution"
                  >
                    <div className="row">
                      <div className="col-sm-7">
                        <OrgAbout
                          orgDetails={orgDetails}
                          getOrgDetails={() => dispatch(getOrganizationDetails(`?name=${orgDetails?.pageName}`))}
                        />

                      </div>
                      <div className="col-sm-5">
                        <div className="all_photos">
                          <div className="d-flex justify-content-between photos_heading">
                            <h5>
                              <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="image"
                                className="svg-inline--fa fa-image "
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path
                                  fill="currentColor"
                                  d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z"
                                ></path>
                              </svg>{" "}
                              All Photos
                            </h5>
                            <div className="seeall" onClick={() => onLinkClick("photos/")}>see all</div>
                          </div>


                          <div className="photos_books">
                            {photos?.length < 1 ? (
                              <h4>No photos found</h4>
                            ) : (
                              photos?.map((item, index) => {
                                console.log(item)
                                return (
                                  <>
                                    <Link
                                      to={`/p/${item?._id}`}
                                      className="br_1"
                                      style={{
                                        backgroundImage: `url(${item?.postInfo[0]?.postImagePath ||
                                          "/assets/web_img/choto_logo_1.png"
                                          })`,
                                      }}
                                    ></Link>
                                  </>
                                )
                              })
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey="followers/" title="Followers">
                    <div className="cmn_box">
                      <div className="row">
                        {followers?.length > 0 && followers?.map((items) => {
                          return (<>
                            <div className="col-lg-2 col-md-4 col-12 kk mt-3" key={items?._id + 'fPro'}>
                              <div className="follow_m_box">

                                <img
                                  height="200px"
                                  width="100%"
                                  src={items?.profilePhoto || "/assets/web_img/choto_logo_1.png"}
                                  alt="none"
                                />

                                <div className="text-center py-2">
                                  <a href={`/${items?.userName}/`} >{items?.showName}</a>
                                </div>
                                <div className='px-2 pb-2'>
                                  <a href={`/${items?.userName}/`} className='btn btn-primary w-100 mb-2'>View Profile</a>
                                </div>
                              </div>
                            </div>
                          </>)
                        })
                        }

                      </div>
                      {/* <OrgFollowers orgDetails={orgDetails} /> */}
                    </div>
                  </Tab>
                  <Tab eventKey="photos/" title="Photos">
                    <div className="cmn_box">
                      <div className="row mb-4">
                        <div className="masonry_wrapper">
                          <div className="masonry">
                            {photos?.length < 1 ? (
                              <h1>No post found</h1>
                            ) : photos?.map((item, index) => (
                              <div className="item text-center mb-3">
                                <div className="item_in">
                                  <Link to={`/p/${item?._id}`} className="mpd_1 gall_hover" key={item?._id + index}>
                                    <img className="img-responsive"
                                      src={`${item?.postInfo[0]?.postImagePath}?w=248&fit=crop&auto=format`}
                                      // srcSet={`${item?.postInfo[0]?.postImagePath}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                      alt={item?.postInfo[0]?.postImagePath}
                                      loading="lazy"
                                    />
                                  </Link>
                                </div>
                              </div>
                            )
                            )}
                          </div>
                        </div>

                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="videos/" title="Videos">
                    <div className="cmn_box row">
                      {videos.length < 1 ?
                        <h1>No post found</h1>
                        :
                        videos?.map((item, index) => (
                          <>
                            <div className="col-lg-3"
                              key={item?._id + index}
                            >
                              <Link
                                to={`/p/${item?._id}`}
                                className="col-lg-4 col-md-4 col-6 mpd_1 mt-3 gall_hover"
                              >
                                {/youtube/.test(item?.embedType) ? <YouTubeEmbed
                                  url={item?.url}
                                  width={"100%"}
                                  height={320}
                                /> :
                                  <FileViewer
                                    link={item?.postInfo[0]?.postImagePath}
                                  />
                                }

                              </Link>
                            </div>
                          </>
                        ))

                      }
                    </div>
                  </Tab>
                  <Tab eventKey="members/" title="Our Members">
                    <div className="cmn_box">
                      <OrgAllMembers
                        orgDetails={orgDetails}
                        setSelectedChildPage={setCurrentPage}
                        members={members}
                      // getMembers={getFunctions.getOrgMembers({orgid: orgDetails?._id, status: path?.hash?.split('#')[2]?.split("/")[0]})}
                      />
                    </div>
                  </Tab>
                  {/* {orgDetails?.userid === token && */}
                  <Tab eventKey="event/" title="Private Post">
                    <div className="row justify-content-center">
                      <div className="col-lg-10 mb-5">
                        <CreateEvent
                          pageid={orgDetails?._id}
                          orgDetails={orgDetails}
                          isOwner={orgDetails?.userid === token ? true : false}
                        />
                      </div>
                    </div>
                  </Tab>
                  {/* } */}
                  {orgDetails?.userid === token &&
                    <Tab eventKey="storage/" title="Storage">
                      <div className="row justify-content-center">
                        <div className="col-lg-10 mb-5">
                          <StorageAnalysis
                            getData={getFunctions.getStorage}
                            pageid={orgDetails?._id}
                            storage={storage?.storage}
                            storegePosts={storage?.posts}
                            storageSubscriptions={storage?.subscriptions}
                          />
                        </div>
                      </div>
                    </Tab>
                  }
                  {orgDetails?.userid === token &&
                    <Tab eventKey="analytics/" title="Analytics">
                      <div className="row justify-content-center">
                        <div className="col-lg-10 mb-5">
                          <div className="bg-white p-4 pt-2 pb-2 mb-4">
                            <div class="row">


                              <div className='col-sm-12 text-center mt-4'>
                                <h1>Org Subscription Analytics</h1>
                                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse pellentesque egestas felis nec tempus.
                                  Suspendisse nibh nunc, aliquet non felis non, sagittis tincidunt mi. In malesuada purus blandit, efficitur urna porta, vulputate magna.
                                  </p> */}
                                <ul className="analytics_list text-center">
                                  <li className="analytics_list_li border-primary">
                                    <h4 className="mt-4 text-primary"><b>Subscribed</b></h4>
                                    <div className="analytics_number bg-primary text-white w-50 h-50 rounded-circle"><h3>{orgAnl?.subDetails?.length}</h3></div>
                                  </li>
                                  <li className="analytics_list_li border-success">
                                    <h4 className="mt-4 text-success"><b>Active</b></h4>
                                    <div className="analytics_number bg-success text-white w-50 h-50 rounded-circle"><h3>{orgAnl?.active}</h3></div>
                                  </li>
                                  <li className="analytics_list_li border-danger">
                                    <h4 className="mt-4 text-danger"><b>Expired</b></h4>
                                    <div className="analytics_number bg-danger text-white w-50 h-50 rounded-circle"><h3>
                                      {orgAnl?.subDetails?.length - orgAnl?.active}
                                    </h3></div>
                                  </li>

                                  <li className="analytics_list_li border-info">
                                    <h4 className="mt-4 text-info"><b>Revenue</b></h4>
                                    <div className="analytics_number bg-info text-white w-50 h-50 rounded-circle">
                                      <h3 className="last-child">
                                        <small className="d-block"><b>Rs</b></small>
                                        {orgAnl?.subDetails?.length * orgDetails?.monthlySubscriptionPrice}
                                      </h3>
                                    </div>
                                  </li>
                                </ul>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  }

                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      {!token && <LoginStatus showname={currentUser?.showName} />}
      <Footer />
    </>
  )
}

OrgHtml.loadData = (params, search) => async (dispatch) => {
  //   const query = queryParse(search)
  console.log("params>>>", params)
  const name = params?.split("/")[2]
  return dispatch(await getOrganizationDetails(`?name=${name}`))
}
const mapStateToProps = (state) => {
  console.log("stateoRgpost", state)
  const { isSuccess, isError, members, adminCount, data, posts, photos, videos, followers, isProcessing, storage, orgAnl } =
    state.organization
  return {
    orgAnl,
    isSuccess,
    isError,
    orgDetails: data,
    photos: photos,
    videos: videos,
    posts: posts,
    adminCount: adminCount,
    isProcessing,
    followers,
    members,
    storage
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOrgPosts: params => dispatch(getOrganizationPosts(params)),
    getOrgStorageAnalytics: params => dispatch(OrgStorageDetails(params))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrgHtml)
