import React, { forwardRef, useState } from 'react'
import Stack from '@mui/material/Stack'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function CustomizedSnackbars({ type, msg, sclose }) {
    console.log("ami hit")
    const [open, setOpen] = useState(true)

    // const handleClick = () => {
    //     setOpen(true)
    // }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpen(false)
        if(sclose) sclose()
    }

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>

            {type === 0 &&
                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>

                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        {msg}
                    </Alert>

                </Snackbar>
            }
            {type === 1 &&
                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>

                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {msg}
                    </Alert>
                </Snackbar>

            }

            {type === 2 &&
                <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>

                    <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                        {msg}
                    </Alert>
                </Snackbar>

            }
            {/* <Alert severity="error">This is an error message!</Alert>
            <Alert severity="warning">This is a warning message!</Alert>
            <Alert severity="info">This is an information message!</Alert>
            <Alert severity="success">This is a success message!</Alert> */}
        </Stack>
    )
}
