import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
// import Login from "./Login"
import { Footer, IndexNavbar } from "../../components";
import WinnersModal from "../../components/Modals/WinnersModal";
import Carousel from "react-bootstrap/Carousel";
import HomeLogin from "./HomeLogin";
import {
  FileViewerHome,
  FileViewerc,
} from "../../components/AppComponents/Helpers";
import { connect } from "react-redux";
import { getHomeData } from "../../redux/reducers/postReducer";
import { Testmonials } from "./Testimonials";
import { Link } from "react-router-dom";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      small
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="container-fluid home_popup">
          <div className="c_pointer" onClick={props.onHide}>
            <div className="c_pointer close_section">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="xmark"
                className="svg-inline--fa fa-xmark post_icons"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                ></path>
              </svg>
            </div>
          </div>
          {props?.token ? (
            <></>
          ) : (
            <>
              <div className="popup_hedign text-center">
                <h4 className="mb-0">
                  For more videos please
                  <Link
                    to="https://allphanes.com/auth/registration/"
                    className="text-success"
                  >
                    {" "}
                    <u>Sign in</u>
                  </Link>
                </h4>
                <small className="text-success">
                  <b>(Sign in is free)</b>
                </small>
              </div>
            </>
          )}
          <div className="col-sm-12 popup_image_section">
            {props?.singlePost?.postInfo ? <>
              <FileViewerc 
              key={props?.singlePost?._id}
                link={props?.singlePost?.postInfo[0]?.postImagePath}
              />
            </>
              
            : (
              "No post found"
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Home(props) {
  // const  { isSuccess, isError, isProcessing, homePromotionPost, winners } = props
  const { winners, homePromotionPost } = props;
  let token, currentUser;
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShow2, setModalShow2] = React.useState(false);
  const [singlePost, setSinglePost] = useState({});

  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
    // eslint-disable-next-line no-unused-vars
    currentUser = JSON.parse(window.localStorage.getItem("currentuser"));
  }
  const handlePostModalShow = (item) => {
    setModalShow(true);
    setSinglePost(item);
  };

  return (
    <main>
      <IndexNavbar />
      {/* <div class="zoom-in-out-box">
        <Link
          to={`/presents/`}
        >
          <img
            className="img-responsive"
            src="../assets/web_img/rabindra_home.png"
            alt=""
          />
        </Link>
      </div> */}
      <div className="container new_home">
        <div className="row">
          <div className="col-md-8">
            <div className="our_client new_banner_slider">
              <div className="new_banner_img">
                <img
                  className="img-responsive"
                  src="../assets/web_img/banner_small_img.png"
                  alt=""
                />
              </div>
              <Carousel controls={true} indicators={true} fade>
                <Carousel.Item>
                  <div className="col-md-6 col-sm-6 new_banner_text">
                    <h2>Platform</h2>
                    <p>
                      We are a Creator media platform but NOT another Tik Tok !!
                      Our community is focused on creative arts from South Asian
                      and regional cultures from all over the world.
                    </p>
                    <div className="cmn-new_btn">
                      <a href={token ? "/mycanvas" : "/auth/login"}>
                        Create a Post
                        <img src="/assets/web_img/arow_new.png" alt="sound" />
                      </a>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="col-md-6 col-sm-6 new_banner_text">
                    <h2>Reward</h2>
                    <p>
                      You don't need a Gazillion Fans to get rewarded !! The
                      best posts will be rewarded and recognized - no need to
                      wait for hundreds of fans and thousands of views.
                    </p>
                    <div className="cmn-new_btn">
                      <a href={token ? "/mycanvas" : "/auth/login"}>
                        Create a Post
                        <img src="/assets/web_img/arow_new.png" alt="sound" />
                      </a>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="col-md-6 col-sm-6 new_banner_text">
                    <h2>Your Work </h2>
                    <p>
                      Showcase your work without renting an Art Gallery or
                      appearing on stage !! Build a portfolio of your creative
                      work for talent hunters to see and recruit you
                    </p>
                    <div className="cmn-new_btn">
                      <a href={token ? "/mycanvas" : "/auth/login"}>
                        Create a Post
                        <img src="/assets/web_img/arow_new.png" alt="sound" />
                      </a>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="col-md-6 col-sm-6 new_banner_text">
                    <h2>New Creators</h2>
                    <p>
                      We bet on new creators, because that's where all
                      celebrities started !! Winners of our monthly contests get
                      promoted on all our Creator media platforms
                    </p>
                    <div className="cmn-new_btn">
                      <a href={token ? "/mycanvas" : "/auth/login"}>
                        Create a Post
                        <img src="/assets/web_img/arow_new.png" alt="sound" />
                      </a>
                    </div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="col-md-6 col-sm-6 new_banner_text">
                    <h2>Looking for a Guru</h2>
                    <p>
                      Looking for a Guru for creative arts ? Connect with our
                      creative community to learn from them by becoming friends,
                      followers or fans.
                    </p>
                    <div className="cmn-new_btn">
                      <a href={token ? "/mycanvas" : "/auth/login"}>
                        Create a Post
                        <img src="/assets/web_img/arow_new.png" alt="sound" />
                      </a>
                    </div>
                  </div>
                </Carousel.Item>
              </Carousel>
              <div
                className="banner_bottom_bar"
                style={{
                  backgroundImage: `url(${"../assets/web_img/banner_bottom_bar.png"})`,
                }}
              >
                <div className="row over_text">
                  <div className="col-md-5 col-xs-5">
                    <h2>150%+</h2>
                    <p>Growth in first 2 months</p>
                  </div>
                  <div className="col-md-5 col-xs-5">
                    <h2>9000+</h2>
                    <p>Views in 2 months</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="home_login m-0 mobile_login">
              {token ? (
                <div className="subscription_section">
                  <img
                    className="img-responsive small_img"
                    src="../assets/web_img/make_monney.png"
                    alt=""
                  />
                  <h5>Make Money</h5>
                  <p>Multiple ways to make money:</p>
                  <ul className="make-money-list m-0 p-0">
                    <li
                      style={{
                        backgroundImage: `url(${"/assets/web_img/arrow.png"})`,
                      }}
                    >
                      Winning contests
                    </li>

                    <li
                      style={{
                        backgroundImage: `url(${"/assets/web_img/arrow.png"})`,
                      }}
                    >
                      {" "}
                      Views of your content in the premium bundled service
                    </li>

                    <li
                      style={{
                        backgroundImage: `url(${"/assets/web_img/arrow.png"})`,
                      }}
                    >
                      Subscriptions to your content
                    </li>
                    <li
                      style={{
                        backgroundImage: `url(${"/assets/web_img/arrow.png"})`,
                      }}
                    >
                      Selling your services in the Creator Marketplace
                    </li>
                    <li
                      style={{
                        backgroundImage: `url(${"/assets/web_img/arrow.png"})`,
                      }}
                    >
                      Get Paid for Your Content now!
                    </li>
                  </ul>
                </div>
              ) : (
                <>
                  <h4 className="mt-4">Login</h4>
                  <HomeLogin />
                </>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div className="our_client">
              <Carousel controls={true} indicators={true} fade>
                {Testmonials?.map((item, index) => (
                  <Carousel.Item key={index}>
                    <div className="client_testimonial">
                      <h1 className="m-0">Our Members Say</h1>
                      <img
                        className="img-responsive"
                        src="../assets/web_img/cotetion.png"
                        alt=""
                      />
                      <p className="review_descrip">{item?.review}</p>
                      <div
                        className="client_profile_img"
                        style={{
                          backgroundImage: `url(${
                            item?.photo || "/assets/web_img/choto_logo_1.png"
                          })`,
                        }}
                      >
                        <div className="client_name">
                          <p>{item?.name}</p>
                          <small>{item?.designation}</small>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
            {/* <div className="december_winners mt-3">
              <div className="new_about_text">
                <h2>
                  {moment().subtract(1, "month").format("MMMM")} 2023 Winners
                </h2>
              </div>
              <div className="december_winners_img_box">
                <div className="d-flex">
                  {winners?.length > 0 &&
                    winners?.map((item, index) => {
                      return (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <a
                          key={item?._id}
                          href={"#"}
                          variant="primary"
                          onClick={() => setModalShow2(true)}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${item?.userid?.profilePhoto ||
                                "/assets/web_img/choto_logo_1.png"
                                })`,
                            }}
                          ></div>
                        </a>
                      );
                    })}
                </div>
              </div>
            </div> */}
          </div>
          <div className="col-sm-12">
            <div className="row mb-4 ">
              <div className="col-sm-12 new_about_text">
                <h2>Some of our member's posts</h2>
              </div>
            </div>
            <div className="row">
              {homePromotionPost?.map((item, index) => (
                <>
                  <div
                    className="col-md-2 col-sm-12"
                    onClick={() => handlePostModalShow(item)}
                    key={item?._id}
                  >
                    <div className="bg-white p-2 mb-2 rounded home_video">
                      <div>
                        <FileViewerHome
                          link={item?.postInfo[0]?.postImagePath}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="row">
              <div className="col-md-7 col-sm-12 ">
                <div className="new_about_text">
                  <h2>What is Allphanes</h2>

                  <p>
                    It is an online network of creators from different domains.
                    Creators can upload their own creations (video, audio,
                    write-ups, music, cooking, gardening). They can share their
                    contents with their friends or public in this Creator Media
                    for Creators.
                  </p>
                  <div className="cmn-new_btn">
                    <a href="/about-us">
                      About Us
                      <img src={"/assets/web_img/arow_new.png"} alt="sound" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-sm-12 ">
                <div className="videos_new">
                  <video
                    height="205px"
                    width="100%"
                    controls
                    controlsList="nodownload"
                  >
                    <source src={"/assets/videos/Edit2.mp4"} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div className="home_login m-0 desktop_login">
              {token ? (
                <div className="subscription_section">
                  <img
                    className="img-responsive small_img"
                    src="../assets/web_img/make_monney.png"
                    alt=""
                  />
                  <h5>Make Money</h5>
                  <p>Multiple ways to make money:</p>
                  <ul className="make-money-list m-0 p-0">
                    <li
                      style={{
                        backgroundImage: `url(${"/assets/web_img/arrow.png"})`,
                      }}
                    >
                      Winning contests
                    </li>

                    <li
                      style={{
                        backgroundImage: `url(${"/assets/web_img/arrow.png"})`,
                      }}
                    >
                      {" "}
                      Views of your content in the premium bundled service
                    </li>

                    <li
                      style={{
                        backgroundImage: `url(${"/assets/web_img/arrow.png"})`,
                      }}
                    >
                      Subscriptions to your content
                    </li>
                    <li
                      style={{
                        backgroundImage: `url(${"/assets/web_img/arrow.png"})`,
                      }}
                    >
                      Selling your services in the Creator Marketplace
                    </li>
                    <li
                      style={{
                        backgroundImage: `url(${"/assets/web_img/arrow.png"})`,
                      }}
                    >
                      Get Paid for Your Content now!
                    </li>
                  </ul>
                </div>
              ) : (
                <>
                  <h4 className="mt-4">Login</h4>
                  <HomeLogin />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <MyVerticallyCenteredModal
        token={token}
        show={modalShow}
        onHide={() => setModalShow(false)}
        singlePost={singlePost}
      />

      <>
        <MyVerticallyCenteredModal
          token={token}
          show={modalShow}
          onHide={() => setModalShow(false)}
          singlePost={singlePost}
        />

        {modalShow2 && (
          <WinnersModal show={modalShow2} close={() => setModalShow2(false)} />
        )}
      </>
      <Footer winners={winners} />
    </main>
  );
}

// export default Home
Home.loadData = (params, search) => async (dispatch) => {
  return dispatch(getHomeData());
};
const mapStateToProps = (state) => {
  console.log("homeState", state);
  const { isSuccess, isError, isProcessing, homePromotionPost, winners } =
    state.posts;
  return {
    isSuccess,
    isError,
    isProcessing,
    homePromotionPost: homePromotionPost,
    winners: winners,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
