// 0:image, 1:video, 2:audio
export const extentions = {
    jpg: 0,
    JPG: 0,
    png: 0,
    apng: 0,
    avif: 0,
    jpeg: 0,
    webp: 0,
    gif: 0,
    jfif: 0,
    mp4: 1,
    mov: 1,
    m4v: 1,
    m4a: 1,
    mp3: 2,
    avi: 1,
    mpeg: 1,
    aac: 2,
    wav:2,
    flac:2,
    alac:2,
    dsd:2,
    ogg:2,
    heic: 0,
};

export const CheckExtention = (link) => {
    const k = link.slice((Math.max(0, link.lastIndexOf(".")) || Infinity) + 1);
    const v = k.toLowerCase();
    return extentions[v];
};  

const seoData = {
    "/": {
        title: "Allphanes | Home",
        link: "https://allphanes.com/",
    },
    "/terms": {
        title: "Allphanes | Terms",
        link: "https://allphanes.com/terms",
    },
    "/help-center": {
        title: "Allphanes | Help",
        link: "https://allphanes.com/help-center",
    },
    "/about-us": {
        title: "Allphanes | About",
        link: "https://allphanes.com/about-us",
    },
    "/privacy": {
        title: "Allphanes | Privacy",
        link: "https://allphanes.com/privacy",
    },
    "/contact-us": {
        title: "Allphanes | Contact-us",
        link: "https://allphanes.com/contact-us",
    },
    "/mycanvas": {
        title: "Allphanes | Mycanvas",
        link: "https://allphanes.com/mycanvas",
    },
    "/creations": {
        title: "Allphanes | Creations",
        link: "https://allphanes.com/creations",
    },
    "/members": {
        title: "Allphanes | Members",
        link: "https://allphanes.com/members",
    },
    "/friends-list": {
        title: "Allphanes | Friends-list",
        link: "https://allphanes.com/friends-list",
    },
    "/request-list": {
        title: "Allphanes | Request-list",
        link: "https://allphanes.com/request-list",
    },
    "/sent-list": {
        title: "Allphanes | Sent-list",
        link: "https://allphanes.com/sent-list",
    },
    "/settings/basic": {
        title: "Allphanes | Basic Settings",
        link: "https://allphanes.com/settings/basic",
    },
    "/settings/account": {
        title: "Allphanes | Account Settings",
        link: "https://allphanes.com/settings/account",
    },
    "/settings/referral": {
        title: "Allphanes | Referral Settings",
        link: "https://allphanes.com/settings/referral",
    },
};

export const homePosts = [
    {
        _id: "63b7025fd327424338af0492",
        referenceUserId: "62f01e372a4e291ec5797269",
        uploadType: "org",
        postTitle: "Samparka | Bengali Short Film | Title Song",
        postType: "Normal",
        postMode: "Normal",
        isSaved: "false",
        postDescription: "Samparka | Bengali Short Film | Title Song",
        postCategory: "Video",
        postSubDomain: "Song",
        language: "Bengali",
        keywords: "samparka bengalishortfilm titlesong video",
        keywords2: "#samparka #bengalishortfilm #titlesong #video",
        isShare: false,
        embedType: "normal",
        numOfFavorites: 0,
        totalViews: 45,
        status: "active",
        gallerType: "video",
        createdAt: "2023-01-05T17:01:19.249Z",
        updatedAt: "2023-01-10T07:33:27.009Z",
        __v: 0,
        lastViewedAt: "2023-01-10T07:33:27.012Z",
        user_info: [
            {
                _id: "62f01e372a4e291ec5797269",
                userName: "nabin",
                showName: "nabin",
                defaultLanguage: ["English", "Bengali", "Hindi"],
                profilePhoto:
                    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-08-07T20%3A56%3A03.641Z-3007580-38f-478a-8f88-1170cb45df0c-1659905758853.jpg",
            },
        ],
        org_info: [],
        user_likes: [
            {
                _id: "63b71fc3d327424338af06f7",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "63b7025fd327424338af0492",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2023-01-05T19:06:43.226Z",
                updatedAt: "2023-01-05T19:06:43.226Z",
                __v: 0,
            },
            {
                _id: "63b7c5f5d327424338af0b98",
                referenceUserId: "63620efe27f4ec3e7ea05762",
                referencePostId: "63b7025fd327424338af0492",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2023-01-06T06:55:49.666Z",
                updatedAt: "2023-01-06T06:55:49.666Z",
                __v: 0,
            },
            {
                _id: "63b7d16bd327424338af0c72",
                referenceUserId: "634907644703bd66fa747583",
                referencePostId: "63b7025fd327424338af0492",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2023-01-06T07:44:43.485Z",
                updatedAt: "2023-01-06T07:44:43.485Z",
                __v: 0,
            },
            {
                _id: "63b7d1d8d327424338af0c80",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "63b7025fd327424338af0492",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2023-01-06T07:46:32.971Z",
                updatedAt: "2023-01-06T07:46:32.971Z",
                __v: 0,
            },
            {
                _id: "63b7fc55d327424338af0dbb",
                referenceUserId: "6384b81173f8170799235857",
                referencePostId: "63b7025fd327424338af0492",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2023-01-06T10:47:49.029Z",
                updatedAt: "2023-01-06T10:47:49.029Z",
                __v: 0,
            },
            {
                _id: "63b802ded327424338af0ec1",
                referenceUserId: "63abcba0dc6e28653e85b7e9",
                referencePostId: "63b7025fd327424338af0492",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2023-01-06T11:15:42.163Z",
                updatedAt: "2023-01-06T11:15:42.163Z",
                __v: 0,
            },
            {
                _id: "63b82748d327424338af0fc9",
                referenceUserId: "62bd8689200665908086adf5",
                referencePostId: "63b7025fd327424338af0492",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2023-01-06T13:51:04.126Z",
                updatedAt: "2023-01-06T13:51:04.126Z",
                __v: 0,
            },
            {
                _id: "63b87c74d327424338af18b3",
                referenceUserId: "6380f091d68ec6263b8eb8a6",
                referencePostId: "63b7025fd327424338af0492",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2023-01-06T19:54:28.617Z",
                updatedAt: "2023-01-06T19:54:28.617Z",
                __v: 0,
            },
            {
                _id: "63bc009ed327424338af574f",
                referenceUserId: "62bab0cbee5e0cbd88fe2ad3",
                referencePostId: "63b7025fd327424338af0492",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2023-01-09T11:55:10.968Z",
                updatedAt: "2023-01-09T11:55:10.968Z",
                __v: 0,
            },
        ],
        user_comment: [
            {
                _id: "63b72073d327424338af06ff",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "63b7025fd327424338af0492",
                messageText: "Khub sundor title track ta. Movie ta Kothai pabo?",
                replyCommentCount: 1,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-05T19:09:39.150Z",
                updatedAt: "2023-01-06T14:37:30.627Z",
                __v: 0,
            },
            {
                _id: "63b7c603d327424338af0ba0",
                referenceUserId: "63620efe27f4ec3e7ea05762",
                referencePostId: "63b7025fd327424338af0492",
                messageText: "Sundor gan ",
                replyCommentCount: 1,
                replyLikeCount: 2,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-06T06:56:03.770Z",
                updatedAt: "2023-01-06T14:37:39.804Z",
                __v: 0,
            },
            {
                _id: "63b7d167d327424338af0c6a",
                referenceUserId: "634907644703bd66fa747583",
                referencePostId: "63b7025fd327424338af0492",
                messageText: "Awesome",
                replyCommentCount: 1,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-06T07:44:39.470Z",
                updatedAt: "2023-01-06T14:37:52.125Z",
                __v: 0,
            },
            {
                _id: "63b7d1ead327424338af0c88",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "63b7025fd327424338af0492",
                messageText: "Khub sundor",
                replyCommentCount: 1,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-06T07:46:50.815Z",
                updatedAt: "2023-01-06T14:38:03.246Z",
                __v: 0,
            },
            {
                _id: "63b7fc6ed327424338af0dcb",
                referenceUserId: "6384b81173f8170799235857",
                referencePostId: "63b7025fd327424338af0492",
                messageText: "very nice",
                replyCommentCount: 1,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-06T10:48:14.654Z",
                updatedAt: "2023-01-06T14:38:17.299Z",
                __v: 0,
            },
            {
                _id: "63b802e9d327424338af0edb",
                referenceUserId: "63abcba0dc6e28653e85b7e9",
                referencePostId: "63b7025fd327424338af0492",
                messageText: "Nice",
                replyCommentCount: 1,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-06T11:15:53.666Z",
                updatedAt: "2023-01-06T14:38:27.465Z",
                __v: 0,
            },
            {
                _id: "63bc00b3d327424338af5757",
                referenceUserId: "62bab0cbee5e0cbd88fe2ad3",
                referencePostId: "63b7025fd327424338af0492",
                messageText: "Nice",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-09T11:55:31.599Z",
                updatedAt: "2023-01-09T11:55:31.599Z",
                __v: 0,
            },
            {
                _id: "63bd001ad327424338af5a34",
                referenceUserId: "62ec97c4d4dffaa77b596249",
                referencePostId: "63b7025fd327424338af0492",
                messageText: "good one",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-10T06:05:14.890Z",
                updatedAt: "2023-01-10T06:05:14.890Z",
                __v: 0,
            },
        ],
        postInfo: [
            {
                _id: "63b7025fd327424338af0494",
                referenceUserId: "62f01e372a4e291ec5797269",
                referencePostId: "63b7025fd327424338af0492",
                postImagePath:
                    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/comp/2023-01-05T16%253A59%253A48.863Z-Samparka%2Ballp_1.mp4",
                gallerType: "video",
                isActive: false,
                isTrash: 0,
                status: false,
                createdAt: "2023-01-05T17:01:19.270Z",
                updatedAt: "2023-01-05T17:01:19.270Z",
                __v: 0,
            },
        ],
        isFav: [],
        IsCommented: [],
        isLiked: [],
        totalComment: 8,
        totalLikes: 9,
        rankings: 4.85,
    },
    {
        _id: "639f0801dc6e28653e84e96c",
        referenceUserId: "634fdb2fe666de9574d0def7",
        uploadType: "org",
        postTitle: "Quick Post",
        postType: "Normal",
        postMode: "Normal",
        isSaved: "false",
        postDescription: "",
        postCategory: "Visual Art",
        postSubDomain: "Painting ",
        language: "",
        keywords: "",
        keywords2: "",
        isActive: true,
        isTrash: false,
        isAllow: true,
        isShare: false,
        embedType: "normal",
        NumOfLike: 0,
        NumOfComment: 0,
        numOfFavorites: 0,
        status: "Active",
        gallerType: "photos",
        createdAt: "2022-12-18T12:30:57.644Z",
        updatedAt: "2023-01-10T07:40:26.239Z",
        __v: 0,
        lastViewedAt: "2023-01-10T07:40:26.240Z",
        totalViews: 6,
        user_info: [
            {
                _id: "634fdb2fe666de9574d0def7",
                userName: "ritasardar",
                showName: "ritasardar",
                defaultLanguage: ["English"],
                profilePhoto:
                    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-10-19T14%3A11%3A18.480Z-cf8c-84ec-ae2d-e4cb-ff4dcd41d37-1666188674542.jpg",
            },
        ],
        org_info: [],
        user_likes: [
            {
                _id: "639f09b2dc6e28653e84edb2",
                referenceUserId: "63270ab4e25f571b1c628c70",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-18T12:38:10.703Z",
                updatedAt: "2022-12-18T12:38:10.703Z",
                __v: 0,
            },
            {
                _id: "639f0a14dc6e28653e84ee32",
                referenceUserId: "62b06ab3d42c246a80e0c363",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-18T12:39:48.733Z",
                updatedAt: "2022-12-18T12:39:48.733Z",
                __v: 0,
            },
            {
                _id: "639f0b6bdc6e28653e84f0fd",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-18T12:45:31.249Z",
                updatedAt: "2022-12-18T12:45:31.249Z",
                __v: 0,
            },
            {
                _id: "639f0e8adc6e28653e84f1b9",
                referenceUserId: "62f9055cd3264acfd45d82fb",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-18T12:58:50.856Z",
                updatedAt: "2022-12-18T12:58:52.025Z",
                __v: 0,
            },
            {
                _id: "639f1e3bdc6e28653e84f780",
                referenceUserId: "639f1c1ddc6e28653e84f470",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-18T14:05:47.653Z",
                updatedAt: "2022-12-18T14:05:47.653Z",
                __v: 0,
            },
            {
                _id: "639f27a0dc6e28653e84f942",
                referenceUserId: "630e01da5a91e82d11d169e2",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-18T14:45:52.877Z",
                updatedAt: "2022-12-18T14:45:52.877Z",
                __v: 0,
            },
            {
                _id: "639f3976dc6e28653e850062",
                referenceUserId: "63620efe27f4ec3e7ea05762",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-18T16:01:58.475Z",
                updatedAt: "2022-12-18T16:01:58.475Z",
                __v: 0,
            },
            {
                _id: "639ffd0ddc6e28653e8526ef",
                referenceUserId: "634907644703bd66fa747583",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-19T05:56:29.667Z",
                updatedAt: "2022-12-19T05:56:29.667Z",
                __v: 0,
            },
            {
                _id: "63a00432dc6e28653e8527d7",
                referenceUserId: "62ace243d84cb463dd4ef1fa",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-19T06:26:58.824Z",
                updatedAt: "2022-12-19T06:26:58.824Z",
                __v: 0,
            },
            {
                _id: "63a00803dc6e28653e852ef7",
                referenceUserId: "62bf1bfe200665908086b28e",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-19T06:43:15.995Z",
                updatedAt: "2022-12-19T06:43:15.995Z",
                __v: 0,
            },
            {
                _id: "63a02536dc6e28653e854917",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-19T08:47:50.754Z",
                updatedAt: "2022-12-19T08:47:50.754Z",
                __v: 0,
            },
            {
                _id: "63a02a1bdc6e28653e854c80",
                referenceUserId: "62b42d24ec5362d521118a73",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-19T09:08:43.366Z",
                updatedAt: "2022-12-19T09:08:43.366Z",
                __v: 0,
            },
            {
                _id: "63a15908dc6e28653e855998",
                referenceUserId: "6391cda07a97d7a5ff0abc78",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-20T06:41:12.702Z",
                updatedAt: "2022-12-20T06:41:12.702Z",
                __v: 0,
            },
            {
                _id: "63a159dadc6e28653e8559cc",
                referenceUserId: "6384b81173f8170799235857",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-20T06:44:42.131Z",
                updatedAt: "2022-12-20T06:44:42.131Z",
                __v: 0,
            },
            {
                _id: "63a837b0dc6e28653e85878d",
                referenceUserId: "631d93fb3078f2154f902bfe",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-25T11:44:48.090Z",
                updatedAt: "2022-12-25T11:44:48.090Z",
                __v: 0,
            },
            {
                _id: "63ac07e7dc6e28653e85bc9d",
                referenceUserId: "635b5d7b41f231df93d8f444",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-28T09:09:59.924Z",
                updatedAt: "2022-12-28T09:09:59.924Z",
                __v: 0,
            },
            {
                _id: "63b05a95dc6e28653e85e689",
                referenceUserId: "62b8614aa21a99fc7852b035",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-31T15:51:49.639Z",
                updatedAt: "2022-12-31T15:51:49.639Z",
                __v: 0,
            },
            {
                _id: "63b6d235d327424338af03c1",
                referenceUserId: "63660b395c034183340b5d6e",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2023-01-05T13:35:49.331Z",
                updatedAt: "2023-01-05T13:35:49.331Z",
                __v: 0,
            },
        ],
        user_comment: [
            {
                _id: "639f09dadc6e28653e84eddc",
                referenceUserId: "63270ab4e25f571b1c628c70",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "e to sotti sriparna ",
                replyCommentCount: 0,
                replyLikeCount: 3,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-18T12:38:50.463Z",
                updatedAt: "2022-12-18T15:32:21.758Z",
                __v: 0,
            },
            {
                _id: "639f0b9adc6e28653e84f113",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "Eta to sotti sotti ami ",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-18T12:46:18.233Z",
                updatedAt: "2022-12-18T14:18:12.046Z",
                __v: 0,
            },
            {
                _id: "639f0bfddc6e28653e84f13e",
                referenceUserId: "62b06ab3d42c246a80e0c363",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText:
                    "It feels like my mother's dream is getting wings! Real talent on real platform!!! So so so proud of you lil Sohan!!! Akdin line a darie tor autograph nebo! ei ashirbad roilo!!!",
                replyCommentCount: 0,
                replyLikeCount: 2,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-18T12:47:57.047Z",
                updatedAt: "2022-12-19T12:01:28.840Z",
                __v: 0,
            },
            {
                _id: "639f0e9adc6e28653e84f1cd",
                referenceUserId: "62f9055cd3264acfd45d82fb",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "Beautiful ",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-18T12:59:06.399Z",
                updatedAt: "2022-12-19T03:21:49.992Z",
                __v: 0,
            },
            {
                _id: "639f1db8dc6e28653e84f705",
                referenceUserId: "62ea65da226ad4a4594154b7",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "good ",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-18T14:03:36.689Z",
                updatedAt: "2022-12-18T14:27:35.148Z",
                __v: 0,
            },
            {
                _id: "639f27bbdc6e28653e84f951",
                referenceUserId: "630e01da5a91e82d11d169e2",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "Really wonderful masterpiece...",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-18T14:46:19.550Z",
                updatedAt: "2022-12-18T14:46:25.576Z",
                __v: 0,
            },
            {
                _id: "639f3c97dc6e28653e850191",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText:
                    "এইটা ছবি না , সান্তাক্লজ এর দেয়া আমার এই বছরের শ্রেষ্ঠ উপহার। যে এঁকেছে সে সম্পর্কে আমার দৌহত্র। ১৩ বছর বয়েস। আশীর্বাদ করি অনেক বড়ো হও , তোমার আঁকা যেন আন্তর্জাতিক মান ছুটে পারে। গগনচুম্বী হোক তোমার প্রতিভা।",
                replyCommentCount: 0,
                replyLikeCount: 2,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-18T16:15:19.693Z",
                updatedAt: "2022-12-19T08:46:34.436Z",
                __v: 0,
            },
            {
                _id: "639ffdf0dc6e28653e852728",
                referenceUserId: "634907644703bd66fa747583",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "Really Talented .Very beautiful.",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-19T06:00:16.423Z",
                updatedAt: "2022-12-19T06:00:16.423Z",
                __v: 0,
            },
            {
                _id: "63a00837dc6e28653e852f35",
                referenceUserId: "62bf1bfe200665908086b28e",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "Wooooow darunnnnn ... ",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-19T06:44:07.075Z",
                updatedAt: "2022-12-19T06:44:07.075Z",
                __v: 0,
            },
            {
                _id: "63a02526dc6e28653e854907",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "Wow.... Excellent  ",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-19T08:47:34.717Z",
                updatedAt: "2022-12-19T08:47:34.717Z",
                __v: 0,
            },
            {
                _id: "63a15a0ddc6e28653e8559e0",
                referenceUserId: "6384b81173f8170799235857",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "Sotti e khub sundor",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-20T06:45:33.960Z",
                updatedAt: "2022-12-20T06:45:33.960Z",
                __v: 0,
            },
            {
                _id: "63b6d23dd327424338af03c9",
                referenceUserId: "63660b395c034183340b5d6e",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "Awesome ",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-05T13:35:57.439Z",
                updatedAt: "2023-01-05T13:35:57.439Z",
                __v: 0,
            },
            {
                _id: "63b6d25dd327424338af03d9",
                referenceUserId: "63660b395c034183340b5d6e",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "Awesome ",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-05T13:36:29.696Z",
                updatedAt: "2023-01-05T13:36:29.696Z",
                __v: 0,
            },
            {
                _id: "63bade50d327424338af3e28",
                referenceUserId: "62da49db83ef314bb1ae930b",
                referencePostId: "639f0801dc6e28653e84e96c",
                messageText: "So sweet",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2023-01-08T15:16:32.089Z",
                updatedAt: "2023-01-08T15:16:32.089Z",
                __v: 0,
            },
        ],
        postInfo: [
            {
                _id: "639f0801dc6e28653e84e96e",
                referenceUserId: "634fdb2fe666de9574d0def7",
                referencePostId: "639f0801dc6e28653e84e96c",
                postImagePath:
                    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-12-18T12%3A30%3A57.550Z-20221218_175212.jpg",
                gallerType: "photos",
                isActive: false,
                isTrash: 0,
                status: false,
                createdAt: "2022-12-18T12:30:57.653Z",
                updatedAt: "2022-12-18T12:30:57.653Z",
                __v: 0,
            },
        ],
        isFav: [],
        IsCommented: [],
        isLiked: [
            {
                _id: "63a02a1bdc6e28653e854c80",
                referenceUserId: "62b42d24ec5362d521118a73",
                referencePostId: "639f0801dc6e28653e84e96c",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-19T09:08:43.366Z",
                updatedAt: "2022-12-19T09:08:43.366Z",
                __v: 0,
            },
        ],
        totalComment: 14,
        totalLikes: 18,
        rankings: 5.3,
    },
    {
        _id: "63051a6e9da1f306a0f30aa2",
        referenceUserId: "6304f0394f3652bee9487164",
        postTitle: "Independence Day Special Dance",
        postType: "Normal",
        postMode: "Normal",
        isSaved: "false",
        postDescription: "",
        postCategory: "Video",
        postSubDomain: "Dance",
        language: "English",
        isActive: true,
        isTrash: false,
        countViews: 0,
        shareCount: 0,
        flagCount: 0,
        isShare: false,
        NumOfLike: 8,
        NumOfComment: 3,
        numOfFavorites: 2,
        createdAt: "2022-08-23T18:20:30.082Z",
        updatedAt: "2023-01-10T07:38:02.462Z",
        __v: 0,
        lastViewedAt: "2023-01-10T07:38:02.465Z",
        totalViews: 15,
        isAllow: true,
        uploadType: "normal",
        status: "Active",
        user_info: [
            {
                _id: "6304f0394f3652bee9487164",
                userName: "sohinikundu",
                showName: "Sohinikundu",
                defaultLanguage: ["English"],
                profilePhoto:
                    "https://allphanes-bucket.s3.amazonaws.com/2022-08-23T18%3A14%3A29.054Z-8cca7b7-ecc-46a-c7d-da5e0f3a54c-1661278463135.jpg",
            },
        ],
        org_info: [],
        user_likes: [
            {
                _id: "63051ce49da1f306a0f30de9",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-08-23T18:31:00.096Z",
                updatedAt: "2022-08-23T18:31:00.096Z",
                __v: 0,
            },
            {
                _id: "6305ba559da1f306a0f31260",
                referenceUserId: "62b18b34fdc243c444cbaeb1",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-08-24T05:42:45.671Z",
                updatedAt: "2022-08-24T05:42:45.671Z",
                __v: 0,
            },
            {
                _id: "63051cd99da1f306a0f30dda",
                referenceUserId: "6304f7a74f3652bee94873d1",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-08-23T18:30:49.673Z",
                updatedAt: "2022-08-23T18:30:49.673Z",
                __v: 0,
            },
            {
                _id: "6308cd33748cf7c7b4a057c8",
                referenceUserId: "62b42d24ec5362d521118a73",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-08-26T13:40:03.607Z",
                updatedAt: "2022-08-26T13:40:03.607Z",
                __v: 0,
            },
            {
                _id: "630a41fd5a91e82d11d140e9",
                referenceUserId: "630a411c5a91e82d11d13f05",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-08-27T16:10:37.524Z",
                updatedAt: "2022-08-27T16:10:37.524Z",
                __v: 0,
            },
            {
                _id: "6305679c9da1f306a0f30f2d",
                referenceUserId: "62b8614aa21a99fc7852b035",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-08-23T23:49:48.717Z",
                updatedAt: "2022-08-25T01:43:52.805Z",
                __v: 0,
            },
            {
                _id: "6305c9509da1f306a0f317d1",
                referenceUserId: "62f0ac0c2a4e291ec57976c1",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-08-24T06:46:40.475Z",
                updatedAt: "2022-08-24T06:46:40.475Z",
                __v: 0,
            },
            {
                _id: "63123a99c2c8d0711e9b5095",
                referenceUserId: "630f29a8e31f7273dbc1349a",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-09-02T17:17:13.169Z",
                updatedAt: "2022-09-02T17:17:13.169Z",
                __v: 0,
            },
            {
                _id: "634934184703bd66fa74a4a3",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-10-14T10:04:08.567Z",
                updatedAt: "2022-10-14T10:04:16.350Z",
                __v: 0,
            },
        ],
        user_comment: [
            {
                _id: "6305c9309da1f306a0f317b2",
                referenceUserId: "62f0ac0c2a4e291ec57976c1",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                messageText: "Nyce",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-08-24T06:46:08.249Z",
                updatedAt: "2022-08-27T06:33:58.584Z",
                __v: 0,
            },
            {
                _id: "6305ba049da1f306a0f31240",
                referenceUserId: "62b18b34fdc243c444cbaeb1",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                messageText: "Beautiful",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-08-24T05:41:24.089Z",
                updatedAt: "2022-08-27T06:33:57.165Z",
                __v: 0,
            },
            {
                _id: "630567ed9da1f306a0f30f36",
                referenceUserId: "62b8614aa21a99fc7852b035",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                messageText:
                    "Beautiful !! Appropriate for 75th Independence Day of India !!",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-08-23T23:51:09.186Z",
                updatedAt: "2022-08-27T06:33:55.848Z",
                __v: 0,
            },
            {
                _id: "634b538a4703bd66fa75737b",
                referenceUserId: "62b8614aa21a99fc7852b035",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                messageText: "Look forward to your next post. ",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-10-16T00:42:50.855Z",
                updatedAt: "2022-10-16T00:42:50.855Z",
                __v: 0,
            },
        ],
        postInfo: [
            {
                _id: "63051a6e9da1f306a0f30aa4",
                referenceUserId: "6304f0394f3652bee9487164",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                postImagePath:
                    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-08-23T18%3A20%3A29.040Z-VID-20210813-WA0001.mp4",
                gallerType: "video",
                isActive: false,
                isTrash: 0,
                status: false,
                createdAt: "2022-08-23T18:20:30.112Z",
                updatedAt: "2022-08-23T18:20:30.112Z",
                __v: 0,
            },
        ],
        isFav: [],
        IsCommented: [],
        isLiked: [
            {
                _id: "6308cd33748cf7c7b4a057c8",
                referenceUserId: "62b42d24ec5362d521118a73",
                referencePostId: "63051a6e9da1f306a0f30aa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-08-26T13:40:03.607Z",
                updatedAt: "2022-08-26T13:40:03.607Z",
                __v: 0,
            },
        ],
        totalComment: 4,
        totalLikes: 9,
        rankings: 2.95,
    },
    {
        _id: "638c44e8c78ec6dc5bc17fa2",
        referenceUserId: "63634ba95c034183340af90f",
        uploadType: "normal",
        postTitle: "Medley - Sandhya Mukhopadhyay",
        postType: "Normal",
        postMode: "Normal",
        isSaved: "false",
        postDescription:
            "I have tried putting together a few songs by respected Sandhya Mukhopadhyay\r\n\r\nplease do let me know what you think of the rendition. I hope you like it! Happy Listening!",
        postCategory: "Music",
        postSubDomain: "Adhunik",
        language: "Bengali",
        keywords: "sandhyamukhopadhyay bangla adhunik",
        keywords2: "#sandhyamukhopadhyay #bangla #adhunik",
        isActive: true,
        isTrash: false,
        countViews: 0,
        shareCount: 0,
        flagCount: 0,
        totalViews: 64,
        isAllow: true,
        isShare: false,
        NumOfLike: 0,
        NumOfComment: 0,
        numOfFavorites: 0,
        gallerType: "video",
        createdAt: "2022-12-04T06:57:45.001Z",
        updatedAt: "2023-01-10T07:38:56.842Z",
        __v: 0,
        lastViewedAt: "2023-01-10T07:38:56.843Z",
        status: "Active",
        user_info: [
            {
                _id: "63634ba95c034183340af90f",
                userName: "dhritichattopadhyay",
                showName: "dhritichattopadhyay",
                defaultLanguage: ["English", "Hindi", "Bengali"],
                profilePhoto:
                    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-12-03T06%3A07%3A10.921Z-7174087-f23b-aac7-f8c6-5cc4d0b213-1670047629339.jpg",
            },
        ],
        org_info: [],
        user_likes: [
            {
                _id: "638c45a8c78ec6dc5bc181cb",
                referenceUserId: "6304e4544f3652bee9486dfb",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-04T07:00:56.304Z",
                updatedAt: "2022-12-04T07:00:56.304Z",
                __v: 0,
            },
            {
                _id: "638c45aec78ec6dc5bc181e1",
                referenceUserId: "63878d44c8e976ef45938841",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-04T07:01:02.668Z",
                updatedAt: "2022-12-04T07:01:02.668Z",
                __v: 0,
            },
            {
                _id: "638c45edc78ec6dc5bc18208",
                referenceUserId: "63877b06c8e976ef459385dc",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-04T07:02:05.357Z",
                updatedAt: "2022-12-04T07:02:05.357Z",
                __v: 0,
            },
            {
                _id: "638c4630c78ec6dc5bc18255",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-04T07:03:12.960Z",
                updatedAt: "2022-12-04T07:03:12.960Z",
                __v: 0,
            },
            {
                _id: "638c4d9ac78ec6dc5bc182c9",
                referenceUserId: "6380f091d68ec6263b8eb8a6",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-04T07:34:50.203Z",
                updatedAt: "2022-12-04T07:34:50.203Z",
                __v: 0,
            },
            {
                _id: "638c7045c78ec6dc5bc183b4",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-04T10:02:45.577Z",
                updatedAt: "2022-12-04T10:02:45.577Z",
                __v: 0,
            },
            {
                _id: "638c9e6cc78ec6dc5bc183f5",
                referenceUserId: "63620efe27f4ec3e7ea05762",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-04T13:19:40.395Z",
                updatedAt: "2022-12-04T13:19:40.395Z",
                __v: 0,
            },
            {
                _id: "638ccc1bc78ec6dc5bc1848c",
                referenceUserId: "62b8614aa21a99fc7852b035",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-04T16:34:35.138Z",
                updatedAt: "2022-12-04T16:34:35.138Z",
                __v: 0,
            },
            {
                _id: "638d7d01c78ec6dc5bc18ad8",
                referenceUserId: "634907644703bd66fa747583",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-05T05:09:21.180Z",
                updatedAt: "2022-12-19T06:20:10.952Z",
                __v: 0,
            },
            {
                _id: "638d8402c78ec6dc5bc18fe7",
                referenceUserId: "635b5d7b41f231df93d8f444",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-05T05:39:14.944Z",
                updatedAt: "2022-12-05T05:39:14.944Z",
                __v: 0,
            },
            {
                _id: "638d84a8c78ec6dc5bc19060",
                referenceUserId: "63660b395c034183340b5d6e",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-05T05:42:00.610Z",
                updatedAt: "2022-12-05T05:42:00.610Z",
                __v: 0,
            },
            {
                _id: "638f3f4cc78ec6dc5bc1aee3",
                referenceUserId: "62b42916ec5362d521118969",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-06T13:10:36.218Z",
                updatedAt: "2022-12-06T13:10:36.218Z",
                __v: 0,
            },
            {
                _id: "639392c5603411302f7f14e0",
                referenceUserId: "639391aa603411302f7f12bb",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-09T19:55:49.907Z",
                updatedAt: "2022-12-09T19:55:49.907Z",
                __v: 0,
            },
            {
                _id: "63981dabd956a5ea1e031b62",
                referenceUserId: "62bf1bfe200665908086b28e",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-12-13T06:37:31.574Z",
                updatedAt: "2022-12-13T06:37:31.574Z",
                __v: 0,
            },
        ],
        user_comment: [
            {
                _id: "638c4646c78ec6dc5bc18284",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                messageText: "Beautiful collage ",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-04T07:03:34.058Z",
                updatedAt: "2022-12-04T07:03:34.058Z",
                __v: 0,
            },
            {
                _id: "638c704dc78ec6dc5bc183bc",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                messageText: "Very nice ",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-04T10:02:53.744Z",
                updatedAt: "2022-12-04T10:02:53.744Z",
                __v: 0,
            },
            {
                _id: "638ccc7cc78ec6dc5bc184b0",
                referenceUserId: "62b8614aa21a99fc7852b035",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                messageText: "Beautiful medley of music. Thanks for sharing.",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-04T16:36:12.954Z",
                updatedAt: "2022-12-04T16:36:12.954Z",
                __v: 0,
            },
            {
                _id: "638d7d04c78ec6dc5bc18ae0",
                referenceUserId: "634907644703bd66fa747583",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                messageText: "Awesome",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-05T05:09:24.438Z",
                updatedAt: "2022-12-05T05:09:24.438Z",
                __v: 0,
            },
            {
                _id: "63a72534dc6e28653e858173",
                referenceUserId: "62ec97c4d4dffaa77b596249",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                messageText: "Tanpura are tabular pitch Milche na. Pls check",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-24T16:13:40.402Z",
                updatedAt: "2022-12-24T16:13:40.402Z",
                __v: 0,
            },
        ],
        postInfo: [
            {
                _id: "638c44e9c78ec6dc5bc17fa4",
                referenceUserId: "63634ba95c034183340af90f",
                referencePostId: "638c44e8c78ec6dc5bc17fa2",
                postImagePath:
                    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-12-04T06%3A57%3A44.540Z-Video.Guru_20221204_122228439.mp4",
                gallerType: "video",
                isActive: false,
                isTrash: 0,
                status: false,
                createdAt: "2022-12-04T06:57:45.011Z",
                updatedAt: "2022-12-04T06:57:45.011Z",
                __v: 0,
            },
        ],
        isFav: [],
        IsCommented: [],
        isLiked: [],
        totalComment: 5,
        totalLikes: 14,
        rankings: 6.5,
    },
    {
        _id: "63629b6127f4ec3e7ea06ae9",
        referenceUserId: "636280e627f4ec3e7ea0638e",
        postTitle: "Quick Post",
        postType: "Normal",
        postMode: "Normal",
        isSaved: "false",
        postDescription: "",
        postCategory: "Music",
        postSubDomain: "Song",
        language: "Hindi",
        keywords: "",
        keywords2: "",
        isActive: true,
        isTrash: false,
        countViews: 0,
        shareCount: 0,
        flagCount: 0,
        totalViews: 53,
        isShare: false,
        NumOfLike: 0,
        NumOfComment: 0,
        numOfFavorites: 0,
        createdAt: "2022-11-02T16:31:29.540Z",
        updatedAt: "2023-01-10T07:40:10.949Z",
        __v: 0,
        lastViewedAt: "2023-01-10T07:40:10.950Z",
        isAllow: true,
        uploadType: "normal",
        status: "Active",
        user_info: [
            {
                _id: "636280e627f4ec3e7ea0638e",
                userName: "dipankar",
                showName: "dipankar",
                defaultLanguage: ["English", "Bengali", "Hindi"],
                profilePhoto: "",
            },
        ],
        org_info: [],
        user_likes: [
            {
                _id: "6362a67127f4ec3e7ea06ca8",
                referenceUserId: "63620efe27f4ec3e7ea05762",
                referencePostId: "63629b6127f4ec3e7ea06ae9",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-02T17:18:41.049Z",
                updatedAt: "2022-11-02T17:18:41.049Z",
                __v: 0,
            },
            {
                _id: "6362b14b27f4ec3e7ea06d1e",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "63629b6127f4ec3e7ea06ae9",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-02T18:04:59.909Z",
                updatedAt: "2022-11-16T08:37:45.329Z",
                __v: 0,
            },
            {
                _id: "6363412e5c034183340af6b6",
                referenceUserId: "62fb81480a18293df7f329d1",
                referencePostId: "63629b6127f4ec3e7ea06ae9",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-03T04:18:54.167Z",
                updatedAt: "2022-11-03T04:18:54.167Z",
                __v: 0,
            },
            {
                _id: "636366235c034183340afe14",
                referenceUserId: "634907644703bd66fa747583",
                referencePostId: "63629b6127f4ec3e7ea06ae9",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-03T06:56:35.016Z",
                updatedAt: "2022-11-07T11:16:35.685Z",
                __v: 0,
            },
            {
                _id: "636367d45c034183340affd3",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "63629b6127f4ec3e7ea06ae9",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-03T07:03:48.877Z",
                updatedAt: "2022-11-03T07:03:48.877Z",
                __v: 0,
            },
            {
                _id: "636ce3bf9d4cc10dd788ded5",
                referenceUserId: "62b42916ec5362d521118969",
                referencePostId: "63629b6127f4ec3e7ea06ae9",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-10T11:42:55.146Z",
                updatedAt: "2022-11-10T11:42:55.146Z",
                __v: 0,
            },
        ],
        user_comment: [
            {
                _id: "6362b19927f4ec3e7ea06ec8",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "63629b6127f4ec3e7ea06ae9",
                messageText: "Onekdin pore sunlam ganta. Bhalo thakben ",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-11-02T18:06:17.241Z",
                updatedAt: "2022-11-02T18:06:17.241Z",
                __v: 0,
            },
            {
                _id: "6362da5927f4ec3e7ea070bc",
                referenceUserId: "62b8614aa21a99fc7852b035",
                referencePostId: "63629b6127f4ec3e7ea06ae9",
                messageText: "Very nice and heartfelt. Thanks for sharing.",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-11-02T21:00:09.997Z",
                updatedAt: "2022-11-02T21:00:09.997Z",
                __v: 0,
            },
            {
                _id: "636341285c034183340af6ad",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "63629b6127f4ec3e7ea06ae9",
                messageText: "Bah...gaan ta shune khub valo laglo",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-11-03T04:18:48.856Z",
                updatedAt: "2022-11-03T04:18:48.856Z",
                __v: 0,
            },
        ],
        postInfo: [
            {
                _id: "63629b6127f4ec3e7ea06aeb",
                referenceUserId: "636280e627f4ec3e7ea0638e",
                referencePostId: "63629b6127f4ec3e7ea06ae9",
                postImagePath:
                    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-11-02T16%3A31%3A28.935Z-VID-20220923-WA0076.mp4",
                gallerType: "video",
                isActive: false,
                isTrash: 0,
                status: false,
                createdAt: "2022-11-02T16:31:29.552Z",
                updatedAt: "2022-11-02T16:31:29.552Z",
                __v: 0,
            },
        ],
        isFav: [],
        IsCommented: [],
        isLiked: [],
        totalComment: 3,
        totalLikes: 6,
        rankings: 4.15,
    },
    {
        _id: "63660fc85c034183340b623b",
        referenceUserId: "63660b395c034183340b5d6e",
        postTitle: "Quick Post",
        postType: "Normal",
        postMode: "Normal",
        isSaved: "false",
        postDescription: "",
        postCategory: "Visual Art",
        postSubDomain: "Painting ",
        language: "",
        keywords: "",
        keywords2: "",
        isActive: true,
        isTrash: false,
        countViews: 0,
        shareCount: 0,
        flagCount: 0,
        totalViews: 50,
        isShare: false,
        NumOfLike: 0,
        NumOfComment: 0,
        numOfFavorites: 0,
        createdAt: "2022-11-05T07:24:56.032Z",
        updatedAt: "2023-01-10T07:40:22.908Z",
        __v: 0,
        lastViewedAt: "2023-01-10T07:40:22.909Z",
        isAllow: true,
        uploadType: "normal",
        status: "Active",
        user_info: [
            {
                _id: "63660b395c034183340b5d6e",
                userName: "apurwa",
                showName: "apurwa",
                defaultLanguage: ["English"],
                profilePhoto:
                    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-12-02T08%3A54%3A13.033Z-b5f34d6-1e70-716e-3bac-f68a063e71b0-1669971245171.jpg",
            },
        ],
        org_info: [],
        user_likes: [
            {
                _id: "636630205c034183340b6dd7",
                referenceUserId: "62df095ac93ec7cb485371cb",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-05T09:42:56.915Z",
                updatedAt: "2022-11-05T09:42:56.915Z",
                __v: 0,
            },
            {
                _id: "63666cf95c034183340b6fc7",
                referenceUserId: "62b8614aa21a99fc7852b035",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-05T14:02:33.974Z",
                updatedAt: "2022-11-05T14:05:36.489Z",
                __v: 0,
            },
            {
                _id: "6366b2dc5c034183340b7399",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-05T19:00:44.572Z",
                updatedAt: "2022-11-05T19:00:44.572Z",
                __v: 0,
            },
            {
                _id: "6367817d5c034183340b7859",
                referenceUserId: "631d93fb3078f2154f902bfe",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-06T09:42:21.766Z",
                updatedAt: "2022-11-06T09:42:21.766Z",
                __v: 0,
            },
            {
                _id: "6367b62d5c034183340b7ffb",
                referenceUserId: "633740a46ab6265b2aa939bf",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-06T13:27:09.612Z",
                updatedAt: "2022-11-06T13:27:09.612Z",
                __v: 0,
            },
            {
                _id: "6368b88c5c034183340bad80",
                referenceUserId: "6367ff5e5c034183340b9090",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-07T07:49:32.344Z",
                updatedAt: "2022-11-07T07:49:32.344Z",
                __v: 0,
            },
            {
                _id: "6368f8ef5c034183340bb42c",
                referenceUserId: "62acdb2c662a8545551be9e4",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-07T12:24:15.083Z",
                updatedAt: "2022-11-07T12:24:15.083Z",
                __v: 0,
            },
            {
                _id: "636b8a155c034183340be9ed",
                referenceUserId: "634907644703bd66fa747583",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-09T11:08:05.610Z",
                updatedAt: "2022-11-09T11:08:05.610Z",
                __v: 0,
            },
            {
                _id: "636ce3989d4cc10dd788deb8",
                referenceUserId: "62b42916ec5362d521118969",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-10T11:42:16.409Z",
                updatedAt: "2022-11-10T11:42:16.409Z",
                __v: 0,
            },
            {
                _id: "637f521cd68ec6263b8e79b4",
                referenceUserId: "635b5d7b41f231df93d8f444",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-24T11:14:36.462Z",
                updatedAt: "2022-11-24T11:14:36.462Z",
                __v: 0,
            },
            {
                _id: "6386fbac73f81707992384c5",
                referenceUserId: "62b42d24ec5362d521118a73",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-30T06:43:56.696Z",
                updatedAt: "2022-11-30T06:43:56.696Z",
                __v: 0,
            },
        ],
        user_comment: [
            {
                _id: "636630a55c034183340b6e12",
                referenceUserId: "62df095ac93ec7cb485371cb",
                referencePostId: "63660fc85c034183340b623b",
                messageText: "Krishna's Basuri.",
                replyCommentCount: 1,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-11-05T09:45:09.263Z",
                updatedAt: "2022-12-05T14:59:56.358Z",
                __v: 0,
            },
            {
                _id: "63666d4e5c034183340b6fcf",
                referenceUserId: "62b8614aa21a99fc7852b035",
                referencePostId: "63660fc85c034183340b623b",
                messageText:
                    "Amazing !! You can feel the softness of his gaze. Thanks for sharing your talent.",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-11-05T14:03:58.662Z",
                updatedAt: "2022-12-05T15:01:01.200Z",
                __v: 0,
            },
            {
                _id: "636781835c034183340b7865",
                referenceUserId: "631d93fb3078f2154f902bfe",
                referencePostId: "63660fc85c034183340b623b",
                messageText: "Excleent",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-11-06T09:42:27.046Z",
                updatedAt: "2022-11-11T01:20:30.652Z",
                __v: 0,
            },
            {
                _id: "6368d6555c034183340bb0bb",
                referenceUserId: "635b5d7b41f231df93d8f444",
                referencePostId: "63660fc85c034183340b623b",
                messageText: "Excellent.",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-11-07T09:56:37.528Z",
                updatedAt: "2022-11-11T01:20:24.768Z",
                __v: 0,
            },
            {
                _id: "636b8a345c034183340bea00",
                referenceUserId: "634907644703bd66fa747583",
                referencePostId: "63660fc85c034183340b623b",
                messageText: "wow!",
                replyCommentCount: 0,
                replyLikeCount: 1,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-11-09T11:08:36.171Z",
                updatedAt: "2022-11-11T01:20:20.110Z",
                __v: 0,
            },
            {
                _id: "637f591bd68ec6263b8e8069",
                referenceUserId: "6349051d4703bd66fa747117",
                referencePostId: "63660fc85c034183340b623b",
                messageText: "Beautiful",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-11-24T11:44:27.610Z",
                updatedAt: "2022-11-24T11:44:27.610Z",
                __v: 0,
            },
            {
                _id: "638e07c5c78ec6dc5bc19dc6",
                referenceUserId: "63660b395c034183340b5d6e",
                referencePostId: "63660fc85c034183340b623b",
                messageText: "Thanks everyone ",
                replyCommentCount: 0,
                replyLikeCount: 0,
                IsTrash: false,
                IsActive: true,
                Status: true,
                createdAt: "2022-12-05T15:01:25.049Z",
                updatedAt: "2022-12-05T15:01:25.049Z",
                __v: 0,
            },
        ],
        postInfo: [
            {
                _id: "63660fc85c034183340b623d",
                referenceUserId: "63660b395c034183340b5d6e",
                referencePostId: "63660fc85c034183340b623b",
                postImagePath:
                    "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-11-05T07%3A24%3A55.979Z-IMG-20210101-WA0045.jpg",
                gallerType: "photos",
                isActive: false,
                isTrash: 0,
                status: false,
                createdAt: "2022-11-05T07:24:56.039Z",
                updatedAt: "2022-11-05T07:24:56.039Z",
                __v: 0,
            },
        ],
        isFav: [],
        IsCommented: [],
        isLiked: [
            {
                _id: "6386fbac73f81707992384c5",
                referenceUserId: "62b42d24ec5362d521118a73",
                referencePostId: "63660fc85c034183340b623b",
                dateTime: null,
                isLike: true,
                isShare: false,
                isComment: false,
                status: false,
                createdAt: "2022-11-30T06:43:56.696Z",
                updatedAt: "2022-11-30T06:43:56.696Z",
                __v: 0,
            },
        ],
        totalComment: 7,
        totalLikes: 11,
        rankings: 5.4,
    },
];

const variables = {
    seoData,
};

export default variables;
