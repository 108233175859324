import React from "react";
import { Footer, IndexNavbar } from "../../components";

const Presents = (props) => {
  return (
    <>
      <IndexNavbar />
      <div class="img-responsive w-100">
        <img
          src="/assets/web_img/presents_img.jpg"
          alt="banner"
          class="img-responsive w-100"
        />
      </div>
      <Footer />
    </>
  );
};

export default Presents;
