import React from 'react'

function CardLoader() {
    return (

        <div className="card-loader card-loader--tabs"></div>

    )
}

export default CardLoader