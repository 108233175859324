import React from "react";
// import background_bg from "../..";
import { Footer, IndexNavbar } from "../../components";

function Terms() {
	return (
		<>
			<IndexNavbar />
			<div
				id="background_bg"
				style={{ backgroundImage: `url("/assets/web_img/background_bg.jpg")` }}
			>
				<div className="terms container">
					<h1>Allphanes Terms of Service</h1>
					<div className="cmn_box">
						<h2 className="">Introduction</h2>
						<p className="mt-2">
							Welcome to Allphanes !! Please read the terms of the service and understand it. Non compliance may lead to termination  of your account.
						</p>

						<h6 className="mt-3">Service we provide</h6>
						<p className="mt-2">
							Allpanes enables registered members to upload and share video, music, photos, digital art, written content,
							comments, links. Membership is free. Some members may pay for a one time pay-per-view (PPV) service or
							subscribe for all of their favorite creators offers for a monthly subscription. Creators will be compensated
							based on the revenue from PPV or subscription. Members can create an organization page to promote their organization
							or creations. These organizations are in no way affiliated with Allphanes and any content posted or commitments made
							by the organizations or creators on Allphanes site are the responsibility of the organization or creator. Allphanes
							will not be liable for any such content or commitments.
						</p>

						<h6 className="mt-3">Service provider</h6>
						<p className="mt-2">
							Allphanes Inc, Texas, USA and its subsidiary Allphanes India Pvt. Ltd, Kolkata, India
						</p>

						<h6 className="mt-3">Applicable terms</h6>
						<p className="mt-2">
							You may use this service as long as you follow the Guidelines below.
						</p>

						<h6 className="mt-3">Users of the Service</h6>
						<p className="mt-2">
							Members must be older than 13 years old to use this service. Members who are younger than 13 years
							must get permission from parents to use the service. You must be at least 18 years old to purchase a
							service or subscription or have your parents consent. You are responsible for anything that occurs while
							anyone is signed into your account.Please protect your account and report any unauthorized use to
							<a href="mailto:info@allphanes.com"> info@allphanes.com</a> as soon as possible.
						</p>

						<h6 className="mt-3">Guidelines</h6>
						<p className="mt-2">
							<ul>
								<li>
									<h6>
										Content uploaded or links shared must meet following guidelines:
									</h6>
									<ul>
										<li className="mt-2">
											Content you upload must be original. It should not have been copied from content protected under
											copy right that belongs to someone else unless you have written permissions to do so.
										</li>
										<li className="mt-2">
											No sexually explicit content allowed
										</li>
										<li className="mt-2">
											No nude pictures or genital pictures
										</li>
										<li className="mt-2">o	No profane language should be used</li>
										<li className="mt-2">
											Should not encourage violence, hate, or bad behavior against anyone or any community
										</li>
										<li className="mt-2">
											Should not share content that is false or untrue, unless it is easily identifiable as a
											work of fiction and the authors claim it to be fiction
										</li>
									</ul>
								</li>
								<li className="mt-3">
									<h6>comments</h6>
									<ul>
										<li className="mt-2">Always be polite in comments</li>
										<li className="mt-2">
											Provide inputs about the content not the person posting it
										</li>
										<li className="mt-2">No personal attacks are allowed</li>
										<li className="mt-2">
											No profane language or images or emoji’s should be used
										</li>
									</ul>
								</li>
								<li className="mt-3">
									<h6>Removal of Content, Comments and Accounts</h6>
									<ul>
										<li className="mt-2">
											When we find content that violates these guidelines, it will be removed and you will receive a warning.
										</li>
										<li className="mt-2">
											If you receive 3 warnings in a period of 90 days, your account will be suspended and you
											will not be able to upload new content or post comments or share anything for a period of 90 days.
										</li>
										<li className="mt-2">
											If your account gets suspended three times, your account will be permanently suspended from Allphanes.
										</li>
									</ul>
								</li>
							</ul>
						</p>


						<h6 className="mt-3">Organization Page</h6>
						<ul className="information_col">
							<li className="mt-2">
								Any member can create an organization/premium (referred to hereafter as organization ) page.
								On the organization page members have the option of posting a private/premium post. Such posts
								can be set-up by the organization creator, hence forth referred to as ‘org admin’, with the option
								of pay-per-view (PPV) or monthly or quarterly or annual subscription. Org admin can choose the price
								they want to charge among a few options. Subscriptions have the option to be automatically renewed.
							</li>
							<li className="mt-2">
								Content posted to Organization pages must follow the guidelines and restrictions on Allphanes.
							</li>
							<li className="mt-2">
								Org admins have the authority to accept or reject members in their organization. Allphanes is in no way liable for these decisions.
							</li>
							<li className="mt-2">
								The revenue earned through PPV and monthly subscriptions are credited to the org admin, after applicable fees are deducted.
							</li>
							<li className="mt-2">
								<h6 className="mt-3">The following fees are charged:</h6>
								<ul>
									<li className="mt-2">
										Platform fees: This is generally a percentage of successfully processed payments.
									</li>
									<li className="mt-2">
										Payment processing fees: This is calculated as a percentage of successfully
										processed payments and a flat fee per successful transaction.
									</li>
									<li className="mt-2">
										Payout fee: Fee for transferring money you earned on Allphanes to your specified payout method.
									</li>
									<li className="mt-2">
										Currency conversion fees: When payment is made in a currency that is different from your payout currency there is a fee
										charged based on percentage of successfully processed payments.
									</li>
									<li className="mt-2">
										You can see the revenue and fees for any membership on your profile dashboard.
									</li>

								</ul>
							</li>
							<li className="mt-2">
								<h6 className="mt-3">Taxes:</h6>
								<ul>
									<li className="mt-2">
										We collect tax identification information so that we can fulfil our tax obligations.
										We are responsible for certain transactional taxes. These transactional taxes will be added
										to price of relevant transactions.
									</li>
								</ul>
							</li>
							<li className="mt-2">
								<h6 className="mt-3">Updates to purchases:</h6>
								<ul>
									<li className="mt-2">
										Your subscriptions may increase with notice to you at any time. This allows org admins to adjust
										subscription charges based on increased cost or additional service.
									</li>
									<li className="mt-2">
										You can cancel your free trial or recurring payments at any time.Canceling subscription
										will reduce your access to content you previously had access to.
									</li>
									<li className="mt-2">
										You cannot cancel PPV subscription because it is access for a day.
									</li>
									<li className="mt-2">
										Refunds: We do not provide refunds. There may be some exception based on our sole discretion.
									</li>
								</ul>
							</li>
							<li className="mt-2">
								<h6 className="mt-3">Removal from annual membership:</h6>
								<ul>
									<li className="mt-2">
										Allphanes reserves the right to remove an org admin or a subscriber from annual memberships.
										When this right is removed from an org admin members cannot make further annual subscriptions
										to that org.  Removing a member from annual subscriptions will prohibit that member
										for signing up for further annual memberships with other organizations. This will not remove
										the members account on Allphanes unless there is another reason to do so.
									</li>
								</ul>
							</li>
						</ul>

						<h6 className="mt-3">Your Information</h6>
						<ul className="information_col">
							<li className="mt-2">
								Your personal information will remain confidential, unless you chose to share it based on privacy
								setting provided.
							</li>
							<li className="mt-2">
								We may send you email updates about activities and new features on Allphanes. You can unsubscribe
								from such email if you choose to not get such email.
							</li>
							<li className="mt-2">
								You may choose a name in your profile that will be publicly shared. Please do not share your password with anyone.
							</li>
							<li className="mt-2">
								The content you upload will belong to you not to Allphanes. Should you choose to remove the content
								you can do so at any time, provided there are no subscribers to the content. If you chose to remove
								that content you need to inform your subscribers to terminate their subscription before removing the content.
								Allphanes reserves the right to continue to store your content but not display/share it anymore, should you
								choose to deactivate your account.
							</li>
						</ul>

						<h6 className="mt-3">Rights</h6>
						<ul className="information_col">
							<li className="mt-2">
								While you retain the ownership of content you upload but you do provide Allphanes and its users certain rights.
							</li>
							<li className="mt-2">
								By providing content to the Service you grant Allphanes a worldwide, non-exclusive, royalty-free, sublicensable
								and transferable license to use that Content (including to reproduce, distribute, prepare derivative works,
								display and perform it) in connection with the Service.
							</li>
							<li className="mt-2">
								You also grant each user of Allphanes a worldwide, non-exclusive, royalty-free license to access your
								Content through the Service, and to use that content, including to reproduce, distribute, prepare derivative
								works, display, and perform it, only as enabled by a feature of the Service and not in any other Service.
							</li>
							<li className="mt-2">
								The licenses granted by you continue for a commercially reasonable period of time after you remove your
								Content from the Service.
							</li>
							<li className="mt-2">
								You grant to Allphanes the right to monetize your Content on the Service (and such monetization may
								include displaying ads on or within Content or charging subscription fee for access). This Agreement
								does not entitle you to any payments unless the Service for payment have been setup that will implemented
								in future.
							</li>
						</ul>

						<h6 className="mt-3">You may not use this service to :</h6>
						<ul className="information_col">
							<li className="mt-2">
								Download, distribute, broadcast, display, sell, alter, license or use any part of the Service or its Contents
								except when explicitly authorized by Allphanes or legally permissible by law.
							</li>
							<li className="mt-2">
								Interfere, disable, or fraudulently engage with the Service or attempt to do so.
							</li>
							<li className="mt-2">
								Misuse any reporting, comments, flagging, complaints services including false claims, information and submissions
							</li>
							<li className="mt-2">
								Cause inaccurate measurement of engagement metrics by paying others to increasing engagement metrics or other
								methods that cause inaccurate measurement of genuine engagement
							</li>
							<li className="mt-2">
								Allphanes is a global company based in the United States with operations in other countries.
								Consequently we must comply with economic sanctions and trade restrictions, including those
								implemented by the Office of Foreign Assets Control (OFAC) of the US Department of Treasury.
								So we cannot take part in transactions that involve designated people, places or items that
								come from those places as determined by OFAC.
							</li>
						</ul>

						<h6 className="mt-3">Other Legal Terms</h6>

						<p className="mt-4">Warranty Disclaimer</p>
						<p className="mt-2">
							OTHER THAN AS EXPRESSLY STATED IN THIS AGREEMENT OR AS REQUIRED BY LAW, THE SERVICE IS PROVIDED “AS IS” AND ALLPHANES DOES
							NOT MAKE ANY SPECIFIC COMMITMENTS OR WARRANTIES ABOUT THE SERVICE. FOR EXAMPLE, WE DON’T MAKE ANY WARRANTIES ABOUT:
							(A) THE CONTENT PROVIDED THROUGH THE SERVICE; (B) THE SPECIFIC FEATURES OF THE SERVICE, OR ITS ACCURACY, RELIABILITY,
							AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS; OR (C) THAT ANY CONTENT YOU SUBMIT WILL BE ACCESSIBLE ON THE SERVICE.
						</p>

						<p className="mt-4">Limitation of Liability</p>
						<p className="mt-2">
							EXCEPT AS REQUIRED BY APPLICABLE LAW, ALLPHANES, ITS AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS
							WILL NOT BE RESPONSIBLE FOR ANY LOSS OF PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL, OR ANTICIPATED SAVINGS;
							LOSS OR CORRUPTION OF DATA; INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES CAUSED BY:
							<br />
							<br />
							ERRORS, MISTAKES, OR INACCURACIES ON THE SERVICE;
							<br />
							PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR USE OF THE SERVICE;
							<br />
							ANY UNAUTHORIZED ACCESS TO OR USE OF THE SERVICE;
							<br />
							ANY INTERRUPTION OR CESSATION OF THE SERVICE;
							<br />
							ANY VIRUSES OR MALICIOUS CODE TRANSMITTED TO OR THROUGH THE SERVICE BY ANY THIRD PARTY;
							<br />
							ANY CONTENT WHETHER SUBMITTED BY A USER OR ALLPHANES, INCLUDING YOUR USE OF CONTENT;
							<br />
							<strong>AND/OR</strong>
							<br />
							THE REMOVAL OR UNAVAILABILITY OF ANY CONTENT.
							<br />
							THIS PROVISION APPLIES TO ANY CLAIM, REGARDLESS OF WHETHER THE CLAIM ASSERTED IS BASED ON WARRANTY, CONTRACT, TORT,
							OR ANY OTHER LEGAL THEORY.
							<br />
							ALLPHANES AND ITS AFFILIATES’ TOTAL LIABILITY FOR ANY CLAIMS ARISING FROM OR RELATING TO THE SERVICE IS
							LIMITED TO THE GREATER OF: (A) THE AMOUNT OF REVENUE THAT ALLPHANES HAS PAID TO YOU FROM YOUR USE OF THE
							SERVICE IN THE 12 MONTHS BEFORE THE DATE OF YOUR NOTICE, IN WRITING TO ALLPHANES, OF THE CLAIM; AND (B) USD $500.
						</p>

						<h6 className="mt-4">Indemnity</h6>
						<p className="mt-2">
							To the extent permitted by applicable law, you agree to defend, indemnify and hold harmless Allphanes, its Affiliates,
							officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities,
							costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access
							to the Service; (ii) your violation of any term of this Agreement; (iii) your violation of any third party right,
							including without limitation any copyright, property, or privacy right; or (iv) any claim that your Content caused
							damage to a third party. This defense and indemnification obligation will survive this Agreement and
							your use of the Service.
						</p>

						<h6 className="mt-4">Third-Party Links</h6>
						<p>
							The Service may contain links to third-party websites and online services that are not owned or controlled by Allphanes.
							Allphanes has no control over, and assumes no responsibility for, such websites and online services. Be aware when you leave the Service;
							we suggest you read the terms and privacy policy of each third-party website and online service that you visit.
						</p>

						<h6 className="mt-4">Dispute resolution</h6>
						<p>
							You are encouraged to contact us if you have an issue. If a dispute arises out of these terms or due to your use
							of Allphanes, then the dispute will be resolved in the federal or state courts located in Delaware, USA.
							Both parties agree to the exclusive jurisdiction and venue of the Delaware courts for the purpose of resolving
							the dispute. Delaware law governs these terms, all Allphanes policies and any dispute arising between you
							and Allphanes.
						</p>

						<h6 className="mt-4">Terms Updates</h6>
						<p>
							These terms and any referenced policies and information are the entire agreement between you and us and supersede
							all prior agreements. These terms do not create any joint venture, partnership, employee-employer or
							franchisee-franchiser relationship between you and Allphanes.
							<br />
							<br />
							We may make changes to these terms sometimes. If we deem the changes material, at our sole discretion,
							we will let you know ahead of time before the changes come into effect. Continuing to use Allphanes means
							you accept the new terms or policies.
						</p>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
}

export default Terms;
