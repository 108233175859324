import React, { useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Footer, IndexNavbar } from "../../components"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { connect, useDispatch } from "react-redux"
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { LDS } from "../../components/Loaders/SimpleCircle"


export const PremiumCard = props => {
    const { item, index } = props
    return (
        <div key={item?._id + index} className="col-lg-3 col-md-4 premium_card_wrapper">
            <div className="premium_card_body shadow-sm">
                <Link
                    to={`/premium/${item?.pageName}/#freepost/`}
                >
                    <div
                        className="blog_icon"
                        style={{
                            backgroundImage: `url(${item?.BackgroundImage || item?.organiztionLogo ||
                                "/assets/web_img/choto_logo_1.png"
                                })`,
                        }}
                    ></div>
                </Link>
                <div className="px-2 py-1">
                    <Link className="fs_19 cl_b"
                        to={`/premium/${item?.pageName}/#freepost/`}
                    >
                        {item?.showName || item?.pageName}
                    </Link>
                    <pre className="cl_b short_des">
                        {item?.details}
                    </pre>
                </div>
            </div>
        </div>
    )
}

export const PremiumListNav = prop => {
    const path = useLocation()
    return (
        <div>
            <div className='d-flex member_nav_items'>
                <Link to={"/premium-list/"} className={path.pathname === 'premium-list' ? "active" : ""}>All</Link>
                <Link to={"/premium-list/mypages/"} className={/mypages/.test(path.pathname) ? "active" : ""}>My Premium Pages</Link>
                <Link to={"/premium-list/posts/"} className={/posts/.test(path.pathname) ? "active" : ""}>All Premium Post</Link>
                <Link to={"/premium-list/mypremiumpost/"} className={/mypremiumpost/.test(path.pathname) ? "active" : ""}>My Premium Post</Link>
            </div>
        </div>
    )
}
function PremiumCreators(props) {
    const {
        // orgList,
        premiumList,
        premiumPost
    } = props
    // const dispatch = useDispatch()
    const [inputs, setInputs] = useState({})
    const [key, setKey] = useState("all")
    const [page, setpage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [initialLoad, setInitialLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const path = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const getOrgDetails = {
        all: async (params) => {
            // setHasMore(false)
            if (page > 1) setIsLoading(true)
            const response = await dispatch(getRequestHandler({
                params: {
                    list: true,
                    // mode: 'pre',
                    page: page,
                    ...params
                },
                url: 'getOrganizationDetails',
                action: 'ACTION_GET_PREMIUM_LIST'
            }))
            if (response.data.responseData.length === 0) setHasMore(false)
            if (page > 1) setIsLoading(false)
            if (page < 2) setInitialLoad(false)

            return response
        },

    }

    const handleScroll = (e) => {
        let scrollTop = window.pageYOffset
        let clientHeight = window.document.documentElement.clientHeight
        let bodyHeight = window.document.body.clientHeight

        if (clientHeight + scrollTop > bodyHeight - 100 && hasMore) {
            window.removeEventListener("scroll", handleScroll)
            setpage(prev => prev + 1)
        }
    }

    const onLinkClick = e => {
        setpage(1)
        setKey(e)
        navigate("#" + e)
        setInputs((prev) => ({ ...prev, q: "" }))
    }

    const init = async () => {
        const { hash } = path
        const initialHash = hash && hash.split("#")[1]
        if (initialHash && initialHash !== "") {
            setKey(() => initialHash)
            setpage(1)
            setHasMore(false)
            setInitialLoad(true)
            console.log("hash>>>", initialHash)
        }
        const params = { list: true, multiple: true }
        if (inputs?.q) params.q = inputs?.q
        if ("#" + key === hash || hash === "")
            await getOrgDetails[key]({ page: page })
    }



    const init2 = async () => await getOrgDetails[key]({ page: page, skip: true })


    useEffect(() => {
        if (inputs?.q) return console.log("q changes")
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputs?.q, key])

    useEffect(() => {
        if (premiumList) window.addEventListener("scroll", handleScroll)
        return () => window.removeEventListener("scroll", handleScroll)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [premiumList])

    useEffect(() => {
        if (page > 2) init2()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <>
            <IndexNavbar />
            <section className="organisation_list">
                <div className="container">
                    <div className="row">
                        <div className="d-flex">
                            <div className="col-sm-7">
                                {" "}
                                <h2 className="blog_heading mb-4">Premium Creators</h2>
                            </div>
                            {/* <div className="ms-auto p-2 col-sm-5">
                                <InputGroup className="mb-3 p-2">
                                    <Form.Control
                                        placeholder="Search Organisation"
                                        aria-label="Recipient's username"
                                        aria-describedby="basic-addon2"
                                        name="q"
                                        value={inputs?.q || ""}
                                        onChange={(e) =>
                                            setInputs((prev) => ({
                                                ...prev,
                                                [e.target.name]: e.target.value,
                                            }))
                                        }
                                    />
                                    <Button
                                        variant="btn-light"
                                        id="button-addon2"
                                        className="btn btn-primary"
                                        onClick={handleSearch}
                                    >
                                        Search
                                    </Button>
                                </InputGroup>
                            </div> */}
                        </div>
                    </div>
                    <div className="org_tab">
                        <Tabs
                            defaultActiveKey="all"
                            activeKey={key}
                            id="uncontrolled-tab-example"
                            onSelect={(e) => onLinkClick(e)}
                            className="mb-3"
                        >
                            <Tab eventKey="all" title="All">
                                <div className="container-fluid mb-4">
                                    <div className="row">
                                        {initialLoad ?
                                            <div className="load_body">
                                                <LDS />
                                            </div> :
                                            premiumList?.length > 0 ? (
                                                premiumList?.map((item, index) => (
                                                    <PremiumCard
                                                        item={item}
                                                        index={index}
                                                    />
                                                ))
                                            ) : (
                                                <>
                                                    <h1>No Organisation found</h1>
                                                </>
                                            )
                                        }

                                        {!initialLoad && isLoading &&
                                            <div className="d-flex justify-content-center">
                                                <LDS />
                                            </div>
                                        }
                                    </div>
                                </div>


                            </Tab>
                            <Tab eventKey="mypremium" id="#mypremium" title="My Premium">
                                <div
                                    className="row post_area_infinite h_100v scrollBar"
                                >
                                    {premiumList?.length > 0 ?
                                        premiumList?.map((item, index) =>
                                            <PremiumCard
                                                item={item}
                                                index={index}
                                            />
                                        )
                                        : (
                                            <>
                                                <h1>No Organisation found</h1>
                                            </>
                                        )}
                                </div>
                            </Tab>
                            <Tab eventKey="allpremiumpost" id="#allpremiumpost" title="All Premium Post">
                                <div className="row post_area_infinite h_100v scrollBar"
                                >

                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
PremiumCreators.loadData = (params, search) => async (dispatch) => {
    // const param = { list: true}
    // console.log(key)
    // return dispatch(await getOrganizationDetailsx(param))
}
const mapStateToProps = (state) => {
    console.log("orglist", state)

    const { premiumList, premiumPost } =
        state.premium
    return {

        premiumList,
        premiumPost,
    }
}
const mapDispatchToProps = (dispatch) => {

}
export default connect(mapStateToProps, mapDispatchToProps)(PremiumCreators)
