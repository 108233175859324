import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { getRequestHandler } from '../../redux/reducers/requestHandler.reducer'
import { LDS } from '../../components/Loaders/SimpleCircle'
import { Link } from 'react-router-dom'

const Photos = (props) => {
  const { open, setOpen, user, photos } = props
  const [page, setPage] = useState(1)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [hasMore, sethasMore] = useState(true)
  const [initialLoad, setInitialLoad] = useState(true)

  const init = async () => {
    if (!hasMore) return setLoading(false)
    setLoading(true)
    let req = {
      params: {
        token: window.localStorage.getItem("token"),
        refToken: user?._id,
        status: 'active',
        page: page,
        galltype: 'photos'
      },
      url: 'getPost',
      action: 'ACTION_GET_MYSPACE_STATE',
      state: "photos"
    }
    const response = await dispatch(getRequestHandler(req))
    if (response?.data?.responseData?.length === 0) sethasMore(false)
    setLoading(false)
  }
  const moreData = (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target
    if (offsetHeight + scrollTop > scrollHeight - 10 && !loading) {
      if (hasMore) setPage((prev) => prev + 1)
    }
  }

  useEffect(() => {
    if (photos?.length > 0) setInitialLoad(false)
  }, [photos])

  useEffect(() => {
    if (user?._id) init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (page > 1) init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])
  return (
    <>
      {open &&
        <div className="popup_newmyspace">
          <div className="popup_in"
          onScroll={moreData}
          >
            <div class="c_pointer close_section" onClick={() => setOpen(false)}>
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark post_icons" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
              </path>
              </svg>
            </div>
            <h3 className="mt-2 text-uppercase">All <span className="text-success">Photos</span> </h3>
            <hr />
            {initialLoad ? <LDS /> :

            <section class="my_space">
              <div class="mt-1">
                <div class="row">
                  {photos?.length > 0 && photos?.map((item, index) =>

                    <Link to={`/p/${item?._id}`} key={item?._id + index} class="col-lg-3 col-sm-6">
                      <div class="bg-white my_space_col p-3 pb-2 mb-4 pt-1">
                        <div class="my_space_img-col">
                          <div class="my_space_img_hover p-1 rounded-4">
                            <Link to={`/p/${item?._id}`} class="rounded-circle text-center mt-1 mb-1">
                              <span class="material-symbols-outlined text-white">
                                visibility
                              </span>
                            </Link>
                          </div>
                          <Link to={`/p/${item?._id}`} class="my-space_img_box" style={{
                            backgroundImage: `url(${item?.postInfo?.length > 0 && item?.postInfo[0]?.postImagePath})`
                          }}
                          ></Link>
                        </div>
                        <div class="d-flex mt-2">
                          <div class="flex-grow-1">
                            <a class="text-dark text-decoration-none">
                              <div class="float-start">
                                <span class="material-symbols-outlined text-success me-1">
                                  thumb_up
                                </span>
                              </div> Likes ({item?.totalLikes || 0})
                            </a>
                          </div>
                          <div class="">
                            <a class="text-dark text-decoration-none">
                              <div class="float-start">
                                <span class="material-symbols-outlined text-success me-1">
                                  comment
                                </span>
                              </div> Comments ({item?.totalComments || 0})
                            </a>
                          </div>
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
                {loading && <LDS />}
              </div>
            </section>
}
          </div>
        </div>
      }
    </>
  )
}

const mapStateToProps = (state) => {
  const { user, photos } = state.mySpaceData
  return { user, photos }
}

export default connect(mapStateToProps, null)(Photos)