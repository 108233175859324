import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { config } from "../../../constant";
import { useDispatch } from "react-redux";
import { postRequestHandler } from "../../redux/reducers/requestHandler.reducer";
import { setToken } from "../../helpers/network";
const resendOtpApi = config.url.API_URL + "users/resendotp";

export default function OtpVerification(props) {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [errorMsg, setErrorMsg] = useState();
  const [resendMsg, setResendMsg] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  let token, currentUser;
  if (typeof window !== "undefined") {
    token = window.localStorage.getItem("token");
    // eslint-disable-next-line no-unused-vars
    currentUser = JSON.parse(window.localStorage.getItem("currentuser"));
  }
  //    if (!token) return <Navigate to="/auth/login" />

  const resendOtp = () => {
    let data = {
      id: token,
    };
    axios
      .post(resendOtpApi, data)
      .then((response) => {
        if (response.data.ack === 1) {
          setResendMsg("OTP send successfully");
        }
      })
      .catch((err) => {
        console.log("error=>", err);
      });
  };

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    let nextPlusOne = element.nextElementSibling;
    let prevPlusOne = element.previousSibling;

    //Focus next input
    element.value ? nextPlusOne.focus() : prevPlusOne.focus();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let token = localStorage.getItem("token");
    let data = {
      id: token,
      otp: otp.join(""),
    }

    const response = await dispatch(postRequestHandler({
      url: 'rotpv',
      action: 'ACTION_AUTH_USER',
      data: data
    }))
    if (response?.data?.status !== 200) {
      return setErrorMsg(response?.data?.message)
    }
    setToken(response?.data?.responseData?.token)
    localStorage.clear()
    localStorage.setItem("token", response.data.responseData.user.id)
    localStorage.setItem("accessToken", response.data.responseData.token)
    localStorage.setItem(
      "currentuser",
      JSON.stringify(response?.data?.responseData)
    )

    if (!response?.data?.responseData?.user?.isEmailVerified) {
      navigate("/auth/verification/")
    } else {
      navigate("/creations/")
    }
  };
  // '246562'
  return (
    <>
      <div className="Login">
        <div className="container-xl">
          <div className="row align-items-center justify-content-center hxz-100">
            <div className="col-lg-5 order-lg-3 order-md-3 order-1">
              {/* <div className="login_col_main"> */}
              <form
                className="login_col_main align-center br_1"
                onSubmit={handleSubmit}
              >
                <h3>Almost There</h3>
                <div className="phone-tag mt-4 mb-3">
                  <h6>
                    Please enter 6 digit OTP that we just sent.{" "}
                    <span className="clr-p"></span>
                  </h6>
                  {errorMsg && <div className="err-msg2">{errorMsg}</div>}
                  {resendMsg && (
                    <div className="bg-white p-3 clr-p">{resendMsg}</div>
                  )}
                  <div className="w-300 my-4 bg-w p_4">
                    <div className="otp-fields">
                      {otp.map((data, index) => {
                        return (
                          <input
                            className="otp-field"
                            type="text"
                            name="otp"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={(e) => handleChange(e.target, index)}
                            onFocus={(e) => e.target.select()}
                            required
                          />
                        );
                      })}
                    </div>

                    <div className="otp-fields mt-1 clr-p">
                      {/* <Link to="/login" className="clr-p">Edit Email</Link> */}
                      <div className="cur-p" onClick={resendOtp}>
                        Resend OTP
                      </div>
                    </div>
                  </div>
                  <h6 className="clr-p">
                    Can't find the email please check spam folder.
                  </h6>
                  {/* <div className='mt-4'>By continue you are agree to our <Link to="/terms" className='clr-p'>Terms of use</Link> & <Link className='clr-p' to="/privacy-policy">Privacy Policy</Link></div> */}
                </div>

                <button className="btn btns">Continue</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
