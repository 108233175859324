import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getNews } from '../../redux/reducers/newsReducer'
import { connect } from 'react-redux'
import { Footer, IndexNavbar } from '../../components'

function News(props) {
    const { news } = props
    return (
        <>
            <IndexNavbar />
            <div className='container mt-4 mb-4'>
                <h2 className="blog_heading mb-4">Latest News</h2>
                <div className='row'>

                    {news?.map(item =>

                        <div class="col-lg-4" href="#">
                            <Link to={"/news/" + item?.slug}>
                                <div class="cmn_box_new blog_main">
                                    <div class="blog_content_box2">
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="blog_icon" style={{
                                                    backgroundImage: `url(${item?.defaultImage || '/assets/web_img/hands.jpg'})`
                                                }}>
                                                </div>
                                                <div>
                                                    <h1 class="cl_b mt-2 blog_title">{item?.title}</h1>
                                                    <pre class="cl_b short_des">
                                                        {item?.shortDescription}
                                                    </pre>
                                                    <div class="fs_12 cl_g">
                                                        {moment(item?.createdAt).format("LL")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                    )}



                </div>
            </div>
            <Footer />
        </>
    )
}

News.loadData = (params, search) => async (dispatch) => {
    //   const query = queryParse(search)
    return await dispatch(getNews())
}
const mapStateToProps = (state) => {
    const { isSuccess, isError, data, isProcessing } = state.news
    return { isSuccess, isError, news: data, isProcessing }
}
const mapDispatchToprops = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToprops)(News)