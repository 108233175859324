import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { config } from '../../constant'
import { SubmitButton } from '../../components/AppComponents/Helpers';
import CustomizedSnackbars from '../../components/Loaders/Snuckbars';
import { useDispatch } from 'react-redux';
import { patchRequestHandler2 } from '../../redux/reducers/requestHandler.reducer';

const getUserUrl = config.url.API_URL + "users/"
const sendcode = config.url.API_URL + 'auth/sendcode'
const countryCode = config.url.API_URL + "users/country"

export const getUser = async (query) => {
    try {
        const response = await axios.get(getUserUrl + query)
        console.log(response)
        return response?.data?.responseData[0]
    } catch (err) {
        console.error(err)
        return err
    }
}

export const AccountsLogo = () => {
    return (
        <a href='/'>
            <div className="head_icon"></div>
        </a>
    )
}


export default function AccountVerificationCodeChoice() {
    const [selectedOption, setSelectedOption] = useState('email')
    const [user, setUser] = useState({})
    const [country, setCountry] = useState([])
    const [defaultCountryCode, setDefaultCountryCode] = useState('')
    const [values, setValues] = useState('')
    const navigate = useNavigate()
    const [isSubmit, setIsSubmit] = useState(false)
    const [isError, setIsError] = useState(false)
    const [msg, setMsg] = useState('')
    const dispatch = useDispatch()


    const getUser = async () => {
        try {
            const response = await axios.get(getUserUrl + `getusers?mode=3&match=${localStorage.getItem('resetuser')}&id=true`)
            console.log(response)
            if(response.data.ack === 0){
                navigate(-1)
            }
            setUser(response?.data?.responseData[0])
        } catch (err) {
            console.error(err)
        }
    }
    const handleOptionChange = e => {
        setSelectedOption(e.target.value)
    }

    const checkCountryCode = async () => {
        const response = await axios.get(countryCode)
        setCountry(response?.data?.responseData)
        // eslint-disable-next-line array-callback-return
        response?.data?.responseData.map((item) => {
            if (item?.dial_code === '+91') setDefaultCountryCode(item?.dial_code)
        })
    }

    const handleSendCode = async e => {
        try {
            e.preventDefault()
            setIsSubmit(true)
            setMsg('')

            console.log("hit amidfdjl")

            if (!user?._id && !user?.countryDialCode) {
                setIsError(true)
                setMsg('Please update your dial country code')
                return setIsSubmit(false)
            }
            const data = {
                id: user?.id,
                sendon: selectedOption,
            }
            const response = await axios.post(sendcode, data)
            setIsSubmit(false)
            if (response.data.ack === 0) {
                setIsError(true)
                return setMsg(response?.data?.message)
            }
            const uri = selectedOption === 'email' ? 'mail' : 'phone'
            navigate(`/accounts/verify/code?sendon=${uri}`)

        } catch (error) {
            console.error(error)
        }
    }

    const updateUser = async e => {
        try {
            e.preventDefault()
            // return console.log("jfdsjfsl")
            const req = {
                data: { countryDialCode: values || defaultCountryCode },
                qs: `_id=${user?.id}`,
                url: 'updateCountryCode'
            }
            const response = await dispatch(patchRequestHandler2(req))
            console.log(response)
            if (response?.data?.ack === 1) getUser()
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        if (!localStorage.getItem("resetuser")) return navigate(-1)
        checkCountryCode()
        getUser()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className='accounts'>
            {!user?.countryDialCode &&
                <form className='verification_box mb-2' onSubmit={updateUser}>
                    <p>Please update country code to get SMS</p>
                    <select
                        name="countryDialCode"
                        className="form-control mt-2"
                        placeholder="select country code"
                        onChange={e => setValues(e.target.value)}
                        required
                    >
                        <option value="" >Select country code</option>
                        {
                            country.map((item) => {
                                return (
                                    item?.dial_code === '+91' ?
                                        <option value={item?.dial_code} key={item?.id} selected >
                                            {item?.name + "/" + item?.dial_code}
                                        </option>
                                        : <option value={item?.dial_code} key={item?.id}>
                                            {item?.name + "/" + item?.dial_code}
                                        </option>
                                )
                            })
                        }
                    </select>

                    <button type='submit' className='btn btn-primary mt-2' >update country code</button>
                </form>
            }

            {user &&
                <form className='verification_box' onSubmit={handleSendCode}>
                    <div className="center_box">
                        <AccountsLogo />

                        <h4 className='text-center'>Verify Your Account</h4>
                        <p className="px-4 pt-3 pb-2">How do you want to receive the code to<br /> reset your password?</p>
                        <div className="d-flex gap-2">
                            <div className='w-100'>
                                <div className="rd_box">
                                    <label className='d-flex gap-4'>
                                        <input type="radio" value="email"
                                            checked={selectedOption === 'email'}
                                            onChange={handleOptionChange}
                                        />
                                        <div>
                                            <p className='cl_b'>Send code via email</p>
                                            <p className='fs_13'>{user?.email}</p>
                                        </div>
                                    </label>
                                </div>
                                <div className="rd_box">
                                    <label className='d-flex gap-4'>
                                        <input type="radio" value="phone"
                                            checked={selectedOption === 'phone'}
                                            onChange={handleOptionChange} disabled={user?.countryDialCode ? "" : "disable"} />
                                        <div>
                                            <p className='cl_b'>Send code via SMS</p>
                                            <p>{user?.countryDialCode + user?.phone}</p>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div className="profile_boxx center_box w-100">
                                <div className="profile_circle_lg">
                                    <img
                                        src={user?.profilePhoto || `/assets/web_img/choto_logo_1.png`}
                                        alt="profile"
                                        // eslint-disable-next-line no-sequences
                                        onError={e => (e.target.onerror = null, e.target.src = `/assets/web_img/choto_logo_1.png`)}
                                    />
                                    <p>{user?.showName}</p>
                                </div>
                            </div>
                        </div>
                        <SubmitButton title={"Send code"} issubmit={isSubmit} css={"mt-2 login-button"} />
                    </div>

                </form>}

            {isError && <CustomizedSnackbars type={1} msg={msg} sclose={() => setIsSubmit(false)} />}
        </div>)
}