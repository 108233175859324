import React, { useState, useEffect } from "react"
import ReplyComments from "./Replycomments"
import SimpleCircle from "./Loaders/SimpleCircle"
import { useDispatch } from "react-redux"
import { deleteRequestHandler, getRequestHandler, postRequestHandler, putRequestHandler } from "../redux/reducers/requestHandler.reducer"

function Comments(props) {
	const [allComments, setAllComments] = useState([])
	const [comment, setComment] = useState("")
	const [comment1, setComment1] = useState("")
	const [commentSwitch, setCommentSwitch] = useState({})
	const [editCommentSwitch, setEditCommentSwitch] = useState({})
	const [editCommentChange, setEditCommentChange] = useState({})
	const [replyShow, setReplyShow] = useState(false)
	const token = localStorage.getItem("token")
	const dispatch = useDispatch()

	const getAllComments = async (limit) => {
		let req = {
			params: {
				pid: props.postdetails.postid,
				limit: limit
			},
			url: 'getComments'
		}
		const response = await dispatch(getRequestHandler(req))
		setAllComments(response.data.responseData)
		props.settotalCom(props.postdetails.postid)
	}

	const handleLikeClick = async (commentId) => {
		let dataObj = {
			referenceUserId: token,
			referencePostId: props.postdetails.postid,
			referenceCommentId: commentId,
		}
		let req = {
			data: dataObj,
			url: 'replyLike'
		}
		const dataPost = await dispatch(postRequestHandler(req))
		if (dataPost)
			getAllComments()
	}

	const handleReplyCommentClick = (commentId, showName, index) => {
		setComment1("@" + showName)
		const k = "comment" + index
		setCommentSwitch((values) => ({ ...values, [k]: index }))
	}

	const handleEditCommentClick = (commentId, index, msg) => {
		const k = "comment" + index
		setEditCommentChange((prev) => ({ ...prev, [k]: msg }))
		setEditCommentSwitch((values) => ({ ...values, [k]: index }))
		console.log(editCommentChange, editCommentSwitch)
	}

	const handleReplyCommentView = (index) => {
		const k = "comment" + index
		setCommentSwitch((values) => ({ ...values, [k]: index }))
		setReplyShow(true)
	}

	const handleChange = (e) => {
		setComment(e.target.value)
	}

	const handleCommentUpdate = async (id, index) => {
		try {
			setEditCommentSwitch((prev) => ({ ...prev, [`comment` + index]: "" }))
			let req = {
				data: {
					cid: id,
					messageText: editCommentChange[`comment` + index]
				},
				url: 'comments'
			}
			const response = await dispatch(putRequestHandler(req))
			if (response.data.ack === 1) getAllComments()
		} catch (err) {
			console.error(err)
		}
	}

	const [isLoaderCom, setIsLoaderCom] = useState(false)

	const handleCommentClick = async () => {
		setIsLoaderCom(true)

		const dataArr = {
			referenceUserId: token,
			referencePostId: props.postdetails.postid,
			messageText: comment,
		}
		let req = {
			data: dataArr,
			url: 'comments'
		}
		const dataPost = await dispatch(postRequestHandler(req))
		setIsLoaderCom(false)

		if (dataPost) {
			setComment("")
			getAllComments()
		}
	}

	// const updateComment = async () =>{

	// }

	const deleteComment = async (id) => {
		try {
			const result = window.confirm("Are you sure?")
			if (!result) return
			let req = {
				params: { id: id },
				url: 'comments'
			}
			const response = await dispatch(deleteRequestHandler(req))

			if (response.data.ack === 1) getAllComments()
		} catch (err) {
			console.error(err)
		}
	}

	useEffect(() => {
		if (props.show) getAllComments(props.limit)
	}, [props.show, props.limit])

	return (
		<div>
			<div style={{ position: "relative" }}>
				<div className="row">
					<div className="col-lg-12">
						{props.show ? (
							<div className="mt-3 add_comment d-flex px_15 bb-1">
								<div className="pro_img">
									{props.tokenuser ? (
										<img src={props.tokenuser} alt="members profile" />
									) : (
										<img
											src={"/assets/web_img/choto_logo_1.png"}
											alt="members profile"
										/>
									)}
								</div>
								<input
									type="text"
									name="replyComment"
									onChange={handleChange}
									className="comment comment1"
									placeholder="Add comment"
									value={comment}
								/>
								{isLoaderCom ?
									<div className="commentPost">
										<SimpleCircle />
									</div>
									: <div className="commentPost" onClick={handleCommentClick}>
										Post
									</div>}
							</div>
						) : (
							""
						)}

						{allComments &&
							allComments.map((item, index) => {
								return (
									<div className="commentsDiv" key={index}>
										<div className="pro_img_com">
											{item.referenceUserId ? (
												<div className="d-flex ">
													{item.referenceUserId.profilePhoto ? (
														<img
															src={item.referenceUserId.profilePhoto}
															alt="members profile"
														/>
													) : (
														<img
															src={"/assets/web_img/choto_logo_1.png"}
															alt="profile"
														/>
													)}
												</div>
											) : (
												""
											)}
										</div>

										<div className="commentsView">
											<div className="commentBox">
												<pre className="fs-14 viewD">
													<span className="user_name">
														{item?.referenceUserId?.showName} :
													</span>
													{item.messageText ? item.messageText : ""}
												</pre>
												<div className="d-flex gap-2">
													<div
														className="like commentx h-underline"
														onClick={() => handleLikeClick(item._id)}
													>
														{item.replyLikeCount ? item.replyLikeCount : 0}{" "}
														Likes
													</div>
													<div
														className="h-underline"
														title="click to reply"
														onClick={() =>
															handleReplyCommentClick(
																item._id,
																item.referenceUserId.showName,
																index
															)
														}
													>
														{item.replyCommentCount
															? item.replyCommentCount
															: 0}{" "}
														reply
													</div>

													{item?.referenceUserId?.id === token && (
														<div
															className="like commentx h-underline"
															onClick={() =>
																handleEditCommentClick(
																	item._id,
																	index,
																	item?.messageText
																)
															}
														>
															Edit
														</div>
													)}

													{item?.referenceUserId?.id === token && (
														<div
															className="like commentx h-underline"
															onClick={() => deleteComment(item._id)}
														>
															Delete
														</div>
													)}
												</div>
											</div>
											{item?.referenceUserId?.id === token &&
												editCommentSwitch["comment" + index] === index && (
													<div className="mt-3 add_comment d-flex px_15 bb-1">
														<div className="pro_img">
															{props.tokenuser ? (
																<img
																	src={props.tokenuser}
																	alt="members profile"
																/>
															) : (
																<img
																	src={"/assets/web_img/choto_logo_1.png"}
																	alt="members profile"
																/>
															)}
														</div>
														<input
															type="text"
															name={`comment` + index}
															onChange={(e) =>
																setEditCommentChange((prev) => ({
																	...prev,
																	[e.target.name]: e.target.value,
																}))
															}
															className="comment comment1"
															placeholder="Edit comment"
															value={editCommentChange[`comment` + index] || ""}
														/>
														<div
															className="btn btn-primary"
															onClick={() =>
																handleCommentUpdate(item._id, index)
															}
														>
															Update
														</div>
														<div
															className="btn btn-danger mx-1"
															onClick={() =>
																setEditCommentSwitch((prev) => ({
																	...prev,
																	[`comment` + index]: "",
																}))
															}
														>
															Cancel
														</div>
													</div>
												)}
											{commentSwitch["comment" + index] === index ? (
												<ReplyComments
													show={replyShow}
													commentid={item._id}
													tokenuser={props.tokenuser}
													usertag={comment1}
													postid={props.postdetails.postid}
													postcreatorid={props.userinfo._id}
												/>
											) : (
												""
											)}

											{commentSwitch["comment" + index] === index ? (
												<div className="d-flex gap-2">
													<div
														className="comment_count"
														style={{ cursor: "pointer" }}
														onClick={() => handleReplyCommentView(index)}
													>
														{/* View more replies (wip), */}
														View more replies,
													</div>
													<div
														className="comment_count close_comment"
														style={{ cursor: "pointer" }}
														onClick={() =>
															setCommentSwitch((values) => ({
																...values,
																["comment" + index]: "",
															}))
														}
													>
														Close replies
													</div>
												</div>
											) : (
												<div
													className="comment_count mt-2"
													style={{ cursor: "pointer" }}
													onClick={() => handleReplyCommentView(index)}
												>
													View recent replies
												</div>
											)}
											{/* <div className="h-underline replyComment" onClick={() => handleReplyCommentView(index)}>view recent replies</div> */}
										</div>
									</div>
								)
							})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Comments
