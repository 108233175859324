import React, { useEffect, useState } from 'react'

const AboutMe = (props) => {
    const {user, aboutToggle, setAboutToggle} = props
    
    return (
        <>
            {aboutToggle &&
            <div className="popup_newmyspace">
                <div className="popup_in">
                    <div class="c_pointer close_section" onClick={()=>setAboutToggle(false)}>
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark post_icons" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
                        </path>
                        </svg>
                    </div>
                    <h3 className="mt-2 text-uppercase">About <span className="text-success">{user?.showName}</span> </h3>
                    <hr />
                    <div className="ps-5 pe-5 text-start">
                        <h4><strong>{user?.showName}</strong></h4>
                        <p>{user?.bio}</p>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default AboutMe