import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import SimpleCircle from '../../components/Loaders/SimpleCircle'
import { ImageUploadDialogManagement } from '../../../_helpers/Material'
import { CustomizedSnackbars, Footer, IndexNavbar } from '../../components'
import { useDispatch } from 'react-redux'
import { getReqManagementWithOutDispatch } from '../../redux/reducers/organizationReducer'
import { postRequestHandler } from '../../redux/reducers/requestHandler.reducer'

function CreatePremiumPage(props) {
    const [values, setValues] = useState({})
    const navigate = useNavigate()
    const token = typeof window !== "undefined" ? localStorage.getItem('token') : ""
    const [isFileUplaoded, setIsFileUplaoded] = useState(false)
    const [isProfileUpload, setIsProfileUpload] = useState(false)
    const [isCoverUpload, setIsCoverUpload] = useState(false)
    const [profileFileLink, setProfileFileLink] = useState("")
    const [coverFileLink, setCoverFileLink] = useState("")
    const [isBtnDisabled, setIsBtnDisabled] = useState(true)
    const [isLoader, setIsLoader] = useState(false)
    const [dataFor, setDataFor] = useState("")
    const [errMsg, setErrMsg] = useState("")
    const [inputfocus, setInputfocus] = useState({});
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState({ pageName: false })
    const dispatch = useDispatch()
    const uploadChanges = field => {
        setIsFileUplaoded(true)
        setDataFor(field)
    }

    const handleOnBlurPageName = async e => {
        if (!values?.pageName) return setErrors(prev => ({ ...prev, pageName: 'Required' }))
        let params = {
            pageName: values.pageName
        }
        setLoading(prev => ({ ...prev, pageName: true }))
        const response = await getReqManagementWithOutDispatch('orgSearch', params)
        setErrors(prev => ({ ...prev, pageName: response?.data?.ack === 1 ? 'Already exist' : '' }))
        console.log("handleOnBlurPageName", response)
        setInputfocus((inputfocus) => ({
            ...inputfocus,
            [e.target.name]: false,
        }))
        setLoading(prev => ({ ...prev, pageName: false }))

    }

    const handleChange = e => {
        if (e.target.name === "pageName") {
            const orgDetails = e.target.value.toLowerCase()
            // eslint-disable-next-line no-useless-escape
            const l = orgDetails.replace(/[`~!@#$%^&*()_|+\-=?:'",.<>\{\}\[\]\\\/]/gi, ".")
            const t = l.replace(" ", ".")
            return setValues(prev => ({ ...prev, [e.target.name]: t }))
        }
        setValues(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleSubmit = async () => {
        try {
            setIsLoader(true)
            setErrMsg("")
            const data = Object.assign(values, {
                userid: token,
                BackgroundImage: coverFileLink,
                organiztionLogo: profileFileLink,
                mode: "pre"
            })
            let req = {
                data: data,
                url: 'createPremiumPage'
            }
            const response = await dispatch(postRequestHandler(req))
            setIsLoader(false)
            if (response?.data?.ack === 0) return setErrMsg(response?.data?.message)
            navigate(`/premium/${response?.data?.responseData?.pageName}/#about/`)
            // console.log(data, response)
        } catch (err) {
            console.log(err)
        }
    }



    useEffect(() => {
        // console.log(values?.pageName , token , values?.pageCategory)
        setErrors(prev => ({ ...prev, pageCategory: !values?.pageCategory ? 'Required' : "" }))
        setIsBtnDisabled(values?.pageName && token && values?.pageCategory ? false : true)
    }, [token, values?.pageName, values?.pageCategory])
    return (
        <>
            <IndexNavbar />
            <div className='create_page_main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12 create_left'>
                            <img className='create_logo img-responsive' src={"/assets/web_img/main_logo2.png"} alt="logo" />
                            <h2>Welcome to the
                                <span>creation of Premium page</span>
                            </h2>
                            <p className='mt-2'>
                                Create a Premium Page where you can share Premium Posts
                                that people will be able to see if they pay a Pay-Per-View
                                (PPV) or a monthly subscription. There will be also place
                                on this page to share free post and talk about yourself so
                                people will know more about your posts.
                            </p>
                        </div>
                        <div className='col-lg-6 col-sm-12 create_right'>
                            <div className='form-group'>
                                <input
                                    className='form-control'
                                    type="text"
                                    name="showName"
                                    onChange={handleChange}
                                    value={values.showName || ''}
                                    placeholder='Type your premium page name' />
                            </div>
                            <div className="mb-3 fs_choto cl_gray info">
                                Help people find your premium page with a page name they can remember.
                            </div>
                            <div className='form-group'>
                                <input
                                    className='form-control'
                                    type="text"
                                    name="pageName"
                                    onChange={handleChange}
                                    value={values.pageName || ''}
                                    placeholder='Type your Premium page URL'
                                    onFocus={e => setInputfocus((inputfocus) => ({ ...inputfocus, [e.target.name]: true }))}
                                    onBlur={handleOnBlurPageName}
                                    required
                                />
                            </div>


                            <div >
                                {loading?.pageName ?
                                    <div className='d-flex gap-2 fs-14 text-primary'><SimpleCircle /> Checking availablity...</div>
                                    : !inputfocus.pageName && errors.pageName ? <div className="errors mb-2">{!inputfocus.pageName && errors.pageName}</div> : values?.pageName && !inputfocus.pageName && <div className='text-success fs-14 mb-2'>Available</div>}

                            </div>
                            <div className="mb-3 fs_choto cl_gray info">
                                You can direct people to your premium page with the url <span className='text-primary'>https://www.allphanes.com/premium-pagename</span>. So, if your premium page name is my.premium type that in this box. Note that the name cannot contain uppercase letters or space or any special characters other than '.'
                            </div>
                            <select className="form-select mb-2 rounded-pill" name="monthlySubscriptionPrice" onChange={handleChange} value={values?.monthlySubscriptionPrice || ""} required>
                                <option className="" value="">
                                    Select Premium page monthly subscription plan (INR)
                                </option>

                                <option className="" value={40}>
                                    40
                                </option>
                                <option className="" value={50}>
                                    50
                                </option>
                                <option className="" value={75}>
                                    75
                                </option>
                                <option className="" value={100}>
                                    100
                                </option>
                                <option className="" value={200}>
                                    200
                                </option>
                                <option className="" value={300}>
                                    300
                                </option>
                                <option className="" value={400}>
                                    400
                                </option>
                                <option className="" value={500}>
                                    500
                                </option>
                            </select>
                            <div className="mb-3 fs_choto cl_gray info">
                                Please select what your subscribers will pay for monthly subscriptions. You will be able to select pay-per-view (PPV) amount later when you post premium content.
                            </div>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <div className='create_upload_img' style={{ backgroundImage: `url(${profileFileLink || ''})`, }}>
                                        </div>
                                    </div>
                                    {isProfileUpload ? "" : <div className='col-sm-8'>
                                        <div className='upload_profile'>
                                            <div className='upload_btns c_pointer' onClick={() => uploadChanges('profile')}>
                                                <img className='img-responsive' src={"/assets/web_img/camera_icon.png"} alt="logo" /> Upload Premium Page Profile Photo</div>
                                        </div>
                                    </div>}
                                </div>
                                <div className='form-group upload_cover_pic'>
                                    <div className='upload_cover_pic' style={{ backgroundImage: `url(${coverFileLink || ''})`, }}></div>
                                    {isCoverUpload ? "" : <div className='upload_profile'>
                                        <div className='upload_btns c_pointer' onClick={() => uploadChanges('cover')}>
                                            <img className='img-responsive' src={"/assets/web_img/camera_icon.png"} alt="logo" /> Upload Premium Page Cover Photo</div>
                                        {/*<input type="file"></input>*/}
                                    </div>}
                                </div>
                                <br />
                                <br />
                                <h1>Describe the objective of your institution</h1>
                                <select
                                    className='form-control'
                                    name="pageCategory"
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Category</option>
                                    <option value="music">Music</option>
                                    <option value="drama">Drama</option>
                                    <option value="dance">Dance</option>
                                    <option value="painting">Painting</option>
                                    <option value="ngo">NGO</option>
                                    <option value="other">Other</option>
                                </select>
                                <div className="errors mb-2">{errors?.pageCategory}</div>

                                <textarea
                                    className="form-control"
                                    rows="3"
                                    placeholder="Describe what kind of Premium Content you provide"
                                    name="details"
                                    onChange={handleChange}
                                    value={values.details || ""}
                                ></textarea>

                                <div className='create_page_button'>
                                    {isBtnDisabled ?
                                        <button className='btn cmn_btn' disabled>
                                            Create Premium Page
                                        </button> :
                                        isLoader ?
                                            <button className='btn cmn_btn'>
                                                <SimpleCircle />
                                            </button> :
                                            <button className='btn cmn_btn' onClick={handleSubmit}>
                                                Create Premium Page
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isFileUplaoded && dataFor === "profile" && <ImageUploadDialogManagement
                    title={"Upload organisation profile photo"}
                    isImageCropper={true}
                    setFileLink={setProfileFileLink}
                    setIsUploaded={() => setIsProfileUpload(true)}
                    close={() => setIsFileUplaoded(false)}
                />}

                {isFileUplaoded && dataFor === "cover" && <ImageUploadDialogManagement
                    title={"Upload organisation cover photo"}
                    isImageCropper={false}
                    setFileLink={setCoverFileLink}
                    setIsUploaded={() => setIsCoverUpload(true)}
                    close={() => setIsFileUplaoded(false)}
                />}


            </div>
            {errMsg && <CustomizedSnackbars type={1} msg={errMsg} />}

            <Footer />
        </>
    )
}

export default CreatePremiumPage