import React, { useState, useEffect } from 'react';
import axios from "axios"
import { config } from "../../../constant"
import { Table } from 'react-bootstrap';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import Button from '@mui/material/Button';
import ManagementLeftNav from '../../components/Navbars/ManagementLeftNav';
import { SeoFrom } from '../../components';
// import { useNavigate } from 'react-router-dom';
const seoUrl = config.url.API_URL + "admin/seo"

function AdminSeoManagement() {
    const [values, setValues] = useState([])
    const [isAdd, setIsAdd] = useState(true)
    const [updateValues, setUpdateValues] = useState({})
    const [id, setId] = useState(null)

    const getData = async () => {
        try {
            const response = await axios.get(seoUrl)
            setValues(response.data.responseData)
            console.log(response)
        } catch (err) {
            console.error(err)
        }
    }

    const handleEdit = (index) => {
        setId(values[index]._id)
        const { path, title, description, link, metaKeyword } = values[index]
        setUpdateValues({ path, title, description, link, metaKeyword })
        setIsAdd(false)
    }

    const handleDelete = async kid => {
        const response = await axios.delete(seoUrl + `?_id=${kid}`)
        if (response.data.ack === 1) getData()
    }
    // const navigate = useNavigate()

    // useEffect(() => {
    //     const user = typeof window !== "undefined"
    //         ? JSON.parse(localStorage.getItem("currentUser"))
    //         : "";

    //     if (user?.userRole !== '1010') navigate('/admin/')
    // }, [])

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-lg-2">
                        <ManagementLeftNav />
                    </div>
                    <div className="col-lg-10 bg_allp" style={{ minHeight: "100vh" }}>
                        <div className="mt-2">
                            <h2>{isAdd ? "Add" : "Edit"} SEO Management</h2>
                            <SeoFrom add={isAdd} update={updateValues} id={id} getReq={() => getData()} cancel={() => setIsAdd(true)} />

                            <h3 className='my-3'>Show</h3>
                            <Table striped bordered hover responsive="sm">
                                <thead>
                                    <tr>
                                        <th>Path</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Link</th>
                                        <th>keyword</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        values?.map((items, index) =>
                                            <tr key={index}>
                                                <td>{items?.path}</td>
                                                <td>{items?.title}</td>
                                                <td>{items?.description}</td>
                                                <td>{items?.link}</td>
                                                <td>{items?.metaKeyword}</td>
                                                <td className='d-flex align-items-center justify-content-center gap-2'>
                                                    <Button variant="outlined" color="success" onClick={() => handleEdit(index)}><ModeEditIcon /></Button>
                                                    <Button variant="outlined" color="error" onClick={() => handleDelete(items?._id)}><DeleteOutlineSharpIcon /></Button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                                {/* </thead> */}
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default AdminSeoManagement

