import React, { useState } from 'react'
import axios from "axios"
import { config } from "../../constant"
import { ImageUploadDialog } from "../../../_helpers/Material"
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import { OrgFollow, OrgLike } from "../CreateOrganisation"
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';

const getUserUrl = config.url.API_URL + "users/getusers?mode=2&userName="
const ORG_URL = config.url.API_URL + "org/"
const POST_URL = config.url.API_URL + "posts/"

export const OrgMenu = (props) => {
    const { orgDetails, token, reLink } = props

    return (
        <>
            <div className="container social_details justify-content-between mt-3">
                <div className="organization_post_left">
                    <a className="sd_tex active" href={`/org/${reLink}/about/`}>About</a>
                    <a className="sd_tex" href={`/org/${reLink}/posts/`}>Posts</a>
                    <a className="sd_tex" href={`/org/${reLink}/photos/`}>Photos</a>
                    <a className="sd_tex" href={`/org/${reLink}/videos/`}>Videos</a>
                    <a className="sd_tex" href={`/org/${reLink}/members/`}>Members</a>
                    <a className="sd_tex" href={`/org/${reLink}/followers/`}>Followers</a>
                </div>

                <div className="organiza_follow_us d-flex gap-2">
                    {orgDetails?.userid === token ? "" : <OrgFollow orgDetails={orgDetails} />}
                    {/* {orgDetails?.userid === token ? "" : <OrgFollow orgDetails={orgDetails} />} */}
                </div>
            </div>
        </>
    )
}

export const OrgProfileInfo = (props) => {
    const { orgDetails, token } = props
    return (<>
        <div className="user_profile_info flex_info">
            <div className="org_info">
                <h1 className="text-primary">{orgDetails?.pageName}</h1>
                
                <p>{orgDetails?.totalFollowers} followers,{orgDetails?.totalLikes} likes, {orgDetails?.totalActiveMembers} members </p>
                {/* <p>{orgDetails?.followers?.length} followers,{orgDetails?.likes?.length} likes, {orgDetails?.membars?.length} members </p> */}
            </div>
            <div className="render_fr d-flex gap-2">
                {orgDetails?.userid === token ? "" : <OrgLike orgDetails={orgDetails} />}
                {orgDetails?.isFollowing ? <div className="fol_status"><CheckCircleOutlineIcon />Following</div> : ""}

            </div>
        </div>
    </>)
}

function OrgDetailsSection(props) {
    const { orgDetails } = props
    const [singleUser, setSingleUser] = useState([])
    const param = useParams()
    const path = useLocation()
    const token = typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const currentUser = typeof window !== "undefined" ? JSON.parse(localStorage.getItem("currentuser")) : ""
    const [modalShow, setModalShow] = useState(false)
    const [PathInfo, setPathInfo] = useState("")
    const [uploadedFor, setUploadedFor] = useState("")
    const handleModalClick = (path) => {
        setModalShow(true)
        setPathInfo(path)
        setUploadedFor(path === 1 ? "logo" : "background")

    }
    const getSingleUser = () => setSingleUser(JSON.parse(localStorage.getItem("currentuser")))


    useEffect(() => {
        getSingleUser()
    }, [param, modalShow])
    return (
        <>
            <div
                className="profile_cover"
                style={{
                    backgroundImage: `url(${orgDetails?.BackgroundImage || '/assets/web_img/choto_logo_1.png'})`,
                }}>


                {orgDetails?.userid === token ? (
                    <button
                        type="button"
                        className="edit_cover_pic_btn shadow"
                        title="Change cover photo"
                        onClick={() => handleModalClick(0)}
                    >
                        <CropOriginalIcon />
                    </button>
                ) : (
                    ""
                )}
            </div>
            <div>
                <div className="user_pro_sec container ">
                    <div className="row">
                        <div className="col-lg-12 d-flex justify-content-between">
                            <div className="kk_modi">
                                <div className="profile_outer_photo bg_allp2">
                                    <div className="profile_inner_photo">
                                        <div className="img_box">
                                            <img
                                                src={orgDetails?.organiztionLogo || `/assets/web_img/choto_logo_1.png`}
                                                alt={orgDetails?.organiztionLogo + "profile photo"}
                                                // eslint-disable-next-line no-sequences
                                                onError={e => (e.target.onerror = null, e.target.src = `/assets/web_img/choto_logo_1.png`)}
                                                className="img_box" />
                                        </div>

                                        {orgDetails?.userid === token ? (
                                            <div
                                                title="Change profile photo"
                                                className="edit_pro text-center mt-1"
                                                onClick={() => handleModalClick(1)}
                                            >
                                                <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                                <div className="user_profile_info flex_info">
                                    <div className="org_info">
                                        <h1 className="text-primary">{orgDetails?.pageName}</h1>
                                        <p>{orgDetails?.totalFollowers} followers,{orgDetails?.totalLikes} likes, {orgDetails?.totalActiveMembers} members </p>
                                    </div>
                                    <div className="render_fr d-flex gap-2">
                                        {orgDetails?.userid === token ? "" : <OrgLike orgDetails={orgDetails} />}
                                        {orgDetails?.isFollowing ? <div className="fol_status"><CheckCircleOutlineIcon />Following</div> : ""}

                                    </div>
                                </div>

                            </div>


                        </div>
                        {/* social links */}
                    </div>

                </div>
            </div>
            {modalShow && <ImageUploadDialog pathinfo={PathInfo} close={() => setModalShow(false)} uploadapi={uploadedFor} orgid={orgDetails?._id} />}

        </>
    )
}

export default OrgDetailsSection