import React from "react";
import { useParams } from "react-router-dom";
import { Footer, IndexNavbar } from "../../components";
import api from "../../config/api";
import config from "../../config/config";

function StreamVideos(props) {
  const { id } = useParams();
  // console.log("s", id)
  return (
    <>
      <IndexNavbar />
      <div className="stream_body">
        {id && (
          <video width="320" height="240" controls controlsList="nodownload">
            <source
              src={`${config.API_URL + api.streamVideo}?pid=${id}`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      <Footer />
    </>
  );
}

export default StreamVideos;
