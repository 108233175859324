import React, { useEffect, useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import useForm from "../../validations/useForm"
import validate from "./../../validations/FormValidationRule"
import { CustomizedSnackbars, SocialAppLogin } from "./../../../new/components"
import LoginForm from "./LoginForm"
import { connect, useDispatch } from "react-redux"
import { RESET_STORE } from "../../redux/reducers/authReducer"
import { FirebaseSignOut } from "../../components/Auth/FireBase"
import { setToken } from "../../helpers/network"
import { postRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import Pushnotification from "../../services/Pushnotification"
// import FacebookLogin from 'react-facebook-login'

function Login(props) {
  const [errmsg, setErrMsg] = useState("")
  const [isSubmit, setIsSubmit] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoginSuccess, setIsLoginSuccess] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const dispatch = useDispatch()

  const currentUser =
    typeof window !== "undefined"
      ? JSON.parse(window.localStorage.getItem("currentuser"))
      : ""

  const { values, errors, handleChange, handleSubmit } = useForm(
    login,
    validate,
    "login"
  )

  async function login() {
    try {
      setIsSubmit(true)
      const data = {
        email: values.email,
        password: values.lpassword,
        loginType: 'normal'
      }
      const response = await dispatch(postRequestHandler({
        url: 'login',
        action: 'ACTION_AUTH_USER',
        data: data
      }))

      setIsSubmit(false)
      if (response?.data?.status === 700) {
        localStorage.setItem('reActivaEmail', values.email)
        return navigate("/accounts/reactivate/")
      }
      if (response?.data?.status !== 200) {
        setIsError(true)
        return setErrMsg(response?.data?.message)
      }
      setIsLoginSuccess(true)
      setToken(response?.data?.responseData?.token)
      localStorage.clear()
      localStorage.setItem("token", response.data.responseData.user.id)
      localStorage.setItem("accessToken", response.data.responseData.token)
      localStorage.setItem(
        "currentuser",
        JSON.stringify(response?.data?.responseData)
      )
      Pushnotification()
      if (!response?.data?.responseData?.user?.isEmailVerified) {
        navigate("/auth/verification/")
      } else {
        const redirect = searchParams.get("redirectTo")
        navigate(redirect || "/creations/")
      }
    } catch (err) {
      setIsError(true)
      setErrMsg(
        "Sorry, Login failed due to some technical issue. Please try again after some time."
      )
      console.error(err)
    }
  }

  const logout = () => {
    if (
      currentUser?.loginType === "google" ||
      currentUser?.loginType === "facebook"
    )
      FirebaseSignOut()
    dispatch({ type: RESET_STORE })
    sessionStorage.clear()
    localStorage.clear()
  }

  useEffect(() => {
    logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="Login">
        <div className="container-xl">
          <div className="row align-items-center justify-content-center hxz-100">
            <div className="col-lg-5 order-lg-3 order-md-3 order-1">
              <div className="login_col_main">
                <Link to={"/"} className="main_logo m-hide">
                  <img
                    className="img-responsive"
                    src="/assets/web_img/footer_logo.png"
                    alt="footer logo"
                  />
                </Link>
                <Link to={"/"} className="main_logo m-show">
                  <img
                    className="img-responsive"
                    src="/assets/web_img/footer_logo.png"
                    alt="footer logo"
                  />
                </Link>
                <LoginForm
                  redirectTo={searchParams.get("redirectTo")}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  values={values}
                  errors={errors}
                  isSubmit={isSubmit}
                />
                <div className="d-flex align-items-center gap-2 mx-3 mb-3 mt-2">
                  <div className="line"></div>
                  <div>or</div>
                  <div className="line"></div>

                  {/* <Link to="/auth/social/login" className="btn btn-primary">Facebook Login</Link> */}
                </div>
                {/* <FacebookLoginx /> */}
                <SocialAppLogin />

                {/* error snackbar */}
                {isError && (
                  <CustomizedSnackbars
                    type={1}
                    msg={errmsg}
                    sclose={() => setIsError(false)}
                  />
                )}
                {isLoginSuccess && (
                  <CustomizedSnackbars
                    type={0}
                    msg={"login successfull"}
                    sclose={() => setIsLoginSuccess(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  return {}
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
