let errors1 = {}
export default function validate(values, callback) {
    // email validation 
    const emailValidate = data => {
        if (!data) return errors1.email = 'Email address is required'
        if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data)) return errors1.email = 'Invalid Email address'
        return delete errors1.email
    }

    // password validation 
    const passwordValidate = data => {
        if (!data) return errors1.password = 'Password is required'
        if (data.length < 8) return errors1.password = 'Password must be 8 or more characters'
        if (!/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/.test(data))
            return errors1.password = 'Password must be consist of 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special charecter'
        return delete errors1.password
    }

    // Indian Mobile number validation 
    const mobileValidationIndian = data => {
        if (!data) return errors1.phone = 'Phone number required'
        if (isNaN(data)) return errors1.phone = 'Phone number should be in Numbers.'
        // if(data.length < 10) return errors1.phone ='Atleast 10 Numbers required'
        if (data.length > 16) return errors1.phone = 'Atmost 16 Numbers allowed if you entered the country code like +91 or 0091'
        // if(!/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/.test(data)) return errors1.phone = 'Invalid Phone number'
        return delete errors1.phone
    }

    // name validation 
    const nameValidate = (data, fieldName, msg) => {
        if (!data) return errors1[fieldName] = msg + ' is required'
        if (!isNaN(data)) return errors1[fieldName] = msg + ' should be string type.'
        if (data.length < 3) return errors1[fieldName] = 'Atleast 3 Characters required'
        if (data.length > 32) return errors1[fieldName] = 'Atmost 32 characters allowed.'
        return delete errors1[fieldName]
    }

    //Referral code validation
    const RefCodeValidation = data =>{
        console.log(data,"=",data.length)
        if(data.length !== 8 ) return errors1.refCode = 'Referral code must be 8 character'
        // if(data)
        // if (!/^[0-9a-z]+$/.test(data)) return errors1.refCode = 'Referral code supports only alpha numeric charecter'
        return delete errors1.refCode
    }

    //calling validation functions according to callbacks

    //on handle Changes
    if (callback === "firstName") nameValidate(values, 'firstName', 'First name')
    if (callback === "lastName") nameValidate(values, 'lastName', 'Last name')
    if (callback === "userName") nameValidate(values, 'userName', 'Username')
    if (callback === "phone") mobileValidationIndian(values)
    if (callback === "password") passwordValidate(values)
    if (callback === "email") emailValidate(values)
    if (callback === "refcode") RefCodeValidation(values)

    // on handleSubmits
    if (callback === "login") {
        emailValidate(values.email)
        // passwordValidate(values.password)
    }

    if (callback === "register") {
        nameValidate(values.firstName, 'firstName', 'First name')
        nameValidate(values.lastName, 'lastName', 'Last name')
        nameValidate(values.userName, 'userName', 'Username')
        emailValidate(values.email)
        mobileValidationIndian(values.phone)
        passwordValidate(values.password)
    }

    if (callback === "updateUser") {
        nameValidate(values.firstName, 'firstName', 'First name')
        nameValidate(values.lastName, 'lastName', 'Last name')
        passwordValidate(values.password)
    }

    return errors1
};