import React from 'react'
// import '/assets/web_img/left_search_img.jpg' from "../../assets/web_img/'/assets/web_img/left_search_img.jpg'.jpg";
// import booklist_img from "../../assets/web_img/booklist_img.jpg";
// import booklist_img1 from "../../assets/web_img/booklist_img1.jpg";

function BooksList() {
  return (
    <div className='container'>
        <div className='row'>
            <div className='col-lg-2 col-md-3'>
              <div className='book_list_left'>
                  <img src={'/assets/web_img/left_search_img.jpg'} alt="search_img" />
              </div>
            </div>
            <div className='col-lg-10 col-md-9 book_list_right'>
                <div className='row'>
                 {/* Loop start */}
                    <div className='book_list_box'>
                        <div className='booklist_img'>
                          <div className='view_book'><span>View Book</span></div>
                          <img src={'/assets/web_img/booklist_img.jpg'} alt="search_img" />
                        </div>
                        <div className='booklist_content'>
                          <h2>Book Headding</h2>
                          <span>Posted By <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" width="15px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g></svg> 
                          7-09-2022</span>
                          <p>Shankar Das </p>
                        </div>
                    </div>
                {/* Loop end */}

                {/* Loop start */}
                <div className='book_list_box'>
                        <div className='booklist_img'>
                          <img src={'/assets/web_img/booklist_img1.jpg'} alt="search_img" />
                        </div>
                        <div className='booklist_content'>
                          <h2>Book Headding</h2>
                          <span>Posted By <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" width="15px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g></svg> 
                          7-09-2022</span>
                          <p>Shankar Das</p>
                        </div>
                    </div>
                {/* Loop end */}

                  {/* Loop start */}
                  <div className='book_list_box'>
                        <div className='booklist_img'>
                          <div className='view_book'><span>View Book</span></div>
                          <img src={'/assets/web_img/booklist_img.jpg'} alt="search_img" />
                        </div>
                        <div className='booklist_content'>
                          <h2>Book Headding</h2>
                          <span>Posted By <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" width="15px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g></svg> 
                          7-09-2022</span>
                          <p>Shankar Das </p>
                        </div>
                    </div>
                {/* Loop end */}

                {/* Loop start */}
                <div className='book_list_box'>
                        <div className='booklist_img'>
                          <img src={'/assets/web_img/booklist_img1.jpg'} alt="search_img" />
                        </div>
                        <div className='booklist_content'>
                          <h2>Book Headding</h2>
                          <span>Posted By <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" width="15px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g></svg> 
                          7-09-2022</span>
                          <p>Shankar Das</p>
                        </div>
                    </div>
                {/* Loop end */}
                 {/* Loop start */}
                 <div className='book_list_box'>
                        <div className='booklist_img'>
                          <div className='view_book'><span>View Book</span></div>
                          <img src={'/assets/web_img/booklist_img.jpg'} alt="search_img" />
                        </div>
                        <div className='booklist_content'>
                          <h2>Book Headding</h2>
                          <span>Posted By <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" width="15px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g></svg> 
                          7-09-2022</span>
                          <p>Shankar Das </p>
                        </div>
                    </div>
                {/* Loop end */}

               {/* Loop start */}
               <div className='book_list_box'>
                        <div className='booklist_img'>
                          <div className='view_book'><span>View Book</span></div>
                          <img src={'/assets/web_img/booklist_img.jpg'} alt="search_img" />
                        </div>
                        <div className='booklist_content'>
                          <h2>Book Headding</h2>
                          <span>Posted By <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" width="15px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g></svg> 
                          7-09-2022</span>
                          <p>Shankar Das </p>
                        </div>
                    </div>
                {/* Loop end */}

                {/* Loop start */}
                <div className='book_list_box'>
                        <div className='booklist_img'>
                          <img src={'/assets/web_img/booklist_img1.jpg'} alt="search_img" />
                        </div>
                        <div className='booklist_content'>
                          <h2>Book Headding</h2>
                          <span>Posted By <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" width="15px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g></svg> 
                          7-09-2022</span>
                          <p>Shankar Das</p>
                        </div>
                    </div>
                {/* Loop end */}

                  {/* Loop start */}
                  <div className='book_list_box'>
                        <div className='booklist_img'>
                          <div className='view_book'><span>View Book</span></div>
                          <img src={'/assets/web_img/booklist_img.jpg'} alt="search_img" />
                        </div>
                        <div className='booklist_content'>
                          <h2>Book Headding</h2>
                          <span>Posted By <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" width="15px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g></svg> 
                          7-09-2022</span>
                          <p>Shankar Das </p>
                        </div>
                    </div>
                {/* Loop end */}

                {/* Loop start */}
                <div className='book_list_box'>
                        <div className='booklist_img'>
                          <img src={'/assets/web_img/booklist_img1.jpg'} alt="search_img" />
                        </div>
                        <div className='booklist_content'>
                          <h2>Book Headding</h2>
                          <span>Posted By <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" width="15px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g></svg> 
                          7-09-2022</span>
                          <p>Shankar Das</p>
                        </div>
                    </div>
                {/* Loop end */}
                 {/* Loop start */}
                 <div className='book_list_box'>
                        <div className='booklist_img'>
                          <div className='view_book'><span>View Book</span></div>
                          <img src={'/assets/web_img/booklist_img.jpg'} alt="search_img" />
                        </div>
                        <div className='booklist_content'>
                          <h2>Book Headding</h2>
                          <span>Posted By <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"  viewBox="0 0 24 24" width="15px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z"/></g></svg> 
                          7-09-2022</span>
                          <p>Shankar Das </p>
                        </div>
                    </div>
                {/* Loop end */}
                
                </div>
            </div>
        </div>
    </div>
  )
}

export default BooksList