import React, { useEffect, useRef, useState } from 'react'
// import {helloWorld, helloWorld1} from '../getFunctions'
import axios from "axios"
import { config } from '../../constant'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"

const getpostSubCategoryUrl = config.url.API_URL + 'postsubcategory/domains'
const getSubCategoryUrl = config.url.API_URL + 'postsubcategory'

function Subcategory(props) {
    const [postSubCategory, setpostSubCategory] = useState([])
    const [inputValue, setInputValue] = useState({})
    const [editInputValue, setEditInputValue] = useState({})
    const [editSwitch, setEditSwitch] = useState(false)
    const [catId, setCatId] = useState('')
    const reFocus = useRef(null)


    const getCategory = async e => {
        const response = await axios.get(getpostSubCategoryUrl)
        setpostSubCategory(await response.data.responseData)
    }

    const handleChange = e => {
        setInputValue(values => ({ ...values, [e.target.name]: e.target.value }))
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const data = {
            referencePostCategory: inputValue.selectedDomain,
            postSubCategory: inputValue.subDomainName
        }
        console.log(data)
        const response = await axios.post(getSubCategoryUrl + '/create', data)
        if (response) {
            getCategory()
            setInputValue(values => ({ ...values, subDomainName: '' }))

        }
    }

    const handleEditChange = e => {
        setEditInputValue(values => ({ ...values, [e.target.name]: e.target.value }))
    }

    const handleEdit = (id, name) => {
        setEditInputValue(values => ({ ...values, editSubDomain: name }))
        setCatId(id)
        setEditSwitch(true)
        if(editSwitch)
            reFocus.current.focus()
    }

    const handleUpdate = async e => {
        e.preventDefault()
        if (!catId) return
        if (!editInputValue) return
        const data = {
            referencePostCategory: editInputValue.postDomain,
            postSubCategory: editInputValue.editSubDomain
        }

        const response = await axios.patch(getSubCategoryUrl + '/' + catId, data)
        if (response) {
            getCategory()
            setEditSwitch(false)
        }
    }

    const deleteCategory = async (id, name) => {
        const result = window.confirm("Are you sure to Delete this " + name + " Category? \n please note: if you delete this category, Subcategories under this category will be deleted. it will occer some problem in future")
        if (!result) return
        const response = await axios.delete(getSubCategoryUrl + '/subdomain/' + id)
        getCategory()
    }

    useEffect(() => {
        getCategory()
    }, [])

    useEffect(() => {
        getCategory()
    }, [props.contV])

    return (
        <div>
            <div className='mt-3 p-3 box_1 shadow_1'>
                <h4 className='cat_title'>Sub Domains</h4>
                {editSwitch ?
                    (
                        <>
                            <div>
                                <label htmlFor="post category">Select Domain</label>
                                <div className="d-flex gap-3">
                                    <select
                                        name="postDomain"
                                        className='form-control form-control-xl mt-2'
                                        onChange={handleEditChange}
                                        ref={reFocus} 
                                    >
                                        <option >Select Category</option>
                                        {postSubCategory.map((item, key) =>
                                            <option key={key} value={item._id} >{item.postCategory}</option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className='mt-3'>
                                <label htmlFor="post category" >Edit Sub Domain</label>
                                <div className="d-flex gap-3">
                                    <input type="text" list="postSubCategory"
                                        className="form-control form-control-xl mt-2"
                                        autoComplete="off"
                                        placeholder="Enter post category name here"
                                        name="editSubDomain"
                                        onChange={handleEditChange}
                                        
                                        value={editInputValue.editSubDomain || ''}
                                    />
                                    <button className='btn btn-primary' onClick={handleUpdate}>Save</button>
                                    <button className='btn btn-danger' onClick={() => setEditSwitch(false)}>Cancel</button>
                                </div>
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div>
                                <label htmlFor="post category">Select Domain</label>
                                <div className="d-flex gap-3">
                                    <select
                                        name="selectedDomain"
                                        className='form-control form-control-xl mt-2'
                                        onChange={handleChange}
                                    >
                                        <option >Select Category</option>
                                        {postSubCategory.map((item, key) =>
                                            <option key={key} value={item._id} >{item.postCategory}</option>
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className='mt-3'>
                                <label htmlFor="post category" >Add Sub Domain</label>
                                <div className="d-flex gap-3">
                                    <input type="text"
                                        className="form-control form-control-xl mt-2"
                                        autoComplete="off"
                                        placeholder="Enter post category name here"
                                        name="subDomainName"
                                        onChange={handleChange}
                                        value={inputValue.subDomainName || ''}
                                    />
                                    <button className='btn btn-primary' onClick={handleSubmit}>Save</button>
                                </div>
                            </div>
                        </>
                    )

                }

                <div className='mt-4'>
                    {postSubCategory.map((item, index) => {
                        return (
                            <div key={index}>
                                <h6>{index + 1 + '. ' + item.postCategory}</h6>
                                <div className="category_box1 my-3" >

                                    {item.subDomain ?
                                        item.subDomain.map((subd, key) => {
                                            return (
                                                <div className="category_in_box shadow_1" key={key}>
                                                    {subd.postSubCategory}
                                                    <div className="action_icons">
                                                        <FontAwesomeIcon icon={faEdit} className="post_icons text-success c_pointer" onClick={() => handleEdit(subd._id, subd.postSubCategory)}></FontAwesomeIcon>
                                                        <FontAwesomeIcon icon={faTrash} className="post_icons text-danger c_pointer" onClick={() => deleteCategory(subd._id, subd.postSubCategory)}></FontAwesomeIcon>
                                                    </div>

                                                </div>
                                            )
                                        }) : ''}
                                </div>

                            </div>


                        )
                    })}
                </div>
            </div>
            <div className="box_1">

            </div>
        </div>
    )
}

export default Subcategory