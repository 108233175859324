export const ACTION_GET_MYSPACE_USER = "ACTION_GET_MYSPACE_USER"
export const ACTION_GET_MYFRIENDS = "ACTION_GET_MYFRIENDS"
export const ACTION_USER_GET_STATE_MANAGEMENT = "ACTION_USER_GET_STATE_MANAGEMENT"

const initialState = {
    mySpaceUser: null,
    myFriends: [],
    requestList: [],
    sendList: [],
    isError: false,
    isProcessing: null,
    isSuccess: null,
    message: null,
}

export const usersReducer = (state = initialState, action) => {
    let returnObj = { ...state }
    switch (action.type) {
        case ACTION_GET_MYFRIENDS:
            returnObj.myFriends = action?.page > 1 ? [...state?.myFriends, ...action?.payload] : action?.payload
            return returnObj
        case ACTION_USER_GET_STATE_MANAGEMENT:
            returnObj[action?.state] = action?.page > 1 ? [...state[action?.state], ...action?.payload] : action?.payload
            return returnObj
        case ACTION_GET_MYSPACE_USER:
            returnObj.mySpaceUser = action.payload
            return returnObj

        default:
            return state
    }
}
