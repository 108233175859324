import React, { useEffect, useState } from "react"
import { Footer, IndexNavbar } from "../../components"
import { connect, useDispatch } from "react-redux"
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { LDS } from "../../components/Loaders/SimpleCircle"
import { PremiumCard, PremiumListNav } from "."
import { useLocation } from "react-router-dom"

function AllPremiumPage(props) {
    const path = useLocation()
    const [loader, setLoader] = useState(true)
    const { premiumList } = props
    const dispatch = useDispatch()
    // const [Q, setQ] = useState("")
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    // const [initialLoad, setInitialLoad] = useState(true)


    const init = async () => {
        try {
            if(!hasMore) return setHasMore(false)
            let params = {
                list: true,
                mode: 'pre', 
                page: page,
            }
            if (path?.pathname.split('/')[2])
                params.myorg = true
            // if (Q) params.q = Q
            let req = {
                params: params,
                url: 'getOrganizationDetails',
                action: 'ACTION_GET_PREMIUM_LIST'
            }
            const response = await dispatch(getRequestHandler(req))
            // setInitialLoad(false)
            if (response?.data?.responseData?.length < 12) setHasMore(false)
        } catch (err) {
            console.error(err)
        }
    }

    // const handleChange = (e) => {
    //     setQ(e.target.value)
    // }

    const handleScroll = (e) => {
        let scrollTop = window.pageYOffset
        let clientHeight = window.document.documentElement.clientHeight
        let bodyHeight = window.document.body.clientHeight

        if (clientHeight + scrollTop > bodyHeight - 100) {
            window.removeEventListener("scroll", handleScroll)
            setPage(page + 1)
        }
    }



    useEffect(() => {
        if (premiumList?.length > 0) {
            window.addEventListener("scroll", handleScroll)
            setLoader(false)
        }
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [premiumList])

    useEffect(() => {
        if (loader) setTimeout(() => setLoader(false), [3000])
    }, [loader])

    // useEffect(() => {
    //     if (Q) init()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [Q])

    useEffect(() => {
        if (page > 1) init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <>
            <IndexNavbar />
            {/* <section className="organisation_list"></section> */}
            <section className="organisation_list" style={{ minHeight: "100vh" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 bg-white mt-3 br_5 shadow_1 p-3">
                            <h2 className="blog_heading mb-4">Premium Creators</h2>

                            <div className="d-flex justify-content-between align-items-center flex_wrap">
                                <PremiumListNav />
                                {/* <div className="mb_w_100">
                                    <input
                                        type="search"
                                        name="search"
                                        id="memberSearch"
                                        className="member_search_1 outline_1"
                                        placeholder="Find members"
                                        onChange={handleChange}
                                        value={Q || ""}
                                    />
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container mt-2 mb-4">
                    <div className="row">
                        {loader ?
                            <div className="load_body">
                                <LDS />
                            </div> :
                            premiumList?.length > 0 ? (
                                premiumList?.map((item, index) => (
                                    <PremiumCard
                                        item={item}
                                        index={index}
                                    />
                                ))
                            ) : (
                                <>
                                    <h1>No premium page found</h1>
                                </>
                            )
                        }

                        {page > 1 && loader &&
                            <div className="d-flex justify-content-center">
                                <LDS />
                            </div>
                        }
                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}

AllPremiumPage.loadData = (params, search) => async (dispatch) => {
    console.log("fdfd", params)
    const parr = params?.split('/')
    let paramsx = {
        list: true,
        mode: 'pre',
        page: 1
    }
    if (parr[2])
        paramsx.myorg = true
    let req = {
        params: paramsx,
        url: 'getOrganizationDetails',
        action: 'ACTION_GET_PREMIUM_LIST'
    }
    return await dispatch(getRequestHandler(req))
}
const mapStateToProps = (state) => {
    console.log("stateoRgpostcreationpostsMembers", state)
    const { premiumList } =
        state.premium
    // const { members } = state.members
    return { premiumList }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(AllPremiumPage)
