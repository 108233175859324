import React, { useState, useEffect } from "react"
import {
    Link,
    Outlet,
    useParams,
    useLocation,
    useNavigate,
} from "react-router-dom"

import axios from "axios"
import { config } from "../../constant"
import { Helmet } from "react-helmet"
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import FriendsCheck from "../../components/FriendsCheck"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import LoginStatus from "../../components/DesignComponents/LoginStatus"
import { ImageUploadDialog } from "../../../_helpers/Material"
import { OrgFollow, OrgGalleryPosts, OrgLike } from "../CreateOrganisation"
import { Footer, IndexNavbar } from "../../components"
import { OrgProfileInfo } from "../OrganizationDetails/OrgDetailsSection"

const getUserUrl = config.url.API_URL + "users/getusers?mode=2&userName="
const ORG_URL = config.url.API_URL + "org/"
const POST_URL = config.url.API_URL + "posts/"

function OrganizationPhotos() {
    const [orgDetails, setOrgDetails] = useState({})
    const [singleUser, setSingleUser] = useState([])
    const [profileUser, setProfileUser] = useState([])

    const [modalShow, setModalShow] = useState(false)
    const [PathInfo, setPathInfo] = useState("")
    const [uploadedFor, setUploadedFor] = useState("")
    const param = useParams()
    const { name } = useParams()
    const path = useLocation()
    const navigate = useNavigate()

    const token = typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const currentUser = typeof window !== "undefined" ? JSON.parse(localStorage.getItem("currentuser")) : ""

    const handleModalClick = (path) => {
        setModalShow(true)
        setPathInfo(path)
        setUploadedFor(path === 1 ? "logo" : "background")

    }

    const getProfileUser = async () => {
        const response = await axios.get(getUserUrl + name)
        console.log("getProfileUser", response)
        // if (response.data.ack === 0) return navigate("/notfound")
        setProfileUser(response.data.responseData[0])
    }

    const getSingleUser = () => setSingleUser(JSON.parse(localStorage.getItem("currentuser")))



    useEffect(() => {
        getSingleUser()
        // console.log(localStorage.getItem("currentuser"))
    }, [param])


    useEffect(() => {
        getSingleUser()
    }, [modalShow])

    const getPageDetails = async () => {
        try {
            const response = await axios.get(ORG_URL + `getorgdetails?name=${name}`)
            console.log("organisation details", response)
            setOrgDetails(response?.data?.responseData[0])
        } catch (err) {
            console.error(err)
        }
    }

    useEffect(() => {
        getPageDetails()
    }, [name])

    useEffect(() => {
        console.log(orgDetails)
    }, [orgDetails])

    return (
        <>
        <Helmet>
				<meta charSet="utf-8" />
				<title>{`Allphanes | page`}</title>
				<link rel="canonical" href={`https://allphanes.com/creations`} />
			</Helmet>
            <IndexNavbar />
            <div
                id="background_bg"
                style={{ backgroundImage: `url(${'/assets/web_img/background_bg.jpg'})` }}
            >
                <div
                    className="profile_cover"
                    style={{
                        backgroundImage: `url(${orgDetails?.BackgroundImage || '/assets/web_img/choto_logo_1.png'})`,
                    }}>


                    {orgDetails?.userid === token ? (
                        <button
                            type="button"
                            className="edit_cover_pic_btn shadow"
                            title="Change cover photo"
                            onClick={() => handleModalClick(0)}
                        >
                            <CropOriginalIcon />
                        </button>
                    ) : (
                        ""
                    )}
                </div>
                <div>
                    <div className="user_pro_sec container ">
                        <div className="row">
                            <div className="col-lg-12 d-flex justify-content-between">
                                <div className="kk_modi">
                                    <div className="profile_outer_photo bg_allp2">
                                        <div className="profile_inner_photo">
                                            <div className="img_box">
                                                <img
                                                    src={orgDetails?.organiztionLogo || `/assets/web_img/choto_logo_1.png`}
                                                    alt={orgDetails?.organiztionLogo + "profile photo"}
                                                    // eslint-disable-next-line no-sequences
                                                    onError={e => (e.target.onerror = null, e.target.src = `/assets/web_img/choto_logo_1.png`)}
                                                    className="img_box" />
                                            </div>

                                            {orgDetails?.userid === token ? (
                                                <div
                                                    title="Change profile photo"
                                                    className="edit_pro text-center mt-1"
                                                    onClick={() => handleModalClick(1)}
                                                >
                                                    <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    <OrgProfileInfo orgDetails={orgDetails} token={token} />


                                </div>


                            </div>
                            {/* social links */}
                        </div>

                    </div>

                </div>

                {modalShow && <ImageUploadDialog pathinfo={PathInfo} close={() => setModalShow(false)} uploadapi={uploadedFor} orgid={orgDetails?._id} />}

                <div className="container social_details justify-content-between mt-3">
                    <div className="organization_post_left">
                        <a className="sd_tex active" href={`/org/${param.name}/about/`}>About</a>
                        <a className="sd_tex" href={`/org/${param.name}/posts/`}>Posts</a>
                        <a className="sd_tex" href={`/org/${param.name}/photos/`}>Photos</a>
                        <a className="sd_tex" href={`/org/${param.name}/videos/`}>Videos</a>
                        <a className="sd_tex" href={`/org/${param.name}/members/`}>Members</a>
                        <a className="sd_tex" href={`/org/${param.name}/followers/`}>Followers</a>
                    </div>

                    <div className="organiza_follow_us">
                        {orgDetails?.userid === token ? "" : <OrgFollow orgDetails={orgDetails} />}
                    </div>
                </div>

                <div className="container">
                    <div className="row justify-content-center">
                        {/* Middle section  */}
                        <div className="col-lg-12 col-md-10">
                            <section id="profile" className="mt-3">
                                {orgDetails?.userid && (
                                    <>
                                        <OrgGalleryPosts orgDetails={orgDetails} />
                                    </>
                                )}
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            {!token && <LoginStatus showname={profileUser?.showName} />}
            <Footer />
        </>
    )
}

export default OrganizationPhotos