import moment from 'moment'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import EditEventModal from '../../components/Post/EditEvent'
import { deletePostManagement, getPostManagement } from '../../redux/reducers/postReducer'
import Dropdown from 'react-bootstrap/Dropdown';
import { DeleteAction } from '../../components/Postsk'
import { getOrganizationPosts } from '../../redux/reducers/organizationReducer'
import EditIcon from "@mui/icons-material/Edit"

export const PrivatePostCard = props => {
    const { showStatus, post, isOwner, publishPost, isPre } = props
    const dispatch = useDispatch()
    const token =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const deletePost = async params => {
        await dispatch(deletePostManagement(params))
        if (isOwner) await dispatch(getOrganizationPosts({
            orgid: post?.pageId,
            status: 'draft|published',
            postType: isPre || 'event'
        }))
    }
    // const deletePost = 
    // console.log("location", location)
    return (
        <>
            <div className="mb-3 card shadow-sm rounded p-3 pt-0 post_main_col" key={post?._id}>
                <div className="post-creator d-flex justify-content-between align-items-center">
                    <div className="posted-user">
                        <div className="posted-user-d">
                            <Link
                                to={/pre/.test(post?.org_info[0]?.mode) ? `/premium/${post?.org_info[0]?.pageName}/#freepost/` : `/org/${post?.org_info[0]?.pageName}/#post/#active/`}
                                className="pro_img">
                                <img
                                    src={post?.org_info[0]?.organiztionLogo || '/assets/web_img/choto_logo_1.png'}
                                    alt={post?.org_info[0]?.organiztionLogo}
                                />
                            </Link>
                            <div className="post-user-name">{post?.postTitle}
                                {showStatus && <div className="fs_12">{post?.status}</div>}
                            </div>

                            {post?.referenceUserId === token && /draft/.test(post?.status) &&

                                <div className='post_drop_down d-flex'>

                                    <EditEventModal
                                        post={post}
                                        postType={isPre || 'event'}
                                        component={<div className='kk'>
                                            <EditIcon />
                                        </div>}
                                    />

                                    <Dropdown className='remove_drop'>
                                        <Dropdown.Toggle className='remove_drop p-0' variant="white" id="dropdown-basic">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 896q-33 0-56.5-23.5T400 816q0-33 23.5-56.5T480 736q33 0 56.5 23.5T560 816q0 33-23.5 56.5T480 896Zm0-240q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm0-240q-33 0-56.5-23.5T400 336q0-33 23.5-56.5T480 256q33 0 56.5 23.5T560 336q0 33-23.5 56.5T480 416Z" /></svg>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className='p-0 m-0'>
                                            <>

                                                <div className='p-3 edit_drop'>
                                                    <DeleteAction
                                                        execute={deletePost}
                                                        params={{ pid: post?._id }}
                                                        alertTitle={"Delete Post"}
                                                        alertMsg={"Are you sure to delete this post."}
                                                        setConfirm={"setConfirm"}
                                                        component={<div className='mb-2'><span class="material-symbols-outlined float-start">
                                                            delete
                                                        </span> Delete Post
                                                        </div>}

                                                    />
                                                    <div
                                                    onClick={() => publishPost({ eventId: post?._id, status: 'published' })}
                                                    >
                                                    <span class="material-symbols-outlined float-start">
                                                        publish
                                                    </span> Publish</div>
                                                </div>
                                            </>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            }

                        </div>

                    </div>
                </div>
                {post?.status === 'draft' && isOwner ?
                    <EditEventModal
                        post={post}
                        postType={isPre || 'event'}
                        component={
                            <>
                                <div className="card event_back" style={{
                                    backgroundImage: `url(${post?.thumbnail})`
                                }}>
                                    <div className="event-overlay"></div>
                                    <div className="position-absolute d-flex event_small_details justify-content-between p-4 text-white">
                                        <div>
                                            <h3 className="fs-5 text">Release  Date </h3>
                                            <h2 className="fs-4 text-success">{moment(post?.publishDate).format("DD-MM-YYYY")}</h2>
                                        </div>
                                        <div className="border-start"></div>
                                        <div>
                                            <small className="fs-6">Posted by:</small>
                                            <h3 className="fs-5">{post?.org_info[0]?.showName || post?.org_info[0]?.pageName}</h3>
                                            <svg className="float-start m-0 pt-0 fill_white" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m9.15 17.15 8-5.15-8-5.15ZM12 22.8q-2.25 0-4.213-.85-1.962-.85-3.424-2.312Q2.9 18.175 2.05 16.212 1.2 14.25 1.2 12t.85-4.225Q2.9 5.8 4.363 4.338q1.462-1.463 3.424-2.301Q9.75 1.2 12 1.2t4.225.837q1.975.838 3.438 2.301 1.462 1.462 2.299 3.437Q22.8 9.75 22.8 12q0 2.25-.838 4.212-.837 1.963-2.299 3.426Q18.2 21.1 16.225 21.95q-1.975.85-4.225.85Zm0-2.65q3.425 0 5.788-2.363Q20.15 15.425 20.15 12t-2.362-5.788Q15.425 3.85 12 3.85q-3.425 0-5.787 2.362Q3.85 8.575 3.85 12q0 3.425 2.363 5.787Q8.575 20.15 12 20.15ZM12 12Z" /></svg>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    />
                    :
                    <Link
                        to={`/post/${post?._id}/`}
                    >
                        <div className="card event_back" style={{
                            backgroundImage: `url(${post?.thumbnail})`
                        }}>
                            <div className="event-overlay"></div>
                            <div className="position-absolute d-flex event_small_details justify-content-between p-4 text-white">
                                <div>
                                    <h3 className="fs-5 text">Release  Date </h3>
                                    <h2 className="fs-4 text-success">{moment(post?.publishDate).format("DD-MM-YYYY")}</h2>
                                </div>
                                <div className="border-start"></div>
                                <div>
                                    <small className="fs-6">Posted by:</small>
                                    <h3 className="fs-5">{post?.org_info[0]?.showName || post?.org_info[0]?.pageName}</h3>
                                    <svg className="float-start m-0 pt-0 fill_white" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m9.15 17.15 8-5.15-8-5.15ZM12 22.8q-2.25 0-4.213-.85-1.962-.85-3.424-2.312Q2.9 18.175 2.05 16.212 1.2 14.25 1.2 12t.85-4.225Q2.9 5.8 4.363 4.338q1.462-1.463 3.424-2.301Q9.75 1.2 12 1.2t4.225.837q1.975.838 3.438 2.301 1.462 1.462 2.299 3.437Q22.8 9.75 22.8 12q0 2.25-.838 4.212-.837 1.963-2.299 3.426Q18.2 21.1 16.225 21.95q-1.975.85-4.225.85Zm0-2.65q3.425 0 5.788-2.363Q20.15 15.425 20.15 12t-2.362-5.788Q15.425 3.85 12 3.85q-3.425 0-5.787 2.362Q3.85 8.575 3.85 12q0 3.425 2.363 5.787Q8.575 20.15 12 20.15ZM12 12Z" /></svg>
                                </div>
                            </div>
                        </div>
                    </Link>
                }
                <div className="d-flex justify-content-between">
                    <div className="mt-1">
                        <button type="button" className="btn btn-btn-light text-success">
                            <svg className="float-start m-0 pt-0 fill_success" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m9.15 17.15 8-5.15-8-5.15ZM12 22.8q-2.25 0-4.213-.85-1.962-.85-3.424-2.312Q2.9 18.175 2.05 16.212 1.2 14.25 1.2 12t.85-4.225Q2.9 5.8 4.363 4.338q1.462-1.463 3.424-2.301Q9.75 1.2 12 1.2t4.225.837q1.975.838 3.438 2.301 1.462 1.462 2.299 3.437Q22.8 9.75 22.8 12q0 2.25-.838 4.212-.837 1.963-2.299 3.426Q18.2 21.1 16.225 21.95q-1.975.85-4.225.85Zm0-2.65q3.425 0 5.788-2.363Q20.15 15.425 20.15 12t-2.362-5.788Q15.425 3.85 12 3.85q-3.425 0-5.787 2.362Q3.85 8.575 3.85 12q0 3.425 2.363 5.787Q8.575 20.15 12 20.15ZM12 12Z" /></svg>
                            <span className="">{post?.totalppvSubcrition || post?.totalPPVSubscription || 0} PPV Subscriptions</span>
                        </button>
                    </div>
                </div>
            </div >

        </>)
}

export const ExpiredPrivatePostCard = props => {
    const { predirect } = props

    return (
        <>
            <div className="mb-3 card shadow-sm rounded p-3 pt-0">
                <div className="overlay_expried position-absolute">
                    <div className="text-center expired_btn">
                        <h4>Expired</h4>
                        <Link to={predirect}>
                            <button type="button" className="btn btn-success m-2 p-4 pb-2 pt-2">Renew</button>
                        </Link>
                        <button type="button" className="btn btn-danger m-2 p-4 pb-2 pt-2">Close</button>
                    </div>
                </div>
                <div className="post-creator d-flex justify-content-between align-items-center">
                    <Link className="posted-user" to={"#"}>
                        <div className="posted-user-d">
                            <div className="pro_img">
                                <img src="https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-09-23T01%3A38%3A24.424Z-02b2131-bd55-2d13-fd0a-fd35516ba446-1663897102516.jpg" alt="SDS" />
                            </div>
                            <div className="post-user-name">Event Name <div className="fs_12">Event Title</div>
                            </div>
                        </div>
                    </Link>
                </div>
                <Link
                    to={predirect}>
                    <div className="card event_back" style={{
                        backgroundImage: `url("/assets/web_img/even_img.jpg")`
                    }}>
                        <div className="event-overlay"></div>
                        <div className="position-absolute d-flex event_small_details justify-content-between p-4 text-white">
                            <div>
                                <h3 className="fs-5 text">Release  Date</h3>
                                <h2 className="fs-4 text-success">26-02-2023</h2>
                            </div>
                            <div className="border-start"></div>
                            <div>
                                <small className="fs-6">Posted by:</small>
                                <h3 className="fs-5">Shibaprasad</h3>
                                <svg className="float-start m-0 pt-0 fill_white" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m9.15 17.15 8-5.15-8-5.15ZM12 22.8q-2.25 0-4.213-.85-1.962-.85-3.424-2.312Q2.9 18.175 2.05 16.212 1.2 14.25 1.2 12t.85-4.225Q2.9 5.8 4.363 4.338q1.462-1.463 3.424-2.301Q9.75 1.2 12 1.2t4.225.837q1.975.838 3.438 2.301 1.462 1.462 2.299 3.437Q22.8 9.75 22.8 12q0 2.25-.838 4.212-.837 1.963-2.299 3.426Q18.2 21.1 16.225 21.95q-1.975.85-4.225.85Zm0-2.65q3.425 0 5.788-2.363Q20.15 15.425 20.15 12t-2.362-5.788Q15.425 3.85 12 3.85q-3.425 0-5.787 2.362Q3.85 8.575 3.85 12q0 3.425 2.363 5.787Q8.575 20.15 12 20.15ZM12 12Z" /></svg>
                            </div>
                        </div>
                    </div>
                </Link>
                <div className="d-flex justify-content-between">
                    <div className="flex-wrap">
                        <button className="btn-white border border-light bg-white shadow-none mt-2" >
                            <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="m10 17.75-1.375-1.271Q6.354 14.417 4.927 13 3.5 11.583 2.698 10.521q-.802-1.063-1.083-1.948-.282-.885-.282-1.844 0-2.083 1.459-3.531Q4.25 1.75 6.354 1.75q.938 0 1.917.385.979.386 1.729 1.073.75-.687 1.729-1.073.979-.385 1.917-.385 2.104 0 3.562 1.448 1.459 1.448 1.459 3.531 0 .959-.271 1.823-.271.865-1.073 1.917-.802 1.052-2.24 2.489-1.437 1.438-3.75 3.563Zm0-2.771q1.979-1.791 3.25-3.031 1.271-1.24 2.031-2.167.761-.927 1.052-1.635.292-.708.292-1.417 0-1.25-.844-2.094-.843-.843-2.135-.843-.688 0-1.344.312-.656.313-.906.667l-.729.854H9.333l-.729-.854q-.25-.354-.927-.667Q7 3.792 6.354 3.792q-1.292 0-2.135.843-.844.844-.844 2.094 0 .709.271 1.386.271.677 1.01 1.593.74.917 2.021 2.167 1.281 1.25 3.323 3.104Zm0-5.604Z" /></svg><span className="p-1">Like</span> </button>

                        <button type="button" className="btn btn-btn-light">
                            <svg className="float-start m-0 pt-0" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#2570c3"><g><rect fill="none" height="24" width="24"></rect><rect fill="none" height="24" width="24"></rect></g><g><g><polygon points="22,9 22,7 20,7 20,9 18,9 18,11 20,11 20,13 22,13 22,11 24,11 24,9"></polygon><path d="M8,12c2.21,0,4-1.79,4-4s-1.79-4-4-4S4,5.79,4,8S5.79,12,8,12z"></path><path d="M8,13c-2.67,0-8,1.34-8,4v3h16v-3C16,14.34,10.67,13,8,13z"></path><path d="M12.51,4.05C13.43,5.11,14,6.49,14,8s-0.57,2.89-1.49,3.95C14.47,11.7,16,10.04,16,8S14.47,4.3,12.51,4.05z"></path><path d="M16.53,13.83C17.42,14.66,18,15.7,18,17v3h2v-3C20,15.55,18.41,14.49,16.53,13.83z"></path></g></g></svg>
                            <span className="p-1">Comment </span></button>
                    </div>
                    <div className="mt-1">
                        <button type="button" className="btn btn-btn-light text-success">
                            <svg className="float-start m-0 pt-0 fill_success" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="m9.15 17.15 8-5.15-8-5.15ZM12 22.8q-2.25 0-4.213-.85-1.962-.85-3.424-2.312Q2.9 18.175 2.05 16.212 1.2 14.25 1.2 12t.85-4.225Q2.9 5.8 4.363 4.338q1.462-1.463 3.424-2.301Q9.75 1.2 12 1.2t4.225.837q1.975.838 3.438 2.301 1.462 1.462 2.299 3.437Q22.8 9.75 22.8 12q0 2.25-.838 4.212-.837 1.963-2.299 3.426Q18.2 21.1 16.225 21.95q-1.975.85-4.225.85Zm0-2.65q3.425 0 5.788-2.363Q20.15 15.425 20.15 12t-2.362-5.788Q15.425 3.85 12 3.85q-3.425 0-5.787 2.362Q3.85 8.575 3.85 12q0 3.425 2.363 5.787Q8.575 20.15 12 20.15ZM12 12Z" /></svg>
                            <span className="">15 Subscriptions</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

function OrgPrivatePosts(props) {

    const { privatePosts, predirect } = props
    const dispatch = useDispatch()
    const currentUserId =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const getPost = async () => {
        await dispatch(getPostManagement({
            token: currentUserId,
            isPrivatePosts: true,
            postType: "event",
            skip: 0
        }))
    }
    useEffect(() => {
        getPost()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <h2 className="fs-5 mt-4 mb-4">Private Posts</h2>
            <div className="row">
                {privatePosts?.length > 0 && privatePosts?.map((item, i) =>
                    <div className="col-sm-4" key={i}>
                        <PrivatePostCard
                            predirect={predirect}
                            post={item}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    console.log("orglist", state);
    const { isSuccess, isError, privatePosts } = state.posts;
    return {
        isSuccess,
        isError,
        privatePosts,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(OrgPrivatePosts);

// 7894480118
// abijitroy1960@gmail.com