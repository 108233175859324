import React from 'react'
import { Footer, IndexNavbar } from '../../components'
import ProfileAnalytics from '../../components/Profile/ProfileAnalytics'
import ProfileSettingsNav from '../../components/Settings/ProfileSettingsNav'

function CompetitionAnalyctics() {
  return (
    <>
    <IndexNavbar />
    <div className="container">
    <ProfileSettingsNav />

    <ProfileAnalytics />

    </div>
    <Footer />
    </>
  )
}

export default CompetitionAnalyctics