import React, { Component } from "react"
import {
  // BrowserRouter,
  Routes,
  Route,
  // useParams,
  useLocation,
  Navigate,
} from "react-router-dom"

import { compose } from "@reduxjs/toolkit"
import { connect } from "react-redux"
// import { Navigate } from "react-router"
// import Layout from "./views/pages/Layout"
// import Index from "./views/Index"

const ToRouteComponent = (route, location) => {
  const renderProps = {
    // 	isAuthenticated,

    //	logoutInProgress,
    route,
    location,
    // ...this.props,
  }
  // console.log("010 data>>>", route, useParams())
  //	const isExact = route.exact != null ? route.exact : true
  const isExact = true
  // const { route, match, location, staticContext } = this.props
  return (
    <Route
      key={Math.random()}
      path={route.path}
      exact={isExact}
      //element={<route.component />}
      element={
        <RouteComponentContainer
          {...renderProps}
        //match={matchProps.match}
        //location={matchProps.location}
        //staticContext={matchProps.staticContext}
        />
      }
    />
  )
}

class RouteComponentRenderer extends React.Component {
  componentDidMount() {
    // Calling loadData on initial rendering (on client side).
    callLoadData(this.props)
    // handleLocationChanged(this.props.dispatch, this.props.location)
  }
  componentDidUpdate(prevProps) {
    // Call for handleLocationChanged affects store/state
    // and it generates an unnecessary update.
    // if (prevProps.location !== this.props.location) {
    //   // Calling loadData after initial rendering (on client side).
    //   // This makes it possible to use loadData as default client side data loading technique.
    //   // However it is better to fetch data before location change to avoid "Loading data" state.
    callLoadData(this.props)
    //   handleLocationChanged(this.props.dispatch, this.props.location)
    // }
  }
  render() {
    const { route } = this.props
    // const { route, match, location, staticContext } = this.props
    const canShow = canShowComponent(this.props)
    return canShow ? (
      <route.component {...this.props} routes={route.routes} />
    ) : (
      <Navigate to={route.authPage} />
    )
  }
}
const mapStateToProps = (state) => {
  const { isAuthenticated, logoutInProgress, user } = state.auth
  return { isAuthenticated, logoutInProgress, currentUser: user }
}
const RouteComponentContainer = compose(connect(mapStateToProps))(
  RouteComponentRenderer
)

const canShowComponent = (props) => {
  const { isAuthenticated, route, currentUser } = props
  const { auth, restrict } = route
  if (!auth) {
    return true
  } else if (isAuthenticated) {
    if (restrict && restrict.length > 0) {
      const isExist = restrict.filter((r) => r === currentUser.role)
      if (isExist.length > 0) return true
      else return false
    } else {
      return true
    }
  }
  // return !auth || isAuthenticated
}

const callLoadData = (props) => {
  const { route, dispatch, location } = props
  const { loadData } = route
  // const { match, route, dispatch, logoutInProgress, location } = props
  // const { loadData, name } = route
  // const location = useLocation()
  console.log("101 route1>>", route, location)
  const shouldLoadData = typeof loadData === "function"
  //   &&
  //   canShowComponent(props) &&
  //   !logoutInProgress

  if (shouldLoadData) {
    dispatch(loadData(location?.pathname))
      .then(() => {
        // eslint-disable-next-line no-console
        console.log(`loadData success for ${location?.pathname} route`)
      })
      .catch((e) => {
        console.error(e, "load-data-failed", { routeName: location?.pathname })
      })
  }
}

const withLocation = (Component) => (props) => {
  const location = useLocation()

  return <Component {...props} location={location} />
}

class RouterNew extends Component {
  render() {
    const { routes, location } = this.props
    // const authRoutes = routes.filter((r) => !r?.auth)
    // const restrictedRoutes = routes.filter((r) => r?.auth)
    return (
      // <BrowserRouter>
      <Routes>
        {routes.map((route) => ToRouteComponent(route, location))}
        {/* <Route path="/" element={<Index />}>
            {authRoutes.map(toRouteComponent)}
          </Route>
          <Route path="">{restrictedRoutes.map(toRouteComponent)}</Route> */}
      </Routes>
      // </BrowserRouter>
    )
  }
}
export default withLocation(RouterNew)
