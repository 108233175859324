import React, { useState } from "react"
import { useParams, useLocation, Link, useNavigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import LoginStatus from "../../components/DesignComponents/LoginStatus"
import { Footer, IndexNavbar } from "../../components"
import { connect, useDispatch } from "react-redux"
import {
    getOrganizationDetails,
    getOrganizationFollowers,
    getOrganizationPosts,
    getOrgAnl,
    OrgStorageDetails,
} from "../../redux/reducers/organizationReducer"

import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import { useEffect } from "react"
import CreatePost from "../../components/Post/CreatePost"
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import StorageAnalysis from "../Organization/StorageAnalysis"
import OrgAbout from "../Organization/OrgAbout"
import PremiumPostComponent from "./PremiumPostComponent"
import PremiumFrontSection from "./PremiumFrontSection"
import CreatePremiumPost from "./CreatePremiumPost"
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import moment from "moment"


export function StandardImageList({ itemData }) {
    return (
        <ImageList cols={4} rowHeight={400}>
            {itemData?.map((item) => (
                <ImageListItem key={item?.postInfo[0]?.postImagePath}>
                    <img
                        src={`${item?.postInfo[0]?.postImagePath}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${item?.postInfo[0]?.postImagePath}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item?.postInfo[0]?.postImagePath}
                        loading="lazy"
                    />
                </ImageListItem>
            ))}
        </ImageList>
    )
}

function PremiumPageDetails(props) {
    const { storage, members, freepost, getOrgStorageAnalytics, followers, photos, adminCount, posts, videos, orgDetails, orgAnl, ppvAnalytics } = props
    const [isAdmin, setIsAdmin] = useState(false)
    const [pages, setPages] = useState(1)
    const param = useParams()
    const path = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState({ parent: "post/", child: "active/" })
    const [endApiHit, setEndApiHit] = useState(false)
    const token =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const currentUser =
        typeof window !== "undefined"
            ? JSON.parse(localStorage.getItem("currentuser"))
            : ""
    const Kelmet = () => {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`Allphanes | ${param.name}`}</title>
                <link rel="canonical" href={`https://allphanes.com${path.pathname}`} />
            </Helmet>
        )
    }
    const getFunctions = {
        getOrgSub: async (params) => {
            await dispatch(getOrgAnl({
                pageId: orgDetails?._id,
            }))
        },
        getStorage: async (params) => {
            await getOrgStorageAnalytics({
                pageId: orgDetails?._id,
            })
        },
        getPost: async (params) => {
            let req = {
                params: params,
                url: 'getpreposts',
                action: 'ACTION_GET_DATA_BY_STATE',
                state: 'freepost'
            }
            await dispatch(getRequestHandler(req))
        },
        initAn: async params => {
            try {
                let req = {
                    params: {
                        orgid: orgDetails?._id,
                    },
                    url: 'preAnaPost',
                    action: 'ACTION_GET_DATA_BY_STATE',
                    state: 'ppvAnalytics'
                }
                await dispatch(getRequestHandler(req))
            } catch (error) {
                console.error(error)
            }
        },
        getFollowers: params => dispatch(getOrganizationFollowers(params)),
        managePath: () => {
            const { hash, pathname } = path
            const initialHash = hash.split('#')
            if (!hash) navigate(pathname + '#about/')
            setPages(1)
            setEndApiHit(false)
            setCurrentPage(prev => ({ ...prev, parent: initialHash[1] || '', child: initialHash[2] || '' }))
        },
        manageApiCall: (params) => {
            const { hash } = path
            const initialHash = hash.split('#')
            if (/freepost/.test(initialHash[1]))
                getFunctions.getPost({ ...params, reqPage: 'freepremium', page: 1 })
            if (/storage/.test(initialHash[1]))
                getFunctions.getStorage({ ...params })
            if (/analytics/.test(initialHash[1]))
                getFunctions.getOrgSub({ ...params })
            if (/ppvanalytics/.test(initialHash[1]))
                getFunctions.initAn({ ...params })
        },
    }
    const onLinkClick = (e) => {
        // setKey(e)
        navigate(`#${e}`)
    }
    const handleScroll = (e) => {
        let scrollTop = window.pageYOffset
        let clientHeight = window.document.documentElement.clientHeight
        let bodyHeight = window.document.body.clientHeight

        if (clientHeight + scrollTop > bodyHeight - 200) {
            if (endApiHit) return window.removeEventListener("scroll", handleScroll)
            setPages((pages) => pages + 1)
        }
    }

    useEffect(() => {
        getFunctions.managePath()
        if (orgDetails?._id) getFunctions.manageApiCall({ orgid: orgDetails?._id })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, orgDetails?._id])
    useEffect(() => {
        setIsAdmin(orgDetails?.userid === token ? true : false)
    }, [orgDetails?.userid, token])

    useEffect(() => {
        const hitStatusChange = {
            start: () => {
                setEndApiHit(false)
                window.addEventListener("scroll", handleScroll)
            },
            stop: () => {
                setEndApiHit(true)
                window.removeEventListener("scroll", handleScroll)
            }
        }
        if (currentPage?.parent === 'freepost/')
            posts?.length > 0 && posts?.length < posts[0]?.totalValue ?
                hitStatusChange.start() : hitStatusChange.stop()
        if (currentPage?.parent === 'premiumpost/')
            posts?.length > 0 && posts?.length < posts[0]?.totalValue ?
                hitStatusChange.start() : hitStatusChange.stop()


        return () => window.removeEventListener("scroll", handleScroll)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [posts, photos, videos, followers, members])
    useEffect(() => {
        if (pages > 1) getFunctions.manageApiCall({ page: pages, orgid: orgDetails._id })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pages])

    return (
        <>
            <Kelmet />
            <IndexNavbar />

            <div>
                <PremiumFrontSection
                    orgDetails={orgDetails}
                    adminCount={adminCount}
                    isAdmin={isAdmin}
                />

                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="org_tab">
                                <Tabs defaultActiveKey="freepost/"
                                    activeKey={currentPage?.parent}
                                    id="uncontrolled-tab-example"
                                    onSelect={(e) => onLinkClick(e)}
                                    className="mb-3"
                                >

                                    <Tab eventKey="freepost/"
                                        title="Free Post"
                                    >
                                        {orgDetails?.userid === token &&
                                            <CreatePost
                                                postType={'pre'}
                                                uploadType={'POFP'}
                                                userinfo={currentUser?.user}
                                                spinner={false}
                                                pageid={orgDetails?._id}
                                                getpost={() => getFunctions?.getPost({ orgid: orgDetails?._id, reqPage: 'freepremium',page: 1 })}
                                            />
                                        }

                                        <div className="row justify-content-center">
                                            {/* Middle section  */}
                                            <div className="col-lg-12 col-md-10">
                                                <section id="profile" className="mt-3">
                                                    {orgDetails?.userid &&
                                                        <>
                                                            <PremiumPostComponent
                                                                orgDetails={orgDetails}
                                                                posts={freepost}
                                                                setSelectedChildPage={setCurrentPage}
                                                                getpost={() => getFunctions?.getPost({ orgid: orgDetails?._id, reqPage: 'freepremium', page: 1 })}
                                                            />
                                                        </>
                                                    }
                                                </section>
                                            </div>
                                        </div>
                                    </Tab>


                                    {/* {orgDetails?.userid === token && */}
                                    <Tab eventKey="premiumpost/" id="premiumpost" title="Premium Post">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-10 mb-5">
                                                <CreatePremiumPost
                                                    pageid={orgDetails?._id}
                                                    orgDetails={orgDetails}
                                                    isOwner={orgDetails?.userid === token ? true : false}
                                                />
                                            </div>
                                        </div>
                                    </Tab>
                                    <Tab
                                        eventKey="about/"
                                        id="about"
                                        title="About"
                                    >
                                        <div className="row justify-content-center">
                                            <div className="col-sm-8">
                                                <OrgAbout
                                                    orgDetails={orgDetails}
                                                    getOrgDetails={() => dispatch(getOrganizationDetails(`?name=${orgDetails?.pageName}`))}
                                                />

                                            </div>

                                        </div>
                                    </Tab>
                                    {/* } */}
                                    {orgDetails?.userid === token &&
                                        <Tab eventKey="storage/" title="Storage">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-10 mb-5">
                                                    <StorageAnalysis
                                                        getData={getFunctions.getStorage}
                                                        pageid={orgDetails?._id}
                                                        storage={storage?.storage}
                                                        storegePosts={storage?.posts}
                                                        storageSubscriptions={storage?.subscriptions}
                                                    />
                                                </div>
                                            </div>
                                        </Tab>
                                    }
                                    {orgDetails?.userid === token &&
                                        <Tab eventKey="analytics/" title="Analytics">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-10 mb-5">
                                                    <div className="bg-white p-4 pt-2 pb-2 mb-4">
                                                        <div class="row">


                                                            <div className='col-sm-12 text-center mt-4'>
                                                                <h1>Premium Page Analytics</h1>
                                                                <ul className="analytics_list text-center">
                                                                    <li className="analytics_list_li border-primary">
                                                                        <h4 className="mt-4 text-primary"><b>Subscribed</b></h4>
                                                                        <div className="analytics_number bg-primary text-white w-50 h-50 rounded-circle"><h3>{orgAnl?.subDetails?.length}</h3></div>
                                                                    </li>
                                                                    <li className="analytics_list_li border-success">
                                                                        <h4 className="mt-4 text-success"><b>Active</b></h4>
                                                                        <div className="analytics_number bg-success text-white w-50 h-50 rounded-circle"><h3>{orgAnl?.active}</h3></div>
                                                                    </li>
                                                                    <li className="analytics_list_li border-danger">
                                                                        <h4 className="mt-4 text-danger"><b>Expired</b></h4>
                                                                        <div className="analytics_number bg-danger text-white w-50 h-50 rounded-circle"><h3>
                                                                            {orgAnl?.subDetails?.length - orgAnl?.active}
                                                                        </h3></div>
                                                                    </li>

                                                                    <li className="analytics_list_li border-info">
                                                                        <h4 className="mt-4 text-info"><b>Revenue</b></h4>
                                                                        <div className="analytics_number bg-info text-white w-50 h-50 rounded-circle">
                                                                            <h3 className="last-child">
                                                                                <small className="d-block"><b>Rs</b></small>
                                                                                {orgAnl?.subDetails?.length * orgDetails?.monthlySubscriptionPrice}
                                                                            </h3>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    }

                                    {orgDetails?.userid === token &&
                                        <Tab eventKey="ppvanalytics/" title="PPV Analytics">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-10 mb-5">
                                                    <table className="table">
                                                        <thead className="border bg-info border text-center">
                                                            <tr>
                                                                <th>Post Title</th>                                                                <th>PPV</th>
                                                                <th>Total PPV Subscription</th>
                                                                <th>Created At</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="text-center  border">
                                                            {ppvAnalytics?.length > 0 && ppvAnalytics?.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{item?.postTitle}</td>
                                                                            <td>{item?.ppv}</td>
                                                                            <td>{item?.totalPPVSubscription}</td>
                                                                            <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                                                            <td><Link to={`/premium/ppv/${item?._id}`} className="btn btn-primary">View</Link></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            }
                                                            )}
                                                            {/* {ppvAnalytics?.length === 0 &&
                                                                <tr>
                                                                    <td colSpan="5" className="text-center">
                                                                        No Details found
                                                                    </td>
                                                                </tr>

                                                            } */}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Tab>
                                    }

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {!token && <LoginStatus showname={currentUser?.user?.showName} />}
            <Footer />
        </>
    )
}

PremiumPageDetails.loadData = (params, search) => async (dispatch) => {
    //   const query = queryParse(search)
    console.log("params>>>", params)
    const name = params?.split("/")[2]
    return dispatch(await getOrganizationDetails(`?name=${name}`))
}
const mapStateToProps = (state) => {
    console.log("stateoRgpost", state)
    const { isSuccess, isError, members, adminCount, data, posts, photos, videos, followers, isProcessing, storage, orgAnl } =
        state.organization
    const { freepost, ppvAnalytics } =
        state.premium
    return {
        orgAnl,
        isSuccess,
        isError,
        orgDetails: data,
        photos: photos,
        videos: videos,
        posts: posts,
        adminCount: adminCount,
        isProcessing,
        followers,
        members,
        storage,
        freepost,
        ppvAnalytics
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getOrgPosts: params => dispatch(getOrganizationPosts(params)),
        getOrgStorageAnalytics: params => dispatch(OrgStorageDetails(params))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PremiumPageDetails)
