import { useState } from "react"

const ReadMore = ({ children }) => {
    const text = children || ''
    const [isReadMore, setIsReadMore] = useState(true)
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore)
    }
    return (
        <div className="text_readmore">
            {isReadMore ? text.slice(0, 300) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {text.length > 300 ?(isReadMore ? "...read more" : "...show less") : ""}
            </span>
        </div>
    )
}

export default ReadMore