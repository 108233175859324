import React, { useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import axios from "axios"
import { config } from '../../constant'
import { useEffect } from "react"
import { AccountsLogo } from "../FindAccount"
import { SubmitButton } from "../../components/AppComponents/Helpers"
import { CustomizedSnackbars } from "../../components"
const getRegisterUrl = config.url.API_URL + 'users/otpverification'

function AccountVerification() {
    const ractivateEmail = typeof window !== "undefined" ? localStorage.getItem("reActivaEmail") : ""
    const [otp, setOtp] = useState('')
    const [isSubmit, setIsSubmit] = useState(false)
    const [errorMsg, setErrorMsg] = useState()
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()
    const token = typeof window !== "undefined" ? localStorage.getItem('resetuser') : ""
    const [msg, setMsg] = useState('')

    const handleChange = e => {
        if (isNaN(e.target.value)) return false
        if (e.target.value.length > 6) return false
        setOtp(e.target.value)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        setIsSubmit(true)
        let data = {
            "id": token,
            "otp": otp
        }
        const response = await axios.post(getRegisterUrl, data)
        setIsSubmit(false)
        if (response.data.status === 200 && ractivateEmail)
            return setMsg('Your account Reactivated. Redirecting to login...')

        response.data.status === 200 ? navigate("/accounts/password/new/")
            : setErrorMsg(response.data.message)
    }

    useEffect(() => {
        if (!msg) return
        setTimeout(() => {
            setMsg("")
            navigate("/auth/login/")
        }, [3000])
    }, [msg, navigate])
    return (
        <div className="accounts">
            <div className="flex_center_box">
                <div className="box">
                    <AccountsLogo />
                    <h4 className='mx-3'>OTP Verification</h4>
                    {searchParams.get("sendon") === 'mail' && <p className="sub_head">Please enter 6 digit OTP that we just sent on your email <span className="clr-p"></span></p>}
                    {searchParams.get("sendon") === 'phone' && <p className="sub_head">Please enter 6 digit OTP that we just sent on your phone <span className="clr-p"></span></p>}
                    {!searchParams.get("sendon") && <p className="sub_head">Please enter 6 digit OTP that we just sent on you <span className="clr-p"></span></p>}
                    {errorMsg &&
                        <div className="err-msg2">
                            {errorMsg}
                        </div>
                    }


                    <form className="login-form" onSubmit={handleSubmit}>
                        {/* otp  */}
                        <div className="field mt-3">
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder="Enter OTP here"
                                name="email"
                                onChange={handleChange}
                                value={otp || ''}
                                required
                            />
                            <label htmlFor="otp">Enter OTP here</label>

                            {/* <div className="errors">{errMsg && errMsg}</div> */}
                        </div>

                        {/* <div className="otp-fields mt-1 clr-p">
                    <div className="cur-p" onClick={resendOtp}>Resend OTP</div>
                </div> */}
                        <Link to={"/accounts/verify/initiate"} >Didn't get a code?</Link>
                        <div className="mt-3">This OTP will valid for 2 min.</div>

                        <SubmitButton title={"Verify"} issubmit={isSubmit} css={"login-button mt-3"} />


                    </form>
                </div>
            </div>
            {msg && <CustomizedSnackbars type={0} msg={msg} />}
        </div>
    )
}

export default AccountVerification