export const ACTION_GET_ANALYTICS_POSTS = "ACTION_GET_ANALYTICS_POSTS"
export const ACTION_GET_MY_ANALYTICS_POSTS = "ACTION_GET_MY_ANALYTICS_POSTS"
export const ACTION_GET_BASIC_DETAILS = "ACTION_GET_BASIC_DETAILS"

const initialState = {
    basic: null,
    analyticsPosts: [],
    myAnalyticsPosts: [],
    isError: false,
    isProcessing: null,
    isSuccess: null,
    message: null,
    selectedNews: null,
}
export const settingsReducer = (state = initialState, action) => {
    const returnObj = { ...state }
    switch (action.type) {
        case ACTION_GET_ANALYTICS_POSTS:
            returnObj.analyticsPosts = action.payload
            return returnObj
        case ACTION_GET_MY_ANALYTICS_POSTS:
            returnObj.myAnalyticsPosts = action.payload
            return returnObj
        case ACTION_GET_BASIC_DETAILS:
            returnObj.basic = action.payload
            return returnObj

        default:
            return state
    }
}
