import React, { useEffect, useState } from "react"
import { Footer, IndexNavbar } from "../../components"
import { connect, useDispatch } from "react-redux"
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { LDS } from "../../components/Loaders/SimpleCircle"
import { PremiumListNav } from "."
import { PrivatePostCard } from "../Organization/OrgPrivatePosts"

function MyPremiumPost(props) {
    const [loader, setLoader] = useState(true)
    const { myPremiumPost } = props
    const dispatch = useDispatch()
    // const [Q, setQ] = useState("")
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    // const [initialLoad, setInitialLoad] = useState(true)
    const init = async () => {
        try {
            if (!hasMore) return setHasMore(false)
            let params = {
                reqPage: 'mySubscriptionPosts',
                page: page,
            }

            let req = {
                params: params,
                url: 'getpreposts',
                action: 'ACTION_GET_DATA_BY_STATE',
                state: 'myPremiumPost'
            }
            const response = await dispatch(getRequestHandler(req))
            // setInitialLoad(false)
            if (response?.data?.responseData?.length < 12) setHasMore(false)
        } catch (err) {
            console.error(err)
        }
    }

    // const handleChange = (e) => {
    //     setQ(e.target.value)
    // }

    const handleScroll = (e) => {
        let scrollTop = window.pageYOffset
        let clientHeight = window.document.documentElement.clientHeight
        let bodyHeight = window.document.body.clientHeight

        if (clientHeight + scrollTop > bodyHeight - 100) {
            window.removeEventListener("scroll", handleScroll)
            setPage(page + 1)
        }
    }



    useEffect(() => {
        if (myPremiumPost.length > 0) {
            window.addEventListener("scroll", handleScroll)
            setLoader(false)
        }
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myPremiumPost])

    useEffect(() => {
        if (loader) setTimeout(() => setLoader(false), [3000])
    }, [loader])

    // useEffect(() => {
    //     if (Q) init()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [Q])

    useEffect(() => {
        if (page > 1) init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    return (
        <>
            <IndexNavbar />
            {/* <section className="organisation_list"></section> */}
            <section className="organisation_list" style={{ minHeight: "100vh" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 bg-white mt-3 br_5 shadow_1 p-3">
                            <h2 className="blog_heading mb-4">Premium Creators</h2>

                            <div className="d-flex justify-content-between align-items-center flex_wrap">
                                <PremiumListNav />
                                {/* <div className="mb_w_100">
                                    <input
                                        type="search"
                                        name="search"
                                        id="memberSearch"
                                        className="member_search_1 outline_1"
                                        placeholder="Find members"
                                        onChange={handleChange}
                                        value={Q || ""}
                                    />
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container mt-2 mb-4">
                    <div className="row">
                        {loader ?
                            <div className="load_body">
                                <LDS />
                            </div> :
                            myPremiumPost?.length > 0 ?
                                myPremiumPost?.map((item, index) =>
                                    <div className="col-lg-4 col-md-4">
                                        <PrivatePostCard
                                            isPre={'pre'}
                                            key={item?._id}
                                            // predirect={""}
                                            post={item}
                                        />
                                    </div>
                                )
                                :
                                <>
                                    <h1>No premium post  found</h1>
                                </>

                        }

                        {page > 1 && loader &&
                            <div className="d-flex justify-content-center">
                                <LDS />
                            </div>
                        }
                    </div>

                </div>
            </section>
            <Footer />
        </>
    )
}

MyPremiumPost.loadData = (params, search) => async (dispatch) => {
    let req = {
        params: {
            reqPage: 'mySubscriptionPosts',
            page: 1
        },
        url: 'getpreposts',
        action: 'ACTION_GET_DATA_BY_STATE',
        state: 'myPremiumPost'
    }
    return await dispatch(getRequestHandler(req))
}
const mapStateToProps = (state) => {
    const { myPremiumPost } = state.premium
    return { myPremiumPost }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(MyPremiumPost)
