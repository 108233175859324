import React from 'react'

function SimpleCircle() {
  return (
    <div className="loader simple-circle"></div>
  )
}

export const LDS = () => {
  return (
    <div className="ldss-spinner">
      <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div>
  )
}

export default SimpleCircle