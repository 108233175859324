import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { putRequestHandler } from '../../redux/reducers/requestHandler.reducer'
import SimpleCircle from '../Loaders/SimpleCircle'
import CustomizedSnackbars from '../Loaders/Snuckbars'


function AccountChangePassword() {
    const [values, setValues] = useState({})
    const [inputfocus, setInputfocus] = useState({})
    const [errmsg, setErrMsg] = useState({})
    const [passMatch, setPassMatch] = useState(false)
    const [isSubmit, setIsSubmit] = useState(false)
    const errors1 = {}
    const dispatch = useDispatch()
    const [load, setLoad] = useState(false)
    const [msg, setMsg] = useState('')
    const [msgT, setMsgT] = useState(null)
    const passwordValidation = (value, name) => {
        if (!value) return errors1[name] = 'Password is required'
        if (value.length < 6) return errors1[name] = 'Password must be 6 or more characters'
        return errors1[name] = ''
    }

    const handleChange = e => {
        e.persist()
        setValues(values => ({ ...values, [e.target.name]: e.target.value }))
        setErrMsg(errmsg => ({ ...errmsg, [e.target.name]: passwordValidation(e.target.value, e.target.name) }))
        console.log(errmsg)
    }

    const handleMatch = () => {
        values.newPassword === values.confirmPassword ?
            setPassMatch(true)
            : setPassMatch(false)

    }

    const changePassword = async data => {
        setMsg('')
        setLoad(true)
        let req = {
            data: data,
            url: 'passChange'
        }
        const response = await dispatch(putRequestHandler(req))
        setLoad(false)
        setMsgT(response.data.ack === 0 ? 1 : 0)
        return setMsg(response.data.ack === 0 ? response.data.message : 'Password changes successfully')
    }

    useEffect(() => {
        !errmsg.oldPassword && !errmsg.newPassword && !errmsg.confirmPassword && passMatch ?
            setIsSubmit(true) :
            setIsSubmit(false)
    }, [errmsg.oldPassword, errmsg.newPassword, errmsg.confirmPassword, passMatch])

    const handleSubmit = e => {
        e.preventDefault()
        console.log(passMatch)
        const data = {
            // uid: token,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
        }
        if (isSubmit)
            changePassword(data)
    }

    return (
        <div id="basic" className="row settings_row border_1 mt-4 border_r5">
            <div className="col-lg-5">
                <div className="mt-3">
                    <h5>Change Password</h5>
                </div>
            </div>
            <div className="col-lg-7">
                <form onSubmit={handleSubmit} >
                    {/* Old Password */}

                    <div className='mt-4'>
                        <div className='w-100'>
                            <label htmlFor="user name" className="log-input-title">Old Password </label>
                            <input type="password"
                                className="form-control form-control-xl mt-2"
                                autoComplete="off"
                                placeholder="Enter old password here"
                                name="oldPassword"
                                onFocus={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: true }))}
                                onBlur={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: false }))}
                                onChange={handleChange}
                                value={values.oldPassword || ''}
                                required
                            />

                            <div className="errors">{!inputfocus.oldPassword && errmsg.oldPassword}</div>
                        </div>
                    </div>
                    {/* </div> */}

                    {/* last Name  */}
                    <div className="input_box c_input_box mt-3">
                        <div className='w-100'>
                            <label htmlFor="new password">
                                New Password
                            </label>
                            <input type="password"
                                className="form-control form-control-xl mt-2"
                                autoComplete="off"
                                placeholder="Enter New Password here"
                                name="newPassword"
                                onFocus={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: true }))}
                                onBlur={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: false }))}
                                onChange={handleChange}
                                value={values.newPassword || ''}
                                onKeyUp={handleMatch}
                                required
                            />

                            <div className="errors">{!inputfocus.newPassword && errmsg.newPassword}</div>

                        </div>
                    </div>

                    {/* password  */}
                    <div className="input_box c_input_box mt-3">
                        <div className='w-100'>
                            <label htmlFor="confirm password">
                                Confirm Password
                            </label>
                            <input type="password"
                                className="form-control form-control-xl mt-1"
                                autoComplete="off"
                                placeholder="Enter Confirm password"
                                name="confirmPassword"
                                onFocus={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: true }))}
                                onBlur={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: false }))}
                                onChange={handleChange}
                                onKeyUp={handleMatch}
                                value={values.confirmPassword || ''}

                                required
                            />
                            {passMatch && <div className='succ'>Matched</div>

                            }
                            <div className="errors">{!inputfocus.confirmPassword && errmsg.confirmPassword}</div>
                        </div>
                    </div>

                    <div className="save_btn mt-4 d-flex gap-2">
                        {load ?
                            <button className='btn btn-primary' type='buttons'><SimpleCircle /></button>
                            : <button className='btn btn-primary' type='submit'>Update Password</button>
                        }
                        {/* <button className='btn btn-primary' type='submit' onClick={()=>console.log('clikc')}>Save</button> */}
                    </div>

                    {/* {errmsg && <div className='mt-3'>{errmsg}</div>} */}

                </form>
            </div>
            {msg && <CustomizedSnackbars type={msgT} msg={msg} />}

        </div>
    )
}

export default AccountChangePassword