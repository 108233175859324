import { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { getRequestHandler } from "../redux/reducers/requestHandler.reducer"


const useForm = (callback, validate, callbackName) => {
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})
  const [duplicate, setDuplicate] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) callback()
    setIsSubmitting(false)
  }, [callback, errors, isSubmitting])

  const handleSubmit = (event) => {
    if (event) event.preventDefault()
    setErrors(validate(values, callbackName))
    if (Object.keys(duplicate).length === 0) setIsSubmitting(true)
  }

  const handleChange = async (event) => {
    // event.persist()

    if (event.target.name === "phone")
      if (isNaN(event.target.value)) return false
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }))

    if (event.target.name === "countryDialCode") {
      !event.target.value
        ? setErrors((prev) => ({
          ...prev,
          [event.target.name]: "Country code required",
        }))
        : setErrors((prev) => ({ ...prev, [event.target.name]: "" }))
    }

    if (event.target.name === "userName") {
      if (event.target.value.indexOf(" ") >= 0) {
        return setDuplicate((duplicate) => ({
          ...duplicate,
          [event.target.name]: "Can't contain space",
        }))
      }
      const response = await dispatch(getRequestHandler({
        url: 'isUserExist',
        params: {
          q: event.target.value,
        }
      }))
      if (response.data.responseData) {
        setDuplicate((duplicate) => ({
          ...duplicate,
          [event.target.name]:
            "This user name already taken. Use some alpha-numeric charecters to make it unique.",
        }))
      } else {
        delete duplicate[event.target.name]
      }
    }
    setErrors(validate(event.target.value, event.target.name))
  }

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    duplicate,
  }
}

export default useForm
