import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CustomizedSnackbars, Footer, IndexNavbar } from '../../components'
import { addSubscriptions, getSubscriptionPlan } from '../../redux/reducers/admin.reducer'
function StorageSubscriptions(props) {
    const { plans } = props
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { name, id } = useParams()
    const [errMsg, setErrMsg] = useState("")
    const [successMsg, setSuccessMsg] = useState(false)
    const addSubs = async params => {
        try {
            setSuccessMsg(false)
            const param = {
                ...params,
                [name === 'users' ? "userId" : "pageId"]: id,
                subId: id,
                type: name === 'users' ? 'susers' : 'sorg',
            }
            // console.log(param)
            const response = await dispatch(addSubscriptions(param))
            if (response?.data?.ack === 0) return setErrMsg(response?.data?.message)
            setSuccessMsg(true)
            console.log(response)
        } catch (error) {
            console.error(error)
        }
    }
    // error clearing after 5s
    useEffect(() => {
        if (!errMsg) return
        setTimeout(() => setErrMsg(""), [5000])
    }, [errMsg])
    return (
        <>
            <IndexNavbar />
            <div className='container'>
                <div className='row p-4'>

                    <div className='col-lg-12 text-center'>
                        <h3>Select Plan for Storage</h3>

                        <ul className='plan_list'>
                            {plans?.length > 0 && plans?.map((item, index) => {
                                return (<>
                                    <li className='card shadow-sm m-2' key={item?._id + index}>
                                        <div className='plan_top_bg bg-success text-white'>
                                            <h4 className='mb-0 mt-4'>For</h4>
                                            <h5 className='mb-0'>{item?.duration} Days</h5>
                                            <h1 className='mb-0 '>₹{item?.price}</h1>
                                        </div>
                                        <div className='plan_list_li'>
                                            <ul className='m-0 p-2'>
                                                <li className='d-block p-1'>
                                                    <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt='storage' />
                                                    {item?.planName}
                                                </li>
                                                <li className='d-block p-1'>
                                                    <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt='storage' />
                                                    {Number(item?.storage / (1024 * 1024)).toFixed(2)} MB
                                                </li>
                                                <li className='d-block p-1'>
                                                    <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt='storage' />
                                                    {item?.duration} Days
                                                </li>
                                                <li className='d-block p-1'>
                                                    <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt='storage' />
                                                    ₹{parseInt(item?.price)}
                                                </li>
                                                <li className='d-block p-1'>
                                                    <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt='storage' />
                                                    {item?.planDescription}
                                                </li>
                                            </ul>
                                        </div>
                                        <button type="button" className="btn btn-success" onClick={() => addSubs({ planId: item?._id, mode: item?.mode, type: item?.type })}>
                                            Add Now
                                        </button>
                                        {/* <butto to={"/org/lists/#premierposts"}> */}
                                        {/* {/Free/.test(item?.planName) ?
                                            <button type="button" className="btn btn-success" onClick={() => addSubs({ planId: item?._id, mode: item?.mode, type: item?.type })}>
                                                Add Now
                                            </button> :
                                            <Link
                                                to={'/underconstruction/'}
                                                className="btn btn-success"
                                            >
                                                Add Now
                                            </Link>

                                        } */}
                                        {/* </butto> */}
                                    </li>
                                </>)
                            })}
                        </ul>

                    </div>
                </div>
            </div>
            {errMsg && <CustomizedSnackbars type={1} msg={errMsg} />}
            {successMsg && <CustomizedSnackbars type={0} msg={<>
                You are succesfully subscribed please check your storage. <span onClick={() => navigate(-1)}>Click here</span>
            </>} />}

            <Footer />
        </>
    )
}

StorageSubscriptions.loadData = (params, search) => async (dispatch) => {
    const name = params.split("/")[3];
    console.log("fdfsdfsdf", name)
    return dispatch(await getSubscriptionPlan({
        sort: "price",
        mode: "create",
        type: name
    }));
    // return res;
};
const mapStateToProps = (state) => {
    const { isSuccess, isError, isProcessing, data } = state.admin;
    console.log("adminstar", state)
    return {
        isSuccess,
        isError,
        isProcessing,
        plans: data,
    };
};

export default connect(mapStateToProps, null)(StorageSubscriptions)