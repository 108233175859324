import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config/config";
import prof1 from "../../../assets/web_img/choto_logo_1.png";
import { Helmet } from "react-helmet";
import pro_2 from "../../../assets/web_img/blog.jpg";
// blog.jpg"
import moment from "moment";
import FacebookIcon from "@mui/icons-material/Facebook";
// import { FacebookButton, FacebookCount } from "react-social"
// import Notfound from "./Notfound";
import { Footer, IndexNavbar } from "../../components";
import { connect } from "react-redux";
import { getBlogData } from "../../redux/reducers/blogReducer";
import { getNewsData } from "../../redux/reducers/newsReducer";
const API_URL = config.API_URL + "admin/";
const NEWS_URL = config.API_URL + "news/";
const LikeUrl = config.API_URL + "social/";

function DisplayNewsContents(props) {
  const { pathname } = useLocation();
  const kk = useLocation();

  // const pageUrl = window.location.href
  // console.log("path", window.location.href)
  console.log("blog>>>", props);
  const { blog } = props;
  const split = pathname.split("/");
  const { slug } = useParams();
  // const [blog, setBlog] = useState({});
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const [comments, setComments] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [bal, setBal] = useState("");
  const getData = async () => {
    try {
      let kk;
      const tq = token ? `token=${token}` : "";

      split[1] === "news"
        ? (kk = NEWS_URL + `get?slug=${slug}&${tq}`)
        : (kk = API_URL + `getblog?slug=${slug}&${tq}`);
      const response = await axios.get(kk);
      console.log("setBlog", response);

      // setBlog(response.data?.responseData[0]);
    } catch (err) {
      console.error(err);
    }
  };

  const getComments = async () => {
    try {
      let kk;
      split[1] === "news"
        ? (kk = NEWS_URL + `comment?referenceNewsId=${blog?._id}`)
        : (kk = LikeUrl + `blogcomment?id=${blog?._id}`);
      const response = await axios.get(kk);

      setAllComments(response.data.responseData);
    } catch (err) {
      console.error(err);
    }
  };

  const handleLike = async () => {
    try {
      let kk, data;
      if (!token && !blog?._id) return console.log("parameters error");

      split[1] === "news"
        ? (kk = NEWS_URL + `like`)
        : (kk = LikeUrl + `bloglike`);

      split[1] === "news"
        ? (data = {
            referenceUserId: token,
            referenceNewsId: blog?._id,
          })
        : (data = {
            referenceUserId: token,
            referenceBlogId: blog?._id,
          });
      const response = await axios.post(kk, data);
      if (response.data.ack === 1) getData();
    } catch (err) {
      console.error(err);
    }
  };

  const handleComment = async (e) => {
    try {
      e.preventDefault();
      let kk, data;
      if (!token && !blog?._id) return console.log("parameters error");

      split[1] === "news"
        ? (kk = NEWS_URL + `comment`)
        : (kk = LikeUrl + `blogcomment`);

      split[1] === "news"
        ? (data = {
            referenceUserId: token,
            referenceNewsId: blog?._id,
            messageText: comments,
          })
        : (data = {
            referenceUserId: token,
            referenceBlogId: blog?._id,
            messageText: comments,
          });
      const response = await axios.post(kk, data);

      if (response.data.ack === 1) {
        setComments("");
        getComments();
      }
      getData();
    } catch (err) {
      console.error(err);
    }
  };

  const Kelmet = () => {
    if (!blog) return <></>;
    return (
      <Helmet>
        {/* <meta property="og:url" content={pageUrl} /> */}
        <meta property="og:type" content="article" />
        <meta property="og:title" content={blog?.title} />
        <meta
          name="keywords"
          content={blog?.title || "Allphanes | create-connect-coverage"}
        />
        <meta
          property="og:description"
          content={blog?.shortDescription || ""}
        />
        <meta
          property="og:image"
          content={blog?.defaultImage || `https://allphanes.com/${pro_2}`}
        />
        <title>{`Allphanes | ${blog?.title}`}</title>
        {/* <link rel="canonical" href={pageUrl} /> */}
      </Helmet>
    );
  };

  useEffect(() => {
    // getData();
    setBal(window.location.href);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const allFigure = window.document.getElementsByTagName('p a')
    console.log("allFigure",allFigure)
    // if (allFigure.length > 0) {
    //     allFigure.forEach((item, index) => {
    //         if (index % 2 !== 0) item.classList.add('even')
    //     })
    // }
    if (blog?._id) getComments();
  }, [blog]);

  
  return (
    <>
      <IndexNavbar />

      <div className="container-fluid bg_5 pb-5">
        <Kelmet />
        {blog ? (
          <div className="container pb-5">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-sm-12">
                <div className="mt-5" id="blog_details">
                  <div className="blog_top">
                    <h1 className="mt-4">{blog?.title}</h1>
                    <div className="">
                      {moment(blog?.createdAt).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <div
                    className="blog_content_box"
                    dangerouslySetInnerHTML={{ __html: blog?.content }}
                  />
                  {/* <div className='blog_content_box' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog?.content) }} /> */}
                  {/* blog comments */}
                  <div className="comment_bottom d-flex gap-3 justify-content-end mt-3 blog_social">
                    <div className="like_div">
                      {token ? (
                        <div
                          className={
                            blog?.isLiked
                              ? blog?.isLiked?.length > 0
                                ? "like blog_like c_pointer active"
                                : "like blog_like c_pointer"
                              : "like blog_like c_pointer"
                          }
                          title="click to Like"
                          onClick={handleLike}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="heart"
                            className="svg-inline--fa fa-heart post_icons"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z"
                            ></path>
                          </svg>
                        </div>
                      ) : (
                        <div className="like" title="Login to Like">
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="heart"
                            className="svg-inline--fa fa-heart post_icons"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M0 190.9V185.1C0 115.2 50.52 55.58 119.4 44.1C164.1 36.51 211.4 51.37 244 84.02L256 96L267.1 84.02C300.6 51.37 347 36.51 392.6 44.1C461.5 55.58 512 115.2 512 185.1V190.9C512 232.4 494.8 272.1 464.4 300.4L283.7 469.1C276.2 476.1 266.3 480 256 480C245.7 480 235.8 476.1 228.3 469.1L47.59 300.4C17.23 272.1 .0003 232.4 .0003 190.9L0 190.9z"
                            ></path>
                          </svg>
                        </div>
                      )}
                    </div>
                    <div className="d-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#6ea20b"
                      >
                        <g>
                          <rect fill="none" height="24" width="24"></rect>
                        </g>
                        <g>
                          <g>
                            <circle cx="4" cy="14" r="2"></circle>
                            <path d="M1.22,17.58C0.48,17.9,0,18.62,0,19.43V21l4.5,0v-1.61c0-0.83,0.23-1.61,0.63-2.29C4.76,17.04,4.39,17,4,17 C3.01,17,2.07,17.21,1.22,17.58z"></path>
                            <circle cx="20" cy="14" r="2"></circle>
                            <path d="M22.78,17.58C21.93,17.21,20.99,17,20,17c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V21l4.5,0v-1.57 C24,18.62,23.52,17.9,22.78,17.58z"></path>
                            <path d="M16.24,16.65c-1.17-0.52-2.61-0.9-4.24-0.9c-1.63,0-3.07,0.39-4.24,0.9C6.68,17.13,6,18.21,6,19.39V21h12v-1.61 C18,18.21,17.32,17.13,16.24,16.65z"></path>
                            <path d="M9,12c0,1.66,1.34,3,3,3s3-1.34,3-3c0-1.66-1.34-3-3-3S9,10.34,9,12z"></path>
                            <path d="M2.48,10.86C2.17,10.1,2,9.36,2,8.6C2,6.02,4.02,4,6.6,4c2.68,0,3.82,1.74,5.4,3.59C13.57,5.76,14.7,4,17.4,4 C19.98,4,22,6.02,22,8.6c0,0.76-0.17,1.5-0.48,2.26c0.65,0.31,1.18,0.82,1.53,1.44C23.65,11.1,24,9.88,24,8.6 C24,4.9,21.1,2,17.4,2c-2.09,0-4.09,0.97-5.4,2.51C10.69,2.97,8.69,2,6.6,2C2.9,2,0,4.9,0,8.6c0,1.28,0.35,2.5,0.96,3.7 C1.31,11.68,1.84,11.17,2.48,10.86z"></path>
                          </g>
                        </g>
                      </svg>
                      {blog?.totalLikes} Likes
                    </div>
                    <div className="">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#2570c3"
                      >
                        <g>
                          <rect fill="none" height="24" width="24"></rect>
                          <rect fill="none" height="24" width="24"></rect>
                        </g>
                        <g>
                          <g>
                            <polygon points="22,9 22,7 20,7 20,9 18,9 18,11 20,11 20,13 22,13 22,11 24,11 24,9"></polygon>
                            <path d="M8,12c2.21,0,4-1.79,4-4s-1.79-4-4-4S4,5.79,4,8S5.79,12,8,12z"></path>
                            <path d="M8,13c-2.67,0-8,1.34-8,4v3h16v-3C16,14.34,10.67,13,8,13z"></path>
                            <path d="M12.51,4.05C13.43,5.11,14,6.49,14,8s-0.57,2.89-1.49,3.95C14.47,11.7,16,10.04,16,8S14.47,4.3,12.51,4.05z"></path>
                            <path d="M16.53,13.83C17.42,14.66,18,15.7,18,17v3h2v-3C20,15.55,18.41,14.49,16.53,13.83z"></path>
                          </g>
                        </g>
                      </svg>
                      {blog?.totalComments} Comments
                    </div>
                  </div>

                  <div>
                    {/* <FacebookButton url={window.location.href} appId={474930874519189}>
                                    {" Share " + window.location.href}
                            </FacebookButton> */}
                    Share :{" "}
                    <a
                      href={`https://www.facebook.com/sharer/sharer.php?u=${bal}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacebookIcon />
                    </a>
                  </div>

                  <form onSubmit={handleComment}>
                    <h4>Discussions</h4>

                    <div className="row">
                      <div className="col-lg-7">
                        <div className="form-group mt-4">
                          <textarea
                            type="text"
                            className="form-control"
                            id="FormControlInput1"
                            placeholder="Write here..."
                            name="comments"
                            onChange={(e) => setComments(e.target.value)}
                            value={comments || ""}
                            rows="5"
                            required
                          ></textarea>
                        </div>
                        
                        <button type="submit" className="mt-2 btn btn-primary">
                          Submit
                        </button>
                        {allComments.length > 0 && (
                          <div className="mt-3 py-3 ot_1">
                            {allComments?.map((item, index) => {
                              return (
                                <div className="commentsDiv" key={index}>
                                  <div className="pro_img_com">
                                    {item?.referenceUserId ? (
                                      <div className="d-flex ">
                                        {item?.referenceUserId?.profilePhoto ? (
                                          <img
                                            src={
                                              item.referenceUserId.profilePhoto
                                            }
                                            alt="members profile"
                                          />
                                        ) : (
                                          <img src={prof1} alt="profile" />
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div className="commentsView">
                                    <div className="commentBox">
                                      <pre className="fs-14 viewD">
                                        <span className="user_name">
                                          {token ? (
                                            <Link
                                              to={`/${item?.referenceUserId?.userName}`}
                                            >
                                              {item?.referenceUserId?.showName}
                                            </Link>
                                          ) : (
                                            item?.referenceUserId?.showName
                                          )}
                                          :
                                        </span>
                                        {item.messageText
                                          ? item.messageText
                                          : ""}
                                      </pre>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        ) : // <Notfound />
        null}
      </div>
      <Footer />
    </>
  );
}
DisplayNewsContents.loadData =
  (params, search) => async (dispatch, getState) => {
    // const query = queryParse(search);
    console.log("slug news>>", params);

    const slugSegment = params.trim().split("/");
    const slug = slugSegment[slugSegment.length - 1];
    return dispatch(getNewsData(slug));
    // console.log("blogsData111>>", params, search, getState());
  };
const mapStateToProps = (state) => {
  const { selectedNews } = state.news;
  console.log("state>>", state);
  return { blog: selectedNews };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DisplayNewsContents);
