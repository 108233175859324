import React from "react";
import { Footer, IndexNavbar } from "../../components";
function About() {
	return (
		<>
			<IndexNavbar />
			<div className="top_search">
				<div
					id="top_search_heading"
					style={{
						backgroundImage: `url(${"/assets/web_img/banner_images.jpg"})`,
					}}
				>


					<div class="container about_us">
						<div className="row">
							<div className="col-sm-12">
								<h2 className="text-center">Our Story</h2>
								<h1>
									The Internet has transformed the world. Yet not everyone has
									been able to participate equally in its benefits. The internet
									today is dominated by one culture and one dominant language. Big
									companies are rewarded while most creators, whose content makes
									them successful, get nothing. The diversity of cultures and
									languages from all over the world, have no home for their
									creative expression and art.
								</h1>

							</div>
						</div>
					</div>
				</div>
				<div className="container">
					<div className="row">
						<div className="col-sm-6 about_us_col-6">
							<div className="cmn_box ">
								<h2>We created Allphanes for those who feel left out…</h2>
								<p>
									हमनेउनलोगोंकेलिए Allphanes बनायाहैजोखुदकोअकेलामहसूसकरतेहैं…
									(Hindi)
								</p>
								<p>
									Creamos Allphanes para aquellos que se sienten excluidos...
									(Spanish)
								</p>
								<p>
									Nous avons créé Allphanes pour ceux qui se sentent exclus…
									(French){" "}
								</p>
								<p>Allphanes তাদের, যারা ব্রাত্য... (Bengali)</p>
								<p>Criamos Allphanes para quem se sente excluído… (Portuguese)</p>
								<p>
									Wij creërden Allphanes voor iedereen die zich uitgesloten
									voelen.... (Dutch)
								</p>
							</div>
						</div>
						<div className="col-sm-6 about_us_col-6">
							<div className="cmn_box">
								<h2>We want to change that.</h2>
								<p>हम इसे बदलना चाहते हैं।...(Hindi) </p>
								<p>Queremos cambiar eso...(Spanish)</p>
								<p>Nous voulons changer ça... (French) </p>
								<p>এস বদলে ফেলি... (Bengali)</p>
								<p>Queremos mudar isso... (Portuguese) </p>
								<p>Wij willen dat veranderen... (Dutch)</p>
							</div>
						</div>
						<div className="col-sm-12">
							<div className="cmn_box_new">
								<div className="about_text">
									<h2>
										<strong>Allphanes</strong> was created to provide a home for
										creators fromall cultures and languages, particularly the
										groups that have been under-represented. Allphanes provides a
										place for them to show and share their work, collaborate with
										others to get better at their art, and earn revenue doing it.
										<span className="fs-500">Showcase Your Talent</span> with us today!{" "}
									</h2>

									<h1>How to Showcase Your Talent?</h1>
									<p>
										Simply upload your content on this highly popular social media network for creators. With global exposure and professional feedback, you can move forward in your career!
									</p>
								</div>
							</div>
							<br />
						</div>
						<div className="col-sm-12">
							<div className="">
								<div className="about_text ">
									<div className="about_more_text">
										<h3>Our Team</h3>
										<p>
											Allphanes Inc. has its headquarters in the USA and a
											subsidiary, Allphanes India Pvt. Ltd., in India.{" "}
										</p>
									</div>
									<div className="about_img">
										<img src={`/assets/web_img/usa_img.jpg`} alt="banner" className="USA" />
										<img src={`/assets/web_img/allphanes_img.jpg`} alt="banner" className="allphanes" />
										<img src={`/assets/web_img/india_img.jpg`} alt="banner" className="INDIA" />
									</div>
									<div className="about_more_text text-center">
										<p>
											At Allphanes, we are a team of“Creative Art” Enthusiasts
											from different disciplines, who also have a background in
											engineering, software development, business &
											entrepreneurship. Together we are creating an online network
											of creators from all over the world.
										</p>
										<p>
											<strong>
												However, our most important team member is{" "}
												<strong>YOU</strong>. Creators like <strong>YOU</strong>{" "}
												provide the creative content, that makes Allphanes the
												creator media destination of the world!!
											</strong>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />

		</>
	);
}

export default About;
