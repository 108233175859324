import api from "../../config/api"
import Network from "../../helpers/network"
export const ACTION_GET_TRANSACTION_REQUEST = "ACTION_GET_TRANSACTION_REQUEST"
export const ACTION_GET_TRANSACTION_SUCCESS = "ACTION_GET_TRANSACTION_SUCCESS"
export const ACTION_TRANSACTION_FAILED = "ACTION_TRANSACTION_FAILED"


const initialState = {
    transactionHistory: [],
    isProcessing: null,
    isError: null,
    isSuccess: null,
    reqState: null
}

export const PaymentReducer = (state = initialState, action) => {
    let returnObj = {
        ...state
    }
    switch (action.type) {
        // case ACTION_GET_TRANSACTION_REQUEST:
        //   returnObj.topCreationPosts = action?.page > 1 ? [...state?.topCreationPosts, ...action?.payload] : action?.payload
        //   return returnObj

        case ACTION_GET_TRANSACTION_REQUEST:
            return {
                ...state,
                reqState: 'processing',
            }
        case ACTION_GET_TRANSACTION_SUCCESS:
            returnObj.transactionHistory = action?.page > 1 ? [...state?.transactionHistory, ...action?.payload] : action?.payload
            returnObj.reqState = 'success'
            return returnObj
        case ACTION_TRANSACTION_FAILED:
            return {
                ...state,
                transactionHistory: [],
                reqState: 'failed'
            }
        default:
            return state
    }
}


export const getTransactions = (params) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({ type: ACTION_GET_TRANSACTION_REQUEST })
                Network.get(api.getTransactions, params)
                    .then((res) => {
                        dispatch({
                            type: ACTION_GET_TRANSACTION_SUCCESS,
                            page: params?.page,
                            payload: res?.data?.responseData,
                        })
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log("error", err)
                        dispatch({ type: ACTION_TRANSACTION_FAILED })
                        reject(err)
                    })
            } catch (err) {
                console.log("error", err)
                dispatch({ type: ACTION_TRANSACTION_FAILED })
                reject(err)
            }
        })
    }



