import api from "../../config/api"
import Network from "../../helpers/network"
export const ACTION_GET_NOTIFICATION_REQUEST = "ACTION_GET_NOTIFICATION_REQUEST"
export const ACTION_GET_NOTIFICATION_SUCCESS = "ACTION_GET_NOTIFICATION_SUCCESS"
export const ACTION_GET_SINGLE_NOTIFICATION_SUCCESS = "ACTION_GET_SINGLE_NOTIFICATION_SUCCESS"
export const ACTION_GET_NOTIFICATION_FAILD = "ACTION_GET_NOTIFICATION_FAILD"
const initialState = {
    notifications: [],
    isError: false,
    isProcessing: null,
    isSuccess: null,
    message: null,
    selectedNews: null,
}
export const NotificationReducer = (state = initialState, action) => {
    const returnObj = { ...state }
    switch (action.type) {
        case ACTION_GET_NOTIFICATION_REQUEST:
            return {
                ...state,
                isError: false,
                isProcessing: true,
                //    message :
            }
        case ACTION_GET_NOTIFICATION_SUCCESS:
            returnObj.notifications = action?.page > 1 ? [...state.notifications, ...action.payload] : action.payload
            return returnObj
        case ACTION_GET_NOTIFICATION_FAILD:
            return {
                ...state,
                isError: true,
                isProcessing: false,
                notifications: null,
                isSuccess: false,
                //    message :
            }
        case ACTION_GET_SINGLE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                isError: false,
                isProcessing: false,
                selectedNews: action.payload,
                isSuccess: true,
                //    message :
            }
        default:
            return state
    }
}

export const getNotifications = (params) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch({ type: ACTION_GET_NOTIFICATION_REQUEST })
                Network.get(api.getNotifications, params)
                    .then((res) => {
                        dispatch({
                            type: ACTION_GET_NOTIFICATION_SUCCESS,
                            payload: res?.data?.responseData,
                            page: params?.page
                        })
                        resolve(res)
                    })
                    .catch((err) => {
                        console.log("error", err)
                        dispatch({ type: ACTION_GET_NOTIFICATION_FAILD })
                        reject(err)
                    })
            } catch (err) {
                console.log("error", err)
                dispatch({ type: ACTION_GET_NOTIFICATION_FAILD })
                reject(err)
            }
        })
    }

export const getNewsData =
    (slug = null) =>
        (dispatch) => {
            console.log("loadData>>", slug)
            return new Promise(async (resolve, reject) => {
                try {
                    //   let kk
                    //   const tq = token ? `token=${token}` : ""

                    //   split[1] === "news"
                    //     ? (kk = NEWS_URL + `get?slug=${slug}&${tq}`)
                    //     : (kk = API_URL + `getblog?slug=${slug}&${tq}`)
                    const response = await Network.get(api.getNews, { slug: slug })
                    dispatch({
                        type: ACTION_GET_SINGLE_NOTIFICATION_SUCCESS,
                        payload: response.data?.responseData[0],
                    })
                    resolve(response)
                    // setBlog(response.data?.responseData[0])
                } catch (err) {
                    console.error(err)
                    reject(err)
                }
            })
        }
