import React from 'react'

const VideoThubnails = (props) => {
    const { thubnails, setFilePreview } = props
    return (
        <>
        {thubnails?.map(item =>
        <div 
        className="col-sm-6 mb-2" 
        key={item}
        onClick={()=>setFilePreview((prev) => ({ ...prev, videoThumbnil: item}))}
        >
            <div className="video_thumbnail" style={{
                backgroundImage: `url(${item})`
            }}></div>
        </div>
        )
        }
        </>
        
    )
}

export default VideoThubnails