import React, { useState, useEffect } from "react"
import axios from "axios"
import { config } from "../../../constant"
import Accordion from "react-bootstrap/Accordion"
import { useRef } from "react"
import ManagementLeftNav from "../../components/Navbars/ManagementLeftNav"
import { SimpleCircle } from "../../components"
// import { useNavigate } from "react-router-dom"

const addFaqUrl = config.url.API_URL + "faq/addfaq"
const getFaqUrl = config.url.API_URL + "faq/getfaq"
const updateFaqUrl = config.url.API_URL + "faq/updatefaq?"
const deleteFaqUrl = config.url.API_URL + "faq/deletefaq/"

function AdminFaqManagement() {
	const [values, setValues] = useState({})
	const [errorMsg, setErrorMsg] = useState("")
	const [editStatus, setEditStatus] = useState(false)
	const [editObj, setEditObj] = useState({})
	const [faqs, setFaqs] = useState([])
	const [editId, setEditId] = useState("")
	const reFocus = useRef(null)
	const editorRef = useRef()
	const [editorLoaded, setEditorLoaded] = useState(false)
	const { CKEditor, ClassicEditor } = editorRef.current || {}

	// const navigate = useNavigate()

    // useEffect(() => {
    //     const user = typeof window !== "undefined"
    //             ? JSON.parse(localStorage.getItem("currentUser"))
    //             : "";

    //     if(user?.userRole !== '1010') navigate('/admin/')
    // }, [])

	useEffect(() => {
		editorRef.current = {
			// CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
			CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
			ClassicEditor: require('@ckeditor/ckeditor5-build-classic')
		}
		setEditorLoaded(true)
	}, [])

	const getFaq = async () => {
		const response = await axios.get(getFaqUrl)
		setFaqs(response.data.responseData)
	}

	const handleChange = (e) => {
		setValues((values) => ({ ...values, [e.target.name]: e.target.value }))
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		const response = await axios.post(addFaqUrl, values)
		// //console.log(response)
		if (response.data.ack === 1) setValues({})
		getFaq()
		response.data.status === 402
			? setErrorMsg("Question already exist")
			: setErrorMsg("")

		// const data = window.btoa(unescape(encodeURIComponent(values.answer)))
		// values.answer = data
		// //console.log(values)

		// Encode the String
		// //console.log("ami encode",encodedString) // Outputs: "SGVsbG8gV29ybGQh"

		// Decode the String
		// var decodedString = decodeURIComponent(escape(window.atob(data)))
		// //console.log("ami decode",decodedString) // Outputs: "SGVsbG8gV29ybGQh"
	}

	// edit start
	const handleEdit = (id, ques, ans) => {
		setEditObj({
			question: ques,
			answer: ans,
		})
		setEditId(id)
		setEditStatus(true)
		reFocus.current.focus()
	}

	const cancelEdit = () => {
		setEditStatus(false)
		setEditId("")
		setEditObj({})
		setValues({})
	}

	const handleUpdate = async (e) => {
		e.preventDefault()
		const response = await axios.patch(updateFaqUrl + `id=${editId}`, editObj)
		if (response.data.ack === 1) {
			getFaq()
			cancelEdit()
		}
	}

	const deleteFAQ = async (id) => {
		const result = window.confirm("Are you sure?")
		if (!result) return
		await axios.delete(deleteFaqUrl + id)
		getFaq()
	}

	useEffect(() => {
		getFaq()
	}, [])

	return (
        <>
        <div className='container-fluid'>
                <div className="row">
                    <div className="col-lg-2">
                        <ManagementLeftNav />
                    </div>
                    <div className="col-lg-10 bg_allp" style={{ minHeight: "100vh" }}>
			{editStatus ? (
				<form onSubmit={handleUpdate}>
					<div className="form-group mt-4">
						<label htmlFor="FormControlInput1">Edit FAQ Question</label>
						<input
							type="text"
							className="form-control"
							id="FormControlInput1"
							placeholder="add questions here"
							name="question"
							onChange={(e) => {
								setEditObj((values) => ({
									...values,
									[e.target.name]: e.target.value,
								}))
							}}
							value={editObj.question || ""}
							ref={reFocus}
							required
						/>
					</div>
					<div className="form-group mt-3">
						<label htmlFor="FormControlTextarea1">Edit Answer</label>
					</div>
					{editorLoaded ?
					<CKEditor
						editor={ClassicEditor}
						data={editObj.answer || ""}
						onReady={(editor) => {
							// You can store the "editor" and use when it is needed.
							//console.log('Editor is ready to use!', editor)
						}}
						onChange={(event, editor) => {
							setEditObj((editObj) => ({
								...editObj,
								answer: editor.getData(),
							}))
						}}
						onBlur={(event, editor) => {
							// //console.log( 'Blur.', editor )
						}}
						onFocus={(event, editor) => {
							// //console.log( 'Focus.', editor )
						}}
					/> : <div><SimpleCircle /></div>}
					<div className="d-flex gap-3">
						<button type="submit" className="mt-4 btn btn-primary">
							Update
						</button>
						<button
							type="button"
							className="mt-4 btn btn-danger"
							onClick={cancelEdit}
						>
							Cancel
						</button>
					</div>
					{errorMsg && <div className="text-danger mt-4">{errorMsg}</div>}
				</form>
			) : (
				<form onSubmit={handleSubmit}>
					<div className="form-group mt-4">
						<label htmlFor="FormControlInput1">Add FAQ Question</label>
						<input
							type="text"
							className="form-control"
							id="FormControlInput1"
							placeholder="add questions here"
							name="question"
							onChange={handleChange}
							value={values.question || ""}
							required
						/>
					</div>
					<div className="form-group mt-3">
						<label htmlFor="FormControlTextarea1">Add Answer</label>
					</div>
					{editorLoaded ? 
					<CKEditor
						editor={ClassicEditor}
						data={values.answer || ""}
						onReady={(editor) => {
							// You can store the "editor" and use when it is needed.
							//console.log('Editor is ready to use!', editor)
						}}
						onChange={(event, editor) => {
							setValues((values) => ({ ...values, answer: editor.getData() }))
						}}
						onBlur={(event, editor) => {
							// //console.log( 'Blur.', editor )
						}}
						onFocus={(event, editor) => {
							// //console.log( 'Focus.', editor )
						}}
					/> : <div><SimpleCircle /></div>}
					<button type="submit" className="mt-4 btn btn-primary">
						Submit
					</button>
					{errorMsg && <div className="text-danger mt-4">{errorMsg}</div>}
				</form>
			)}

			<div className="mt-5">
				<h2>FAQ'S</h2>
				<Accordion defaultActiveKey="0">
					{faqs &&
						faqs.map((item, index) => {
							return (
								<Accordion.Item eventKey={index} key={index}>
									<Accordion.Header>
										<div className="d-flex justify-content-between w-97">
											<div className="fw-500">{item.question}</div>
											{/* deleteFAQ */}
											<div className="d-flex gap-3">
												<button
													className="btn btn-success"
													onClick={() =>
														handleEdit(item._id, item.question, item.answer)
													}
												>
													Edit
												</button>
												<button
													className="btn btn-danger"
													onClick={() => deleteFAQ(item._id)}
												>
													Delete
												</button>
											</div>
										</div>
									</Accordion.Header>
									<Accordion.Body
										dangerouslySetInnerHTML={{ __html: item.answer }}
									/>
									{/* {} */}
									{/* </Accordion.Body> */}
								</Accordion.Item>
							)
						})}
				</Accordion>
			</div>
		</div>
                </div>
                </div>
        </>
		
	)
}

export default AdminFaqManagement
