import React, { useState } from "react"
import axios from "axios"
import { config } from "../../constant"
import {
  FacebookAuthProvider,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth"
import { auth } from "./FireBase"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect } from "react"
import SocialUserSignUp from "./SocialUserSignUp"
const USER_URL = config.url.API_URL + "users/"

function SocialAppLoginx() {
  // const auth = getAuth()
  const navigate = useNavigate()
  const [userData, setUserData] = useState({})
  const [provider, setProvider] = useState("")
  const [searchParams] = useSearchParams()
  const [isRegistrationRequired, setIsRegistrationRequired] = useState(false)

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      'prompt': 'select_account',
    })

    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("firebase", result)
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result)
        // const token = credential.accessToken
        // The signed-in user info.
        const user = result.user
        const tokenResponse = result?._tokenResponse
        console.log("firebase google user", user, tokenResponse)
        setProvider("google")
        setUserData(result?._tokenResponse)
        // ...
      })
      .catch((error) => {
        console.log("firebase error", error)
        // Handle Errors here.
        // const errorCode = error.code
        // const errorMessage = error.message
        // // The email of the user's account used.
        // const email = error.customData.email
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error)
        // ...
      })
  }

  const FacebookSignIn = () => {
    const provider = new FacebookAuthProvider()
    provider.setCustomParameters({
      'prompt': 'select_account',
    })
    if (auth) {
      signInWithPopup(auth, provider)
        .then((result) => {
          // console.log(result)
          // The signed-in user info.
          const user = result.user
          const tokenResponse = result?._tokenResponse
          console.log("firebase google user", user, tokenResponse)
          setProvider("facebook")
          setUserData(result?._tokenResponse)
          // This gives you a Facebook Access Token. You can use it to access the Facebook API.
          // const credential = FacebookAuthProvider.credentialFromResult(result)
          // const accessToken = credential.accessToken
          // ...
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code
          const errorMessage = error.message
          // The email of the user's account used.
          const email = error.customData.email
          // The AuthCredential type that was used.
          const credential = FacebookAuthProvider.credentialFromError(error)
          console.error(errorCode,errorMessage,email,credential)

          // ...
        })
    }
  }

  const redirectUser = (user) => {
    localStorage.setItem("token", user?.id)
    localStorage.setItem("currentuser", JSON.stringify(user))
    const redirect = searchParams.get("redirectTo")
    navigate(redirect || "/creations/")
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (!provider) return
    if (!userData?.localId) return
    // const response = await axios.get(
    //     USER_URL + `getusers?userID=${userData?.localId}&mode=1`
    // )

    const response = await axios.get(
      USER_URL +
      `getusers?mode=1&loginType=${provider}&email=${userData?.email}`
    )
      
    if (response.data.ack === 1)
      return redirectUser(response.data.responseData[0])
    if (response.data.ack === 0) setIsRegistrationRequired(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, provider])

  return (
    <div className="container">
      <div className="row">
        <div className="social_login_btn_sec">
          {!isRegistrationRequired && (
            <>
              <button
                className="btn btn-primary kep-login-facebook button"
                onClick={FacebookSignIn}
              >
                <i className="fa fa-facebook" aria-hidden="true"></i>Sign in
                with Facebook
              </button>
              <button
                className="btn btn-primary kep-login-facebook button g_login"
                onClick={googleSignIn}
              >
                <i className="fa fa-google" aria-hidden="true"></i>Sign in with
                Google
              </button>
            </>
          )}
          {/* <button className='btn btn-primary' onClick={FacebookSignIn}>sign in with Facebook</button> */}
          {isRegistrationRequired && (
            <SocialUserSignUp
              userid={userData?.localId}
              firstName={userData?.firstName || ""}
              lastName={userData?.lastName || ""}
              showName={userData?.displayName}
              photoUrl={userData?.photoUrl}
              provider={provider}
              email={userData?.email}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SocialAppLoginx
