import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";
import LoginStatus from "../../components/DesignComponents/LoginStatus";
import { Footer, IndexNavbar } from "../../components";
import { connect } from "react-redux";
import {
  getOrganizationDetails,
  getOrganizationPosts,
} from "../../redux/reducers/organizationReducer";
import OrganizationFrontSection from "./OrganizationFrontSection";
import OrganizationPosts from "./OrganizationPosts";

function OrganizationPostDetails(props) {
  // const { isSuccess, isError, orgDetails, isProcessing } = props
  const { posts, getOrgPosts, orgDetails } = props;
  const param = useParams();
  const path = useLocation();
  // const navigate = useNavigate()

  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const currentUser =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("currentuser"))
      : "";
  const init = async () => {
    if (token === orgDetails?.userid) {
      await getOrgPosts({
        orgid: orgDetails?._id,
        ownerid: token,
        statustype: "all",
      });
    } else {
      await getOrgPosts({
        ownerid: token,
        orgid: orgDetails?._id,
      });
    }
  };
  useEffect(() => {
    if (!orgDetails?._id) return;
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgDetails]);

  const Kelmet = () => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Allphanes | ${param.name}`}</title>
        <link rel="canonical" href={`https://allphanes.com${path.pathname}`} />
      </Helmet>
    );
  };

  return (
    <>
      <Kelmet />
      <IndexNavbar />
      <div
        id="background_bg"
        style={{
          backgroundImage: `url(${"/assets/web_img/background_bg.jpg"})`,
        }}
      >
        <OrganizationFrontSection orgDetails={orgDetails} />

        <div className="container">
          <div className="row justify-content-center">
            {/* Middle section  */}
            <div className="col-lg-12 col-md-10">
              <section id="profile" className="mt-3">
                {orgDetails?.userid && (
                  <>
                    <OrganizationPosts orgDetails={orgDetails} posts={posts} />
                  </>
                )}
              </section>
            </div>
          </div>
        </div>
      </div>
      {!token && <LoginStatus showname={currentUser?.showName} />}
      <Footer />
    </>
  );
}

OrganizationPostDetails.loadData = (params, search) => async (dispatch) => {
  //   const query = queryParse(search);
  console.log("query>>>", params, search);
  const name = params.split("/")[2];
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const viewerid = token ? `&viewerid=${token}` : "";
  const data = await dispatch(
    await getOrganizationDetails(`?name=${name}${viewerid}`)
  );
  return data;
};
const mapStateToProps = (state) => {
  console.log("stateoRgpost", state);
  const { isSuccess, isError, data, posts, isProcessing } = state.organization;
  return { isSuccess, isError, orgDetails: data, posts: posts, isProcessing };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOrgPosts: (params) => {
      dispatch(getOrganizationPosts(params));
    },
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationPostDetails);
