import heic2any from "heic2any"
import Compressor from "compressorjs"

export const Heic2Jpg = async (file) => {
  const resultBlob = await heic2any({
    blob: file,
    toType: "image/jpg",
  })
  return resultBlob
}

export const compressImage = (file) => {
  let kk = new Compressor(file, {
    quality: 0.6,
    success: (result) => result,
  })
  return kk
}
