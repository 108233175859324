import React, { useState } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined"
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import Button from "@mui/material/Button"
import SendIcon from "@mui/icons-material/Send"
import Tooltip from "@mui/material/Tooltip"
import EditPost from "../Post/EditPost"
import { useEffect } from "react"
import { AlertDialog } from "../../../_helpers/Material"
import { YouTubeEmbed } from "react-social-media-embed"
import Comments from "../Comments"
import { CopyLink, FileViewer } from "../AppComponents/Helpers"
import ReadMore from "../Readmore"
import {
  PostDescriptionComponent,
  SharePostAsFeed,
} from "../Post/PostComponents"
import SimpleCircle from "../Loaders/SimpleCircle"
import LikeModal from "../Modals/LikeModal"
import ShareModal from "../Modals/ShareModal"
import ReportPost from "../Modals/ReportModal"
import SinglePost from "../../containers/Creation/SinglePost"
import { useDispatch } from "react-redux"
import { orgPostsAction } from "../../redux/reducers/organizationReducer"
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined"
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined"
import {
  postRequestHandler,
  getRequestHandler,
  deleteRequestHandler,
  putRequestHandler,
} from "../../redux/reducers/requestHandler.reducer"
import { BookmarkOutlined } from "@mui/icons-material"
import CustomizedSnackbars from "../Loaders/Snuckbars"

export const SavePost = ({ id, isMarked }) => {
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const [isSaved, setIsSaved] = useState(isMarked)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const [msg, setMsg] = useState("Post saved successfully")
  const dispatch = useDispatch()

  const handleFavouriteClick = async () => {
    try {
      let req = {
        data: {
          referenceUserId: token,
          referencePostId: id,
        },
        url: "favPost",
      }
      const response = await dispatch(postRequestHandler(req))
      if (response?.data?.ack === 0) {
        setIsError(true)
        return setMsg("Opps! something went wrong")
      }

      setIsSuccess(true)
      setMsg(
        response?.data?.responseData
          ? "Post Saved successfully"
          : "Post successfully removed from favourite"
      )
      setIsSaved(response?.data?.responseData)
    } catch (error) {
      setIsError(true)
      setMsg(
        "Post canot be saved due to some technical issue. Please try again later"
      )
    }
  }

  return (
    <>
      <Tooltip
        title={
          isSaved
            ? "click to remove from your saved posts"
            : "click to save as favourite post"
        }
        arrow
      >
        <div
          className={
            isSaved ? "like commentx saved_post active" : "like commentx"
          }
          onClick={() => handleFavouriteClick()}
        >
          <BookmarkOutlined />
        </div>
      </Tooltip>
      {isSuccess && (
        <CustomizedSnackbars
          type={0}
          msg={msg}
          sclose={() => setIsSuccess(false)}
        />
      )}
      {isError && (
        <CustomizedSnackbars
          type={1}
          msg={msg}
          sclose={() => setIsError(false)}
        />
      )}
    </>
  )
}
export const PostLike = ({ id, isLike, getlikes }) => {
  const [isLiked, setIsLiked] = useState(isLike)
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const dispatch = useDispatch()

  const handleLikeClick = async () => {
    try {
      let req = {
        data: {
          referenceUserId: token,
          referencePostId: id,
        },
        url: "postLike",
      }
      const response = await dispatch(postRequestHandler(req))
      if (response?.data?.ack === 0) return console.error("like error")
      setIsLiked(response?.data?.responseData?.isLike)
      getlikes()
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <Tooltip title="Like" arrow>
      <div className="like commentx" onClick={() => handleLikeClick()}>
        {isLiked ? (
          <FavoriteOutlinedIcon style={{ color: "red" }} />
        ) : (
          <FavoriteBorderOutlinedIcon />
        )}
      </div>
    </Tooltip>
  )
}

export const PostProfilePhoto = (props) => {
  const { image, showName } = props
  return (
    <img
      src={image || "/assets/web_img/choto_logo_1.png"}
      alt={showName}
      onError={(e) => {
        e.target.onerror = null
        e.target.src = "/assets/web_img/choto_logo_1.png"
      }}
    />
  )
}
export const PostProfile = (props) => {
  const { redirect, profilePhoto, showName, createdAt } = props
  return (
    <>
      <Link to={redirect} className="posted-user mt-2">
        <div className="posted-user-d">
          <div className="pro_img">
            <PostProfilePhoto image={profilePhoto} showName={showName} />
          </div>
          <div className="post-user-name">
            {showName}
            <div className="fs_12">{moment(createdAt).fromNow(true)}</div>
          </div>
        </div>
      </Link>
    </>
  )
}

export const DeleteAction = (props) => {
  const { buttonText, component, execute, params, alertTitle, alertMsg } = props
  const [confirm, setConfirm] = useState(false)

  useEffect(() => {
    if (confirm) return execute(params)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirm])

  return (
    <>
      <AlertDialog
        component={component}
        buttonText={buttonText || "Delete Post"}
        alertTitle={alertTitle}
        alertMsg={alertMsg}
        setConfirm={setConfirm}
      />
    </>
  )
}

export const PostActionsDropDown = (props) => {
  const {
    token,
    PostAction,
    singlePost,
    deletePost,
    setReportShow,
    reportShow,
    isOrgAdmin,
  } = props
  return (
    <>
      <div className="d-flex gap-2 bb_kib">
        <div className="post_actions d-flex align-items-center justify-content-end c_pointer">
          <span className="like commentx">
            <MoreHorizOutlinedIcon />
          </span>

          <div className="dropdown_post_actions shadow_1 br_5">
            {singlePost?.referenceUserId === token ? (
              <>
                <EditPost post={singlePost} />
                <DeleteAction
                  execute={deletePost}
                  params={singlePost?._id}
                  alertTitle={"Delete Post"}
                  alertMsg={
                    "Want to delete this post. Please click agree to confirm it."
                  }
                />
              </>
            ) : (
              <>
                {isOrgAdmin && singlePost?.status === "rejected" && (
                  <>
                    <Button
                      className="w-100 text-success"
                      onClick={() => PostAction(singlePost?._id, "active")}
                    >
                      Approve
                    </Button>
                    <Button
                      className="w-100"
                      title="Add to the pending queue"
                      onClick={() => PostAction(singlePost?._id, "pending")}
                    >
                      Pending
                    </Button>
                  </>
                )}
                {isOrgAdmin && singlePost?.status === "pending" && (
                  <>
                    <Button
                      className="w-100 text-success"
                      onClick={() => PostAction(singlePost?._id, "active")}
                    >
                      Approve
                    </Button>

                    <Button
                      className="w-100 text-danger"
                      onClick={() => PostAction(singlePost?._id, "rejected")}
                    >
                      Reject
                    </Button>
                  </>
                )}
                <ReportPost
                  pid={singlePost?._id}
                  reportShow={reportShow}
                  setReportShow={setReportShow}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export const PostBlogShow = (props) => {
  const { content } = props
  return (
    <>
      <div
        className="blog_body"
        dangerouslySetInnerHTML={{
          __html: content?.replace(/(<([^>]+)>)/gi, ""),
        }}
      />
    </>
  )
}

export const PostBody = (props) => {
  const { singlePost, isViewDetails, setIsPostModal } = props
  return (
    <div className="hola">
      <div className="post_file_viewer post_video_img_audio">
        {singlePost?.postInfo[0] && (
          <FileViewer
            id={singlePost?.postInfo[0]?._id}
            link={singlePost?.postInfo[0]?.postImagePath}
            photo={singlePost?.user_info[0]?.profilePhoto}
          />
        )}
        {/Normal|pre/.test(singlePost?.postType) && singlePost?.url && (
          <YouTubeEmbed url={singlePost?.url} width={"100%"} height={320} />
        )}
      </div>
      {singlePost?.postInfo[0] &&
        singlePost?.postType !== "shared" &&
        singlePost?.postTitle}

      {!singlePost?.postInfo[0] && (
        <pre className="mt-4 px_15">
          {singlePost?.postCategory === "Normal"
            ? ""
            : singlePost?.postTitle !== "Quick Post" && (
              <div className="mb-3 fs_tt">
                {singlePost?.postType !== "shared" && singlePost?.postTitle}
              </div>
            )}

          {/Blogs/.test(singlePost?.postCategory) && (
            <>
              <PostBlogShow content={singlePost?.postDescription} />
            </>
          )}
          {!/Blogs/.test(singlePost?.postCategory) &&
            singlePost?.postInfo?.length === 0 &&
            !singlePost?.url && (
              <ReadMore className="view-post-des mt-2">
                {singlePost?.postDescription}
              </ReadMore>
            )}
          <h6 className="mt-2 text-primary">{singlePost?.keywords2}</h6>
        </pre>
      )}

      {isViewDetails && (
        <>
          <div
            className="d-flex justify-content-end  mb-2"
            onClick={() => setIsPostModal(true)}
          >
            <div className="view_more  p-1 px-2 c_pointer">View Details</div>
          </div>
          {/* <Link
						to={`/p/${singlePost?._id}/`}
						className="d-flex justify-content-end  mb-2"
						onClick={() => setIsPostModal(true)}
					>
						<div className="view_more  p-1 px-2 c_pointer">
							View Details
						</div>
					</Link> */}
        </>
      )}
    </div>

  )
}

export const PostLastSecOne = (props) => {
  const { totalLikes, totalComments } = props
  return (
    <>
      <div className="comment_bottom d-flex gap-3 justify-content-start">
        <div className="d-flex">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#6ea20b"
          >
            <g>
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <g>
                <circle cx="4" cy="14" r="2" />
                <path d="M1.22,17.58C0.48,17.9,0,18.62,0,19.43V21l4.5,0v-1.61c0-0.83,0.23-1.61,0.63-2.29C4.76,17.04,4.39,17,4,17 C3.01,17,2.07,17.21,1.22,17.58z" />
                <circle cx="20" cy="14" r="2" />
                <path d="M22.78,17.58C21.93,17.21,20.99,17,20,17c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V21l4.5,0v-1.57 C24,18.62,23.52,17.9,22.78,17.58z" />
                <path d="M16.24,16.65c-1.17-0.52-2.61-0.9-4.24-0.9c-1.63,0-3.07,0.39-4.24,0.9C6.68,17.13,6,18.21,6,19.39V21h12v-1.61 C18,18.21,17.32,17.13,16.24,16.65z" />
                <path d="M9,12c0,1.66,1.34,3,3,3s3-1.34,3-3c0-1.66-1.34-3-3-3S9,10.34,9,12z" />
                <path d="M2.48,10.86C2.17,10.1,2,9.36,2,8.6C2,6.02,4.02,4,6.6,4c2.68,0,3.82,1.74,5.4,3.59C13.57,5.76,14.7,4,17.4,4 C19.98,4,22,6.02,22,8.6c0,0.76-0.17,1.5-0.48,2.26c0.65,0.31,1.18,0.82,1.53,1.44C23.65,11.1,24,9.88,24,8.6 C24,4.9,21.1,2,17.4,2c-2.09,0-4.09,0.97-5.4,2.51C10.69,2.97,8.69,2,6.6,2C2.9,2,0,4.9,0,8.6c0,1.28,0.35,2.5,0.96,3.7 C1.31,11.68,1.84,11.17,2.48,10.86z" />
              </g>
            </g>
          </svg>
          {totalLikes} Likes
        </div>
        <div className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#2570c3"
          >
            <g>
              <rect fill="none" height="24" width="24" />
              <rect fill="none" height="24" width="24" />
            </g>
            <g>
              <g>
                <polygon points="22,9 22,7 20,7 20,9 18,9 18,11 20,11 20,13 22,13 22,11 24,11 24,9" />
                <path d="M8,12c2.21,0,4-1.79,4-4s-1.79-4-4-4S4,5.79,4,8S5.79,12,8,12z" />
                <path d="M8,13c-2.67,0-8,1.34-8,4v3h16v-3C16,14.34,10.67,13,8,13z" />
                <path d="M12.51,4.05C13.43,5.11,14,6.49,14,8s-0.57,2.89-1.49,3.95C14.47,11.7,16,10.04,16,8S14.47,4.3,12.51,4.05z" />
                <path d="M16.53,13.83C17.42,14.66,18,15.7,18,17v3h2v-3C20,15.55,18.41,14.49,16.53,13.83z" />
              </g>
            </g>
          </svg>
          {totalComments} Comments
        </div>
      </div>
    </>
  )
}

export default function PostTestCard(props) {
  const {
    orgDetails,
    getpost,
    isViewDetails,
    isPostCommentSec,
    singlePost,
    isPostActionSec,
    isOrgAdmin,
    isOrgMember,
  } = props
  const [totalCom, setTotalCom] = useState(singlePost.totalComment || 0)
  const [modalShow, setModalShow] = useState(false)
  const [commentSwitch, setCommentSwitch] = useState({})
  const [likeSwitch, setLikeSwitch] = useState({})
  const [comment, setComment] = useState([])
  const [modalShow3, setModalShow3] = useState(false)
  const [modalShow4, setModalShow4] = useState(false)
  const [commentModal, setCommentModal] = useState([])
  const [commentModal2, setCommentModal2] = useState([])
  const [commentModal3, setCommentModal3] = useState("")
  const [reportShow, setReportShow] = useState(false)
  const [fetchLength, setFetchLength] = useState(0)
  const [fetchLen, setFetchLen] = useState(0)
  const [totalLikesCount, setTotalLikesCount] = useState(singlePost?.totalLikes || 0)
  const token = localStorage.getItem("token")
  const currentUser = JSON.parse(localStorage.getItem("currentuser"))
  const [isPostModal, setIsPostModal] = useState(false)
  const dispatch = useDispatch()
  console.log("101 props>>>", props)
  //   return
  const getTotalComment = async (pid) => {
    let req = {
      params: {
        pid: pid,
      },
      url: "totalComments",
    }
    const response = await dispatch(getRequestHandler(req))
    setTotalCom(response.data.responseData)
  }

  const getTotalLikes = async () => {
    try {
      let req = {
        params: {
          referencePostId: singlePost?._id,
        },
        url: "totalLikes",
      }
      const response = await dispatch(getRequestHandler(req))
      if (response.data.ack === 0) return
      setTotalLikesCount(response?.data?.responseData)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCommentChange = (e) => {
    setComment((values) => ({ ...values, [e.target.name]: e.target.value }))
  }

  const [isLoaderCom, setIsLoaderCom] = useState(false)
  const handleCommentClick = async (postId, userId, commentName) => {
    setIsLoaderCom(true)
    const dataArr = {
      referenceUserId: userId,
      referencePostId: postId,
      messageText: comment[commentName],
    }
    let req = {
      data: dataArr,
      url: "comments",
    }
    const response = await dispatch(postRequestHandler(req))
    setIsLoaderCom(false)
    if (response.data.ack === 1) {
      setComment((values) => ({ ...values, [commentName]: "" }))
      getTotalComment(postId)
      // props.getpost()
    }
    setComment((values) => ({ ...values, [commentName]: "" }))
  }

  const likeModalClick = async (c, index, limit) => {
    if (c < 1) return
    setModalShow4(true)
    const l = "like" + index
    const d = "comment" + index
    if (fetchLen <= singlePost?.totalLikes) setFetchLen(fetchLen + limit)
    setLikeSwitch((values) => ({ ...values, [l]: index }))
    setCommentSwitch((values) => ({ ...values, [d]: "" }))
  }

  const handleCommentModalClick = (
    postInfo,
    userInfo,
    postDescription,
    postid,
    index,
    limit
  ) => {
    setModalShow3(true)
    const k = "comment" + index
    setFetchLength(fetchLength + limit)
    setCommentSwitch((values) => ({ ...values, [k]: index }))
    setCommentModal(postInfo)
    setCommentModal2(userInfo)
    setCommentModal3({ postid: postid, postDescription: postDescription })
    setLikeSwitch((values) => ({ ...values, [`like${index}`]: "" }))
  }

  const deletePost = async (postId) => {
    let req = {
      params: {
        pid: postId,
      },
      url: "deletePost",
    }
    await dispatch(deleteRequestHandler(req))
    props.getpost()
  }

  const PostAction = async (pid, request) => {
    try {
      const params = {
        body: {
          orgId: orgDetails?._id,
          ownerId: token,
          postId: pid,
          request: request,
        },
        query: {
          orgid: orgDetails?._id,
          multiple: true,
        },
      }
      dispatch(orgPostsAction(params))
      if (props?.getpost) await getpost()
    } catch (err) {
      console.error(err)
    }
  }

  const incrementPostViews = async (post) => {
    try {
      let req = {
        data: {
          pid: post?._id,
        },
        url: "updateView",
      }
      // if (post.referenceUserId !== userId) return
      await dispatch(putRequestHandler(req))
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div
        className="view-post mt-0 mb-4"
        id={singlePost?._id}
        onClick={() => incrementPostViews(singlePost)}
      >
        {singlePost?.org_info && singlePost?.org_info?.length > 0 && (
          <div className="post-creator d-flex justify-content-between align-items-center">
            <PostProfile
              redirect={
                /pre/.test(singlePost?.org_info[0]?.mode)
                  ? `/premium/${singlePost?.org_info[0]?.pageName}/#freepost/`
                  : `/org/${singlePost?.org_info[0]?.pageName?.toLowerCase()}/#post/#active/`
              }
              profilePhoto={singlePost?.org_info[0]?.organiztionLogo}
              showName={
                singlePost?.org_info[0]?.showName ||
                singlePost?.org_info[0]?.pageName
              }
              createdAt={singlePost?.createdAt}
            />

            <div className="post_marks">
              {/pre/.test(singlePost?.org_info[0]?.mode) ? "premium" : "org"}
            </div>
          </div>
        )}

        <div className="post-creator d-flex justify-content-between align-items-center">
          {singlePost?.user_info && singlePost?.user_info.length > 0 && (
            <PostProfile
              redirect={"/" + singlePost?.user_info[0]?.userName?.toLowerCase()}
              profilePhoto={singlePost?.user_info[0]?.profilePhoto}
              showName={singlePost?.user_info[0]?.showName}
              createdAt={singlePost?.createdAt}
            />
          )}
          <div>
            {singlePost?.postCategory && (
              <div className="post_marks">{singlePost?.postCategory}</div>
            )}
            {singlePost?.gallerType && (
              <div className="post_marks">{singlePost?.gallerType}</div>
            )}
          </div>
          <PostActionsDropDown
            isOrgAdmin={isOrgAdmin}
            isOrgMember={isOrgMember}
            token={token}
            PostAction={PostAction}
            singlePost={singlePost}
            deletePost={deletePost}
            setReportShow={setReportShow}
            reportShow={reportShow}
          />
        </div>
        <PostBody
          singlePost={singlePost}
          isViewDetails={isViewDetails || false}
          setIsPostModal={setIsPostModal}
        />
        {/shared/.test(singlePost?.postType) && (
          <>
            <div className="share_body">
              {/shared/.test(singlePost?.postType) && singlePost?.url && (
                <YouTubeEmbed
                  url={singlePost?.url}
                  width={"100%"}
                  height={320}
                />
              )}
              {singlePost?.shareGalleryInfo && (
                <FileViewer link={singlePost?.shareGalleryInfo} />
              )}
              <div className="share_sec">
                {singlePost?.pageId && <div className="org_mark">org</div>}
                <div className="view_name p-2 px-3">
                  {singlePost?.pageId ? (
                    <Link to={"/org/" + singlePost?.shareFullName}>
                      {singlePost?.shareFullName}
                    </Link>
                  ) : singlePost?.shareUserName ? (
                    <Link to={"/" + singlePost?.shareUserName}>
                      {singlePost?.shareFullName}
                    </Link>
                  ) : (
                    singlePost?.shareFullName + " : " + singlePost?.postTitle
                  )}
                </div>
                <div className="px-3 ">
                  <div className="fs_tt cl_b">{singlePost?.postTitle}</div>
                  <PostDescriptionComponent
                    description={singlePost?.shareDescription}
                  />
                </div>
                <div className="posted-user kb_1">
                  <div className="d-flex align-items-center px-2">
                    <div className="pro_img py-2">
                      <img
                        src={
                          singlePost?.shareProfile ||
                          "/assets/web_img/choto_logo_1.png"
                        }
                        alt={singlePost?.shareFullName || "user"}
                        onError={(e) => {
                          e.target.onerror = null
                          e.target.src = "/assets/web_img/choto_logo_1.png"
                        }}
                      />
                    </div>
                    <div className="post-user-name">
                      {singlePost?.shareFullName && singlePost?.shareFullName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {isPostActionSec && (
          <>
            {isPostCommentSec && (
              <div>
                <Link
                  to={`/p/${singlePost?._id}/`}
                  className="like commentx d-block text-right text-dark pt-1 pb-1"
                >
                  {/* <DetailsIcon /> */}
                  View Details
                </Link>
              </div>
            )}
            <div className="post-action-sec d-flex justify-content-between">

              <div className="d-flex gap-3">
                {/* Post like */}
                {singlePost?.referenceUserId === token ? (
                  ""
                ) : (
                  <PostLike
                    id={singlePost?._id}
                    isLike={singlePost?.isLiked[0]?.isLike}
                    getlikes={() => getTotalLikes()}
                  />
                )}

                {isPostCommentSec && (
                  <Tooltip title="click to view comments" arrow>
                    <div
                      className="like commentx"
                      onClick={() =>
                        handleCommentModalClick(
                          singlePost?.postInfo[0],
                          singlePost?.user_info[0],
                          singlePost?.postDescription,
                          singlePost?._id,
                          props.indexinfo,
                          3
                        )
                      }
                    >
                      <CommentOutlinedIcon />
                    </div>
                  </Tooltip>
                )}

                {singlePost?.user_info &&
                  token === singlePost?.user_info[0]?._id ? (
                  ""
                ) : (
                  <SharePostAsFeed post={singlePost} getpost={getpost} />
                )}

                <div className="like commentx">
                  <CopyLink link={`/p/${singlePost?._id}?redirect=1`} />
                </div>

              </div>
              <div className="d-flex gap-2">
                {singlePost?.isFav && singlePost?.isFav.length > 0 && (
                  <SavePost
                    id={singlePost?._id}
                    isMarked={singlePost?.isFav[0]?.mark}
                  />
                )}
              </div>
            </div>
          </>
        )}

        {isPostCommentSec ? (
          <>
            <div className="px_15-c">
              {/* like */}
              <div className="likes_count">
                <span
                  className="likes_count_btn c_point"
                  title="Click to see who likes your post"
                  onClick={() =>
                    likeModalClick(singlePost?.totalLikes, props?.indexinfo, 11)
                  }
                >
                  {totalLikesCount} Likes
                </span>
                <span
                  className="likes_count_btn c_point"
                  title="Click to see recent comments"
                  onClick={() =>
                    handleCommentModalClick(
                      singlePost?.postInfo[0],
                      singlePost?.user_info[0],
                      singlePost?.postDescription,
                      singlePost?._id,
                      props.indexinfo,
                      11
                    )
                  }
                  style={{ marginLeft: "10px" }}
                >
                  {totalCom}
                  {" Comments"}
                </span>

                {/* Comments  */}
                {singlePost?.postInfo[0] && singlePost?.postDescription && (
                  <>
                    <div className="fs_tt cl_b">{singlePost?.postTitle}</div>
                    <PostDescriptionComponent
                      description={singlePost?.postDescription}
                      keyword={singlePost?.keywords2}
                    />
                  </>
                )}

                {commentSwitch["comment" + props.indexinfo] ===
                  props.indexinfo ? (
                  <>
                    <Comments
                      className="width-100"
                      show={modalShow3}
                      onHide={() => setModalShow3(false)}
                      backdrop="static"
                      userinfo={commentModal2}
                      postinfo={commentModal}
                      postdetails={commentModal3}
                      key={props.indexinfo}
                      limit={fetchLength}
                      tokenuser={currentUser?.profilePhoto}
                      settotalCom={getTotalComment}
                    />
                  </>
                ) : (
                  ""
                )}

                {commentSwitch["comment" + props.indexinfo] ===
                  props.indexinfo ? (
                  <div className="d-flex gap-2">
                    {fetchLength >= singlePost?.totalComment ? (
                      <div className="comment_count">
                        You have seen all comments,
                      </div>
                    ) : (
                      <div
                        className="comment_count"
                        style={{ cursor: "pointer" }}
                        title="Click to view more comments"
                        onClick={() =>
                          handleCommentModalClick(
                            singlePost?.postInfo[0],
                            singlePost?.user_info[0],
                            singlePost?.postDescription,
                            singlePost?._id,
                            props.indexinfo,
                            11
                          )
                        }
                      >
                        View more comments,
                      </div>
                    )}

                    <div
                      className="comment_count close_comment"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setCommentSwitch((values) => ({
                          ...values,
                          ["comment" + props.indexinfo]: "",
                        }))
                      }
                    >
                      Close comments
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>

              {commentSwitch["comment" + props.indexinfo] !==
                props.indexinfo ? (
                <div className="mt-2 add_comment d-flex">
                  <input
                    type="text"
                    name={"comment" + props.indexinfo}
                    onChange={handleCommentChange}
                    value={comment["comment" + props.indexinfo]}
                    className="comment canvas_com"
                    placeholder="Add comment"
                  />

                  {isLoaderCom ? (
                    <Tooltip title={"click to post comment"} arrow>
                      <div className="btn cmn_btn">
                        <SimpleCircle />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title={"click to post comment"} arrow>
                      <div
                        className="btn cmn_btn"
                        onClick={() =>
                          handleCommentClick(
                            singlePost?._id,
                            token,
                            "comment" + props.indexinfo
                          )
                        }
                      >
                        <SendIcon />
                      </div>
                    </Tooltip>
                  )}
                </div>
              ) : (
                ""
              )}

              {likeSwitch["like" + props.indexinfo] === props.indexinfo ? (
                <>
                  <LikeModal
                    className="width-100"
                    show={modalShow4}
                    onHide={() => setModalShow4(false)}
                    backdrop="static"
                    postid={singlePost?._id}
                    key={props.indexinfo}
                    limit={fetchLen}
                  />
                  <div className="d-flex gap-2 mb-2">
                    {fetchLen >= singlePost?.totalLikes ? (
                      <div className="comment_count">
                        You have seen all Like users,
                      </div>
                    ) : (
                      <div
                        className="comment_count"
                        style={{ cursor: "pointer" }}
                        title="Click to view more comments"
                        onClick={() =>
                          likeModalClick(
                            singlePost?.totalLikes,
                            props?.indexinfo,
                            11
                          )
                        }
                      >
                        View more,
                      </div>
                    )}

                    <div
                      className="comment_count close_comment"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setLikeSwitch((values) => ({
                          ...values,
                          ["like" + props.indexinfo]: "",
                        }))
                      }
                    >
                      Close
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          <>
            <PostLastSecOne
              totalLikes={singlePost?.totalLikes}
              totalComments={singlePost?.totalComment}
            />
          </>
        )}
      </div>

      <ShareModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        backdrop="static"
        info={props}
      />
      {isPostModal && (
        <SinglePost
          type="modal"
          isView={isPostModal}
          postid={singlePost._id}
          closeBtn={() => setIsPostModal(false)}
        />
      )}
    </>
  )
}
