import React from "react"
import { Link, useNavigate } from "react-router-dom"

// import Online from "../Online"

function ManagementLeftNav(props) {
   const navigate = useNavigate()

   const logOut = async (e) => {
      // const response = await axios.patch(usersUrl + tokens, { isActive: false });
      //   if (response) {
      localStorage.clear();
      navigate("/admin/");
      window.location.reload(false)
      //   }
   };

   return (
      <section id="leftBar">
         <div className="left-sec-1">
            {/* <div className="left-sec-con"><Link to="/mycanvas">Events</Link></div> */}
            <div className="left-sec-con"><Link to="/admin/dashboard/">Dashboard</Link></div>
            <div className="left-sec-con"><Link to="/admin/management/domains/">Domain Management</Link></div>
            <div className="left-sec-con"><Link to="/admin/management/blogs/">Blog Management</Link></div>
            <div className="left-sec-con"><Link to="/admin/management/news/">News Management</Link></div>
            <div className="left-sec-con"><Link to="/admin/management/posts/">Post Management</Link></div>
            <div className="left-sec-con"><Link to="/admin/management/faq/">FAQ Management</Link></div>
            <div className="left-sec-con"><Link to="/admin/management/seo/">SEO Management</Link></div>
            <div className="left-sec-con"><Link to="/admin/management/referral/">Referral Management</Link></div>
            <div className="left-sec-con"><Link to="/admin/management/homepage/">Homepage Management</Link></div>
            <div className="left-sec-con"><Link to="/admin/management/subscriptions/">Subscription Management</Link></div>
            {/* <div className="left-sec-con"><Link to="/admin/management/wallet/">Wallet Management</Link></div> */}
            <div className="left-sec-con c_pointer" onClick={logOut}><div>Log Out</div></div>
         </div>

      </section>
   )
}

export default ManagementLeftNav