export const ACTION_GET_MYSPACE_REQUEST = "ACTION_GET_MYSPACE_REQUEST"
export const ACTION_GET_MYSPACE_FAILD = "ACTION_GET_MYSPACE_FAILD"
export const ACTION_GET_MYSPACE_SUCCESS = "ACTION_GET_MYSPACE_SUCCESS"
export const ACTION_GET_MYSPACE_SUCCESSF = "ACTION_GET_MYSPACE_SUCCESSF"
export const ACTION_GET_MYSPACE_STATE = "ACTION_GET_MYSPACE_STATE"
export const UPDATE_PROFILE_MYSPACE = "UPDATE_PROFILE_MYSPACE"

const initialState = {
    user: null,
    noOfPost: 0,
    noOfPhotos: 0,
    noOfVideos: 0,
    totalFriends: 0,
    isFriend: null,
    isFollow: null,
    noOfFollowers: 0,
    noOfFollowings: 0,
    posts: [],
    photos: [],
    videos: [],
    friends: [],
    followers: [],
    followings: []
}

export const MySPaceDataReducer = (state = initialState, action) => {
    let returnObj = {
        ...state
    }

    switch (action.type) {
        case UPDATE_PROFILE_MYSPACE:
            let kk = {}
            kk = {
                ...state?.user,
                bio: action?.payload?.bio
            }
            return {
                ...state,
                user: kk
            }
        case ACTION_GET_MYSPACE_SUCCESSF:
            return {
                ...state,
                isFollow: action?.payload?.isFollow,
                noOfPost: action?.payload?.noPosts || 0,
                noOfPhotos: action?.payload?.noPhotos || 0,
                noOfVideos: action?.payload?.noVidoes || 0,
                totalFriends: action?.payload?.noFriends || 0,
                isFriend: action?.payload?.isFriend,
                noOfFollowers: action?.payload?.noFollowers,
                noOfFollowings: action?.payload?.noFollowing || 0,
            }
        case ACTION_GET_MYSPACE_SUCCESS:
            return {
                ...state,
                user: action?.payload?.user,
                isFollow: action?.payload?.isFollow,
                noOfPost: action?.payload?.noPosts || 0,
                noOfPhotos: action?.payload?.noPhotos || 0,
                noOfVideos: action?.payload?.noVidoes || 0,
                totalFriends: action?.payload?.noFriends || 0,
                isFriend: action?.payload?.isFriend,
                noOfFollowers: action?.payload?.noFollowers,
                noOfFollowings: action?.payload?.noFollowing || 0,
            }
        case ACTION_GET_MYSPACE_STATE:
            if (/followers/.test(action?.state))
                returnObj[action?.state] = action?.page > 1 ? [...state[action?.state], ...action?.payload?.followers] : action?.payload?.followers
            else if (/followings/.test(action?.state))
                returnObj[action?.state] = action?.page > 1 ? [...state[action?.state], ...action?.payload?.following] : action?.payload?.following
            else
                returnObj[action?.state] = action?.page > 1 ? [...state[action?.state], ...action?.payload] : action?.payload
            return returnObj
        default:
            return state
    }
}

