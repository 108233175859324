import React from 'react'
import { Footer, IndexNavbar } from '../../components'
import Carousel from 'react-bootstrap/Carousel';

const NewHome = (props) => {
    return (
        <>
            <IndexNavbar />
            <section className="home_bg" style={{
                backgroundImage: `url("/assets/web_img/home_bg.jpg")`
            }}>
                <div className="container background_opacity p-0">
                    <section className="banner position-relative">
                        <div className="login_home position-absolute">
                            <div className="container">
                                <div className="d-flex block_responsive">
                                    <div className="p-2 w-100"></div>
                                    <div className="p-2 flex-shrink-1 home_login_new text-center">
                                        <div >
                                            <div className="card-body p-3 text-center">

                                                <h4 className="mb-3">Login</h4>

                                                <div className="form-outline mb-4 position-relative">
                                                    <input type="email" id="typeEmailX-2" placeholder="Email Address"
                                                        className="form-control form-control-sm bg-transparent border border-0 border-bottom rounded-0 border border-dark color-dark" />
                                                    <div className="position-absolute new_login_icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                                                            <path
                                                                d="M168-192q-29.7 0-50.85-21.162Q96-234.324 96-264.04v-432.243Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.162Q864-725.676 864-695.96v432.243Q864-234 842.85-213T792-192H168Zm312-240L168-611v347h624v-347L480-432Zm0-85 312-179H168l312 179Zm-312-94v-85 432-347Z" />
                                                        </svg>
                                                    </div>
                                                </div>

                                                <div className="form-outline mb-3 position-relative">
                                                    <input type="email" id="typeEmailX-2" placeholder="Password"
                                                        className="form-control form-control-sm bg-transparent border border-0 border-bottom rounded-0 border border-dark color-dark" />
                                                    <div className="position-absolute new_login_icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 -960 960 960" width="20">
                                                            <path
                                                                d="M263.717-96Q234-96 213-117.15T192-168v-384q0-29.7 21.15-50.85Q234.3-624 264-624h24v-96q0-79.68 56.226-135.84t136-56.16Q560-912 616-855.84T672-720v96h24q29.7 0 50.85 21.15Q768-581.7 768-552v384q0 29.7-21.162 50.85Q725.676-96 695.96-96H263.717Zm.283-72h432v-384H264v384Zm216.212-120Q510-288 531-309.212q21-21.213 21-51Q552-390 530.788-411q-21.213-21-51-21Q450-432 429-410.788q-21 21.213-21 51Q408-330 429.212-309q21.213 21 51 21ZM360-624h240v-96q0-50-35-85t-85-35q-50 0-85 35t-35 85v96Zm-96 456v-384 384Z" />
                                                        </svg>
                                                    </div>
                                                </div>

                                                <div className="text-right">
                                                    <a href="#" className="text-dark w-100">Forgot password ? </a>
                                                </div>
                                                <button type="button"
                                                    className="btn btn-light rounded-pill mt-4 p-2 ps-5 pe-5 btn_new text-uppercase bg-success">Login</button>

                                                <hr className="my-4" />

                                                <button className="btn btn-lg btn-block bg-danger text-white mb-2 border border-0"
                                                    type="submit">
                                                    <svg style={{ fill: "white" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"
                                                        className="float-start mt-1 me-2">
                                                        <path
                                                            d="M12.545,10.239v3.821h5.445c-0.712,2.315-2.647,3.972-5.445,3.972c-3.332,0-6.033-2.701-6.033-6.032 s2.701-6.032,6.033-6.032c1.498,0,2.866,0.549,3.921,1.453l2.814-2.814C17.503,2.988,15.139,2,12.545,2 C7.021,2,2.543,6.477,2.543,12s4.478,10,10.002,10c8.396,0,10.249-7.85,9.426-11.748L12.545,10.239z" />
                                                    </svg>
                                                    Sign in with google
                                                </button>
                                                <button className="btn btn-lg btn-block btn-primary mb-2 border border-0"
                                                    type="submit">
                                                    <svg style={{ fill: "white" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px"
                                                        className="float-start mt-1 me-2">
                                                        <path
                                                            d="M11.666,2.005C6.62,2.17,2.374,6.251,2.025,11.288c-0.369,5.329,3.442,9.832,8.481,10.589V14.65H8.892 c-0.726,0-1.314-0.588-1.314-1.314v0c0-0.726,0.588-1.314,1.314-1.314h1.613v-1.749c0-2.896,1.411-4.167,3.818-4.167 c0.357,0,0.662,0.008,0.921,0.021c0.636,0.031,1.129,0.561,1.129,1.198v0c0,0.663-0.537,1.2-1.2,1.2h-0.442 c-1.022,0-1.379,0.969-1.379,2.061v1.437h1.87c0.591,0,1.043,0.527,0.953,1.111l-0.108,0.701c-0.073,0.47-0.477,0.817-0.953,0.817 h-1.762v7.247C18.235,21.236,22,17.062,22,12C22,6.366,17.341,1.821,11.666,2.005z" />
                                                    </svg> Sign in with facebook</button>

                                                <div className="text-center mt-4">
                                                    <h6>Don't Have an Account?
                                                        The Registration is free</h6>
                                                    <p><a href="#" className="text-dark w-100">Register Now</a></p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Carousel fade>
                                <Carousel.Item className="banner_img " style={{
                                    backgroundImage: `url("/assets/web_img/banner_img_new.jpg")`
                                }} >
                                </Carousel.Item>
                                <Carousel.Item className="banner_img " style={{
                                    backgroundImage: `url("/assets/web_img/banner_img_new2.jpg")`
                                }} >
                                </Carousel.Item>
                                <Carousel.Item className="banner_img " style={{
                                    backgroundImage: `url("/assets/web_img/banner_img_new3.jpg")`
                                }} >
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    </section>

                    <section className="your_work pt-5 pb-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7 col-sm-12">
                                    <div className="bg-white p-4 your_work_position">
                                        <div className="bg-success text-white text-center p-4 your_work_position_height">
                                            <Carousel fade>
                                                <Carousel.Item className="banner_img">
                                                    <h2 className="fs-1 mt-3">Platform</h2>
                                                    <p className="fs-5 ps-5 pe-5">We are a Creator media platform but NOT another Tik Tok !! Our
                                                        community is focused on creative arts from South Asian and regional
                                                        cultures from all over the world.</p>
                                                    <button type="button"
                                                        className="btn btn-light rounded-pill mt-4 p-2 ps-5 pe-5 btn_new text-uppercase">Create a
                                                        Post</button>
                                                </Carousel.Item>
                                                <Carousel.Item className="banner_img">
                                                    <h2 className="fs-1 mt-3">Your Work</h2>
                                                    <p className="fs-5 ps-5 pe-5">Showcase your work without renting an Art
                                                        Gallery or appearing on stage !! Build a portfolio of your creative work for
                                                        talent hunters to see and recruit you</p>
                                                    <button type="button"
                                                        className="btn btn-light rounded-pill mt-4 p-2 ps-5 pe-5 btn_new text-uppercase">Create a
                                                        Post</button>
                                                </Carousel.Item>
                                                <Carousel.Item className="banner_img">
                                                    <h2 className="fs-1 mt-3">New Creators</h2>
                                                    <p className="fs-5 ps-5 pe-5">We bet on New creators, because that's where all Celebrities started !!
                                                        Winners of our monthly contests
                                                        get promoted on all our creator media platforms</p>
                                                    <button type="button"
                                                        className="btn btn-light rounded-pill mt-4 p-2 ps-5 pe-5 btn_new text-uppercase">Create a
                                                        Post</button>
                                                </Carousel.Item>
                                            </Carousel>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-sm-12 text-center Winners_section">
                                    <h2 className="fs-1 fw-bold">April 2023 Winners</h2>
                                    <ul className="some_member_post_img new_winner m-0 p-0">
                                        <li className="position-relative bg-white p-4">
                                            <a href="#" style={{
                                                backgroundImage: `url("/assets/web_img/profile_img1.png")`
                                            }}></a>
                                        </li>
                                        <li className="position-relative bg-white p-4">
                                            <a href="#" style={{
                                                backgroundImage: `url("/assets/web_img/profile_img.png")`
                                            }}></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="row">
                        <section className="n_member_posts mt-4 mb-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5 col-sm-12 text-center">
                                        <h2 className="fs-1 mt-4">Some of our member's posts</h2>
                                        <p className="fs-5">Our members have been actively posting great content. Here is a sample. Enjoy and join !!'</p>
                                        <div className="counter_box mt-5">
                                            <h2 className="fs-1 fw-bold">150%+</h2>
                                            <p className="fs-4 fw-bold">Growth in first 2 months</p>
                                        </div>
                                        <div className="counter_box">
                                            <h2 className="fs-1 fw-bold">9000+</h2>
                                            <p className="fs-4 fw-bold">Views in 2 months</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-1 col-sm-12"></div>
                                    <div className="col-lg-6 col-sm-12">
                                        <ul className="some_member_post_img home_post_new m-0 p-0">
                                            <li className="position-relative">
                                                <div className="post_icon position-absolute bg-success rounded-circle p-1 text-center">
                                                    <img className="img-responsive" src="/assets/web_img/post_icon_new.png" />
                                                </div>
                                                <a href="#" style={{
                                                    backgroundImage: `url("/assets/web_img/post_img_new.jpg")`
                                                }}>
                                                    <div className='position-absolute bg-white p-1 ps-1 pe-4 mt-1 ms-1 rounded-pill'>
                                                        <div class="posted-user-d"><div class="pro_img">
                                                            <img src="https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-10-20T07%3A10%3A12.116Z-58fcf4-adad-3fbf-a8f2-81ab8533e8-1666249811319.jpg" alt="somrita" />
                                                        </div>
                                                            <div class="post-user-name">somrita
                                                                <div class="fs_12">6 days</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="position-relative">
                                                <div className="post_icon position-absolute bg-success rounded-circle p-1 text-center">
                                                    <img className="img-responsive" src="/assets/web_img/post_icon_new.png" />
                                                </div>
                                                <a href="#" style={{
                                                    backgroundImage: `url("/assets/web_img/post_img_new.jpg")`
                                                }}>
                                                    <div className='position-absolute bg-white p-1 ps-1 pe-4 mt-1 ms-1 rounded-pill'>
                                                        <div class="posted-user-d"><div class="pro_img">
                                                            <img src="https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-10-20T07%3A10%3A12.116Z-58fcf4-adad-3fbf-a8f2-81ab8533e8-1666249811319.jpg" alt="somrita" />
                                                        </div>
                                                            <div class="post-user-name">somrita
                                                                <div class="fs_12">6 days</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="position-relative">
                                                <div className="post_icon position-absolute bg-success rounded-circle p-1 text-center">
                                                    <img className="img-responsive" src="/assets/web_img/post_icon_new.png" />
                                                </div>
                                                <a href="#" style={{
                                                    backgroundImage: `url("/assets/web_img/post_img_new.jpg")`
                                                }}>
                                                    <div className='position-absolute bg-white p-1 ps-1 pe-4 mt-1 ms-1 rounded-pill'>
                                                        <div class="posted-user-d"><div class="pro_img">
                                                            <img src="https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-10-20T07%3A10%3A12.116Z-58fcf4-adad-3fbf-a8f2-81ab8533e8-1666249811319.jpg" alt="somrita" />
                                                        </div>
                                                            <div class="post-user-name">somrita
                                                                <div class="fs_12">6 days</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                            <li className="position-relative">

                                                <div className="post_icon position-absolute bg-success rounded-circle p-1 text-center">
                                                    <img className="img-responsive" src="/assets/web_img/post_icon_new.png" />
                                                </div>
                                                <a href="#" style={{
                                                    backgroundImage: `url("/assets/web_img/post_img_new.jpg")`
                                                }}>
                                                    <div className='position-absolute bg-white p-1 ps-1 pe-4 mt-1 ms-1 rounded-pill'>
                                                        <div class="posted-user-d"><div class="pro_img">
                                                            <img src="https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2022-10-20T07%3A10%3A12.116Z-58fcf4-adad-3fbf-a8f2-81ab8533e8-1666249811319.jpg" alt="somrita" />
                                                        </div>
                                                            <div class="post-user-name">somrita
                                                                <div class="fs_12">6 days</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="whatisallphanes mt-3 mb-3">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5 col-sm-12">
                                        <div className="bg-white p-4 pt-2 pb-2">
                                            <video height="300px" width="100%" >
                                                <source src="/assets/web_img/videos/Edit2.mp4" type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                    <div className="col-sm-1"></div>
                                    <div className="col-lg-6 col-sm-12 text-center mt-5">
                                        <h2 className="fs-1">What is Allphanes</h2>
                                        <p className="fs-5 mt-4">It is an online network of creators from different domains. Creators can upload
                                            their
                                            own
                                            creations
                                            (video, audio, write-ups, music, cooking, gardening). They can share their contents with their friends
                                            or public in this Creator Media for Creators.</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="n_make_money mt-4 mb-4">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-12 text-center  mt-5">
                                        <h2 className="fs-1">Make Money</h2>
                                        <h4 className="fs-2">Multiple ways to make money:</h4>
                                        <ul className="n_make_money_list fs-4 m-0 p-0 mt-5">
                                            <li className="bg-success text-white">
                                                <p>Winning contests</p>
                                            </li>
                                            <li>
                                                <p>Views of your content in the premium bundled service</p>
                                            </li>
                                            <li className="bg-success text-white">
                                                <p>Subscriptions to your content</p>
                                            </li>
                                            <li>
                                                <p>Selling your services in the Creator Marketplace</p>
                                            </li>
                                            <li className="bg-success text-white">
                                                <p>Get Paid for Your Content now!</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-1"></div>
                                    <div className="col-lg-5 col-sm-12">
                                        <img className="img-responsive" src="/assets/web_img/make_money_new.png" />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div className="row d-flex justify-content-center new_tesrmonial mt-5">
                                <div className="col-md-10 col-xl-8 text-center">
                                    <h3 className="mb-4 fs-1">Our Members Say</h3>
                                    <p className="fs-5 mt-4">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit, error amet
                                        numquam iure provident voluptate esse quasi, veritatis totam voluptas nostrum
                                        quisquam eum porro a pariatur veniam.
                                    </p>
                                </div>

                                <div className="row text-center">
                                    <Carousel>
                                        <Carousel.Item>
                                            <div className='row'>
                                                <div className="col-md-4 mb-5 mb-md-0">
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                                                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                                                    </div>
                                                    <h5 className="mb-3">Maria Smantha 1</h5>
                                                    <h6 className="text-success mb-3">Web Developer</h6>
                                                    <p className="px-xl-3 fs-5">
                                                        Lorem ipsum dolor sit amet, consectetur
                                                        adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic
                                                        tenetur.
                                                    </p>
                                                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star_half
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 mb-5 mb-md-0">
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp"
                                                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                                                    </div>
                                                    <h5 className="mb-3">Lisa Cudrow</h5>
                                                    <h6 className="text-success mb-3">Graphic Designer</h6>
                                                    <p className="px-xl-3 fs-5">
                                                        Ut enim ad minima veniam, quis nostrum
                                                        exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid commodi.
                                                    </p>
                                                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star_half
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 mb-0">
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(9).webp"
                                                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                                                    </div>
                                                    <h5 className="mb-3">John Smith</h5>
                                                    <h6 className="text-success mb-3">Marketing Specialist</h6>
                                                    <p className="px-xl-3 fs-5">
                                                        At vero eos et accusamus et iusto odio
                                                        dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti.
                                                    </p>
                                                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star_half
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                <div className="col-md-4 mb-5 mb-md-0">
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                                                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                                                    </div>
                                                    <h5 className="mb-3">Maria Smantha 2</h5>
                                                    <h6 className="text-success mb-3">Web Developer</h6>
                                                    <p className="px-xl-3 fs-5">
                                                        Lorem ipsum dolor sit amet, consectetur
                                                        adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic
                                                        tenetur.
                                                    </p>
                                                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star_half
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 mb-5 mb-md-0">
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp"
                                                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                                                    </div>
                                                    <h5 className="mb-3">Lisa Cudrow</h5>
                                                    <h6 className="text-success mb-3">Graphic Designer</h6>
                                                    <p className="px-xl-3 fs-5">
                                                        Ut enim ad minima veniam, quis nostrum
                                                        exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid commodi.
                                                    </p>
                                                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star_half
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 mb-0">
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(9).webp"
                                                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                                                    </div>
                                                    <h5 className="mb-3">John Smith</h5>
                                                    <h6 className="text-success mb-3">Marketing Specialist</h6>
                                                    <p className="px-xl-3 fs-5">
                                                        At vero eos et accusamus et iusto odio
                                                        dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti.
                                                    </p>
                                                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star_half
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className='row'>
                                                <div className="col-md-4 mb-5 mb-md-0">
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp"
                                                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                                                    </div>
                                                    <h5 className="mb-3">Maria Smantha 3</h5>
                                                    <h6 className="text-success mb-3">Web Developer</h6>
                                                    <p className="px-xl-3 fs-5">
                                                        Lorem ipsum dolor sit amet, consectetur
                                                        adipisicing elit. Quod eos id officiis hic tenetur quae quaerat ad velit ab hic
                                                        tenetur.
                                                    </p>
                                                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star_half
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 mb-5 mb-md-0">
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp"
                                                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                                                    </div>
                                                    <h5 className="mb-3">Lisa Cudrow</h5>
                                                    <h6 className="text-success mb-3">Graphic Designer</h6>
                                                    <p className="px-xl-3 fs-5">
                                                        Ut enim ad minima veniam, quis nostrum
                                                        exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid commodi.
                                                    </p>
                                                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star_half
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-4 mb-0">
                                                    <div className="d-flex justify-content-center mb-4">
                                                        <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(9).webp"
                                                            className="rounded-circle shadow-1-strong" width="150" height="150" />
                                                    </div>
                                                    <h5 className="mb-3">John Smith</h5>
                                                    <h6 className="text-success mb-3">Marketing Specialist</h6>
                                                    <p className="px-xl-3 fs-5">
                                                        At vero eos et accusamus et iusto odio
                                                        dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti.
                                                    </p>
                                                    <ul className="list-unstyled d-flex justify-content-center mb-0">
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star
                                                            </span>
                                                        </li>
                                                        <li>
                                                            <span className="material-symbols-outlined text-success">
                                                                star_half
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    </Carousel>
                                </div>


                            </div>


                        </section>
                        <section className="new_footer">
                            <diV className="container mt-5 bg-white pb-5">
                                <div className="row">
                                    <div className="col-lg-4 col-sm-12 p-4">
                                        <div className="footer_logo">
                                            <a href="#"><img className="img-responsive" src="/assets/web_img/footer_logo.png" /></a>
                                        </div>
                                        <div className="ftr-text-about pt-3">
                                            <p>It is an online network of creators from different domains. Creators can upload their own creations
                                                (video, audio,
                                                write-ups, music, cooking, gardening). They can share their contents with their friends or public in
                                                this
                                                Creator Media
                                                for Creators.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-sm-12 p-4">
                                        <div className="row">
                                            <div className="col-lg-4 col-sm-12 mt-4">
                                                <h2>Quick Link</h2>
                                                <ul className="footer_link p-0 m-0">
                                                    <li><a className="text-decoration-none text-dark" href="javascript:void(0)">Help Center & FAQ</a></li>
                                                    <li><a className="text-decoration-none text-dark" href="javascript:void(0)">About Us</a></li>
                                                    <li><a className="text-decoration-none text-dark" href="javascript:void(0)">Blogs</a></li>
                                                    <li><a className="text-decoration-none text-dark" href="javascript:void(0)">News</a></li>
                                                    <li><a className="text-decoration-none text-dark" href="javascript:void(0)">Contact us</a></li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 mt-4">
                                                <h2>Rules & Result</h2>
                                                <ul className="footer_link p-0 m-0">
                                                    <li><a className="text-decoration-none text-dark" href="javascript:void(0)">Terms & Condition</a></li>
                                                    <li><a className="text-decoration-none text-dark" href="javascript:void(0)">Privacy</a></li>
                                                    <li><a className="text-decoration-none text-dark" href="javascript:void(0)">Monthly Talent Search
                                                        Rules</a>
                                                    </li>
                                                    <li><a className="text-decoration-none text-dark" href="javascript:void(0)">Competition Result</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 mt-4">
                                                <h2>Networks</h2>
                                                <h5>Social Network</h5>
                                                <div className="footer_social_link p-0 m-0">
                                                    <a href="https://www.facebook.com/Allphanesinc" target="_blank"><img className="img-responsive"
                                                        src="/assets/web_img/facebook.png" /></a>
                                                    <a href="https://twitter.com/AllphanesInc" target="_blank"><img className="img-responsive"
                                                        src="/assets/web_img/twitter.png" /></a>
                                                    <a href="https://www.instagram.com/allphanesinc/" target="_blank"><img className="img-responsive"
                                                        src="/assets/web_img/instagram.png" /></a>
                                                    <a href="https://www.linkedin.com/in/allphanes-inc-a43940251/" target="_blank"><img
                                                        className="img-responsive" src="/assets/web_img/linkedin.png" /></a>
                                                    <a href="https://www.youtube.com/@allphanesinc" target="_blank"><img className="img-responsive"
                                                        src="/assets/web_img/youtube.png" /></a>
                                                </div>
                                                <h5 className="mt-3">App from play store</h5>
                                                <a href="https://play.google.com/store/apps/details?id=allphanes.com.allphanes" target="_blank"><img
                                                    className="img-responsive" src="/assets/web_img/app_icon.png" /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </diV>
                            <div className="text-center bg-dark text-white">
                                <p className="mb-0 pt-2 pb-2">2023 allphanes.com</p>
                            </div>
                        </section>
                    </div>






                </div>
            </section>
        </>
    )
}

export default NewHome