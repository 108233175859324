import moment from 'moment'
import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getOrgMyAnl } from '../../redux/reducers/organizationReducer'

function OrgMyAnalytice(props) {
    const dispatch = useDispatch()
    const { storageSubscriptions, myAnalytics } = props

    const getMyanl = async () => {
        await dispatch(getOrgMyAnl({
            type: "ppv|org",
            // sort: "price"
        }))
    }
    useEffect(() => {
        getMyanl()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="col-sm-12 mt-4">
            <h4>Active Subscription Details</h4>
            <div className="table-responsive">
                <table className="table">
                    <thead className="border bg-info border text-center">
                        <tr>
                            <th>Subscription Id</th>
                            <th>Subscription Type</th>
                            <th>Post Id</th>
                            <th>Org Id</th>
                            <th>Status</th>
                            <th>Duration (Day)</th>
                            <th>Price</th>
                            <th>Start Date</th>
                            <th>EndDate</th>
                        </tr>
                    </thead>
                    <tbody className="text-center  border">
                        {myAnalytics?.length > 0 && myAnalytics?.map((item, index) => {
                            return (
                                <>
                                    <tr>
                                        <td>{item?._id}</td>
                                        <td>{item?.type}</td>
                                        <td>
                                            <Link
                                                to={`/post/${item?.postId}/`}
                                            >
                                                {item?.postId}
                                            </Link>

                                        </td>
                                        <td>
                                            <Link
                                                to={`/org/${item?.pageId?.pageName}/#post/#active/`}
                                            >
                                                {item?.pageId?.pageName}
                                            </Link>
                                        </td>
                                        <td>{item?.status}</td>
                                        <td>{Number(item?.duration)}</td>
                                        <td>{Number(item?.price)}</td>
                                        <td>{moment(item?.startDate).format("DD/MM/YYYY")}</td>
                                        <td>{moment(item?.endDate).format("DD/MM/YYYY")}</td>
                                    </tr>
                                </>
                            )
                        }
                        )}
                        {storageSubscriptions?.length === 0 &&
                            <tr>
                                <td colSpan="5" className="text-center">
                                    No Details found
                                </td>
                            </tr>

                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log("stateoRgpost", state);
    const { myAnalytics } = state.organization;
    return {
        myAnalytics
    };
};
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(OrgMyAnalytice)