import React, { useEffect, useRef, useState } from "react"
import {
    Footer,
    IndexNavbar,
} from "../../components"
import ProfileSettingsNav from "../../components/Settings/ProfileSettingsNav"
import { connect, useDispatch } from "react-redux"
import { getStorageDetails } from "../../redux/reducers/authReducer"
// import Plot from "react-plotly.js";
import moment from "moment"
import { DeleteAction } from "../../components/Postsk"
import { deletePostManagement } from "../../redux/reducers/postReducer"
import { Link } from "react-router-dom"


var layout = {
    height: 400,
    width: 500
};

function UsersStorageSettings(props) {
    const { getData, storage, storegePosts, storageSubscriptions } = props
    const editorRef = useRef()
    const [editorLoaded, setEditorLoaded] = useState(false)
    const { Plot } = editorRef.current || {}
    const dispatch = useDispatch()
    const token =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""

    var data = [{
        values: [((parseInt(storage?.totalSpace) - parseInt(storage?.allocateSpace)) / (1024 * 1024)), (parseInt(storage?.allocateSpace) / (1024 * 1024))],
        labels: [
            `Available ${((parseInt(storage?.totalSpace) - parseInt(storage?.allocateSpace)) / (1024 * 1024))} MB`,
            `Used ${(parseInt(storage?.allocateSpace) / (1024 * 1024))} MB`
        ],
        type: 'pie',
        // textinfo: "label+percent",
        insidetextorientation: "radial"
    }];

    const deletePost = async params => {
        await dispatch(deletePostManagement(params))
        getData()
    }

    useEffect(() => {
        editorRef.current = {
            Plot: require('react-plotly.js').default
        }
        setEditorLoaded(true)
    }, [])
    useEffect(() => {

    })
    // useEffect(()=>{
    //     setPieData(prev=>([...prev, ]))
    // }, [storage])
    return (
        <>
            <IndexNavbar />
            <div className="container my-4">
                <ProfileSettingsNav />

                <div
                    id="basic"
                    className="row bg_allp3 justify-content-center settings_row border_1 mt-4 border_r5"
                >
                    <section className=''>
                        <div className='container  mt-5 mb-5'>
                            <div className='row'>
                                <div className='col-sm-12 text-center'>
                                    <h1 className='text-success'>
                                        <b>
                                            Total Subscribed Storage : {(parseInt(storage?.totalSpace) / (1024 * 1024))} MB
                                        </b>
                                    </h1>
                                    <p className="mb-4">
                                        200MB of storage is offered to all members for free. Your current storage subscription is shown above.
                                        <br />
                                        In the chart below you see how much of your subscribed storage is used. When you reach the maximum
                                        <br />
                                        storage you have subscribed to, you will not be able to post any more additional content.
                                        <br />
                                        You can optimize storage by deleting some posts. Alternatively you can subscribe to higher level of storage.
                                    </p>
                                </div>


                                <div className="row justify-content-center">
                                    <div className="col-lg-8 d-flex justify-content-center">
                                        {editorLoaded && <Plot
                                            data={data}
                                            layout={layout}
                                        // frames={this.state.frames}
                                        // config={this.state.config}
                                        // onInitialized={(figure) => this.setState(figure)}
                                        // onUpdate={(figure) => this.setState(figure)}
                                        />
                                        }
                                    </div>
                                </div>

                                <div className="col-sm-12 mt-4">
                                    <h4>Active Subscription Plans</h4>
                                    <div className="table-responsive">
                                        <table class="table">
                                            <thead class="border bg-secondary border text-center text-white">
                                                <tr>
                                                    <th>Plan Name</th>
                                                    <th>Plan Id</th>
                                                    <th>Plan Code</th>
                                                    <th>Duration (Day)</th>
                                                    <th>Storage</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    {/* <th>Status</th> */}
                                                </tr>
                                            </thead>
                                            <tbody className="text-center  border">
                                                {storageSubscriptions?.length > 0 && storageSubscriptions?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{item?.planName}</td>

                                                                <td>{item?._id}</td>
                                                                <td>{item?.planCode}</td>
                                                                <td>{item?.duration}</td>
                                                                <td>{parseInt(item?.storage) / (1024 * 1024)} MB</td>
                                                                <td>{moment(item?.startDate).format("DD/MM/YYYY")}</td>
                                                                <td>{moment(item?.endDate).format("DD/MM/YYYY")}</td>
                                                            </tr>

                                                        </>
                                                    )
                                                }
                                                )}
                                                {storageSubscriptions?.length === 0 &&
                                                    <tr>
                                                        <td colSpan="5" className="text-center">
                                                            No Plan found

                                                        </td>
                                                    </tr>

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='text-center mt-4 mb-4 mb-4'>
                                        <Link
                                            to={`/subcriptions/storage/users/${token}`}
                                            class="btn btn-success p-5 pt-2 pb-2"
                                        >
                                            <h4>Add Space</h4>
                                        </Link>
                                    </div>
                                    <h4>Storage Details</h4>

                                    <div className="table-responsive">
                                        <table class="table">
                                            <thead class="border bg-secondary border text-center text-white">
                                                <tr>
                                                    <th>Postid</th>
                                                    <th>Title</th>
                                                    <th>Space</th>
                                                    <th>Created time</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-center  border">
                                                {storegePosts?.length > 0 && storegePosts?.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr>
                                                                <td>{item?._id}</td>
                                                                <td>{item?.postTitle}</td>
                                                                <td>{(parseInt(item?.postSize) / (1024 * 1024))} MB</td>
                                                                <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                                                <td>
                                                                    <DeleteAction
                                                                        buttonText={<><div className="delete c_pointer">
                                                                            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M280 936q-33 0-56.5-23.5T200 856V336h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680 936H280Zm400-600H280v520h400V336ZM360 776h80V416h-80v360Zm160 0h80V416h-80v360ZM280 336v520-520Z" /></svg>
                                                                        </div> </> || "Delete Post"}
                                                                        execute={deletePost}
                                                                        params={{ pid: item?._id }}
                                                                        alertTitle={"Delete Post"}
                                                                        alertMsg={"Are you sure to delete this post."}
                                                                        setConfirm={"setConfirm"}
                                                                    />
                                                                </td>
                                                            </tr>

                                                        </>
                                                    )
                                                }
                                                )}
                                                {storegePosts?.length === 0 &&
                                                    <tr>
                                                        <td colSpan="5" className="text-center">
                                                            No Post found

                                                        </td>
                                                    </tr>

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    )
}

UsersStorageSettings.loadData = (params) => async (dispatch) => {
    return await dispatch(getStorageDetails())
}
const mapStateToProps = (state) => {
    const { storage, storegePosts, storageSubscriptions } = state.auth
    return {
        storage,
        storegePosts,
        storageSubscriptions
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        getData: async () => {
            await dispatch(getStorageDetails())
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UsersStorageSettings)