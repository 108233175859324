import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Notifications from "../Notification/Notifications";
import { MainLogo } from "../AppComponents/Helpers";
import SimpleBadge from "../../../_helpers/Material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { connect, useDispatch } from "react-redux";
import { RESET_STORE } from "../../redux/reducers/authReducer";
import { FirebaseSignOut } from "../Auth/FireBase";
import { getNotifications } from "../../redux/reducers/notification.reducer";
import {
  getRequestHandler,
  putRequestHandler,
} from "../../redux/reducers/requestHandler.reducer";

function IndexNavbar(props) {
  const { user } = props;
  const [isActive, setActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [searchResult, setSearchResult] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notiLen, setNotiLen] = useState(0);
  const tokens =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const currentUser =
    typeof window !== "undefined"
      ? JSON.parse(window.localStorage.getItem("currentuser"))
      : "";
  const navigate = useNavigate();
  const path = useLocation();

  const dispatch = useDispatch();

  const getAllNotifications = async () => {
    const response = await dispatch(
      getNotifications({
        page: 1,
        perPage: 10,
      })
    );
    const R = response?.data?.responseData;
    setNotifications(R);
    const k = R?.filter((item) => item.markRead === false);
    setNotiLen(k?.length);
  };

  const getSearchResult = async () => {
    let req = {
      params: {
        id: tokens,
        q: searchValue,
      },
      url: "getmembers",
      page: 1,
      perPage: 20,
    };
    const response = await dispatch(getRequestHandler(req));

    setSearchResult(response.data.responseData);
    if (response) setSearchActive(true);
    else setSearchActive(false);
  };

  const handleChange = (e) => setSearchValue(e.target.value);

  const closeNotification = async () => {
    setShowNotification(false);
    let req = {
      url: "markReadNotification",
      data: {
        uid: tokens,
      },
    };
    const response = await dispatch(putRequestHandler(req));
    if (response.data.ack === 1) setNotiLen(0);
  };

  const handleToggleNotification = (e) => {
    if (showNotification === false) return setShowNotification(true);
    closeNotification();
  };
  // useEffect(()=>{
  // 	setTokens(localStorage.getItem('token'))
  // 	setCurrentUser(JSON.parse(window.localStorage.getItem("currentuser")))
  // }, [])

  useEffect(() => {
    console.log("fdsfffffffffffff", path);

    getAllNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path.pathname]);

  useEffect(() => {
    searchValue.length > 2 ? getSearchResult() : setSearchActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue.length]);

  const toggleClass = () => {
    setActive(!isActive);
  };

  // const tokens = localStorage.getItem("token")

  const logout = (e) => {
    if (
      currentUser?.user?.loginType === "google" ||
      currentUser?.user?.loginType === "facebook"
    )
      FirebaseSignOut();
    dispatch({ type: RESET_STORE });
    sessionStorage.clear();
    localStorage.clear();
    navigate("/auth/login/");
  };

  useEffect(() => {
    if (tokens === "undefined") logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokens]);

  return (
    <header id="header" className="index-header">
      <div className="nav-main">
        <div className="logo-top">
          <a href="/" onClick={() => setActive(false)}>
            <MainLogo />
          </a>
        </div>

        <div className="nav-right">
          {/* <div className=""> */}
          <ul className={isActive ? "nav-middle active" : "nav-middle"}>
            {/* {tokens && (

              <li className="nav-item">
                <Link
                  className={
                    path.pathname === "/creations/" ||
                      path.pathname === "/creations"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/creations/"
                  onClick={() => setActive(false)}
                >
                  Creations
                </Link>
              </li>
            )} */}
            {tokens && (
              <li className="nav-item dropdown">
                <Link
                  to="/creations/"
                  className={
                    /org/.test(path.pathname)
                      ? "nav-link dropdown-toggle active"
                      : "nav-link"
                  }
                  onClick={() => setActive(false)}
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Creations
                </Link>
                <ul className="dropdown-menu bg-dark shadow-none border border-0">
                  <li>
                    <Link to={"/creations/"} className="dropdown-item">
                      All Creations
                    </Link>
                  </li>
                  <li>
                    <Link to={"/top-creations/"} className="dropdown-item">
                      Top Creation
                    </Link>
                  </li>
                </ul>
              </li>
            )}
            {/* {tokens && (
              <li className="nav-item">
                <Link
                  className={
                    path.pathname === "/top-creations" ||
                    path.pathname === "/top-creations/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/top-creations/"
                  onClick={() => setActive(false)}
                >
                  Top Creations
                </Link>
              </li>
            )} */}
            {tokens && (
              <li className="nav-item">
                <Link
                  className={
                    path.pathname === "/mycanvas" ||
                      path.pathname === "/mycanvas/"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/mycanvas/"
                  onClick={() => setActive(false)}
                >
                  My Canvas
                </Link>
              </li>
            )}
            {tokens && (
              <>
                <li className="nav-item">
                  <a
                    className={
                      path.pathname === "/" + currentUser?.user?.userName ||
                        path.pathname === `/${currentUser?.user?.userName}/`
                        ? "nav-link active"
                        : "nav-link"
                    }
                    href={
                      "/" + currentUser?.user?.userName?.toLowerCase() + "/"
                    }
                    onClick={() => setActive(false)}
                  >
                    My Space
                  </a>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/org/lists/"
                    className={
                      /org/.test(path.pathname)
                        ? "nav-link dropdown-toggle active"
                        : "nav-link"
                    }
                    onClick={() => setActive(false)}
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Creators
                  </Link>
                  <ul className="dropdown-menu bg-dark shadow-none border border-0">
                    <li>
                      <Link to={"/org/lists/"} className="dropdown-item">
                        Organizations
                      </Link>
                    </li>
                    <li>
                      <Link to={"/premium-list/"} className="dropdown-item">
                        Premium Creators
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="desktop_g-play">
                  <a
                    className="nav-link"
                    href="https://play.google.com/store/apps/details?id=allphanes.com.allphanes"
                  >
                    <img
                      className="img-responsive"
                      src={"/assets/web_img/google_play.png"}
                      alt="https://play.google.com/store/apps/details?id=allphanes.com.allphanes"
                    />
                  </a>
                </li>
                <div className="desktop_none">
                  <li>
                    <Link to={"/org/lists/"} className="dropdown-item">
                      Organizations
                    </Link>
                  </li>
                  <li>
                    <Link to={"/premium-list/"} className="dropdown-item">
                      Premium Creators
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/members/"
                      onClick={() => setActive(false)}
                    >
                      Members
                    </a>
                  </li>

                  <li className="nav-item desktop_none_hide">
                    <a
                      className="nav-link"
                      href="/org/create/"
                      onClick={() => setActive(false)}
                    >
                      Create Organization
                    </a>
                  </li>
                  <li className="nav-item desktop_none_hide">
                    <Link
                      className="nav-link"
                      to="/premium/create/"
                      onClick={() => setActive(false)}
                    >
                      Create Premium Page
                    </Link>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/settings/basic/"
                      onClick={() => setActive(false)}
                    >
                      Profile settings
                    </a>
                  </li>
                  {currentUser?.user?.storageId && (
                    <>
                      <li>
                        <Link
                          to="/settings/storage/"
                          className="nav-link text-white"
                        >
                          Total Storage :{" "}
                          {parseInt(currentUser?.user?.storageId?.totalSpace) /
                            (1024 * 1024)}{" "}
                          MB
                        </Link>
                      </li>
                    </>
                  )}
                  <li className="nav-item c_pointer">
                    <div className="nav-link cl_w" onClick={logout}>
                      Log out
                    </div>
                  </li>
                </div>
              </>
            )}

            {!tokens && (
              <>
                <li className="nav-item">
                  <a className="nav-link" href="/auth/login/">
                    Sign in
                  </a>
                </li>

                <li className="nav-item">
                  <a className="nav-link" href="/auth/registration/">
                    Sign up
                  </a>
                </li>
              </>
            )}

            {tokens && (
              <li
                className="nav-item pos_r mobile_none p-2"
                title="Notification"
              >
                <div
                  className="nav-link cl_1 c_point pos_rel"
                  onClick={handleToggleNotification}
                >
                  {notiLen > 0 ? (
                    <SimpleBadge content={notiLen} />
                  ) : (
                    <NotificationsIcon />
                  )}
                  {/* <FontAwesomeIcon icon={faBell}></FontAwesomeIcon> */}
                </div>
                {showNotification && (
                  <Notifications
                    notifications={notifications}
                    onhide={closeNotification}
                  />
                )}
              </li>
            )}
            {tokens && (
              <div className="mobile_none">
                <li className="nav-item search_nav">
                  <input
                    type="search"
                    name="search"
                    className="member_search"
                    placeholder="Find a creator"
                    onChange={handleChange}
                    value={searchValue || ""}
                  />
                  {searchActive && (
                    <div className="search_res shadow br_5">
                      {searchResult &&
                        searchResult.map((items, index) => {
                          return (
                            <a
                              href={"/" + items?.userName?.toLowerCase() + "/"}
                              key={index}
                              onClick={() => setSearchValue("")}
                              className="d-flex align-items-center search_items gap-2"
                            >
                              <img
                                className="drop_down_img"
                                src={
                                  items?.profilePhoto
                                    ? items?.profilePhoto
                                    : "/assets/web_img/choto-log-img2.jpg"
                                }
                                alt={items?.showName}
                                onError={(e) => (
                                  // eslint-disable-next-line no-sequences
                                  (e.target.onerror = null),
                                  (e.target.src =
                                    "/assets/web_img/choto-log-img2.jpg")
                                )}
                              />
                              <div className="se_name">{items.showName} </div>
                            </a>
                          );
                        })}
                    </div>
                  )}
                </li>

                <li className="nav-item drop_down">
                  {/* {currentUser.showName} */}
                  <img
                    className="drop_down_img"
                    src={
                      currentUser?.user?.profilePhoto
                        ? currentUser?.user?.profilePhoto
                        : "/assets/web_img/choto-log-img2.jpg"
                    }
                    alt={currentUser?.user?.showName}
                    onError={(e) => (
                      // eslint-disable-next-line no-sequences
                      (e.target.onerror = null),
                      (e.target.src = "/assets/web_img/choto-log-img2.jpg")
                    )}
                  />
                  <div className="drop_child hov br_5 shadow">
                    {/* <div>
											<Link to="/search/member">Search by categories</Link>
										</div> */}
                    <div>
                      <a href="/members/">Members</a>
                    </div>
                    <div>
                      <a href="/org/create/">Create Organization</a>
                    </div>
                    <div>
                      <Link to="/premium/create/">Create Premium Page</Link>
                    </div>

                    <div>
                      <a href="/settings/basic/">Profile settings</a>
                    </div>
                    {currentUser?.user?.storageId && (
                      <>
                        <div>
                          <a href="/settings/storage/">
                            Total Storage :{" "}
                            {parseInt(
                              currentUser?.user?.storageId?.totalSpace
                            ) /
                              (1024 * 1024)}{" "}
                            MB
                          </a>
                        </div>
                      </>
                    )}

                    <div>
                      <div className="drop_in_ch" onClick={logout}>
                        Log out
                      </div>
                    </div>
                  </div>
                </li>
              </div>
            )}
          </ul>

          {/* </div> */}
          <div className="nav-right">
            <div className="desktop_none md_show show_responsive">
              <li>
                <a
                  className="nav-link"
                  href={
                    "https://play.google.com/store/apps/details?id=allphanes.com.allphanes"
                  }
                >
                  <img
                    className="img-responsive"
                    src={"/assets/web_img/google_play.png"}
                    alt={"googleplay store"}
                  />
                </a>
              </li>
              {tokens && (
                <li className="nav-item" title="Notification">
                  <div
                    className="nav-link cl_1 c_point pos_rel"
                    onClick={handleToggleNotification}
                  >
                    {notiLen > 0 ? (
                      <SimpleBadge content={notiLen} />
                    ) : (
                      <NotificationsIcon />
                    )}
                  </div>
                  {showNotification && (
                    <Notifications
                      notifications={notifications}
                      onhide={closeNotification}
                    />
                  )}
                </li>
              )}
            </div>
            {/* <div className="menu-buttn">
							<a href={`${process.env.PUBLIC_URL}/register`} className="new-button nav-btn">Start Free Trial</a>
						</div> */}
            <div
              className={isActive ? "hamburger active" : "hamburger"}
              onClick={toggleClass}
            >
              <div className="h_line"></div>
              <div className="h_line"></div>
              <div className="h_line"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

const mapStateToProps = (state) => {
  console.log("stateoRgpostcreationposts", state);
  const { user } = state.auth;
  return { user };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(IndexNavbar);
