import React, { useEffect } from "react";
import RouterNew from "./RouterNew";
import routeConfig from "./routerConfig";
// import { persistor, store } from "./new/redux/store";
// import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";
import configureStore from "./new/redux/store";
import ReactDOMServer from "react-dom/server";
// import Pushnotification from "./new/services/Pushnotification";

export default function App() {
  return <RouterNew routes={routeConfig()} />;
}

export const ClientApp = (props) => {
  // const { store, hostedTranslations = {} } = props;
  const { store } = props;

  return (
    <Provider store={store}>
      {/* <HelmetProvider> */}
      <BrowserRouter>
        <RouterNew routes={routeConfig()} />
      </BrowserRouter>
      {/* </HelmetProvider> */}
    </Provider>
  );
};

export const ServerApp = (props) => {
  const { url, context, store } = props;
  // const { url, context, helmetContext, store, hostedTranslations = {} } = props;
  // setupLocale();
  // HelmetProvider.canUseDOM = false;
  // delete window.__PRELOADED_STATE__;
  useEffect(() => {
    // Pushnotification();
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);
  return (
    <Provider store={store}>
      {/* <HelmetProvider context={helmetContext}> */}
      <StaticRouter location={url} context={context}>
        <RouterNew routes={routeConfig()} />
      </StaticRouter>
      {/* </HelmetProvider> */}
    </Provider>
  );
};

export const RenderApp = (props) => {
  // Don't pass an SDK instance since we're only rendering the
  // component tree with the preloaded store state and components
  // shouldn't do any SDK calls in the (server) rendering lifecycle.
  const { url, serverContext, preloadedState } = props;
  const store = configureStore(preloadedState);

  // const helmetContext = {};

  // When rendering the app on server, we wrap the app with webExtractor.collectChunks
  // This is needed to figure out correct chunks/scripts to be included to server-rendered page.
  // https://loadable-components.com/docs/server-side-rendering/#3-setup-chunkextractor-server-side
  const WithChunks = (
    // collectChunks(
    <ServerApp
      url={url}
      context={serverContext}
      // helmetContext={helmetContext}
      store={store}
      // hostedTranslations={hostedTranslations}
    />
  );
  // )
  const body = ReactDOMServer.renderToString(WithChunks);
  // const { helmet: head } = helmetContext;
  // return { body };
  console.log("body>>", body);
  return body;
};
