import React from 'react'
import { Link } from 'react-router-dom'
import { Footer, IndexNavbar } from '../../components'

function Subcriptions() {
    return (
        <>
            <IndexNavbar />
            <div className='container'>
                <div className='row p-4'>
                    <div className='col-sm-7'>
                        <h3><strong>Upcoming event name</strong></h3>
                        <h5>Event title</h5>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            pellentesque egestas felis nec tempus. Suspendisse nibh nunc, aliquet non felis non, sagittis tincidunt mi. In malesuada purus blandit,
                            efficitur urna porta, vulputate magna.</p>
                        <p>Phasellus finibus pretium venenatis. Nulla blandit magna odio, non ullamcorper tortor consequat non. Suspendisse augue est, luctus et commodo non,
                            tristique sit amet risus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
                    </div>
                    <div className='col-sm-1'></div>
                    <div className='col-sm-4 text-center'>
                        <div className='card rounded p-3'>
                            <iframe width="100%" height="200" src="https://www.youtube.com/embed/uCiFfVY12kU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <h5 className='mb-0 mt-2 position-relative release_date mb-1'>Release  Date:</h5>
                        <h2 className='mt-0 text-success'>26-02-2023</h2>
                    </div>
                    <div className='col-sm-12 text-center'>
                        <h3>Pick Best Plan to See Event</h3>
                        <p>Lorem ipsum is placeholder text commonly used in the graphic, print,
                            and publishing industries for previewing layouts and visual mockups.</p>
                        <ul className='plan_list'>
                            <li className='card shadow-sm m-2'>
                                <div className='plan_top_bg bg-success text-white'>
                                    <h4 className='mb-0 mt-4'>For</h4>
                                    <h5 className='mb-0'>1 Day</h5>
                                    <h1 className='mb-0'>$2</h1>
                                </div>
                                <div className='plan_list_li'>
                                    <ul className='m-0 p-2'>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                    </ul>
                                </div>
                                <Link to={"/org/lists/#premierposts"}>
                                    <button type="button" class="btn btn-success">Buy Now</button>
                                </Link>
                            </li>
                            <li className='card shadow-sm m-2'>
                                <div className='plan_top_bg bg-success text-white'>
                                    <h4 className='mb-0 mt-4'>For</h4>
                                    <h5 className='mb-0'>30 Days</h5>
                                    <h1 className='mb-0'>$60</h1>
                                </div>
                                <div className='plan_list_li'>
                                    <ul className='m-0 p-2'>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                    </ul>
                                </div>
                                <Link to={"/org/lists/#premierposts"}>
                                    <button type="button" class="btn btn-success">Buy Now</button>
                                </Link>
                            </li>
                            <li className='card shadow-sm m-2'>
                                <div className='plan_top_bg bg-success text-white'>
                                    <h4 className='mb-0 mt-4'>For</h4>
                                    <h5 className='mb-0'>1 year</h5>
                                    <h1 className='mb-0'>$100</h1>
                                </div>
                                <div className='plan_list_li'>
                                    <ul className='m-0 p-2'>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/tick.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                        <li className='d-block p-1'>
                                            <img className='float-start mt-1 plan_icon' src="/assets/web_img/close2.png" alt="subscription" />
                                            Lorem ipsum is placeholder
                                        </li>
                                    </ul>
                                </div>
                                <Link to={"/org/lists/#premierposts"}>
                                    <button type="button" class="btn btn-success">Buy Now</button>
                                </Link>

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Subcriptions