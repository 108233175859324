import React, { useEffect } from "react"
import SimpleCircle from "../Loaders/SimpleCircle"
import { connect, useDispatch } from "react-redux"
import { getWinnersPost } from "../../redux/reducers/postReducer"
import moment from "moment"

function WinnersModal(props) {
  const { winners, show, close } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getWinnersPost())
  }, [])

  return (
    <>
      {show ? (
        <div className="win_popup_bg">
          <div
            className="win-popup_in"
            style={{
              backgroundImage: `url(${"/assets/web_img/winner_bg.jpg"})`,
            }}
          >
            <div className="win_popup_heading">
              <div
                className="win_close_btn"
                title="close"
                onClick={() => close()}
              >
                <img src={"/assets/web_img/close_btn.png"} alt="sound" />
              </div>
              <div className="win_popup_heading_in">
                {moment().subtract(1, "month").format("MMMM")} 2023 Winners
                <h4>
                  Only two winners in{" "}
                  {moment().subtract(1, "month").format("MMMM")}.{" "}
                  <small>No winner for comments-try next month!!’</small>
                </h4>
              </div>
            </div>
            {winners?.length > 0 ? (
              <>
                <ul className="win_list_blog">
                  <li>
                    <a href={"/" + winners[1]?.userid?.userName}>
                      <img
                        className="img-responsive"
                        src={"/assets/web_img/top_two.png"}
                        alt=""
                      />
                      <h2>{winners[1]?.userid?.showName}</h2>
                      <div
                        className="wnr_profile_img"
                        style={{
                          backgroundImage: `url(${
                            winners[1]?.userid?.profilePhoto ||
                            "/assets/web_img/choto_logo_1.png"
                          })`,
                        }}
                      ></div>
                    </a>
                  </li>
                  <li>
                    <a href={"/" + winners[0]?.userid?.userName}>
                      <img
                        className="img-responsive"
                        src={"/assets/web_img/top_winner_bg.png"}
                        alt=""
                      />
                      <h2>{winners && winners[0]?.userid?.showName}</h2>
                      <div
                        className="wnr_profile_img"
                        style={{
                          backgroundImage: `url(${
                            winners[0]?.userid?.profilePhoto ||
                            "/assets/web_img/choto_logo_1.png"
                          })`,
                        }}
                      ></div>
                    </a>
                  </li>
                  <li>
                    <a href={"/" + winners[1]?.userid?.userName}>
                      <img
                        className="img-responsive"
                        src={"/assets/web_img/top_two.png"}
                        alt=""
                      />
                      <h2>{winners[1]?.userid?.userName}</h2>
                      <div
                        className="wnr_profile_img"
                        style={{
                          backgroundImage: `url(${
                            winners[1]?.userid?.profilePhoto ||
                            "/assets/web_img/choto_logo_1.png"
                          })`,
                        }}
                      ></div>
                    </a>
                  </li>
                </ul>
              </>
            ) : (
              <>
                <h1 className="mt-5">The Winner's are... </h1>
                <div className="d-flex justify-content-center">
                  <SimpleCircle />
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}
const mapStateToProps = (state) => {
  const { isSuccess, isError, isProcessing, homePromotionPost, winners } =
    state.posts
  return {
    isSuccess,
    isError,
    isProcessing,
    homePromotionPost: homePromotionPost,
    winners: winners
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(WinnersModal)
