import React, { useState, useEffect } from "react"
// eslint-disable-next-line no-unused-vars
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import Modal from "react-bootstrap/Modal"
import ReadMore from "../../components/Readmore"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClose } from "@fortawesome/free-solid-svg-icons"
import moment from "moment"
import ReplyComments from "../../components/Replycomments"
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined"
import QuickreplyOutlinedIcon from "@mui/icons-material/QuickreplyOutlined"
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined"
// eslint-disable-next-line no-unused-vars
import {
  CopyLink,
  FileViewerc,
} from "../../components/AppComponents/Helpers"
import {
  SharePostAsFeed,
} from "../../components/Post/PostComponents"
import { YouTubeEmbed } from "react-social-media-embed"
import { connect, useDispatch } from "react-redux"
import { deleteRequestHandler, getRequestHandler, postRequestHandler, putRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { PostLike, SavePost } from "../../components/Postsk"


function SinglePostDetails(props) {
  const { singlePost, closeBtn } = props
  const dispatch = useDispatch()
  const params = useParams()
  const lastLocation = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const [viewArea, setViewArea] = useState(0)
  const [allComments, setAllComments] = useState([])
  const currentUser =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("currentuser"))
      : ""
  const [comValue, setComValue] = useState("")
  const [likeUsers, setLikeUsers] = useState([])
  const [commentSwitch, setCommentSwitch] = useState(false)
  const [replyShow, setReplyShow] = useState(false)
  const [postid2, setPostid2] = useState("")
  const [isShow, setIsShow] = useState(true)
  const [totalLikesCount, setTotalLikesCount] = useState(singlePost?.totalLikes || 0)
  const [likePages] = useState(1)
  const [commentPages] = useState(1)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (!token)
      navigate(
        "/auth/login?redirectTo=" +
        lastLocation.pathname +
        lastLocation.search +
        `?redirect=1`
      )
  }, [lastLocation.pathname, lastLocation.search, navigate, token])

  const getPosts = async (id) => {
    try {
      let req = {
        params: { id: id },
        url: 'getPost',
        action: 'ACTION_GET_SINGLEPOST'
      }
      const response = await dispatch(getRequestHandler(req))
      if (response.data.ack === 0) return
      setPostid2(response?.data?.responseData[0]._id)
      setTotalLikesCount(response?.data?.responseData[0]?.totalLikes)

    } catch (err) {
      console.error(err)
    }
  }

  const getAllComments = async () => {
    let req = {
      params: {
        pid: singlePost?._id,
        page: commentPages
      },
      url: 'getComments',
      action: 'ACTION_GET_SINGLEPOST_COMMENTS'
    }
    const response = await dispatch(getRequestHandler(req))
    setAllComments(response?.data?.responseData)
  }

  const getAllLikeUser = async () => {
    // singlePostInfo
    let req = {
      params: {
        pid: singlePost?._id,
        page: likePages
      },
      url: 'likeUser',
      action: 'ACTION_GET_SINGLEPOST_LIKES_USER'
    }
    const response = await dispatch(getRequestHandler(req))
    setLikeUsers(response?.data?.responseData)
  }

  // const [isLoaderCom, setIsLoaderCom] = useState(false)
  const handleCommentClick = async () => {
    // setIsLoaderCom(true)
    const dataArr = {
      referenceUserId: token,
      referencePostId: singlePost?._id,
      messageText: comValue,
    }
    let req = {
      data: dataArr,
      url: 'comments'
    }
    const dataPost = await dispatch(postRequestHandler(req))
    if (dataPost) {
      setComValue("")
      getAllComments()
    }
  }

  const getTotalLikes = async () => {
    try {
      let req = {
        params: {
          referencePostId: singlePost?._id
        },
        url: 'totalLikes'
      }
      const response = await dispatch(getRequestHandler(req))
      if (response.data.ack === 0)
        return setTotalLikesCount(response?.data?.responseData)
    } catch (error) {
      console.error(error)
    }
  }

  const handleCommentLikeClick = async (commentId) => {
    let dataObj = {
      referenceUserId: token,
      referencePostId: singlePost._id,
      referenceCommentId: commentId,
    }
    let req = {
      data: dataObj,
      url: 'replyLike'
    }
    const response = await dispatch(postRequestHandler(req))
    if (response) getAllComments()
  }

  // reply comment
  const handleReplyCommentClick = (commentId, index) => {
    const k = "comment" + index
    setCommentSwitch((values) => ({ ...values, [k]: index }))
  }

  const handleReplyCommentView = (index) => {
    const k = "comment" + index
    setCommentSwitch((values) => ({ ...values, [k]: index }))
    setReplyShow(true)
    //console.log(commentSwitch, index)
  }

  const handleCloseModal = () => {
    setIsShow(false)
    props.closeBtn
      ? closeBtn()
      : searchParams.get("redirect") === "1"
        ? navigate("/creations")
        : navigate(-1)
  }

  const deleteComment = async (id) => {
    try {
      const result = window.confirm("Are you sure?")
      if (!result) return
      let req = {
        params: { id: id },
        url: 'comments'
      }
      const response = await dispatch(deleteRequestHandler(req))
      if (response.data.ack === 1) getAllComments()
    } catch (err) {
      console.error(err)
    }
  }

  const incrementPostViews = async (post) => {
    try {
      if(post?.referenceUserId === token) return
      let req = {
        data: {
          pid: post?._id,
        },
        url: "updateView",
      }
      // if (post.referenceUserId !== userId) return
      await dispatch(putRequestHandler(req))
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (params.id && token) getPosts(params.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, token])

  useEffect(() => {
    if (!postid2) return
    viewArea === 0 ? getAllComments() : getAllLikeUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewArea, postid2])

  useEffect(() => {
    if (postid2) {
      getAllComments()
      getAllLikeUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postid2])

  useEffect(()=>{
    if(singlePost?._id) incrementPostViews(singlePost)
  }, [singlePost])

  return (
    <Modal
      show={isShow}
      size="lg"
      onHide={handleCloseModal}
      className="single_post_modal"
      style={{ visibility: show ? "hidden" : "" }}
    >
      <Modal.Body className="pd_0 h-100">
        <div className="container-fluid min_h">
          <div className="row min_h">
            <div className="col-lg-8">
              {/Blogs/.test(singlePost?.postCategory) ? (
                <>
                  <div className="row justify-content-center blog_h">
                    <div className="col-lg-10 col-sm-12">
                      <div id="blog_details">
                        <div className="blog_top">
                          <h1>{singlePost?.postTitle}</h1>
                          <div className="">
                            {moment(singlePost?.createdAt).format("DD/MM/YYYY")}
                          </div>
                          {singlePost?.url && (
                            <YouTubeEmbed
                              url={singlePost?.url}
                              width={"100%"}
                              height={450}
                            />
                          )}
                          {singlePost?.postInfo[0]?.postImagePath &&
                            singlePost?.postInfo &&
                            singlePost?.postInfo[0] && (
                              <FileViewerc
                                link={singlePost?.postInfo[0]?.postImagePath}
                              />
                            )}

                        </div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: singlePost.postDescription,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {(singlePost?.url || singlePost?.postInfo[0]?.postImagePath || singlePost?.shareGalleryInfo) &&
                    <div className="popup_image_section">
                      {singlePost?.url && (
                        <YouTubeEmbed
                          url={singlePost?.url}
                          width={"100%"}
                          height={450}
                        />
                      )}
                      {(singlePost?.postInfo?.length > 0 || singlePost?.shareGalleryInfo) && (
                        <>
                          <FileViewerc
                            link={singlePost?.postInfo[0]?.postImagePath || singlePost?.shareGalleryInfo}
                          />
                        </>
                      )}

                    </div>
                  }


                  {singlePost?.postInfo && !singlePost?.shareGalleryInfo &&
                    !singlePost?.url &&
                    !singlePost?.postInfo[0]?.postImagePath && (
                      <div className="popup_desc popup_image_section overflow_man">
                        <pre className="mt-4 px_15 hola fs_16">
                          {singlePost?.postCategory === "Normal"
                            ? ""
                            : singlePost?.postTitle !== "Quick Post" && (
                              <div className="mb-3 fs_tt">
                                {singlePost?.postTitle}
                              </div>
                            )}
                          {/Blogs/.test(singlePost?.postCategory) ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: singlePost?.shareDescription ||singlePost.postDescription,
                              }}
                            />
                          ) : (
                            <pre className="mt-2">
                              {singlePost?.shareDescription || singlePost?.postDescription}
                            </pre>
                          )}

                          {/* {singlePost?.postDescription} */}

                          <h6 className="text-primary mt-2">
                            {singlePost?.keywords2}
                          </h6>
                        </pre>
                      </div>
                    )}
                </>
              )}
            </div>

            <div className="col-lg-4 bl_1">
              <div className="d-flex justify-content-between align-items-center bb_1">
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <div className="posted-user mb-2">
                    {singlePost?.user_info && (
                      <Link
                        to={singlePost?.org_info?.length > 0 ?
                          /pre/.test(singlePost?.org_info[0]?.mode) ?
                            `/premium/${singlePost?.org_info[0]?.pageName}/#freepost/`
                            : `/org/${singlePost?.org_info[0]?.pageName?.toLowerCase()}/#post/#active/`
                          : `/${singlePost?.user_info[0]?.userName.toLowerCase()}/`
                        }
                        className="posted-user-d mt-2"
                      >
                        <div className="pro_img">
                          <img
                            src={
                              singlePost?.pageId
                                ? singlePost?.org_info &&
                                singlePost?.org_info[0]?.organiztionLogo
                                : singlePost?.user_info &&
                                singlePost?.user_info[0]?.profilePhoto
                            }
                            alt="members profile"
                            // eslint-disable-next-line no-sequences
                            onError={(e) => {
                              e.target.onerror = null
                              e.target.src = "/assets/web_img/choto_logo_1.png"
                            }
                            }

                          // organiztionLogo
                          />
                        </div>
                        <div className="post-user-name">
                          {singlePost?.pageId
                            ? singlePost?.org_info &&
                            singlePost?.org_info[0]?.pageName
                            : singlePost?.user_info &&
                            singlePost?.user_info[0]?.showName}
                          <div className="fs_12">
                            {moment(singlePost?.createdAt).fromNow()}
                          </div>
                        </div>
                      </Link>
                    )}
                  </div>
                  <SavePost
                    id={singlePost?._id}
                    isMarked={
                      singlePost?.isFav ? singlePost?.isFav[0]?.mark : false
                    }
                  />
                </div>
                <div
                  className="c_pointer close_section"
                  onClick={handleCloseModal}
                >
                  <FontAwesomeIcon
                    icon={faClose}
                    className="post_icons"
                  ></FontAwesomeIcon>
                </div>
              </div>

              {viewArea === 1 && (
                <div className="like_box">
                  {likeUsers?.map((item) => {
                    return (
                      <div
                        className="pro_img d-flex align-items-center mt-1"
                        key={item?._id}
                      >
                        <img
                          src={
                            item?.referenceUserId?.profilePhoto
                              ? item?.referenceUserId?.profilePhoto
                              : "/assets/web_img/choto_logo_1.png"
                          }
                          alt="members profile"
                        />

                        <div className="d-flex justify-content-between w-100 px-2">
                          <div>
                            <Link
                              to={
                                "/" +
                                item?.referenceUserId?.userName.toLowerCase()
                              }
                              className="like_link"
                            >
                              {item?.referenceUserId?.showName}
                            </Link>
                            <div className="fs_12">
                              {moment(item?.createdAt).fromNow()}
                            </div>
                          </div>
                          <div className="like_icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 0 24 24"
                              width="24px"
                              fill="#000000"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}

              {/* comment area */}
              {viewArea === 0 && (
                <div className="comments_Area">
                  <div className="row">
                    <div className="col-lg-12">
                      {/* <div onClick={closeModal} className="modal_close_btn">x</div> */}
                      {/Blogs/.test(singlePost?.postCategory) ? (
                        ""
                      ) : (
                        <>
                          {(singlePost?.shareDescription || (singlePost?.postInfo &&
                            singlePost?.postInfo[0]?.postImagePath)) &&
                            <>
                              {singlePost?.user_info && (
                                <div className="commentsView">
                                  <div className="commentBox">
                                    <pre className="fs-14 viewD">
                                      <ReadMore>
                                        {singlePost?.postDescription || singlePost?.shareDescription}
                                      </ReadMore>
                                    </pre>
                                  </div>
                                </div>
                              )}
                            </>
                          }
                        </>
                      )}
                      {allComments &&
                        allComments?.map((item, index) => {
                          //console.log("fdsfhdskfd", item)
                          return (
                            <div className="commentsDiv mt-3">
                              <Link
                                to={
                                  "/" +
                                  item?.referenceUserId?.userName.toLowerCase()
                                }
                                className="pro_img_com"
                              >
                                {item?.referenceUserId ? (
                                  <div className="d-flex ">
                                    <img
                                      src={
                                        item?.referenceUserId?.profilePhoto ||
                                        "/assets/web_img/choto_logo_1.png"
                                      }
                                      alt="members profile"
                                    />
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Link>

                              <div className="commentsView">
                                <div className="commentBox">
                                  <pre className="fs-14 viewD">
                                    <Link
                                      to={
                                        "/" +
                                        item?.referenceUserId?.showName.toLowerCase()
                                      }
                                      className="user_name cl_b"
                                    >
                                      {item?.referenceUserId?.showName
                                        ? item?.referenceUserId?.showName
                                        : item?.referenceUserId?.userName}
                                      :
                                    </Link>
                                    {item?.messageText ? item?.messageText : ""}
                                  </pre>
                                  <div className="d-flex gap-2 commbox">
                                    <div
                                      className="like commentx h-underline d-flex align-items-center"
                                      onClick={() =>
                                        handleCommentLikeClick(item?._id)
                                      }
                                    >
                                      {item?.replyLikeCount
                                        ? item?.replyLikeCount
                                        : 0}{" "}
                                      <FavoriteBorderOutlinedIcon />
                                      {/* <FavoriteSharpIcon className="cl_r"/> */}
                                    </div>
                                    <div
                                      className="like commentx h-underline d-flex align-items-center"
                                      title="click to reply"
                                      onClick={() =>
                                        handleReplyCommentClick(
                                          item?._id,
                                          index
                                        )
                                      }
                                    >
                                      {item?.replyCommentCount
                                        ? item?.replyCommentCount
                                        : 0}{" "}
                                      <QuickreplyOutlinedIcon />
                                    </div>

                                    {item?.referenceUserId?.id === token && (
                                      <>

                                        <div
                                          className="like commentx h-underline cl_hov_cng"
                                          onClick={() =>
                                            deleteComment(item._id)
                                          }
                                          title="Click to delete this comment"
                                        >
                                          <DeleteOutlinedIcon className="cl_rh" />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                                {commentSwitch["comment" + index] === index ? (
                                  <ReplyComments
                                    show={replyShow}
                                    commentid={item?._id}
                                    tokenuser={currentUser.profilePhoto}
                                    // usertag={comment1}
                                    postid={singlePost._id}
                                    postcreatorid={singlePost?.userinfo?._id}
                                  // changereply={setTotalReplyComments}
                                  />
                                ) : (
                                  ""
                                )}

                                {commentSwitch["comment" + index] === index ? (
                                  <div className="d-flex gap-2">
                                    <div
                                      className="comment_count"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleReplyCommentView(index)
                                      }
                                    >
                                      {/* View more replies (wip), */}
                                      View more replies,
                                    </div>
                                    <div
                                      className="comment_count close_comment"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        setCommentSwitch((values) => ({
                                          ...values,
                                          ["comment" + index]: "",
                                        }))
                                      }
                                    >
                                      Close replies
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    className="comment_count mt-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleReplyCommentView(index)
                                    }
                                  >
                                    View recent replies
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        })}
                    </div>
                  </div>
                </div>
              )}

              <div className="d-flex gap-2 popup_like_section">
                <div>
                  {singlePost?.referenceUserId &&
                    singlePost?.referenceUserId !== token && (
                      <PostLike
                        id={singlePost?._id}
                        isLike={singlePost?.isLiked[0]?.isLike}
                        getlikes={() => getTotalLikes()}
                      />
                    )}
                  {/* {singlePost?._id && singlePost?.referenceUserId === token ? "" : <PostLike id={singlePost?._id} isLike={singlePost?.isLiked[0]?.isLike} getlikes={() => getTotalLikes()} />} */}
                </div>
                <div className="like commentx">
                  <CopyLink link={`/p/${singlePost?._id}?redirect=1`} />
                  {/* <FontAwesomeIcon icon={faLink} className="post_icons"></FontAwesomeIcon> */}
                </div>

                {singlePost?.user_info &&
                  token === singlePost?.user_info[0]?._id ? (
                  ""
                ) : <SharePostAsFeed
                  post={singlePost}
                  setShow={setShow}
                  closeBtn={closeBtn}
                  isNavigate={navigate}
                />}

                <div
                  className={viewArea === 1 ? "btn active" : "btn"}
                  onClick={() => setViewArea(1)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#6ea20b"
                  >
                    <g>
                      <rect fill="none" height="24" width="24"></rect>
                    </g>
                    <g>
                      <g>
                        <circle cx="4" cy="14" r="2"></circle>
                        <path d="M1.22,17.58C0.48,17.9,0,18.62,0,19.43V21l4.5,0v-1.61c0-0.83,0.23-1.61,0.63-2.29C4.76,17.04,4.39,17,4,17 C3.01,17,2.07,17.21,1.22,17.58z"></path>
                        <circle cx="20" cy="14" r="2"></circle>
                        <path d="M22.78,17.58C21.93,17.21,20.99,17,20,17c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V21l4.5,0v-1.57 C24,18.62,23.52,17.9,22.78,17.58z"></path>
                        <path d="M16.24,16.65c-1.17-0.52-2.61-0.9-4.24-0.9c-1.63,0-3.07,0.39-4.24,0.9C6.68,17.13,6,18.21,6,19.39V21h12v-1.61 C18,18.21,17.32,17.13,16.24,16.65z"></path>
                        <path d="M9,12c0,1.66,1.34,3,3,3s3-1.34,3-3c0-1.66-1.34-3-3-3S9,10.34,9,12z"></path>
                        <path d="M2.48,10.86C2.17,10.1,2,9.36,2,8.6C2,6.02,4.02,4,6.6,4c2.68,0,3.82,1.74,5.4,3.59C13.57,5.76,14.7,4,17.4,4 C19.98,4,22,6.02,22,8.6c0,0.76-0.17,1.5-0.48,2.26c0.65,0.31,1.18,0.82,1.53,1.44C23.65,11.1,24,9.88,24,8.6 C24,4.9,21.1,2,17.4,2c-2.09,0-4.09,0.97-5.4,2.51C10.69,2.97,8.69,2,6.6,2C2.9,2,0,4.9,0,8.6c0,1.28,0.35,2.5,0.96,3.7 C1.31,11.68,1.84,11.17,2.48,10.86z"></path>
                      </g>
                    </g>
                  </svg>
                  {totalLikesCount} Likes
                </div>
                <div
                  className={viewArea === 0 ? "btn active" : "btn"}
                  onClick={() => setViewArea(0)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#2570c3"
                  >
                    <g>
                      <rect fill="none" height="24" width="24"></rect>
                      <rect fill="none" height="24" width="24"></rect>
                    </g>
                    <g>
                      <g>
                        <polygon points="22,9 22,7 20,7 20,9 18,9 18,11 20,11 20,13 22,13 22,11 24,11 24,9"></polygon>
                        <path d="M8,12c2.21,0,4-1.79,4-4s-1.79-4-4-4S4,5.79,4,8S5.79,12,8,12z"></path>
                        <path d="M8,13c-2.67,0-8,1.34-8,4v3h16v-3C16,14.34,10.67,13,8,13z"></path>
                        <path d="M12.51,4.05C13.43,5.11,14,6.49,14,8s-0.57,2.89-1.49,3.95C14.47,11.7,16,10.04,16,8S14.47,4.3,12.51,4.05z"></path>
                        <path d="M16.53,13.83C17.42,14.66,18,15.7,18,17v3h2v-3C20,15.55,18.41,14.49,16.53,13.83z"></path>
                      </g>
                    </g>
                  </svg>
                  {singlePost?.totalComment}
                </div>
              </div>

              {/* comment post  */}
              <div className="add_comment d-flex px_15">
                <div className="pro_img">
                  <img
                    src={
                      currentUser?.profilePhoto
                        ? currentUser?.profilePhoto
                        : "/assets/web_img/choto_logo_1.png"
                    }
                    alt="members profile"
                  />
                </div>
                <input
                  type="text"
                  name="replyComment"
                  onChange={(e) => setComValue(e.target.value)}
                  className="comment"
                  placeholder="Add comment"
                  value={comValue || ""}
                />
                <div className="commentPost" onClick={handleCommentClick}>
                  Post
                </div>
              </div>
              {/* end comment  */}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  console.log("stateoRgpostcreationposts", state)
  const { singlePost } = state.posts
  return { singlePost }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(SinglePostDetails)