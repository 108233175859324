import React, { useState } from "react"
import SinglePost from "../Creation/SinglePost"
import PostTestCard from "../../components/Postsk"

const PremiumPostComponent = (props) => {
	const { posts, orgDetails, getpost } = props
	console.log(props, "OrganizationPosts")
	const [singlePost] = useState({})
	const [isPostModal, setIsPostModal] = useState(false)
	const token = typeof window !== "undefined" ? localStorage.getItem("token") : ""

	return (
		<>

			<hr className="my-4" />
			<div className="mt-3">

				<div className="masonry_wrapper">
					<div className="masonry">
						{posts?.length > 0 &&
							posts?.map((item, index) => {
								return (
									<>
										<div
											className="item"
											key={index}
										// onClick={() => incrementPostViews(item)}
										>
											<div className="item_in">
												<PostTestCard
													isOrgAdmin={orgDetails?.userid === token ? true : false}
													singlePost={item}
													key={item._id}
													indexinfo={index}
													getpost={() => getpost()}
													isViewDetails={true}
													isPostActionSec={true}
													isPostCommentSec={false}
													orgDetails={orgDetails}
												// changeId={setSharePostId}
												/>
											</div>
										</div>
									</>
								)
							})}
					</div>
					{/* 9123362465 */}
				</div>
				{posts?.length === 0 &&
					<h2 className="text-center w-100">No post found</h2>
				}

			</div>

			{isPostModal && singlePost && <SinglePost type="modal" isView={isPostModal} postid={singlePost._id} closeBtn={() => setIsPostModal(false)} />}
		</>
	)
}

export default PremiumPostComponent



