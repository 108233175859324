import React, { useState, useEffect } from "react"
import {
	useLocation,
	useNavigate,
} from "react-router-dom"
// import { useDispatch } from "react-redux"
import { Tab, Tabs } from "react-bootstrap"
import SinglePost from "../Creation/SinglePost"
// import { orgPostsAction } from "../../redux/reducers/organizationReducer"
import PostTestCard from "../../components/Postsk"

function OrganizationPosts(props) {
	const { posts, orgDetails, setSelectedChildPage, getpost } = props
	console.log(props, "OrganizationPosts")
	const [singlePost] = useState({})
	// const [singlePost, setSinglePost] = useState({})
	const [isPostModal, setIsPostModal] = useState(false)
	// const [isProcessing, setIsProcessing] = useState(false)
	const navigate = useNavigate();

	// needable variables
	// (userDetails, token)
	// const dispatch = useDispatch()
	const path = useLocation();

	// const currentUser = typeof window !== "undefined" ? JSON.parse(localStorage.getItem("currentuser")) : ""
	const token = typeof window !== "undefined" ? localStorage.getItem("token") : ""
	const [key, setKey] = useState("active/")
	const [pages, setPages] = useState(1)

	// const getPosts = async () => await getpost()

	// const PostAction = async (pid, request) => {
	// 	try {
	// 		const params = {
	// 			body: {
	// 				"orgId": orgDetails?._id,
	// 				"ownerId": token,
	// 				"postId": pid,
	// 				"request": request
	// 			},
	// 			query: {
	// 				orgid: orgDetails?._id,
	// 				multiple: true
	// 			}
	// 		}
	// 		dispatch(orgPostsAction(params))
	// 		await getpost()

	// 	} catch (err) {
	// 		console.error(err)
	// 	}
	// }

	// const handleModal = (item) => {
	// 	console.log("clicked")
	// 	setIsPostModal(true)
	// 	setSinglePost(item)
	// 	console.log(singlePost)
	// }

	const onLinkClick = (e) => {
		setPages(() => 1)
		setKey(e);
		const { pathname } = path
		navigate(pathname + "#post/#" + e);
		setSelectedChildPage(prev => ({ ...prev, parent: "post/", child: e }))
	};
	// open modal function
	// const handleModalShow = (index) => {
	// 	setIsPostModal(true)
	// 	setSinglePost(posts[index])
	// }

	const init = async () => {
		const { hash } = path;
		const initialHash = hash && hash.split("#")[2];

		if (initialHash && initialHash !== "") {
			setKey(() => initialHash);
			// console.log("hash>>>", initialHash);
		}
		// const params = {
		// 	status: key,
		// 	orgid: orgDetails?._id,
		// 	page: pages,
		// 	pagination: true
		// }
		// const response = await dispatch()
		// console.log("init",response)
	}
	const handleScroll = (e) => {
		let scrollTop = window.pageYOffset
		let clientHeight = window.document.documentElement.clientHeight
		let bodyHeight = window.document.body.clientHeight

		if (clientHeight + scrollTop > bodyHeight - 200) {
			window.removeEventListener("scroll", handleScroll)
			setPages((pages) => pages + 1)
		}
	}
	useEffect(() => {
		if (posts) window.addEventListener("scroll", handleScroll)
		return () => window.removeEventListener("scroll", handleScroll)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [posts])

	useEffect(() => {
		console.log("pages", pages)
	}, [pages])

	useEffect(() => {
		if (pages > 1 && pages < 3) init()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pages])

	useEffect(() => {
		init()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path])

	return (
		<>

			<hr className="my-4" />
			<div className="mt-3">

				<Tabs
					defaultActiveKey="active/"
					activeKey={key}
					id="uncontrolled-tab-example"
					onSelect={(e) => onLinkClick(e)}
					className="mb-3"
				>
					<Tab eventKey="active/"
						title="All"
					>
						<div className="masonry_wrapper">
							<div className="masonry">
								{posts?.length > 0 &&
									posts?.map((item, index) => {
										return (
											<>
												<div
													className="item"
													key={index}
												// onClick={() => incrementPostViews(item)}
												>
													<div className="item_in">
														<PostTestCard
															isOrgAdmin={orgDetails?.userid === token ? true : false}
															singlePost={item}
															key={item._id}
															indexinfo={index}
															getpost={() => getpost()}
															isViewDetails={true}
															isPostActionSec={true}
															isPostCommentSec={false}
															orgDetails={orgDetails}
														// changeId={setSharePostId}
														/>
													</div>
												</div>
											</>
										)
									})}
							</div>
							{/* 9123362465 */}
						</div>
						{posts?.length === 0 &&
							<h2 className="text-center w-100">No post found</h2>
						}
					</Tab>
					{(orgDetails?.userid === token || orgDetails?.isMember[0]?.status === "active") &&
						<Tab eventKey="pending/"
							id="#pending"
							title="Pending"
						>
							<div className="masonry_wrapper">
								<div className="masonry">
									{posts?.length > 0 &&
										posts?.map((item, index) => {
											return (
												<>
													<div
														className="item"
														key={index}
													// onClick={() => incrementPostViews(item)}
													>
														<div className="item_in">
															<PostTestCard
																singlePost={item}
																key={item._id}
																indexinfo={index}
																isOrgMember={orgDetails?.isMember[0]?.status === "active" ? true : false}
																isOrgAdmin={orgDetails?.userid === token ? true : false}
																getpost={() => getpost()}
																isViewDetails={true}
																isPostActionSec={true}
																isPostCommentSec={false}
																orgDetails={orgDetails}

															// changeId={setSharePostId}
															/>
														</div>
													</div>
												</>)
										})}

									{posts?.length < 1 &&
										<h2 className="text-center d-flex w-100">
											No post found
										</h2>
									}
								</div>
							</div>
							{posts?.length === 0 &&
								<h2 className="text-center w-100">No post found</h2>
							}
						</Tab>
					}

					{(orgDetails?.userid === token || orgDetails?.isMember[0]?.status === "active") &&
						<Tab eventKey="rejected/"
							title="Rejected">
							<div className="masonry_wrapper">
								<div className="masonry">
									{posts?.length > 0 ?

										<>{posts?.map((item, index) => {
											return (
												<>
													<div
														className="item"
														key={index}
													// onClick={() => incrementPostViews(item)}
													>
														<div className="item_in">
															<PostTestCard
																singlePost={item}
																key={item._id}
																indexinfo={index}
																orgDetails={orgDetails}
																isOrgMember={orgDetails?.isMember[0]?.status === "active" ? true : false}
																isOrgAdmin={orgDetails?.userid === token ? true : false}
																getpost={() => getpost()}

																// getpost={() => getAllPosts()}
																isViewDetails={true}
																isPostActionSec={true}
																isPostCommentSec={false}
															// changeId={setSharePostId}
															/>
														</div>
													</div>
												</>
											)
										})}</>
										:
										<h2 className="text-center d-flex w-100">
											No post found
										</h2>}
								</div>
							</div>
							{posts?.length === 0 &&
								<h2 className="text-center w-100">No post found</h2>
							}
						</Tab>
					}

				</Tabs>

			</div>

			{isPostModal && singlePost && <SinglePost type="modal" isView={isPostModal} postid={singlePost._id} closeBtn={() => setIsPostModal(false)} />}
		</>
	)
}

export default OrganizationPosts


