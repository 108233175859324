import React, {  useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import Language from '../../components/categories/Language'
import PostCategory from '../../components/categories/PostCategory'
import Subcategory from '../../components/categories/Subcategory'
// import LeftNavbar from '../../components/Navbars/LeftNavbar'
import ManagementLeftNav from '../../components/Navbars/ManagementLeftNav'

function AdminDomainManagement() {
    const [value, setValue] = useState(0)
    // const navigate = useNavigate()

    // useEffect(() => {
    //     const user = typeof window !== "undefined"
    //             ? JSON.parse(localStorage.getItem("currentUser"))
    //             : "";

    //     if(user?.userRole !== '1010') navigate('/admin/')
    // }, [])

    return (
        <div className='container-fluid'>
                <div className="row">
                    <div className="col-lg-2">
                        <ManagementLeftNav />
                    </div>
        <div className="col-lg-10 bg_allp" style={{ minHeight: "100vh" }}>
            <Language />
            <PostCategory cont={() => setValue(value + 1)} />
            <Subcategory contV={value} />
        </div>
        </div>
        </div>

    )
}

export default AdminDomainManagement