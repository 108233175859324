export const ACTION_GET_PREMIUM_LIST = "ACTION_GET_PREMIUM_LIST"
export const ACTION_GET_PREMIUM_POST = "ACTION_GET_PREMIUM_POST"
export const ACTION_GET_DATA_BY_STATE = "ACTION_GET_DATA_BY_STATE"


const initialState = {
    initialLoad: true,
    basic: null,
    premiumList: [],
    freepost: [],
    premiumPost: [],
    myPremiumPost:[],
    isError: false,
    isProcessing: null,
    isSuccess: null,
    message: null,
    selectedNews: null,
    premiumPagePost: [],
    ppvAnalytics: [],
    ppvAnalyticsPage: null
}
export const premiumReducer = (state = initialState, action) => {
    const returnObj = { ...state }
    switch (action.type) {
        case ACTION_GET_PREMIUM_LIST:
            returnObj.premiumList = action?.page > 1 ? [...state?.premiumList, ...action?.payload] : action?.payload
            return returnObj
        case ACTION_GET_PREMIUM_POST:
            returnObj.premiumPost = action?.page > 1 ? [...state?.premiumPost, ...action?.payload] : action?.payload
            return returnObj
        case ACTION_GET_DATA_BY_STATE:
            returnObj[action?.state] = action?.page > 1 ? [...returnObj[action?.state], ...action?.payload] : action?.payload
            return returnObj
        default:
            return state
    }
}
