import React from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { CustomizedSnackbars, SimpleCircle } from "../../components";

export default function RegistrationForm({
  handleSubmit,
  inputfocus,
  setInputfocus,
  values,
  errors,
  handleChange,
  country,
  options,
  selectedOptions,
  handleIsDisplayName,
  isApproval,
  isSubmit,
  isError,
  isAgreed,
  isGdpr,
  setIsError,
  setisGdpr,
  setIsApproval,
  isDisplayName,
  termsError,
  handleIsAgreed,
  errmsg,
  refError,
  handleSelectChange,
  duplicate,
}) {
  return (
    <>
      <form className="items-center" onSubmit={handleSubmit}>
        <div className="reg-div mt-4 mb-2">
          <div className="name-div">
            {/* firstname  */}
            <div className="form-group position-relative has-icon-left mb-2">
              <label htmlFor="" className="log-input-title">
                First Name :
              </label>

              <input
                type="text"
                className="form-control form-control-xl mt-2"
                autoComplete="off"
                placeholder="Enter first name here"
                name="firstName"
                onFocus={(e) =>
                  setInputfocus((inputfocus) => ({
                    ...inputfocus,
                    [e.target.name]: true,
                  }))
                }
                onBlur={(e) =>
                  setInputfocus((inputfocus) => ({
                    ...inputfocus,
                    [e.target.name]: false,
                  }))
                }
                onChange={handleChange}
                value={values.firstName || ""}
                required
              />

              <div className="errors">
                {!inputfocus.firstName && errors.firstName}
              </div>
            </div>

            {/* last name  */}
            <div className="form-group position-relative has-icon-left mb-2">
              <label htmlFor="" className="log-input-title">
                Last Name :
              </label>
              <input
                type="text"
                className="form-control form-control-xl mt-2"
                autoComplete="off"
                placeholder="Enter last name here"
                name="lastName"
                onFocus={(e) =>
                  setInputfocus((inputfocus) => ({
                    ...inputfocus,
                    [e.target.name]: true,
                  }))
                }
                onBlur={(e) =>
                  setInputfocus((inputfocus) => ({
                    ...inputfocus,
                    [e.target.name]: false,
                  }))
                }
                onChange={handleChange}
                value={values.lastName || ""}
                required
              />
              <div className="errors">
                {!inputfocus.lastName && errors.lastName}
              </div>
            </div>
          </div>

          {/* username  */}
          <div className="form-group position-relative has-icon-left mb-2">
            <label htmlFor="" className="log-input-title">
              User Name :
            </label>

            <input
              type="text"
              className="form-control form-control-xl mt-2"
              autoComplete="off"
              placeholder="Enter user name here"
              name="userName"
              onFocus={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: true,
                }))
              }
              onBlur={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: false,
                }))
              }
              onChange={handleChange}
              value={values.userName || ""}
              required
            />
            {/* <div className="fs_choto">Use some alpha-numeric charecters to make it unique</div> */}
            <div className="mt-1 fs_choto">
              <input
                type="checkbox"
                className="kk_check"
                id="showName"
                name="isDisplayed"
                value="isDisplayed"
                checked={isDisplayName}
                onChange={handleIsDisplayName}
              />
              show as display name
            </div>

            <div className="errors">
              {(!inputfocus.userName && errors.userName) || duplicate.userName}
            </div>
            {/* <div className="errors">{inputfocus.userName && duplicate.userName}</div> */}
          </div>

          {/* email  */}
          <div className="form-group position-relative has-icon-left mb-2">
            <label htmlFor="Email" className="log-input-title">
              Email address :
            </label>
            <input
              type="email"
              className="form-control form-control-xl mt-2"
              autoComplete="off"
              placeholder="Enter email address here"
              name="email"
              onFocus={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: true,
                }))
              }
              onBlur={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: false,
                }))
              }
              onChange={handleChange}
              value={values.email || ""}
              required
            />
            <div className="errors">{!inputfocus.email && errors.email}</div>
          </div>

          {/* phone  */}
          <div className="form-group position-relative has-icon-left mb-2">
            <label htmlFor="" className="log-input-title">
              Phone :
            </label>
            <select
              // defaultValue={[colourOptions[2], colourOptions[3]]}

              name="countryDialCode"
              className="form-control"
              onFocus={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: true,
                }))
              }
              onBlur={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: false,
                }))
              }
              placeholder="select country code"
              onChange={handleChange}
              required
            >
              <option value="">Select country code</option>
              {country.map((item) => {
                return item?.dial_code === "+91" ? (
                  <option value={item?.dial_code} key={item?.id} selected>
                    {item?.name + "/" + item?.dial_code}
                  </option>
                ) : (
                  <option value={item?.dial_code} key={item?.id}>
                    {item?.name + "/" + item?.dial_code}
                  </option>
                );
              })}
            </select>
            <input
              type="tel"
              className="form-control form-control-xl mt-1"
              autoComplete="off"
              placeholder="Enter phone no. here"
              name="phone"
              onFocus={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: true,
                }))
              }
              onBlur={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: false,
                }))
              }
              onChange={handleChange}
              value={values.phone || ""}
              required
            />
            <div className="errors">{!inputfocus.phone && errors.phone}</div>
          </div>

          {/* password  */}
          <div className="form-group position-relative has-icon-left mb-2">
            <label htmlFor="" className="log-input-title">
              Password :
            </label>

            <input
              type="password"
              className="form-control form-control-xl mt-1"
              autoComplete="off"
              placeholder="Enter password here"
              name="password"
              onFocus={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: true,
                }))
              }
              onBlur={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: false,
                }))
              }
              onChange={handleChange}
              value={values.password || ""}
              required
            />
            <div className="mt-1 fs_choto cl_gray info">
              * Password must be consist of 1 uppercase letter, 1 lowercase
              letter, 1 digit, and 1 special charecter and minimum length of 8
              characters.
            </div>

            <div className="errors">
              {!inputfocus.password && errors.password}
            </div>
          </div>

          {/* select demo  */}
          <div className="form-group position-relative has-icon-left mb-2">
            <label htmlFor="" className="log-input-title">
              Languages :
            </label>
            <Select
              // defaultValue={[colourOptions[2], colourOptions[3]]}
              isMulti
              name="languages"
              options={options}
              className="basic-multi-select"
              classNamePrefix="select"
              value={options.find((obj) => obj.value === selectedOptions)}
              onChange={handleSelectChange}
              // trim={true}
              placeholder="Choose languages"
            />
            <div className="errors">{errors?.languages}</div>
          </div>

          {/* refcode  */}

          <div className="form-group position-relative has-icon-left mb-2">
            <label htmlFor="" className="log-input-title">
              Have a referral code ? <span className="errors">(optional)</span>
            </label>

            <input
              type="text"
              className="form-control form-control-xl mt-2"
              autoComplete="off"
              placeholder="Enter referral code here"
              name="refcode"
              onFocus={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: true,
                }))
              }
              onBlur={(e) =>
                setInputfocus((inputfocus) => ({
                  ...inputfocus,
                  [e.target.name]: false,
                }))
              }
              onChange={handleChange}
              value={values.refcode || ""}
            />

            <div className="errors">{refError}</div>

            {/* <div className="errors">
							{!inputfocus.refCode && erro.refCode}
						</div> */}
          </div>
          {/* )} */}

          {/* end refcode */}

          <div className="mt-3 fs-14">
            <input
              type="checkbox"
              className="kk_check"
              id="isApproval"
              name="ParentalApproval"
              value="termsAgreed"
              checked={isApproval}
              onChange={() => setIsApproval(!isApproval)}
              required
            />
            <span className="text-danger">* </span>I am older than 17 years or I
            have got parental approval to access this site.
          </div>
          {isSubmit && termsError?.isApproval && (
            <div className="errors">{termsError?.isApproval}</div>
          )}

          <div className="mt-1 fs_choto">
            <input
              type="checkbox"
              className="kk_check"
              id="showName"
              name="GDPR"
              value={isGdpr}
              checked={isGdpr}
              onChange={() => setisGdpr(!isGdpr)}
            />
            <span className="text-danger">* </span>
            GDPR
          </div>

          <div className="mt-3 fs-14">
            <input
              type="checkbox"
              className="kk_check"
              id="termsAgreed"
              name="termsAgreed"
              value="termsAgreed"
              checked={isAgreed}
              onChange={handleIsAgreed}
              required
            />
            <span className="text-danger">* </span>
            Agree to our{" "}
            <Link to="/terms" className="clr-p">
              Terms of use
            </Link>{" "}
            &{" "}
            <Link className="clr-p" to="/privacy">
              Privacy Policy
            </Link>
          </div>

          {isSubmit && termsError?.isAgreed && (
            <div className="errors">{termsError?.isAgreed}</div>
          )}
        </div>

        {isError && (
          <CustomizedSnackbars
            type={1}
            msg={errmsg}
            sclose={() => setIsError(false)}
          />
        )}

        {isSubmit ? (
          <button
            type="button"
            className="btn btns mt-3 d-flex justify-content-center"
          >
            <SimpleCircle />
          </button>
        ) : (
          <button className="btn btns mt-3">Continue</button>
        )}
        <span className="text-success"><b>The Registration is free</b></span>
        <div className="mt-3">
          Already have an account? goto{" "}
          <Link className="clr-p" to="/auth/login">
            Sign In
          </Link>
          
        </div>
      </form>
    </>
  );
}
