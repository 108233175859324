import React, { useState, useEffect, useRef } from "react"
import Compressor from "compressorjs"
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto"
import VideocamIcon from "@mui/icons-material/Videocam"
import PublishIcon from "@mui/icons-material/Publish"
import { connect, useDispatch } from "react-redux"
import { createEvents, updateEvents } from "../../redux/reducers/postReducer"
// import { getSubscriptionPlan } from "../../redux/reducers/admin.reducer"
import { PrivatePostCard } from "../../containers/Organization/OrgPrivatePosts"
import { getOrganizationPosts } from "../../redux/reducers/organizationReducer"
import { useLocation } from "react-router-dom"
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { CustomizedSnackbars, SimpleCircle } from "../../components"
import { CopyLink, PostProfilex } from "../../components/AppComponents/Helpers"
import { LDS } from "../../components/Loaders/SimpleCircle"

const CreatePremiumPost = (props) => {
    const { domains, orgDetails, isOwner, posts } = props
    const [pageLoader, setPageLoader] = useState(true)
    const [createPost, setCreatePost] = useState(false)
    const [postText, setPostText] = useState({})
    const [fileInputState] = useState("")
    const [errMsg, setErrMsg] = useState("")
    const [waitingLoader, setWaitingLoader] = useState(false)
    const [loaderSwitch, setLoaderSwitch] = useState(props.spinner)
    const [domainId, setDomainId] = useState("")
    const [showTitle, setShowTitle] = useState(false)
    const [shareDial, setShareDial] = useState(false)
    const [postIdx, setPostIdx] = useState("")
    const [isCopyLink, setIsCopyLink] = useState("")
    const [editorLoaded, setEditorLoaded] = useState(false)
    const [filePreview, setFilePreview] = useState({})
    const [fileRawData, setFileRawData] = useState({})
    const [page, setPage] = useState(1)
    const editorRef = useRef()
    const { CKEditor, ClassicEditor } = editorRef.current || {}
    const dispatch = useDispatch()
    const { hash } = useLocation()
    console.log("locationfdfdf", hash)

    useEffect(() => {
        editorRef.current = {
            // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
            CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
            ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
        }
        setEditorLoaded(true)
    }, [])

    const init = async () => {
        try {
            let param = {
                orgid: orgDetails?._id,
                reqPage: "premium",
                page: page,
            }
            let req = {
                params: param,
                url: 'getpreposts',
                action: 'ACTION_GET_DATA_BY_STATE',
                state: 'premiumPagePost'
            }
            await dispatch(getRequestHandler(req))

        } catch (error) {
            console.error(error)
        }
    }
    const init2 = async () => {
        try {

            let req2 = {
                url: 'domains',
                action: 'ACTION_GET_DOMAINS'
            }
            await dispatch(getRequestHandler(req2))
        } catch (error) {
            console.error(error)
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0]
        const typeImage = file.type
        if (/image/.test(typeImage)) {
            new Compressor(file, {
                quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {
                    setFilePreview((prev) => ({
                        ...prev,
                        [e.target.name]: URL.createObjectURL(file),
                    }))
                    setFileRawData((prev) => ({
                        ...prev,
                        [e.target.name]: compressedResult,
                    }))
                },
            })
        } else {
            setFilePreview((prev) => ({
                ...prev,
                [e.target.name]: URL.createObjectURL(file),
            }))
            setFileRawData((prev) => ({ ...prev, [e.target.name]: file }))
        }
    }

    const clearPost = () => {
        setPostText({})
        setFilePreview({})
        setFileRawData((prev) => ({}))
        setCreatePost(false)
    }

    const detectURLs = (message) => {
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
        return message.match(urlRegex)
    }

    const handleChange = (e) => {
        e.persist()
        if (e.target.name === "postDescription") {
            let urlCheck = detectURLs(e.target.value)
            if (urlCheck) return false
        }

        setPostText((values) => ({ ...values, [e.target.name]: e.target.value }))
        if (e.target.name === "postCategory") {
            const index = e.target.selectedIndex
            setPostText((prev) => ({ ...prev, postDescription: "" }))
            setDomainId(index)
        }
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault()
            setLoaderSwitch(!loaderSwitch)
            const postTitle = postText.postTitle || "Quick Post"
            const data = new FormData()
            if (!props?.pageid) return setCreatePost(false)
            data.append("pageId", props?.pageid)
            data.append("gallerType", "video")
            data.append("postType", "pre") //shared
            data.append("uploadType", "POPP") //shared
            data.append("isSaved", false)
            data.append("postTitle", postTitle)
            data.append("isFile", fileInputState ? true : false)
            data.append(
                "thumbnail",
                fileRawData?.thumbnail,
                fileRawData?.thumbnail?.name
            )
            data.append("videolink", fileRawData?.eventVideo)
            data.append("postDescription", postText?.postDescription || "")
            data.append("postCategory", postText.postCategory || "")
            data.append("postSubDomain", postText.postSubDomain || "")
            data.append("language", postText.language || "")
            data.append(
                "ppv",
                postText?.ppv === "custom" ? postText?.ppvc || "" : postText?.ppv || ""
            )
            data.append("publishDate", postText?.publishDate || "")
            data.append("status", "draft")
            const res = await dispatch(createEvents(data))
            clearPost()
            setLoaderSwitch(false)
            if (res?.data?.ack === 0) return setErrMsg(res?.data?.message)
            // if (props?.getpost) props?.getpost()
            init()
            setPostIdx(res?.data?.responseData)
            setIsCopyLink(`https://${window.location.host}`)
            setShareDial(true)
            setErrMsg("")
        } catch (err) {
            console.error(err)
            setErrMsg(
                "Post upload failed due to some technical issue. Please try again later."
            )
        }
    }

    const publishPost = async (params) => {
        const response = await dispatch(updateEvents(params))
        if (response?.data?.ack === 0) return setErrMsg(response?.data?.message)
        await init()
    }

    // useEffect(() => dispatch(getSubscriptionPlan({
    //     mode: "view",
    //     type: "ppv",
    //     sort: "price",
    //     stype: 1,
    // })
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // ), [])

    // error clearing after 5s
    useEffect(() => {
        if (!errMsg) return
        setTimeout(() => setErrMsg(""), [5000])
    }, [errMsg])

    useEffect(() => {
        if (!isCopyLink) return
        setTimeout(() => setIsCopyLink(""), [10000])
    }, [isCopyLink])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(
        () =>
            !postText?.postCategory || postText?.postCategory === "Normal"
                ? setShowTitle(false)
                : setShowTitle(true),
        [postText?.postCategory]
    )
    useEffect(
        () =>
            setCreatePost(
                postText?.postTitle &&
                    postText?.postDescription &&
                    fileRawData?.thumbnail &&
                    postText?.publishDate &&
                    postText?.ppv
                    ? true
                    : false
            ),
        [postText, fileRawData]
    )

    useEffect(() => {
        loaderSwitch
            ? setTimeout(() => {
                setWaitingLoader(true)
            }, [10000])
            : setWaitingLoader(false)
    }, [loaderSwitch])

    useEffect(() => {
        init2()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (orgDetails?._id && /premiumpost/.test(hash)) {
            init()
            setPageLoader(false)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hash, orgDetails, isOwner])

    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        setIsShow(postText?.ppv === "custom" ? true : false)
    }, [postText.ppv])

    return (
        <>
            {isOwner && (
                <>
                    <div id="createPosts" className="mt-3">
                        <div className="d-flex">
                            <div className="dom_title">Tags </div>
                            <div className="w-100 bg-danger cpost_select">
                                <select name="language" onChange={handleChange}>
                                    <option className="t_cap" value="English">
                                        Language
                                    </option>
                                    {domains?.language?.length > 0 &&
                                        domains?.language?.map((item, index) => {
                                            return (
                                                <option
                                                    className="t_cap"
                                                    value={item.language}
                                                    key={item._id + index}
                                                >
                                                    {item.language}
                                                </option>
                                            )
                                        })}
                                </select>
                            </div>

                            <div className="w-100">
                                <select name="postCategory" onChange={handleChange}>
                                    <option className="t_cap" value="Normal">
                                        Domain
                                    </option>

                                    {domains?.domains?.length > 0 &&
                                        domains?.domains?.map((item, index) => {
                                            return (
                                                <option
                                                    className="t_cap"
                                                    id={item._id}
                                                    value={item.postCategory}
                                                    key={item._id + index}
                                                >
                                                    {item.postCategory}
                                                </option>
                                            )
                                        })}
                                </select>
                            </div>

                            <div className="w-100">
                                <select name="postSubDomain" onChange={handleChange}>
                                    <option className="t_cap" value="Normal">
                                        Sub domain
                                    </option>
                                    {domainId > 0 &&
                                        domains?.domains[domainId - 1]?.subDomain.map((item, index) => {
                                            return (
                                                <option
                                                    className="t_cap"
                                                    value={item.postSubCategory}
                                                    key={item._id + index}
                                                >
                                                    {item.postSubCategory}
                                                </option>
                                            )
                                        })}
                                </select>
                            </div>
                        </div>

                        <div className="cpost_body">
                            <div className="cpost_profile_section">
                                <PostProfilex
                                    className="sub-profile-pic"
                                    img={props?.userinfo?.profilePhoto}
                                    alt={props?.userinfo?.showName}
                                />
                            </div>
                            <form
                                onSubmit={handleSubmit}
                                className="cpost_info_sec w-100 p-2"
                            >
                                <label className="fs-14">
                                    Title <span className="text-danger">*</span>
                                </label>
                                <br />
                                <input
                                    type="text"
                                    className="inputs"
                                    name="postTitle"
                                    onChange={handleChange}
                                    value={postText?.postTitle || ""}
                                    placeholder="Enter title"
                                // required='required'
                                />
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label className="fs-14 mt-2">
                                            Publish Date <span className="text-danger">*</span>
                                        </label>
                                        <br />
                                        <input
                                            type="date"
                                            className="inputs"
                                            name="publishDate"
                                            onChange={handleChange}
                                            value={postText?.publishDate || ""}
                                            placeholder="Pick publish date"
                                            required="required"
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <label className="fs-14 mt-2">
                                            Pay Per View Price <span className="text-danger">*</span>
                                        </label>
                                        <br />

                                        <select
                                            className="form-select"
                                            name="ppv"
                                            onChange={handleChange}
                                            value={postText?.ppv || ""}
                                            required
                                        >
                                            <option className="" value="">
                                                Select ppv plan
                                            </option>
                                            <option className="" value={0}>
                                                Free
                                            </option>
                                            <option className="" value={5}>
                                                5
                                            </option>
                                            <option className="" value={10}>
                                                10
                                            </option>
                                            <option className="" value={15}>
                                                15
                                            </option>
                                            <option className="" value={20}>
                                                20
                                            </option>
                                            <option className="" value={"custom"}>
                                                Custom
                                            </option>
                                        </select>

                                        {isShow && (
                                            <input
                                                type="number"
                                                min="0"
                                                max={200}
                                                name="ppvc"
                                                className="form-control"
                                                onChange={handleChange}
                                                value={postText?.ppvc || ""}
                                                placeholder="Enter custom ppv amount"
                                                required
                                            />
                                        )}
                                    </div>
                                    <div className="col-lg-6 modal_image_input_1">
                                        <label title="Click to Attach Photo" className="mt-4">
                                            <InsertPhotoIcon />
                                            Select Thumbnail Photo{" "}
                                            <span className="text-danger">*</span>
                                            <input
                                                type="file"
                                                accept="image/png, image/gif, image/jpeg"
                                                name="thumbnail"
                                                onChange={handleFileChange}
                                                value={fileInputState}
                                                required
                                            />
                                        </label>
                                    </div>
                                </div>
                                {filePreview?.thumbnail ? (
                                    <div className="create_post_img__1">
                                        <div
                                            className="cross"
                                            title="click to discard image"
                                            onClick={() => {
                                                setFilePreview((prev) => ({ ...prev, thumbnail: "" }))
                                                setFileRawData((prev) => ({ ...prev, thumbnail: "" }))
                                            }}
                                        >
                                            x
                                        </div>
                                        <img
                                            className="height_max img-responsive"
                                            src={filePreview?.thumbnail || ""}
                                            alt="Select thumbnail"
                                        />
                                    </div>
                                ) : (
                                    ""
                                )}

                                <label className="fs-14 mt-2">
                                    Description <span className="text-danger">*</span>
                                </label>
                                {editorLoaded ? (
                                    <div className="mt-1">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={postText?.postDescription || ""}
                                            config={{
                                                removePlugins: ["MediaEmbed"],
                                                placeholder: "Type the description here!",
                                                link: {
                                                    // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                                    addTargetToExternalLinks: true,
                                                    decorators: [
                                                        {
                                                            mode: "manual",
                                                            label: "External Link",
                                                            attributes: {
                                                                target: "_blank",
                                                            },
                                                        },
                                                    ],
                                                },
                                            }}
                                            onReady={(editor) => {
                                                // You can store the "editor" and use when it is needed.
                                                //console.log('Editor is ready to use!', editor)
                                            }}
                                            onChange={(event, editor) => {
                                                console.log("editrData", editor.getData())
                                                setPostText((editObj) => ({
                                                    ...editObj,
                                                    postDescription: editor.getData(),
                                                }))
                                            }}
                                            onBlur={(event, editor) => {
                                                // //console.log( 'Blur.', editor )
                                            }}
                                            onFocus={(event, editor) => {
                                                // //console.log( 'Focus.', editor )
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <SimpleCircle />
                                    </div>
                                )}

                                {showTitle && (
                                    <>
                                        <label className="post_label fs-14">HashTags :</label>
                                        <input
                                            type="text"
                                            className="inputs w-100 mt-2"
                                            name="keywords"
                                            onChange={handleChange}
                                            value={postText?.keywords || ""}
                                            placeholder="Enter hash tags"
                                        />
                                    </>
                                )}

                                <div className="">
                                    {filePreview?.eventVideo ? (
                                        <>
                                            <div className="create_post_img__1">
                                                <div
                                                    className="cross"
                                                    title="click to discard image"
                                                    onClick={() => {
                                                        setFilePreview((prev) => ({
                                                            ...prev,
                                                            eventVideo: "",
                                                        }))
                                                        setFileRawData((prev) => ({
                                                            ...prev,
                                                            eventVideo: "",
                                                        }))
                                                    }}
                                                >
                                                    x
                                                </div>

                                                <video
                                                    id={filePreview?.eventVideo || ""}
                                                    key={filePreview?.eventVideo || ""}
                                                    controls
                                                    controlsList="nodownload"
                                                >
                                                    <source
                                                        src={filePreview?.eventVideo || ""}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="post_action my-3">
                                    <div className="modal_image_input_1">
                                        <label title="Click to select event video. You can upload it later">
                                            <>
                                                <VideocamIcon />
                                                <span className="m_none">Select Event Video</span>
                                            </>
                                            <input
                                                type="file"
                                                name="eventVideo"
                                                onChange={handleFileChange}
                                                value={fileInputState}
                                                accept=".mp4, .mov, .webm, .m4a, .m4v, .avi"
                                            />
                                        </label>
                                    </div>

                                    {loaderSwitch ? (
                                        <div className="loader_text">
                                            {waitingLoader && (
                                                <div className="waiting_msg shadow_1">
                                                    This will take time, please wait.
                                                </div>
                                            )}
                                            <SimpleCircle />
                                        </div>
                                    ) : (
                                        <>
                                            {createPost ? (
                                                <button
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                    className="btn cmn_btn"
                                                >
                                                    <PublishIcon /> Save as Draft
                                                </button>
                                            ) : (
                                                <button type="button" className="btn cmn_btn" disabled>
                                                    <PublishIcon /> Save as Draft
                                                </button>
                                            )}

                                            {createPost && (
                                                <div
                                                    className="cancle_post h-underline"
                                                    onClick={clearPost}
                                                >
                                                    Discard
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </form>
                        </div>

                        {isCopyLink && postIdx && (
                            <div className="p-3">
                                {isCopyLink + `/post/${postIdx}`}
                                <CopyLink link={`/post/${postIdx}`} />
                            </div>
                        )}

                        {shareDial && postIdx && (
                            <CustomizedSnackbars
                                type={0}
                                msg={"Your post uploaded succefully"}
                            />
                        )}
                        {errMsg && <CustomizedSnackbars type={1} msg={errMsg} />}
                    </div>
                </>
            )}

            <div class="row mt-5">
                {pageLoader ?
                    <div className="load_body">
                        <LDS />
                    </div> :
                    posts?.length > 0 &&
                    posts?.map((item, i) => (
                        <div class="col-lg-4" key={i}>
                            <PrivatePostCard
                                predirect={"predirect"}
                                post={item}
                                showStatus={true}
                                isOwner={isOwner}
                                publishPost={publishPost}
                            />
                        </div>
                    ))}
                {!pageLoader && posts?.length < 1 &&
                    <div className="mt-3 items_center bg-white shadow_1 br_5 no_post">
                        No Post Found
                    </div>
                }
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    const { domains, isSuccess, isError, isProcessing, createPostStatus } = state.posts
    const { premiumPagePost } = state.premium
    const { data } = state.admin
    return {
        domains,
        isSuccess,
        isError,
        isProcessing,
        createPostStatus,
        data,
        posts: premiumPagePost,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(CreatePremiumPost)


