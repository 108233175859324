// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: "auth/login",
  adminLogin: "admin/login",
  signin: "auth/signin",
  logint: "user/login",
  updateCountryCode: "user/updateCountryCode",
  rotpv: "auth/rotpverificaition",
  isUserExist: "auth/isUserExist",
  passChange: "users/changepassword",
  userStorage: "users/storage",
  forgotPassword: "user/forgot-password",
  resetPassword: "user/reset-password",
  getBlogs: "admin/getblog",
  getFAQ: "faq/getfaq",
  getNews: "news/get",
  getNotifications: "notifications",
  createPost: "posts/genarate",
  events: "posts/events",
  myCanvas: "posts/mycanvas",
  eventsUpdate: "posts/eventsUpdate",
  getPost: "posts/multiplefilters",
  likeComPost: "posts/mostCmdAndLike",
  editPosts: "posts/editPosts",
  getPostDetails: "posts/postdetails",
  getOrgPost: "org/getorgposts",
  getpreposts: "org/getpreposts",
  ppvanalytics: "org/ppvanalytics",
  preAnaPost: "org/preAnaPost",
  orgLogo: "org/orglogo",
  orgBackground: "org/orgbackground",
  getwinner: "posts/getwinner",
  deletePost: "posts/delete",
  getHomePagePosts: "posts/homeposts",
  updateHomePagePosts: "posts/updatehomepost",
  orgPostAction: "posts/orgpostaction",
  streamVideo: "posts/video",
  updateView: "posts/updateView",
  postLike: "social/like",
  getUsers: "users/getusers",
  contact: "services/contactus",
  getUser: "users/getusers?mode=2&userName=",
  userNumberOfPost: "services/numOfPost/",
  userNumberOfFriends: "services/numOfFriends/",
  userNumberOfPhotos: "services/numofphovid/",
  createOrg: "org/createpage",
  getOrganizationDetails: "org/getorgdetails",
  getOrganizationCount: "org/countorg",
  orgMembers: "org/addmembars",
  getOrganizationMember: "org/getmembar",
  getOrganizationFollowers: "org/getfollowers",
  orgFollow: "org/followrequest",
  orgLike: "org/pageLike",
  orgStorage: "org/orgStorage",
  orgUpload: "org/orgimages",
  getOrgAnl: "org/orgsubs",
  orgUpdate: "org/update",
  getOrgMyAnl: "org/myanalytics",
  getHomeData: "page/home",
  adminSubscription: "admin/subscription",
  addSubscription: "users/addSubscription",
  followers: "users/followers",
  addFollow: "users/follow",
  allCreation: "page/allCreation",
  sendCode: "auth/sendcodes",
  sendOTP: "auth/sendcode",
  accountActMang: "auth/deactivateAccount",
  orgSearch: "org/orgSearch",
  addFund: "wallets/addfund",
  getTransactions: "wallets/getTransactions",
  getmembers: "users/getmembers",
  mySpaceUser: "users/myspaceuser",
  findUserName: "users/findByUserName",
  markReadNotification: "notifications/markread",
  analyticsPosts: "posts/rankedposts",
  totalLikes: "social/totallikes",
  totalComments: "social/totalcomment",
  comments: "social/comments",
  favPost: "social/markfavourite",
  postReport: "posts/report",
  sharePost: "posts/sharepost",
  domains: "posts/domains",
  myFriends: "services/friends",
  addFriend: "services/addfriend",
  friendsList: "services/totalFriendList/",
  unFriend: "services/unfriend",
  isFriend: "services/isfriend",
  friendsRequests: "services/friendRequests",
  acceptRequest: "services/acceptFriendRequest",
  sentReqList: "services/sentReqList",
  likeUser: "social/likeview",
  getComments: "social/getcomments",
  replyLike: "social/replylike",
  replyComments: "social/replycomments",
  otpVerification: "users/otpverification",
  blogImage: "admin/blogimage",
  profilePhoto: "users/profilephotos",
  coverPhoto: "users/coverphotos",
  country: "users/country",
  basicUser: "users/getusers",
  settingUser: "users/settingUser",
  updateUser: "users/update",
  language: "language",
  uniqueCheck: "users/uniquecheck",
  deviceToken: "users/deviceToken",
  createPremiumPage: "preOrg/createPage",
  getPremiumPost: "preOrg/createPage",
  getMySpacePost: "posts/mySpaceManagments",
  getTranding: "posts/trending",
  getTopCreations: "posts/topCreation",
  recentPost: "posts/recentPost",
  creators: "posts/creators",
  trending: "posts/trending",
  members: 'users/membar'
};
