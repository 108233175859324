import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { connect } from 'react-redux'
import { Footer, IndexNavbar } from '../../components'
import { getFAQ } from '../../redux/reducers/feqReducer'

// const getFaqUrl = config.url.API_URL + "faq/getfaq"

function HelpCenter(props) {
  const { faqs, isSuccess, isError } = props

  return (
    <>
    <IndexNavbar />
    <div className='container'>
      <div className='row faq_section'>
        <h1>Frequently Asked Questions (FAQ)</h1>
        <div className="">
        
          <Accordion defaultActiveKey="0">

            {faqs?.map((item, index) => {
              return (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>{item.question}</Accordion.Header>
                  <Accordion.Body dangerouslySetInnerHTML={{__html: item.answer}} />
                    {/* {} */}
                  {/* </Accordion.Body> */}
                </Accordion.Item>
              )
            })}

          </Accordion>

        </div>
      </div>
    </div>
    <Footer />
    </>
  )
}

HelpCenter.loadData = (params, search) => (dispatch) => {
    //   const query = queryParse(search)
    const faqData = dispatch(getFAQ())
    return faqData
  }
  const mapStateToProps = (state) => {
    const { isSuccess, isError, data, isProcessing } = state?.faqs
    return { isSuccess, isError, faqs: data, isProcessing }
  }
  const mapDispatchToprops = (dispatch) => {
    return {}
  }
  export default connect(mapStateToProps, mapDispatchToprops)(HelpCenter)
