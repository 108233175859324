import React from "react";
import pro_2 from "./../../../assets/web_img/blog.jpg";
import { Link } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import { getBlogs } from "../../redux/reducers/blogReducer";
import { Footer, IndexNavbar } from "../../components";

function Blogs(props) {
  const { blogs, isSuccess, isError } = props;
  return (
    <>
      <IndexNavbar />
      <div className="container">
        <h2 className="blog_heading mb-4">Our Blogs</h2>

        <div className="row">
          {blogs?.map((item, index) => {
            return (
              <Link
                to={"/blogs/" + item?.slug}
                key={index}
                className="col-lg-4"
              >
                <div className="cmn_box_new blog_main">
                  <div className="blog_content_box2">
                    <div className="col-sm-12">
                      <div className="row">
                        <div className="blog_icon">
                          <img
                            src={
                              item?.defaultImage ? item?.defaultImage : pro_2
                            }
                            alt="banner"
                            className="img-responsive"
                          />
                        </div>

                        <div>
                          <h1 className="cl_b mt-2 blog_title">
                            {item?.title}
                          </h1>
                          <pre className="cl_b short_des">
                            {item?.shortDescription}
                          </pre>
                          <div className="fs_12 cl_g">
                            {moment(item?.createdAt).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
}
Blogs.loadData = (params, search) => async (dispatch) => {
  //   const query = queryParse(search);
  const blogsData = await dispatch(getBlogs());
  return blogsData;
};
const mapStateToProps = (state) => {
  const { isSuccess, isError, data, isProcessing } = state.blogs;
  return { isSuccess, isError, blogs: data, isProcessing };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
