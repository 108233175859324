import React, { useState } from 'react'
import { SimpleCircle } from '../../components';

function CreateSubscriptionForm(props) {
    const {
        handleChange,
        handleSubmit,
        values,
        errors,
        isSubmit,
        setValues,
        isAdd,
        setIsAdd,
        setIsSubmit
    } = props

    const [inputfocus, setInputfocus] = useState({});
    console.log("values", values)

    return (
        <>
            <form onSubmit={handleSubmit} className="row create_sub_form">
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <label htmlFor="email">Plan Name :</label>
                    <input
                        type="text"
                        className="form-control form-control-xl login mt-1"
                        autoComplete="off"
                        placeholder="Enter Plan name here"
                        name="planName"
                        onFocus={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: true,
                            }))
                        }
                        onBlur={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: false,
                            }))
                        }
                        onChange={handleChange}
                        value={values?.planName || ""}
                        required
                    />
                    <div className="errors">{!inputfocus?.email && errors?.email}</div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                    <label htmlFor="type">Plan Mode :</label>
                    <select
                        // defaultValue={[colourOptions[2], colourOptions[3]]}
                        name="mode"
                        className="form-control"
                        onFocus={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: true,
                            }))
                        }
                        onBlur={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: false,
                            }))
                        }
                        placeholder="select plan mode"
                        onChange={handleChange}
                        value={values?.mode || ""}
                        required
                    >
                        <option value="">Select plan mode</option>
                        <option value="view">view</option>
                        <option value="create">create</option>
                    </select>

                    <div className="errors">{!inputfocus?.mode && errors?.mode}</div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                    <label htmlFor="type">Plan Type :</label>
                    <select
                        // defaultValue={[colourOptions[2], colourOptions[3]]}
                        name="type"
                        className="form-control"
                        onFocus={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: true,
                            }))
                        }
                        onBlur={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: false,
                            }))
                        }
                        placeholder="select plan type"
                        onChange={handleChange}
                        value={values?.type || ""}
                        required
                    >
                        <option value="">Select plan type</option>
                        <option value="users">Users</option>
                        <option value="org">Organizations</option>
                        <option value="ppv">Pay per view</option>
                    </select>

                    <div className="errors">{!inputfocus?.mode && errors?.mode}</div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                    <label htmlFor="plan code">Plan Code :</label>
                    <input
                        type="text"
                        className="form-control form-control-xl login mt-1"
                        autoComplete="off"
                        placeholder="Enter plan code here"
                        name="planCode"
                        onFocus={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: true,
                            }))
                        }
                        onBlur={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: false,
                            }))
                        }
                        onChange={handleChange}
                        value={values?.planCode || ""}
                        required
                    />
                    <div className="errors">{!inputfocus?.planCode && errors?.planCode}</div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                    <label htmlFor="price">Price :</label>
                    <input
                        type="number"
                        className="form-control form-control-xl login mt-1"
                        autoComplete="off"
                        placeholder="Enter price here"
                        name="price"
                        min={0}
                        onFocus={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: true,
                            }))
                        }
                        onBlur={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: false,
                            }))
                        }
                        onChange={handleChange}
                        value={values?.price || ""}
                        required
                    />
                    <div className="errors">{!inputfocus?.price && errors?.price}</div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                    <label htmlFor="storage">Storage (MB):</label>
                    <input
                        type="number"
                        min={0}
                        className="form-control form-control-xl login mt-1"
                        autoComplete="off"
                        placeholder="Please enter in bytes"
                        name="storage"
                        onFocus={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: true,
                            }))
                        }
                        onBlur={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: false,
                            }))
                        }
                        onChange={handleChange}
                        value={!isAdd ? parseInt(values?.storage) / (1024 * 1024) : values?.storage || ""}
                        required
                    />
                    <div className="errors">{!inputfocus?.storage && errors?.storage}</div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
                    <label htmlFor="Plan Duration">Plan Duration (Day) :</label>
                    <input
                        type="number"
                        min={0}
                        className="form-control form-control-xl login mt-1"
                        autoComplete="off"
                        placeholder="Please enter storage space here"
                        name="duration"
                        onFocus={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: true,
                            }))
                        }
                        onBlur={(e) =>
                            setInputfocus((inputfocus) => ({
                                ...inputfocus,
                                [e.target.name]: false,
                            }))
                        }
                        onChange={handleChange}
                        value={values?.duration || ""}
                        required
                    />
                    <div className="errors">{!inputfocus?.duration && errors?.duration}</div>
                </div>

                <div className="col-lg-12 mt-2">
                    <label htmlFor="plan description">Plan Description :</label>

                    <textarea
                        className="form-control mt-2"
                        rows="3"
                        placeholder="Enter plan description"
                        name="planDescription"
                        onChange={handleChange}
                        value={values?.planDescription || ""}
                        required
                    ></textarea>
                </div>



                <div className="col-lg-12 d-flex gap-2">
                    {isSubmit ?
                        <div className="btn btn-primary mt-4">
                            <SimpleCircle />
                        </div>
                        :
                        <button type='submit' className="btn btn-primary mt-4">Submit</button>
                    }
                    <button
                        type='button'
                        className="btn btn-info text-white mt-4"
                        onClick={() => {
                            setValues({})
                            setIsSubmit(false)
                        }}
                    >
                        Clear
                    </button>

                    {!isAdd && <button
                        type='button'
                        className="btn btn-danger text-white mt-4"
                        onClick={() => {
                            // setValues({})
                            setIsAdd(true)
                            setIsSubmit(false)
                        }}
                    >
                        Cancel Edit
                    </button>
                    }

                </div>
            </form>
        </>
    )
}

export default CreateSubscriptionForm