import React, { useEffect, useState } from 'react'
import { CustomizedSnackbars, SimpleCircle } from '../../components'
import Select from "react-select"
import { AllSkills } from '../BasicSettings'
import { connect, useDispatch } from 'react-redux'
import { getRequestHandler, patchRequestHandler } from '../../redux/reducers/requestHandler.reducer'
import { LDS } from '../../components/Loaders/SimpleCircle'

const UpdateProfile = (props) => {
    const { open, setOpen, basic, callback } = props
    const [succesMsg] = useState("Settings saved successfully")
    const [success, setSuccess] = useState(false)
    const [values, setValues] = useState(basic)
    const [loadingStatus, setLoadingStatus] = useState(false)

    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedSkills, setSelectedSkills] = useState([])
    const [selectedInterest, setSelectedInterest] = useState([])
    const [options, setOptions] = useState([])
    const dispatch = useDispatch()
    const [initialLoad, setInitialLoad] = useState(true)

    const getLanguage = async (e) => {
        let req = { url: 'language' }
        const response = await dispatch(getRequestHandler(req))
        response.data.responseData.map(item =>
            setOptions((options) => [
                ...options,
                { value: item.language, label: item.language },
            ]))
    }

    const getUser = async () => {
        let req = {
            params: {},
            action: 'ACTION_GET_BASIC_DETAILS',
            url: 'settingUser'
        }
        const reposnse = await dispatch(getRequestHandler(req))
        setValues(reposnse?.data?.responseData)
    }
    const handleSubmit = async e => {
        try {
            e.preventDefault()
            setSuccess(false)
            setLoadingStatus(true)


            let defLang = {
                defaultLanguage: values.defaultLanguage,
            }
            if (selectedOptions.length > 0)
                defLang = {
                    defaultLanguage: selectedOptions,
                }

            let intr = {
                interest: values?.interest
            }
            if (selectedInterest?.length > 0)
                intr = {
                    interest: selectedInterest
                }
            let skill = {
                skills: values?.skills
            }
            if (selectedSkills?.length > 0)
                skill = {
                    skills: selectedSkills
                }

            let data = Object.assign(values, defLang, intr, skill)
            let req = {
                url: 'updateUser',
                data: data,
                // action: 'UPDATE_PROFILE_MYSPACE'
            }
            const response = await dispatch(patchRequestHandler(req))
            if (props?.callback) callback()
            if (response.data.status === 200)
                setTimeout(() => {
                    setLoadingStatus(false)
                    setSuccess(true)
                }, 500)
        } catch (err) {
            console.error(err)
        }
    }

    const handleChange = async (e) => {


        setValues((values) => ({
            ...values,
            [e.target.name]: e.target.value,
        }))
    }



    const handleSelectChange = (e) => {
        setSelectedOptions(e.map((item, i) => item.value))
    }

    useEffect(() => {
        getUser()
        getLanguage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (basic)
            setInitialLoad(false)
    }, [basic])

    useEffect(() => {
        console.log("selectedInterest", selectedInterest)
    }, [selectedInterest])
    return (
        <>
            {open &&
                <div className="popup_newmyspace t_left">
                    <div
                        className="popup_in"

                    >
                        <div class="c_pointer close_section" onClick={() => setOpen(false)}>
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark post_icons" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
                            </path>
                            </svg>
                        </div>
                        <h3 className="mt-2 text-uppercase text-center">Update <span className="text-success">Profile</span> </h3>
                        {initialLoad ?
                            <div
                                className='d-flex justify-content-center mt-3'
                            ><LDS /></div> :
                            <div className="container">

                                <div
                                    id="basic"
                                    className="row bg_allp3 justify-content-center settings_row border_1 mt-0 border_r5 p-0"
                                >
                                    <div className="col-lg-11 pb-4">

                                        {/* <div className="mt-3">Profile</div> */}
                                        <form onSubmit={handleSubmit} className="mt-3">
                                            <div className="row justify-content-center">
                                                <div className="col-lg-10">

                                                    <div className="mt-4" id="YourIntro">
                                                        <div className="w-100">
                                                            <label htmlFor="last name" className="log-input-title">
                                                                About Me
                                                            </label>
                                                            {/* <div className="mb-3 cl_sec fs_s">Required</div> */}

                                                            <textarea
                                                                className="form-control form-control-xl mt-2"
                                                                placeholder="About information"
                                                                name="bio"
                                                                rows="5"
                                                                onChange={handleChange}
                                                                value={values?.bio || ""}
                                                            ></textarea>
                                                            <div className="mt-4 fs_choto cl_gray info">
                                                                <div className="info_title">Personal Information</div>
                                                                This is the first thing potential Allphanes will see
                                                                when they land on your page, so make sure you paint a
                                                                compelling picture of how they can join you on this
                                                                journey.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mt-4" id="YourIntro">
                                                        <div className="w-100">
                                                            <label htmlFor="last name" className="log-input-title">
                                                                Experience:
                                                            </label>
                                                            {/* <div className="mb-3 cl_sec fs_s">Required</div> */}

                                                            <textarea
                                                                className="form-control form-control-xl mt-2"
                                                                placeholder="Your Experience"
                                                                name="experience"
                                                                onChange={handleChange}
                                                                rows="5"
                                                                value={values?.experience || ''}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    {/* <IntroVideo /> */}

                                                    <div className="mt-4">
                                                        <div className="w-100">
                                                            <label
                                                                htmlFor="Default language"
                                                                className="log-input-title"
                                                            >
                                                                {" "}
                                                                Default Language
                                                            </label>

                                                            {/* {defaultLanguage}
                       */}                                  {basic?.defaultLanguage?.length > 0 ?
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={basic?.defaultLanguage?.map(item => ({ value: item, label: item }))}
                                                                    name="languages"
                                                                    options={options}
                                                                    className="basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    value={options.find(
                                                                        (obj) => obj.value === selectedOptions
                                                                    )}
                                                                    onChange={handleSelectChange}
                                                                    // trim={true}
                                                                    placeholder="Choose languages"
                                                                /> :
                                                                <Select
                                                                    isMulti
                                                                    // defaultValue={basic?.defaultLanguage?.map(item => ({value : item, label: item}))}
                                                                    name="languages"
                                                                    options={options}
                                                                    className="basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    value={options.find(
                                                                        (obj) => obj.value === selectedOptions
                                                                    )}
                                                                    onChange={handleSelectChange}
                                                                    // trim={true}
                                                                    placeholder="Choose languages"
                                                                />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="mt-4">
                                                        <div className="w-100">
                                                            <label
                                                                htmlFor="Default language"
                                                                className="log-input-title"
                                                            >
                                                                {" "}
                                                                Skills
                                                            </label>

                                                            {/* {defaultLanguage}*/}
                                                            {basic?.skills.length > 0 ?
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={basic?.skills?.map(item => ({ value: item, label: item }))}
                                                                    // defaultValue={tempLang}
                                                                    name="skills"
                                                                    options={AllSkills}
                                                                    className="basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    value={AllSkills.find(
                                                                        (obj) => obj.value === selectedSkills
                                                                    )}
                                                                    onChange={e => setSelectedSkills(e.map((item, i) => item.value))}
                                                                    // trim={true}
                                                                    placeholder="Choose skills"
                                                                /> : <Select
                                                                    isMulti
                                                                    name="skills"
                                                                    options={AllSkills}
                                                                    className="basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    value={AllSkills.find(
                                                                        (obj) => obj.value === selectedSkills
                                                                    )}
                                                                    onChange={e => setSelectedSkills(e.map((item, i) => item.value))}
                                                                    // trim={true}
                                                                    placeholder="Choose skills"
                                                                />}
                                                        </div>
                                                    </div>

                                                    <div className="mt-4">
                                                        <div className="w-100">
                                                            <label
                                                                htmlFor="Default language"
                                                                className="log-input-title"
                                                            >
                                                                {" "}
                                                                Interest
                                                            </label>
                                                            {basic?.interest?.length > 0 ?
                                                                <Select
                                                                    isMulti
                                                                    defaultValue={basic?.interest?.map(item => ({ value: item, label: item }))}
                                                                    name="interest"
                                                                    options={AllSkills}
                                                                    className="basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    value={AllSkills.find(
                                                                        obj => obj.value === selectedInterest
                                                                    )}
                                                                    onChange={e => setSelectedInterest(e.map((item, i) => item.value))}
                                                                    placeholder="Choose interest"
                                                                /> :
                                                                <Select
                                                                    isMulti
                                                                    name="interest"
                                                                    options={AllSkills}
                                                                    className="basic-multi-select mt-2"
                                                                    classNamePrefix="select"
                                                                    value={AllSkills.find(
                                                                        obj => obj.value === selectedInterest
                                                                    )}
                                                                    onChange={e => setSelectedInterest(e.map((item, i) => item.value))}

                                                                    placeholder="Choose interest"
                                                                />
                                                            }
                                                        </div>
                                                    </div>


                                                    <div className="mt-4">
                                                        <div className="w-100">
                                                            <label htmlFor="tagline" className="log-input-title">
                                                                Tagline{" "}
                                                            </label>

                                                            <input
                                                                type="text"
                                                                className="form-control form-control-xl mt-2"
                                                                autoComplete="on"
                                                                placeholder="Enter your tagline"
                                                                name="tagline"
                                                                onChange={handleChange}
                                                                value={values?.tagline || ""}
                                                            />
                                                            <div className="fs_choto cl_gray info mt-2">
                                                                <span className="info_title">Tag Line: </span>
                                                                <span >
                                                                    Add a 50 character tag line. Eg. 'Bengali Poet' or 'Rabindra Sangeet Singer' or 'Painter'. This Tag line will be shown in Member page below Profile Picture.

                                                                </span>
                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div className="mt-3">
                                                        {/* <button type='submit' className='btn btn-primary mt-5' disabled={btnDisable}>Submit</button> */}
                                                        {loadingStatus ? (
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary mt-3 w_100x"
                                                            >
                                                                <SimpleCircle />
                                                            </button>
                                                        ) : (
                                                            <>
                                                                <button
                                                                    title='Click to submit'
                                                                    type="submit"
                                                                    className="btn btn-primary mt-3">
                                                                    Update Profile
                                                                </button>
                                                                {success && (
                                                                    <CustomizedSnackbars
                                                                        type={0}
                                                                        msg={succesMsg}
                                                                        sclose={() => setSuccess(false)}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => {
    console.log("stateoRgpostcreationposts", state)
    const { basic } = state.settings
    return { basic }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile)