import moment from 'moment';
import React, { useState } from 'react'
import { Table } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import ManagementLeftNav from '../../components/Navbars/ManagementLeftNav';
import { addSubscriptionPlan, deleteSubscriptionPlan, getSubscriptionPlan, updateSubscriptionPlan } from '../../redux/reducers/admin.reducer';
import CreateSubscriptionForm from './CreateSubscriptionForm';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import Button from '@mui/material/Button';
import { DeleteAction } from '../../components/Postsk';

function AdminSubscriptionManagement(props) {
    const { plans } = props
    const [values, setValues] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [isAdd, setIsAdd] = useState(true)
    const dispatch = useDispatch()
    const handleChange = e => {
        setValues(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }
    const handleSubmit = async e => {
        try {
            e.preventDefault()
            setIsSubmit(true)
            const params = {
                ...values,
                storage: parseInt(values.storage) * 1024 * 1024
            }
            const response = await dispatch(isAdd ? addSubscriptionPlan(params) : updateSubscriptionPlan(params))
            setIsAdd(true)
            setIsSubmit(false)

            if (response?.data?.ack === 1) setValues({})
        } catch (error) {
            console.error(error)
        }
    }

    const handleEdit = index => {
        setValues(plans[index])
        setIsAdd(false)
    }
    const handleDelete = async id => {
        try {
            const params = {
                _id: id
            }
            await dispatch(deleteSubscriptionPlan(params))
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-lg-2">
                        <ManagementLeftNav />
                    </div>
                    <div className="col-lg-10 bg-white" style={{ minHeight: "100vh" }}>
                        <div className="mt-2 px-3 py-2 pb-4 mt-3 bg_allp">
                            <h4>{isAdd ? "Add" : "Edit"} Subcriptions Plans</h4>
                            <CreateSubscriptionForm
                                values={values}
                                setValues={setValues}
                                handleChange={handleChange}
                                handleSubmit={handleSubmit}
                                isSubmit={isSubmit}
                                isAdd={isAdd}
                                setIsAdd={setIsAdd}
                                setIsSubmit={setIsSubmit}
                            />
                        </div>
                        <div className="mt-2 py-2 pb-4 mt-3">
                            <h4>Subcriptions Plans</h4>
                            <Table striped bordered hover responsive="sm">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Plan Name</th>
                                        <th>Mode</th>
                                        <th>Type</th>
                                        <th>Price</th>
                                        <th>Duration (day)</th>
                                        <th>Code</th>
                                        <th>Storage (MB)</th>
                                        <th>Description</th>
                                        <th>CreatedAt</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {plans?.map((items, index) =>
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{items?.planName}</td>
                                            <td>{items?.mode}</td>
                                            <td>{items?.type}</td>
                                            <td>{items?.price}</td>
                                            <td>{items?.duration}</td>
                                            <td>{items?.planCode}</td>
                                            <td>{parseInt(items?.storage) / (1024 * 1024)}</td>
                                            <td>{items?.planDescription}</td>
                                            <td>{moment(items?.createdAt).format('YYYY-MM-DD h:mm:ss a')}</td>
                                            <td className='d-flex align-items-center justify-content-center gap-2'>
                                                <Button color="success" onClick={() => handleEdit(index)}><ModeEditIcon /></Button>
                                                <DeleteAction
                                                    buttonText={<DeleteOutlineSharpIcon />}
                                                    execute={handleDelete}
                                                    params={items?._id}
                                                    alertTitle={"Delete Subscription plan"}
                                                    alertMsg={"Are you sure delete this plan. Please check it again before deleting"}
                                                />
                                            </td>
                                        </tr>
                                    )
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

AdminSubscriptionManagement.loadData = (params, search) => async (dispatch) => {
    return dispatch(getSubscriptionPlan({ sort: "createdAt", stype: -1 }));
};
const mapStateToProps = (state) => {
    const { isSuccess, isError, isProcessing, data } = state.admin;
    console.log("adminstar", state)
    return {
        isSuccess,
        isError,
        isProcessing,
        plans: data,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminSubscriptionManagement);