import React, { useState, useEffect } from 'react'
import NewsTicker from "react-advanced-news-ticker";

import axios from "axios"
import { config } from "../../constant"
import { Link } from 'react-router-dom'
import moment from 'moment'
const API_URL = config.url.API_URL + "news/"

function NewsList() {
    const [values, setValues] = useState([])

    const getData = async () => {
        try {
            const response = await axios.get(API_URL + "get")
            setValues(response.data.responseData)
            console.log("NewsList", response)
        } catch (err) {
            console.error(err)
        }
    }

    const NewsListHtml = () => {
        return <NewsTicker
            rowHeight={142}
            maxRows={2}
            speed={600}
            //direction = {Directions.UP}
            duration={4000}
            autoStart={true}
            pauseOnHover={false}
            id="myId"
            className="myClassName1 myClassName2"
            style={{ marginTop: "34" }}
        >
            {values?.map(item =>
                <div key={item._id}>
                    <Link to={"/news/" + item?.slug}>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="news_img_box">
                                    <img
                                        src={item?.defaultImage || '/assets/web_img/hands.jpg'}
                                        alt={item?.title}
                                        className="img-responsive"
                                    />
                                </div>
                            </div>
                            <div className="col-sm-8">
                                <h2>{item?.title}</h2>
                                <p>{item?.shortDescription}</p>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" /></g><g><path d="M19,4h-1V2h-2v2H8V2H6v2H5C3.89,4,3.01,4.9,3.01,6L3,20c0,1.1,0.89,2,2,2h14c1.1,0,2-0.9,2-2V6C21,4.9,20.1,4,19,4z M19,20 H5V10h14V20z M9,14H7v-2h2V14z M13,14h-2v-2h2V14z M17,14h-2v-2h2V14z M9,18H7v-2h2V18z M13,18h-2v-2h2V18z M17,18h-2v-2h2V18z" /></g></svg>
                                    {moment(item?.createdAt).format("LL")}
                                </span>
                                <small>Read Full Article</small>
                            </div>
                        </div>
                    </Link>
                </div>
            )}
        </NewsTicker>
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className="trend-sec latest_news">
            {/* <NewsListHtml /> */}
            <div className="trend-title mb-2 latest_news">
                <img
                    src={'/assets/web_img/news_icon.png'}
                    alt="dd"
                    width="25px"
                />
                Latest News</div>

            <NewsListHtml />
        </div>
    )
}

export default NewsList