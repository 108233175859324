import React, { useEffect, useState } from "react"
import moment from "moment"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { CustomizedSnackbars, Footer, IndexNavbar } from "../../components"
import api from "../../config/api"
import config from "../../config/config"
import { addSubscriptions } from "../../redux/reducers/admin.reducer"
import { getPostDetails } from "../../redux/reducers/postReducer"
import { Link } from 'react-router-dom'

function SinglePrivatePostDetails(props) {
  const { postDetails } = props
  const [isFActi] = useState(true)
  const [errMsg, setErrMsg] = useState("")
  // const [successMsg, setSuccessMsg] = useState(false)
  const dispatch = useDispatch()
  const param = useParams()
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const addSubs = async (params) => {
    try {
      setErrMsg("")
      // setSuccessMsg(false)
      const response = await dispatch(addSubscriptions(params))
      console.log(response)
      await dispatch(
        getPostDetails({
          pid: param?.id,
        })
      )
      if (response?.data?.ack === 0) return setErrMsg(response?.data?.message)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (errMsg) setTimeout(() => setErrMsg(""), [5000])
  }, [errMsg])
  return (
    <>
      <IndexNavbar />
      <div className="container">
        <div className="row p-4">
          <div className="col-sm-6">
            <div className="rounded p-3 pt-0 pb-0 ppv_images ppv_videos_image">
              <h1>
                <strong>{postDetails?.post?.postTitle}</strong>
              </h1>
              <h5 className="mb-0 mt-2 position-relative release_date mb-1">
                Release Date:
              </h5>
              <h2 className="mt-0 text-success">
                {moment(postDetails?.post?.publishDate).format("DD-MM-YYYY")}
              </h2>

              {moment() > moment(postDetails?.post?.publishDate) &&
                postDetails?.isSubscribed &&
                param?.id ? (
                <video width="" height="350" controls controlsList="nodownload">
                  <source
                    src={`${config.API_URL + api.streamVideo}?pid=${param?.id}`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <div>
                  <p className="bg-danger text-white p-1 rounded mb-2 d-inline">You can watch this video after release date.</p>
                </div>
              )}
              <div className="">
                <div className="row">
                  <div className=" rounded p-3  ppv_images">
                    <img
                      className="img-responsive"
                      src={postDetails?.post?.thumbnail}
                      alt={postDetails?.post?.thumbnail}
                    />
                    {/* <iframe width="100%" height="200" src="https://www.youtube.com/embed/uCiFfVY12kU" title="YouTube video player" frameborder="0" allow="accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture web-share" allowfullscreen></iframe> */}
                  </div>
                  <h4>About Post</h4>
                  <div className="pt-3"
                    dangerouslySetInnerHTML={{
                      __html: postDetails?.post?.postDescription,
                    }}
                  />

                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">

            {!postDetails?.isSubscribed && (
              <>
                <div className="col-sm-12 text-center mt-4">
                  <div className="bg-light border rounded-3 p-4">
                    <h3>Payment options to see content.</h3>
                    <p>
                      For Pay-Per-View (PPV) you will get access to this particular content for 1 day only. If you select monthly
                      subscription you will get access to ALL private/premium content for 1 month. Monthly subscriptions
                      will automatically renew every month, but you can cancel it in My Subscription page. When you cancel
                      you will not be charged for following month onwards.
                    </p>
                    <ul className="plan_list">
                      <li className="card shadow-sm m-2">
                        <div className="plan_top_bg bg-success text-white">
                          <h4 className="mb-0">For</h4>
                          <h5 className="mb-0">1 Day</h5>
                          <h1 className="mb-0">₹{postDetails?.post?.ppv}</h1>
                        </div>
                        <div className="plan_list_li">
                          <ul className="m-0 p-2">
                            <li className="d-block p-1">
                              <img
                                className="float-start mt-1 plan_icon"
                                src="/assets/web_img/tick.png"
                                alt="private post"
                              />
                              Pay per view
                            </li>
                            <li className="d-block p-1">
                              <img
                                className="float-start mt-1 plan_icon"
                                src="/assets/web_img/tick.png"
                                alt="private post"
                              />
                              Duration: 1 Day
                            </li>
                            <li className="d-block p-1">
                              <img
                                className="float-start mt-1 plan_icon"
                                src="/assets/web_img/tick.png"
                                alt="private post"
                              />
                              This Post Only
                            </li>
                            <li className="d-block p-1">
                              <img
                                className="float-start mt-1 plan_icon"
                                src="/assets/web_img/close2.png"
                                alt="private post"
                              />
                              All Posts of this Organization
                            </li>
                          </ul>
                        </div>
                        {moment() < moment(postDetails?.post?.publishDate) ? (
                          <>
                            <p className="release_date_msg text-white p-2 bg-danger rounded-3">
                              You can buy ppv after release date
                            </p>
                          </>
                        ) : isFActi ?
                          <Link to={`/underconstruction/`}
                            className="btn btn-success mb-3"
                          >
                            Buy Now
                          </Link> :
                          <>
                            <button
                              type="button"
                              class="btn btn-success mb-3"
                              onClick={() =>
                                addSubs({
                                  mode: "view",
                                  type: "ppv",
                                  userId: token,
                                  postId: postDetails?.post?._id,
                                })
                              }
                            >
                              Buy Now
                            </button>
                          </>
                        }
                      </li>
                      <li className="card shadow-sm m-2">
                        <div className="plan_top_bg bg-success text-white">
                          <h4 className="mb-0">For</h4>
                          <h5 className="mb-0">30 Days</h5>
                          <h1 className="mb-0">₹{postDetails?.post?.pageId?.monthlySubscriptionPrice}</h1>
                        </div>
                        <div className="plan_list_li">
                          <ul className="m-0 p-2">
                            <li className="d-block p-1">
                              <img
                                className="float-start mt-1 plan_icon"
                                src="/assets/web_img/tick.png"
                                alt="private post"
                              />
                              Organization Subscripition
                            </li>
                            <li className="d-block p-1">
                              <img
                                className="float-start mt-1 plan_icon"
                                src="/assets/web_img/tick.png"
                                alt="private post"
                              />
                              Duration: 30 Days
                            </li>
                            <li className="d-block p-1">
                              <img
                                className="float-start mt-1 plan_icon"
                                src="/assets/web_img/tick.png"
                                alt="private post"
                              />
                              All Posts of this Organization
                            </li>
                          </ul>
                        </div>
                        {isFActi ?
                          <Link to={`/underconstruction/`}
                            className="btn btn-success mb-3"
                          >
                            Buy Now
                          </Link> :
                          <button
                            type="button"
                            class="btn btn-success mb-3"
                            onClick={() =>
                              addSubs({
                                mode: "view",
                                type: "org",
                                userId: token,
                                pageId: postDetails?.post?.pageId?._id,
                              })
                            }
                          >
                            Buy Now
                          </button>
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>



        </div>
      </div>
      {errMsg && <CustomizedSnackbars type={1} msg={errMsg} />}

      <Footer />
    </>
  )
}
SinglePrivatePostDetails.loadData = (params, search) => async (dispatch) => {
  const id = params.split("/")[2]
  return dispatch(
    await getPostDetails({
      pid: id,
    })
  )
  // return res
}
const mapStateToProps = (state) => {
  const { postDetails } = state.posts
  console.log("adminstar", state)
  return {
    postDetails,
  }
}

export default connect(mapStateToProps, null)(SinglePrivatePostDetails)