import React, { useState, useEffect, useRef } from "react"
import Compressor from "compressorjs"
import AddLinkIcon from "@mui/icons-material/AddLink"
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto"
import VideocamIcon from "@mui/icons-material/Videocam"
import InfoIcon from "@mui/icons-material/Info"
import PublishIcon from "@mui/icons-material/Publish"
import select_language from "../../../assets/web_img/select_language.png"
import select_domain from "../../../assets/web_img/select_domain.png"
import select_subdomain from "../../../assets/web_img/select_subdomain.png"
import give_title from "../../../assets/web_img/give_title.png"
import post_icon from "../../../assets/web_img/post_icon.png"
import welcome_arrow from "../../../assets/web_img/welcome_arrow22.png"
import welcome_arrow1 from "../../../assets/web_img/welcome_arrow23.png"
import welcome_arrow2 from "../../../assets/web_img/welcome_arrow24.png"
import { Tab, Tabs } from "react-bootstrap"
import { Link } from "react-router-dom"
import CustomizedSnackbars from "../Loaders/Snuckbars"
import SimpleCircle from "../Loaders/SimpleCircle"
import { CopyLink, FileViewery, PostProfilex } from "../AppComponents/Helpers"
import { YouTubeEmbed } from "react-social-media-embed"
import { connect, useDispatch } from "react-redux"
import {
  getRequestHandler,
  postRequestHandler,
  uploadRequestHandler,
} from "../../redux/reducers/requestHandler.reducer"
import { CircularStatic } from "../../../_helpers/Material"
import config from "../../config/config"
import { CheckExtention } from "../../../_helpers/Variables"
import VideoThubnails from "./VideoThubnails"

function CreatePost(props) {
  const { isMember, domains, user, progressSize, createPostThubnails } = props
  const [loadings, setLoadings] = useState({ previewLoader: false })
  const [createPost, setCreatePost] = useState(false)
  const [postText, setPostText] = useState({})
  const [fileInputState, setFileInputState] = useState("")
  const [previewSource, setPreviewSource] = useState("")
  const [selectedFile, setSelectedFile] = useState(null)
  const [errMsg, setErrMsg] = useState("")
  const [fileType, setFileType] = useState("")
  const [waitingLoader, setWaitingLoader] = useState(false)
  const [loaderSwitch, setLoaderSwitch] = useState(props.spinner)
  const [domainId, setDomainId] = useState("")
  const [isThubnailUpload, setIsThubnailUpload] = useState(false)
  const currentUser =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("currentuser"))
      : ""
  const [popUp, setPopUp] = useState(false)
  const [showTitle, setShowTitle] = useState(false)
  const [shareDial, setShareDial] = useState(false)
  const [postIdx, setPostIdx] = useState("")
  const [isCopyLink, setIsCopyLink] = useState("")
  const [isEmbed, setIsEmbed] = useState(false)
  const [embedUrl, setEmbedUrl] = useState({})
  const [editorShow, setEditorShow] = useState(false)
  const [editorLoaded, setEditorLoaded] = useState(false)
  const editorRef = useRef()
  const { Heic2Jpg, CKEditor, ClassicEditor } = editorRef.current || {}
  const dispatch = useDispatch()
  const [totalFileSize, setTotalFileSize] = useState(0)
  const [fileUploadPercent, setFileUploadPercent] = useState(0)

  useEffect(() => {
    console.log("progressEventCal", totalFileSize, progressSize)
    // console.log("progressEvent",progressEvent.loaded)
    setFileUploadPercent(parseInt((progressSize / totalFileSize) * 100))
  }, [selectedFile, totalFileSize, progressSize])
  useEffect(() => {
    console.log("progressEvent", fileUploadPercent)
  }, [fileUploadPercent])
  useEffect(
    () => setEditorShow(/Blogs/.test(postText?.postCategory)),
    [postText]
  )

  useEffect(() => {
    editorRef.current = {
      Heic2Jpg: require("../ImageHelpers").Heic2Jpg,
      // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    }
    setEditorLoaded(true)
  }, [])

  const imageUploader = async () => {
    if (!fileRawData?.videoThumbnil?.name) return
    const data = new FormData()
    // data.append("files", file, file?.name)
    data.append("files", fileRawData?.videoThumbnil, fileRawData?.videoThumbnil?.name)
    setIsThubnailUpload(true)
    let req = {
      data: data,
      url: "blogImage",
      action: 'ACTION_ADD_THUMBNAIL'
    }
    const response = await dispatch(postRequestHandler(req))
    setIsThubnailUpload(false)

    return response.data.responseData
  }

  const init = async () => {
    try {
      let req = {
        url: "domains",
        action: "ACTION_GET_DOMAINS",
      }
      await dispatch(getRequestHandler(req))
    } catch (error) {
      console.error(error)
    }
  }

  const compressImage = (file) => {
    new Compressor(file, {
      quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
      // convertTypes: ['image/heif'],
      success: (compressedResult) => {
        previewFile(compressedResult)
        setSelectedFile(compressedResult)
      },
    })
  }

  const [filePreview, setFilePreview] = useState({})
  const [fileRawData, setFileRawData] = useState({})
  const [fileInputStateT, setFileInputStateT] = useState("")
  const [thubCompShow, setThubCompShow] = useState(false)

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0]
    setFileInputStateT(e.target.value)
    const typeImage = file.type
    if (/image/.test(typeImage)) {
      new Compressor(file, {
        quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          setFileRawData((prev) => ({
            ...prev,
            [e.target.name]: compressedResult,
          }))

        },
      })
    }
  }

  const handleFileInputChange = async (e) => {
    const file = e.target.files[0]
    const typeImage = file.type
    const filesize = Math.round(file.size)
    if (filesize > config.max_video_upload_size) {
      setErrMsg(
        "File upload size exceed the max upload size. Please try with smaller one"
      )
    } else {
      setFileType(fileType)
      setLoadings((prev) => ({ ...prev, previewLoader: true }))

      if (/image/.test(typeImage)) {
        if (/heic|heif/.test(typeImage)) {
          const response = await Heic2Jpg(file)
          let filex = new File([response], "heic.jpg", {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          })
          compressImage(filex)
        } else compressImage(file)
      } else {
        previewFile(file)
        setSelectedFile(file)
      }
      setLoadings((prev) => ({ ...prev, previewLoader: false }))
      setFileInputState(e.target.value)
    }
  }

  const previewFile = (file) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      setPreviewSource(reader.result)
    }
    reader.onerror = () => {
      console.error("AHHHHHHHH!!")
      setErrMsg("Post render failed. File size is too large.")
    }
  }

  const clearImg = () => {
    setFileInputStateT()
    setThubCompShow(false)
    setPreviewSource("")
    setSelectedFile(null)
  }

  const clearPost = () => {
    setThubCompShow(false)
    setPostText({})
    clearImg()
    setCreatePost(false)
    setEmbedUrl({})
    setIsEmbed(false)
    setFilePreview({})
    setFileRawData((prev) => ({}))
  }

  const detectURLs = (message) => {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
    return message.match(urlRegex)
  }

  const handleChange = (e) => {
    e.persist()
    if (e.target.name === "postDescription") {
      let urlCheck = detectURLs(e.target.value)
      if (urlCheck) return false
    }
    setPostText((values) => ({ ...values, [e.target.name]: e.target.value }))
    if (e.target.name === "postCategory") {
      const index = e.target.selectedIndex
      setPostText((prev) => ({ ...prev, postDescription: "" }))
      setDomainId(index)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setLoaderSwitch(!loaderSwitch)
      let galleryType,
        kk = postText.keywords || "",
        ll = ""
      const fileType = selectedFile?.type
      if (/image/.test(fileType)) galleryType = "photos"
      if (/audio/.test(fileType)) galleryType = "audio"
      if (/video/.test(fileType)) galleryType = "video"
      const rex = new RegExp("photos", "g")
      const re = new RegExp("#", "g")
      if (kk !== "") ll = kk.replace(re, "")
      const postC = postText.postCategory || ""
      const postD = postText.postSubDomain || ""
      const pLanguage = postText.language || ""
      const postdescription = postText.postDescription || ""
      const postTitle = postText.postTitle || "Quick Post"
      const data = new FormData()
      // data.append("referenceUserId", currentUser.id)
      if (props?.pageid) data.append("pageId", props?.pageid)
      data.append("postType", props?.postType || "Normal") //shared
      data.append("postMode", "Normal")
      data.append("isSaved", false)
      data.append("postTitle", postTitle)
      rex.test(galleryType)
        ? data.append("image", selectedFile, selectedFile.name)
        : data.append("image", selectedFile)
      data.append("isFile", selectedFile ? true : false)
      data.append("videoThumbnil", filePreview?.videoThumbnil || (createPostThubnails?.length > 0 && createPostThubnails[0]))
      data.append("text", postdescription)
      data.append("postCategory", postC)
      data.append("postSubDomain", postD)
      data.append("language", pLanguage)
      data.append("galleryType", galleryType)
      data.append("keywords", ll)
      data.append("keywords2", kk)
      if (props?.uploadType) data.append("uploadType", props?.uploadType) //shared
      if (isMember) data.append("status", "pending")
      if (isEmbed && embedUrl?.embedType && embedUrl?.embedUrl) {
        data.append("embedType", embedUrl?.embedType)
        data.append("url", embedUrl?.embedUrl)
      }
      let req = {
        url: "createPost",
        data: data,
      }
      if (selectedFile) {
        req.fileSize = selectedFile?.size
        setTotalFileSize(selectedFile?.size)
      }
      // return console.log("fdfd",selectedFile.size)
      const res = await dispatch(uploadRequestHandler(req))
      clearPost()
      setLoaderSwitch(false)
      if (res?.data?.ack === 0) return setErrMsg(res?.data?.message)
      if (props?.getpost) props?.getpost()
      setPostIdx(res?.data?.responseData)
      setIsCopyLink(`https://${window.location.host}`)
      setShareDial(true)
      setErrMsg("")
      if (props?.setOpen) props?.setOpen()
    } catch (err) {
      console.error(err)
      setErrMsg(
        "Post upload failed due to some technical issue. Please try again later."
      )
    }
  }

  function uploadAdapter(loader) {
    return {
      upload: async () => {
        const data = new FormData()
        loader.file.then(async (file) => {
          data.append("files", file)
          let req = {
            data: data,
            url: "blogImage",
          }
          const response = await dispatch(postRequestHandler(req))
          return response.data.responseData
        })

        return
      },
    }
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader)
    }
  }

  // error clearing after 5s
  useEffect(() => {
    if (!errMsg) return
    setTimeout(() => setErrMsg(""), [5000])
  }, [errMsg])

  useEffect(() => {
    if (!isCopyLink) return
    setTimeout(() => setIsCopyLink(""), [10000])
  }, [isCopyLink])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () =>
      !postText?.postCategory || postText?.postCategory === "Normal"
        ? setShowTitle(false)
        : setShowTitle(true),
    [postText?.postCategory]
  )
  useEffect(
    () =>
      setCreatePost(
        postText?.postDescription || previewSource || embedUrl?.embedUrl
          ? true
          : false
      ),
    [createPost, postText.postDescription, previewSource, embedUrl]
  )

  useEffect(() => {
    loaderSwitch
      ? setTimeout(() => {
        setWaitingLoader(true)
      }, [10000])
      : setWaitingLoader(false)
  }, [loaderSwitch])

  useEffect(() => {
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!fileRawData?.videoThumbnil?.name) return console.log("fdfd")
    const kk = async () => {
      const res = await imageUploader()
      setFilePreview(prev => ({ ...prev, videoThumbnil: res }))
    }
    kk()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileRawData])

  useEffect(() => {
    const getFileTypeCode = CheckExtention(fileInputState)
    setThubCompShow(/data:video|data:audio/.test(fileInputState) || getFileTypeCode === 1 ? true : false)
  }, [fileInputState])

  return (
    <>
      <div id="createPosts" className="mt-3">
        <div className="d-flex">
          <div className="dom_title">Tags </div>
          <div className="w-100 bg-danger cpost_select">
            <select name="language" onChange={handleChange}>
              <option className="t_cap" value="English">
                Language
              </option>
              {domains?.language?.length > 0 &&
                domains?.language?.map((item, index) => {
                  return (
                    <option
                      className="t_cap"
                      value={item.language}
                      key={item._id + index}
                    >
                      {item.language}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className="w-100">
            <select name="postCategory" onChange={handleChange}>
              <option className="t_cap" value="Normal">
                Domain
              </option>

              {domains?.domains?.length > 0 &&
                domains?.domains?.map((item, index) => {
                  return (
                    <option
                      className="t_cap"
                      id={item._id}
                      value={item.postCategory}
                      key={item._id + index}
                    >
                      {item.postCategory}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className="w-100">
            <select name="postSubDomain" onChange={handleChange}>
              <option className="t_cap" value="Normal">
                Sub domain
              </option>
              {domainId > 0 &&
                domains?.domains[domainId - 1]?.subDomain.map((item, index) => {
                  return (
                    <option
                      className="t_cap"
                      value={item.postSubCategory}
                      key={item._id + index}
                    >
                      {item.postSubCategory}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>

        <div className="cpost_body">
          <div className="cpost_profile_section">
            <PostProfilex
              className="sub-profile-pic"
              img={user?.user?.profilePhoto}
              alt={user?.user?.showName}
            />
          </div>
          <form onSubmit={handleSubmit} className="cpost_info_sec w-100 p-2">
            {showTitle && (
              <>
                <label className="post_label fs-14">Title :</label>
                <br />
                <input
                  type="text"
                  className="inputs"
                  name="postTitle"
                  onChange={handleChange}
                  value={postText?.postTitle || ""}
                  placeholder="Enter title"
                // required='required'
                />
              </>
            )}
            {editorShow ? (
              editorLoaded ? (
                <div className="mt-3">
                  <CKEditor
                    editor={ClassicEditor}
                    data={postText?.postDescription || ""}
                    config={{
                      removePlugins: ["MediaEmbed"],
                      placeholder: "Type the content here!",
                      extraPlugins: [uploadPlugin],
                      link: {
                        // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                        addTargetToExternalLinks: true,
                        decorators: [
                          {
                            mode: "manual",
                            label: "External Link",
                            attributes: {
                              target: "_blank",
                            },
                          },
                        ],
                      },
                    }}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      //console.log('Editor is ready to use!', editor)
                    }}
                    onChange={(event, editor) => {
                      console.log("editrData", editor.getData())
                      setPostText((editObj) => ({
                        ...editObj,
                        postDescription: editor.getData(),
                      }))
                    }}
                    onBlur={(event, editor) => {
                      // //console.log( 'Blur.', editor )
                    }}
                    onFocus={(event, editor) => {
                      // //console.log( 'Focus.', editor )
                    }}
                  />
                </div>
              ) : (
                <div>
                  <SimpleCircle />
                </div>
              )
            ) : (
              ""
            )}
            {!editorShow && (
              <textarea
                className="inputs mt-3"
                rows="3"
                placeholder="Share your ideas"
                name="postDescription"
                onChange={handleChange}
                value={postText?.postDescription || ""}
              ></textarea>
            )}
            {showTitle && (
              <>
                <label className="post_label fs-14">HashTags :</label>
                <input
                  type="text"
                  className="inputs w-100 mt-2"
                  name="keywords"
                  onChange={handleChange}
                  value={postText?.keywords || ""}
                  placeholder="Enter hash tags"
                // required='required'
                />
              </>
            )}


            {isEmbed && (
              <>
                <div className="social_embed">
                  <div className="d-flex mt-2">
                    {/* Select Ty */}
                    <select
                      name="embedType"
                      onChange={(e) =>
                        setEmbedUrl((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value || "",
                        }))
                      }
                    >
                      <option className="t_cap" value="">
                        Select Type
                      </option>
                      <option className="t_cap" value="youtube">
                        Youtube
                      </option>
                    </select>
                  </div>
                  {embedUrl?.embedType && (
                    <>
                      <input
                        type="text"
                        placeholder="Paste url here"
                        className="inputs w-100 my-2"
                        name="embedUrl"
                        value={embedUrl?.embedUrl || ""}
                        onChange={(e) =>
                          setEmbedUrl((prev) => ({
                            ...prev,
                            [e.target.name]: e.target.value || "",
                          }))
                        }
                      />
                      {embedUrl?.embedUrl && (
                        <>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <YouTubeEmbed
                              url={embedUrl?.embedUrl}
                              width={325}
                              height={220}
                            />
                            {/* https://www.youtube.com/watch?v=HpVOs5imUN0 */}
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
            <div className="create_post_img">
              {previewSource &&
                <>
                  <div className="row mt-3 mb-3">
                    <div className="col-sm-5">
                      {previewSource ? (
                        <>
                          <div
                            className="cross"
                            title="click to discard image"
                            onClick={clearImg}
                          >
                            x
                          </div>

                          <FileViewery link={previewSource} />
                        </>
                      ) : (
                        ""
                      )
                      }

                      {isThubnailUpload ?
                        <>
                          <div className="create_post_img__1">
                            <span className="text-white">
                              Uploading your thumbnail please wait...
                            </span>
                            <SimpleCircle />
                          </div>
                        </> :
                        filePreview?.videoThumbnil ? (
                          <div className="create_post_img__1">
                            <div
                              className="cross"
                              title="click to discard image"
                              onClick={() => {
                                setFilePreview((prev) => ({ ...prev, videoThumbnil: "" }))
                                setFileRawData((prev) => ({ ...prev, videoThumbnil: "" }))
                              }}
                            >
                              x
                            </div>
                            <img
                              className="height_max img-responsive"
                              src={filePreview?.videoThumbnil || ""}
                              alt="Select thumbnail"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                    </div>
                    {thubCompShow &&
                      <div className="col-sm-7 text-start">
                        <div className="row">
                          <h6 className="bg-success mt-2 p-2 text-white">Upload a thumbnail Or select any one from this four options</h6>

                          <VideoThubnails
                            thubnails={createPostThubnails}
                            setFilePreview={setFilePreview}
                          />

                        </div>
                      </div>
                    }
                  </div>
                </>
              }
            </div>
            <div className="post_action my-3">
              {thubCompShow &&
                <div className="modal_image_input_1">
                  <label title="Click to Attach Photo">
                    <InsertPhotoIcon /> Thumbnail Photo
                    <input
                      type="file"
                      name="videoThumbnil"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleThumbnailChange}
                      value={fileInputStateT}
                    />
                  </label>
                </div>
              }
              {!isEmbed && (
                <>
                  {loadings?.previewLoader ? (
                    <>
                      {" "}
                      <SimpleCircle />
                    </>
                  ) : (
                    <div className="modal_image_input_1">
                      <label title="Click to Attach Photo">
                        {editorShow ? (
                          <>
                            <InsertPhotoIcon /> Thumbnail Photo
                          </>
                        ) : (
                          <>
                            <InsertPhotoIcon />
                            <span className="m_none">Photo</span>
                            {" / "}
                            <VideocamIcon />
                            <span className="m_none">video</span>
                          </>
                        )}
                        <input
                          type="file"
                          name="postImage"
                          onChange={handleFileInputChange}
                          value={fileInputState}
                        />
                      </label>
                    </div>
                  )}
                </>
              )}

              <div
                className="c_pointer btn rounded"
                onClick={() => setIsEmbed(isEmbed ? false : true)}
              >
                <AddLinkIcon />{" "}
                <span className="m_none">
                  {isEmbed ? "cancel" : "Embed Url"}
                </span>
              </div>

              {/* <CircularStatic
								progress={fileUploadPercent || 50}
							/> */}
              {loaderSwitch ? (
                <div className="loader_text">
                  {waitingLoader && (
                    <div className="waiting_msg shadow_1">
                      This will take time, please wait.
                    </div>
                  )}
                  {selectedFile ? (
                    <CircularStatic progress={fileUploadPercent || 0} />
                  ) : (
                    <SimpleCircle />
                  )}
                </div>
              ) : (
                <>
                  {createPost ? (
                    <button type="submit" className="btn cmn_btn">
                      <PublishIcon /> Post
                    </button>
                  ) : (
                    <button type="button" className="btn cmn_btn" disabled>
                      <PublishIcon /> Post
                    </button>
                  )}

                  {createPost && (
                    <div
                      className="cancle_post h-underline"
                      onClick={clearPost}
                    >
                      Discard
                    </div>
                  )}
                </>
              )}

              <button
                type="button"
                className="btn cmn_btn bg_b m_none"
                onClick={() => setPopUp(true)}
              >
                <InfoIcon />
                Help
              </button>
            </div>
          </form>
        </div>

        {isCopyLink && postIdx && (
          <div className="p-3">
            {isCopyLink + `/p/${postIdx}?redirect=1`}
            <CopyLink link={`/p/${postIdx}?redirect=1`} />
          </div>
        )}

        {shareDial && postIdx && (
          <CustomizedSnackbars type={0} msg={"Your post uploaded succefully"} />
        )}
        {errMsg && <CustomizedSnackbars type={1} msg={errMsg} />}

        {popUp && (
          <div className="welcome_section_popup">
            <div onClick={() => setPopUp(false)} className="bg-white close_pop">
              X
            </div>

            <div className="container">
              <h2>Welcome to Allphanes</h2>
              <div className="welcome_logo">
                <img
                  className="img-responsive"
                  src="/assets/web_img/main_logo.png"
                  alt="main_logo"
                />
              </div>
              <div className="category_list">
                <div className="row">
                  <Tabs
                    defaultActiveKey={1}
                    animation={false}
                    id="noanim-tab-example"
                  >
                    <Tab eventKey={1} title="Do you want to post?">
                      <div className="row">
                        <div className="col-sm-2 welcome_icon">
                          <Link to={"/" + currentUser?.userName}>
                            <img src={select_language} alt="main_logo" />
                            <h3>Select Language</h3>
                          </Link>
                        </div>
                        <div className="col-sm-3 wecome_categori_border">
                          <img
                            className="img-responsive"
                            src={welcome_arrow}
                            alt="main_logo"
                          />
                        </div>
                        <div className="col-sm-2 welcome_icon dance_section">
                          <Link to={"/" + currentUser?.userName}>
                            <img src={select_domain} alt="main_logo" />
                            <h3>Select Domain</h3>
                          </Link>
                        </div>
                        <div className="col-sm-3 wecome_categori_border">
                          <img
                            className="img-responsive"
                            src={welcome_arrow1}
                            alt="main_logo"
                          />
                        </div>
                        <div className="col-sm-2 welcome_icon">
                          <Link to={"/" + currentUser?.userName}>
                            <img src={select_subdomain} alt="main_logo" />
                            <h3>Select sub domain</h3>
                          </Link>
                        </div>
                        <div className="col-sm-4 welcome_icon">
                          <Link to={"/" + currentUser?.userName}>
                            <img src={give_title} alt="main_logo" />
                            <h3>Give title</h3>
                          </Link>
                        </div>
                        <div className="col-sm-4 wecome_categori_border">
                          <img
                            className="img-responsive"
                            src={welcome_arrow2}
                            alt="main_logo"
                          />
                        </div>
                        <div className="col-sm-4 welcome_icon">
                          <Link to={"/" + currentUser?.userName}>
                            <img src={post_icon} alt="main_logo" />
                            <h3>Post</h3>
                          </Link>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
const mapStateToProps = (state) => {
  console.log("stateoRgpost", state)
  const {
    domains,
    isSuccess,
    isError,
    isProcessing,
    createPostStatus,
    progressSize,
    uploadFileSize,
    createPostThubnails
  } = state.posts
  const { user } = state.auth
  return {
    domains,
    isSuccess,
    isError,
    isProcessing,
    createPostStatus,
    user,
    progressSize,
    uploadFileSize,
    createPostThubnails
  }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(CreatePost)
