import React, { useState } from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import useForm from "../../validations/useForm"
import validate from "./../../validations/FormValidationRule"
import SimpleCircle from "../../components/Loaders/SimpleCircle"
import CustomizedSnackbars from "../../components/Loaders/Snuckbars"
import SocialAppLogin from "../../components/Auth/SocialAppLogin"
import { connect, useDispatch } from "react-redux"
import { postRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { setToken } from "../../helpers/network"
import Pushnotification from "../../services/Pushnotification"

function HomeLogin(props) {
	const [inputfocus, setInputfocus] = useState({})
	const [errmsg, setErrMsg] = useState("")
	const [isSubmit, setIsSubmit] = useState(false)
	const [isError, setIsError] = useState(false)
	const [isLoginSuccess, setIsLoginSuccess] = useState(false)
	const navigate = useNavigate()
	const [searchParams] = useSearchParams()
	const dispatch= useDispatch()

	const { values, errors, handleChange, handleSubmit } = useForm(
		login,
		validate,
		"login"
	)

	

	async function login() {
		try {
			setIsSubmit(true)
			const data = {
				email: values.email,
				password: values.lpassword,
				loginType: 'normal'
			}
			const response = await dispatch(postRequestHandler({
				url: 'login',
				action: 'ACTION_AUTH_USER',
				data: data
			}))

			setIsSubmit(false)
			if (response?.data?.status === 700) {
				localStorage.setItem('reActivaEmail', values.email)
				return navigate("/accounts/reactivate/")
			}
			if (response?.data?.status !== 200) {
				setIsError(true)
				return setErrMsg(response?.data?.message)
			}
			setIsLoginSuccess(true)
			setToken(response?.data?.responseData?.token)
			localStorage.clear()
			localStorage.setItem("token", response.data.responseData.user.id)
			localStorage.setItem("accessToken", response.data.responseData.token)
			localStorage.setItem(
				"currentuser",
				JSON.stringify(response?.data?.responseData)
			)
			Pushnotification()
			if (!response?.data?.responseData?.user?.isEmailVerified) {
				navigate("/auth/verification/")
			} else {
				const redirect = searchParams.get("redirectTo")
				navigate(redirect || "/creations/")
			}
		} catch (err) {
			setIsError(true)
			setErrMsg(
				"Sorry, Login failed due to some technical issue. Please try again after some time."
			)
			console.error(err)
		}
	}

	return (
		<>
			{/* <h3 className="text-center">Login</h3> */}
			{/* <SocialLogins /> */}

			{/*	<SocialLogins /> */}
			<form className="items-center" onSubmit={handleSubmit}>
				<div className="phone-tag mb-2">
					{/* email  */}
					<div className="mt-4">

						<input
							type="email"
							className="form-control form-control-xl login mt-1"
							autoComplete="off"
							placeholder="Enter email address here"
							name="email"
							onFocus={(e) =>
								setInputfocus((inputfocus) => ({
									...inputfocus,
									[e.target.name]: true,
								}))
							}
							onBlur={(e) =>
								setInputfocus((inputfocus) => ({
									...inputfocus,
									[e.target.name]: false,
								}))
							}
							onChange={handleChange}
							value={values.email || ""}
							required
						/>
						<div className="errors">{!inputfocus.email && errors.email}</div>
					</div>

					{/* password */}
					<div className="t-4">

						<input
							type="password"
							className="login form-control form-control-xl"
							autoComplete="off"
							placeholder="Enter password here"
							name="lpassword"
							onFocus={(e) =>
								setInputfocus((inputfocus) => ({
									...inputfocus,
									[e.target.name]: true,
								}))
							}
							onBlur={(e) =>
								setInputfocus((inputfocus) => ({
									...inputfocus,
									[e.target.name]: false,
								}))
							}
							onChange={handleChange}
							value={values.lpassword || ""}
						/>
						<div className="errors"></div>
					</div>
				</div>

				{isSubmit ? <button
					type="button"
					className="btn btns mt-3 d-flex justify-content-center"
				>
					<SimpleCircle />
				</button> :
					<button className="btn cmn-new_btn">Login <img src="/assets/web_img/arow_new.png" alt="sound" /></button>


				}
				<div className="t-4">
					<a href="/accounts/password/reset/">Forgot password ?</a>
				</div>



				<div className="mt-2 text-center">
					Don't Have an Account?{" "}
					<br />
					<span className="text-success"><b>The Registration is free</b></span>
					<br />
					{searchParams.get("redirectTo") ? (
						<Link
							className="clr-p"
							to={`/auth/registration/?redirectTo=${searchParams.get(
								"redirectTo"
							)}`}
						>
							Register
						</Link>
					) : (
						<a className="clr-p" href="/auth/registration/">
							Register Now
						</a>

					)}

				</div>
			</form>
			<div className="d-flex align-items-center gap-2 mx-3 mb-3 mt-2">
				<div className="line"></div>
				<div className="login_or">or</div>
				<div className="line"></div>
			</div>
			{/* <FacebookLoginx /> */}
			{typeof window !== "undefined" && <SocialAppLogin />}

			{/* error snackbar */}
			{isError && <CustomizedSnackbars type={1} msg={errmsg} sclose={() => setIsError(false)} />}
			{isLoginSuccess && <CustomizedSnackbars type={0} msg={"login successfull"} sclose={() => setIsLoginSuccess(false)} />}

		</>
	)
}
const mapStateToProps = (state) => {
	return {}
}
const mapDispatchToProps = (dispatch) => {
	return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(HomeLogin)