import React, { useEffect, useState, useRef } from "react"
import axios from "axios"
import { config } from "../../constant"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClipboard } from "@fortawesome/free-solid-svg-icons"
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import moment from "moment"
import { Footer, IndexNavbar } from '../../components'
import ProfileSettingsNav from '../../components/Settings/ProfileSettingsNav'

const userUrl = config.url.API_URL + "users/"
const refUrl = config.url.API_URL + "users/getref?"

export default function ReferralSettings(props) {
    const target = useRef(null)
    const [show, setShow] = useState(false)
    const [TooltipMsg, setTooltipMsg] = useState("Copy Link")
    const [values, setValues] = useState({})
    const [refDetails, setRefDetails] = useState([])
    const token = typeof window !== "undefined" ? localStorage.getItem('token') : ""
    const [host, setHost] = useState('')


    const getUser = async () => {
        const response = await axios.get(userUrl + token)
        console.log("getUser", response)
        setValues(await response.data.responseData)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getRefDetails = async () => {
        const response = await axios.get(refUrl + `token=${token}&mm=true`)
        setRefDetails(response.data.responseData)
        console.log("refDetails", response)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const genarateRefCode = async () => {
        const response = await axios.post(userUrl + `/updateref?id=${token}`)
        console.log("genarateRefCode", response)
        // if (response.data.ack === 1)
        getUser()
    }

    const handleCopyClik = () => {
        setTooltipMsg("Copied")
        // if(typeof window !== "undefined")
        window.navigator.clipboard.writeText(host + values.referralCode)
    }

    useEffect(() => {
        setHost('https://' + window.location.host + '/auth/registration/')
    }, [host])

    useEffect(() => {
        // getUser()getref?mm=true
        genarateRefCode()
        getRefDetails()
        // console.log(moment().month('2022-08').format("MMMM"))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <IndexNavbar />
            <div className="container">
                <ProfileSettingsNav />

                <div id="referral" className="row bg_allp3 justify-content-center settings_row border_1 mt-4 border_r5">
                    <div className="col-lg-8">

                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className='mt-4'>
                                    <div className='w-100'>
                                        <label htmlFor="Default language" className="log-input-title mb-2">Your Referral Link</label>

                                        <div className="copy_div">
                                            <input
                                                type="text"
                                                className="form-control form-control-xl mt-2"
                                                autoComplete="off"
                                                placeholder="User Name"
                                                name="referral"

                                                value={host + values?.referralCode || ""}
                                                // required
                                                disabled
                                            />
                                            <div
                                                ref={target}
                                                className="copy_icon c_pointer"
                                                onClick={handleCopyClik}
                                                onMouseEnter={() => setShow(true)}
                                                onMouseLeave={() => {
                                                    setTimeout(() => {
                                                        setShow(false)
                                                        setTooltipMsg("Copy Link")
                                                    }, 1000)

                                                }}
                                            >
                                                <FontAwesomeIcon icon={faClipboard} className="post_icons"></FontAwesomeIcon>

                                                <Overlay target={target?.current} show={show} placement="top">
                                                    {(props) => (
                                                        <Tooltip id="overlay-example" {...props}>
                                                            {TooltipMsg}
                                                        </Tooltip>
                                                    )}
                                                </Overlay>

                                            </div>
                                        </div>
                                        <div className="mt-3 fs_choto cl_gray info">
                                            Share your referral link to help people discover Allphanes and earn exciting rewards.
                                        </div>


                                    </div>

                                    <div className='w-100 mt-4'>
                                        <label htmlFor="Default language" className="log-input-title mb-2">Your Referral Code</label>

                                        <div className="copy_div">
                                            <input
                                                type="text"
                                                className="form-control form-control-xl mt-2"
                                                autoComplete="off"
                                                placeholder="User Name"
                                                name="referral"

                                                value={values?.referralCode || ""}
                                                // required
                                                disabled
                                            />
                                            <div
                                                ref={target}
                                                className="copy_icon c_pointer"
                                                onClick={() => {
                                                    setTooltipMsg('Copied')
                                                    window.navigator.clipboard.writeText(values?.referralCode)
                                                }}
                                                onMouseEnter={() => setShow(true)}
                                                onMouseLeave={() => {
                                                    setTimeout(() => {
                                                        setShow(false)
                                                        setTooltipMsg("Copy Code")
                                                    }, 1000)

                                                }}
                                            >
                                                <FontAwesomeIcon icon={faClipboard} className="post_icons"></FontAwesomeIcon>

                                                <Overlay target={target.current} show={show} placement="top">
                                                    {(props) => (
                                                        <Tooltip id="overlay-example" {...props}>
                                                            {TooltipMsg}
                                                        </Tooltip>
                                                    )}
                                                </Overlay>

                                            </div>
                                        </div>
                                        <div className="mt-3 fs_choto cl_gray info">
                                            Share your referral link to help people discover Allphanes and earn exciting rewards.
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div id="refMonth" className="row bg_allp3 justify-content-center settings_row border_1 mt-4 border_r5">
                    <h5 className="text-center my-3">Your Monthly Referral Report</h5>
                    <div className="col-lg-8">

                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className='mt-4'>
                                    <div className='w-100'>
                                        {/* <label htmlFor="Default language" className="log-input-title mb-2">Your Referral Link</label> */}


                                        <div>{refDetails ? <h5>{moment().month(`${refDetails[0]?._id}`).format("MMMM") + ' - ' + moment().year(`${refDetails[0]?._id}`).format("YYYY")}</h5> : ''} </div>


                                        <h6 className="mt-3 info">
                                            Your total referral this month : {refDetails?.length > 0 && refDetails[0]?.count}
                                        </h6>

                                        <h6 className="mt-4 mb-3">Members you referrared :</h6>

                                        <div>
                                            {refDetails?.length > 0 && refDetails[0]?.list?.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="mt-2">{index + 1 + '. ' + item?.user_info[0]?.showName}</div>
                                                    </div>
                                                )
                                            })

                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />

        </>
    )
}
