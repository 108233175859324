import React, { useState } from "react"
import { Link } from "react-router-dom"
import { CheckExtention } from "../../../_helpers/Variables"
import axios from "axios"
import config from "../../config/config"
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import Tooltip from "@mui/material/Tooltip"
import SimpleCircle from "../Loaders/SimpleCircle"
import CustomizedSnackbars from "../Loaders/Snuckbars"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
export const POSTAPIURI = config.API_URL + "posts/"

// credentials
export const TOKEN =
  typeof window !== "undefined" ? localStorage.getItem("token") : ""

export default function Helpers(props) {
  return <div {...props}></div>
}

export const Profile = ({ url, css }) => {
  return (
    <div
      className={css || "friend_img"}
      style={{
        backgroundImage: `url(${url ||
          "/assets/web_img/choto-log-img2.jpg" ||
          "/assets/web_img/choto-log-img2.jpg"
          })`,
      }}
    >
      {/* profile photo */}
    </div>
  )
}

export const PostProfilex = (props) => {
  const { img, alt, className } = props
  return (
    <>
      <img
        className={className}
        src={img || `/assets/web_img/choto_logo_1.png`}
        alt={alt}
        onError={(e) => {
          e.target.onerror = null
          e.target.src = `/assets/web_img/choto_logo_1.png`
        }}
      />
    </>
  )
}

export const SpaceProfileImg = ({ link, clas }) => {
  return (
    <>
      <img
        src={link || `/assets/web_img/choto_logo_1.png`}
        alt={link + "profile photo"}
        onError={(e) => {
          e.target.onerror = null
          e.target.src = `/assets/web_img/choto_logo_1.png`
        }}
        className={clas}
      />
    </>
  )
}

export const UsersProfile = (props) => {
  const { user, isshare } = props
  return (
    <div
      className="w-100 d-flex gap-2 align-items-center"
      style={{ background: "#FFF" }}
    >
      <div className="profile_photo">
        <img
          src={user?.profilePhoto || `/assets/web_img/choto_logo_1.png`}
          alt="profile"
          // eslint-disable-next-line no-sequences
          onError={(e) => {
            e.target.onerror = null
            e.target.src = `/assets/web_img/choto_logo_1.png`
          }}
        />
      </div>
      <div>
        <Link to={"/" + user?.userName} className="profile_link">
          {user?.showName}
        </Link>
        {isshare && (
          <div className="share_type">
            <LockOutlinedIcon /> Friends only
          </div>
        )}
      </div>
    </div>
  )
}

export const FileViewer = (file) => {
  const { link } = file

  const getFileTypeCode = CheckExtention(file.link)
  return (
    <>
      {getFileTypeCode === 0 && (
        <img
          id={link}
          key={link}
          src={link}
          alt={`Opps! this ${link.slice(
            (Math.max(0, link.lastIndexOf(".")) || Infinity) + 1
          )} format not supported.`}
          className="post_img_1"
        />
      )}
      {getFileTypeCode === 1 && (
        <>
          <video
            className="videoxs"
            id={link}
            key={link}
            // onMouseEnter={event => event.target.play()}
            // onBlur={event => event.target.pause()}
            controlsList="nodownload"
          >
            <source src={link} type="video/mp4" />
          </video>
        </>
      )}
      {getFileTypeCode === 2 &&
        <div className="audio_view">
          {file?.photo &&
            <img
              src={file?.photo}
              alt="not playing"
            />
          }
          <video
            className="videoxs"
            id={link}
            key={link}
            // onMouseEnter={event => event.target.play()}
            // onBlur={event => event.target.pause()}
            controls
            controlsList="nodownload"
          >
            <source src={link} type="video/mp4" />
          </video>
        </div>


      }
      {/* 
      <audio
          className="videoxs"

          key={link}
          controlsList="nodownload"
          controls>
          <source src={link} type="audio/ogg" />
          <source src={link} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
       */}
    </>
  )
}

export const FileViewerc = (file) => {
  const { link } = file

  const getFileTypeCode = CheckExtention(file.link)
  return (
    <>
      {getFileTypeCode === 0 && (
        <img
          id={link}
          key={link}
          src={link}
          alt={`Opps! this ${link.slice(
            (Math.max(0, link.lastIndexOf(".")) || Infinity) + 1
          )} format not supported.`}
          className="post_img_1"
        />
      )}
      {getFileTypeCode === 1 && (
        <>
          <video
            className="videoxs"
            id={link}
            key={link}
            // onMouseEnter={event => event.target.play()}
            // onBlur={event => event.target.pause()}
            controls
            controlsList="nodownload"
          >
            <source src={link} type="video/mp4" />
          </video>
        </>
      )}
      {getFileTypeCode === 2 && (
        <audio
          className="videoxs"

          key={link}
          controlsList="nodownload"
          controls>
          <source src={link} type="audio/ogg" />
          <source src={link} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </>
  )
}

export const FileViewerHome = (file) => {
  const { link } = file
  const getFileTypeCode = CheckExtention(file.link)
  return (
    <>
      {getFileTypeCode === 0 && (
        <div id={link} key={link} className="home_posts border border-white border-5 rounded-3 bg-white" style={{
          backgroundImage: `url(${link})`
        }}>
        </div>
      )}
      {getFileTypeCode === 1 && (
        <>
          <video id={link} key={link} controlsList="nodownload">
            <source src={link} type="video/mp4" />
          </video>
        </>
      )}
      {getFileTypeCode === 2 && (
        <audio key={link} controls>
          <source src={link} type="audio/ogg" />
          <source src={link} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      )}
    </>
  )
}

export const FileViewery = (file) => {
  const { link } = file
  const getFileTypeCode = CheckExtention(file.link)
  console.log(link)
  return (
    <>
      {(/data:image/.test(link) || getFileTypeCode === 0) && (
        <img
          id={link}
          key={link}
          src={link}
          alt="posts"
          className="post_img_1"
        />
      )}
      {(/data:video|data:audio/.test(link) || getFileTypeCode === 1) &&
        <video id={link} key={link} controls controlsList="nodownload">
          <source src={link} type="video/mp4" />
        </video>
      }

      {/data:application/.test(link) && (
        <div className="p-4 text-center">Not Supported</div>
      )}
    </>
  )
}

export const FileViewerx = (file) => {
  const { link } = file
  return (
    <>
      {/image|jpeg|jpg|png|webp|jfif|gif|apng|avif|data:image/.test(link) ? (
        <img
          id={link}
          key={link}
          src={link}
          alt="posts"
          className="post_img_1"
        />
      ) : (
        <video id={link} key={link} controls controlsList="nodownload">
          <source src={link} type="video/mp4" />
        </video>
      )}
    </>
  )
}

export const MainLogo = () => {
  return (
    <img
      src={`/assets/web_img/main_logo.png`}
      alt="allphenes logo"
      loading="lazy"
    />
  )
}

export const AdForKhagerKolom = (image) => {
  return (
    <div className="add_box">
      <img
        src={`/assets/web_img/khagerkolom.jpg`}
        alt="advertisement for Khager Kolom."
        width="100%"
        loading="lazy"
      />
    </div>
  )
}

export const AdForBangalKobiSammeloni = (image) => {
  return (
    <div className="add_box">
      <a
        href="https://banglakobisammeloni.com/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src={`/assets/web_img/banglaKobi.jpg`}
          alt="advertisement for bangla kobi sammeloni. 
				        you can visit https://banglakobisammeloni.com/ to know more"
          width="100%"
          loading="lazy"
        />
      </a>
    </div>
  )
}

export const incrementPostViews = async (post) => {
  try {
    const userId =
      typeof window !== "undefined" ? localStorage.getItem("token") : ""
    if (post.referenceUserId === userId) return
    await axios.patch(
      POSTAPIURI + `updateview?_id=${post._id}&referenceUserId=${userId}`
    )
  } catch (err) {
    console.error(err)
  }
}

export const CopyLink = ({ link, component }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const handleCopyClik = () => {
    window.navigator.clipboard.writeText(
      "https://" + window.location.host + link
    )
    setIsSuccess(true)
  }
  return (
    <>
      {component ?
        <Tooltip
          title="copy link"
          className="c_pointer"
          onClick={handleCopyClik}
          arrow>
          {component}
        </Tooltip> :
        <Tooltip title="copy link" arrow>
          <ContentCopyOutlinedIcon
            className="c_pointer"
            onClick={handleCopyClik}
          />
        </Tooltip>
      }
      {isSuccess && (
        <CustomizedSnackbars
          type={0}
          msg={"Link copied successfully"}
          sclose={() => setIsSuccess(false)}
        />
      )}
    </>
  )
}

export const SubmitButton = ({ title, issubmit, css }) => {
  return (
    <>
      {issubmit ? (
        <div className={css}>
          <SimpleCircle />
        </div>
      ) : (
        <button type="submit" className={css}>
          {title || "Submit"}
        </button>
      )}
    </>
  )
}

export const OnClickSubmitButton = ({ title, issubmit, css, click }) => {
  return (
    <>
      {issubmit ? (
        <button type="button" className={css}>
          <SimpleCircle />
        </button>
      ) : (
        <button type="button" className={css} onClick={() => click()}>
          {title}
        </button>
      )}
    </>
  )
}
