import api from "../../config/api"
import Network from "../../helpers/network"
export const ACTION_ORGANIZATION_DETAILS_GET =
  "ACTION_ORGANIZATION_DETAILS_GET"
export const ACTION_ORGANIZATION_LIST_GET = "ACTION_ORGANIZATION_LIST_GET"
export const ACTION_ORGANIZATION_SKIP_LIST_GET = "ACTION_ORGANIZATION_SKIP_LIST_GET"
export const ACTION_ORG_FOLLOWERS_GET = "ACTION_ORG_FOLLOWERS_GET"
export const ACTION_ORG_MEMBERS_GET = "ACTION_ORG_MEMBERS_GET"
export const ACTION_ORG_POSTS_GET = "ACTION_ORG_POSTS_GET"
export const ACTION_ORG_POST_GET = "ACTION_ORG_POST_GET"
export const ACTION_ORG_PHOTOS_GET = "ACTION_ORG_PHOTOS_GET"
export const ACTION_ORG_VIDEOS_GET = "ACTION_ORG_VIDEOS_GET"
export const UNFOLLOWMEMBERS = "UNFOLLOWMEMBERS"
export const ACTION_ORGANIZATION_DETAILS_GET_FAILD = "ACTION_ORGANIZATION_DETAILS_GET_FAILD"
export const ACTION_ORGANIZATION_LIKE = "ACTION_ORGANIZATION_LIKE"
export const ACTION_ORGANIZATION_FOLLOW = "ACTION_ORGANIZATION_FOLLOW"
export const ACTION_ORGANIZATION_COUNT = "ACTION_ORGANIZATION_COUNT"
export const ACTION_ORGANIZATION_STORAGE = "ACTION_ORGANIZATION_STORAGE"
export const ACTION_GET_ORGANIZATION_ANL = "ACTION_GET_ORGANIZATION_ANL"
export const ACTION_GET_MYSUBSCRIPTIONS = "ACTION_GET_MYSUBSCRIPTIONS"
export const ACTION_GET_MYANALYTICS = "ACTION_GET_MYANALYTICS"
export const ACTION_GET_ORGDATA_BY_STATE = "ACTION_GET_ORGDATA_BY_STATE"
const initialState = {
  myAnalytics: [],
  mySubscriptions: [],
  orgAnl: null,
  storage: null,
  page: true,
  orgList: [],
  data: null,
  adminCount: null,
  isError: false,
  isProcessing: true,
  isSuccess: null,
  message: null,
  members: {},
  followers: null,
  posts: null,
  photos: [],
  videos: [],
  allPrivatePost: []
}

export const OrganizationReducer = (state = initialState, action) => {
  let returnObj = {
    ...state
  }
  switch (action.type) {
    case ACTION_GET_MYANALYTICS:
      return {
        ...state,
        myAnalytics: action.payload,
      }
    case ACTION_GET_ORGANIZATION_ANL:
      return {
        ...state,
        orgAnl: action.payload,
      }
    case ACTION_GET_ORGDATA_BY_STATE:
      returnObj[action?.state] = action?.page > 1 ? [...returnObj[action?.state], ...action?.payload] : action?.payload
      return returnObj
    case ACTION_ORGANIZATION_DETAILS_GET:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        data: action.payload,
        isSuccess: true,
        //    message :
      }
    case ACTION_ORGANIZATION_STORAGE:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        storage: action.payload,
        isSuccess: true,
        //    message :
      }
    case ACTION_ORGANIZATION_SKIP_LIST_GET:
      let kk = true
      if (action.payload.length < 1) kk = false
      return {
        ...state,
        isError: false,
        orgList: [...state.orgList, ...action.payload],
        isSuccess: true,
        isProcessing: false,
        page: kk
        //    message :
      }
    case ACTION_ORGANIZATION_LIST_GET:
      return {
        ...state,
        isError: false,
        orgList: action.payload,
        isSuccess: true,
        isProcessing: false,
        page: true
        //    message :
      }
    case ACTION_GET_MYSUBSCRIPTIONS:
      return {
        ...state,
        mySubscriptions: action.payload,
        // page: true
      }
    case ACTION_ORGANIZATION_COUNT:
      return {
        ...state,
        adminCount: action.payload,
        //    message :
      }
    case ACTION_ORGANIZATION_LIKE:
      console.log(action.payload.updatePage)
      if (action.payload.updatePage === 'orglist') {
        const tempOrgList = [...state.orgList]
        const indexOfOrgList = tempOrgList.findIndex((e) => e._id === action.payload._id)
        tempOrgList[indexOfOrgList].isLiked = action.payload.isLiked
        tempOrgList[indexOfOrgList].totalLikes = action.payload.totalLikes
        returnObj.orgList = [...tempOrgList]
      }
      if (action.payload.updatePage === 'detailspage') {
        const tempOrgData = { ...state.data }
        console.log(action.payload.isLiked)
        tempOrgData.isLiked = action.payload.isLiked
        tempOrgData.totalLikes = action.payload.totalLikes
        returnObj.data = { ...tempOrgData }
      }
      return returnObj
    case ACTION_ORGANIZATION_FOLLOW:
      if (action.payload.updatePage === 'orglist') {
        const tempOrgList = [...state.orgList]
        const indexOfOrgList = tempOrgList.findIndex((e) => e._id === action.payload._id)
        tempOrgList[indexOfOrgList].isFollowing = action.payload.isFollowing
        tempOrgList[indexOfOrgList].totalFollowers = action.payload.totalFollowers
        returnObj.orgList = [...tempOrgList]
      }
      if (action.payload.updatePage === 'detailspage') {
        const tempOrgData = { ...state.data }
        tempOrgData.isFollowing = action.payload.isFollowing
        tempOrgData.totalFollowers = action.payload.totalFollowers
        returnObj.data = { ...tempOrgData }
      }
      return returnObj
    case ACTION_ORG_FOLLOWERS_GET:
      if (action?.payload?.param?.page > 1) {
        const tempPost = [...state.followers, ...action.payload.response]
        returnObj.followers = tempPost
      } else returnObj.followers = action.payload.response
      return returnObj
    case ACTION_ORG_PHOTOS_GET:
      console.log("ACTION_ORG_PHOTOS_GET", action?.payload?.param)
      if (action?.payload?.param?.page > 1) {
        if (action?.payload?.param?.gtype === 'video') {
          const tempPost = [...state.videos, ...action.payload.response]
          returnObj.videos = tempPost
        }
        else {
          const tempPost = [...state.photos, ...action.payload.response]
          returnObj.photos = tempPost
        }
      } else {
        if (action?.payload?.param?.gtype === 'video')
          returnObj.videos = action.payload.response
        else returnObj.photos = action.payload.response
      }
      return returnObj
    case ACTION_ORG_VIDEOS_GET:
      return {
        ...state,
        videos: action.payload,
      }
    case ACTION_ORG_POSTS_GET:
      if (action?.payload?.param?.page > 1) {
        const tempPost = [...state.posts, ...action.payload.response]
        returnObj.posts = tempPost
      } else returnObj.posts = action.payload.response
      return returnObj
    case ACTION_ORG_MEMBERS_GET:
      console.log("ACTION_ORG_MEMBERS_GET", action.payload)
      if (action?.payload?.responseDetails?.page > 1) {
        const tempMember = [...state?.members?.members, ...action?.payload?.members]
        // const tempResD = action?.payload?.responseDetails
        const tempMembers = { members: [...tempMember], responseDetails: action?.payload?.responseDetails }
        console.log("ACTION_ORG_MEMBERS_GET mem", tempMembers, state.members)

        returnObj.members = tempMembers
      } else
        returnObj.members = action.payload

      //   const tempPost = [ ...state.posts, ...action.payload.response ]
      //   returnObj.posts = tempPost
      // } else returnObj.posts = action.payload.response
      // return returnObj
      return returnObj
    default:
      return state
  }
}

export const postReqManagementWithOutDispatch = (url, data) => {
  return new Promise((resolve, reject) => {
    try {
      Network.post(api[url], data)
        .then(res => resolve(res))
        .catch(err => reject(err))
    } catch (err) {
      reject(err)
    }
  })
}

export const getReqManagementWithOutDispatch = (url, params) => {
  return new Promise((resolve, reject) => {
    try {
      Network.get(api[url], params)
        .then(res => resolve(res))
        .catch(err => reject(err))
    } catch (err) {
      reject(err)
    }
  })
}

export const OrgUpdatex = (sParams, data) => dispatch => {
  return new Promise((resolve, reject) => {
    try {
      Network.put(api.orgUpdate + sParams, data)
        .then((response) => {
          console.log("OrgUpdatex", response)
          dispatch({
            type: ACTION_ORGANIZATION_DETAILS_GET,
            payload:
              response?.data?.responseData && response?.data?.responseData[0],
          })
          resolve(response)
        })
        .catch((err) => {
          reject(err)
        })
    } catch (error) {

      return reject(error)
    }
  })
}

export const getOrgAnl = params => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      //console.log(name)
      Network.get(api.getOrgAnl, params)
        .then((response) => {
          dispatch({
            type: ACTION_GET_ORGANIZATION_ANL,
            payload: response?.data?.responseData
          })
          resolve(response)
        })
        .catch((err) => {
          //console.log("error", err)
          reject(err)
        })
    } catch (error) {

      return reject(error)
    }
  })
}

export const getOrgMyAnl = params => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      //console.log(name)
      Network.get(api.getOrgMyAnl, params)
        .then((response) => {
          dispatch({
            type: ACTION_GET_MYANALYTICS,
            payload: response?.data?.responseData
          })
          resolve(response)
        })
        .catch((err) => {
          //console.log("error", err)
          reject(err)
        })
    } catch (error) {

      return reject(error)
    }
  })
}

export const getMySubscriptions = params => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      //console.log(name)
      Network.get(api.getPost, params)
        .then((response) => {
          dispatch({
            type: ACTION_GET_MYSUBSCRIPTIONS,
            payload: response?.data?.responseData
          })
          resolve(response)
        })
        .catch((err) => {
          //console.log("error", err)
          reject(err)
        })
    } catch (error) {

      return reject(error)
    }
  })
}
export const getOrganizationDetails = async (name) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      //console.log(name)
      Network.get(api.getOrganizationDetails + name, {})
        .then(async (response) => {
          dispatch({
            type: ACTION_ORGANIZATION_DETAILS_GET,
            payload:
              response?.data?.responseData && response?.data?.responseData[0],
          })
          resolve(response)
        })
        .catch((err) => {
          //console.log("error", err)
          dispatch({ type: ACTION_ORGANIZATION_DETAILS_GET_FAILD })
          reject(err)
        })
    } catch (error) {
      dispatch({
        type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      })
      return reject(error)
    }
  })
}

export const getOrganizationDetailss = async params => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      //console.log(name)
      Network.get(api.getOrganizationDetails, params)
        .then(async (response) => {
          dispatch({
            type: ACTION_ORGANIZATION_DETAILS_GET,
            payload:
              response?.data?.responseData && response?.data?.responseData[0],
          })
          resolve(response)
        })
        .catch((err) => {
          //console.log("error", err)
          dispatch({ type: ACTION_ORGANIZATION_DETAILS_GET_FAILD })
          reject(err)
        })
    } catch (error) {
      dispatch({
        type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      })
      return reject(error)
    }
  })
}

export const getOrganizationDetailsx = (params) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      //console.log(name)
      Network.get(api.getOrganizationDetails, params)
        .then((response) => {
          dispatch({
            type: params.list
              ? params.skip ? ACTION_ORGANIZATION_SKIP_LIST_GET
                : ACTION_ORGANIZATION_LIST_GET
              : ACTION_ORGANIZATION_DETAILS_GET,
            payload: params.list
              ? response?.data?.responseData
              : response?.data?.responseData && response?.data?.responseData[0],
          })
          resolve(response)
        })
        .catch((err) => {
          //console.log("error", err)
          dispatch({ type: ACTION_ORGANIZATION_DETAILS_GET_FAILD })
          reject(err)
        })
    } catch (error) {
      dispatch({
        type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      })
      return reject(error)
    }
  })
}

export const getOrganizationMembers = (param) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      //console.log("getOrganizationMembers>>",param)
      resolve(true)
      Network.get(api.getOrganizationMember, param)
        .then(async (response) => {
          //console.log("orgMembers", response)
          resolve(response)
          dispatch({
            type: ACTION_ORG_MEMBERS_GET,
            payload: response?.data?.responseData,
          })
        })
        .catch((err) => {
          //console.log("error", err)
          // dispatch({ type: ACTION_ORGANIZATION_DETAILS_GET_FAILD })
          reject(err)
        })
    } catch (error) {
      // dispatch({
      //     type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      // })
      return reject(error)
    }
  })
}

export const getOrgDetailsCount = (param) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      //console.log("getOrganizationMembers>>",param)
      resolve(true)
      Network.get(api.getOrganizationCount, param)
        .then(async (response) => {
          //console.log("orgMembers", response)
          resolve(response)
          dispatch({
            type: ACTION_ORGANIZATION_COUNT,
            payload: response?.data?.responseData,
          })
        })
        .catch((err) => {
          //console.log("error", err)
          // dispatch({ type: ACTION_ORGANIZATION_DETAILS_GET_FAILD })
          reject(err)
        })
    } catch (error) {
      // dispatch({
      //     type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      // })
      return reject(error)
    }
  })
}

export const getOrganizationPosts = (param) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      //console.log("getOrganizationMembers>>",param)
      resolve(true)
      Network.get(api.getOrgPost, param)
        .then(async (response) => {
          console.log("getOrganizationPosts", response)
          resolve(response)
          dispatch({
            type: ACTION_ORG_POSTS_GET,
            payload: { response: response?.data?.responseData, param: param },
          })
        })
        .catch((err) => {
          //console.log("error", err)
          // dispatch({ type: ACTION_ORGANIZATION_DETAILS_GET_FAILD })
          reject(err)
        })
    } catch (error) {
      // dispatch({
      //     type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      // })
      return reject(error)
    }
  })
}

export const orgPostsAction = (param) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("orgPostsAction>>", param)
      resolve(true)
      Network.post(api.orgPostAction, param.body)
        .then(async (response) => {
          console.log(response)
          // dispatch(getOrganizationPosts(param.query))
          resolve(response)
        })
        .catch((err) => {
          //console.log("error", err)
          reject(err)
        })
    } catch (error) {
      // dispatch({
      //     type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      // })
      return reject(error)
    }
  })
}

export const OrgStorageDetails = (param) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("orgPostsAction>>", param)
      resolve(true)
      Network.get(api.orgStorage, param)
        .then(async (response) => {
          console.log(response)
          dispatch({
            type: ACTION_ORGANIZATION_STORAGE,
            payload: response?.data?.responseData,
          })
          resolve(true)
        })
        .catch((err) => {
          //console.log("error", err)
          reject(err)
        })
    } catch (error) {
      // dispatch({
      //     type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      // })
      return reject(error)
    }
  })
}

export const getOrgPhotosVideos = (param) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      //console.log("getOrganizationMembers>>",param)
      resolve(true)
      delete param["currPage"]

      Network.get(api.getOrgPost, param)
        .then(async (response) => {
          console.log("photos", param)

          resolve(response)
          dispatch({
            type: ACTION_ORG_PHOTOS_GET,
            payload: { response: response?.data?.responseData, param: param },
          })
        })
        .catch((err) => {
          //console.log("error", err)
          // dispatch({ type: ACTION_ORGANIZATION_DETAILS_GET_FAILD })
          reject(err)
        })
    } catch (error) {
      // dispatch({
      //     type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      // })
      return reject(error)
    }
  })
}

export const getOrganizationFollowers = (param) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(true)
      Network.get(api.getOrganizationFollowers, param)
        .then(async (response) => {
          resolve(response)
          dispatch({
            type: ACTION_ORG_FOLLOWERS_GET,
            payload: { response: response?.data?.responseData[0]?.followers, param: param },
          })
        })
        .catch((err) => {
          // dispatch({ type: ACTION_ORGANIZATION_DETAILS_GET_FAILD })
          reject(err)
        })
    } catch (error) {
      // dispatch({
      //     type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      // })
      return reject(error)
    }
  })
}

export const orgIsFollowManagement = (param) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("orgIsFollowManagement>>", param)
      resolve(true)
      Network.post(api.orgFollow, param.apis)
        .then(async (response) => {
          console.log("orgIsLikeManagement", response)
          dispatch({
            type: ACTION_ORGANIZATION_FOLLOW,
            payload: { ...response.data.responseData, ...param.local },
          })
          resolve(response)
        })
        .catch((err) => {
          //console.log("error", err)
          reject(err)
        })
    } catch (error) {
      // dispatch({
      //     type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      // })
      return reject(error)
    }
  })
}

export const orgIsLikeManagement = (param) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("orgIsFollowManagement>>", param)

      resolve(true)
      Network.post(api.orgLike, param.apis)
        .then(async (response) => {
          resolve(response)

          console.log("orgIsLikeManagement", response)
          dispatch({
            type: ACTION_ORGANIZATION_LIKE,
            payload: { ...response.data.responseData, ...param.local },
          })
        })
        .catch((err) => {
          //console.log("error", err)
          reject(err)
        })
    } catch (error) {
      // dispatch({
      //     type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      // })
      return reject(error)
    }
  })
}

export const orgMembersAction = (param) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log("orgMembersAction>>", param)
      resolve(true)
      Network.post(api.orgMembers, param.body)
        .then(async (response) => {
          // dispatch(getOrganizationMembers(param.query))
          resolve(response)
        })
        .catch((err) => {
          //console.log("error", err)
          reject(err)
        })
    } catch (error) {
      // dispatch({
      //     type: ACTION_ORGANIZATION_DETAILS_GET_FAILD,
      // })
      return reject(error)
    }
  })
}


