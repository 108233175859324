import React, { useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import ReactCrop, { Crop } from "react-image-crop";
// import "../../assets/css/style.css"
import uuid from "react-uuid";

export default function ImageCropper({ src, selectedFile, cropData }) {
  //save the resulted image
  const [result, setResult] = useState(null);
  const [image, setImage] = useState(null);
  const [crop, setCrop] = useState(
    cropData || {
      unit: "px", // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 200,
      height: 200,
    }
  );
  const getCroppedImg = async () => {
    try {
      console.log("image>>>", image);
      const canvas = window.document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const pixelRatio = window.devicePixelRatio;
      const ctx = canvas.getContext("2d");
      canvas.width = scaleX * crop.width * pixelRatio;
      canvas.height = scaleY * crop.height * pixelRatio;
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);
      selectedFile(
        dataURLtoFile(base64Image, uuid() + "-" + Date.now() + ".jpg")
      );
    } catch (e) {
      console.log("crop the image", e);
    }
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?)/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  return (
    <>
      <ReactCrop
        crop={crop}
        onChange={setCrop}
        style={{ height: "100%" }}
        maxHeight={"100%"}
        src={src}
        keepSelection={true}
        locked={true}
        onImageLoaded={(e) => {
          console.log("imageLoad>>>", e);
          setImage(e);
        }}
        onComplete={(c) => {
          console.log("onComplete", c);
          setTimeout(() => {
            getCroppedImg();
          }, 500);
        }}
      >
        {/* <img src={src} /> */}
      </ReactCrop>
      {/* <a className="cropButton" onClick={getCroppedImg}>
				<i class="icon-crop"></i> Crop
			</a> */}
    </>
  );
}
