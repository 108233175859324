import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import GAnalytics from "../../services/GAnalytics";
import CardLoader from "../../components/Loaders/CardLoader";
import CreatorPopup from "../../components/Widgets/Creator.popup";
import NewsList from "../../components/DesignComponents/NewsList";
import SinglePost from "./SinglePost";
import { Helmet } from "react-helmet";
import { Footer, IndexNavbar } from "../../components";
import PostTestCard from "../../components/Postsk";
import { connect, useDispatch } from "react-redux";
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer";
import Pushnotification from "../../services/Pushnotification";

// h1 hh
function TopCreations(props) {
  const { trendingCreations, topCreations } = props;
  const [load, setLoad] = useState(true);
  const [singlePost] = useState([]);
  const [loadingPost, setLoadingPost] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [isPostModal, setIsPostModal] = useState(false);
  const [page, setPage] = useState(1);
  const [recentPostPage, setRecentPostPage] = useState(1);
  const [hasMore, sethasMore] = useState(true);
  const [hasRecentPostMore, sethasRecentPostMore] = useState(true);
  const dispatch = useDispatch();
  const currentUser =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("currentuser"))
      : "";
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";

  const getPosts = async (param) => {
    if (!hasMore) return setLoadingPost(false);
    setLoadingPost(true);
    let params = {
      sort: "mc",
      page: param?.page || page,
    };
    let req = {
      params: params,
      state: "topCreations",
      action: "ACTION_GET_POSTS_MANAGEMENT",
      url: "getTopCreations",
      // url: "getPost",
    };
    const response = await dispatch(getRequestHandler(req));
    if (response?.data?.responseData?.length === 0) sethasMore(false);
    setLoadingPost(false);
  };
  const getTrandingPosts = async (param) => {
    if (!hasRecentPostMore) return setLoadingPost(false);
    setLoadingPost(true);
    let params2 = {
      // token: token,
      // sort: "ml",
      private: true,
      page: param?.page || recentPostPage,
      limit: 10,
    };
    let req2 = {
      params: params2,
      state: "trendingCreations",
      action: "ACTION_GET_POSTS_MANAGEMENT",
      url: "getTranding",
    };
    const response = await dispatch(getRequestHandler(req2));
    if (response?.data?.responseData?.length === 0) sethasRecentPostMore(false);
    setLoadingPost(false);
  };
  useEffect(() => {
    if (page > 1) getPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (recentPostPage > 1) getTrandingPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentPostPage]);
  useEffect(() => {
    if (topCreations?.length > 0) setLoad(false);
  }, [topCreations]);

  useEffect(() => {
    Pushnotification();
  }, []);

  const handleScroll = (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    if (offsetHeight + scrollTop > scrollHeight - 20 && !loadingPost) {
      if (hasMore) setPage((prev) => prev + 1);
    }
  };
  const moreRecentVideo = (e) => {
    console.log("v======", e);
    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    if (offsetHeight + scrollTop > scrollHeight - 20 && !loadingPost) {
      if (hasMore) setRecentPostPage((prev) => prev + 1);
    }
  };
  const navigate = useNavigate();
  const lastLocation = useLocation();
  const [searchParams] = useSearchParams();

  const handlePopUpClose = () => {
    setPopUp(false);
    navigate("/creations/");
  };

  useEffect(() => {
    GAnalytics();
    if (searchParams.get("popup") === "1") setPopUp(true);
    if (!currentUser)
      navigate(
        "/auth/login/?redirectTo=" + lastLocation.pathname + lastLocation.search
      );
  }, [
    currentUser,
    lastLocation.pathname,
    lastLocation.search,
    navigate,
    searchParams,
  ]);

  return (
    <>
      <IndexNavbar />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Allphanes | creations`}</title>
      </Helmet>
      {popUp && <CreatorPopup onHide={() => handlePopUpClose()} />}
      <div className="bg_allp">
        <div className="creation_banner">
          <div className="container-md">
            <h1>Search Global Top Creations</h1>

            <div className="advance_search d-flex gap-2">
              <Link to="/search/post/" className="btn cmn_btn">
                Advance Search
              </Link>
              <button
                onClick={() => setPopUp(true)}
                className="btn cmn_btn bg_b"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20px"
                  viewBox="0 0 24 24"
                  width="20px"
                  fill="#fff"
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
                </svg>
                Help
              </button>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div
              className="col-lg-9 post_area_infinite scrollBar"
              onScroll={handleScroll}
            >
              <div className="row">
                <div className="masonry_wrapper">
                  <div className="masonry">
                    {topCreations?.length > 0 &&
                      topCreations.map((item, index) => {
                        return (
                          <div
                            className="item"
                            key={item?._id + index}
                            // onClick={() => incrementPostViews(item)}
                          >
                            <div className="item_in">
                              <PostTestCard
                                singlePost={item}
                                key={item?._id}
                                indexinfo={index}
                                getpost={() => getPosts({ page: 1 })}
                                isViewDetails={true}
                                isPostActionSec={true}
                                isPostCommentSec={false}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>

              {(loadingPost || load) && (
                <div className="row">
                  <div className="col-lg-4">
                    <CardLoader />
                  </div>
                  <div className="col-lg-4">
                    <CardLoader />
                  </div>
                  <div className="col-lg-4">
                    <CardLoader />
                  </div>
                </div>
              )}
            </div>

            {/* ad section  */}
            <div
              className="col-lg-3 col-md-12 mt-2	post_area_infinite scrollBar"
              onScroll={moreRecentVideo}
            >
              <NewsList
                trendingCreations={trendingCreations}
                title="Trending posts"
              />
            </div>
          </div>
        </div>
      </div>

      {isPostModal && (
        <SinglePost
          type="modal"
          isView={isPostModal}
          postid={singlePost._id}
          closeBtn={() => setIsPostModal(false)}
        />
      )}
      <Footer />
    </>
  );
}

// export default
TopCreations.loadData = (params, search) => async (dispatch) => {
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const params = {
    // token: token,
    // type: "Normal",
    sort: "mc",
    page: 1,
  };

  let params2 = {
    // token: token,
    // sort: "ml",
    private: true,
    page: 1,
    limit: 10,
  };

  let req = {
    params: params,
    state: "topCreations",
    action: "ACTION_GET_POSTS_MANAGEMENT",
    url: "getTopCreations",
    // url: "getPost",
  };

  let req2 = {
    params: params2,
    state: "trendingCreations",
    action: "ACTION_GET_POSTS_MANAGEMENT",
    // url: "recentPost",
    url: "getTranding",
  };

  await dispatch(getRequestHandler(req));
  return await dispatch(getRequestHandler(req2));
};
const mapStateToProps = (state) => {
  console.log("stateoRgpostcreationposts", state);
  const { isSuccess, isError, isProcessing, topCreations, trendingCreations } =
    state.posts;
  return { isSuccess, isError, isProcessing, topCreations, trendingCreations };
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(TopCreations);
