import React, { useState } from 'react'
import Badge from '@mui/material/Badge'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive'
import UsersImage from '../new/components/Modals/UsersImage'
import OrganisationImageUpload from '../new/containers/CreateOrganisation/OrganisationImageUpload'
import DialogContentText from '@mui/material/DialogContentText'
import Avatar from '@mui/material/Avatar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import PersonIcon from '@mui/icons-material/Person'
import AddIcon from '@mui/icons-material/Add'
import { blue } from '@mui/material/colors'
import { SimpleCircle } from '../new/components'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', height: '30px' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 10,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

export function CircularStatic({ progress }) {

    console.log("profd", progress)
    return <CircularProgressWithLabel value={progress > 100 ? 100 : progress} />;
}

const emails = ['username@gmail.com', 'user02@gmail.com']

function SimpleDialog(props) {
    const { onClose, selectedValue, open } = props

    const handleClose = () => {
        onClose(selectedValue)
    }

    const handleListItemClick = (value) => {
        onClose(value)
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Set backup account</DialogTitle>
            <List sx={{ pt: 0 }}>
                {emails.map((email) => (
                    <ListItem disableGutters>
                        <ListItemButton onClick={() => handleListItemClick(email)} key={email}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={email} />
                        </ListItemButton>
                    </ListItem>
                ))}

                <ListItem disableGutters>
                    <ListItemButton
                        autoFocus
                        onClick={() => handleListItemClick('addAccount')}
                    >
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Add account" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Dialog>
    )
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
}

export function SimpleDialogDemo() {
    const [open, setOpen] = useState(false)
    const [selectedValue, setSelectedValue] = useState(emails[1])

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = (value) => {
        setOpen(false)
        setSelectedValue(value)
    }

    return (
        <div>
            <Typography variant="subtitle1" component="div">
                Selected: {selectedValue}
            </Typography>
            <br />
            <Button variant="outlined" onClick={handleClickOpen}>
                Open simple dialog
            </Button>
            <SimpleDialog
                selectedValue={selectedValue}
                open={open}
                onClose={handleClose}
            />
        </div>
    )
}
export function AlertDialog(props) {
    const { buttonText, component, alertTitle, alertMsg, setConfirm } = props
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleAgree = () => {
        setConfirm(true)
        handleClose()
    }

    return (
        <div>
            {component ?
                <div
                    className='c_pointer'
                    onClick={handleClickOpen}
                >
                    {component}
                </div> :
                <Button className='w-100' onClick={handleClickOpen}>
                    {buttonText}
                </Button>
            }
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {alertTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {alertMsg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAgree} autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export const SmallDialogs = props => {
    const { buttonText, dtitle, loading, dBody, setConfirm, handleSubmit } = props
    const [open, setOpen] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        if (props?.clearForm) props?.clearForm()
    }

    const handleAgree = () => {
        setConfirm(true)
        handleClose()
    }

    const handleSubmits = () => {
        handleSubmit()
        handleClose()
    }

    return (
        <div>

            <Button className='w-100' onClick={handleClickOpen}>
                {buttonText}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {dtitle}
                </DialogTitle>
                <DialogContent>
                    {<DialogContentText id="alert-dialog-description">
                        {dBody}
                    </DialogContentText>}
                </DialogContent>
                {loading ?
                    <DialogActions>
                        <button type='button' className='btn btn-primary'>
                            <SimpleCircle />
                        </button>

                    </DialogActions>
                    :
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        {handleSubmit ?
                            <Button onClick={handleSubmits} autoFocus>
                                Submit
                            </Button> :
                            <Button onClick={handleAgree} autoFocus>
                                Agree
                            </Button>
                        }
                    </DialogActions>
                }
            </Dialog>
        </div>
    )
}

export default function SimpleBadge({ content }) {
    return (
        <Badge badgeContent={content} color="secondary">
            <NotificationsActiveIcon style={{ color: "#fff" }} />
        </Badge>
    )
}

export function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
}

export function ImageUploadDialog({ pathinfo, uploadapi, orgid, close }) {
    const [open, setOpen] = useState(true)
    const handleClose = () => {
        setOpen(false)
        close()
    }

    return (
        <div>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
                Edit Profile Photo
            </Button> */}

            {/* <DialogTitle>Edit Profile Photo</DialogTitle> */}
            <Dialog
                fullWidth={"md"}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    {pathinfo === 0 ? "Edit cover photo" : "Edit profile photo"}
                </BootstrapDialogTitle>
                <DialogContent>
                    <UsersImage pathinfo={pathinfo} orgid={orgid} uploadapi={uploadapi} onHide={() => close()} />
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
        </div>
    )
}

export function ImageUploadDialogManagement(props) {
    const { title, close, isImageCropper, setFileLink, setIsUploaded } = props
    const [open, setOpen] = useState(true)
    const handleClose = () => {
        setOpen(false)
        close()
    }

    return (
        <>
            <Dialog
                fullWidth={"sm"}
                maxWidth={"sm"}
                open={open}
                onClose={handleClose}
            >
                <BootstrapDialogTitle id="image-dialog-title" onClose={handleClose}>
                    {title}
                </BootstrapDialogTitle>
                <DialogContent>
                    <OrganisationImageUpload
                        isUploaded={setIsUploaded}
                        setFileLink={setFileLink}
                        isImageCropper={isImageCropper}
                        onHide={() => handleClose()}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}