import React, { useState } from "react"
import { Footer, IndexNavbar } from "../../components"
import InputGroup from "react-bootstrap/InputGroup"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import {
  getMySubscriptions,
  getOrganizationDetailsx,
} from "../../redux/reducers/organizationReducer"
import { connect, useDispatch } from "react-redux"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  OrgFollowManagement,
  JoinMember,
  OrganizationLike,
} from "./OrganizationFrontSection"
import { useEffect } from "react"
import {
  PrivatePostCard,
} from "./OrgPrivatePosts"
import OrgMyAnalytice from "./OrgMyAnalytice"
import AllPrivatePost from "./AllPrivatePost"

function OrgListing(props) {
  const {
    getOrgList,
    orgList,
    isSuccess,
    page,
    mySubscriptions,
  } = props
  // const dispatch = useDispatch()
  const currentUserId =
    typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const [inputs, setInputs] = useState({})
  const [key, setKey] = useState("all")
  const [pages, setPages] = useState(1)
  const path = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const getOrgDetails = {
    all: async (params) => {
      return await getOrgList({
        list: true,
        mode: 'org',
        ...params,
      })
    },
    joined: async (params) => {
      return await getOrgList({
        list: true,
        mode: 'org',
        joinedorg: true,
        ...params,
      })
    },
    myorg: async (params) => {
      return await getOrgList({
        list: true,
        myorg: true,
        mode: 'org',
        ...params,
      })
    },
    private: async (params) => {
      return await getOrgList({
        list: true,
        joinedorg: true,
        mode: 'org',
        ...params,
      })
    },

    analytics: async (params) => {
      return await getOrgList({
        list: true,
        joinedorg: true,
        ...params,
      })
    },
    premierposts: async (params) => {
      return await getOrgList({
        list: true,
        joinedorg: true,
        ...params,
      })
    },

    mysubscriptions: async (params) => {
      return await dispatch(
        getMySubscriptions({
          token: currentUserId,
          isMySubcription: true,
          // ...params
        })
      )
    },
  }
  const handleSearch = async (e) => {
    try {
      e.preventDefault()
      let params = {
        q: inputs?.q,
        page: 1,
        mode: 'org',
      }

      if (!/allprivatepost/.test(key)) await getOrgDetails[key](params)
    } catch (error) {
      console.error(error)
    }
  }

  const handleScroll = (e, page) => {
    console.log("entered here")
    const { offsetHeight, scrollTop, scrollHeight } = e.target

    if (offsetHeight + scrollTop > scrollHeight - 150) {
      // if (endApiHit) return setLoadingPost(false)
      setPages(pages + 1)
    }
  }

  const onLinkClick = (e) => {
    setPages(1)
    setKey(e)
    navigate("#" + e)
    setInputs((prev) => ({ ...prev, q: "" }))
  }

  const init = async () => {
    console.log("init called")
    const { hash } = path
    console.log("init hash", hash)

    const initialHash = hash && hash.split("#")[1]
    if (initialHash && initialHash !== "") {
      setKey(() => initialHash)
      console.log("hash>>>", initialHash)
    }
    const params = { list: true, multiple: true }
    if (inputs?.q) params.q = inputs?.q
    if ("#" + key === hash || hash === "")
      if (!/allprivatepost/.test(key)) await getOrgDetails[key]({ page: pages })
  }

  useEffect(() => {
    if (inputs?.q) return console.log("q changes")
    init()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs?.q, key])

  const init2 = async () => {
    if (page && !/allprivatepost/.test(key)) {
      const response = await getOrgDetails[key]({ page: pages, skip: true })
      console.log("pagination", response)
    }
    return
  }

  useEffect(() => {
    if (pages < 2) return
    init2()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages])

  return (
    <>
      <IndexNavbar />
      <section className="organisation_list">
        <div className="container">
          <div className="row">
            <div className="d-flex">
              <div className="col-sm-7">
                {" "}
                <h2 className="blog_heading mb-4">Organisation</h2>
              </div>
              <div className="ms-auto p-2 col-sm-3">
                <InputGroup className="mb-3 p-2">
                  <Form.Control
                    placeholder="Search Organisation"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    name="q"
                    value={inputs?.q || ""}
                    onChange={(e) =>
                      setInputs((prev) => ({
                        ...prev,
                        [e.target.name]: e.target.value,
                      }))
                    }
                  />
                  <Button
                    variant="btn-light"
                    id="button-addon2"
                    className="btn btn-success ps-3 pe-3"
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                </InputGroup>
              </div>
            </div>
          </div>
          {/* <p>dd</p> */}
          <div className="org_tab">
            <Tabs
              defaultActiveKey="all"
              activeKey={key}
              id="uncontrolled-tab-example"
              onSelect={(e) => onLinkClick(e)}
              className="mb-3"
            >
              <Tab eventKey="all" title="All">
                <div
                  className="row post_area_infinite h_100v scrollBar"
                  onScroll={(e) => handleScroll(e, "all")}
                >
                  {isSuccess && orgList?.length > 0 ? (
                    orgList?.map((item, index) => (
                      <>
                        <div className="col-sm-3">
                          <div className="cmn_box_new blog_main">
                            <div className="blog_content_box2">
                              <div className="col-sm-12">
                                <div className="row">
                                  <Link
                                    to={`/org/${item?.pageName}/#post/#active/`}
                                  >
                                    <div
                                      className="blog_icon"
                                      style={{
                                        backgroundImage: `url(${item?.organiztionLogo ||
                                          "/assets/web_img/choto_logo_1.png"
                                          })`,
                                      }}
                                    ></div>
                                  </Link>
                                  <div>
                                    <Link
                                      to={`/org/${item?.pageName}/#post/#active/`}
                                    >
                                      <div className="cl_b">
                                        {item?.showName || item?.pageName}
                                      </div>
                                    </Link>
                                    <small>
                                      {item?.totalFollowers} followers,
                                      {item?.totalLikes} likes,{" "}
                                      {item?.totalActiveMembers} members{" "}
                                    </small>

                                    <pre className="cl_b short_des mt-1">
                                      Des : {item?.details}
                                    </pre>
                                    <hr></hr>
                                    <div className="d-flex gap-1 flex-wrap">
                                      {item?.userid === currentUserId ? (
                                        <>
                                          <div className="text-center admin_mark">
                                            {" "}
                                            Admin{" "}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <OrgFollowManagement
                                            orgDetails={item}
                                            page="orglist"
                                          />
                                          <JoinMember orgDetails={item} />
                                          <OrganizationLike
                                            orgDetails={item}
                                            page="orglist"
                                          />
                                        </>
                                      )}
                                      {/* <div className="flex-grow-1 p-2"><div className="fs_12 cl_g">11/11/2022</div></div>
                                                            <div className=""><button type="button" className="btn btn-primary">Join</button>  <button type="button" className="btn btn-success ms-1">Follow</button></div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <>
                      <h1>No Organisation found</h1>
                    </>
                  )}
                </div>
              </Tab>
              <Tab eventKey="myorg" id="#myorg" title="My Organizations">
                <div
                  className="row post_area_infinite h_100v scrollBar"
                  onScroll={(e) => handleScroll(e, "myorg")}
                >
                  {orgList?.length > 0 ? (
                    orgList?.map((item, index) => (
                      <>
                        <div className="col-sm-3">
                          <div className="cmn_box_new blog_main">
                            <div className="blog_content_box2">
                              <div className="col-sm-12">
                                <div className="row">
                                  <Link
                                    to={`/org/${item?.pageName}/#post/#active/`}
                                  >
                                    <div
                                      className="blog_icon"
                                      style={{
                                        backgroundImage: `url(${item?.organiztionLogo ||
                                          "/assets/web_img/choto_logo_1.png"
                                          })`,
                                      }}
                                    ></div>
                                  </Link>
                                  <div>
                                    <Link
                                      to={`/org/${item?.pageName}/#post/#active/`}
                                    >
                                      <div className="cl_b">
                                        {item?.showName || item?.pageName}
                                      </div>
                                    </Link>
                                    <small>
                                      {item?.totalFollowers} followers,
                                      {item?.totalLikes} likes,{" "}
                                      {item?.totalActiveMembers} members{" "}
                                    </small>

                                    <pre className="cl_b short_des mt-1">
                                      Des : {item?.details}
                                    </pre>
                                    <hr></hr>
                                    <div className="d-flex gap-1 flex-wrap">
                                      {item?.userid === currentUserId ? (
                                        <>
                                          <div className="text-center admin_mark">
                                            {" "}
                                            Admin{" "}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <OrgFollowManagement
                                            orgDetails={item}
                                            page="orglist"
                                          />
                                          <JoinMember orgDetails={item} />
                                          <OrganizationLike
                                            orgDetails={item}
                                            page="orglist"
                                          />
                                        </>
                                      )}
                                      {/* <div className="flex-grow-1 p-2"><div className="fs_12 cl_g">11/11/2022</div></div>
                                                            <div className=""><button type="button" className="btn btn-primary">Join</button>  <button type="button" className="btn btn-success ms-1">Follow</button></div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <>
                      <h1>No Organisation found</h1>
                    </>
                  )}
                </div>
              </Tab>
              <Tab eventKey="joined" title="Joined Organizations">
                <div
                  className="row post_area_infinite h_100v scrollBar"
                  onScroll={(e) => handleScroll(e, "joined")}
                >
                  {orgList?.length > 0 ? (
                    orgList?.map((item, index) => (
                      <>
                        <div className="col-lg-3 col-md-4 col-sm-6">
                          <div className="cmn_box_new blog_main">
                            <div className="blog_content_box2">
                              <div className="col-sm-12">
                                <div className="row">
                                  <Link
                                    to={`/org/${item?.pageName}/#post/#active/`}
                                  >
                                    <div
                                      className="blog_icon"
                                      style={{
                                        backgroundImage: `url(${item?.organiztionLogo ||
                                          "/assets/web_img/choto_logo_1.png"
                                          })`,
                                      }}
                                    ></div>
                                  </Link>
                                  <div>
                                    <Link
                                      to={`/org/${item?.pageName}/#post/#active/`}
                                    >
                                      <div className="cl_b">
                                        {item?.pageName}
                                      </div>
                                    </Link>
                                    <small>
                                      {item?.totalFollowers} followers,
                                      {item?.totalLikes} likes,{" "}
                                      {item?.totalActiveMembers} members{" "}
                                    </small>

                                    <pre className="cl_b short_des mt-1">
                                      Des : {item?.details}
                                    </pre>
                                    <hr></hr>
                                    <div className="d-flex gap-1 flex-wrap">
                                      {item?.userid === currentUserId ? (
                                        <>
                                          <div className="text-center admin_mark">
                                            {" "}
                                            Admin{" "}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <OrgFollowManagement
                                            orgDetails={item}
                                            page="orglist"
                                          />
                                          <JoinMember orgDetails={item} />
                                          <OrganizationLike
                                            orgDetails={item}
                                            page="orglist"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <>
                      <h1>No Organisation found</h1>
                    </>
                  )}
                </div>
              </Tab>
              <Tab eventKey="allprivatepost" title="All Private Posts">
                <AllPrivatePost />
              </Tab>


              <Tab eventKey="mysubscriptions" title="My Subscriptions">
                <div className="premierposts_in">
                  {/* <h2 className="fs-5 mt-4 mb-4">Premier Posts</h2> */}
                  <Tabs
                    defaultActiveKey="active"
                    id="uncontrolled-tab-example"
                    className="bg-transparent shadow-none mb-"
                    style={{ left: "0", top: "10px" }}
                  >
                    <Tab eventKey="active" title="Active">
                      {mySubscriptions?.length > 0 ? (
                        <div className="mt-5 row">
                          {mySubscriptions?.map((item) => {
                            return (
                              <div className=" col-lg-4">
                                <PrivatePostCard
                                  key={item?._id}
                                  // predirect={""}
                                  post={item}
                                />
                              </div>
                            )
                          })}
                        </div>
                      ) : (
                        <h2 className="text-center mt-5 mb-4">No post found</h2>
                      )}
                    </Tab>

                  </Tabs>
                </div>
              </Tab>

              <Tab eventKey="analytics" title="My Analytics">
                {/analytics/.test(path.hash) && <OrgMyAnalytice />}
              </Tab>
            </Tabs>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
OrgListing.loadData = (params, search) => async (dispatch) => {
  // const param = { list: true}
  // console.log(key)
  // return dispatch(await getOrganizationDetailsx(param))
}
const mapStateToProps = (state) => {
  console.log("orglist", state)
  const { isSuccess, isError, orgList, isProcessing, page, mySubscriptions } =
    state.organization
  return {
    isSuccess,
    isError,
    orgList: orgList,
    isProcessing,
    page,
    mySubscriptions,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOrgList: (params) => {
      dispatch(getOrganizationDetailsx(params))
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OrgListing)
