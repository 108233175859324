import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function LoginStatus({showname}) {
	const lastLocation = useLocation()

    return (
        <div className='bottom_login shadow_1 p-3'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <h3 className='text-center'>Want to know more about {showname}</h3>
                        <p className='fs_13 text-center'>Log into Allphanes</p>
                        <div className='d-flex justify-content-center align-items-center'>
                        <Link to={"/auth/login?redirectTo=" + lastLocation.pathname + lastLocation.search}>
                            <button className='btn btn-primary'>
                                Login
                            </button>
                        </Link>

                        <span className='mx-2'>OR</span>
                        <Link to={"/auth/registration?redirectTo=" + lastLocation.pathname + lastLocation.search}>
                            <button className='btn btn-success'>
                                Sign up
                            </button>
                        </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginStatus