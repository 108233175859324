import React, { useEffect, useState } from "react"
import { AdForBangalKobiSammeloni } from "../AppComponents/Helpers"
import { connect, useDispatch } from "react-redux"
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer"
// import NewsList from "../NewsList"
import NewsList from "../../components/DesignComponents/NewsList"


function RightBar(props) {
	const { trendingCreations } = props
	const dispatch = useDispatch()
	const token = typeof window !== "undefined" ? localStorage.getItem('token') : ""

	const getSinglePost = async () => {
		let params = {
			page: 1
		}
		let req = {
			params: params,
			url: 'trending',
			action: 'ACTION_GET_POST_SUCCESS',
		}
		await dispatch(getRequestHandler(req))
	}

	useEffect(() => {
		getSinglePost()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	return (
		<section id="rightBar" className="py-3">
			<AdForBangalKobiSammeloni />
			<div className="trend-sec mt-3">
				<div className="trend-title mb-2">Trending Creations in your Area</div>
				<div className="trending_creations scrollBar">
					<NewsList
						trendingCreations={trendingCreations}
					/>
				</div>
			</div>
		</section>

	)
}

const mapStateToProps = (state) => {
	const { trendingCreations } = state.posts;
	return { trendingCreations }
};
const mapDispatchToProps = (dispatch) => {
	return {}
};
export default connect(mapStateToProps, mapDispatchToProps)(RightBar);
