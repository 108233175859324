import React, { useState, useEffect, useRef } from "react"
import Compressor from 'compressorjs'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import VideocamIcon from '@mui/icons-material/Videocam'
import CustomizedSnackbars from "../Loaders/Snuckbars"
import SimpleCircle from "../Loaders/SimpleCircle"
import { connect, useDispatch } from "react-redux"
import { getRequestHandler, patchRequestHandler2, postRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { FileViewery } from "../AppComponents/Helpers"
import { CheckExtention } from "../../../_helpers/Variables"
import VideoThubnails from "./VideoThubnails"

function EditPosts(props) {
    const { domains, createPostThubnails } = props
    const [post] = useState(props?.editPost)
    const [postText, setPostText] = useState({
        postDescription: post?.postDescription,
        postTitle: post?.postTitle,
        keywords: post?.keywords2,
        postCategory: post?.postCategory,
        postSubDomain: post?.postSubDomain,
        language: post?.language
    })
    const [fileInputState, setFileInputState] = useState("")
    const [previewSource, setPreviewSource] = useState(props?.editPost?.postInfo[0]?.postImagePath || "")
    const [selectedFile, setSelectedFile] = useState(null)
    const [errMsg, setErrMsg] = useState("")
    const [fileType, setFileType] = useState("")
    const [loaderSwitch, setLoaderSwitch] = useState(props.spinner)
    const [domainId, setDomainId] = useState("")
    const [subDomains] = useState([])
    const [showTitle, setShowTitle] = useState(false)
    const [shareDial] = useState(false)
    const [postIdx] = useState('')
    const editorRef = useRef()
    const { Heic2Jpg, CKEditor, ClassicEditor } = editorRef.current || {}
    const [editorShow, setEditorShow] = useState(false)
    const [editorLoaded, setEditorLoaded] = useState(false)
    const [previewLoader] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => setEditorShow(/Blogs/.test(postText?.postCategory)), [postText])

    useEffect(() => {
        editorRef.current = {
            Heic2Jpg: require('../ImageHelpers').Heic2Jpg,
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
            ClassicEditor: require('@ckeditor/ckeditor5-build-classic')
        }
        setEditorLoaded(true)
    }, [])

    const [filePreview, setFilePreview] = useState({ videoThumbnil: post?.videoThumbnil })
    const [fileRawData, setFileRawData] = useState({ videoThumbnil: post?.videoThumbnil })
    const [fileInputStateT, setFileInputStateT] = useState("")
    const [thubCompShow, setThubCompShow] = useState(false)
    const [isThubnailUpload, setIsThubnailUpload] = useState(false)

    const handleThumbnailChange = (e) => {
        const file = e.target.files[0]
        setFileInputStateT(e.target.value)
        const typeImage = file.type
        if (/image/.test(typeImage)) {
            new Compressor(file, {
                quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
                success: (compressedResult) => {
                    setFileRawData((prev) => ({
                        ...prev,
                        [e.target.name]: compressedResult,
                    }))

                },
            })
        }
    }

    const imageUploader = async () => {
        if (!fileRawData?.videoThumbnil?.name) return
        const data = new FormData()
        // data.append("files", file, file?.name)
        data.append("files", fileRawData?.videoThumbnil, fileRawData?.videoThumbnil?.name)
        setIsThubnailUpload(true)
        let req = {
            data: data,
            url: "blogImage",
            action: 'ACTION_ADD_THUMBNAIL'
        }
        const response = await dispatch(postRequestHandler(req))
        setIsThubnailUpload(false)

        return response.data.responseData
    }

    useEffect(() => {
        if (!fileRawData?.videoThumbnil?.name) return console.log("fdfd")
        const kk = async () => {
            const res = await imageUploader()
            setFilePreview(prev => ({ ...prev, videoThumbnil: res }))
        }
        kk()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileRawData])

    useEffect(() => {
        if(fileInputState || props?.editPost?.postInfo[0]?.postImagePath) {
            const getFileTypeCode = CheckExtention(fileInputState || props?.editPost?.postInfo[0]?.postImagePath)
        setThubCompShow(/data:video|data:audio/.test(fileInputState || props?.editPost?.postInfo[0]?.postImagePath) || getFileTypeCode === 1 ? true : false)
        }
        
    }, [fileInputState, props?.editPost?.postInfo])

    const init = async () => {
        try {
            let req = {
                url: 'domains',
                action: 'ACTION_GET_DOMAINS'
            }
            await dispatch(getRequestHandler(req))
        } catch (error) {
            console.error(error)
        }
    }

    const compressImage = file => {
        new Compressor(file, {
            quality: 0.7, // 0.6 can also be used, but its not recommended to go below.
            // convertTypes: ['image/heif'],
            success: (compressedResult) => {
                previewFile(compressedResult)
                setSelectedFile(compressedResult)
            },
        })
    }

    const handleFileInputChange = async e => {
        const file = e.target.files[0]
        const typeImage = file.type
        setFileType(fileType)
        if (/image/.test(typeImage)) {
            if (/heic|heif/.test(typeImage)) {
                const response = await Heic2Jpg(file)
                let filex = new File([response], "heic.jpg", { type: "image/jpeg", lastModified: new Date().getTime() })
                compressImage(filex)
            } else
                compressImage(file)

        } else {
            previewFile(file)
            setSelectedFile(file)
        }
        // setLoadings(prev => ({ ...prev, previewLoader: false }))
        setFileInputState(e.target.value)

    }



    const previewFile = file => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            setPreviewSource(reader.result)
        }
        reader.onerror = () => {
            console.error("AHHHHHHHH!!")
            setErrMsg("Post render failed. File size is too large.")
        }
    }

    const clearImg = () => {
        setFileInputStateT()
        setThubCompShow(false)
        setPreviewSource("")
        setSelectedFile(null)
      }

    const detectURLs = message => {
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
        return message.match(urlRegex)
    }

    const handleChange = e => {
        e.persist()
        if (e.target.name === "postDescription") {
            let urlCheck = detectURLs(e.target.value)
            if (urlCheck) return false
        }
        setPostText((values) => ({ ...values, [e.target.name]: e.target.value }))
        if (e.target.name === "postCategory") {
            const index = e.target.selectedIndex
            setDomainId(index)
        }
    }

    const handleSubmit = async e => {
        try {
            setLoaderSwitch(!loaderSwitch)
            let galleryType, kk = postText.keywords || '', ll = ''
            const fileType = selectedFile?.type
            if (
                fileType === "image/png" ||
                fileType === "image/jpg" ||
                fileType === "image/jpeg" ||
                fileType === "image/webp"
            )
                galleryType = "photos"
            if (fileType === "audio/mpeg") galleryType = "audio"
            if (fileType === "video/mp4") galleryType = "video"
            const rex = new RegExp('image', 'g')
            const re = new RegExp('#', 'g')
            if (kk !== '') ll = kk.replace(re, '')
            const postC = postText.postCategory
            const postD = postText.postSubDomain
            const pLanguage = postText.language
            const postdescription = postText.postDescription
            const postTitle = postText.postTitle
            const data = new FormData()
            if (props?.pageid) data.append("pageId", props?.pageid)
            data.append("postTitle", postTitle)
            data.append("videoThumbnil", filePreview?.videoThumbnil || (createPostThubnails?.length > 0 && createPostThubnails[0]))
            data.append("postDescription", postdescription)
            data.append("postCategory", postC)
            data.append("postSubDomain", postD)
            data.append("language", pLanguage)
            data.append("keywords", ll)
            data.append("keywords2", kk)
            data.append("gallId", post?.postInfo[0]?._id || '')

            if (selectedFile) {
                data.append("isFile", fileInputState ? true : false)
                data.append("galleryType", galleryType)
                rex.test(fileType) ?
                    data.append("image", selectedFile, selectedFile.name)
                    : data.append("image", selectedFile)
            }

            let req = {
                data: data,
                url: 'editPosts',
                qs: `postid=${post?._id}`
            }
            const response = await dispatch(patchRequestHandler2(req))
            setLoaderSwitch(false)
            if (response?.data?.ack === 0) return setErrMsg(response?.data?.message)
            // props?.close()
            window.location.reload(false)
        } catch (err) {
            console.error(err)
            setErrMsg("Post Edit failed due to some technical issue. Please try again later.")
        }
    }

    function uploadAdapter(loader) {
        return {
            upload: async () => {
                const data = new FormData()
                loader.file.then(async (file) => {
                    data.append("files", file)
                    // const response = get
                    let req = {
                        data: data,
                        url: 'blogImage'
                    }

                    const response = await dispatch(postRequestHandler(req))
                    return response.data.responseData
                })

                return
            },
        }
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader)
        }
    }

    // error clearing after 5s
    useEffect(() => {
        if (!errMsg) return
        setTimeout(() => setErrMsg(""), [5000])
    }, [errMsg])

    useEffect(() => {
        if (props?.issubmit) handleSubmit()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.issubmit])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => !postText?.postCategory || postText?.postCategory === 'Normal' ? setShowTitle(false) : setShowTitle(true), [postText?.postCategory])
    // useEffect(() => postText.postDescription || previewSource ? setCreatePost(true) : setCreatePost(false), [postText.postDescription, previewSource])

    useEffect(() => {
        init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div id="createPost" className="mt-3">
            <div className="post_category_sec">
                <div className="dom_title">Tags </div>
                <div className="w-100">
                    <select name="language" onChange={handleChange}>
                        <option className="t_cap" value="English">
                            Language
                        </option>
                        {domains?.language?.length > 0 &&
                            domains?.language?.map((item, index) => {
                                return item?.language === post?.language ?
                                    <option className="t_cap" value={item?.language} key={index} selected>
                                        {item?.language}
                                    </option>
                                    : <option className="t_cap" value={item?.language} key={index}>
                                        {item?.language}
                                    </option>

                            })}
                    </select>
                </div>

                <div className="w-100">
                    <select name="postCategory" onChange={handleChange}>
                        <option className="t_cap" value="Normal">
                            Domain
                        </option>

                        {domains?.domains?.length > 0 &&
                            domains?.domains?.map((item, index) => {
                                return item?.postCategory === post?.postCategory ?
                                    <option
                                        className="t_cap"
                                        id={item?._id}
                                        value={item?.postCategory}
                                        key={index}
                                        selected
                                    >
                                        {item?.postCategory}
                                    </option>
                                    :
                                    <option
                                        className="t_cap"
                                        id={item?._id}
                                        value={item?.postCategory}
                                        key={index}
                                    >
                                        {item?.postCategory}
                                    </option>

                            })}
                    </select>
                </div>

                <div className="w-100">
                    <select name="postSubDomain" onChange={handleChange}>
                        <option className="t_cap" value="Normal">
                            Sub domain
                        </option>
                        {subDomains?.length <= 0 ?

                            <option
                                className="t_cap"
                                value={post?.postSubDomain}
                                key={post?.postSubDomain + 'u'}
                                selected={post?.postSubDomain ? 'selected' : ''}
                            >
                                {post?.postSubDomain}
                            </option>
                            : domainId > 0 &&
                            domains?.domains[domainId - 1]?.subDomain.map((item, index) => {
                                return (
                                    <option
                                        className="t_cap"
                                        value={item?.postSubCategory}
                                        key={index}
                                    >
                                        {item?.postSubCategory}
                                    </option>
                                )
                            })}
                    </select>
                </div>
            </div>
            <div className="middle-sec-box post-area profile-sec2">
                <div className="post-text po_text">
                    <form onSubmit={handleSubmit} className="post_form">
                        <div className="d-flex">

                            <div className="w-100">

                                {showTitle && <>
                                    <label className="post_label fs-14">
                                        Title :
                                    </label>
                                    <br />
                                    <input
                                        type="text"
                                        className="form-control1 w-100 mb-3"
                                        name="postTitle"
                                        onChange={handleChange}
                                        value={postText?.postTitle || ""}
                                        placeholder="Enter title"
                                    // required='required'
                                    />
                                </>}

                                {!editorShow &&
                                    <textarea
                                        className="form-control"
                                        rows="4"
                                        placeholder="Share your ideas"
                                        name="postDescription"
                                        onChange={handleChange}
                                        value={postText?.postDescription || ""}
                                    ></textarea>
                                }



                                {showTitle && <>
                                    <label className="post_label fs-14">
                                        HashTags :
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control1 w-100 mt-3"
                                        name="keywords"
                                        onChange={handleChange}
                                        value={postText.keywords || ""}
                                        placeholder="Enter hash tags"
                                    // required='required'
                                    />
                                </>}
                            </div>
                        </div>
                        <div className="post_action my-3">
                            {/* {!isEmbed && */}
                            {thubCompShow &&
                                <div className="modal_image_input_1">
                                    <label title="Click to Attach Photo">
                                        <InsertPhotoIcon /> Thumbnail Photo
                                        <input
                                            type="file"
                                            name="videoThumbnil"
                                            accept="image/png, image/gif, image/jpeg"
                                            onChange={handleThumbnailChange}
                                            value={fileInputStateT}
                                        />
                                    </label>
                                </div>
                            }
                            <>
                                {previewLoader ?
                                    <> <SimpleCircle /></>
                                    :
                                    <div className="modal_image_input_1">
                                        <label title="Click to Attach Photo">
                                            {editorShow ? <>
                                                <InsertPhotoIcon /> Thumbnail Photo
                                            </> :
                                                <>
                                                    <InsertPhotoIcon /><span className="m_none">Photo</span>{" / "}
                                                    <VideocamIcon /><span className="m_none">video</span>
                                                </>
                                            }
                                            <input
                                                type="file"
                                                name="postImage"
                                                onChange={handleFileInputChange}
                                                value={fileInputState}
                                            />

                                        </label>
                                    </div>
                                }

                            </>

                            {/* } */}
                        </div>

                    </form>

                </div>
            </div>
            <div className="create_post_img">
                {previewSource &&
                    <>
                        <div className="row mt-3 mb-3">
                            <div className="col-sm-5">
                                {previewSource ? (
                                    <>
                                        <div
                                            className="cross"
                                            title="click to discard image"
                                            onClick={clearImg}
                                        >
                                            x
                                        </div>

                                        <FileViewery link={previewSource} />
                                    </>
                                ) : (
                                    ""
                                )
                                }

                                {isThubnailUpload ?
                                    <>
                                        <div className="create_post_img__1">
                                            <span className="text-white">
                                                Uploading your thumbnail please wait...
                                            </span>
                                            <SimpleCircle />
                                        </div>
                                    </> :
                                    thubCompShow && filePreview?.videoThumbnil ? (
                                        <div className="create_post_img__1">
                                            <div
                                                className="cross"
                                                title="click to discard image"
                                                onClick={() => {
                                                    setFilePreview((prev) => ({ ...prev, videoThumbnil: "" }))
                                                    setFileRawData((prev) => ({ ...prev, videoThumbnil: "" }))
                                                }}
                                            >
                                                x
                                            </div>
                                            <img
                                                className="height_max img-responsive"
                                                src={filePreview?.videoThumbnil || ""}
                                                alt="Select thumbnail"
                                            />
                                        </div>
                                    ) : (
                                        ""
                                    )}
                            </div>
                            {thubCompShow &&
                                <div className="col-sm-7 text-start">
                                    <div className="row">
                                        <h6 className="bg-success mt-2 p-2 text-white">Upload a thumbnail Or select any one from this four options</h6>
                                        <VideoThubnails
                                            thubnails={createPostThubnails}
                                            setFilePreview={setFilePreview}
                                        />

                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }

            </div>
            {editorShow ?
                editorLoaded ?
                    <CKEditor
                        editor={ClassicEditor}
                        data={postText.postDescription || ""}
                        config={{
                            extraPlugins: [uploadPlugin],
                            link: {
                                // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                addTargetToExternalLinks: true,
                                decorators: [
                                    {
                                        mode: 'manual',
                                        label: 'External Link',
                                        attributes: {
                                            target: '_blank',
                                        }
                                    }
                                ]
                            }
                        }}
                        onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            //console.log('Editor is ready to use!', editor)
                        }}
                        onChange={(event, editor) => {
                            setPostText((editObj) => ({
                                ...editObj,
                                postDescription: editor.getData(),
                            }))
                        }}
                        onBlur={(event, editor) => {
                            // //console.log( 'Blur.', editor )
                        }}
                        onFocus={(event, editor) => {
                            // //console.log( 'Focus.', editor )
                        }}
                    /> : <div><SimpleCircle /></div> : ''}

            {shareDial && postIdx && <CustomizedSnackbars type={0} msg={"Your post updated succefully"} />}
            {errMsg && <CustomizedSnackbars type={1} msg={errMsg} />}


        </div>
    )
}

const mapStateToProps = (state) => {
    console.log("stateoRgpost", state)
    const { domains, isSuccess, isError, isProcessing, createPostStatus, createPostThubnails } =
        state.posts
    return {
        domains,
        isSuccess,
        isError,
        isProcessing,
        createPostStatus,
        createPostThubnails
    }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(EditPosts)