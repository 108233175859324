import React, { useState, useEffect } from "react"
import SimpleCircle from "./Loaders/SimpleCircle"
import { useDispatch } from "react-redux"
import { getRequestHandler, postRequestHandler } from "../redux/reducers/requestHandler.reducer"

function ReplyComments(props) {
    const [allComments, setAllComments] = useState([])
    const [replyComments, setReplyComments] = useState()
    const token = localStorage.getItem("token")
    const dispatch = useDispatch()

    const getAllReplyComments = async e => {
        let req = {
            params: { cid: props.commentid },
            url: 'replyComments'
        }
        const response = await dispatch(getRequestHandler(req))
        if (props.changereply) props.changereply(response.data.responseData.length)
        setAllComments(response.data.responseData)
    }

    const handleChange = e => {
        setReplyComments(e.target.value)
    }
    const [isLoaderCom, setIsLoaderCom] = useState(false)

    const handleCommentClick = async () => {
        setIsLoaderCom(true)

        const dataArr = {
            referenceUserId: token,
            referencePostId: props.postid,
            referenceCommentId: props.commentid,
            messageText: replyComments
        }
        let req = {
            data: dataArr,
            url: 'replyComments'
        }
        const dataPost = await dispatch(postRequestHandler(req))
        setIsLoaderCom(false)

        if (dataPost) {
            setReplyComments('')
            getAllReplyComments()
        }
    }

    useEffect(() => {
        if (props.show)
            getAllReplyComments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show])

    return (
        <>
            <div style={{ position: "relative" }}>
                <div className="row">

                    <div className="col-lg-12">
                        {allComments && allComments.map((item, index) => {
                            return (

                                <div className="commentsDiv" key={index}>
                                    <div className="pro_img_com">
                                        {item.referenceUserId ? (
                                            <div className="d-flex ">
                                                {item.referenceUserId.profilePhoto ? (<img src={item.referenceUserId.profilePhoto} alt="members profile" />) : (<img src='/assets/web_img/choto_logo_1.png' alt="profile" />)}

                                            </div>
                                        ) : ''}
                                    </div>

                                    <div className="commentsView">
                                        <div className="commentBox">
                                            <div className="fs-14 viewD">
                                                <span className="user_name">{item.referenceUserId.showName} :</span>
                                                {item.messageText ? item.messageText : ''}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>

                <div className="mt-3 add_comment d-flex px_15 bb-1">
                    <div className="pro_img">
                        {props.tokenuser ? (<img src={props.tokenuser} alt="members profile" />)
                            : (<img src={'/assets/web_img/choto_logo_1.png'} alt="members profile" />)}
                    </div>
                    <input type="text" name="replyComment" onChange={handleChange} className="comment comment1" placeholder='Add reply' value={replyComments} />

                    {isLoaderCom ?
                        <div className="commentPost" >
                            <SimpleCircle />
                        </div>
                        : <div className="commentPost" onClick={handleCommentClick}>
                            Reply
                        </div>}
                </div>




            </div>
        </>
    )
}

export default ReplyComments