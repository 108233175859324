import React, { useState } from "react"
import CropOriginalIcon from "@mui/icons-material/CropOriginal"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import { ImageUploadDialog } from "../../../_helpers/Material"
import { SpaceProfileImg } from "../../components/AppComponents/Helpers"



export const OrgProfileBar = (props) => {
    const { orgDetails, isAdmin } = props
    return (
        <>
            <div className="org_profile_name mt-2">
                <p className="text-primary">{orgDetails?.showName || orgDetails?.pageName}</p>
                {isAdmin && <div className="mt-2 admin_stl bg-dark ps-2 pe-2">Admin</div>}
            </div>


        </>
    )
}

const PremiumFrontSection = (props) => {
    const { orgDetails, adminCount, isAdmin } = props
    const currentUserId =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const [modalShow, setModalShow] = useState(false)
    const [PathInfo, setPathInfo] = useState("")
    const [uploadedFor, setUploadedFor] = useState("")

    const handleModalClick = (path) => {
        setModalShow(true)
        setPathInfo(path)
        setUploadedFor(path === 1 ? "logo" : "background")
    }
    return (
        <>
            <section className="profile_hero" id="profileHero">
                <div
                    className="profile_cover"
                    style={{
                        backgroundImage: `url(${orgDetails?.BackgroundImage || "/assets/web_img/choto_logo_1.png"
                            })`,
                    }}
                >
                    {orgDetails?.userid === currentUserId && (
                        <button
                            type="button"
                            className="edit_cover_pic_btn shadow"
                            title="Change cover photo"
                            onClick={() => handleModalClick(0)}
                        >
                            <CropOriginalIcon />
                        </button>
                    )}
                </div>
                <div className="container org_cover">
                    <div className="profile_outer_photo bg_allp2">
                        <div className="profile_inner_photo">
                            <div className="img_box">
                                <SpaceProfileImg
                                    link={orgDetails?.organiztionLogo}
                                    clas={`img_box`}
                                />
                            </div>
                            {orgDetails?.userid === currentUserId && (
                                <div
                                    title="Change profile photo"
                                    className="edit_pro text-center mt-1"
                                    onClick={() => handleModalClick(1)}
                                >
                                    <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* <OrgProfileInfos orgDetails={orgDetails} token={currentUserId} /> */}

                    <OrgProfileBar
                        orgDetails={orgDetails}
                        adminCount={adminCount}
                        token={currentUserId}
                        isAdmin={isAdmin}
                    />
                </div>
            </section>
            {modalShow && (
                <ImageUploadDialog
                    pathinfo={PathInfo}
                    close={() => setModalShow(false)}
                    uploadapi={uploadedFor}
                    orgid={orgDetails?._id}
                />
            )}
        </>
    )
}

export default PremiumFrontSection
