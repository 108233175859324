import React, { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import MembarNav from "../../components/Members/MembarNav"
import { Footer, IndexNavbar } from "../../components"
import { connect, useDispatch } from "react-redux"
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { LDS } from "../../components/Loaders/SimpleCircle"
import MembersCard from "./MembersCard"
import Followers from "../MySpace/Followers"

function Members(props) {
	const [loader, setLoader] = useState(true)
	const { members } = props
	const dispatch = useDispatch()
	const [Q, setQ] = useState("")
	const [page, setPage] = useState(1)
	const [followerOpen, setFollowerOpen] = useState(false)

	const getAllMembers = async () => {
		try {
			let params = {
				page: page
			}
			if (Q) params.q = Q
			let req = {
				params: params,
				url: 'members',
				action: 'ACTION_GET_MEMBERS_SUCCESS'
			}
			await dispatch(getRequestHandler(req))
		} catch (err) {
			console.error(err)
		}
	}

	const handleChange = (e) => {
		setQ(e.target.value)
	}

	const handleScroll = (e) => {
		let scrollTop = window.pageYOffset
		let clientHeight = window.document.documentElement.clientHeight
		let bodyHeight = window.document.body.clientHeight

		if (clientHeight + scrollTop > bodyHeight - 100) {
			window.removeEventListener("scroll", handleScroll)
			setPage(page + 1)
		}
	}

	useEffect(() => {
		if (!localStorage.getItem("token")) {
			return <Navigate to="/auth/login" />
		}
	}, [])

	useEffect(() => {
		if (members?.length > 0) {
			window.addEventListener("scroll", handleScroll)
			setLoader(false)
		}
		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [members])

	useEffect(() => {
		if (loader) setTimeout(() => setLoader(false), [3000])
	}, [loader])

	useEffect(() => {
		if (Q) getAllMembers()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Q])

	useEffect(() => {
		if (page > 1) getAllMembers()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page])

	return (
		<>
			<IndexNavbar />
			<div className="container-fluid bg_allp" style={{ minHeight: "100vh" }}>
				<div className="container">
					<div className="container background_opacity">
						<div className="row">
							<div className="d-flex justify-content-between align-items-center flex_wrap mb-4 mt-3">
								<MembarNav />
								<div className="mb_w_100">
									<input
										type="search"
										name="search"
										id="memberSearch"
										className="member_search_1 outline_1"
										placeholder="Find members"
										onChange={handleChange}
										value={Q || ""}
									/>
								</div>
							</div>
							{loader ?
								<div className="load_body">
									<LDS />
								</div> :
								members?.length > 0 &&
								<MembersCard
									members={members}
								/>
							}
							{followerOpen && <Followers
								open={followerOpen}
								setOpen={setFollowerOpen}
							/>}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

Members.loadData = (params, search) => async (dispatch) => {
	let params = {
		page: 1
	}
	let req = {
		params: params,
		url: 'members',
		action: 'ACTION_GET_MEMBERS_SUCCESS'
	}
	return await dispatch(getRequestHandler(req))
}
const mapStateToProps = (state) => {
	console.log("stateoRgpostcreationpostsMembers", state)
	const { members } = state.members
	return { members }
}
const mapDispatchToProps = (dispatch) => {
	return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(Members)
