import React from 'react'
import { Link } from 'react-router-dom'

const UnderConstruction = () => {
    // const navigate = useNavigate()
    return (
        <>
            <section className="text-center">
                <Link to={'/'} >
                    <img className="img-responsive" src="/assets/web_img/under_cons.jpg" alt='underconstruction' />
                </Link>

            </section>
        </>
    )
}

export default UnderConstruction