import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import moment from 'moment/moment';
const token = typeof window !== "undefined" ? localStorage.getItem('token') : ""

function createData1(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

function createData(items) {
    return items;
  }

const rows = [
  createData({"name" : 'Frozen yoghurt', "calories": 159, "fat":6.0, "carbs": 24, "protein" :4.0})
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function DenseTable(props) {
    const {data, headings, fields} = props
    const k = ''

    const trActiveConditions = (i) =>{
        const lx = fields[i]
    }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {headings?.map(theadName=>{
                return <TableCell>{theadName}</TableCell>
            })}
            
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <TableRow
              key={row?._id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={ row?.referenceUserId === token ? {background : "#e6e3e3", color:"#fff !important"} : {background : "white"}}
            >
              <TableCell align="left">{index + 1}</TableCell>
              <TableCell align="left">{row?.postTitle}</TableCell>
              <TableCell align="left">{moment(row?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
              {headings.includes("Name") &&<TableCell component="th" scope="row">
                {row?.user_info[0]?.showName}
              </TableCell>}
              {/* <TableCell align="left">{Math.floor(row?.rankings || 0) }</TableCell> */}
              <TableCell align="left">{row?.totalViews || 0}</TableCell>
              <TableCell align="left">{row?.totalLikes}</TableCell>
              <TableCell align="left">{row?.totalComment}</TableCell>
              <TableCell align="left">
                <Link to={`/p/${row?._id}`}>
                <VisibilityIcon />
                </Link>
              </TableCell>

              {/* <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
