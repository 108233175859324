import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { FileViewer } from '../../components/AppComponents/Helpers';
import ManagementLeftNav from '../../components/Navbars/ManagementLeftNav';
import { getHomePosts, updateHomePosts } from '../../redux/reducers/postReducer';

function AdminHomePageManagement(props) {
    const { homePromotionPost } = props
    const [names] = useState(["no1", "no2", "no3", "no4", "no5", "no6"])
    const [inputs, setInputs] = useState({})
    const dispatch = useDispatch()

    const handleSubmit = (i,d) =>{
        try{
            if(!inputs[i]) return console.error("input error") 
            
            const data = {
                postIds:inputs[i],
                no:d+1,
                status : 'active'
            }
            dispatch(updateHomePosts(data))

        }catch(error){
            console.error(error)
        }
    }
    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-lg-2">
                        <ManagementLeftNav />
                    </div>
                    <div className="col-lg-10 bg_allp" style={{ minHeight: "100vh" }}>
                        <div className="col-sm-12">
                            <div className="row mb-4 ">
                                {names?.map((item,index)=>(
                                    <>
                                    <div className="d-flex mt-2">
                                    <input type="text"
                                        name={item}
                                        placeholder='add post id'
                                        value={inputs[item] || ''}
                                        onChange={e=>setInputs(prev=>({...prev, [e.target.name]: e.target.value}))}
                                    />
                                    <button type='button' onClick={()=>handleSubmit(item, index)}>Update</button>
                                </div>
                                    </>
                                ))

                                }
                                <div className="col-sm-12 new_about_text">
                                    <h2>Home Page Posts</h2>
                                </div>
                                {homePromotionPost?.map((item, index) => (

                                    <>
                                        <div className="col-md-2 col-sm-12" key={item?._id}>
                                            <div className="bg-white p-2 mb-2 rounded home_video">
                                                <div >
                                                    <FileViewer link={item?.postInfo[0]?.postImagePath} />
                                                </div>
                                            </div>
                                        </div>
                                    </>))
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

AdminHomePageManagement.loadData = (params, search) => async (dispatch) => {
    return dispatch(getHomePosts());
};
const mapStateToProps = (state) => {
    console.log("homeState", state);
    const { isSuccess, isError, isProcessing, homePromotionPost, winners } =
        state.posts;
    return {
        isSuccess,
        isError,
        isProcessing,
        homePromotionPost: homePromotionPost,
        winners: winners
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminHomePageManagement);