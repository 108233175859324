import React from "react";
import { Footer, IndexNavbar } from "../../components";

function Privacy() {
	return (
		<>
			<IndexNavbar />
			<div
				id="background_bg"
				style={{ backgroundImage: `url("/assets/web_img/background_bg.jpg")` }}
			>
				<div className="privecy_header">
					<div className="col-lg-12">
						Allphanes updated our Terms of Use, Data Policy, and Cookies Policy effectiveApr 15, 2023
					</div>
				</div>
				<div className="terms container privacy_policy">
					<div className="col-lg-12">
						<h2>Data and Privacy Policy</h2>
						<p>
							This policy describes the information we process to support products and features offered by Allphanes, Inc. and its affiliates.
						</p>
					</div>
					<div className="cmn_box">
						<h1>Kinds of information we collect?</h1>
						<p>
							To provide the Allphanes Products, we must process information about you.
						</p>
						<h3>Personal Data</h3>
						<p>We collect the following personal data to verify identity when you sign up for membership at Allphanes :</p>
						<ul>
							<li>First name</li>
							<li>Last name</li>
							<li>Email ID</li>
							<li>Phone number</li>
							<li>Language preference</li>
						</ul>

						<h3>You also need to provide a ‘User Name’ that will be visible to all other members. </h3>
						<p>
							We do not share your personal information listed above with any member of Allphanes, unless you choose to
							share your name and photo as your User Name and profile picture.
						</p>
						<p>We ask you for a unique password to create your account on Allphanes after we verify your email by sending a
							code to your email id. Do not share this password with anyone.</p>

						<p>In addition, you can sign in to Allphanes with your Google or Facebook account. In such cases we use your identity
							on these platforms to sign you in. However, we do not access any of your personal information on these platforms.</p>

						<h3>How we use your Personal Data</h3>
						<ul>
							<li>
								We use your email and phone to communicate with you about activities on Allphanes so that you can stay informed. We may call you
								to get your feedback and comments occasionally.
							</li>
							<li>
								We use your email and phone to communicate with you about activities on Allphanes so that you can stay informed.
								We may call you to get your feedback and comments occasionally.
							</li>
							<li>
								We reserve the right to continue to send you important information related to your account. For example we will send you an email when there is a
								material change in our terms of service.
							</li>
							<li>
								In future, we reserve the right to share your email id with advertisers you may be interested in. This allows them to
								contact you with products or services they offer. You can opt
								out of this by sending an email to <a href="mailto:info@allphanes.com"> info@allphanes.com</a> requesting that we do not share email id with Advertisers.
							</li>
						</ul>

						<h3>Things you and others do and provide.</h3>
						<p>
							Information and content you provide. We collect the content, communications and other information you
							provide when you use our Product, including when you sign up for an account, upload or share content,
							and communicate with others. This includes information about the content you provide, such as the location,
							tags, description, etc.  Our Productautomatically process content and communications you and others provide to
							analyze context and what's in them for the purposes described below.
						</p>
						<p>Posts on Allphanes</p>
						<ul>
							<li>Content you post on Allphanes belongs to you and you are responsible for making sure the content meets
								the conditions of our terms of service and does not violate the copyright of anyone else.
								Should we find out of any violation either through a report or our own curation, we will remove the
								identified content.</li>
							<li>We reserve the right, at our sole discretion, to remove any posts that violate our terms of service and guidelines
								listed there. You may not bring a claim against us for removing content at our sole discretion. If you do bring a
								claim you agree to bear all damages and legal fees consequent to such a claim.</li>
							<li>Private/Premium posts uploaded by creators or organizations will only be accessible based on how it was set-up on
								Allphanes. Members must subscribe to gain access to such posts. However, free posts are accessible to everyone
								on Allphanes.</li>
							<li>We reserve the right to share your posts for marketing purposes on Allphanes landing page as well as on other
								social media platforms. We will always give you (username only) credit for the content and this will help
								broader recognition of your work. If you want to opt out of us sharing your content for marketing please
								send us an email with this request at <a href="info@allphanes.com."> info@allphanes.com.</a></li>
						</ul>
						<h6><strong>Networks and connections.</strong> We collect information about the people you connect to and who you communicate with</h6>
						<h6><strong>Your usage. </strong> We collect information about how you use our Products, includingbut not
							exclusively types of content you view; the features you use; the actions you take; the people you interact with;
							the groups you join and the time, frequency and duration of your activities. </h6>
						<h6><strong>Information about transactions made on our Products.  </strong> If you use our Products for purchasing subscriptions, services or get payments that
							require financial transactions, we collect information about the purchase or transaction. This includes payment
							information, such as your credit or debit card number and other card information; bank details for payments;
							other account and authentication information; and billing, shipping and contact details.</h6>

						<h6><strong>Things others do that are related to you  </strong> We receive and analyze content, communications and
							information that other people provide when they use our Products. This can include information about you,
							such as when others share, like or comment on content youcreated, or send a message to you.</h6>

						<h6>Device Information</h6>
						<p>We collect information from and about the computers, phones, and other web-connected devices you use that
							integrate with our Product, and we combine this information across different devices you use. This helps
							to better personalize your use of our Product so you see content and ads better suited for your device location.</p>

						<h3>
							Information we obtain from these devices:
						</h3>
						<ul>
							<li><strong>Data from device settings:</strong> information you allow us to receive through device settings you turn on,
								such as access to your GPS location, camera or photos.</li>
							<li><strong>Network and connections:</strong>  information such as language, time zone, mobile phone number, IP address.</li>
							<li><strong>Cookie data: </strong> from cookies stored on your device, including cookie IDs and settings.</li>
						</ul>

						<h3>Information from partners.</h3>
						<p>
							Advertisers, service providerscan send us information about purchases they made on their site through access from our Product.
						</p>
						<h3>How do we use this information?</h3>
						<p>
							We use the information we have (subject to choices you make) as described below and to
							provide and improve the AllphanesProduct and related services described in the Allphanes Terms:
						</p>
						<h3>Provide, and improve our Products.</h3>
						<p>
							We use the information we have to deliver our Product, including to personalize features and content
							(including your ads) and make suggestions for you about content you may be interested in.
						</p>
						<ul>
							<li>
								<strong>Location-related information:</strong> We use location information-such as your current location, where you live,
								the places you like to go, and the businesses and people you're near-to provide, personalize
								and improve our Products, including ads, for you. Location-related information can be based on
								things like precise device location, and IP addresses.
							</li>
							<li>
								<strong>Research and development: </strong> The information we have can be used to develop, test and
								improve our Products, including by conducting surveys and research, and testing new products and features.
							</li>
							<li>
								<strong>Ads and other sponsored content: </strong> We use the information we have
								about you-including information about your interests, actions and connections-to select and personalize ads, offers
								and other sponsored content that we show you.
							</li>
						</ul>
						<h6>Provide measurement, analytics, and other business services.</h6>
						<p>
							We use the information we have (including your activity off our Product) to help advertisers and other partners
							measure the effectiveness and distribution of their ads and services, and to understand the types of people
							who use their services and how people interact with their websites, apps, and services.
						</p>
						<h6>Promote safety, integrity and security.</h6>
						<p>We use the information we have to verify accounts and violations of Allphanes Terms.</p>

						<h6>Communicate with you.</h6>
						<p>We use the information we have to send you marketing communications, communicate with you about our Products,
							and let you know about our policies and terms. We can use your information to respond to you when you contact us.</p>

						<h6>Support social good.</h6>
						<p>
							We use the information we have to support innovation on topics of general social welfare, technological advancement,
							public interest, health and well-being.
						</p>
						<h6>How is this information shared?</h6>
						<p>
							Your information is shared with others in the following ways:
						</p>
						<h6>Sharing on Allphanes Product</h6>
						<h6>People you share and communicate with</h6>
						<p>
							When you share content on Allphanesyou can chose to share it on the Free space, Premium space or
							Subscription space (also called Organization Private Post or Creator Premium Posts)
							<br />
							<br />
							If you chose to share in the Free space your content will be available for all Allphanes subscribers to see,
							like and provide comments. If you chose to share in the Premium space, you must subscribe to the Premium space.
							Only Premium subscribers can see content in the Premium space, like and comment on it. You can also chose to set up a
							Subscription space where you can charge your supporters and fans to pay the subscription charges to view your content.
							<br />
							<br />
							You can also connect to other members of Allphanes and make them your Friends. You can share content that you
							like with your Friends. Content that you upload to Allphanes can be viewed in the MySpace page.
							<br />
							<br />
							Comments you post on a creation will be seen by everyone who has access to the content. For example if the
							creation is in the Free Space, every member will be able to see the comment. If the creation is in the Premium Space,
							only Premium members will see the comment. In the Subscription space only people who subscribed to the celebrity
							will be able to see the creation and comments associated with it.
						</p>
						<h6>New owner.</h6>
						<p>
							If the ownership or control of all or part of our Products or their assets changes, we may transfer your information to the new owner.
						</p>
						<h3>Sharing with Third-Party Partners and members</h3>
						<p>
							We work with third-party partners and members who help us and improve our Product. We also impose strict
							restrictions on how our partners can use and disclose the data we provide. Here are the types of third
							parties we share information with:
						</p>
						<h3>Partners who use our analytics services.</h3>
						<p>
							We provide aggregated statistics and insights that help people and businesses understand how people are
							engaging with their creations, posts and ads.
						</p>
						<h6>Advertisers.</h6>
						<p>
							We provide advertisers with reports about the kinds of people seeing their ads and how their ads are performing,
							but we don't share information that personally identifies you unless you give us permission. We also confirm which
							ads led you to take an action with an advertiser.
						</p>
						<h6>Partners and members offering goods and services in our Products.</h6>
						<p>When you buy something from a seller or service provider in our Product, the seller can receive your public information and
							other information you share with them, as well as the information needed to complete the transaction, including shipping and
							contact details.</p>
						<h6>Vendors and service providers.</h6>
						<p>
							We provide information and content to vendors and service providers who support our business, such as by providing
							technical infrastructure services, analyzing how our Products are used, providing customer service, facilitating
							payments or conducting surveys.
						</p>
						<h6>Law enforcement or legal requests.</h6>
						<p>We share information with law enforcement or in response to legal requests that are related
							to compliance with laws of the country where the member resides.
							<br />
							<br />
							How do we respond to legal requests or prevent harm?
							<br />
							<br />
							We access, preserve and share your information with regulators, law enforcement or others:
						</p>

						<ul>
							<li>
								In response to a legal request (like a search warrant, court order or subpoena) if we have a good faith belief
								that the law requires us to do so.
							</li>
							<li>
								When we have a good-faith belief it is necessary to: detect, prevent and address fraud, unauthorized use
								of the Products, violations of our terms or policies, or other harmful or illegal activity; to protect
								ourselves (including our rights, property or Products), you or others, including as part of investigations or regulatory inquiries; or to prevent death or imminent bodily harm.
							</li>
						</ul>
						<p>
							Information we receive about you (including financial transaction data related to purchases made on our Product)
							can be accessed and preserved for an extended period when it is the subject of a legal request or obligation,
							governmental investigation, or investigations of possible violations of our terms or policies, or otherwise to
							prevent harm. We also retain information from accounts disabled for terms violations for at least a year to
							prevent repeat abuse or other term violations.
						</p>
						<h6>
							How do we operate and transfer data as part of our global services?</h6>
						<p>
							We share information globally, both internally within the Allphanes Companies, and externally with our partners and with
							those you connect and share with around the world in accordance with this policy. Your information may, for example,
							be transferred or transmitted to, or stored and processed in the United States or other countries outside of where
							you live for the purposes as described in this policy. These data transfers are necessary to provide the services to
							globally operate and provide our Products to you.
						</p>
						<h3>Account De-Activation</h3>
						<p>
							You can de-activate your account at any time. When you de-activate your account, you will not be able to access
							Allphanes within next 30days. Your content will also no longer be accessible on Allphanes.  If there are
							other subscribers to your content, account and content may remain active until their subscription expires in 30 days.
						</p>
						<p>
							Allphane also reserves the right to de-activate any account at our sole discretion. You may not bring a
							claim against us for removing content at our sole discretion. If you do bring a claim you agree to bear all
							damages and legal fees consequent to such a claim.
						</p>
						<h6>Payment Gateway Data Information</h6>
						<p>In order to facilitate online payments in a secure and safe manner, we partner with payment gateways.
							These payment gateways require you to share certain financial information to facilitate the transaction.
							This payment information is not shared with Allphanes and is securely maintained by the payment gateway.
							Allphanes does receive information about successful transactions and the identity of the buyer and creator/seller.
							This information is necessary for Allphanes to track payments and generate invoices. If a transaction fails,
							the payment gateway will inform you.
							<br />
							<br />
							You may pay using the Allphanes Wallet feature. You can add a balance to your wallet and upload a picture of your
							successful transaction for your wallet to be updated with a new balance. You can pay for products,
							services and subscriptions with the balance in your wallet. You can review balance and transaction
							history of your wallet. Only you and Allphanes Adminstrators have access to your wallet information and it
							will not be shared with anyone else. You can withdraw balance in your wallet at any time.
						</p>
						<h6>How will we notify you of changes to this policy?</h6>
						<p>
							Wewill notify you before we make changes to this policy and give you the opportunity to review the revised
							policy before you choose to continue using our Product.
						</p>

						<h6>How to contact us with questions</h6>
						<p>If you have questions about this policy, you can contact us as described below.
							<br />
							You can contact us online or by mail at:
						</p>


						<p>
							<strong>
								Allphanes, Inc. <br />
								ATTN: Privacy Operations<br />
								7706 Grand Pass Lane<br />
								Katy, TX 77494
							</strong>
							<br />
							<br />
							<a href="mailto:info@allphanes.com"> info@allphanes.com</a>
						</p>
						<div className="privecy_footer">
							<p>Date of Last Revision: Apr 15, 2023</p>
						</div>
					</div>
				</div>

			</div>
			<Footer />
		</>
	);
}

export default Privacy;
