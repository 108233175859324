import React from 'react'
import Carousel from "react-bootstrap/Carousel"
import { Footer, IndexNavbar } from '../../components'

function Competition() {
    // const token = localStorage.getItem('token')
    let token, currentUser
    if (typeof window !== "undefined") {
        // eslint-disable-next-line no-unused-vars
        token = window.localStorage.getItem("token")
        // eslint-disable-next-line no-unused-vars
        currentUser = JSON.parse(window.localStorage.getItem("currentuser"))
    }
    return (
        <main>
            <IndexNavbar />
            <Carousel className='com_rules' controls={true} indicators={true} fade>
                <Carousel.Item>
                    <div class="img-responsive w-100">
                        <img
                            src={"/assets/web_img/add_web_img.jpg"} alt="banner"
                            className="img-responsive w-100"
                        />
                    </div>
                </Carousel.Item>
            </Carousel>
            <Footer />
        </main>
    )

}

export default Competition