import React from 'react'
import { useLocation, Link } from 'react-router-dom'

function ProfileSettingsNav() {
    const path = useLocation()
    const pathArr = path.pathname.split('/')
    console.log(pathArr[2])
    return (
        <div>
            <div className='d-flex align-items-center p_set_box mt-4 profile_set'>
                <Link to="/settings/basic/" className={pathArr[2] === 'basic' ? 'p_set_navlink active' : 'p_set_navlink'}>
                    Basic
                </Link>
                <Link to="/settings/account/" className={pathArr[2] === 'account' ? 'p_set_navlink active' : 'p_set_navlink'}>
                    Account
                </Link>
                <Link to="/settings/analytics/" className={pathArr[2] === 'analytics' ? 'p_set_navlink active' : 'p_set_navlink'}>
                    Analytics
                </Link>
                <Link to="/settings/storage/" className={pathArr[2] === 'storage' ? 'p_set_navlink active' : 'p_set_navlink'}>
                    Storage
                </Link>
                <Link to="/settings/referral/" className={pathArr[2] === 'referral' ? 'p_set_navlink active' : 'p_set_navlink'}>
                    Referral
                </Link>
                <Link to="/settings/edit-profile/" className={pathArr[2] === 'edit-profile' ? 'p_set_navlink active' : 'p_set_navlink'}>
                    Edit Profile
                </Link>
                {/* <Link to="/settings/wallet/" className={pathArr[2] === 'wallet' ? 'p_set_navlink active' : 'p_set_navlink'}>
                    Wallet Balance
                </Link> */}
            </div>
        </div>
    )
}

export default ProfileSettingsNav