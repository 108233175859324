  // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, signOut } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC4CJEJkXuaRntq5DEb8oYv7ZG9DWIIazw",
  authDomain: "allphanes-users.firebaseapp.com",
  projectId: "allphanes-users",
  storageBucket: "allphanes-users.appspot.com",
  messagingSenderId: "547946134724",
  appId: "1:547946134724:web:64fb64b5f90d818b1a5d91",
  measurementId: "G-679SYWKMCF",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);

export const FirebaseSignOut = () => {
  signOut(auth).then(() => {
    // Sign-out successful.
    
    console.log("Sign-out successful")

  }).catch((error) => {
    console.log("An error happened.", error)
  });
  // try {
  //   const response = await signOut(auth);
  //   console.log("firebasesignout", response)
  //   return response ? true : false
  // } catch (error) {
  //   console.log(error)
  //   return false
  // }
}
