import React, { useEffect, useState } from "react"
import { Footer, IndexNavbar } from "../../components"
import { connect, useDispatch } from "react-redux"
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { LDS } from "../../components/Loaders/SimpleCircle"
import { PremiumListNav } from "."
import { PrivatePostCard } from "../Organization/OrgPrivatePosts"

function AllPrivatePost(props) {
    const { allPrivatePost } = props
    const [loader, setLoader] = useState(true)
    const dispatch = useDispatch()
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)

    const init = async () => {
        try {
            if (!hasMore) return setHasMore(false)
            let params = {
                reqPage: 'aopp',
                page: page,
            }

            let req = {
                params: params,
                url: 'getpreposts',
                action: 'ACTION_GET_ORGDATA_BY_STATE',
                state: 'allPrivatePost'
            }
            const response = await dispatch(getRequestHandler(req))
            if (response?.data?.responseData?.length < 12) setHasMore(false)
        } catch (err) {
            console.error(err)
        }
    }

    // const handleChange = (e) => {
    //     setQ(e.target.value)
    // }

    const handleScroll = (e) => {
        let scrollTop = window.pageYOffset
        let clientHeight = window.document.documentElement.clientHeight
        let bodyHeight = window.document.body.clientHeight
        if (clientHeight + scrollTop > bodyHeight - 100) {
            window.removeEventListener("scroll", handleScroll)
            setPage(page + 1)
        }
    }



    useEffect(() => {
        if (allPrivatePost?.length > 0) {
            window.addEventListener("scroll", handleScroll)
            setLoader(false)
        }
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPrivatePost])

    useEffect(() => {
        if (loader) setTimeout(() => setLoader(false), [2000])
    }, [loader])

    // useEffect(() => {
    //     if (Q) getAllMembers()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [Q])

    useEffect(() => {
        if (page > 1) init()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    useEffect(()=>init(), [])

    return (
        <>
               
                <div className="container mt-2 mb-4">
                    <div className="row">
                        {loader ?
                            <div className="load_body">
                                <LDS />
                            </div> :
                            allPrivatePost?.length > 0 ?
                            allPrivatePost?.map((item, index) =>
                                    <div className="col-lg-4 col-md-4">
                                        <PrivatePostCard
                                            // isPre={'pre'}
                                            key={item?._id}
                                            // predirect={""}
                                            post={item}
                                        />
                                    </div>
                                )
                                :
                                <>
                                    <h1>No premium post  found</h1>
                                </>

                        }

                        {page > 1 && loader &&
                            <div className="d-flex justify-content-center">
                                <LDS />
                            </div>
                        }
                    </div>

                </div>
        </>
    )
}

const mapStateToProps = (state) => {
    console.log("stateoRgpostcreationpostsMembers", state)
    const { allPrivatePost } =
        state.organization
    // const { members } = state.members
    return { allPrivatePost }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(AllPrivatePost)
