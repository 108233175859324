import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { deleteRequestHandler, postRequestHandler, putRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { SimpleCircle } from "../../components"

const FriendHandle = (props) => {
    const { friends, callback, cuid, opuid } = props
    const [loadData, setLoadData] = useState(false)
    const dispatch = useDispatch()

    const unFriend = async () => {
        setLoadData(true)
        const confirm = window.confirm('are you sure')
        if (!confirm) return setLoadData(false)
        let req = {
            params: { id: friends._id },
            url: 'unFriend'
        }
        await dispatch(deleteRequestHandler(req))
        await callback()
        setLoadData(false)
    }

    const addFriend = async () => {
        try {
            setLoadData(true)
            const data = {
                referenceUserId: cuid,
                acceptorId: opuid,
            }
            let req = {
                data: data,
                url: 'addFriend'
            }
            await dispatch(postRequestHandler(req))
            await callback()
            setLoadData(false)
        } catch (Err) {
            console.log(Err)
        }
    }

    const acceptRequest = async () => {
        setLoadData(true)
        const data = {
            id: friends._id,
        }
        let req = {
            data: data,
            url: 'acceptRequest'
        }
        await dispatch(putRequestHandler(req))
        await callback()
        setLoadData(false)
    }

    return (
        <>
            {loadData ? <button
                type="button"
                className="btn btn-light"
            >
                <SimpleCircle />
            </button>
                : friends?._id ?
                    friends.isAccepted ?
                        props?.space ? (
                            <div className="w-100 marked_friend">
                                Friends
                                <img src={"/assets/web_img/green-check.png"} alt="static green check" />
                            </div>
                        ) : <button
                            type="button"
                            class="btn btn-danger me-2"
                            onClick={unFriend}

                        >
                            <span class="material-symbols-outlined float-start me-1">
                                diversity_3
                            </span>
                            Unfriend
                        </button>

                        : friends?.referenceUserId === cuid ?
                            <button
                                type="button"
                                class="btn btn-danger me-2"
                                onClick={unFriend}

                            ><span class="material-symbols-outlined float-start me-1">
                                    group_add
                                </span>
                                Cancel Request
                            </button>
                            : <>
                                <button
                                    type="button"
                                    class="btn btn-success me-2"
                                    onClick={acceptRequest}
                                >
                                    <span class="material-symbols-outlined float-start me-1">
                                        diversity_3
                                    </span>
                                    Accept Friend
                                </button>
                                <button
                                    type="button"
                                    class="btn btn-danger me-2"
                                    onClick={unFriend}

                                ><span class="material-symbols-outlined float-start me-1">
                                        group_add
                                    </span>
                                    Cancel Request
                                </button>
                            </>
                    :
                    <button
                        type="button"
                        class="btn btn-success me-2"
                        onClick={addFriend}
                    >
                        <span class="material-symbols-outlined float-start me-1">
                            diversity_3
                        </span>
                        Add Friend
                    </button>

            }
        </>
    )
}

export default FriendHandle
