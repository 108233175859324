import React from 'react'
import { useDispatch } from 'react-redux'
import { orgMembersAction } from '../../redux/reducers/organizationReducer'

function BlockedMembers({ orgDetails, members }) {
    const currentUserId = typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const dispatch = useDispatch()

    const membersAction = async (mid, request) => {
        try {

            const params = {
                body: {
                    "orgid": orgDetails?._id,
                    "memberId": mid,
                    "ownerId": currentUserId,
                    "permission": request
                },

                query: {
                    orgid: orgDetails?._id,
                    ownerid: currentUserId,
                    statustype: 'all'
                }
            }

            await dispatch(orgMembersAction(params))

        } catch (err) {
            console.error(err)
        }
    }
    return (
        <>
        <div className="row">
                {members?.length > 0 ?
                    members?.map((items, index) => {
                        return (
                            <>
                                <div className="col-lg-2 col-md-3 col-12 kk mt-3" key={"index"}>
                                    <div className="follow_m_box">

                                        <img height="200px" width="100%" src="/assets/web_img/choto_logo_1.png" alt="none" />

                                        {/* <div className="text-center py-2">SRK</div> */}
                                        <div className="text-center py-2">{items?.user_info[0]?.showName}</div>

                                        <div className='px-2 pb-2'>
                                            <button
                                                type='button'
                                                className='btn btn-warning w-100'
                                                onClick={() => membersAction(items?.user_info[0]?._id, 'active')}
                                            >Unblock</button>
                                            <button
                                                type='button'
                                                onClick={() => membersAction(items?.user_info[0]?._id, 'deleted')}
                                                className='mt-2 w-100 btn btn-danger'
                                            >Remove</button>

                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })
                    : <div className='w-100 text-center cl_b'>No Blocked member found</div>

                }
            </div>
        </>
    )
}

export default BlockedMembers