import React from 'react'
import { Link, useLocation } from 'react-router-dom'

function MembarNav() {
    const path = useLocation()
    return (
        <>
            <div className='d-flex member_nav_items'>
                <Link to={"/members/"} className={/members/.test(path.pathname) ? "active" : ""}>Members</Link>
                <Link to={"/friends-list/"} className={/friends-list/.test(path.pathname) ? "active" : ""}>Friends</Link>
                <Link to={"/request-list/"} className={/request-list/.test(path.pathname) ? "active" : ""}>Friend Requests</Link>
                <Link to={"/sent-list/"} className={/sent-list/.test(path.pathname) ? "active" : ""}>Sent Requests</Link>
            </div>
        </>

    )
}

export default MembarNav