import React, { useEffect, useState } from "react"
import {
  useParams,
} from "react-router-dom"

import { Helmet } from "react-helmet"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import LoginStatus from "../../components/DesignComponents/LoginStatus"
import { ImageUploadDialog } from "../../../_helpers/Material"
import { Footer, IndexNavbar, SimpleCircle } from "../../components"
import { connect, useDispatch } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getRequestHandler, postRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import Dropdown from 'react-bootstrap/Dropdown';
import AboutMe from "./AboutMe"
import CPost from "./CPost"
import CreatePost from "../../components/Post/CreatePost"
import Followers from "./Followers"
import Followings from "./Followings"
import Friends from "./Friends"
import Photos from "./Photos"
import Videos from "./Videos"
import FriendHandle from "./FriendHandle"
import PostCard from "./PostCard"
import UpdateProfile from "./UpdateProfile"


function MySpace(props) {
  // const { user, noOfPost, noOfPhotos, noOfVideos, totalFriends, isFriend, isFollow, noOfFollowers, noOfFollowings, posts, photos, videos, friends, followers, followings } = props
  const { posts, user, isFriend, noOfPhotos, noOfVideos, totalFriends, isFollow, noOfFollowers, noOfFollowings } = props
  const [modalShow, setModalShow] = useState(false)
  const [PathInfo, setPathInfo] = useState("")
  const param = useParams()
  const [aboutToggle, setAboutToggle] = useState(false)
  const [createPostToggle, setCreatePostToggle] = useState(false)
  const [followerOpen, setFollowerOpen] = useState(false)
  const [followingsOpen, setFollowingsOpen] = useState(false)
  const [friendsOpen, setFriendsOpen] = useState(false)
  const [photosOpen, setPhotosOpen] = useState(false)
  const [videosOpen, setVideosOpen] = useState(false)
  const [isUpdateProfile, setIsUpdateProfile] = useState(false)
  const [loader, setLoader] = useState(false)
  const token = typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const dispatch = useDispatch()
  const [initialLoad, setInitialLoad] = useState(true)
  const [page, setPage] = useState(1)



  const handleModalClick = (path) => {
    setModalShow(true)
    setPathInfo(path)
  }

  const getAllPosts = async (param) => {
    if (!user?._id) return
    let req = {
      params: {
        token: token,
        refToken: user?._id,
        normalPermission: true,
        status: 'active',
        page: param?.page || page,
      },
      url: 'getMySpacePost',
      action: 'ACTION_GET_MYSPACE_STATE',
      state: "posts",
    }
    await dispatch(getRequestHandler(req))
  }

  const handleScroll = (e) => {
    let scrollTop = window.pageYOffset
    let clientHeight = window.document.documentElement.clientHeight
    let bodyHeight = window.document.body.clientHeight

    if (clientHeight + scrollTop > bodyHeight - 100) {
      window.removeEventListener("scroll", handleScroll)
      setPage(prev => prev + 1)
    }
  }

  useEffect(() => {
    if (posts) window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts])

  useEffect(() => {
    if (user?._id) getAllPosts({ page: 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (page > 1) getAllPosts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const init = async () => {
    setLoader(true)
    let req = {
      params: {
        userName: user?.userName
      },
      url: 'mySpaceUser',
      action: 'ACTION_GET_MYSPACE_SUCCESSF'
    }
    await dispatch(getRequestHandler(req))
    setLoader(false)
  }
  const follow = async () => {
    let data = {
      userid: token,
      followingid: user?._id,
    }
    let req = {
      data: data,
      url: 'addFollow',
    }
    const res = await dispatch(postRequestHandler(req))
    if (res) await init()
  }

  useEffect(() => setInitialLoad(false), [user, isFollow])

  // const Friend = async () =>{

  // }

  const Kelmet = () => {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Allphanes | ${param.name}`}</title>
      </Helmet>
    )
  }


  return (

    <main className="bg_allp">
      <IndexNavbar />
      <Kelmet />
      <div
        id="background_bg"
        style={{
          backgroundImage: `url(${"/assets/web_img/background_bg.jpg"})`,
        }}
      >
        {aboutToggle && <AboutMe
          user={user}
          aboutToggle={aboutToggle}
          setAboutToggle={setAboutToggle}
        />
        }

        {user?._id === token && createPostToggle && <CPost
          open={createPostToggle}
          setOpen={setCreatePostToggle}
          component={
            <CreatePost
              userinfo={user}
              spinner={false}
              getpost={() => getAllPosts({ page: 1 })}
              setOpen={setCreatePostToggle}
            />
          }
        />}

        {followerOpen && <Followers
          open={followerOpen}
          setOpen={setFollowerOpen}
        />}

        {followingsOpen && <Followings
          open={followingsOpen}
          setOpen={setFollowingsOpen}
        />}

        {photosOpen && <Photos
          open={photosOpen}
          setOpen={setPhotosOpen}
        />}
        {videosOpen && <Videos
          open={videosOpen}
          setOpen={setVideosOpen}
        />}
        {friendsOpen && <Friends
          open={friendsOpen}
          setOpen={setFriendsOpen}
        />}
        {isUpdateProfile && <UpdateProfile
          open={isUpdateProfile}
          setOpen={setIsUpdateProfile}
          callback={init}
        />}
        <div
          className="profile_cover"
          style={{
            backgroundImage: `url(${user?.coverPhoto || "/assets/web_img/choto_logo_1.png"})`
          }}
        >
          {user?._id === token ? (
            <button
              type="button"
              className="edit_cover_pic_btn shadow bg-dark"
              title="Change cover photo"
              onClick={() => handleModalClick(0)}
            >
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="camera" class="svg-inline--fa fa-camera " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 384c-53 0-96-43-96-96s43-96 96-96s96 43 96 96s-43 96-96 96z"></path></svg>
              {/* <CropOriginalIcon /> */}
            </button>
          ) : (
            ""
          )}
          <div className="position-absolute cover_link">
            {user?._id !== token && <FriendHandle
              friends={isFriend}
              callback={init}
              cuid={token}
              opuid={user?._id}
            />
            }
            {user?._id !== token && <>
              {loader ?
                <button
                  type="button"
                  className="btn btn-light"
                >
                  <SimpleCircle />
                </button> : !isFollow ?
                  <button
                    type="button"
                    className="btn btn-success"
                    title="click to follow"
                    onClick={() => follow()}
                  >

                    <span className="material-symbols-outlined float-start me-1">
                      group_add
                    </span> Follow
                  </button>
                  :
                  <button
                    type="button"
                    className="btn btn-danger"
                    title="click to unfollow"
                    onClick={() => follow()}
                  >
                    <span className="material-symbols-outlined float-start me-1">
                      group_add
                    </span> Unfollow
                  </button>
              }
            </>
            }
          </div>

        </div>
        <div>
          <section style={{
            backgroundImage: `url("")`
          }}>
            <div className="container background_opacity">
              <div className="user_pro_sec container position-relative">
                <div className="row">
                  <div className="col-lg-12 d-flex justify-content-between">
                    <div className="kk_modi">
                      <div className="profile_outer_photo bg_allp2">
                        <div className="profile_inner_photo">
                          <div className="img_box">
                            <img
                              src={/tempd|inActive/.test(user?.status) && !user ?
                                `/assets/web_img/choto_logo_1.png`
                                : user?.profilePhoto || `/assets/web_img/choto_logo_1.png`
                              }
                              alt={user?.showName + "profile photo"}
                              onError={(e) => {
                                e.target.onerror = null
                                e.target.src = `/assets/web_img/choto_logo_1.png`
                              }}
                              className="img_box"
                            />
                          </div>

                          {user?._id === token ? (
                            <div
                              title="Change profile photo"
                              className="edit_pro text-center mt-1"
                              onClick={() => handleModalClick(1)}
                            >
                              <FontAwesomeIcon icon={faCamera}></FontAwesomeIcon>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {(!/tempd|inActive/.test(user?.status) || user) &&
                        <div className="user_profile_info flex_info">
                          <h4>{user?.showName}</h4>

                        </div>
                      }

                    </div>
                  </div>

                  <div className="col-sm-12 text-end my_space_links position-absolute">
                    <div
                      onClick={() => setAboutToggle(true)}
                      className="text-decoration-none text-dark ps-1 c_pointer myspace_toplink">
                      About Me
                    </div>
                    {user?._id === token &&
                      <div
                        onClick={() => setIsUpdateProfile(true)}

                        className="myspace_toplink">
                        <span className="material-symbols-outlined float-end ms-1 mt-1 pe-1 text-dark">
                          edit_note
                        </span>
                      </div>
                    }
                    {user?._id === token && <>
                      <span className="text-secondary">|</span>
                      <div
                        onClick={() => setIsUpdateProfile(true)}
                        // to="/settings/basic/" 
                        className="text-decoration-none text-dark ps-1 pe-1 myspace_toplink">
                        Update Profile
                        <span className="material-symbols-outlined float-end ms-1 mt-1">
                          edit_note
                        </span>
                      </div>
                      <span className="text-secondary">|</span>
                      <div
                        onClick={() => setCreatePostToggle(true)}
                        className="text-decoration-none text-dark ps-1 pe-1 c_pointer myspace_toplink"
                      >
                        Post
                        <span className="material-symbols-outlined float-end ms-1 mt-1">
                          post_add
                        </span>
                      </div>
                      <span className="text-secondary">|</span>
                    </>

                    }

                    <div className="text-decoration-none text-dark ps-1 pe-1 ms-2 my_space_nav rounded-circle text-center">
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          <span className="material-symbols-outlined text-white">
                            apps
                          </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => setPhotosOpen(true)}> Photos <span>{noOfPhotos || 0}</span></Dropdown.Item>
                          <Dropdown.Item onClick={() => setVideosOpen(true)}> Videos <span>{noOfVideos || 0}</span></Dropdown.Item>
                          <Dropdown.Item onClick={() => setFriendsOpen(true)}>Friends <span>{totalFriends || 0}</span></Dropdown.Item>
                          <Dropdown.Item onClick={() => setFollowerOpen(true)}>Followers <span>{noOfFollowers}</span></Dropdown.Item>
                          <Dropdown.Item onClick={() => setFollowingsOpen(true)}>Following <span>{noOfFollowings || 0}</span></Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>

                    </div>
                  </div>


                </div>
              </div>
              <div className="row">
                <section className="my_space">
                  <div className="mt-1">
                    <div className="row">
                      {posts?.length > 0 &&
                        posts?.map((item, index) => {
                          return (
                            <PostCard
                              cuser={user}
                              post={item}
                              getPost={() => {
                                init()
                                getAllPosts({ page: 1 })
                              }}
                            />
                          )
                        })}

                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>

        </div>

        {modalShow && (
          <ImageUploadDialog
            pathinfo={PathInfo}
            close={() => setModalShow(false)}
          />
        )}

      </div>
      {!token && <LoginStatus showname={user?.showName} />}
      <Footer />
    </main>
  )
}
MySpace.loadData = (params, search) => async (dispatch) => {
  const name = params.split('/')[1]
  let req = {
    params: {
      userName: name
    },
    url: 'mySpaceUser',
    action: 'ACTION_GET_MYSPACE_SUCCESS'
  }
  // mySpaceUser
  return await dispatch(getRequestHandler(req))
  // return res
}
const mapStateToProps = (state) => {
  console.log("mySpaceData", state)
  const { user, noOfPost, noOfPhotos, noOfVideos, totalFriends, isFriend, isFollow, noOfFollowers, noOfFollowings, posts, photos, videos, friends, followers, followings } = state.mySpaceData
  const auths = state.auth
  const authUser = auths?.user?.user
  return { user, noOfPost, noOfPhotos, noOfVideos, totalFriends, isFriend, isFollow, noOfFollowers, noOfFollowings, posts, photos, videos, friends, followers, followings, authUser }

}

export default connect(mapStateToProps, null)(MySpace)