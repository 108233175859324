import React, { useState, useEffect } from "react"
import { Navigate, useSearchParams } from "react-router-dom"
import CreatorPopup from "../../components/Widgets/Creator.popup"
import { Footer, IndexNavbar } from "../../components"
import PostTestCard from "../../components/Postsk"
import { connect, useDispatch } from "react-redux"
import { getRequestHandler, getRequestHandler2 } from "../../redux/reducers/requestHandler.reducer"


function AdvancePostSearch(props) {
	const { domains, advanceSearch } = props
	const [skip, setSkip] = useState(0)
	const [checkedSubD, setCheckedSubd] = useState([])
	const [sortValue, setSortValue] = useState('newf')
	const [myFav, setMyFav] = useState(false)
	const [searchParams] = useSearchParams()
	const [skipUrl, setSkipUrl] = useState('')
	const [token, setToken] = useState('')
	const [currentUser, setCurrentUser] = useState('')
	const [checkedLanguage, setCheckedLanguage] = useState(currentUser?.defaultLanguage || [])
	const [toggleHandler, setToggleHangdler] = useState({})
	const [popUp, setPopUp] = useState(false)
	const [values, setValues] = useState("")
	const [values2, setValues2] = useState("")
	const dispatch = useDispatch()

	// getting creator posts 
	// getting filtered posts 
	const getAllPosts = async (queryString) => {
		let searchPar = ''
		if (searchParams.get("dm"))
			searchPar = `&dm=${searchParams.get("dm")}`
		if (searchParams.get("sd"))
			searchPar += `&sd=${searchParams.get("sd")}`
		let url = 'getPost'
		if (/oldf|ml|mc/.test(sortValue))
			url = 'likeComPost'
		const urls = `token=${token + searchPar}&type=Normal&${queryString}&q=${values2}`
		setSkipUrl(urls)
		let req = {
			params: `${urls}&skip=0`,
			url: url,
			action: 'ACTION_GET_POSTS_MANAGEMENT',
			state: 'advanceSearch'
		}
		await dispatch(getRequestHandler2(req))
	}

	const getSkipPosts = async () => {
		if (skip < 1) return
		const urls = `${skipUrl}&skip=${skip}`
		let url = 'getPost'
		if (/oldf|ml|mc/.test(sortValue))
			url = 'likeComPost'
		let req = {
			params: urls,
			url: url,
			action: 'ACTION_GET_POSTS_MANAGEMENT',
			state: 'advanceSearch',
			page: 2
		}
		await dispatch(getRequestHandler2(req))
	}

	const handleScroll = (e) => {
		const { offsetHeight, scrollTop, scrollHeight } = e.target
		if (offsetHeight + scrollTop > scrollHeight - 200)
			setSkip(advanceSearch?.length)
	}

	const handleSearch = e => {
		const lanQuery = checkedLanguage.map(k => `lang=${encodeURIComponent(k)}`).join('&')
		const subdQuery = checkedSubD.map(k => `sd=${encodeURIComponent(k)}`).join('&')
		const queryString = `${lanQuery}&${subdQuery}&fav=${myFav}&sort=${sortValue}`
		if (token) getAllPosts(queryString)
	}

	const init = async () => {
		try {
			setCurrentUser(JSON.parse(localStorage.getItem("currentuser")))
			setToken(localStorage.getItem("token"))
			if (!localStorage.getItem("token")) return <Navigate to="/auth/login/" />
			let req = {
				url: 'domains',
				action: 'ACTION_GET_DOMAINS'
			}
			const response = await dispatch(getRequestHandler(req))
			response?.data?.responseData?.language?.map(item =>
				currentUser?.defaultLanguage?.includes(item.language) ?
					item.checked = true : item.checked = false
			)
			let obj = {}
			response?.data?.responseData?.domains?.map(item => obj[item.postCategory] = false)
			setToggleHangdler(obj)
		} catch (error) {
			console.error(error)
		}
	}



	const checkedArr = (isChecked, value, vari, fun) => {
		if (isChecked)
			return fun(vari => ([...vari, value]))
		let arr = vari
		if (arr.indexOf(value) !== -1)
			arr.splice(arr.indexOf(value), 1)
		fun(arr)
		handleSearch()
	}

	const handleFilterChange = e => {
		if (e.target.name === "language")
			checkedArr(e.target.checked, e.target.value, checkedLanguage, setCheckedLanguage)
		if (e.target.name === "subdomain")
			checkedArr(e.target.checked, e.target.value, checkedSubD, setCheckedSubd)
	}

	const handleFavChange = e => setMyFav(e.target.checked ? true : false)
	const handleToggle = key => setToggleHangdler(toggleHandler => ({ ...toggleHandler, [key]: toggleHandler[key] ? false : true }))
	const handleChange = e => {
		const re = new RegExp('#', 'g')
		const kk = e.target.value.replace(re, '')
		setValues(e.target.value)
		setValues2(kk)
	}
	useEffect(() => {
		init()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		const k = searchParams.get("dm")
		if (!k) return

		if (domains?.domains)
			setToggleHangdler(toggleHandler => ({ ...toggleHandler, [k]: true }))

	}, [searchParams, domains?.domains])


	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => handleSearch(), [checkedLanguage, checkedSubD, sortValue, myFav, searchParams.get("dm"), searchParams.get("sd")])
	useEffect(() => {
		if (token) getAllPosts()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [values2, token])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => getSkipPosts(), [skip])

	return (
		<>
			{popUp && <CreatorPopup onHide={() => setPopUp(false)} />}
			<IndexNavbar />
			{/* <div className='navbar'>Nav bar</div> */}
			<div className="bg_allp container-fluid">
				<div className="">
					<div className="row">
						<div className="col-lg-2 col-md-3">
							<div className="filter_button">
								<button class="btn btn-success">
									Filter
								</button>
							</div>
							<div className="bg-white search_left">
								<button className="btn cmn_btn bg_b" type="button" onClick={() => setPopUp(true)}>
									<svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="#fff"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" /></svg>
									Help
								</button>
								<div className="w-100">
									<h6 className="domain_nav">Search :</h6>
									<input
										type="text"
										className="form-controll w-100 mb-2"
										name="keywords"
										onChange={handleChange}
										value={values || ""}
										placeholder="Search Post"
									/>
								</div>

								<h6 className="domain_nav">Selections</h6>

								<div className="sort_item">
									<div className="check_items">
										<input type="checkbox" name="fav" id="" value={myFav} onChange={handleFavChange} /> My Favorites
									</div>
								</div>
								{/* <div className="c_pointer" onClick={() => setMyFav(true)}>My Favorites</div> */}

								<h6 className="domain_nav">Language</h6>
								{domains?.language?.length > 0 && domains?.language?.map((item, index) => (
									<div className="sort_item" key={index}>
										<div className="check_items" >
											<input type="checkbox" name="language" id="" value={item.language} onChange={handleFilterChange} defaultChecked={item.checked} /> {item.language}
										</div>
									</div>
								))
								}


								<h6 className="domain_nav">Sort</h6>
								<div className="sort_item">
									<div className="check_items"><input type="radio" value="newf" name="sort" onChange={e => setSortValue(e.target.value)} checked={sortValue === 'newf'} /> Newest First</div>
									<div className="check_items"><input type="radio" value="oldf" name="sort" onChange={e => setSortValue(e.target.value)} checked={sortValue === 'oldf'} /> Oldest First</div>
									<div className="check_items"><input type="radio" value="ml" name="sort" onChange={e => setSortValue(e.target.value)} checked={sortValue === 'ml'} /> Most Liked</div>
									<div className="check_items"><input type="radio" value="mc" name="sort" onChange={e => setSortValue(e.target.value)} checked={sortValue === 'mc'} /> Most Commented</div>
								</div>



								<h6 className="sub_domain">Sub Domain</h6>

								{domains?.domains?.length > 0 && domains?.domains?.map((item, index) => {
									return (
										<div key={index} className="domains_body">
											<h6
												className="domain_nav d-flex justify-content-between align-items-center bg_red"
												onClick={() => handleToggle(item?.postCategory)}
											>
												{item?.postCategory}
												<div>{toggleHandler[item?.postCategory] === false ? '+' : '-'}</div>
											</h6>
											<div className={toggleHandler[item?.postCategory] === true ? "category_box active" : "category_box"}>

												{item?.subDomain ?
													item?.subDomain?.map((subd, key) => {
														return (
															<div className="check_items" key={key}>
																<input type="checkbox" name="subdomain" id="" value={subd?.postSubCategory} onChange={handleFilterChange} /> {subd.postSubCategory}
															</div>
														)
													}) : ''}
											</div>

										</div>


									)
								})}

							</div>
						</div>

						<div className="col-lg-10 col-md-9 post_area_infinite scrollBar advance_serch advance_search_box"
							onScroll={handleScroll}>
							<div className="row">
								{advanceSearch?.length > 0 &&
									advanceSearch?.map((item, index) => {
										return (
											<div
												className="col-lg-4 col-md-6 col-12"
												key={index}
											>
												<PostTestCard
													singlePost={item}
													key={item._id}
													indexinfo={index}
													getpost={() => getAllPosts()}
													isViewDetails={true}
													isPostActionSec={true}
													isPostCommentSec={false}
												/>
											</div>
										)
									})}
							</div>
						</div>

					</div>
				</div>
			</div>


			<Footer />
		</>
	)
}

const mapStateToProps = (state) => {
	console.log("stateoRgpostcreationposts", state)
	const { domains, advanceSearch } = state.posts
	return { domains, advanceSearch }
}
const mapDispatchToProps = (dispatch) => {
	return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(AdvancePostSearch)
