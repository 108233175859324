import React, {  useState } from "react"
import {
    useLocation,
    useNavigate,
} from "react-router-dom"
import LoginStatus from "../../components/DesignComponents/LoginStatus"
import OrgMembers from "./OrgMembers"
import RequestMemebers from "./RequestMemebers"
import BlockedMembers from "./BlockedMembers"
import { Tab, Tabs } from "react-bootstrap"

function OrgAllMembers(props) {
    const { setSelectedChildPage, orgDetails, members } = props
    const path = useLocation()
    const token = typeof window !== "undefined" ? localStorage.getItem("token") : ""
    const currentUser = typeof window !== "undefined" ? JSON.parse(localStorage.getItem("currentuser")) : ""
    const [key, setKey] = useState("active/")
    const navigate = useNavigate();


    const onLinkClick = (e) => {
        setKey(e);
        const { pathname } = path
        navigate(pathname + "#members/#" + e);
		setSelectedChildPage(prev => ({...prev, parent : "members/", child :e}))
    };

    

    return (
        <>
            <section id="orgMembers">
                <Tabs
                    defaultActiveKey="active/"
                    activeKey={key}
                    id="uncontrolled-tab-example"
                    onSelect={(e) => onLinkClick(e)}
                    className="mb-3"
                >
                    <Tab eventKey="active/"
                        title="Active"
                    >
                        <OrgMembers 
                        orgDetails={orgDetails} 
                        activeMembers={members?.members}
                        // getMembers={getMembers}
                         />
                    </Tab>
                    {orgDetails?.userid === token &&
                        <Tab eventKey="requested/"
                            id="#requested"
                            title="Requested"
                        >
                            <RequestMemebers
                                orgDetails={orgDetails}
                                members={members?.members}
                                // getMembers={getMembers}
                            />
                        </Tab>
                    }

                    {orgDetails?.userid === token &&
                        <Tab eventKey="blocked/"
                            title="Blocked">
                            <BlockedMembers 
                            orgDetails={orgDetails} 
                            members={members?.members} 
                            // getMembers={getMembers}

                            />

                        </Tab>
                    }

                </Tabs>
            </section>
            {!token && <LoginStatus showname={currentUser?.showName} />}
        </>
    )
}


export default OrgAllMembers;
