import React, { useState, useEffect, useRef } from "react"
import Compressor from "compressorjs"
import axios from "axios"
import { config } from "../../constant"

import InsertPhotoIcon from "@mui/icons-material/InsertPhoto"
import VideocamIcon from "@mui/icons-material/Videocam"
// import YouTubeIcon from '@mui/icons-material/YouTube'
import EditIcon from "@mui/icons-material/Edit"
import CustomizedSnackbars from "../Loaders/Snuckbars"
import SimpleCircle from "../Loaders/SimpleCircle"
import { CopyLink } from "../AppComponents/Helpers"
import { useDispatch } from "react-redux"
import { updateEvents } from "../../redux/reducers/postReducer"
import { getSubscriptionPlan } from "../../redux/reducers/admin.reducer"
import { getOrganizationPosts } from "../../redux/reducers/organizationReducer"
import Button from "@mui/material/Button"
import PropTypes from "prop-types"
import { styled } from "@mui/material/styles"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import DialogContent from "@mui/material/DialogContent"
import DialogActions from "@mui/material/DialogActions"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import moment from "moment"
// apis
const getPostCategoryUrl = config.url.API_URL + "postcategory"
const getLanguageUrl = config.url.API_URL + "language"
const getSubDomainUrl = config.url.API_URL + "postsubcategory/"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export const UserProfilePhoto = ({ photo }) => {
  return (
    <>
      <img
        id={photo}
        className="user_profile_image"
        alt={photo}
        src={photo || "/assets/web_img/choto_logo_1.png"}
        onError={(e) => {
          e.target.onerror = null
          e.target.src = "/assets/web_img/choto_logo_1.png"
        }}
        width="50px"
        height="50px"
      />
    </>
  )
}

export const EditEvent = (props) => {
  // orgDetails, isOwner
  const { loading, setLoading, setIsSubmit, close, issubmit, post, getpost } = props
  const [createPost, setCreatePost] = useState(false)
  const [postText, setPostText] = useState({
    postDescription: post?.postDescription,
    publishDate: post?.publishDate,
    ppv: post?.ppv,
    ppvc: post?.ppv,
    postTitle: post?.postTitle,
    keywords: post?.keywords2,
    postCategory: post?.postCategory,
    postSubDomain: post?.postSubDomain,
    language: post?.language,
  })
  const [fileInputState, setFileInputState] = useState("")
  const [errMsg, setErrMsg] = useState("")
  const [postCategory, setPostCategory] = useState([])
  const [language, setLanguage] = useState([])
  const [waitingLoader, setWaitingLoader] = useState(false)
  const [loaderSwitch, setLoaderSwitch] = useState(props.spinner)
  const [domainId, setDomainId] = useState("")
  const [subDomains, setSubDomains] = useState([])
  const [showTitle, setShowTitle] = useState(false)
  const [shareDial, setShareDial] = useState(false)
  const [postIdx, setPostIdx] = useState("")
  const [isCopyLink, setIsCopyLink] = useState("")
  const [editorLoaded, setEditorLoaded] = useState(false)
  const [filePreview, setFilePreview] = useState({
    thumbnail: post?.thumbnail,
    // eventVideo: post?.
  })
  const [fileRawData, setFileRawData] = useState({})
  const editorRef = useRef()
  const { CKEditor, ClassicEditor } = editorRef.current || {}
  const dispatch = useDispatch()

  useEffect(() => {
    editorRef.current = {
      // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    }
    setEditorLoaded(true)
  }, [])

  const getSubDomain = async (e) => {
    const response = await axios.get(getSubDomainUrl + domainId)
    setSubDomains(await response.data.responseData)
  }

  const getLanguage = async (e) => {
    const response = await axios.get(getLanguageUrl)
    setLanguage(await response.data.responseData)
  }

  const getCategory = async (e) => {
    const response = await axios.get(getPostCategoryUrl)
    setPostCategory(await response.data.responseData)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    const typeImage = file.type
    if (/image/.test(typeImage)) {
      new Compressor(file, {
        quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          setFilePreview((prev) => ({
            ...prev,
            [e.target.name]: URL.createObjectURL(file),
          }))
          setFileRawData((prev) => ({
            ...prev,
            [e.target.name]: compressedResult,
          }))
        },
      })
    } else {
      setFilePreview((prev) => ({
        ...prev,
        [e.target.name]: URL.createObjectURL(file),
      }))
      setFileRawData((prev) => ({ ...prev, [e.target.name]: file }))
    }
  }

  const clearPost = () => {
    setPostText({})
    setFilePreview({})
    setFileRawData((prev) => ({}))
    setCreatePost(false)
  }

  const detectURLs = (message) => {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
    return message.match(urlRegex)
  }

  const handleChange = (e) => {
    e.persist()
    if (e.target.name === "postDescription") {
      let urlCheck = detectURLs(e.target.value)
      if (urlCheck) return false
    }
    setPostText((values) => ({ ...values, [e.target.name]: e.target.value }))
    if (e.target.name === "postCategory") {
      const index = e.target.selectedIndex
      const el = e.target.childNodes[index]
      const option = el.getAttribute("id")
      setPostText((prev) => ({ ...prev, postDescription: "" }))
      setDomainId(option || "")
    }
  }

  const handleSubmit = async () => {
    try {
      console.log("hala madidfdkjdfldjdkjfdfkjsdkfdjsfd")
      // e.preventDefault()
      console.log("hala kadidfdkjdfldjdkjfdfkjsdkfdjsfd", post?.pageId)
      setLoading(true)
      setLoaderSwitch(!loaderSwitch)
      const postTitle = postText.postTitle || "Quick Post"
      const data = new FormData()
      if (!post?.pageId) return setIsSubmit(false)
      data.append("eventId", post?._id)
      data.append("pageId", post?.pageId)
      data.append("gallerType", "video")
      // data.append("postType", "event") //shared
      data.append("isSaved", false)
      data.append("postTitle", postTitle)
      data.append("isFile", fileInputState ? true : false)
      fileRawData?.thumbnail
        ? data.append(
          "thumbnail",
          fileRawData?.thumbnail,
          fileRawData?.thumbnail?.name
        )
        : data.append("thumbnail", post?.thumbnail)

      data.append("videolink", fileRawData?.eventVideo)

      // formData.append('file', result, result.name)
      data.append("postDescription", postText?.postDescription || "")
      data.append("postCategory", postText.postCategory || "")
      data.append("postSubDomain", postText.postSubDomain || "")
      data.append("language", postText.language || "")
      data.append(
        "ppv",
        postText?.ppv === "custom" ? postText?.ppvc || "" : postText?.ppv || ""
      )
      data.append("publishDate", postText?.publishDate || "")
      data.append("status", "draft")
      const res = await dispatch(updateEvents(data))
      setLoaderSwitch(false)
      setLoading(false)
      close()
      if (res?.data?.ack === 0) return setErrMsg(res?.data?.message)
      if (props?.getpost) await props?.getpost()
      else
        await dispatch(
          getOrganizationPosts({
            orgid: post?.pageId,
            status: "draft|published",
            postType: post?.postType,
          })
        )
      setPostIdx(res?.data?.responseData)
      setIsCopyLink(`https://${window.location.host}`)
      setShareDial(true)
      setErrMsg("")
    } catch (err) {
      console.error(err)
      setErrMsg(
        "Post upload failed due to some technical issue. Please try again later."
      )
    }
  }

  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    setIsShow(postText?.ppv === "custom" ? true : false)
  }, [postText.ppv])

  useEffect(() => {
    dispatch(
      getSubscriptionPlan({
        mode: "view",
        type: "ppv",
        sort: "price",
        stype: 1,
      })
    )
  }, [])

  useEffect(() => {
    console.log("aal bal chal", issubmit)
    if (issubmit) handleSubmit()
  }, [issubmit])

  // error clearing after 5s
  useEffect(() => {
    if (!errMsg) return
    setTimeout(() => setErrMsg(""), [5000])
  }, [errMsg])

  useEffect(() => {
    if (!isCopyLink) return
    setTimeout(() => setIsCopyLink(""), [10000])
  }, [isCopyLink])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => (domainId ? getSubDomain() : setSubDomains([])), [domainId])
  useEffect(
    () =>
      !postText?.postCategory || postText?.postCategory === "Normal"
        ? setShowTitle(false)
        : setShowTitle(true),
    [postText?.postCategory]
  )
  useEffect(
    () =>
      setCreatePost(
        postText?.postTitle &&
          postText?.postDescription &&
          fileRawData?.thumbnail &&
          postText?.publishDate &&
          postText?.ppv
          ? true
          : false
      ),
    [postText, fileRawData]
  )

  useEffect(() => {
    loaderSwitch
      ? setTimeout(() => {
        setWaitingLoader(true)
      }, [10000])
      : setWaitingLoader(false)
  }, [loaderSwitch])

  useEffect(() => {
    getCategory()
    getLanguage()
  }, [])

  // test
  useEffect(() => {
    console.log("postText", fileRawData?.eventVideo)
  }, [fileRawData])
  useEffect(() => {
    console.log("postText", postText)
  }, [postText])
  // setPostText

  return (
    <>
      <div id="createPosts" className="mt-3">
        <div className="d-flex">
          <div className="dom_title">Tags </div>
          <div className="w-100 bg-danger cpost_select">
            <select name="language" onChange={handleChange}>
              <option className="t_cap" value="English">
                Language
              </option>
              {language &&
                language.map((item, index) => {
                  return (
                    <option
                      className="t_cap"
                      value={item.language}
                      key={item._id + index}
                    >
                      {item.language}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className="w-100">
            <select name="postCategory" onChange={handleChange}>
              <option className="t_cap" value="Normal">
                Domain
              </option>

              {postCategory &&
                postCategory.map((item, index) => {
                  return (
                    <option
                      className="t_cap"
                      id={item._id}
                      value={item.postCategory}
                      key={item._id + index}
                    >
                      {item.postCategory}
                    </option>
                  )
                })}
            </select>
          </div>

          <div className="w-100">
            <select name="postSubDomain" onChange={handleChange}>
              <option className="t_cap" value="Normal">
                Sub domain
              </option>
              {subDomains &&
                subDomains.map((item, index) => {
                  return (
                    <option
                      className="t_cap"
                      value={item.postSubCategory}
                      key={item._id + index}
                    >
                      {item.postSubCategory}
                    </option>
                  )
                })}
            </select>
          </div>
        </div>

        <div className="cpost_body">
          <form onSubmit={handleSubmit} className="cpost_info_sec w-100 p-2">
            <label className="fs-14">
              Title <span className="text-danger">*</span>
            </label>
            <br />
            <input
              type="text"
              className="inputs"
              name="postTitle"
              onChange={handleChange}
              value={postText?.postTitle || ""}
              placeholder="Enter title"
            // required='required'
            />
            <div className="row">
              <div className="col-lg-6">
                <label className="fs-14 mt-2">
                  Publish Date <span className="text-danger">*</span>
                </label>
                <br />
                <input
                  type="date"
                  className="inputs"
                  name="publishDate"
                  onChange={handleChange}
                  value={
                    moment(postText?.publishDate).format("YYYY-MM-DD") || ""
                  }
                  placeholder="Pick publish date"
                  required="required"
                />
              </div>
              <div className="col-lg-6">
                <label className="fs-14 mt-2">
                  Pay Per View Price <span className="text-danger">*</span>
                </label>
                <br />
                <select
                  className="form-select"
                  name="ppv"
                  onChange={handleChange}
                  value={postText?.ppv || ""}
                  required
                >
                  <option className="" value="">
                    Select ppv plan
                  </option>
                  <option className="" value={0}>
                    Free
                  </option>
                  <option className="" value={5}>
                    5
                  </option>
                  <option className="" value={10}>
                    10
                  </option>
                  <option className="" value={15}>
                    15
                  </option>
                  <option className="" value={20}>
                    20
                  </option>
                  <option className="" value={"custom"}>
                    Custom
                  </option>
                </select>
                {isShow && (
                  <input
                    type="number"
                    min="0"
                    max={200}
                    name="ppvc"
                    className="form-control"
                    onChange={handleChange}
                    value={postText?.ppvc || ""}
                    placeholder="Enter custom ppv amount"
                    required
                  />
                )}
              </div>
              <div className="col-lg-6 modal_image_input_1">
                <label title="Click to Attach Photo" className="mt-4">
                  <InsertPhotoIcon />
                  Select Thumbnail Photo <span className="text-danger">*</span>
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    name="thumbnail"
                    onChange={handleFileChange}
                    value={fileInputState}
                    required
                  />
                </label>
              </div>
            </div>
            {filePreview?.thumbnail ? (
              <div className="create_post_img__1">
                <div
                  className="cross"
                  title="click to discard image"
                  onClick={() => {
                    setFilePreview((prev) => ({ ...prev, thumbnail: "" }))
                    setFileRawData((prev) => ({ ...prev, thumbnail: "" }))
                  }}
                >
                  x
                </div>
                <img
                  className="height_max w-100"
                  src={filePreview?.thumbnail || ""}
                  alt="Select thumbnail"
                />
              </div>
            ) : (
              ""
            )}

            <label className="fs-14 mt-2">
              Description <span className="text-danger">*</span>
            </label>
            {editorLoaded ? (
              <div className="mt-1">
                <CKEditor
                  editor={ClassicEditor}
                  data={postText?.postDescription || ""}
                  config={{
                    removePlugins: ["MediaEmbed"],
                    placeholder: "Type the description here!",
                    link: {
                      // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                      addTargetToExternalLinks: true,
                      decorators: [
                        {
                          mode: "manual",
                          label: "External Link",
                          attributes: {
                            target: "_blank",
                          },
                        },
                      ],
                    },
                  }}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    //console.log('Editor is ready to use!', editor)
                  }}
                  onChange={(event, editor) => {
                    console.log("editrData", editor.getData())
                    setPostText((editObj) => ({
                      ...editObj,
                      postDescription: editor.getData(),
                    }))
                  }}
                  onBlur={(event, editor) => {
                    // //console.log( 'Blur.', editor )
                  }}
                  onFocus={(event, editor) => {
                    // //console.log( 'Focus.', editor )
                  }}
                />
              </div>
            ) : (
              <div>
                <SimpleCircle />
              </div>
            )}

            {showTitle && (
              <>
                <label className="post_label fs-14">HashTags :</label>
                <input
                  type="text"
                  className="inputs w-100 mt-2"
                  name="keywords"
                  onChange={handleChange}
                  value={postText?.keywords || ""}
                  placeholder="Enter hash tags"
                />
              </>
            )}

            <div className="">
              {filePreview?.eventVideo ? (
                <>
                  <div className="create_post_img__1">
                    <div
                      className="cross"
                      title="click to discard image"
                      onClick={() => {
                        setFilePreview((prev) => ({ ...prev, eventVideo: "" }))
                        setFileRawData((prev) => ({ ...prev, eventVideo: "" }))
                      }}
                    >
                      x
                    </div>

                    <video
                      id={filePreview?.eventVideo || ""}
                      key={filePreview?.eventVideo || ""}
                      controls
                      controlsList="nodownload"
                    >
                      <source
                        src={filePreview?.eventVideo || ""}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="post_action my-3">
              <div className="modal_image_input_1">
                <label title="Click to select event video. You can upload it later">
                  <>
                    <VideocamIcon />
                    <span className="m_none">Select Event Video</span>
                  </>
                  <input
                    type="file"
                    name="eventVideo"
                    onChange={handleFileChange}
                    value={fileInputState}
                    accept=".mp4, .mov, .webm, .m4a, .m4v, .avi"
                  />
                </label>
              </div>
            </div>
          </form>
        </div>

        {isCopyLink && postIdx && (
          <div className="p-3">
            {isCopyLink + `/p/${postIdx}?redirect=1`}
            <CopyLink link={`/p/${postIdx}?redirect=1`} />
          </div>
        )}

        {shareDial && postIdx && (
          <CustomizedSnackbars type={0} msg={"Your post uploaded succefully"} />
        )}
        {errMsg && <CustomizedSnackbars type={1} msg={errMsg} />}
      </div>
    </>
  )
}

export default function EditEventModal(props) {
  const { post, component } = props
  // const [post, setPost] = useState(props?.post)
  const [open, setOpen] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
    setIsSubmit(false)
  }
  const handleClose = () => {
    setOpen(false)
    setIsSubmit(false)
  }

  const handleSubmitBtn = () => {
    setIsSubmit(true)
    // handleClose()
  }

  const [loading, setLoading] = useState(false)

  return (
    <div>
      {component ? (
        <div onClick={handleClickOpen} className="c_pointer">
          {component}
        </div>
      ) : (
        <>
          <Button onClick={handleClickOpen} className="eclipse_btn">
            <EditIcon /> Edit Post
          </Button>
        </>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Edit Post
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div>
            <UserProfilePhoto photo={post?.user_info[0]?.profilePhoto} />
            <span className="px-2">{post?.user_info[0]?.showName}</span>
          </div>
          <EditEvent
            postType={props?.postType}
            post={post}
            close={() => handleClose()}
            issubmit={isSubmit}
            setIsSubmit={setIsSubmit}
            clickSubmit={() => handleSubmitBtn()}
            loading={loading}
            setLoading={setLoading}
          />
        </DialogContent>
        <DialogActions>
          {loading ?
            <div className="btn btn-primary">
              <SimpleCircle />
            </div> :
            <Button autoFocus onClick={handleSubmitBtn}>
              Save changes
            </Button>}

        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
