import React from "react"
import ReactDOM from "react-dom"
// css
import "./assets/css/pages/auth.css"
import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/pages/profile.css"
import "./assets/css/navbar.css"
import "./assets/css/accounts.css"
import "./assets/css/settings.css"
import { ClientApp } from "./App"
import configureStore from "./new/redux/store"
// import configureStore, { persistor, store } from "./new/redux/store"
// import { PersistGate } from "redux-persist/integration/react"
// import { Provider } from "react-redux"

// const root = ReactDOM.createRoot(document.getElementById("root"))
// pages

// import PrivateOutlet from './_helpers/PrivateOutlet'
const render = (store, shouldHydrate) => {
  console.log("test", { store, shouldHydrate })
  // If the server already loaded the auth information, render the app
  // immediately. Otherwise wait for the flag to be loaded and render
  // when auth information is present.
  const state = store.getState()
  console.log("state>>>", state)
  // const cdnAssetsVersion = state.hostedAssets.version
  // const authInfoLoaded = state.Auth.authInfoLoaded
  // const info = authInfoLoaded ? Promise.resolve({}) : store.dispatch(authInfo())
  // info
  //   .then(() => {
  //     store.dispatch(fetchCurrentUser())
  //     // Ensure that Loadable Components is ready
  //     // and fetch hosted assets in parallel before initializing the ClientApp
  //     return Promise.all([
  //       loadableReady(),
  //       store.dispatch(fetchAppAssets(config.appCdnAssets, cdnAssetsVersion)),
  //     ])
  //   })
  //   .then(([_, fetchedAssets]) => {
  //     const translations = fetchedAssets?.translations?.data || {}
  if (shouldHydrate) {
    ReactDOM.hydrate(
      <ClientApp store={store} />,
      document.getElementById("root")
    )
  } else {
    ReactDOM.render(
      <ClientApp store={store} />,
      document.getElementById("root")
    )
  }
  // })
  // .catch(e => {
  //   log.error(e, 'browser-side-render-failed')
  // })
}
// ReactDOM.hydrate(
//   // <Provider store={store}>
//   //   <PersistGate loading={null} persistor={persistor}>
//   <App />,
//   //   </PersistGate>
//   // </Provider>,
//   document.getElementById("root")
// )
const token =
  typeof window !== "undefined" ? localStorage.getItem("token") : ""
const currentUser =
  typeof window !== "undefined"
    ? JSON.parse(localStorage.getItem("currentuser"))
    : ""

const store = configureStore({
  auth: { user: currentUser, isAuthenticated: token ? true : false },
})

render(store, !!window.__PRELOADED_STATE__)
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
