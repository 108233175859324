import React, { useEffect, useState } from "react"
import Select from "react-select"
import validate from "../../validations/FormValidationRule"
import {
  CustomizedSnackbars,
  Footer,
  IndexNavbar,
  SimpleCircle,
} from "../../components"
import ProfileSettingsNav from "../../components/Settings/ProfileSettingsNav"
import { connect, useDispatch } from "react-redux"
import { getRequestHandler, patchRequestHandler } from "../../redux/reducers/requestHandler.reducer"

export const AllSkills = [
  { value: 'Music', label: 'Music' },
  { value: 'Video', label: 'Video' },
  { value: 'Dance', label: 'Dance' },
  { value: 'Drama', label: 'Drama' },
  { value: 'Writing', label: 'Writing' },
  { value: 'Poetry', label: 'Poetry' },
  { value: 'Painting', label: 'Painting' },
  { value: 'Drawing', label: 'Drawing' },
  { value: 'Photography', label: 'Photography' },
  { value: 'Fashion', label: 'Fashion' },
]

// export const Interests = [
//   { value: 'Music', label: 'Music' },
//   { value: 'Video', label: 'Video' },
//   { value: 'Dance', label: 'Dance' },
//   { value: 'Drama', label: 'Drama' },
//   { value: 'Writing', label: 'Writing' },
//   { value: 'Poetry', label: 'Poetry' },
//   { value: 'Painting', label: 'Painting' },
//   { value: 'Drawing', label: 'Drawing' },
//   { value: 'Photography', label: 'Photography' },
//   { value: 'Fashion', label: 'Fashion' },
// ]

function BasicSettings(props) {
  const { basic } = props
  const [values, setValues] = useState(basic)
  const [country, setCountry] = useState([])
  const [errors, setErrors] = useState({})
  // const [duplicate, setDuplicate] = useState({})
  const [inputfocus, setInputfocus] = useState({})
  const [isDisplayName, setIsDisplayName] = useState(false)
  const [isSubmitting] = useState(true)
  const [success, setSuccess] = useState(false)
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [options, setOptions] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])
  const [selectedSkills, setSelectedSkills] = useState([])
  const [selectedInterest, setSelectedInterest] = useState([])
  const [succesMsg] = useState("Settings saved successfully")
  const dispatch = useDispatch()

  // unique items
  const [userTemp, setUserTemp] = useState({})
  const [tempLang, setTempLang] = useState([])


  const getCountry = async () => {
    try {
      let req = {
        url: 'country'
      }
      const response = await dispatch(getRequestHandler(req))
      setCountry(response.data.responseData)
    } catch (error) {
      console.error(error)
    }
  }



  // getting languages
  const getLanguage = async (e) => {
    let req = { url: 'language' }
    const response = await dispatch(getRequestHandler(req))
    response.data.responseData.map(item =>
      setOptions((options) => [
        ...options,
        { value: item.language, label: item.language },
      ]))
  }

  const handleSelectChange = (e) => {
    setSelectedOptions(e.map((item, i) => item.value))
  }

  const handleIsDisplayName = (e) => {
    setIsDisplayName(!isDisplayName)
  }

  const handleChange = async (e) => {
    e.persist()
    if (e.target.name === "phone" || e.target.name === "altPhoneNo")
      if (isNaN(e.target.value)) return false

    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }))
    setErrors(validate(e.target.value, e.target.name))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSuccess(false)
    setLoadingStatus(true)
    console.log("vasidf", isSubmitting)
    if (isSubmitting === false) return setLoadingStatus(false)
    let name = values.firstName + " " + values.lastName
    let showNamex = {}

    if (isDisplayName === true) {
      showNamex = {
        showName: values?.userName,
      }
      values.isShowName = true
    } else {
      showNamex = {
        showName: name,
      }
      values.isShowName = false
    }
    let defLang = {
      defaultLanguage: values.defaultLanguage,
    }
    if (selectedOptions.length > 0)
      defLang = {
        defaultLanguage: selectedOptions,
      }

    let intr = {
      interest: values?.interest
    }
    if (selectedInterest?.length > 0)
      intr = {
        interest: selectedInterest
      }

    let skill = {
      skills: values?.skills
    }
    if (selectedSkills?.length > 0)
      skill = {
        skills: selectedSkills
      }

    let data = Object.assign(values, showNamex, defLang, intr, skill)
    let req = {
      url: 'updateUser',
      data: data
    }
    const response = await dispatch(patchRequestHandler(req))
    if (response.data.status === 200) {

      setTimeout(() => {
        setLoadingStatus(false)
        setSuccess(true)
      }, 500)
    }
  }


  useEffect(() => {
    if (!success) return
    const timer = setTimeout(() => {
      setSuccess(false)
    }, 4000)
    return () => clearTimeout(timer)
  }, [success])

  useEffect(() => {
    setValues(basic)
    setUserTemp(basic)
    setIsDisplayName(basic?.isShowName)
  }, [basic])

  useEffect(() => {
    // getUser()
    getCountry()
    getLanguage()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <IndexNavbar />
      <div className="container my-4">
        <ProfileSettingsNav />

        <div
          id="basic"
          className="row bg_allp3 justify-content-center settings_row border_1 mt-4 border_r5"
        >
          <div className="col-lg-8">
            <div className="mt-3 mb-5 text-center">
              <h4>Profile Information</h4>
            </div>
            {/* <div className="mt-3">Profile</div> */}
            <form onSubmit={handleSubmit} className="mt-3">
              <div className="row justify-content-center">
                <div className="col-lg-10">
                  <div className="d-flex gap-3">
                    {/* first Name  */}
                    <div className="w-100">
                      <label htmlFor="First Name" className="log-input-title">
                        First Name{" "}
                      </label>
                      <div className="mb-3 cl_sec fs_s">Required</div>

                      <input
                        type="text"
                        className="form-control form-control-xl mt-2"
                        autoComplete="on"
                        placeholder="Enter first name here"
                        name="firstName"
                        onFocus={(e) =>
                          setInputfocus((inputfocus) => ({
                            ...inputfocus,
                            [e.target.name]: true,
                          }))
                        }
                        onBlur={(e) =>
                          setInputfocus((inputfocus) => ({
                            ...inputfocus,
                            [e.target.name]: false,
                          }))
                        }
                        onChange={handleChange}
                        value={values?.firstName || ""}
                        required
                      />

                      <div className="errors">
                        {!inputfocus.firstName && errors.firstName}
                      </div>
                    </div>

                    {/* Last name  */}
                    <div className="w-100">
                      <label htmlFor="last name" className="log-input-title">
                        Last Name{" "}
                      </label>
                      <div className="mb-3 cl_sec fs_s">Required</div>

                      <input
                        type="text"
                        className="form-control form-control-xl mt-2"
                        autoComplete="on"
                        placeholder="Enter last name here"
                        name="lastName"
                        onFocus={(e) =>
                          setInputfocus((inputfocus) => ({
                            ...inputfocus,
                            [e.target.name]: true,
                          }))
                        }
                        onBlur={(e) =>
                          setInputfocus((inputfocus) => ({
                            ...inputfocus,
                            [e.target.name]: false,
                          }))
                        }
                        onChange={handleChange}
                        value={values?.lastName || ""}

                        required
                      />
                      <div className="errors">
                        {!inputfocus.lastName && errors.lastName}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 fs_choto cl_gray info">
                    Help people discover your account by using the name you're
                    known by: either your full name, nickname, or business name.
                  </div>

                  <div className="mt-4">
                    <div className="w-100">
                      <label htmlFor="user name" className="log-input-title">
                        User Name{" "}
                      </label>
                      <div className="mb-3 cl_sec fs_s">Required</div>

                      <input
                        type="text"
                        className="form-control form-control-xl mt-2"
                        autoComplete="on"
                        placeholder="User Name"
                        name="userName"
                        // onFocus={(e) =>
                        // 	setInputfocus((inputfocus) => ({
                        // 		...inputfocus,
                        // 		[e.target.name]: true,
                        // 	}))
                        // }
                        // onBlur={(e) =>
                        // 	setInputfocus((inputfocus) => ({
                        // 		...inputfocus,
                        // 		[e.target.name]: false,
                        // 	}))
                        // }
                        // onChange={handleChange}
                        value={values?.userName || ""}
                        // required
                        disabled
                      />
                      {/* <div className="errors">
                        {(!inputfocus?.userName && errors?.userName)}
                      </div> */}
                    </div>
                  </div>

                  {/* <div className='mt-3 fs_choto cl_gray info'>
                                Help people discover your account by using the name you're known by: 
                                either your full name, nickname, business name, user name.
                            </div> */}

                  <div className="mt-4 fs_choto">
                    <input
                      type="checkbox"
                      className="kk_check"
                      id="termsAgreed"
                      name="isDisplayed"
                      value="isDisplayed"
                      checked={isDisplayName}
                      onChange={handleIsDisplayName}
                    />
                    Show username as display name
                  </div>
                  <div className="mt-2 fs_choto cl_gray info">
                    Help people discover your account by using the name you're
                    known by: either your full name, nickname, business name,
                    user name.
                  </div>

                  <div className="mt-4" id="YourIntro">
                    <div className="w-100">
                      <label htmlFor="last name" className="log-input-title">
                        Your Intro Text
                      </label>
                      <div className="mb-3 cl_sec fs_s">Required</div>

                      <textarea
                        className="form-control form-control-xl mt-2"
                        placeholder="About information"
                        name="bio"
                        rows="5"
                        onChange={handleChange}
                        value={values?.bio || ""}
                      ></textarea>
                      <div className="mt-4 fs_choto cl_gray info">
                        <div className="info_title">Personal Information</div>
                        This is the first thing potential Allphanes will see
                        when they land on your page, so make sure you paint a
                        compelling picture of how they can join you on this
                        journey.
                      </div>
                    </div>
                  </div>

                  <div className="mt-4" id="YourIntro">
                    <div className="w-100">
                      <label htmlFor="last name" className="log-input-title">
                        Experience:
                      </label>
                      {/* <div className="mb-3 cl_sec fs_s">Required</div> */}

                      <textarea
                        className="form-control form-control-xl mt-2"
                        placeholder="Your Experience"
                        name="experience"
                        onChange={handleChange}
                        value={values?.experience || ''}
                        rows="5"
                      ></textarea>
                    </div>
                  </div>

                  {/* <IntroVideo /> */}

                  <div className="mt-4">
                    {/* email  */}
                    <div className="form-group position-relative has-icon-left mb-2">
                      <label htmlFor="" className="log-input-title">
                        Email Address{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control form-control-xl mt-2"
                        autoComplete="on"
                        placeholder="Enter email address here"
                        name="email"
                        // onFocus={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: true }))}
                        // onBlur={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: false }))}
                        // onChange={handleChange}
                        value={values?.email || ""}
                        // required
                        disabled
                      />
                      {/* <div className="errors">{!inputfocus.email && errors.email}</div> */}
                    </div>
                  </div>

                  <div className="mt-4">
                    {/* phone  */}
                    <div className="form-group position-relative has-icon-left mb-2">
                      <label htmlFor="" className="log-input-title">
                        Primary Phone{" "}
                      </label>
                      <input
                        type="tel"
                        className="form-control form-control-xl mt-1"
                        autoComplete="on"
                        placeholder="Enter phone no. here"
                        name="phone"
                        // onFocus={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: true }))}
                        // onBlur={e => setInputfocus(inputfocus => ({ ...inputfocus, [e.target.name]: false }))}
                        // onChange={handleChange}
                        value={values?.phone || ""}
                        // required
                        disabled
                      />
                      {/* <div className="errors">{!inputfocus.phone && errors.phone}</div> */}
                    </div>
                  </div>

                  {/*2nd phone  */}
                  <div className="mt-4">
                    <div className="form-group position-relative has-icon-left mb-2">
                      <label htmlFor="" className="log-input-title">
                        Secondary Phone{" "}
                      </label>
                      <input
                        type="tel"
                        className="form-control form-control-xl mt-1"
                        autoComplete="on"
                        placeholder="Add alternative number"
                        name="altPhoneNo"
                        onFocus={(e) =>
                          setInputfocus((inputfocus) => ({
                            ...inputfocus,
                            [e.target.name]: true,
                          }))
                        }
                        onBlur={(e) =>
                          setInputfocus((inputfocus) => ({
                            ...inputfocus,
                            [e.target.name]: false,
                          }))
                        }
                        onChange={handleChange}
                        value={values?.altPhoneNo || ""}
                      // required
                      />
                      <div className="errors">
                        {!inputfocus.altPhoneNo && errors.altPhoneNo}
                      </div>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="w-100">
                      <label htmlFor="website" className="log-input-title">
                        Website{" "}
                      </label>

                      <input
                        type="text"
                        className="form-control form-control-xl mt-2"
                        autoComplete="on"
                        placeholder="Website domain"
                        name="website"
                        onFocus={(e) =>
                          setInputfocus((inputfocus) => ({
                            ...inputfocus,
                            [e.target.name]: true,
                          }))
                        }
                        onBlur={(e) =>
                          setInputfocus((inputfocus) => ({
                            ...inputfocus,
                            [e.target.name]: false,
                          }))
                        }
                        onChange={handleChange}
                        value={values?.website || ""}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="w-100">
                      <label htmlFor="last name" className="log-input-title">
                        {" "}
                        Gender
                      </label>

                      <select
                        className="form-control form-control-xl mt-2"
                        name="gender"
                        onChange={handleChange}
                      >
                        <option value="optout">Select Gender</option>
                        <option
                          value="male"
                          selected={values?.gender === "male" ? "selected" : ""}
                        >
                          Male
                        </option>
                        <option
                          value="female"
                          selected={
                            values?.gender === "female" ? "selected" : ""
                          }
                        >
                          Female
                        </option>
                        <option
                          value="custom"
                          selected={
                            values?.gender === "custom" ? "selected" : ""
                          }
                        >
                          Other
                        </option>
                        <option
                          value="optout"
                          selected={
                            values?.gender === "optout" ? "selected" : ""
                          }
                        >
                          Prefer not to say
                        </option>
                      </select>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="w-100">
                      <label htmlFor="last name" className="log-input-title">
                        {" "}
                        Country of Residence
                      </label>

                      <select
                        className="form-control form-control-xl mt-2"
                        name="referenceCountryId"
                        onChange={handleChange}
                      >
                        <option value="optout" selected>
                          Select Country
                        </option>
                        {country &&
                          country.map((item, index) => {
                            return (
                              <option
                                value={item._id}
                                key={index}
                                selected={
                                  values?.referenceCountryId === item._id
                                    ? "selected"
                                    : ""
                                }
                              >
                                {item?.name}
                              </option>
                            )
                          })}
                      </select>
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="w-100">
                      <label
                        htmlFor="Default language"
                        className="log-input-title"
                      >
                        {" "}
                        Default Language
                      </label>
                      <div className="fs_choto cl_gray info">
                        <span className="info_title">Selected languages : </span>
                        {basic?.defaultLanguage?.length > 0 && basic?.defaultLanguage?.map(item =>
                          <>
                            <span className="lang_box" key={item}>{item}</span>
                          </>)}
                      </div>
                      {/* {defaultLanguage}
                       */}
                      <Select
                        isMulti
                        defaultValue={tempLang}
                        name="languages"
                        options={options}
                        className="basic-multi-select mt-2"
                        classNamePrefix="select"
                        value={options.find(
                          (obj) => obj.value === selectedOptions
                        )}
                        onChange={handleSelectChange}
                        // trim={true}
                        placeholder="Choose languages"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="w-100">
                      <label
                        htmlFor="Default language"
                        className="log-input-title"
                      >
                        {" "}
                        Skills
                      </label>
                      <div className="fs_choto cl_gray info">
                        <span className="info_title">Selected Skills : </span>
                        {basic?.skills?.length > 0 && basic?.skills?.map(item =>
                          <>
                            <span className="lang_box" key={item}>{item}</span>
                          </>)}
                      </div>
                      {/* {defaultLanguage}
                       */}
                      <Select
                        isMulti
                        // defaultValue={tempLang}
                        name="skills"
                        options={AllSkills}
                        className="basic-multi-select mt-2"
                        classNamePrefix="select"
                        value={AllSkills.find(
                          (obj) => obj.value === selectedSkills
                        )}
                        onChange={e => setSelectedSkills(e.map((item, i) => item.value))}
                        // trim={true}
                        placeholder="Choose skills"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="w-100">
                      <label
                        htmlFor="Default language"
                        className="log-input-title"
                      >
                        {" "}
                        Interest
                      </label>
                      <div className="fs_choto cl_gray info">
                        <span className="info_title">Selected Interest : </span>
                        {basic?.interest?.length > 0 && basic?.interest?.map(item =>
                          <>
                            <span className="lang_box" key={item}>{item}</span>
                          </>)}
                      </div>
                      {/* {defaultLanguage}
                       */}
                      <Select
                        isMulti
                        // defaultValue={tempLang}
                        name="interest"
                        options={AllSkills}
                        className="basic-multi-select mt-2"
                        classNamePrefix="select"
                        value={AllSkills.find(
                          obj => obj.value === selectedInterest
                        )}
                        onChange={e => setSelectedInterest(e.map((item, i) => item.value))}

                        placeholder="Choose interest"
                      />
                    </div>
                  </div>


                  <div className="mt-4">
                    <div className="w-100">
                      <label htmlFor="tagline" className="log-input-title">
                        Tagline{" "}
                      </label>

                      <input
                        type="text"
                        className="form-control form-control-xl mt-2"
                        autoComplete="on"
                        placeholder="Enter your tagline"
                        name="tagline"
                        onChange={handleChange}
                        value={values?.tagline || ""}
                      />
                      <div className="fs_choto cl_gray info mt-2">
                        <span className="info_title">Tag Line: </span>
                        <span >
                          Add a 50 character tag line. Eg. 'Bengali Poet' or 'Rabindra Sangeet Singer' or 'Painter'. This Tag line will be shown in Member page below Profile Picture.

                        </span>
                      </div>

                    </div>
                  </div>

                  <div className="mt-3">
                    {/* <button type='submit' className='btn btn-primary mt-5' disabled={btnDisable}>Submit</button> */}
                    {loadingStatus ? (
                      <button
                        type="button"
                        className="btn btn-primary mt-3 w_100x"
                      >
                        <SimpleCircle />
                      </button>
                    ) : (
                      <>
                        <button type="submit" className="btn btn-primary mt-3">
                          Save Profile
                        </button>
                        {success && (
                          <CustomizedSnackbars
                            type={0}
                            msg={succesMsg}
                            sclose={() => setSuccess(false)}
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
BasicSettings.loadData = (params, search) => async (dispatch) => {
  let req = {
    params: {},
    action: 'ACTION_GET_BASIC_DETAILS',
    url: 'settingUser'
  }
  return await dispatch(getRequestHandler(req))
}
const mapStateToProps = (state) => {
  console.log("stateoRgpostcreationposts", state)
  const { basic } = state.settings
  return { basic }
}
const mapDispatchToProps = (dispatch) => {
  return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(BasicSettings)
