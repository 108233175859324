import React from 'react'
import { Table } from 'react-bootstrap'
import ManagementLeftNav from '../../components/Navbars/ManagementLeftNav'
import { getTransactions } from '../../redux/reducers/transactions.reducer'
import { connect } from 'react-redux'
import moment from 'moment'

function AdminWalletManagement(props) {
    const { transactionHistory } = props

    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-lg-2">
                        <ManagementLeftNav />
                    </div>
                    <div className="col-lg-10 bg-white" style={{ minHeight: "100vh" }}>

                        <div className="mt-2 py-2 pb-4 mt-3">
                            <h4>Wallet Management</h4>
                            <Table striped bordered hover responsive="sm">
                                <thead>
                                    <tr>
                                        <th>No</th>
                                        <th>Name</th>
                                        <th>Registered Number</th>
                                        <th>Transaction Name</th>
                                        <th>Type</th>
                                        <th>Transaction ID</th>
                                        <th>Amount</th>
                                        <th>Created At</th>
                                        <th>Approved By</th>
                                        <th>Approved At</th>
                                        <th>status</th>

                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactionHistory?.length > 0 ?
                                        transactionHistory?.map((item, index) =>
                                            <>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.referenceUserId?.showName}</td>
                                                    <td>{`${item?.referenceUserId?.countryDialCode + item?.referenceUserId?.phone}`}</td>
                                                    <td>{item?.fullName}</td>
                                                    <td>{item?.transType}</td>
                                                    <td>{item?.transactionId}</td>
                                                    <td>{item?.amount}</td>
                                                    <td>{moment(item?.createdAt).format('DD-MM-YYYY h:m a')}</td>
                                                    <td>{item?.approvedBy}</td>
                                                    <td>{item?.approvedAt && moment(item?.approvedAt).format('DD-MM-YYYY h:m a')}</td>
                                                    <td>{item?.status}</td>

                                                    <td>

                                                    </td>
                                                </tr>
                                            </>
                                        )
                                        : ""
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

AdminWalletManagement.loadData = (params, search) => async (dispatch) => {

    return dispatch(getTransactions({
        page: 1,
        transType: 'deposit',
        status: 'pending',
        admin: true
    }))

}
const mapStateToProps = (state) => {
    console.log("stateoRgpostcreationpostsdfd", state)
    const { editUser } = state.auth
    const { transactionHistory } = state.transactions
    return { editUser, transactionHistory }
}
const mapDispatchToProps = (dispatch) => {

    return {
        getTransactionsList: async params => await dispatch(getTransactions(params))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminWalletManagement)