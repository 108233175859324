import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { CustomizedSnackbars, SimpleCircle } from '../../components'
import { useDispatch } from 'react-redux'
import { postRequestHandler } from '../../redux/reducers/requestHandler.reducer'

const ReactivateAccount = (props) => {
    const ractivateEmail = typeof window !== "undefined" ? localStorage.getItem("reActivaEmail") : ""
    const [isSubmit, setIsSubmit] = useState(false)
    const [isError, setIsError] = useState(false)
    const [msg, setMsg] = useState('')
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleFindUserSubmit = async e => {
        try {
            e.preventDefault()
            setIsSubmit(true)
            if (!ractivateEmail) return setIsSubmit(false)
            let req = {
                data: {
                    email: ractivateEmail,
                    sendon: 'email'
                },
                url: 'sendOTP'
            }

            const response = await dispatch(postRequestHandler(req))
            setIsSubmit(false)
            if (response.data.ack === 0) {
                setIsError(true)
                return setMsg("Opps! Can't verify this process due to some technical error")
            }
            // localStorage.clear()
            localStorage.setItem('resetuser', response?.data?.responseData)
            navigate('/accounts/verify/code/?sendon=mail')
        } catch (err) {
            console.error("catchblock>>", err)
        }

    }

    return (
        <div className='Login'>
            <div className='container-xl'>
                <div class="row align-items-center justify-content-center hxz-100">
                    <div class="col-lg-5 order-lg-3 order-md-3 order-1">
                        <div className="login_col_main">
                            <div className="box forget_password">
                                <a class="main_logo m-hide" href="/"><img src="/assets/web_img/allphance_logoblack.png" alt="allphenes logo" loading="lazy" /></a>
                                <h4 className='mt-2'>Want to reactivate your account?</h4>
                                <p className='sub_head'>You have to verify your account. Please click below to get 6 six digit OTP to your email : <span className='text-primary'>{ractivateEmail}</span></p>
                                {isSubmit ?
                                    <button
                                        type='button'
                                        class="btn cmn-new_btn my-3"
                                    >
                                        <SimpleCircle />
                                        {/* Reactivate Account <img src="/assets/web_img/arow_new.png" alt="sound" /> */}
                                    </button>
                                    :
                                    <button
                                        type='button'
                                        onClick={handleFindUserSubmit}
                                        class="btn cmn-new_btn my-3"
                                    >
                                        Reactivate Account <img src="/assets/web_img/arow_new.png" alt="sound" />
                                    </button>
                                }


                            </div>
                            <div className="box">
                                <Link to="/auth/login" className="fb-login-btn" type="button">
                                    Back to login
                                </Link>
                            </div>

                            {isError && <CustomizedSnackbars type={1} msg={msg} sclose={() => setIsError(false)} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReactivateAccount