import React, { useEffect, useState } from "react"
import moment from "moment"

import { Link } from "react-router-dom"
import { connect, useDispatch } from "react-redux"
import { getNotifications } from "../../redux/reducers/notification.reducer"


function Notifications(props) {
  const { notifications } = props
  const [page, setPage] = useState(1)
  const [hit, setHit] = useState(true)
  const dispatch = useDispatch()
  console.log("getNots", props)

  const getNots = async () => {
    try {
      const response = await dispatch(getNotifications({
        page: page,
        perPage: 10
      }))
      setHit(response?.data?.responseData?.length > 0 ? true : false)
      // console.log("getNots", response, page)
    } catch (error) {
      console.log(error)
    }
  }

  const handleScroll = (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target

    if (offsetHeight + scrollTop > scrollHeight - 10 && hit) {
      setHit(false)
      setPage(page + 1)
    }
  }

  useEffect(() => {
    if (page > 1)
      getNots()
  }, [page])

  return (
    <div className="search_res shadow br_5 wi_80 mt_67" onScroll={handleScroll}>

      <div className="container-fluid">
        <div className="d-flex justify-content-between px-3 mb_none">
          <h5 className="mt-2">Notifications</h5>
          <button
            onClick={() => props?.onhide()}
            className="btn btn-primary mt-1"
          >
            X
          </button>
        </div>
        <div className="row pb-3 justify-content-center">
          {notifications &&
            notifications?.map((item, index) => {
              return (
                <div className="col-lg-12 mt-2 br_5" key={index} id={item?._id}>
                  <div className="notification_body bg-white shadow_1 p-2">
                    <div className="notification_msg">
                      {item?.notificationCode === "NTFAR001" && (
                        <div>
                          <Link
                            onClick={() => props?.onhide()}
                            to={"/friends-list"}
                          >
                            {item?.msgUserId?.showName} accepted your friend
                            request.{" "}
                          </Link>
                        </div>
                      )}

                      {item?.notificationCode === "NTFAR002" && (
                        <div>
                          <Link
                            onClick={() => props?.onhide()}
                            to={"/friends-list"}
                          >
                            {item?.msgUserId?.showName} and you are now friends{" "}
                          </Link>
                          .
                        </div>
                      )}

                      {item?.notificationCode === "NTFSR002" && (
                        <div>
                          <Link
                            onClick={() => props?.onhide()}
                            to={"/request-list"}
                          >
                            {item?.msgUserId?.showName} Sent you friend request
                          </Link>
                          .
                        </div>
                      )}

                      {item?.notificationCode === "NTFSR001" && (
                        <div>
                          <Link onClick={() => props?.onhide()} to={"/sent-list"}>
                            {" "}
                            You sent friend request to {item?.msgUserId?.showName}
                          </Link>
                          .
                        </div>
                      )}

                      {item?.notificationCode === "NTFFL002" && (
                        <div>
                          <Link onClick={() => props?.onhide()} to={`/${item?.msgUserId?.userName}/`}>
                            {" "}
                            You are now following {item?.msgUserId?.showName}
                          </Link>
                          .
                        </div>
                      )}

                      {item?.notificationCode === "NTFFL001" && (
                        <div>
                          <Link onClick={() => props?.onhide()} to={`/${item?.msgUserId?.userName}/`}>
                            {" "}
                            {item?.msgUserId?.showName} started following you
                          </Link>
                          .
                        </div>
                      )}

                      {item?.notificationCode === "NTFNU001" && item?.msgUserId && (
                        <div>
                          <Link onClick={() => props?.onhide()} to="/members">
                            {item?.msgUserId?.showName} registered at Allphanes.
                          </Link>
                        </div>
                      )}

                      {item?.notificationCode === "NTFNP001" && (
                        <div>
                          {item?.referencePostId ? (
                            <Link
                              to={"/p/" + item?.referencePostId}
                              onClick={() => props?.onhide()}
                            >
                              {item?.msgUserId?.showName} uploaded a new post.
                            </Link>
                          ) : (
                            <>{item?.msgUserId?.showName} uploaded a new post.</>
                          )}
                        </div>
                      )}

                      {item?.notificationCode === "NTFNOC001" && <>
                        {item?.pageId?.mode === 'pre' ?
                          <Link
                            to={`/premium/${item?.pageId?.pageName}/#freepost/`}
                          >
                            {item?.msgUserId?.showName} created a new Premium page {item?.pageId?.pageName}

                          </Link> :
                          <Link
                            to={`/org/${item?.pageId?.pageName}/#post/#active/`}
                          >
                            {item?.msgUserId?.showName} created a new Organization {item?.pageId?.pageName}

                          </Link>}
                      </>
                      }

                      {item?.notificationCode === "NTFCM001" && (
                        <div onClick={() => props?.onhide()}>
                          <Link
                            to={"/p/" + item?.referencePostId}
                            className="f-500"
                          >
                            {item?.msgUserId?.showName} commented on your post.
                          </Link>
                        </div>
                      )}

                      {item?.notificationCode === "NTFLK001" && (
                        <div onClick={() => props?.onhide()}>
                          <Link to={"/p/" + item?.referencePostId}>
                            {item?.msgUserId?.showName} Liked your post.
                          </Link>
                        </div>
                      )}
                    </div>
                    {item?.notificationCode === "NTFNU001" && !item?.msgUserId ? "" :
                      <div className="time px-2">
                        {moment(item?.createdAt).fromNow()}
                      </div>
                    }
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  console.log("Notifications", state);
  const { isSuccess, isError, isProcessing, notifications } =
    state.notifications;
  return { isSuccess, isError, isProcessing, notifications }
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

