import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom'
import SimpleCircle from '../../components/Loaders/SimpleCircle'
import Tooltip from '@mui/material/Tooltip'
import { FileViewer } from '../../components/AppComponents/Helpers'
import { ImageUploadDialogManagement } from '../../../_helpers/Material'
import { CustomizedSnackbars, Footer, IndexNavbar } from '../../components'
import CreatePost from '../../components/Post/CreatePost'
import { useDispatch } from 'react-redux'
import { orgIsFollowManagement, getReqManagementWithOutDispatch } from '../../redux/reducers/organizationReducer'
import { Button } from 'react-bootstrap'
import { getRequestHandler, postRequestHandler } from '../../redux/reducers/requestHandler.reducer'


export const OrgLike = ({ orgDetails }) => {
    const [isLiked, setIsLiked] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        setIsLiked(orgDetails?.isLiked)
    }, [orgDetails])

    const handleLikeClick = async () => {
        try {
            let req = {
                data: { orgid: orgDetails?._id },
                url: 'orgLike'
            }
            await dispatch(postRequestHandler(req))
            window.location.reload()
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            <Tooltip title="Like" arrow>
                <Button variant="danger" onClick={handleLikeClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
                        <path d="m12 21-1.45-1.3q-2.525-2.275-4.175-3.925T3.75 12.812Q2.775 11.5 2.388 10.4 2 9.3 2 8.15 2 5.8 3.575 4.225 5.15 2.65 7.5 2.65q1.3 0 2.475.55T12 4.75q.85-1 2.025-1.55 1.175-.55 2.475-.55 2.35 0 3.925 1.575Q22 5.8 22 8.15q0 1.15-.387 2.25-.388 1.1-1.363 2.412-.975 1.313-2.625 2.963-1.65 1.65-4.175 3.925Zm0-2.7q2.4-2.15 3.95-3.688 1.55-1.537 2.45-2.674.9-1.138 1.25-2.026.35-.887.35-1.762 0-1.5-1-2.5t-2.5-1q-1.175 0-2.175.662-1 .663-1.375 1.688h-1.9q-.375-1.025-1.375-1.688-1-.662-2.175-.662-1.5 0-2.5 1t-1 2.5q0 .875.35 1.762.35.888 1.25 2.026.9 1.137 2.45 2.674Q9.6 16.15 12 18.3Zm0-6.825Z" />
                    </svg>
                    {isLiked ? 'Liked' :
                        'like'}
                </Button>
            </Tooltip>
        </>
    )
}

export const OrgFollow = ({ orgDetails }) => {
    const dispatch = useDispatch()
    const [isLiked, setIsLiked] = useState(orgDetails?.isFollowing)
    useEffect(() => setIsLiked(orgDetails?.isFollowing), [orgDetails])
    const userId = typeof window !== "undefined" ? localStorage.getItem('token') : ""

    const handleClick = async (e) => {
        try {
            if (e === 1) {
                const result = window.confirm("Are you sure to unfollow this organization")
                if (!result) return
            }
            const params = {
                "follow": {
                    userid: userId,
                    orgid: orgDetails?._id,
                },
                "details": {
                    viewerid: userId,
                    orgid: orgDetails?._id,
                }
            }
            const response = await dispatch(orgIsFollowManagement(params))

            console.log("OrgFollow", response)
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            {isLiked ? <Tooltip title="click to unfollow" arrow>
                <div
                    className="like commentx"
                    onClick={() => handleClick(1)}>
                    <div className="btn btn-danger follow-btn">Unfollow</div>
                </div>
            </Tooltip>
                : <Tooltip title="click to follow" arrow>
                    <div
                        className="like commentx"
                        onClick={() => handleClick(2)}>
                        <div className="btn btn-primary follow-btn">Follow</div>
                    </div>
                </Tooltip>
            }

        </>
    )
}

export const OrgMemberJoin = ({ orgDetails }) => {
    const [isLiked, setIsLiked] = useState(false)
    const dispatch = useDispatch()
    const token = typeof window !== "undefined" ? localStorage.getItem('token') : ""

    useEffect(() => {
        if (!orgDetails?.membars) return setIsLiked(false)
        orgDetails?.followers?.includes(token) ? setIsLiked(true) : setIsLiked(false)
    }, [orgDetails, token])

    const handleClick = async (e) => {
        try {
            if (e === 1) {
                const result = window.confirm("Are you sure to unfollow this organization")
                if (!result) return
            }
            let req = {
                data: {
                    orgid: orgDetails?._id,
                },
                url: 'orgFollow'
            }
            await dispatch(postRequestHandler(req))
            window.location.reload()
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            {isLiked ? <Tooltip title="click to unfollow" arrow>
                <div
                    className="like commentx"
                    onClick={() => handleClick(1)}>
                    <div className="btn btn-danger follow-btn">Unfollow</div>
                </div>
            </Tooltip> : <Tooltip title="click to follow" arrow>
                <div
                    className="like commentx"
                    onClick={() => handleClick(2)}>
                    <div className="btn btn-primary follow-btn">Follow</div>
                </div>
            </Tooltip>
            }

        </>
    )
}

export const OrgGalleryPosts = ({ orgDetails }) => {
    const [gall, setGall] = useState([])
    const dispatch = useDispatch()
    const loc = useLocation()
    const pathUrl = loc?.pathname?.split('/')[3]
    const [page, setPage] = useState(1)
    const token = typeof window !== "undefined" ? localStorage.getItem('token') : ""

    const getPosts = async () => {
        setPage(1)
        const req = {
            params: {
                token: token,
                orgpost: true,
                page: page,
                orgid: orgDetails?._id,
                galltype: pathUrl === 'photos' ? 'photos' : 'video'
            },
            url: 'getPost'
        }
        const response = await dispatch(getRequestHandler(req))
        console.log("fal>>", response)
        setGall(await response.data.responseData)
    }

    useEffect(() => {
        console.log("hala madrid", orgDetails?._id)
        if (!orgDetails?._id) return
        getPosts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgDetails?._id, pathUrl])

    return (<>
        <hr className='my-4' />
        <div className='row mt-4 mb-5'>
            {gall.length < 1 && <h1>No post found</h1>}
            {gall?.map((item, index) => {
                console.log(item)
                return (
                    <Link to={`/p/${item?._id}`} className="col-lg-4 col-md-4 col-6 mpd_1 mt-3 gall_hover" key={index}>
                        <FileViewer link={item?.postInfo[0]?.postImagePath} />
                    </Link>
                )
            })}
        </div>
    </>)
}

export const OrgFollwers = ({ orgDetails }) => {
    const [members, setMembers] = useState([])
    const dispatch = useDispatch()
    const getOrgMember = async () => {
        try {
            let req = {
                params: { orgid: orgDetails?._id },
                url: 'getOrganizationFollowers'
            }
            const response = await dispatch(getRequestHandler(req))
            if (response?.data?.responseData?.length > 0)
                setMembers(response?.data?.responseData[0]?.followers)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        getOrgMember()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            <hr className='my-4' />
            <div className="row">
                {members &&
                    members.map((items, index) => {
                        return (
                            <div className="col-lg-3 col-md-4 col-12 kk mt-3" key={index}>
                                <div className="shadow_1 bg-white">
                                    <div
                                        className="friend_img"
                                        style={{
                                            backgroundImage: `url(${items?.profilePhoto || ''})`,
                                        }}
                                    >

                                        {/* profile photo */}
                                    </div>
                                    <div className="pro_pro_details">
                                        <div className="member_pro_name">{items?.showName}</div>
                                        <a className="view_profile" href={"/" + items?.userName?.toLowerCase()}>
                                            View profile
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
            </div>
        </>
    )
}

export const OrgAbout = (props) => {
    const contextDetails = useOutletContext()
    const token = typeof window !== "undefined" ? localStorage.getItem('token') : ""
    const currentUser = typeof window !== "undefined" ? JSON.parse(localStorage.getItem("currentuser")) : ""

    return (<>
        <div>
            <div className="row">
                <div className="col-lg-6 pt-3">
                    <div className="shadow_1 sticky_div bg-white">
                        <div className="about_details">
                            <div className="d-flex justify-content-between">
                                <h4 className="text-center  w-100">
                                    About {contextDetails[2]?.pageName}
                                </h4>

                            </div>
                        </div>
                        <pre className="py-2 px-4 about_info">
                            {contextDetails[2]?.details}
                        </pre>
                    </div>
                    {/* <ProfileGallary paramid={param.id} />
                                    <ProfileGallary paramid={param.id} /> */}
                </div>
                {/* view post section */}

                <div className="col-lg-6 post-area-section mb-5">
                    {contextDetails[2]?.userid?.id === token ? (
                        <CreatePost
                            userinfo={currentUser}
                            spinner={false}
                            pageid={contextDetails[2]?._id}
                        />
                    ) : (
                        ""
                    )}
                </div>

            </div>

        </div>
    </>)
}

function CreateOrganisation() {
    const [values, setValues] = useState({})
    const navigate = useNavigate()
    const token = typeof window !== "undefined" ? localStorage.getItem('token') : ""
    const [isFileUplaoded, setIsFileUplaoded] = useState(false)
    const [isProfileUpload, setIsProfileUpload] = useState(false)
    const [isCoverUpload, setIsCoverUpload] = useState(false)
    const [profileFileLink, setProfileFileLink] = useState("")
    const [coverFileLink, setCoverFileLink] = useState("")
    const [isBtnDisabled, setIsBtnDisabled] = useState(true)
    const [isLoader, setIsLoader] = useState(false)
    const [dataFor, setDataFor] = useState("")
    const [errMsg, setErrMsg] = useState("")
    const [inputfocus, setInputfocus] = useState({});
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState({ pageName: false })
    const dispatch = useDispatch()
    const uploadChanges = field => {
        setIsFileUplaoded(true)
        setDataFor(field)
    }

    const handleOnBlurPageName = async e => {
        if (!values?.pageName) return setErrors(prev => ({ ...prev, pageName: 'Required' }))
        let params = {
            pageName: values.pageName
        }
        setLoading(prev => ({ ...prev, pageName: true }))
        const response = await getReqManagementWithOutDispatch('orgSearch', params)
        setErrors(prev => ({ ...prev, pageName: response?.data?.ack === 1 ? 'Already exist' : '' }))
        console.log("handleOnBlurPageName", response)
        setInputfocus((inputfocus) => ({
            ...inputfocus,
            [e.target.name]: false,
        }))
        setLoading(prev => ({ ...prev, pageName: false }))

    }

    const handleChange = e => {
        if (e.target.name === "pageName") {
            const orgDetails = e.target.value.toLowerCase()
            // eslint-disable-next-line no-useless-escape
            const l = orgDetails.replace(/[`~!@#$%^&*()_|+\-=?:'",.<>\{\}\[\]\\\/]/gi, ".")
            const t = l.replace(" ", ".")
            return setValues(prev => ({ ...prev, [e.target.name]: t }))
        }
        setValues(prev => ({ ...prev, [e.target.name]: e.target.value }))
    }

    const handleSubmit = async () => {
        try {
            setIsLoader(true)
            setErrMsg("")
            const data = Object.assign(values, {
                userid: token,
                BackgroundImage: coverFileLink,
                organiztionLogo: profileFileLink,
            })
            let req = {
                data: data,
                url: 'createOrg'
            }
            const response = await dispatch(postRequestHandler(req))
            setIsLoader(false)
            if (response?.data?.ack === 0) return setErrMsg(response?.data?.message)
            navigate(`/org/${response?.data?.responseData?.pageName}/#post/#active/`)
            console.log(data, response)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        console.log("dfsd")
        if (!token) return navigate("/auth/login?redirectTo=/page/demo")
    }, [navigate, token])

    useEffect(() => {
        // console.log(values?.pageName , token , values?.pageCategory)
        setErrors(prev => ({ ...prev, pageCategory: !values?.pageCategory ? 'Required' : "" }))
        setIsBtnDisabled(values?.pageName && token && values?.pageCategory ? false : true)
    }, [token, values?.pageName, values?.pageCategory])
    return (
        <>
            <IndexNavbar />
            <div className='create_page_main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6 col-sm-12 create_left'>
                            <img className='create_logo img-responsive' src={"/assets/web_img/main_logo2.png"} alt="logo" />
                            <h2>Welcome to the
                                <span>creation page for organization</span></h2>
                            <p className='mt-2'>
                                This is a wonderful way to promote your organization
                                and keep your creations in one place for access all
                                over the world !! You can create a page for your organization.
                                Here you will be able to talk about what your organization
                                does and post contents about your organization.
                                Please follow all terms and policies for Allphanes when posting.
                                You as the creator of this page will be able to grant access to members who request access to the page. Anyone can see the page, but only you as admin can post and update content. Your members may also do so, if you give them permission. Other people can Like or Follow your organization page.</p>
                        </div>
                        <div className='col-lg-6 col-sm-12 create_right'>
                            <div className='form-group'>
                                <input
                                    className='form-control'
                                    type="text"
                                    name="showName"
                                    onChange={handleChange}
                                    value={values.showName || ''}
                                    placeholder='Type your organisation name' />
                            </div>
                            <div className="mb-3 fs_choto cl_gray info">
                                Help people discover your organization by using the name you want.
                            </div>
                            <div className='form-group'>
                                <input
                                    className='form-control'
                                    type="text"
                                    name="pageName"
                                    onChange={handleChange}
                                    value={values.pageName || ''}
                                    placeholder='Type your organization slug url '
                                    onFocus={e => setInputfocus((inputfocus) => ({ ...inputfocus, [e.target.name]: true }))}
                                    onBlur={handleOnBlurPageName}
                                    required
                                />
                            </div>


                            <div >
                                {loading?.pageName ?
                                    <div className='d-flex gap-2 fs-14 text-primary'><SimpleCircle /> Checking availablity...</div>
                                    : !inputfocus.pageName && errors.pageName ? <div className="errors mb-2">{!inputfocus.pageName && errors.pageName}</div> : values?.pageName && !inputfocus.pageName && <div className='text-success fs-14 mb-2'>Available</div>}

                            </div>
                            <div className="mb-3 fs_choto cl_gray info">
                                Help people discover your organization by using the name you want. <br />
                                ex: https://allphanes.com/org/<span className='text-primary'>org-name</span>/. eg. for abc org it will be 'abc' <br />
                                note: url cannot contain uppercase letter, space or any special charecter except "dot".
                            </div>
                            <select className="form-select mb-2 rounded-pill" name="monthlySubscriptionPrice" onChange={handleChange} value={values?.monthlySubscriptionPrice || ""} required>
                                <option className="" value="">
                                    Select Organization Monthly plan
                                </option>

                                <option className="" value={40}>
                                    40
                                </option>
                                <option className="" value={50}>
                                    50
                                </option>
                                <option className="" value={75}>
                                    75
                                </option>
                                <option className="" value={100}>
                                    100
                                </option>
                                <option className="" value={200}>
                                    200
                                </option>
                                <option className="" value={300}>
                                    300
                                </option>
                                <option className="" value={400}>
                                    400
                                </option>
                                <option className="" value={500}>
                                    500
                                </option>
                            </select>
                            <div className="mb-3 fs_choto cl_gray info">
                                Help people discover your private posts by subscribing your organization. This amount will be charged from the members who wants to subscribe your organization. <br />
                            </div>
                            <div className='form-group'>
                                <div className='row'>
                                    <div className='col-sm-3'>
                                        <div className='create_upload_img' style={{ backgroundImage: `url(${profileFileLink || ''})`, }}>
                                        </div>
                                    </div>
                                    {isProfileUpload ? "" : <div className='col-sm-8'>
                                        <div className='upload_profile'>
                                            <div className='upload_btns c_pointer' onClick={() => uploadChanges('profile')}>
                                                <img className='img-responsive' src={"/assets/web_img/camera_icon.png"} alt="logo" /> Upload org profile photo</div>
                                        </div>
                                    </div>}
                                </div>
                                <div className='form-group upload_cover_pic'>
                                    <div className='upload_cover_pic' style={{ backgroundImage: `url(${coverFileLink || ''})`, }}></div>
                                    {isCoverUpload ? "" : <div className='upload_profile'>
                                        <div className='upload_btns c_pointer' onClick={() => uploadChanges('cover')}>
                                            <img className='img-responsive' src={"/assets/web_img/camera_icon.png"} alt="logo" /> Upload org cover photo</div>
                                        {/*<input type="file"></input>*/}
                                    </div>}
                                </div>
                                <br />
                                <br />
                                <h1>Describe the objective of your institution</h1>
                                <select
                                    className='form-control'
                                    name="pageCategory"
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">Select Category</option>
                                    <option value="music">Music</option>
                                    <option value="drama">Drama</option>
                                    <option value="dance">Dance</option>
                                    <option value="painting">Painting</option>
                                    <option value="ngo">NGO</option>
                                    <option value="other">Other</option>
                                </select>
                                <div className="errors mb-2">{errors?.pageCategory}</div>

                                <textarea
                                    className="form-control"
                                    rows="3"
                                    placeholder="Please type about your institution"
                                    name="details"
                                    onChange={handleChange}
                                    value={values.details || ""}
                                ></textarea>

                                <div className='create_page_button'>
                                    {isBtnDisabled ?
                                        <button className='btn cmn_btn' disabled>Create Page</button> :
                                        isLoader ? <button className='btn cmn_btn'><SimpleCircle /></button> : <button className='btn cmn_btn' onClick={handleSubmit}>Create Page</button>}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {isFileUplaoded && dataFor === "profile" && <ImageUploadDialogManagement
                    title={"Upload organisation profile photo"}
                    isImageCropper={true}
                    setFileLink={setProfileFileLink}
                    setIsUploaded={() => setIsProfileUpload(true)}
                    close={() => setIsFileUplaoded(false)}
                />}

                {isFileUplaoded && dataFor === "cover" && <ImageUploadDialogManagement
                    title={"Upload organisation cover photo"}
                    isImageCropper={false}
                    setFileLink={setCoverFileLink}
                    setIsUploaded={() => setIsCoverUpload(true)}
                    close={() => setIsFileUplaoded(false)}
                />}


            </div>
            {errMsg && <CustomizedSnackbars type={1} msg={errMsg} />}

            <Footer />
        </>
    )
}

export default CreateOrganisation