import React from 'react'
import { Footer, IndexNavbar } from '../../components'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { connect } from 'react-redux'
import { getRequestHandler } from '../../redux/reducers/requestHandler.reducer'

const PPVSinglePageAnalytics = (props) => {
    const { ppvAnalyticsPage } = props
    return (
        <div>
            <IndexNavbar />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 my-5">
                        <h2>Premium PPV Post Analytics</h2>
                        <table className="table">
                            <thead className="border bg-info border text-center">
                                <tr>
                                    <th>Subscribed User</th>
                                    <th>Price</th>
                                    <th>Status</th>
                                    <th>Subscribed At</th>
                                </tr>
                            </thead>
                            <tbody className="text-center  border">
                                {ppvAnalyticsPage?.subDetails?.length > 0 && ppvAnalyticsPage?.subDetails?.map((item, index) => {
                                    return (
                                        <>
                                            <tr>
                                                <td><Link to={`/${item?.userId?.userName}`}>{item?.userId?.showName}</Link></td>
                                                <td>{item?.price}</td>
                                                <td>{item?.status}</td>
                                                <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>
                                            </tr>
                                        </>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

PPVSinglePageAnalytics.loadData = (params, search) => async (dispatch) => {
    const kk = params.split('/')[3]
    console.log("PPVSinglePageAnalytics", params, kk)

    const paramsx = {
        postId: kk,
    }



    let req = {
        params: paramsx,
        state: "ppvAnalyticsPage",
        action: "ACTION_GET_DATA_BY_STATE",
        url: "ppvanalytics",
    }



    return await dispatch(getRequestHandler(req))
}
const mapStateToProps = (state) => {
    console.log("stateoRgpostcreationposts", state)
    const { ppvAnalyticsPage } =
        state.premium
    return { ppvAnalyticsPage }
}
const mapDispatchToProps = (dispatch) => {
    return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(PPVSinglePageAnalytics)