import moment from 'moment'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import EditPost from '../../components/Post/EditPost'
import { DeleteAction, PostBlogShow } from '../../components/Postsk'
import { useDispatch } from 'react-redux'
import { deleteRequestHandler, getRequestHandler, postRequestHandler, putRequestHandler } from '../../redux/reducers/requestHandler.reducer'
import { SharePostAsFeed } from '../../components/Post/PostComponents'
import { CopyLink } from '../../components/AppComponents/Helpers'
import Tooltip from "@mui/material/Tooltip"
import { SimpleCircle } from '../../components'
import { CheckExtention } from '../../../_helpers/Variables'
import { YouTubeEmbed } from 'react-social-media-embed'

export const ShareProfileInfo = props => {
  const { post } = props
  return (
    <>
      <div className="my_space_img_hover2 rounded-3">
        <div class="post-creator d-flex justify-content-between align-items-center border-bottom-0">
          <Link class="posted-user" to={`/${post?.shereInfo?.length > 0 ? post?.shereInfo[0]?.userName : post?.shareUserName}/`}>
            <div class="posted-user-d">
              <div class="post-user-name text-right me-1">
                {post?.shareFullName}
                <div class="fs_12">
                  {post?.org_info?.length > 0 && <div className="post_marks ms-1 me-1 rounded-4">org</div>}
                  <div className="post_marks ms-1 me-1 rounded-4">shared</div>
                </div>
              </div>
              <div class="pro_img">
                <img
                  src={post?.shareProfile || "/assets/web_img/choto_logo_1.png"}
                  alt="allphanes"
                />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  )
}
export const PostLike = ({ id, isLike, getlikes, isClick }) => {
  const [isLiked, setIsLiked] = useState(isLike)
  const [loader, setLoader] = useState(false)
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const dispatch = useDispatch()

  const handleLikeClick = async () => {
    try {
      setLoader(true)
      let req = {
        data: {
          referenceUserId: token,
          referencePostId: id,
        },
        url: "postLike",
      }
      const response = await dispatch(postRequestHandler(req))
      if (response?.data?.ack === 0) return console.error("like error")
      setIsLiked(response?.data?.responseData?.isLike)
      getlikes()
      setLoader(false)
    } catch (error) {
      console.error(error)
    }
  }
  
  return (
    <>
      {isClick ?
        <Tooltip
          title="Like"
          className='c_pointer'
          onClick={() => handleLikeClick()}
          arrow
        >

          <div className="float-start">
            {isLiked ? <span className="material-symbols-outlined text-danger me-1">
              thumb_up
            </span> :
              <span className="material-symbols-outlined text-success me-1">
                thumb_up
              </span>}
          </div>

        </Tooltip> : loader ? <SimpleCircle /> :
          <Tooltip
            title="Like"
            arrow
          >

            <div className="float-start">
              <span className="material-symbols-outlined text-success me-1">
                thumb_up
              </span>
            </div>

          </Tooltip>
      }
    </>

  )
}
const PostCard = (props) => {
  const { post, getPost } = props
  const cuid = typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const dispatch = useDispatch()
  const [totalLikesCount, setTotalLikesCount] = useState(post?.totalLikes || 0)

  const incrementPostViews = async (post) => {
    try {
      let req = {
        data: {
          pid: post?._id,
        },
        url: "updateView",
      }
      // if (post.referenceUserId !== userId) return
      await dispatch(putRequestHandler(req))
    } catch (err) {
      console.error(err)
    }
  }

  const deletePost = async (postId) => {
    let req = {
      params: {
        pid: postId,
      },
      url: "deletePost",
    }
    await dispatch(deleteRequestHandler(req));
    await getPost({ page: 1 })
  }

  const getTotalLikes = async () => {
    try {
      let req = {
        params: {
          referencePostId: post?._id,
        },
        url: "totalLikes",
      }
      const response = await dispatch(getRequestHandler(req))
      if (response.data.ack === 0) return
      setTotalLikesCount(response?.data?.responseData)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-12"
        key={post?._id}
        onClick={() => incrementPostViews(post)}

      >
        <div className="bg-white my_space_col p-3 pb-2 mb-4 pt-1">
          <div className="post-creator d-flex justify-content-between align-items-center">
            <Link className="posted-user"
              to={post?.org_info?.length > 0 ?
                /pre/.test(post?.org_info[0]?.mode) ?
                  `/premium/${post?.org_info[0]?.pageName}/#freepost/`
                  : `/org/${post?.org_info[0]?.pageName?.toLowerCase()}/#post/#active/`
                : `/${post?.user_info[0]?.userName.toLowerCase()}/`
              }

            >
              <div className="posted-user-d">
                <div className="pro_img">
                  <img
                    src={
                      post?.org_info?.length > 0 ? post?.org_info[0]?.organiztionLogo || "/assets/web_img/choto_logo_1.png" : post?.user_info[0]?.profilePhoto || "/assets/web_img/choto_logo_1.png"
                    }
                    alt="pro"
                  />
                </div>
                <div className="post-user-name">
                  {post?.org_info?.length > 0 ? post?.org_info[0]?.showName || post?.org_info[0]?.pageName : post?.user_info[0]?.showName || post?.user_info[0]?.userName}
                  <div className="fs_12">{moment(post?.createdAt).fromNow(true)}</div>
                </div>
              </div>
            </Link>
            {post?.org_info?.length > 0 && <div className="post_marks">org</div>}
          </div>
          <div className="my_space_img-col">
            <div className="my_space_img_hover p-1 rounded-4">
              <Link
                to={`/p/${post?._id}/`}
                className="rounded-circle text-center mt-1 mb-1"
              >
                <span title='View' className="material-symbols-outlined text-white">
                  visibility
                </span>
              </Link>
              {post?.referenceUserId === cuid && <>
                <EditPost
                  post={post}
                  component={<div className="rounded-circle text-center mt-1 mb-1 div_link">
                    <span title='Edit' className="material-symbols-outlined material-symbols-outlined text-white">
                      edit_note
                    </span>
                  </div>}
                />

                <DeleteAction
                  component={<div className="rounded-circle text-center mt-1 mb-1 div_link">
                    <span title='Delete' className="material-symbols-outlined material-symbols-outlined text-white">
                      delete
                    </span>
                  </div>}
                  execute={deletePost}
                  params={post?._id}
                  alertTitle={"Delete Post"}
                  alertMsg={
                    "Want to delete this post. Please click agree to confirm it."
                  }
                />

              </>
              }
              {post?.user_info?.length > 0 &&
                cuid !== post?.referenceUserId &&
                <SharePostAsFeed
                  post={post}
                  getpost={getPost}
                  component={<div className="rounded-circle text-center mt-1 mb-1 div_link">
                    <span className="material-symbols-outlined text-white">
                      share
                    </span>
                  </div>}
                />
              }
              <CopyLink
                link={`/p/${post?._id}?redirect=1`}
                component={<div className="rounded-circle text-center mt-1 mb-1 div_link">
                  <span className="material-symbols-outlined text-white">
                    content_copy
                  </span>
                </div>}
              />

              {/* {post?.isFav && post?.isFav.length > 0 && (
                <SavePost
                  id={post?._id}
                  isMarked={post?.isFav[0]?.mark}
                  component={<div className="rounded-circle text-center mt-1 mb-1 div_link" >
                    <span className="material-symbols-outlined text-white">
                      favorite
                    </span>
                  </div>}
                />
              )} */}

              {/* <div className="rounded-circle text-center mt-1 mb-1 div_link" >
                <span className="material-symbols-outlined text-white">
                  favorite
                </span>
              </div> */}



            </div>
            {post?.postInfo?.length > 0 && CheckExtention(post.postInfo[0]?.postImagePath) === 0 &&
              <Link
                to={`/p/${post?._id}/`}
                className="my-space_img_box" style={{
                  backgroundImage: `url(${post?.postInfo[0]?.postImagePath}`
                }}
              >

              </Link>
            }

            {post?.postInfo?.length > 0 && CheckExtention(post?.postInfo[0]?.postImagePath) !== 0 &&

              <Link
                to={`/p/${post?._id}/`}
                className="my-space_img_box my-space_vieos_box" style={{
                  backgroundImage: `url(${post?.videoThumbnil || "/assets/web_img/video_default.jpeg"}`
                }}
              >
                <div className="videoplay_hover">
                  <span class="material-symbols-outlined">
                    play_circle
                  </span>
                </div>
              </Link>
            }

            {post?.shareGalleryInfo && CheckExtention(post?.shareGalleryInfo) === 0 &&
              <Link
                to={`/p/${post?._id}/`}
                className="my-space_img_box" style={{
                  backgroundImage: `url(${post?.shareGalleryInfo}`
                }}
              >
                <ShareProfileInfo
                  post={post}
                />
              </Link>
            }

            {post?.shareGalleryInfo && CheckExtention(post?.shareGalleryInfo) !== 0 &&
              <Link
                to={`/p/${post?._id}/`}
                className="my-space_img_box my-space_vieos_box" style={{
                  backgroundImage: `url(${post?.videoThubnail || "/assets/web_img/video_default.jpeg"}`
                }}
              >
                <ShareProfileInfo
                  post={post}
                />
                <div className="videoplay_hover">
                  <span class="material-symbols-outlined">
                    play_circle
                  </span>
                </div>
              </Link>
            }

            {/Normal|pre/.test(post?.postType) && post?.url && (
              <Link
                to={`/p/${post?._id}/`}
                className='pos_r you_h'
              >
                <div className="videoplay_hoverx">
                  <span class="material-symbols-outlined">
                    play_circle
                  </span>
                </div>
                <YouTubeEmbed url={`${post?.url}`} width={"100%"} height={250} />
              </Link>
            )}

            {post?.postInfo?.length === 0 && !post?.shareGalleryInfo && !post?.url && (post?.postDescription || post?.shareDescription) &&

              <Link
                to={`/p/${post?._id}/`}
                className="my-space_img_box my-space_vieos_box text-dark"

              >
                {/shared/.test(post?.postType) &&
                  <ShareProfileInfo
                    post={post}
                  />
                }
                <pre>
                  {/* <ReadMore className="view-post-des mt-2"> */}
                  {/Blogs/.test(post?.postCategory) ?
                    <>
                      <PostBlogShow content={post?.postDescription} />
                    </>
                    : post?.shareDescription || post?.postDescription}
                  {/* </ReadMore> */}
                </pre>
              </Link>


            }

          </div>
          <div className="d-flex mt-2">
            <div className="flex-grow-1">
              <div className="text-dark text-decoration-none">
                <PostLike
                  id={post?._id}
                  isLike={post?.isLiked[0]?.isLike}
                  getlikes={() => getTotalLikes()}
                  isClick={cuid === post?.referenceUserId ? false : true}
                />
                Likes ({totalLikesCount})
              </div>
            </div>
            <div className="">
              <Link
                to={`/p/${post?._id}/`}
                className="text-dark text-decoration-none">
                <div className="float-start">
                  <span className="material-symbols-outlined text-success me-1">
                    comment
                  </span>
                </div> Comments ({post?.totalComment || 0})
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PostCard 