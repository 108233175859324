import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from "../../constant"
import Table from 'react-bootstrap/Table'
import Accordion from 'react-bootstrap/Accordion';
import moment from 'moment'
import ManagementLeftNav from '../../components/Navbars/ManagementLeftNav';
// import { useNavigate } from 'react-router-dom';
// api urls
const refUrl = config.url.API_URL + "admin/referral?mm=1&"

function AdminReferralManagement() {
    const [refValues, setRefValues] = useState([])
    const [monthY, setMonthY] = useState(moment().format('YYYY-MM'))

    const getRefDetails = async () => {
        const response = await axios.get(refUrl + `gm=${monthY}`)
        console.log(response)
        setRefValues(response.data.responseData)
    }

    const handleChange = async e => {
        console.log(e.target.value)
        setMonthY(e.target.value)
    }

    // const navigate = useNavigate()

    // useEffect(() => {
    //     const user = typeof window !== "undefined"
    //             ? JSON.parse(localStorage.getItem("currentUser"))
    //             : "";

    //     if(user?.userRole !== '1010') navigate('/admin/')
    // }, [])

    useEffect(() => {
        getRefDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthY])
    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-lg-2">
                        <ManagementLeftNav />
                    </div>
                    <div className="col-lg-10 bg_allp" style={{ minHeight: "100vh" }}>
                        <h4 className='mt-3 mb-4'>Referral Management</h4>

                        <input type="month" name="month"
                            min="2022-01" max={moment().format('YYYY-MM')} value={monthY} onChange={handleChange} />

                        <Accordion defaultActiveKey="0" id="refAcc">
                            <Accordion.Item eventKey={0} key={0}>
                                <Accordion.Header >
                                    <div className='d-flex justify-content-between w-97 fw-500'>
                                        <div className="minw_no">No</div>

                                        <div className="w-100">Name</div>
                                        <div className="minw_t">Total</div>
                                    </div>

                                </Accordion.Header>

                            </Accordion.Item>

                            {refValues?.map((refItem, refIndex) => {
                                return (
                                    <Accordion.Item eventKey={refIndex + 1} key={refIndex + 1}>
                                        <Accordion.Header >


                                            <div className='d-flex justify-content-between w-97'>
                                                <div className="minw_no">{refIndex + 1}</div>

                                                <div className="w-100">{refItem?.list[0]?.refUserInfo[0]?.userName}</div>
                                                <div className="minw_t">{refItem?.count}</div>
                                            </div>

                                        </Accordion.Header>
                                        <Accordion.Body >
                                            <Table striped bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Code</th>
                                                        <th>Created At</th>
                                                    </tr>
                                                </thead>
                                                <tbody key={"jj"}>
                                                    {
                                                        refItem?.list?.map((items, index) => {
                                                            return (
                                                                <>
                                                                    <tr key={refIndex}>
                                                                        <td>{items?.user_info[0]?.userName}</td>
                                                                        <td>{items?.refCode}</td>
                                                                        <td>{moment(items?.createdAt).format("YYYY-MM-DD")}</td>
                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </Table>

                                        </Accordion.Body>
                                    </Accordion.Item>

                                )
                            })}

                        </Accordion>
                    </div>
                </div>
            </div>
        </>

    )
}

export default AdminReferralManagement