export const Testmonials = [
    {
        name: "Rana Biswas",
        photo: "https://allphanes-bucket.s3.amazonaws.com/2022-09-11T12%3A50%3A53.603Z-2aa3486-d35-dbac-b31a-ddbd4bf72fa1-1662900652612.jpg",
        designation: "",
        review: "Allphones is very good platform for them who want bring his or her self to world with his or her cultural activities"
    },
    {
        name: "Pannalal Bandyopadhyay",
        photo: "https://lh3.googleusercontent.com/a/AGNmyxZr4TVnL24HWpLuIKGc-NkR11XUtm2PeUB3X5IgEg=s96-c",
        designation: "",
        review: "Keeping in mind my association and participation in allphanes it is most purposeful and effective for me. With newer members entry and enlarge member base, it will be a real gem of social media in the future, I hope."
    },
    {
        name: "Priyanka Niyogi",
        photo: "https://lh3.googleusercontent.com/a/AGNmyxYCQwh1kx0W4p_q9nUrbxuD5e6lDrzw7fQTXhIw=s96-c",
        designation: "",
        review: "Allphanes, best of our wishes to You! To showcase every talent however new, Following every creativity in its full blossom, To appreciate all talents great and awesome!"
    },
    {
        name: "Bhaskar Roy",
        photo: "https://lh3.googleusercontent.com/a/AEdFTp45gS_i8VhV6Lsqm3fiHKV3BBkquZykJyDIH3vKew=s96-c",
        designation: "Singer",
        review: "Allphanes is the one of the best platform today  for the artists and musicians and it's very nice and friendly. This platform help me to send my musical work to every part of world ."
    },
    {
        name: "Tapas Chakraborty",
        photo: "https://allphanes-bucket.s3.ap-south-1.amazonaws.com/2023-03-04T17%3A13%3A22.886Z-15110ca-1d-d245-ebd4-3dc5f11ffd1b-1677949996480.jpg",
        designation: "Photographer",
        review: "Allphanes is a well organised global platform to share inherent creative talents for mutual benefits."
    },
    {
        name: "Bhaswati Dutta",
        photo: "",
        designation: "Singer",
        review: "Its a unique idea"
    },
    {
        name: "Debarati Chakraborty",
        photo: "/assets/web_img/deba.jpg",
        designation: "Painter",
        review: "Allphanes is well organised site where I can post my painting.I am very happy to connect with allphanes."
    },
    {
        name: "Apurwa",
        photo: "/assets/web_img/apurwa.jpg",
        designation: "House Wife",
        review: "It is global platform where I can post  nicely. Site is working well."
    },
    {
        name: "Dhriti",
        photo: "/assets/web_img/dhriti.jpg",
        designation: "Student",
        review: "I will have a  great time by sharing my creation with all. I am looking forward to connecting through creativity."
    },
    {
        name: "Gautam Dey",
        photo: "/assets/web_img/gautam_dey.png",
        designation: "Singer",
        review: "Allphanes is well organised platform. I am very happy  for posting my creation in this platform."
    },
    {
        name: "Gk Pal",
        photo: "/assets/web_img/gk_pal.jpg",
        designation: "Writer",
        review: "Allphanes is a good platform. Thanks allphanes for giving me such a good opportunity."
    },
    {
        name: "Tapu",
        photo: "/assets/web_img/taposhi.jpg",
        designation: "Singer",
        review: "Allphanes is a nice, well organized site where I can post all my performances."
    },
    {
        name: "Rita Sardar",
        photo: "/assets/web_img/rita.jpg",
        designation: "Housewife",
        review: "Allphanes enabled me to show my son's art to a global platform where he won a monthly contest !!"
    },
    {
        name: "Suman",
        photo: "/assets/web_img/suman.jpg",
        designation: "Photographer",
        review: "This site enables me to store all my creative work in one place so that others can see it…"
    },
]