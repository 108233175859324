import api from "../../config/api"
import Network, { setToken } from "../../helpers/network"
export const ACTION_SUBMIT_LOGIN_SUCCESS = "ACTION_SUBMIT_LOGIN_SUCCESS"
export const ACTION_LOGIN_FAILURE = "ACTION_LOGIN_FAILURE"
export const ACTION_LOGIN_REQUEST = "ACTION_LOGIN_REQUEST"

export const ACTION_SUBMIT_FORGOTPASSWORD_SUCCESS =
  "ACTION_SUBMIT_FORGOTPASSWORD_SUCCESS"
export const ACTION_FORGOTPASSWORD_FAILURE = "ACTION_FORGOTPASSWORD_FAILURE"
export const ACTION_FORGOTPASSWORD_REQUEST = "ACTION_FORGOTPASSWORD_REQUEST"
export const ACTION_GET_STORAGE = "ACTION_GET_STORAGE"
export const ACTION_GET_STORAGE_SUBSCRIPTIONS = "ACTION_GET_STORAGE_SUBSCRIPTIONS"
export const ACTION_EDIT_USER = "ACTION_EDIT_USER"
export const ACTION_AUTH_USER = "ACTION_AUTH_USER"
export const ACTION_UPDATE_AUTH_USER = "ACTION_UPDATE_AUTH_USER"


export const RESET_STORE = "RESET_STORE"
export const ERROR_SET = "ERROR_SET"

const initialState = {
  auth: null,
  editUser: {},
  storagePlans: [],
  storageSubscriptions: [],
  storegePosts: [],
  storage: null,
  user: null,
  isLogin: false,
  isSubmitted: false,
  isProcessing: null,
  isAuthenticated: false,
  isError: false,
  message: "",
}
export const AuthReducer = (state = initialState, action) => {
  const returnObj = { ...state }
  switch (action.type) {
    case ACTION_EDIT_USER:
      return {
        ...state,
        editUser: action?.payload
      }
    case ACTION_GET_STORAGE_SUBSCRIPTIONS:
      return {
        ...state,
        storagePlans: action.payload,
      }
    case ACTION_SUBMIT_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLogin: true,
        isSubmitted: false,
        isProcessing: false,
        isAuthenticated: true,
      }
    case ACTION_AUTH_USER:
      return {
        ...state,
        auth: action.payload,
        isLogin: true,
        isSubmitted: true,
        isProcessing: false,
        isAuthenticated: true,
      }

    case ACTION_GET_STORAGE:
      return {
        ...state,
        storage: action?.payload?.storage,
        storageSubscriptions: action?.payload?.subscriptions,
        storegePosts: action?.payload?.posts
      }

    case ACTION_UPDATE_AUTH_USER:
      returnObj.user.user = action?.payload
      return returnObj


    case ACTION_LOGIN_FAILURE:
      return {
        ...state,
        user: {},
        isLogin: false,
        isProcessing: false,
        isAuthenticated: false,
      }
    case ACTION_LOGIN_REQUEST:
      return { ...state, isSubmitted: true, isProcessing: true }
    case ACTION_FORGOTPASSWORD_REQUEST:
      return {
        ...state,
        isLogin: false,
        isSubmitted: true,
        isProcessing: true,
      }
    case ACTION_SUBMIT_FORGOTPASSWORD_SUCCESS:
      return { ...state, isSubmitted: false, isProcessing: false }
    case ACTION_FORGOTPASSWORD_FAILURE:
      return { ...state, isProcessing: false }
    case ERROR_SET:
      return {
        ...state,
        isLogin: false,
        isError: true,
        message: action.payload.message,
      }
    default:
      return state
  }
}

export const putReqManagement = (url, params, data, options) => dispatch => {
  return new Promise((resolve, reject) => {
    try {
      Network.put(api[url], params)
        .then(res => resolve(res))
        .catch(err => reject(err))
    } catch (err) {
      console.log("error", err)
      dispatch({ type: ACTION_LOGIN_FAILURE })
      return reject(err)
    }
  })
}
export const loginApiCall = params => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch({ type: ACTION_LOGIN_REQUEST })
      Network.post(api.login, params)
        .then((res) => {
          console.log("loginx res>>>", res)
          dispatch({
            type: ACTION_SUBMIT_LOGIN_SUCCESS,
            payload: res?.data?.responseData?.user
          })
          setToken(res?.data?.responseData?.token)
          return resolve(res)
        })
        .catch((err) => {
          dispatch({ type: ACTION_LOGIN_FAILURE })
          return reject(err)
        })
    } catch (err) {
      console.log("error", err)
      dispatch({ type: ACTION_LOGIN_FAILURE })
      return reject(err)
    }
  })
}

export const getStorageDetails = params => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch({ type: ACTION_LOGIN_REQUEST })
      Network.get(api.userStorage, params)
        .then((res) => {
          dispatch({
            type: ACTION_GET_STORAGE,
            payload: res?.data?.responseData
          })
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    } catch (err) {
      console.log("error", err)
      // dispatch({ type: ACTION_LOGIN_FAILURE })
      return reject(err)
    }
  })
}

export const getUsers = params => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      Network.get(api.getUsers, params)
        .then((res) => {
          dispatch({
            type: ACTION_EDIT_USER,
            payload: res?.data?.responseData[0]
          })
          return resolve(res)
        })
        .catch((err) => {
          return reject(err)
        })
    } catch (err) {
      console.log("error", err)
      // dispatch({ type: ACTION_LOGIN_FAILURE })
      return reject(err)
    }
  })
}
export const forgotPassword = (email) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch({ type: ACTION_FORGOTPASSWORD_REQUEST })
      Network.post(api.forgotPassword, { email })
        .then((res) => {
          dispatch({
            type: ACTION_SUBMIT_FORGOTPASSWORD_SUCCESS,
            payload: res?.data,
          })
          resolve({
            status: true,
            message: res?.data?.message,
            redirect: res?.data?.redirect,
          })
        })
        .catch((err) => {
          console.log("error", err)
          dispatch({ type: ACTION_FORGOTPASSWORD_FAILURE })
          reject(err)
        })
    } catch (err) {
      console.log("error", err)
      dispatch({ type: ACTION_FORGOTPASSWORD_FAILURE })
      reject(err)
    }
  })
}
export const resetPassword = (password, token) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      dispatch({ type: ACTION_FORGOTPASSWORD_REQUEST })
      Network.post(
        api.resetPassword,
        { password },
        {
          headers: {
            "x-access-token": `${token}`,
          },
        }
      )
        .then((res) => {
          dispatch({
            type: ACTION_SUBMIT_FORGOTPASSWORD_SUCCESS,
            payload: res?.data,
          })
          resolve({
            status: true,
            message: res?.data?.message,
            redirect: res?.data?.redirect,
          })
        })
        .catch((err) => {
          dispatch({ type: ACTION_FORGOTPASSWORD_FAILURE })
          reject(err)
        })
    } catch (err) {
      console.log("error", err)
      dispatch({ type: ACTION_FORGOTPASSWORD_FAILURE })
      reject(err)
    }
  })
}
// export const errorHandler = (error) => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     switch (error.response?.status) {
//       case 401:
//         alert("session expired! Please login again")

//         setTimeout(() => {
//           return resolve(dispatch(logout()))
//         }, 100)
//       default:
//     }
//   })
// }

export const logout = () => (dispatch) => {
  sessionStorage.removeItem("token")
  localStorage.clear()
  return dispatch({
    type: RESET_STORE,
  })
}
