import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SimpleCircle } from "./../../../new/components";

export default function LoginForm({
  handleChange,
  handleSubmit,
  values,
  errors,
  redirectTo,
  isSubmit,
  navigation,
}) {
  const [inputfocus, setInputfocus] = useState({});

  return (
    <form className="items-center" onSubmit={handleSubmit}>
      <div className="phone-tag mb-2">
        {/* email  */}
        <div className="login-form mt-4">
          <label htmlFor="email">Email address :</label>
          <input
            type="email"
            className="form-control form-control-xl login mt-1"
            autoComplete="off"
            placeholder="Enter email address here"
            name="email"
            onFocus={(e) =>
              setInputfocus((inputfocus) => ({
                ...inputfocus,
                [e.target.name]: true,
              }))
            }
            onBlur={(e) =>
              setInputfocus((inputfocus) => ({
                ...inputfocus,
                [e.target.name]: false,
              }))
            }
            onChange={handleChange}
            value={values.email || ""}
            required
          />
          <div className="errors">{!inputfocus.email && errors.email}</div>
        </div>

        {/* password */}
        <div className="login-form mt-2">
          <label htmlFor="password">Password :</label>
          <input
            type="password"
            className="login form-control form-control-xl"
            autoComplete="off"
            placeholder="Enter password here"
            name="lpassword"
            onFocus={(e) =>
              setInputfocus((inputfocus) => ({
                ...inputfocus,
                [e.target.name]: true,
              }))
            }
            onBlur={(e) =>
              setInputfocus((inputfocus) => ({
                ...inputfocus,
                [e.target.name]: false,
              }))
            }
            onChange={handleChange}
            value={values.lpassword || ""}
          />
          <div className="errors"></div>
        </div>
      </div>

      {isSubmit ? (
        <button
          type="button"
          className="btn btns mt-3 d-flex justify-content-center"
        >
          <SimpleCircle />
        </button>
      ) : (
        <button className="btn btns mt-4">Continue</button>
      )}
      <div className="mt-4">
        <a href="/accounts/password/reset">Forgot password ?</a>
      </div>
      <div className="mt-2">
        Don't Have an Account?{" "}
        <br/>
        <span className="text-success"><b>The Registration is free</b></span>
        {redirectTo ? (
          <Link
            className="clr-p"
            to={`/auth/registration?redirectTo=${redirectTo}`}
          >
            Register
          </Link>
        ) : (
          <Link className="clr-p" to="/auth/registration/">
            <br/>
            Register Now
          </Link>
          
        )}
        
      </div>
    </form>
  );
}
