import React, { useState } from "react"
import { Link } from "react-router-dom"
import { Modal } from "react-bootstrap"

import axios from "axios"
import { config } from "../../constant"

const shareUrl = config.url.API_URL + "posts/sharepost"

function ShareModal(props) {
  const [values, setValues] = useState("")
  const token = localStorage.getItem("token")

  const handleChange = (e) => {
    setValues(e.target.value)
  }

  const handleSubmit = async (gall) => {
    const fullName = props?.info?.postinfo?.user_info[0]?.showName
    let data = {}
    if (props?.info?.postinfo?.postType === "shared") {
      data = {
        referenceUserId: token,
        postType: "shared",
        postTitle: props?.info?.postinfo?.postTitle || "Default",
        postDescription: values,
        postCategory: props?.info?.postinfo?.postCategory,
        postSubDomain: props?.info?.postinfo?.postSubDomain,
        language: props?.info?.postinfo?.language,
        // share
        shareUserId: props?.info?.postinfo?.shareUserId,
        sharedPostId: props?.info?.postinfo?.sharedPostId,
        shareGalleryInfo: props?.info?.postinfo?.shareGalleryInfo,
        shareDescription: props?.info?.postinfo?.shareDescription,
        populator: "",
        shareFullName: props?.info?.postinfo?.shareFullName,
        shareProfile: props?.info?.postinfo?.shareProfile,
      }
    } else {
      data = {
        referenceUserId: token,
        postType: "shared",
        postTitle: props?.info?.postinfo?.postTitle || "Default",
        postDescription: values,
        postCategory: props?.info?.postinfo?.postCategory,
        postSubDomain: props?.info?.postinfo?.postSubDomain,
        language: props?.info?.postinfo?.language,
        // share
        shareUserId: props?.info?.postinfo?.user_info[0]?._id,
        sharedPostId: props?.info?.postinfo?._id,
        shareGalleryInfo: gall,
        shareDescription: props?.info?.postinfo?.postDescription,
        populator: "",
        shareFullName: fullName,
        shareProfile: props?.info?.postinfo?.user_info[0]?.profilePhoto,
      }
    }

    const response = await axios.post(shareUrl, data)

    if (response.data.ack === 1) {
      closeModal()
      props?.info?.getpost()
      console.log("ddddddone")
    }
  }

  const closeModal = () => {
    setValues("")
    props?.onHide()
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="bs_modal"
    >
      <div className="modal_header d-flex justify-content-between px-4 align-items-center py-2 text-center">
        <h5>Share as Post</h5>

        <div type="button" onClick={closeModal}>
          X
        </div>
      </div>
      <Modal.Body className="pd-0">
        <div className="row pd-0">
          <div className="col-lg-6 pd-0 post_img">
            <Link
              to={
                "/profiles/" + props?.info?.postinfo?.postType === "shared"
                  ? props?.info?.postinfo?.shareUserId
                  : props?.info?.postinfo?.user_info[0]?._id
              }
              className="posted-user"
            >
              <div className="posted-user-d">
                <div className="pro_img">
                  {props?.info?.postinfo?.user_info[0]?.profilePhoto ? (
                    props?.info?.postinfo?.postType === "shared" ? (
                      <img
                        src={props?.info?.postinfo?.shareProfile}
                        alt="members profile"
                      />
                    ) : (
                      <img
                        src={props?.info?.postinfo?.user_info[0]?.profilePhoto}
                        alt="members profile"
                      />
                    )
                  ) : (
                    <img
                      src={"assets/web_img/choto_logo_1.png"}
                      alt="members profile"
                    />
                  )}
                </div>
                <div className="post-user-name">
                  {props?.info?.postinfo?.postType === "shared"
                    ? props?.info?.postinfo?.shareFullName
                    : props?.info?.postinfo?.user_info[0]?.showName}
                </div>
              </div>
            </Link>
            {props?.info?.postinfo?.postType === "shared" ? (
              props?.info?.postinfo?.shareGalleryInfo &&
              props?.info?.postinfo?.shareGalleryInfo.slice(
                (Math.max(
                  0,
                  props?.info?.postinfo?.shareGalleryInfo.lastIndexOf(".")
                ) || Infinity) + 1
              ) === "mp4" ? (
                <>
                  <video
                    width="100%"
                    height="100%"
                    controls
                    controlsList="nodownload"
                    className="mt-3"
                  >
                    <source
                      src={props?.info?.postinfo?.shareGalleryInfo}
                      type="video/mp4"
                    />
                  </video>
                </>
              ) : (
                <img
                  src={props?.info?.postinfo?.shareGalleryInfo}
                  alt="posts"
                  className="post_img_1 mt-3"
                />
              )
            ) : (
              <div className="mt-3">
                {props?.info?.postinfo?.postInfo[0] ? (
                  props?.info?.postinfo?.postInfo[0].postImagePath.slice(
                    (Math.max(
                      0,
                      props?.info?.postinfo?.postInfo[0].postImagePath.lastIndexOf(
                        "."
                      )
                    ) || Infinity) + 1
                  ) === "mp4" ? (
                    <>
                      <video
                        width="100%"
                        height="100%"
                        controls
                        controlsList="nodownload"
                      >
                        <source
                          src={props?.info?.postinfo?.postInfo[0].postImagePath}
                          type="video/mp4"
                        />
                      </video>
                    </>
                  ) : (
                    <img
                      src={props?.info?.postinfo?.postInfo[0].postImagePath}
                      alt="posts"
                      className="post_img_1"
                    />
                  )
                ) : (
                  ""
                )}
              </div>
            )}

            <div className="mt-3">
              {props?.info?.postinfo?.postType === "shared"
                ? props?.info?.postinfo?.shareDescription
                : props?.info?.postinfo?.postDescription &&
                  props?.info?.postinfo?.postDescription}
            </div>
          </div>

          <div className="col-lg-6">
            <div className="text">
              <label htmlFor="share mt-3">Want to write something</label>
              <textarea
                name="postText"
                className="mt-3"
                rows="4"
                style={{ width: "100%" }}
                onChange={handleChange}
                value={values || ""}
                placeholder="write here"
              ></textarea>
            </div>
            {props?.info?.postinfo?.postInfo[0] ? (
              <button
                type="button"
                className="btn btn-primary mt-4"
                onClick={() =>
                  handleSubmit(props?.info?.postinfo?.postInfo[0].postImagePath)
                }
              >
                Share
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary mt-4"
                onClick={() => handleSubmit("")}
              >
                Share
              </button>
            )}
            <button
              type="button"
              className="btn btn-danger mt-4"
              style={{ marginLeft: "20px" }}
              onClick={closeModal}
            >
              Discard
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ShareModal
