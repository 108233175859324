// import { configureStore } from "@reduxjs/toolkit"
import { AuthReducer, RESET_STORE } from "./reducers/authReducer"
// import AsyncStorage from "@react-native-async-storage/async-storage"
// import { persistReducer, persistStore } from "redux-persist"
import thunk from "redux-thunk"
import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import { BlogReducer } from "./reducers/blogReducer"
import { FaqReducer } from "./reducers/feqReducer"
import { NewsReducer } from "./reducers/newsReducer"
import { postReducer } from "./reducers/postReducer"
import { contactReducer } from "./reducers/contactReducer"
// import { attachPathNameToAction } from "./middlewares/routeMiddleware"
import { MySpaceReducer } from "./reducers/mySpaceReducer"
import { OrganizationReducer } from "./reducers/organizationReducer"
import { AdminReducer } from "./reducers/admin.reducer"
import { NotificationReducer } from "./reducers/notification.reducer"
import { PaymentReducer } from "./reducers/transactions.reducer"
import { usersReducer } from "./reducers/users.reducer"
import { settingsReducer } from "./reducers/settings.reducer"
import { membersReducer } from "./reducers/members.reducer"
import { MySPaceDataReducer } from "./reducers/myspace.reducer"
import { premiumReducer } from "./reducers/premium.reducer"

// const persistConfig = {
//   key: "root",
//   storage: AsyncStorage,
// }

const appReducer = combineReducers({
  auth: AuthReducer,
  blogs: BlogReducer,
  faqs: FaqReducer,
  news: NewsReducer,
  posts: postReducer,
  contacts: contactReducer,
  myspace: MySpaceReducer,
  organization: OrganizationReducer,
  admin: AdminReducer,
  notifications: NotificationReducer,
  transactions: PaymentReducer,
  usersData: usersReducer,
  settings: settingsReducer,
  members: membersReducer,
  mySpaceData: MySPaceDataReducer,
  premium: premiumReducer
})
const rootReducer = (state, action) => {
  if (
    action.type === RESET_STORE &&
    typeof window !== "undefined" &&
    !!window.sessionStorage
  ) {
    state = undefined
    window.sessionStorage.clear()
  }
  return appReducer(state, action)
}

// const persistedReducer = persistReducer(persistConfig, rootReducer)

// export const store = configureStore({
//   reducer: rootReducer,
//   preloadedState:
//     typeof window !== "undefined" && !!window.__PRELOADED_STATE__
//       ? window.__PRELOADED_STATE__
//       : {},
//   devTools: process.env.NODE_ENV !== "production",
//   middleware: [thunk],
// })

// export const persistor = persistStore(store)

export default function configureStore(preloadedState) {
  const middlewares = [thunk]
  const middlewareEnhancer = applyMiddleware(...middlewares)

  const enhancers = [middlewareEnhancer]
  const composedEnhancers = compose(...enhancers)

  const store = createStore(rootReducer, preloadedState, composedEnhancers)

  // if (process.env.NODE_ENV !== "production" && module.hot) {
  //   module.hot.accept("./reducers", () => store.replaceReducer(rootReducer))
  // }

  return store
}
