import api from "../../config/api";
import Network from "../../helpers/network";
export const ACTION_USER_PROFILE_GET = "ACTION_USER_PROFILE_GET";
export const ACTION_USER_FRIENDS_GET = "ACTION_USER_FRIENDS_GET";
export const ACTION_USER_FOLLOWERS_GET = "ACTION_USER_FRIENDS_GET";
export const ACTION_USER_POST_GET = "ACTION_USER_POST_GET";
export const ACTION_USER_PHOTOS_GET = "ACTION_USER_PHOTOS_GET";
export const ACTION_USER_VIDEOS_GET = "ACTION_USER_VIDEOS_GET";
export const ACTION_USER_PROFILE_GET_FAILD = "ACTION_USER_PROFILE_GET_FAILD";
const initialState = {
  profile: null,
  isError: false,
  isProcessing: null,
  isSuccess: null,
  message: null,
  noPosts: 0,
  noPhoto: 0,
  noFriends: 0,
  noVideo: 0,
};

export const MySpaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_USER_PROFILE_GET:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        profile: action.payload,
        isSuccess: true,
        //    message :
      };
    case ACTION_USER_FRIENDS_GET:
      return {
        ...state,
        noFriends: action.payload,
      };
    case ACTION_USER_POST_GET:
      return {
        ...state,
        noPosts: action.payload,
      };
    case ACTION_USER_PHOTOS_GET:
      return {
        ...state,
        noPhoto: action.payload,
      };
    case ACTION_USER_VIDEOS_GET:
      return {
        ...state,
        noVideo: action.payload,
      };
    case ACTION_USER_PROFILE_GET_FAILD:
      return {
        ...state,
        isError: true,
      };
    default:
      return state;
  }
};

export const getProfileUser = async (name) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      Network.get(api.getUser + name)
        .then(async (response) => {
          const profile =
            response?.data?.responseData && response?.data?.responseData[0];
          dispatch({
            type: ACTION_USER_PROFILE_GET,
            payload: profile,
          });
          Promise.all([
            dispatch(await getNumOFFriends(profile?.id)),
            dispatch(await getNumOFPosts(profile?.id)),
            dispatch(await getNumOFPhotos(profile?.id)),
            dispatch(await getNumOFVideos(profile?.id)),
          ])
            .then(() => {
              resolve(response);
            })
            .catch((error) => reject(error));
        })
        .catch((err) => {
          console.log("error", err);
          dispatch({ type: ACTION_USER_PROFILE_GET_FAILD });
          reject(err);
        });
    } catch (error) {
      dispatch({
        type: ACTION_USER_PROFILE_GET_FAILD,
      });
      return reject(error);
    }
  });
};

const getNumOFFriends = async (id) => async (dispatch) => {
  const response = await Network.get(api.userNumberOfFriends + id);
  dispatch({
    type: ACTION_USER_FRIENDS_GET,
    payload: response.data.responseData,
  });
};
const getNumOFPosts = async (id) => async (dispatch) => {
  const response = await Network.get(api.userNumberOfPost + id);
  dispatch({
    type: ACTION_USER_POST_GET,
    payload: response.data.responseData,
  });
};
const getNumOFPhotos = async (id) => async (dispatch) => {
  const response = await Network.get(api.userNumberOfPhotos + id + "/photos");
  dispatch({
    type: ACTION_USER_PHOTOS_GET,
    payload: response.data.responseData,
  });
};
const getNumOFVideos = async (id) => async (dispatch) => {
  const response = await Network.get(api.userNumberOfPhotos + id + "/video");
  dispatch({
    type: ACTION_USER_VIDEOS_GET,
    payload: response.data.responseData,
  });
};
