import React from 'react'
import { connect } from 'react-redux';
import { Footer, IndexNavbar } from '../../components'
import { getPostDetailsAnl } from '../../redux/reducers/postReducer';

function SinglePrivatePostAnalytics(props) {
    const { postAnl } = props
    return (
        <>
            <IndexNavbar />
            <div className="row justify-content-center mt-5">
                <div className="col-lg-10 mb-5">
                    <div className="bg-white p-4 pt-2 pb-2 mb-4">
                        <div class="row">
                            <div className='col-sm-12 text-center mt-4'>
                                <h1>PPV Post Subscription Analytics</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse pellentesque egestas felis nec tempus.
                                    Suspendisse nibh nunc, aliquet non felis non, sagittis tincidunt mi. In malesuada purus blandit, efficitur urna porta, vulputate magna.</p>
                                <ul className="analytics_list text-center">
                                    <li className="analytics_list_li border-primary">
                                        <h4 className="mt-4 text-primary"><b>PPV Subscribed</b></h4>
                                        <div className="analytics_number bg-primary text-white w-50 h-50 rounded-circle"><h3>
                                            {postAnl?.totalPPVSubscription || 0}
                                        </h3></div>
                                    </li>
                                    <li className="analytics_list_li border-success">
                                        <h4 className="mt-4 text-success"><b>Active</b></h4>
                                        <div className="analytics_number bg-success text-white w-50 h-50 rounded-circle">
                                            <h3>
                                                {postAnl?.totalActivePPVSubscription || 0}
                                            </h3>
                                        </div>
                                    </li>
                                    <li className="analytics_list_li border-danger">
                                        <h4 className="mt-4 text-danger"><b>Expired</b></h4>
                                        <div className="analytics_number bg-danger text-white w-50 h-50 rounded-circle">
                                            <h3>
                                                {postAnl?.totalExpirePPVSubscription || 0}
                                            </h3>
                                        </div>
                                    </li>

                                    <li className="analytics_list_li border-info">
                                        <h4 className="mt-4 text-info"><b>PPV Revenue</b></h4>
                                        <div className="analytics_number bg-info text-white w-50 h-50 rounded-circle">
                                            <h3 className="last-child">
                                                <small className="d-block"><b>Rs</b></small>
                                                {Number(postAnl?.totalPPVSubscription * postAnl?.ppv)}
                                            </h3>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

SinglePrivatePostAnalytics.loadData = (params, search) => async (dispatch) => {
    //   const query = queryParse(search);
    const id = params?.split("/")[3];
    const currentUserId =
        typeof window !== "undefined" ? localStorage.getItem("token") : ""
    // console.log("SinglePrivatePostAnalytics params>>>", params, id);

    return await dispatch(getPostDetailsAnl({ id: id, token: currentUserId }));
};
const mapStateToProps = (state) => {
    console.log("SinglePrivatePostAnalytics", state);
    const { postAnl } =
        state.posts;
    return {
        postAnl
    };
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SinglePrivatePostAnalytics);