import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import map from "../../../assets/web_img/map.png"
import moment from "moment";
import { useDispatch } from "react-redux";
import { addContact } from "../../redux/reducers/contactReducer";
import { CustomizedSnackbars, Footer, IndexNavbar } from '../../components';
import { SubmitButton } from '../../components/AppComponents/Helpers';


function Contactus(props) {
  const dispatch = useDispatch()
  const { Contact, isSuccess, isError } = props;
  const [errMsg, setErrMsg] = useState("")
  const [isSubmit, setIsSubmit] = useState(false)
  const [isSuccessed, setIsSuccessed] = useState(false)


  const [inputField, setInputFiels] = useState({
    fullname: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  })
  const inputHandler = (e) => {
    setInputFiels({ ...inputField, [e.target.name]: e.target.value })
    // console.log(inputField)
  }
  const submitButton = async e => {
    try {
      e.preventDefault()
      setIsSubmit(true)
      const response = await dispatch(addContact(inputField))
      console.log(response)
      if(response) setIsSubmit(false)
      
      if (response?.status) {
        setInputFiels({})
        setErrMsg("")
        setIsSuccessed(true)
      } else {
        setErrMsg("Sorry, we can't send your request for now. Please try again later")
      }

    } catch (err) {
      console.error(err)
      setErrMsg("Sorry, we can't send your request for now. Please try again later")
    }

  }

  useEffect(() => {
    if (!errMsg) return
    setTimeout(() => setErrMsg(""), [5000])
  }, [errMsg])

  useEffect(() => {
    if (!isSuccessed) return
    setTimeout(() => setIsSuccessed(false), [5000])
  }, [isSuccessed])

  return (
    <>
      <IndexNavbar />
      <div id="contact_to" style={{ backgroundImage: `url(${map})` }}>
        <div className="container">
          <form className="row contact-form" onSubmit={submitButton}>
            <h2 className="text-center">Get in Touch</h2>
            <p className="text-center">Have any questions? we'd love to hear from you.</p>
            <div className="col-sm-6 form-group">
              <input
                type="text"
                name="fullname"
                className="form-control"
                onChange={inputHandler}
                placeholder="Name*"
                value={inputField?.fullname || ''}
                required
              />
            </div>
            <div className="col-sm-6 form-group">
              <input
                type="email"
                name="email"
                className="form-control"
                onChange={inputHandler}
                placeholder="Email*"
                value={inputField?.email || ''}
                required
              />
            </div>
            <div className="col-sm-6 form-group">
              <input
                type="text"
                name="phone"
                className="form-control"
                onChange={inputHandler}
                placeholder="Phone*"
                value={inputField?.phone || ''}

                required />
            </div>
            <div className="col-sm-6 form-group">
              <input
                type="text"
                name="subject"
                className="form-control"
                onChange={inputHandler}
                placeholder="Subject"
                value={inputField?.subject || ''}

                required />
            </div>
            <div className="col-sm-12 form-group">
              <textarea
                name="message"
                placeholder="Message"
                className="form-control"
                onChange={inputHandler}
                value={inputField?.message || ''}
                required></textarea>
            </div>
            <div className="col-sm-12 text-center">
              <SubmitButton css={"btn btn-success"} title={"Submit"} issubmit={isSubmit} />
              {/* <button type='button' className='btn btn-danger' onClick={()=>setInputFiels({})}>clear form</button> */}
            </div>
          </form>

          {/* {sendMsg && <BottomUpNotification msg={msg} close={() => setSendMsg(false)} />} */}


          <div className="row contact-form contact_info">
            <h1>Contact Info</h1>
            <div className="col-sm-5 cmn_box">
              <h3>Houston, USA</h3>
              <p><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none" /><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" /></svg>
                7706 GrandPass Lane
                <br />
                Katy, Tx 77494
              </p>
              <p>
                <a href="tel:+1 8324370395"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z" /></svg>
                  +1 8324370395</a><br />
                <a href="tel:+1 8324370395"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z" /></svg>
                  +1 9728378358</a>
              </p>
              <p>
                <a href="mailto:info@allphanes.com"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" /></svg>
                  info@allphanes.com</a>
              </p>
            </div>
            <div className="col-sm-1"></div>
            <div className="col-sm-5 cmn_box">
              <h3>Kolkata, India</h3>
              <p><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M0 0h24v24H0z" fill="none" /><path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" /></svg>
                DB 34, Sector 1, Bidhannagar,
                <br />
                Kolkata, West Bengal700064
              </p>
              <p>
                <a href="tel:+913346051900"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z" /></svg>
                  +913346051900</a><br />
                <a href="tel:+91 9147054999"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z" /></svg>
                  +91 9147054999</a>
              </p>
              <p>
                <a href="mailto:info@allphanes.com"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#000000"><path d="M0 0h24v24H0z" fill="none" /><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" /></svg>
                  info@allphanes.com</a>
              </p>
            </div>
          </div>
        </div>

        {isSuccessed && <CustomizedSnackbars type={0} msg={"Your message sent succefully"} />}
        {errMsg && <CustomizedSnackbars type={1} msg={errMsg} />}



      </div>
      <Footer />
    </>
  )
}
export default Contactus
