import {
  faComment,
  faHeart,
  faLink,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { config } from "../constant";
import Comments from "../Comments";
import ReadMore from "../Readmore";
const getPostUrl = config.url.API_URL + "posts/multiplefilters?";
const likePost = config.url.API_URL + "social/like";
const markFav = config.url.API_URL + "social/markfavourite";
const commentPost = config.url.API_URL + "social/comments";
export default function ShareLink(props) {
  console.log("ar ami pa", props);
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  const [selectedPost, setSelectedPost] = useState(null);
  const [post, setPost] = useState(null);
  const [searchParams] = useSearchParams();
  const currentUser = JSON.parse(localStorage.getItem("currentuser"));
  const [commentSwitch, setCommentSwitch] = useState({});
  const [comment, setComment] = useState([]);
  const [commentModal, setCommentModal] = useState([]);
  const [commentModal2, setCommentModal2] = useState([]);
  const [commentModal3, setCommentModal3] = useState("");
  const [fetchLength, setFetchLength] = useState(0);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const lastLocation = useLocation();
  const handleClose = () => {
    props.cls();
    navigate({ pathname: lastLocation.pathname });
    setShow(false);
    setSelectedPost(null);
  };
  function handleShow(breakpoint) {
    setFullscreen(breakpoint);
    setShow(true);
  }
  const handleLikeClick = async (postId, userId) => {
    const dataPost = await axios.post(likePost, {
      referenceUserId: token,
      referencePostId: postId,
    });
    if (dataPost) getPosts();
  };

  const handleFavouriteClick = async (postId, userId) => {
    const dataPost = await axios.post(markFav, {
      referenceUserId: token,
      referencePostId: postId,
    });
    console.log("clicked", dataPost);

    if (dataPost) getPosts();
  };

  const handleCommentChange = (e) => {
    setComment((values) => ({ ...values, [e.target.name]: e.target.value }));
  };

  const handleCommentClick = async (postId, userId, commentName) => {
    const dataArr = {
      referenceUserId: userId,
      referencePostId: postId,
      messageText: comment[commentName],
    };
    const dataPost = await axios.post(commentPost, dataArr);
    if (dataPost) {
      setComment((values) => ({ ...values, [commentName]: "" }));
      getPosts();
    }
    setComment((values) => ({ ...values, [commentName]: "" }));
  };
  // const getAllPosts = async () => {
  // 	const response = await axios.get(getPosts + token)
  // 	setPosts(await response.data.responseData)
  // }
  const handleCommentModalClick = (
    postInfo,
    userInfo,
    postDescription,
    postid,
    index,
    limit
  ) => {
    setModalShow3(true);
    const k = "comment" + index;
    setFetchLength(fetchLength + limit);
    setCommentSwitch((values) => ({ ...values, [k]: index }));
    setCommentModal(postInfo);
    setCommentModal2(userInfo);
    setCommentModal3({ postid: postid, postDescription: postDescription });
  };
  const getPosts = async (id) => {
    const response = await axios.get(getPostUrl + `id=${id}&token=${token}`);
    console.log("post>>>", response);
    if (response.data.responseData.length > 0)
      setPost(response.data.responseData[0]);
  };
  const goToPostDetails = (id) => {
    console.log("goToPostDetails>", id);
    navigate({ pathname: lastLocation.pathname, search: "?v=" + id });
  };
  const postDetails = () => {
    const postId = searchParams.get("v");
    getPosts(postId);
    setShow(true);
  };
  useEffect(() => {
    if (selectedPost) goToPostDetails(selectedPost);
  }, [selectedPost]);
  useEffect(() => {
    if (!currentUser)
      navigate(
        "/auth/login?redirectTo=" + lastLocation.pathname + lastLocation.search
      );
    // let params = queryString.parse(props.location.search)
    if (searchParams.get("v")) {
      setSelectedPost(searchParams.get("v"));
      postDetails();
    }
  }, [searchParams]);

  useEffect(() => {
    setSelectedPost(props.id);
  }, [props.id]);

  // console.log("postIds",props.id)

  return (
    <div>
      <div className="like commentx" onClick={() => setSelectedPost(props.id)}>
        <FontAwesomeIcon icon={faLink} className="post_icons"></FontAwesomeIcon>
      </div>

      {selectedPost && (
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={() => setShow(false)}
          id="pop_box"
        >
          {/* <Modal.Header closeButton>
							<Modal.Title>Modal</Modal.Title>
						</Modal.Header> 
					*/}
          <Modal.Body>
            <Row className="d-flex align-items-stretch">
              <Col xs={12} md={8} className="context">
                <Button
                  className="close_btn"
                  variant="primary"
                  onClick={handleClose}
                >
                  X
                </Button>
                <div className="post_holder">
                  {/* if text */}

                  <div className="text_content_holder">
                    <div className="mb-3 mt-3 fs_tt">
                      {post?.postDescription}
                    </div>
                    <pre className="mt-4 px_15 hola">
                      {post?.postInfo &&
                      post?.postInfo.length > 0 &&
                      post.postInfo[0] ? (
                        post.postInfo[0].postImagePath.slice(
                          (Math.max(
                            0,
                            post.postInfo[0].postImagePath.lastIndexOf(".")
                          ) || Infinity) + 1
                        ) === "mp4" ? (
                          <>
                            <video
                              width="100%"
                              height="100%"
                              controls
                              controlsList="nodownload"
                            >
                              <source
                                src={post.postInfo[0].postImagePath}
                                type="video/mp4"
                              />
                            </video>
                          </>
                        ) : (
                          <img
                            src={post.postInfo[0].postImagePath}
                            alt="posts"
                            className="post_img_1"
                          />
                        )
                      ) : (
                        ""
                      )}

                      {post?.postInfo &&
                        post?.postInfo.length > 0 &&
                        !post.postInfo[0] && (
                          <pre className="mt-4 px_15 hola">
                            {post.postCategory === "Normal" ? (
                              ""
                            ) : (
                              <div className="mb-3 fs_tt">
                                {post.postTitle && post.postTitle}
                              </div>
                            )}
                            <ReadMore className="view-post-des mt-2">
                              {post.postDescription && post.postDescription}
                            </ReadMore>
                          </pre>
                        )}

                      {post?.postType ? (
                        <>
                          {post?.postType === "shared" && (
                            <>
                              {/* {post.shareDescription && ( */}
                              <div className="share_body">
                                {post.shareGalleryInfo ? (
                                  <img
                                    src={post.shareGalleryInfo}
                                    alt="posts"
                                    className="post_img_1"
                                  />
                                ) : (
                                  ""
                                )}
                                <div className="share_sec">
                                  <pre className="mt-4 px_15">
                                    {post.shareDescription && (
                                      <ReadMore className="view-post-des mt-2">
                                        {post.shareDescription &&
                                          post.shareDescription}
                                      </ReadMore>
                                    )}
                                  </pre>

                                  <div className="posted-user kb_1">
                                    <div className="posted-user-d">
                                      <div className="pro_img px-3 py-2 ">
                                        {post.shareProfile ? (
                                          <img
                                            src={post.shareProfile}
                                            alt="members profile"
                                          />
                                        ) : (
                                          <img
                                            src="assets/web_img/choto_logo_1.png"
                                            alt="members profile"
                                          />
                                        )}
                                      </div>
                                      <div className="post-user-name">
                                        {post.shareFullName &&
                                          post.shareFullName}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-3 post-action-sec d-flex gap-3">
                                    {/* Post like */}
                                    {post.referenceUserId === token ? (
                                      <div className="like commentx">
                                        <FontAwesomeIcon
                                          icon={faHeart}
                                          className="post_icons"
                                        ></FontAwesomeIcon>
                                      </div>
                                    ) : post.isLiked[0] ? (
                                      <div
                                        className="like commentx"
                                        onClick={() =>
                                          handleLikeClick(post._id)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faHeart}
                                          className="post_icons"
                                          style={{ color: "red" }}
                                        ></FontAwesomeIcon>
                                      </div>
                                    ) : (
                                      <div
                                        className="like commentx"
                                        onClick={() =>
                                          handleLikeClick(post._id)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faHeart}
                                          className="post_icons"
                                        ></FontAwesomeIcon>
                                      </div>
                                    )}

                                    <div
                                      className="commentx"
                                      title="Comments"
                                      onClick={() =>
                                        handleCommentModalClick(
                                          post.postInfo[0],
                                          post.user_info[0],
                                          post.postDescription,
                                          post._id,
                                          post.indexinfo,
                                          3
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faComment}
                                        className="post_icons"
                                      ></FontAwesomeIcon>
                                    </div>

                                    {token === post.user_info[0]._id ? (
                                      <div
                                        className="commentx"
                                        title="You already shared this post"
                                      >
                                        <FontAwesomeIcon
                                          icon={faPaperPlane}
                                          className="post_icons"
                                        ></FontAwesomeIcon>
                                      </div>
                                    ) : (
                                      <div
                                        className="commentx"
                                        onClick={() => setModalShow(true)}
                                      >
                                        <FontAwesomeIcon
                                          icon={faPaperPlane}
                                          className="post_icons"
                                        ></FontAwesomeIcon>
                                      </div>
                                    )}
                                  </div>
                                  <div className="px_15">
                                    {/* like */}
                                    <div className="likes_count">
                                      {post.totalLikes && post.totalLikes} Likes
                                      <span style={{ marginLeft: "10px" }}>
                                        {post.totalComment && post.totalComment}{" "}
                                        Comments
                                      </span>
                                    </div>

                                    {/* Comments  */}
                                    {post.postInfo[0] &&
                                      post.postDescription && (
                                        <pre>
                                          <ReadMore className="view-post-des mt-2">
                                            <span className="view_name">
                                              {post.postDescription &&
                                                post.user_info[0].firstName +
                                                  " " +
                                                  post.user_info[0].lastName +
                                                  " : "}
                                            </span>
                                            {post.postDescription &&
                                              post.postDescription}
                                          </ReadMore>
                                        </pre>
                                      )}

                                    {commentSwitch[
                                      "comment" + post.indexinfo
                                    ] === post.indexinfo ? (
                                      <>
                                        <Comments
                                          className="width-100"
                                          show={modalShow3}
                                          onHide={() => setModalShow3(false)}
                                          backdrop="static"
                                          userinfo={commentModal2}
                                          postinfo={commentModal}
                                          postdetails={commentModal3}
                                          key={post.indexinfo}
                                          limit={fetchLength}
                                          tokenuser={currentUser.profilePhoto}
                                        />
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {commentSwitch[
                                      "comment" + post.indexinfo
                                    ] === post.indexinfo ? (
                                      <div className="d-flex gap-2">
                                        {fetchLength >= post.totalComment ? (
                                          <div className="comment_count">
                                            You have seen all comments,
                                          </div>
                                        ) : (
                                          <div
                                            className="comment_count"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleCommentModalClick(
                                                post.postInfo[0],
                                                post.user_info[0],
                                                post.postDescription,
                                                post._id,
                                                post.indexinfo,
                                                11
                                              )
                                            }
                                          >
                                            View more comments (wip),
                                          </div>
                                        )}

                                        <div
                                          className="comment_count"
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            setCommentSwitch((values) => ({
                                              ...values,
                                              ["comment" + post.indexinfo]: "",
                                            }))
                                          }
                                        >
                                          Close comments
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        className="comment_count"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleCommentModalClick(
                                            post.postInfo[0],
                                            post.user_info[0],
                                            post.postDescription,
                                            post._id,
                                            post.indexinfo,
                                            3
                                          )
                                        }
                                      >
                                        View recent comments
                                      </div>
                                    )}
                                  </div>

                                  {commentSwitch["comment" + post.indexinfo] !==
                                  post.indexinfo ? (
                                    <div className="mt-3 add_comment d-flex px_15">
                                      <div className="pro_img">
                                        {props?.currentuser?.profilePhoto ? (
                                          <img
                                            src={
                                              props?.currentuser?.profilePhoto
                                            }
                                            alt="members profile"
                                          />
                                        ) : (
                                          <img
                                            src="assets/web_img/choto_logo_1.png"
                                            alt="members profile"
                                          />
                                        )}
                                      </div>
                                      <input
                                        type="text"
                                        name={"comment" + post.indexinfo}
                                        onChange={handleCommentChange}
                                        value={
                                          comment["comment" + post.indexinfo]
                                        }
                                        className="comment"
                                        placeholder="Add comment"
                                      />
                                      <div
                                        className="commentPost"
                                        onClick={() =>
                                          handleCommentClick(
                                            post._id,
                                            token,
                                            "comment" + post.indexinfo
                                          )
                                        }
                                      >
                                        Post
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              {/* )} */}
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </pre>
                  </div>
                </div>
              </Col>
              <Col xs={6} md={4} className="context_comment" closeButton>
                <ul className="list-group list-group-flush">
                  <li className="list-group-item author_box">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <div
                          className="profile_image_holder"
                          style={{
                            backgroundImage: `url(${
                              post?.user_info[0]?.profilePhoto
                                ? post?.user_info[0]?.profilePhoto
                                : "https://res.cloudinary.com/dsg7oitoj/image/upload/v1656326065/zmiolsx9kov7sgr2gltj.webp"
                            })`,
                          }}
                        ></div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        {post?.user_info[0]?.showName}
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}
