import React, { useEffect, useRef, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import EditOffIcon from '@mui/icons-material/EditOff';
import SaveIcon from '@mui/icons-material/Save';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { OrgUpdatex } from '../../redux/reducers/organizationReducer';

function OrgAbout(props) {
    const { orgDetails } = props
    console.log("OrgAbout", orgDetails)
    const [aboutInputs, setAboutInputs] = useState("")
    const [editorLoaded, setEditorLoaded] = useState(false)
    const [editorShow, setEditorShow] = useState(false)
    const editorRef = useRef()
    // const { CKEditor, ClassicEditor } = editorRef.current || {}
    const token =
        typeof window !== "undefined" ? localStorage.getItem("token") : "";
    const dispatch = useDispatch()

    const handleSubmit = async e => {
        try {
            e.preventDefault()
            console.log("OrgUpdatex")
            const response = await dispatch(OrgUpdatex(`?_id=${orgDetails?._id}`, {
                details: aboutInputs
            }))
            // const response2 = await 
            console.log("handleSubmitfdf", response)
        } catch (err) {
            console.error(err)
        }
    }
    useEffect(() => {
        editorRef.current = {
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
            ClassicEditor: require('@ckeditor/ckeditor5-build-classic')
        }
        setEditorLoaded(true)
    }, [])

    useEffect(() => {
        setAboutInputs(orgDetails?.details)
    }, [orgDetails])

    const handleEditorShow = () => {
        setEditorShow(!editorShow)
        setAboutInputs(orgDetails?.details)
    }

    return (
        <>
            <div className="cmn_box">
                <div className="d-flex justify-content-between">
                    <h4>About Institution</h4>
                    <div className="d-flex">
                        {editorShow &&
                            <Button onClick={handleEditorShow}>
                                <SaveIcon
                                    titleAccess='Click to save'
                                    onClick={handleSubmit}
                                />
                            </Button>
                        }
                        {orgDetails?.userid === token &&
                            <Button onClick={() => setEditorShow(!editorShow)}>
                                {!editorShow ?
                                    <EditIcon titleAccess='Click to edit org about' />
                                    :
                                    <EditOffIcon
                                        titleAccess='Click to cancel edit'
                                    />
                                }
                            </Button>
                        }
                    </div>
                </div>
                {editorLoaded && token === orgDetails?.userid && editorShow &&
                    // <CKEditor
                    //     editor={ClassicEditor}
                    //     data={aboutInputs || ""}
                    //     config={{
                    //         removePlugins: ['MediaEmbed'],
                    //         placeholder: 'Type the content here!',
                    //         link: {
                    //             // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                    //             addTargetToExternalLinks: true,
                    //             decorators: [
                    //                 {
                    //                     mode: 'manual',
                    //                     label: 'External Link',
                    //                     attributes: {
                    //                         target: '_blank',
                    //                     }
                    //                 }
                    //             ]
                    //         }
                    //     }}
                    //     onReady={(editor) => {
                    //         // You can store the "editor" and use when it is needed.
                    //         //console.log('Editor is ready to use!', editor)
                    //     }}
                    //     onChange={(event, editor) => {
                    //         setAboutInputs(editor.getData())
                    //     }}
                    //     onBlur={(event, editor) => {
                    //         // //console.log( 'Blur.', editor )
                    //     }}
                    //     onFocus={(event, editor) => {
                    //         // //console.log( 'Focus.', editor )
                    //     }}
                    // />
                    <textarea
                        className="form-control"
                        rows="8"
                        placeholder="Please type about your institution"
                        name="details"
                        onChange={e => setAboutInputs(e.target.value)}
                        value={aboutInputs}
                    >
                        {aboutInputs}
                    </textarea>
                }


                <p>{!editorShow && orgDetails?.details}</p>
            </div>
        </>
    )
}

export default OrgAbout