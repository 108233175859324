import React, { useState, useEffect } from "react"
import axios from "axios"
import { config } from "../../../constant"
import Accordion from "react-bootstrap/Accordion"
import { useRef } from "react"
import { SimpleCircle } from "../../components"
import ManagementLeftNav from "../../components/Navbars/ManagementLeftNav"
// import { useNavigate } from "react-router-dom"

const API_URLX = config.url.API_URL + "news/"
const IMAGE_UPLOAD_URL = config.url.API_URL + "admin/"

function AdminNewsManagement() {
    const [values, setValues] = useState({})
    const [errorMsg, setErrorMsg] = useState("")
    const [editStatus, setEditStatus] = useState(false)
    const [editObj, setEditObj] = useState({})
    const [faqs, setFaqs] = useState([])
    const [editId, setEditId] = useState("")
    const editBlogFocus = useRef()
    const [previewSource, setPreviewSource] = useState("")
    const [selectedFile, setSelectedFile] = useState(null)
    const [files, setFiles] = useState("")
    const editorRef = useRef()
    const [editorLoaded, setEditorLoaded] = useState(false)
    const { CKEditor, ClassicEditor } = editorRef.current || {}

    // const navigate = useNavigate()

    // useEffect(() => {
    //     const user = typeof window !== "undefined"
    //         ? JSON.parse(localStorage.getItem("currentUser"))
    //         : "";

    //     if (user?.userRole !== '1010') navigate('/admin/')
    // }, [])

    useEffect(() => {
        editorRef.current = {
            // CKEditor: require('@ckeditor/ckeditor5-react'), // depricated in v3
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, // v3+
            ClassicEditor: require('@ckeditor/ckeditor5-build-classic')
        }
        setEditorLoaded(true)
    }, [])

    const getData = async () => {
        const response = await axios.get(API_URLX + "get")
        setFaqs(response.data.responseData)
    }

    const handleChange = (e) => {
        setValues((values) => ({ ...values, [e.target.name]: e.target.value }))
    }

    const previewFile = (file) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            setPreviewSource(reader.result)
        }
    }

    const handleFileInputChange = (e) => {
        const file = e.target.files[0]
        console.log("handleFileInputChange", file)
        file ? previewFile(file) : setPreviewSource("")
        file ? setSelectedFile(file) : setSelectedFile(null)
        setFiles(e.target.value)
    }

    const manageData = (obj) => {
        console.log(obj)
        const file = selectedFile ? selectedFile : obj?.defaultImage || ""
        const data = new FormData()
        data.append("title", obj.title)
        data.append("shortDescription", obj.shortDescription)
        data.append("content", obj.content)
        data.append("defaultImage", file)
        return data
    }

    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const data = new FormData()
                    loader.file.then((file) => {
                        data.append("files", file)

                        // const response = get
                        axios
                            .post(`${IMAGE_UPLOAD_URL}blogimage`, data)
                            .then((res) => {
                                // image array
                                resolve({
                                    default: `${res.data.responseData}`,
                                })
                            })
                            .catch((err) => {
                                console.error(err)
                                reject(err)
                            })
                    })
                })
            },
        }
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // manageImages
        const data = manageData(values)

        const response = await axios.post(API_URLX + "add?role=1010", data)
        // if (response.data.ack === 0) return setErrorMsg("Some Error occured")
        if (response.data.status === 400) return setErrorMsg("title already exist")
        setErrorMsg("")
        setValues({})
        setSelectedFile(null)
        setPreviewSource("")
        getData()
       
    }

    // edit start
    const handleEdit = (item) => {
        setEditObj(item)
        setEditId(item._id)
        setEditStatus(true)
        editBlogFocus.current.focus()
    }

    const cancelEdit = () => {
        setEditStatus(false)
        setEditId("")
        setEditObj({})
        setValues({})
    }

    const handleUpdate = async (e) => {
        try {
            e.preventDefault()
            const data = manageData(editObj)

            const response = await axios.patch(
                API_URLX + `update?role=1010&_id=${editId}`,
                data
            )
            if (response.data.ack === 1) {
                getData()
                cancelEdit()
            }
        } catch (err) {
            console.error(err)
        }
    }

    const deleteFAQ = async (id) => {
        const result = window.confirm("Are you sure?")
        if (!result) return
        await axios.delete(API_URLX + `delete?role=1010&_id=${id}`)
        getData()
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-lg-2">
                        <ManagementLeftNav />
                    </div>
                    <div className="col-lg-10 bg_allp editor" style={{ minHeight: "100vh" }}>
                        {editStatus ? (
                            <form onSubmit={handleUpdate}>
                                <div className="form-group mt-4">
                                    <label htmlFor="FormControlInput1">Edit News Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="FormControlInput1"
                                        placeholder="edit title here"
                                        name="title"
                                        onChange={(e) => {
                                            setEditObj((values) => ({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            }))
                                        }}
                                        value={editObj.title || ""}
                                        required
                                    />
                                </div>

                                <div className="form-group mt-4">
                                    <label htmlFor="FormControlInput1">Edit Default Image</label>
                                    <input
                                        type="file"
                                        name="postImage"
                                        onChange={handleFileInputChange}
                                        value={files || ""}
                                    />
                                </div>

                                {/* {editObj?.defaultImage ? */}
                                <div className="preview_image">
                                    <img
                                        src={previewSource ? previewSource : editObj?.defaultImage}
                                        alt="preview not required"
                                    />
                                    <div
                                        className="btn btn-danger"
                                        onClick={() => {
                                            console.log("kk")
                                            setPreviewSource("")
                                            setSelectedFile(null)
                                            setFiles("")
                                            setEditObj((k) => ({ ...k, defaultImage: "" }))
                                        }}
                                    >
                                        Delete
                                    </div>
                                </div>

                                <div className="form-group mt-4">
                                    <label htmlFor="FormControlInput1">Edit Short Description</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="FormControlInput1"
                                        placeholder="edit des here"
                                        name="shortDescription"
                                        onChange={(e) => {
                                            setEditObj((values) => ({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            }))
                                        }}
                                        value={editObj.shortDescription || ""}
                                        ref={editBlogFocus}
                                        rows="6"
                                        required
                                    ></textarea>
                                </div>

                                <div className="form-group mt-3">
                                    <label htmlFor="FormControlTextarea1">Edit Content</label>
                                </div>
                                {editorLoaded ?
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editObj.content || ""}
                                        config={{
                                            extraPlugins: [uploadPlugin],
                                            link: {
                                                // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                                addTargetToExternalLinks: true,
                                                decorators: [
                                                    {
                                                        mode: 'manual',
                                                        label: 'External Link',
                                                        attributes: {
                                                            target: '_blank',
                                                        }
                                                    }
                                                ]
                                            }
                                        }}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor)
                                        }}
                                        onChange={(event, editor) => {
                                            setEditObj((editObj) => ({
                                                ...editObj,
                                                content: editor.getData(),
                                            }))
                                        }}
                                        onBlur={(event, editor) => {
                                            // //console.log( 'Blur.', editor )
                                        }}
                                        onFocus={(event, editor) => {
                                            // //console.log( 'Focus.', editor )
                                        }}
                                    /> : <div><SimpleCircle /></div>}
                                <div className="d-flex gap-3">
                                    <button type="submit" className="mt-4 btn btn-primary">
                                        Update
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-4 btn btn-danger"
                                        onClick={cancelEdit}
                                    >
                                        Cancel
                                    </button>
                                </div>
                                {errorMsg && <div className="text-danger mt-4">{errorMsg}</div>}
                            </form>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <div className="form-group mt-4">
                                    <label htmlFor="FormControlInput1">Add News Title</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="FormControlInput1"
                                        placeholder="add title here"
                                        name="title"
                                        onChange={handleChange}
                                        value={values.title || ""}
                                        required
                                    />
                                </div>
                               
                                {/* <p>{errorMsg}</p> */}

                                <div className="form-group mt-4">
                                    <label htmlFor="FormControlInput1">Add Default Image</label>
                                    <input
                                        type="file"
                                        name="postImage"
                                        onChange={handleFileInputChange}
                                    // value={fileInputState}
                                    />
                                </div>

                                {previewSource ? (
                                    <div className="preview_image">
                                        <img src={previewSource} alt="preview not required" />
                                    </div>
                                ) : (
                                    ""
                                )}

                                <div className="form-group mt-4">
                                    <label htmlFor="FormControlInput1">Add Short Description</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="FormControlInput1"
                                        placeholder="edit des here"
                                        name="shortDescription"
                                        onChange={(e) =>
                                            setValues((values) => ({
                                                ...values,
                                                [e.target.name]: e.target.value,
                                            }))
                                        }
                                        value={values.shortDescription || ""}
                                        rows="6"
                                        required
                                    ></textarea>
                                </div>

                                <div className="form-group mt-3">
                                    <label htmlFor="FormControlTextarea1">Add Content</label>
                                </div>
                                {editorLoaded ?
                                    <CKEditor
                                        config={{
                                            extraPlugins: [uploadPlugin],
                                            link: {
                                                // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
                                                addTargetToExternalLinks: true,
                                                decorators: [
                                                    {
                                                        mode: 'manual',
                                                        label: 'External Link',
                                                        attributes: {
                                                            target: '_blank',
                                                        }
                                                    }
                                                ]
                                            }
                                        }}
                                        editor={ClassicEditor}
                                        data={values.content || ""}
                                        onReady={(editor) => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor)
                                        }}
                                        onChange={(event, editor) => {
                                            setValues((values) => ({ ...values, content: editor.getData() }))
                                        }}
                                        onBlur={(event, editor) => {
                                            // //console.log( 'Blur.', editor )
                                        }}
                                        onFocus={(event, editor) => {
                                            // //console.log( 'Focus.', editor )
                                        }}
                                    /> : <div><SimpleCircle /></div>}
                                <button type="submit" className="mt-4 btn btn-primary">
                                    Submit
                                </button>
                                {errorMsg && <div className="text-danger mt-4">{errorMsg}</div>}
                            </form>
                        )}

                        <div className="mt-5">
                            <h2>News</h2>
                            <Accordion defaultActiveKey="0">
                                {faqs &&
                                    faqs.map((item, index) => {
                                        return (
                                            <Accordion.Item eventKey={index} key={index}>
                                                <Accordion.Header>
                                                    <div className="d-flex justify-content-between w-97">
                                                        <div className="fw-500">{item.title}</div>
                                                        {/* deleteFAQ */}
                                                        <div className="d-flex gap-3">
                                                            <div
                                                                className="btn btn-success"
                                                                onClick={() => handleEdit(item)}
                                                            >
                                                                Edit
                                                            </div>
                                                            <div
                                                                className="btn btn-danger"
                                                                onClick={() => deleteFAQ(item._id)}
                                                            >
                                                                Delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body
                                                    dangerouslySetInnerHTML={{ __html: item.content }}
                                                />
                                                {/* {} */}
                                                {/* </Accordion.Body> */}
                                            </Accordion.Item>
                                        )
                                    })}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminNewsManagement
