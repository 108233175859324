import React, { useState } from "react";
import moment from "moment";
import SinglePost from "../../containers/Creation/SinglePost";
import { CheckExtention } from "../../../_helpers/Variables";
import { YouTubeEmbed } from "react-social-media-embed";

function NewsList(props) {
  const { trendingCreations } = props;
  const [isPostModal, setIsPostModal] = useState(false);
  const [postId, setPostId] = useState();
  const handleModalShow = (index) => {
    setIsPostModal(true);
    setPostId(index);
  };

  return (
    <div className="trend-sec latest_news ps-3 pe-3">
      <div className="trend-title mb-0 latest_news">
        <img src="/assets/web_img/news_icon.png" alt="dd" width="25px" />
        {props.title}
      </div>

      {trendingCreations?.map((item) => (
        <div key={item._id} className="mt-3 border p-2">
          <div>
            <div className="row">
              <div className="posted-user-d mb-2">
                <div className="pro_img">
                  <img
                    src={
                      item?.user_info[0]?.profilePhoto ||
                      "/assets/web_img/choto_logo_1.png"
                    }
                    alt={item?.user_info[0]?.showName}
                    // eslint-disable-next-line no-sequences
                    onError={(e) => (
                      (e.target.onerror = null),
                      (e.target.src = "/assets/web_img/choto_logo_1.png")
                    )}
                  />
                </div>
                <div className="post-user-name">
                  {item?.user_info[0]?.showName}
                  <div className="fs_12">
                    {moment(item?.createdAt).fromNow(true)}
                  </div>
                </div>
                <div>
                  <div class="post_marks">{item?.postSubDomain}</div>
                </div>
              </div>
              {!item?.url && item?.postInfo?.length === 0 && (
                <div className="my-2">{item?.postDescription}</div>
              )}
              {item?.url && (
                <YouTubeEmbed url={item?.url} width={"100%"} height={450} />
              )}
              {item?.postInfo?.length > 0 && (
                <>
                  {CheckExtention(item?.postInfo[0]?.postImagePath) === 0 ? (
                    <div className="p-2">
                      <div
                        className="home_posts border border-white border-5 rounded-3 bg-white"
                        style={{
                          backgroundImage: `url(${item?.postInfo[0]?.postImagePath})`,
                        }}
                      ></div>
                    </div>
                  ) : (
                    <>
                      <video
                        className="videoxs"
                        id={item?.postInfo[0]?.postImagePath}
                        key={item?.postInfo[0]?.postImagePath}
                        // onClick={handlePlayVideo}
                        // onMouseEnter={event => event.target.play()}
                        // onBlur={event => event.target.pause()}
                        controls
                        controlsList="nodownload"
                      >
                        <source
                          src={item?.postInfo[0]?.postImagePath}
                          type="video/mp4"
                        />
                      </video>
                    </>
                  )}
                </>
              )}
              <div className="col-sm-12">
                <small
                  className="mt-1 c_pointer recent_post_view_detais text-dark"
                  onClick={() => handleModalShow(item?._id)}
                >
                  View Details
                </small>
              </div>
            </div>
          </div>
        </div>
      ))}
      {isPostModal && (
        <SinglePost
          isView={isPostModal}
          postid={postId}
          closeBtn={() => setIsPostModal(false)}
        />
      )}
    </div>
  );
}

export default NewsList;
