import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import prof1 from "./assets/web_img/choto_logo_1.png"
// import khagerKolom from "../../assets/ad_img/khagerKolom.jpeg"

import moment from "moment";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { config } from "../../../constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import ManagementLeftNav from "../../components/Navbars/ManagementLeftNav";
import ReadMore from "../../components/Readmore";
import ReplyComments from "../../components/Replycomments";
import { FileViewer } from "../../components/AppComponents/Helpers";
import { YouTubeEmbed } from "react-social-media-embed";
import { useDispatch } from "react-redux";
import {
  deleteRequestHandler,
  getRequestHandler2,
  patchRequestHandler,
} from "../../redux/reducers/requestHandler.reducer";
import { Button } from "@mui/material";

const likePost = config.url.API_URL + "social/like";
const getLanguageUrl = config.url.API_URL + "language";
const getSubDomainUrl = config.url.API_URL + "postsubcategory/domains";
const getPostsUrl = config.url.API_URL + "posts/multiplefilters?";
const getCommentsUrl = config.url.API_URL + "social/getcomments?";
const replyLikeCommentUrl = config.url.API_URL + "social/replylike";
const commentPost = config.url.API_URL + "social/comments";
const getDomainUrl = config.url.API_URL + "postCategory/";
const getSubDomainsUrl = config.url.API_URL + "postSubCategory/";
const postUpdateUrl = config.url.API_URL + "posts/update/";

function AdminPostManagement(props) {
  // variables to store posts, domain, subdomain and language
  const [posts, setPosts] = useState([]);
  const [domains, setDomains] = useState([]);
  const [subDomains, setSubDomains] = useState([]);
  const [filters] = useState({});
  const [singlePost, setSinglePost] = useState([]);
  const [allComments, setAllComments] = useState([]);
  // const [comment, setComment] = useState("")
  // const [replyComment, setReplyComment] = useState("")
  const [commentSwitch, setCommentSwitch] = useState(false);
  const [comment1, setComment1] = useState("");
  const [replyShow, setReplyShow] = useState(false);
  const [comValue, setComValue] = useState("");
  const [onlySubDom, setOnlySubDom] = useState([]);
  const [editActive, setEditActive] = useState(false);
  const [postUpdateValues, setPostUpdateValues] = useState({});

  const [checkedLanguage, setCheckedLanguage] = useState([]);
  const [checkedSubD, setCheckedSubd] = useState([]);
  const [language, setLanguage] = useState([]);
  const [sortValue, setSortValue] = useState("newf");
  const [filteredItems, setFilteredItems] = useState("");
  const [skip, setSkip] = useState(0);
  const dispatch = useDispatch();
  // needable variables
  // (userDetails, token)
  const [modalShow, setModalShow] = useState(false);
  const [domainId] = useState("");
  const currentUser =
    typeof window !== "undefined"
      ? JSON.parse(localStorage.getItem("currentuser"))
      : "";
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const [toggleHandler, setToggleHangdler] = useState({});

  // const navigate = useNavigate()

  // useEffect(() => {
  //   const user =
  //     typeof window !== "undefined"
  //       ? JSON.parse(localStorage.getItem("currentUser"))
  //       : ""

  //   if (user?.userRole !== "1010") navigate("/admin/")
  // }, [])

  // functions
  // getting all comments
  const getAllComments = async () => {
    const response = await axios.get(getCommentsUrl + `id=${singlePost._id}`);
    setAllComments(response?.data?.responseData);
    console.log("allcomments>>", response);
  };

  // getting domains
  const getDomain = async (e) => {
    const response = await axios.get(getDomainUrl);
    setDomains(await response?.data?.responseData);
    console.log("creator page domains", response);
  };

  // getting languages
  const getLanguage = async (e) => {
    try {
      const response = await axios.get(getLanguageUrl);
      setLanguage(await response?.data?.responseData);
    } catch (err) {
      console.error(err);
    }
  };

  // get subdomains if any domain selected
  const getSubDomain = async (e) => {
    try {
      const response = await axios.get(getSubDomainUrl);
      setSubDomains(await response?.data?.responseData);
    } catch (err) {
      console.error(err);
    }
  };

  // get subdomains if any domain selected
  const getSubDomains = async (e) => {
    try {
      const response = await axios.get(getSubDomainsUrl);
      setOnlySubDom(await response?.data?.responseData);
    } catch (err) {
      console.error(err);
    }
  };

  const checkedArr = (isChecked, value, vari, fun) => {
    if (isChecked) return fun((vari) => [...vari, value]);

    let arr = vari;

    if (arr.indexOf(value) !== -1) arr.splice(arr.indexOf(value), 1);
    fun(arr);
    handleSearch();
  };

  const handleFilterChange = (e) => {
    if (e.target.name === "language")
      checkedArr(
        e.target.checked,
        e.target.value,
        checkedLanguage,
        setCheckedLanguage
      );
    if (e.target.name === "subdomain")
      checkedArr(e.target.checked, e.target.value, checkedSubD, setCheckedSubd);
  };

  const handleToggle = (key) => {
    if (!toggleHandler[key])
      setToggleHangdler((toggleHandler) => ({ ...toggleHandler, [key]: true }));

    if (toggleHandler[key] === true)
      setToggleHangdler((toggleHandler) => ({
        ...toggleHandler,
        [key]: false,
      }));
  };

  const handleSearch = (e) => {
    console.log("hit");
    console.log(sortValue);
    const lanQuery = checkedLanguage
      ?.map((k) => `lang=${encodeURIComponent(k)}`)
      .join("&");
    const subdQuery = checkedSubD
      ?.map((k) => `sd=${encodeURIComponent(k)}`)
      .join("&");
    const queryString = lanQuery.concat(`&sort=${sortValue}`, `&${subdQuery}`);
    setFilteredItems(queryString);
  };

  // getting creator posts
  // getting filtered posts
  const getAllPosts = async () => {
    setPosts([]);
    const url = `admin=true` + filteredItems;
    // const url = `token=${token}&status=active` + filteredItems
    console.log("url=", url);
    let req = {
      params: url,
      url: "getPost",
    };
    const response = await dispatch(getRequestHandler2(req));
    setPosts(response?.data?.responseData);
  };

  const getSkipPosts = async () => {
    const url = `token=${token}&skip=${skip}&status=active` + filteredItems;
    let req = {
      params: url,
      url: "getPost",
    };
    const response = await dispatch(getRequestHandler2(req));
    console.log("getSkipPost", skip, response);
    if (posts.length > 0)
      setPosts((posts) => [...posts, ...response?.data?.responseData]);
  };

  const handleScroll = (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;

    if (offsetHeight + scrollTop > scrollHeight - 200) {
      setSkip(posts.length);

      // console.log('hit')
    }
  };

  //selected handle change
  // const handleChange = (e) => {
  //   setFilters((filters) => ({ ...filters, [e.target.name]: e.target.value }))
  //   // console.log(filters)
  // }

  //handle domain change
  // const handleDomainChange = (e) => {
  //   handleChange(e)
  //   const index = e.target.selectedIndex
  //   const el = e.target.childNodes[index]
  //   const option = el.getAttribute("id")
  //   setDomainId(option)
  // }

  // open modal function
  const handleModalShow = (index, edit) => {
    edit === false ? setEditActive(false) : setEditActive(true);
    setModalShow(true);
    setSinglePost(posts[index]);
    // console.log("for single post", singlePost)
  };

  const handleClose = () => setModalShow(false);

  // reply comment
  const handleReplyCommentClick = (commentId, showName, index) => {
    setComment1("@" + showName);
    const k = "comment" + index;
    setCommentSwitch((values) => ({ ...values, [k]: index }));
  };

  const handleReplyCommentView = (index) => {
    const k = "comment" + index;
    setCommentSwitch((values) => ({ ...values, [k]: index }));
    setReplyShow(true);
  };

  // EDIT POST
  const handleEditChange = (e) => {
    setPostUpdateValues((postUpdateValues) => ({
      ...postUpdateValues,
      [e.target.name]: e.target.value,
    }));
    console.log(postUpdateValues);
  };

  // editpostsubmit
  const handlePostUpdate = async () => {
    // if (Object.keys(postUpdateValues).length === 0) return
    if (postUpdateValues?.length < 1) return console.log("no update");
    console.log("handlePostUpdate", postUpdateValues);
    let req = {
      data: { ...postUpdateValues, pid: singlePost?._id },
      url: "editPosts",
    };
    const response = await dispatch(patchRequestHandler(req));
    // const response = await axios.patch(
    //   postUpdateUrl + singlePost._id,
    //   postUpdateValues
    // )
    console.log("handlePostUpdate2", response);

    if (response.data.ack !== 1) return setPostUpdateValues({});

    setPostUpdateValues({});
    setEditActive(false);
    setModalShow(false);
    getAllPosts();
  };

  const deletePost = async (postId) => {
    const result = window.confirm("Are you sure?");
    if (!result) return;
    let req = {
      params: {
        pid: postId,
      },
      url: "deletePost",
    };
    await dispatch(deleteRequestHandler(req));
    // await axios.delete(deletePostUrl + postId)
    getAllPosts();
  };

  useEffect(() => {
    getDomain();
    getSubDomain();
    getLanguage();
    getSubDomains();
  }, []);

  useEffect(() => {
    getAllPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredItems]);

  useEffect(() => {
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedLanguage, checkedSubD, sortValue]);

  useEffect(() => {
    getSkipPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip]);

  useEffect(() => {
    if (domainId) getSubDomain();
  }, [domainId]);

  useEffect(() => {
    if (modalShow) getAllComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalShow]);

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-2">
            <ManagementLeftNav />
          </div>
          {/* <div className='navbar'>Nav bar</div> */}
          <div className="bg_allp col-lg-10 mt-4">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <div className="bg-white search_left">
                    <h6 className="domain_nav">Selections</h6>

                    {/* <div className="sort_item">
                                    <div className="check_items">
                                        <input type="checkbox" name="fav" id="" value={myFav} onChange={handleFavChange} /> My Favorites
                                    </div>
                                </div> */}
                    {/* <div className="c_pointer" onClick={() => setMyFav(true)}>My Favorites</div> */}

                    <h6 className="domain_nav">Language</h6>
                    {language.length > 0 &&
                      language?.map((item, index) => (
                        <div className="sort_item" key={index}>
                          <div className="check_items">
                            <input
                              type="checkbox"
                              name="language"
                              id=""
                              value={item.language}
                              onChange={handleFilterChange}
                              defaultChecked={item.checked}
                            />{" "}
                            {item.language}
                          </div>
                        </div>
                      ))}

                    <h6 className="domain_nav">Sort</h6>
                    <div className="sort_item">
                      <div className="check_items">
                        <input
                          type="radio"
                          value="newf"
                          name="sort"
                          onChange={(e) => setSortValue(e.target.value)}
                          checked={sortValue === "newf"}
                          defaultChecked
                        />{" "}
                        Newest First
                      </div>
                      <div className="check_items">
                        <input
                          type="radio"
                          value="oldf"
                          name="sort"
                          onChange={(e) => setSortValue(e.target.value)}
                          checked={sortValue === "oldf"}
                        />{" "}
                        Oldest First
                      </div>
                      <div className="check_items">
                        <input
                          type="radio"
                          value="ml"
                          name="sort"
                          onChange={(e) => setSortValue(e.target.value)}
                          checked={sortValue === "ml"}
                        />{" "}
                        Most Liked
                      </div>
                      <div className="check_items">
                        <input
                          type="radio"
                          value="mc"
                          name="sort"
                          onChange={(e) => setSortValue(e.target.value)}
                          checked={sortValue === "mc"}
                        />{" "}
                        Most Commented
                      </div>
                    </div>

                    <h6 className="sub_domain">Sub Domain</h6>

                    {subDomains?.map((item, index) => {
                      return (
                        <div key={index} className="domains_body">
                          <h6
                            className="domain_nav d-flex justify-content-between align-items-center bg_red"
                            onClick={() => handleToggle(item.postCategory)}
                          >
                            {item?.postCategory}
                            <div>
                              {toggleHandler[item?.postCategory] === false
                                ? "+"
                                : "-"}
                            </div>
                          </h6>
                          <div
                            className={
                              toggleHandler[item?.postCategory] === true
                                ? "category_box active"
                                : "category_box"
                            }
                          >
                            {item?.subDomain.length > 0
                              ? item?.subDomain?.map((subd, key) => {
                                  return (
                                    <div className="check_items" key={key}>
                                      <input
                                        type="checkbox"
                                        name="subdomain"
                                        id=""
                                        value={subd?.postSubCategory}
                                        onChange={handleFilterChange}
                                      />{" "}
                                      {subd.postSubCategory}
                                    </div>
                                  );
                                })
                              : ""}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  className="col-lg-9 post_area_infinite scrollBar"
                  onScroll={handleScroll}
                >
                  <div className="row">
                    {posts?.length > 0 &&
                      posts?.map((item, index) => {
                        return (
                          <div className="col-md-6 col-12" key={index}>
                            <div className="post_box">
                              <div className="post-image-box">
                                <div className="d-flex justify-content-between align-items-center bb_1">
                                  {item?.user_info && (
                                    <div className="user_info">
                                      <Link
                                        to={"/" + item?.user_info[0]?.userName}
                                        className="posted-user mb-2"
                                      >
                                        <div className="posted-user-d">
                                          <div className="pro_img">
                                            <img
                                              src={
                                                item?.user_info[0]
                                                  ?.profilePhoto ||
                                                `/assets/web_img/choto_logo_1.png`
                                              }
                                              alt="members profile"
                                            />
                                          </div>
                                          <div className="post-user-name">
                                            {item?.user_info[0] &&
                                              item?.user_info[0].showName}
                                            <div className="momment_timex">
                                              {moment(item?.createdAt).fromNow(
                                                true
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  )}

                                  <div className="post_actions items_center c_pointer">
                                    <FontAwesomeIcon
                                      icon={faEllipsis}
                                      className="cl_gr"
                                    ></FontAwesomeIcon>
                                    <div className="dropdown_post_actions shadow_1 br_5">
                                      <div
                                        onClick={() =>
                                          handleModalShow(index, true)
                                        }
                                      >
                                        {" "}
                                        Edit Post
                                      </div>

                                      <div
                                        onClick={() => deletePost(item?._id)}
                                      >
                                        Delete Post
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="main-post_box main_Pre">
                                  {/* <hr /> */}
                                  {item?.url && (
                                    <YouTubeEmbed
                                      url={item?.url}
                                      width={"100%"}
                                      height={220}
                                    />
                                  )}

                                  {item?.postInfo[0] ? (
                                    <FileViewer
                                      link={
                                        item?.postInfo[0]?.postImagePath || ""
                                      }
                                    />
                                  ) : (
                                    <>
                                      <pre className="p-2">
                                        {item?.postCategory === "Normal" ? (
                                          ""
                                        ) : (
                                          <div className="mb-3 fs_tt">
                                            {item?.postTitle}
                                          </div>
                                        )}

                                        <ReadMore className="view-post-des mt-2 fs_13">
                                          {item?.postDescription &&
                                            item?.postDescription}
                                        </ReadMore>
                                      </pre>
                                    </>
                                  )}
                                </div>

                                <div
                                  className="d-flex justify-content-end  mb-2"
                                  onClick={() => handleModalShow(index, false)}
                                >
                                  <div className="view_more  p-1 px-2 c_pointer">
                                    View Details
                                  </div>
                                </div>

                                <div className="comment_bottom d-flex gap-3 justify-content-end">
                                  <div className="d-flex">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      enableBackground="new 0 0 24 24"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      fill="#6ea20b"
                                    >
                                      <g>
                                        <rect
                                          fill="none"
                                          height="24"
                                          width="24"
                                        />
                                      </g>
                                      <g>
                                        <g>
                                          <circle cx="4" cy="14" r="2" />
                                          <path d="M1.22,17.58C0.48,17.9,0,18.62,0,19.43V21l4.5,0v-1.61c0-0.83,0.23-1.61,0.63-2.29C4.76,17.04,4.39,17,4,17 C3.01,17,2.07,17.21,1.22,17.58z" />
                                          <circle cx="20" cy="14" r="2" />
                                          <path d="M22.78,17.58C21.93,17.21,20.99,17,20,17c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V21l4.5,0v-1.57 C24,18.62,23.52,17.9,22.78,17.58z" />
                                          <path d="M16.24,16.65c-1.17-0.52-2.61-0.9-4.24-0.9c-1.63,0-3.07,0.39-4.24,0.9C6.68,17.13,6,18.21,6,19.39V21h12v-1.61 C18,18.21,17.32,17.13,16.24,16.65z" />
                                          <path d="M9,12c0,1.66,1.34,3,3,3s3-1.34,3-3c0-1.66-1.34-3-3-3S9,10.34,9,12z" />
                                          <path d="M2.48,10.86C2.17,10.1,2,9.36,2,8.6C2,6.02,4.02,4,6.6,4c2.68,0,3.82,1.74,5.4,3.59C13.57,5.76,14.7,4,17.4,4 C19.98,4,22,6.02,22,8.6c0,0.76-0.17,1.5-0.48,2.26c0.65,0.31,1.18,0.82,1.53,1.44C23.65,11.1,24,9.88,24,8.6 C24,4.9,21.1,2,17.4,2c-2.09,0-4.09,0.97-5.4,2.51C10.69,2.97,8.69,2,6.6,2C2.9,2,0,4.9,0,8.6c0,1.28,0.35,2.5,0.96,3.7 C1.31,11.68,1.84,11.17,2.48,10.86z" />
                                        </g>
                                      </g>
                                    </svg>
                                    {item?.totalLikes} Likes
                                  </div>
                                  <div className="">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      enableBackground="new 0 0 24 24"
                                      height="24px"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      fill="#2570c3"
                                    >
                                      <g>
                                        <rect
                                          fill="none"
                                          height="24"
                                          width="24"
                                        />
                                        <rect
                                          fill="none"
                                          height="24"
                                          width="24"
                                        />
                                      </g>
                                      <g>
                                        <g>
                                          <polygon points="22,9 22,7 20,7 20,9 18,9 18,11 20,11 20,13 22,13 22,11 24,11 24,9" />
                                          <path d="M8,12c2.21,0,4-1.79,4-4s-1.79-4-4-4S4,5.79,4,8S5.79,12,8,12z" />
                                          <path d="M8,13c-2.67,0-8,1.34-8,4v3h16v-3C16,14.34,10.67,13,8,13z" />
                                          <path d="M12.51,4.05C13.43,5.11,14,6.49,14,8s-0.57,2.89-1.49,3.95C14.47,11.7,16,10.04,16,8S14.47,4.3,12.51,4.05z" />
                                          <path d="M16.53,13.83C17.42,14.66,18,15.7,18,17v3h2v-3C20,15.55,18.41,14.49,16.53,13.83z" />
                                        </g>
                                      </g>
                                    </svg>
                                    {item?.totalComment} Comments
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal show={modalShow} size="lg" onHide={handleClose}>
            <Button variant="contained" onClick={handleClose} color="error">
              Close
            </Button>
            <Modal.Body className="pd_0">
              <div className="container-fluid min_h">
                <div className="row min_h">
                  <div className="col-lg-6">
                    {singlePost?.postInfo && singlePost?.postInfo[0] ? (
                      singlePost?.postInfo[0]?.postImagePath.slice(
                        (Math.max(
                          0,
                          singlePost?.postInfo[0]?.postImagePath.lastIndexOf(
                            "."
                          )
                        ) || Infinity) + 1
                      ) === "mp4" ? (
                        <>
                          <video
                            width="100%"
                            className="mt-2"
                            controls
                            controlsList="nodownload"
                          >
                            <source
                              src={singlePost?.postInfo[0]?.postImagePath}
                              type="video/mp4"
                            />
                          </video>
                        </>
                      ) : (
                        <img
                          src={singlePost?.postInfo[0]?.postImagePath}
                          alt="posts"
                          className="post_img_1"
                        />
                      )
                    ) : (
                      ""
                    )}

                    <pre className="mt-4 px_15 hola fs_16">
                      {singlePost?.postCategory === "Normal" ? (
                        ""
                      ) : (
                        <div className="mb-3 fs_tt">
                          {singlePost?.postTitle}
                        </div>
                      )}

                      <ReadMore className="view-post-des mt-2 fs_13">
                        {singlePost?.postDescription}
                      </ReadMore>
                    </pre>
                  </div>

                  <div className="col-lg-6 bl_1">
                    <div className="d-flex justify-content-between align-items-center bb_1">
                      <div className="user_info">
                        <div className="posted-user mb-2">
                          {singlePost?.user_info && (
                            <div className="posted-user-d mt-2">
                              <div className="pro_img">
                                <img
                                  src={
                                    singlePost?.user_info[0]?.profilePhoto ||
                                    `/assets/web_img/choto_logo_1.png`
                                  }
                                  alt="members profile"
                                />
                              </div>
                              <div className="post-user-name">
                                {singlePost?.user_info &&
                                  singlePost?.user_info[0]?.showName}
                                <div className="fs_12">
                                  {moment(singlePost?.createdAt).fromNow()}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* comments and edits */}
                    {editActive ? (
                      <>
                        <div className="w-100">
                          <select name="language" onChange={handleEditChange}>
                            <option className="t_cap" value="">
                              Language
                            </option>
                            {language?.length > 0 &&
                              language?.map((item, index) => {
                                return (
                                  <option
                                    className="t_cap"
                                    value={item?.language}
                                    key={index}
                                    selected={
                                      item?.language === singlePost.language
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {item?.language}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="w-100">
                          <select
                            name="postCategory"
                            onChange={handleEditChange}
                          >
                            <option className="t_cap" value="">
                              Domain
                            </option>

                            {domains?.length > 0 &&
                              domains?.map((items, index) => {
                                return (
                                  <option
                                    className="t_cap"
                                    id={items._id}
                                    value={items.postCategory}
                                    key={index}
                                    selected={
                                      items.postCategory ===
                                      singlePost.postCategory
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {items.postCategory}
                                  </option>
                                );
                              })}
                          </select>
                        </div>

                        <div className="w-100">
                          <select
                            name="postSubDomain"
                            onChange={handleEditChange}
                          >
                            <option className="t_cap" value="">
                              Sub domain
                            </option>
                            {onlySubDom?.length > 0 &&
                              onlySubDom?.map((item, index) => {
                                return (
                                  <option
                                    className="t_cap"
                                    value={item?.postSubCategory}
                                    key={index}
                                    selected={
                                      item?.postSubCategory ===
                                      singlePost.postSubDomain
                                        ? "selected"
                                        : ""
                                    }
                                  >
                                    {item?.postSubCategory}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <Button
                          variant="contained"
                          // type="button"
                          onClick={handlePostUpdate}
                          className="m-3"
                        >
                          Update
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleClose}
                          color="error"
                        >
                          Close
                        </Button>
                      </>
                    ) : (
                      <>
                        {/* comment area */}
                        <div className="comments_Area">
                          <div className="row">
                            <div className="col-lg-12">
                              {allComments?.length > 0 &&
                                allComments?.map((item, index) => {
                                  return (
                                    <div
                                      className="commentsDiv mt-3"
                                      key={index}
                                    >
                                      <div className="pro_img_com">
                                        {item?.referenceUserId ? (
                                          <div className="d-flex ">
                                            <img
                                              src={
                                                item?.referenceUserId
                                                  ?.profilePhoto ||
                                                `/assets/web_img/choto_logo_1.png`
                                              }
                                              alt="members profile"
                                            />
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="commentsView">
                                        <div className="commentBox">
                                          <pre className="fs-14 viewD">
                                            <span className="user_name">
                                              {item?.referenceUserId.showName}:
                                            </span>
                                            {item?.messageText
                                              ? item?.messageText
                                              : ""}
                                          </pre>
                                          <div className="d-flex gap-2">
                                            <div
                                              className="h-underline"
                                              title="click to reply"
                                              onClick={() =>
                                                handleReplyCommentClick(
                                                  item?._id,
                                                  item?.referenceUserId
                                                    .firstName,
                                                  item?.referenceUserId
                                                    .lastName,
                                                  index
                                                )
                                              }
                                            >
                                              {item?.replyCommentCount
                                                ? item?.replyCommentCount
                                                : 0}{" "}
                                              reply
                                            </div>
                                          </div>
                                        </div>
                                        {commentSwitch["comment" + index] ===
                                        index ? (
                                          <ReplyComments
                                            show={replyShow}
                                            commentid={item?._id}
                                            tokenuser={
                                              currentUser?.profilePhoto
                                            }
                                            usertag={comment1}
                                            postid={singlePost?._id}
                                            postcreatorid={
                                              singlePost?.userinfo?._id
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}

                                        {commentSwitch["comment" + index] ===
                                        index ? (
                                          <div className="d-flex gap-2">
                                            <div
                                              className="comment_count"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                handleReplyCommentView(index)
                                              }
                                            >
                                              {/* View more replies (wip), */}
                                              View more replies,
                                            </div>
                                            <div
                                              className="comment_count close_comment"
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                setCommentSwitch((values) => ({
                                                  ...values,
                                                  ["comment" + index]: "",
                                                }))
                                              }
                                            >
                                              Close replies
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="comment_count mt-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleReplyCommentView(index)
                                            }
                                          >
                                            View recent replies
                                          </div>
                                        )}
                                        {/* <div className="h-underline replyComment" onClick={() => handleReplyCommentView(index)}>view recent replies</div> */}
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* end comments and edits  */}
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default AdminPostManagement;
