import api from "../../config/api";
import Network from "../../helpers/network";
export const ACTION_GET_BLOGS_REQUEST = "ACTION_GET_BLOGS_REQUEST";
export const ACTION_GET_BLOGS_SUCCESS = "ACTION_GET_BLOGS_SUCCESS";
export const ACTION_GET_BLOG_SUCCESS = "ACTION_GET_BLOG_SUCCESS";
export const ACTION_GET_BLOGS_FAILD = "ACTION_GET_BLOGS_FAILD";
const initialState = {
  data: null,
  isError: false,
  isProcessing: null,
  isSuccess: null,
  message: null,
  selectedBlog: null,
};
export const BlogReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_BLOGS_REQUEST:
      return {
        ...state,
        isError: false,
        isProcessing: true,
        //    message :
      };
    case ACTION_GET_BLOGS_SUCCESS:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        data: action.payload,
        isSuccess: true,
        //    message :
      };
    case ACTION_GET_BLOGS_FAILD:
      return {
        ...state,
        isError: true,
        isProcessing: false,
        data: null,
        isSuccess: false,
        //    message :
      };
    case ACTION_GET_BLOG_SUCCESS:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        selectedBlog: action.payload,
        isSuccess: true,
        //    message :
      };
    default:
      return state;
  }
};

export const getBlogs =
  (page = 0) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          dispatch({ type: ACTION_GET_BLOGS_REQUEST });
          Network.get(api.getBlogs)
            .then((res) => {
              dispatch({
                type: ACTION_GET_BLOGS_SUCCESS,
                payload: res?.data?.responseData,
              });
              resolve({
                status: true,
                message: res?.data?.message,
              });
            })
            .catch((err) => {
              console.log("error", err);
              dispatch({ type: ACTION_GET_BLOGS_FAILD });
              reject(err);
            });
        } catch (err) {
          console.log("error", err);
          dispatch({ type: ACTION_GET_BLOGS_FAILD });
          reject(err);
        }
      });
    };

export const getBlogData =
  (slug = null) =>
    (dispatch) => {
      console.log("loadData>>", slug);
      return new Promise(async (resolve, reject) => {
        try {
          //   let kk;
          //   const tq = token ? `token=${token}` : "";

          //   split[1] === "news"
          //     ? (kk = NEWS_URL + `get?slug=${slug}&${tq}`)
          //     : (kk = API_URL + `getblog?slug=${slug}&${tq}`);
          const response = await Network.get(api.getBlogs, { slug: slug });
          console.log("setBlog>>>>>", response, response.data?.responseData);
          dispatch({
            type: ACTION_GET_BLOG_SUCCESS,
            payload: response.data?.responseData[0],
          });
          resolve(response);
          // setBlog(response.data?.responseData[0]);
        } catch (err) {
          console.error(err);
          reject(err);
        }
      });
    };
