import React, { useState } from 'react'
import ReplyIcon from '@mui/icons-material/Reply'
import Tooltip from '@mui/material/Tooltip'
import CustomizedSnackbars from '../Loaders/Snuckbars'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'

import { BootstrapDialogTitle } from '../../../_helpers/Material'
import { FileViewer, UsersProfile } from '../AppComponents/Helpers'
import ReadMore from '../Readmore'
import SimpleCircle from '../Loaders/SimpleCircle'
import { YouTubeEmbed } from 'react-social-media-embed'
import { useDispatch } from 'react-redux'
import { postRequestHandler } from '../../redux/reducers/requestHandler.reducer'


const token = typeof window !== "undefined" ? localStorage.getItem('token') : ""
const currentUser = typeof window !== "undefined" ? JSON.parse(localStorage.getItem("currentuser")) : ""

export default function PostComponents() {
    return (
        <div>PostComponents</div>
    )
}

export const PostDescriptionComponent = ({ description, keyword }) => {
    return (
        <pre>
            <ReadMore>
                {description?.replace(/(<([^>]+)>)/ig, '') || ''}
            </ReadMore>

            <div className="mt-2 text-primary">
                {keyword || ''}
            </div>
        </pre>
    )
}

export const SharePostDialog = (props) => {
    const { post, close } = props
    const dispatch = useDispatch()
    const [open, setOpen] = useState(true)
    const [valuesx, setValuesx] = useState({})
    const [isSubmit, setIsSubmit] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [isError, setIsError] = useState(false)
    const [msgs, setmsgs] = useState("")

    const handleClose = () => {
        setOpen(false)
        close()
    }

    const handleShareTextChange = e => setValuesx(prev => ({ ...prev, [e.target.name]: e.target.value }))

    const timeOut = () => {
        setTimeout(() => {
            handleClose()
            if(props?.getpost) props?.getpost()
            if(props?.isNavigate) props?.isNavigate(-1)
        }, [3000])
    }

    const handleSubmit = async () => {
        try {
            setIsSubmit(true)
            const tokenx = typeof window !== "undefined" ? localStorage.getItem('token') : ""
            const fullName = post?.user_info[0]?.showName
            const data = {
                "shared": {
                    referenceUserId: tokenx,
                    postType: 'shared',
                    postTitle: post?.postTitle || 'Default',
                    postDescription: valuesx.postDescription || '',
                    postCategory: post?.postCategory,
                    postSubDomain: post?.postSubDomain,
                    language: post?.language,
                    // share 
                    videoThumbnil: post?.videoThumbnil,
                    shareUserName: post?.user_info[0]?.userName,
                    shareUserId: post?.shareUserId,
                    sharedPostId: post?.sharedPostId,
                    shareGalleryInfo: post?.shareGalleryInfo,
                    shareDescription: post?.shareDescription,
                    populator: '',
                    shareFullName: post?.shareFullName,
                    shareProfile: post?.shareProfile
                },
                "normal": {
                    referenceUserId: token,
                    postType: 'shared',
                    postTitle: post?.postTitle || 'Default',
                    postDescription: valuesx.postDescription || '',
                    postCategory: post?.postCategory,
                    postSubDomain: post?.postSubDomain,
                    language: post?.language,
                    videoThumbnil: post?.videoThumbnil,

                    // share 
                    shareUserName: post?.user_info[0]?.userName,
                    shareUserId: post?.user_info[0]?._id,
                    sharedPostId: post?._id,
                    shareGalleryInfo: post?.postInfo[0] ? post?.postInfo[0]?.postImagePath : '',
                    shareDescription: post?.postDescription,
                    populator: '',
                    shareFullName: fullName,
                    shareProfile: post?.user_info[0]?.profilePhoto
                },
                "org": {
                    referenceUserId: token,
                    postType: 'shared',
                    pageId: post?.pageId,
                    postTitle: post?.postTitle || 'Default',
                    postDescription: valuesx.postDescription || '',
                    postCategory: post?.postCategory,
                    postSubDomain: post?.postSubDomain,
                    language: post?.language,
                    videoThumbnil: post?.videoThumbnil,
                    // share 
                    shareUserName: post?.user_info[0]?.userName,
                    shareUserId: post?.user_info[0]?._id,
                    sharedPostId: post?._id,
                    shareGalleryInfo: post?.postInfo[0] ? post?.postInfo[0]?.postImagePath : '',
                    shareDescription: post?.postDescription,
                    populator: '',
                    shareFullName: post?.org_info[0]?.pageName,
                    shareProfile: post?.org_info[0]?.profilePhoto
                }
            }
            let reqData = {}

            reqData = post?.pageId ? { ...data.org, embedType: post?.embedType, url: post?.url }
                : post?.postType === 'shared' ?
                    { ...data.shared, embedType: post?.embedType, url: post?.url }
                    : { ...data.normal, embedType: post?.embedType, url: post?.url }

            let req = {
                data: reqData,
                url: 'sharePost'
            }
            const response = await dispatch(postRequestHandler(req))
            setIsSubmit(false)
            // kimkardasian()
            if (response?.data?.ack === 0) {
                setIsError(true)
                setmsgs(response?.data?.message)
                return timeOut()
            }
            setIsSuccess(true)
            setmsgs("Post shared successfully")
            timeOut()
            // window.location.reload()

        } catch (error) {
            console.error(error)
            setIsError(true)
            setmsgs("Share failed due to technical error")
        }
    }

    return (
        <Dialog
            fullWidth={"sm"}
            maxWidth={"sm"}
            open={open}
            onClose={handleClose}
        >
            <BootstrapDialogTitle className="fs-500 text-center" onClose={handleClose}>
                <strong>Write post</strong>
            </BootstrapDialogTitle>
            <DialogContent className='kkmodix'>
                <UsersProfile user={currentUser} isshare={true} />
            </DialogContent>
            <DialogContent>
                <textarea
                    className="form-control"
                    rows="2"
                    placeholder="Write Something"
                    name="postDescription"
                    onChange={handleShareTextChange}
                    value={valuesx.postDescription || ""}
                ></textarea>

                <div className="share_post_preview">
                    {post?.url && <YouTubeEmbed
                        url={post?.url}
                        width={"100%"}
                        height={280}
                    />
                    }
                    {post?.postType === 'shared' ?
                        (post?.shareGalleryInfo && <FileViewer link={post?.shareGalleryInfo} />) :
                        (<div>
                            {post?.postInfo[0] && <FileViewer link={post?.postInfo[0]?.postImagePath} />}
                        </div>)
                    }
                    <div className='py-2 px-2'>
                        <UsersProfile user={{ userName: post?.user_info[0]?.userName, showName: post?.user_info[0]?.userName }} />
                        {(post?.shareDescription || post?.postDescription) &&
                            <div className='post_description p-2'>
                                <PostDescriptionComponent description={post?.postType === 'shared' ? post?.shareDescription : post?.postDescription} keyword={post?.keywords2} />
                            </div>}
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                {isSubmit ?
                    <button type="button" className="btn btn-primary" >
                        <SimpleCircle />
                    </button>
                    : <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                        Post
                    </button>
                }
                {!isSubmit && <Button onClick={handleClose}>Close</Button>}
            </DialogActions>

            {isSuccess && <CustomizedSnackbars type={0} msg={msgs} sclose={() => setIsSuccess(false)} />}
            {isError && <CustomizedSnackbars type={1} msg={msgs} sclose={() => setIsError(false)} />}
        </Dialog>
    )
}

export const SharePostAsFeed = (props) => {
    const { post, getpost, isNavigate, setShow, component} = props
    const [isOpen, setIsOpen] = useState(false)
   
    return (
        <>  {component ?
            <Tooltip
                title="share as Post"
                onClick={() => {
                    if(setShow) setShow(true)
                    setIsOpen(true)
                }}
                className='c_pointer'
                arrow
            >
                {component}
            </Tooltip>
            :

            <Tooltip title="share as post" arrow>
                <div className="like commentx share_icon"
                    onClick={() => {
                        if(setShow) setShow(true)
                        setIsOpen(true)
                    }}
                    >
                    <ReplyIcon />
                </div>
            </Tooltip>
}
            {isOpen && <SharePostDialog isNavigate={isNavigate} getpost={getpost} post={post} close={() => setIsOpen(false)} />}
        </>
    )
}


export const CreationPost = (post) => {
    return (
        <></>
    )
}

export const SinglePostPageComponent = (props) => {

}