import React, { useState } from "react"
import ImageCropper from "../../components/ImageCropper"
import { SubmitButton } from "../../components/AppComponents/Helpers"
import { useDispatch } from "react-redux"
import { postRequestHandler } from "../../redux/reducers/requestHandler.reducer"


function OrganisationImageUpload(props) {
	console.log("OrganisationImageUpload", props)
	const { isImageCropper } = props
	const [fileInputState, setFileInputState] = useState("")
	const [previewSource, setPreviewSource] = useState("")
	const [selectedFile, setSelectedFile] = useState()
	// const [isError, setIsError] = useState(false)
	// const [errMsg, setErrMsg] = useState("")
	const [isSubmit, setIsSubmit] = useState(false)
	const dispatch = useDispatch()

	const handleFileInputChange = (e) => {
		const file = e.target.files[0]
		previewFile(file)
		setSelectedFile(file)
		setFileInputState(e.target.value)
	}

	const previewFile = (file) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onloadend = () => {
			setPreviewSource(reader.result)
		}
	}

	const handleSubmitFile = (e) => {
		e.preventDefault()
		console.log("selec =>", selectedFile)
		if (!selectedFile) return
		const reader = new FileReader()
		reader.readAsDataURL(selectedFile)
		reader.onloadend = () => {
			uploadImage(selectedFile)
			setSelectedFile("")
		}
		reader.onerror = () => {
			console.error("AHHHHHHHH!!")
			// setIsError(true)
			// setErrMsg("something went wrong!")
		}
	}

	const uploadImage = async (image) => {
		try {
			setIsSubmit(true)
			var response = {}
			const formData = new FormData()
			formData.append("orgimages", image)
			let req = {
				data: formData,
				url: 'orgUpload'
			}
			response = await dispatch(postRequestHandler(req))
			props.isUploaded()
			props.setFileLink(response?.data?.responseData)
			setIsSubmit(false)
			setFileInputState("")
			setPreviewSource("")
			props.onHide()
		} catch (err) {
			console.error(err)
			// setIsError(true)
			// setErrMsg("Failed!. Something went wrong!")
		}
	}

	const closeModal = () => {
		setFileInputState("")
		setPreviewSource("")
		props.onHide()
	}

	return (
		<form onSubmit={handleSubmitFile} encType="multipart/formdata" className="form_modal br_5">

			<div className="form_image">
				<div className="form_preview_img">
					{previewSource && isImageCropper ?
						<ImageCropper
							src={previewSource}
							selectedFile={setSelectedFile}
						>
						</ImageCropper> : previewSource && <img src={previewSource} alt="modal" />
					}
				</div>
				

				<div className={previewSource ? "modal_image_input active" : "modal_image_input"}>
					{isImageCropper ?
						<div className="text-center max_width">
							We recommend a 256px by 256px image.
						</div>
						:
						<div className="text-center max_width">
							We recommend an image at least 400px wide and 400px tall.
						</div>
					}
					<div className="items_center">
						<label className="mt-3">
							{" "}
							Browse
							<input
								type="file"
								name="postImage"
								onChange={handleFileInputChange}
								value={fileInputState}
								required
							/>
						</label>
					</div>
				</div>
			</div>

			<div className="d-flex justify-content-end gap-3">
				{/* <button type="submit" className="mt-3 btn btn-primary">
						Upload
					</button> */}
				<SubmitButton css={"mt-3 btn btn-primary"} title={"upload"} issubmit={isSubmit} />
				<button
					type="button"
					className="mt-3 btn btn-danger"
					onClick={closeModal}
				>
					Cancel
				</button>
			</div>
		</form>
	)
}

export default OrganisationImageUpload
