import React, { useEffect, useState } from 'react'
import { CustomizedSnackbars, Footer, IndexNavbar, SimpleCircle } from '../../components'
import ProfileSettingsNav from '../../components/Settings/ProfileSettingsNav'
import { Button } from '@mui/material'
import { otpValidation } from '../../../FormValidationRule'
import { connect, useDispatch } from 'react-redux'
import { postReqManagementWithOutDispatch } from '../../redux/reducers/organizationReducer'
import { ACTION_EDIT_USER, getUsers } from '../../redux/reducers/authReducer'
import { useNavigate } from 'react-router-dom'

function EditProfile(props) {
  const { editUser } = props
  const [values, setValues] = useState({})
  const [error, setError] = useState({})
  const [inputfocus, setInputfocus] = useState({})
  const [isShow, setIsShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [msg, setMsg] = useState('')


  const usrId = typeof window !== "undefined" ? localStorage.getItem("token") : ""
  const handleChange = e => {
    if (isNaN(e.target.value)) return
    let err = otpValidation(e.target.value)
    setValues(prev => ({ ...prev, [e.target.name]: e.target.value }))
    setError(prev => ({ ...prev, [e.target.name]: err ? err : '' }))
  }

  const handleSubmit = async e => {
    try {
      let data = {
        id: usrId,
        sendall: true
      }
      setIsLoading(true)
      const response = await postReqManagementWithOutDispatch('sendCode', data)
      setIsLoading(false)
      if (response?.data?.ack === 1)
        setIsShow(true)

    } catch (err) {
      console.error(err)
    }
  }

  const handleSubmit2 = async e => {
    try {
      let data = {
        id: usrId,
        otp: values?.otp,
        status: /active/.test(editUser?.status?.toLowerCase()) ? 'tempd' : 'active'
      }
      setIsLoading(true)
      const response = await postReqManagementWithOutDispatch('accountActMang', data)
      setIsLoading(false)
      setValues({})
      if (response.data.ack === 1) return setMsg('Your account DeActivated successfully. Redirecting to login...')

    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (!msg) return
    setTimeout(() => {
      setMsg("")
      navigate("/auth/login/")
    }, [3000])
  }, [msg, navigate])

  // https://test-api.allphanes.com/api/users/getusers?_id=62acdb2c662a8545551be9e4&mode=1


  return (
    <>
      <IndexNavbar />
      <div className="container">
        <ProfileSettingsNav />
        <div className='text-center'>
          <div class="row bg_allp3 justify-content-center border_1 mt-4 border_r5 mb-4">
            <div class="col-lg-8">
              <div class="mt-3 mb-4 text-center">
                <h3>Deactivate Account</h3>
                <p>
                  Deactivate Account' will disable your access to Allphanes.
                  It will also disable access to all your content withing 30 days.
                  If there are subscribers to your posts, their subscriptions will
                  continue through the end of the month, but will not be renewed.
                </p>

                {isShow &&
                  <div className="row justify-content-center my-3">
                    <div className="col-lg-6">
                      <div className="w-100">
                        <label htmlFor="First Name" className="log-input-title">
                          Enter OTP{" "}
                        </label>

                        <input
                          type="num"
                          className="form-control form-control-xl mt-2"
                          autoComplete="off"
                          placeholder="Enter six digit otp here"
                          name="otp"
                          onFocus={(e) =>
                            setInputfocus((inputfocus) => ({
                              ...inputfocus,
                              [e.target.name]: true,
                            }))
                          }
                          onBlur={(e) =>
                            setInputfocus((inputfocus) => ({
                              ...inputfocus,
                              [e.target.name]: false,
                            }))
                          }
                          value={values?.otp || ''}
                          onChange={handleChange}
                          required
                        />

                        <div className="errors">
                          {!inputfocus?.otp && error?.otp}
                        </div>
                      </div>
                      <div className="mt-2 fs_choto cl_gray info">
                        Please enter the six digit OTP(one time password) sended to your phone or email.
                      </div>
                    </div>
                  </div>}
                {isLoading ?
                  <Button
                    class="btn btn-primary"
                    title='loading'
                  >
                    <SimpleCircle />

                  </Button>
                  : isShow ? error?.otp === '' ?
                    <Button
                      class="btn btn-primary"
                      title='click to submit'
                      onClick={handleSubmit2}
                    >Submit
                    </Button>
                    :
                    <Button
                      class="btn btn-primary"
                      title='click to submit'
                      disabled
                    >Submit
                    </Button>
                    :
                    <Button
                      class="btn btn-danger"
                      title='click to deactivate account'
                      onClick={handleSubmit}
                    >
                      {/active/.test(editUser?.status?.toLowerCase()) ? "Deactivate" : "Reactive"} Account
                    </Button>
                }
              </div>
            </div>
          </div>
        </div>
        {msg && <CustomizedSnackbars type={0} msg={msg} />}

      </div >
      <Footer />
    </>
  )
}

// export default 
EditProfile.loadData = (params, search) => async (dispatch) => {
  const token = typeof window !== "undefined" ? localStorage.getItem("token") : ""
  return await dispatch(getUsers({
    _id: token,
    mode: 3,
    wall: true
  }))
};
const mapStateToProps = (state) => {
  console.log("stateoRgpostcreationpostsdfd", state);
  const { editUser } = state.auth;
  return { editUser }
};
const mapDispatchToProps = (dispatch) => {
  return {}
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
