import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { config } from "../../../constant"
import moment from 'moment'
import ManagementLeftNav from '../../components/Navbars/ManagementLeftNav'
// import { useNavigate } from 'react-router-dom'
// api urls
const totalMembar = config.url.API_URL + "admin/membercount"
const monthwise = config.url.API_URL + "admin/membercount?mm=1&"
// const monthmostfeature = config.url.API_URL + "admin/mostlikeComment"

function AdminDashboard() {

    const [membar, setmembar] = useState("")
    const [posts, setposts] = useState("")
    // const [Favorites, setFavorites] = useState("")
    // const [AllFavorites, setAllFavorites] = useState([])
    const [monthlyMember, setmonthlyMember] = useState("")
    const [monthlypost, setmonthlypost] = useState("")
    const [monthY, setMonthY] = useState(moment().format('YYYY-MM'))

    const getmebarcount = async () => {
        const response = await axios.get(totalMembar)
        console.log(response)
        setmembar(response.data.responseData)
        setposts(response.data.Totalposts)
        // setmonthlyMember(response.data.monthWise)
        // setmonthlypost(response.data.monthwisepost)
    }
    const getmonthDetails = async () => {
        const response = await axios.get(monthwise + `gm=${monthY}`)
        setmonthlyMember(response.data.monthWise)
        setmonthlypost(response.data.monthwisepost)
    }

    // const getmostFeature = async () => {
    //     const response = await axios.get(monthmostfeature)
    //     setFavorites(response.data.response1)
    //     setAllFavorites(response.data.response2)
    // }
    const handleChange = async (e) => {
        setMonthY(e.target.value)
        getmonthDetails()
    }

    // const navigate = useNavigate()

    // useEffect(() => {
    //     const user = typeof window !== "undefined"
    //             ? JSON.parse(localStorage.getItem("currentUser"))
    //             : "";

    //     if(user?.userRole !== '1010') navigate('/admin/')
    // }, [])

    useEffect(() => {
        getmebarcount()
        getmonthDetails()
        // getmostFeature()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monthY])

    return (
        <>
            <div className='container-fluid'>
                <div className="row">
                    <div className="col-lg-2">
                        <ManagementLeftNav />
                    </div>
                    <div className="col-lg-10 bg_allp" style={{ minHeight: "100vh" }}>

                        <div className='container'><br /><br />
                            <div className='row'>
                                <div className='col-md-6 shadow p-3 mb-5 bg-white rounded'>
                                    <center> <p>Total Member Management</p></center><br /><hr />
                                    <div className='card-group'>
                                        <div className='col-md-6 shadow-sm p-3 mb-5 bg-white rounded '>
                                            <div className='card' style={{ backgroundColor: "#db7b76" }}>
                                                <center><p>Total Member</p>
                                                    {membar ? membar : 0}</center>
                                            </div>
                                        </div>
                                        <div className='col-md-6 shadow-sm p-3 mb-5 bg-white rounded'>
                                            <div className='card' style={{ backgroundColor: "#f2c73a" }}>
                                                <center> <p>Total Post</p>
                                                    {posts ? posts : 0}</center>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 shadow p-3 mb-5 bg-white rounded'>

                                    {/* </div> */}
                                    {/* <div className='container'><hr/> */}
                                    <center> <p>Month Wise Managment</p></center>
                                    <input type="month" name="month" className='form-control'
                                        min="2022-01" max={moment().format('YYYY-MM')} value={monthY} onChange={handleChange} /><hr />
                                    <div className='card-group'>
                                        <div className='col-md-6 shadow-sm p-3 mb-5 bg-white rounded'>
                                            <div className='card' style={{ backgroundColor: "#c1fa9d" }}>
                                                <center><p>Month Wise Member</p>
                                                    {monthlyMember ? monthlyMember : 0}</center>
                                            </div>
                                        </div>

                                        <div className='col-md-6 shadow-sm p-3 mb-5 bg-white rounded'>
                                            <div className='card' style={{ backgroundColor: "#649183" }}>
                                                <center><p>Month Wise Post</p>
                                                    {monthlypost ? monthlypost : 0}</center>
                                            </div>
                                        </div>




                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminDashboard