import React, { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import MembarNav from "../../components/Members/MembarNav"
import { Footer, IndexNavbar } from "../../components"
import { connect, useDispatch } from "react-redux"
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer"
import { LDS } from "../../components/Loaders/SimpleCircle"
import MembersCard from "../Members/MembersCard"

function FriendsList(props) {
	const { myFriends } = props
	const [page, setPage] = useState(1)
	const dispatch = useDispatch()
	const [initialLoad, setInitialLoad] = useState(true)
	const [loading, setLoading] = useState(false)
	const [endApiHit, setEndApiHit] = useState(false)

	const getFriendsList = async () => {
		setLoading(true)
		let req = {
			params: {
				page: page,
				friends: true
			},
			url: 'members',
			action: 'ACTION_GET_MYFRIENDS'
		}
		const response = await dispatch(getRequestHandler(req))
		setLoading(false)
		if (response.data.responseData.length === 0) setEndApiHit(true)
	}

	const handleScroll = (e) => {
		let scrollTop = window.pageYOffset
		let clientHeight = window.document.documentElement.clientHeight
		let bodyHeight = window.document.body.clientHeight
		if (clientHeight + scrollTop > bodyHeight - 50 && !endApiHit) {
			window.removeEventListener("scroll", handleScroll)
			setPage(prev => prev + 1)
		}
	}

	useEffect(() => {
		if (myFriends?.length > 0) {
			setInitialLoad(false)
			window.addEventListener("scroll", handleScroll)
		}
		return () => window.removeEventListener("scroll", handleScroll)
	}, [myFriends])
	useEffect(() => {
		if (initialLoad) setTimeout(() => setInitialLoad(false), [2500])
	}, [initialLoad])


	useEffect(() => {
		getFriendsList()
		if (!localStorage.getItem("token")) {
			return <Navigate to="/auth/login/" />
		}
	}, [])

	useEffect(() => {
		if (page > 1) getFriendsList()
	}, [page])

	return (
		<>
			<IndexNavbar />
			<div className="container-fluid bg_allp" style={{ minHeight: "100vh" }}>
				<div className="container">
					<div className="container background_opacity">
						<div className="row">
							<div className="d-flex justify-content-between align-items-center flex_wrap mb-4 mt-3">
								<MembarNav />

							</div>
							{initialLoad ?
								<div className="load_body">
									<LDS />
								</div> :
								<div className="row">
									{myFriends?.length > 0 &&
										<MembersCard
											members={myFriends}
										/>
									}
									{loading &&
										<div className="d-flex justify-content-center">
											<LDS />
										</div>
									}
								</div>
							}
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}
const mapStateToProps = (state) => {
	console.log("stateoRgpostcreationposts", state)
	const { myFriends } = state.usersData
	return { myFriends }
}
const mapDispatchToProps = (dispatch) => {
	return {}
}
export default connect(mapStateToProps, mapDispatchToProps)(FriendsList)

