import api from "../../config/api";
import Network from "../../helpers/network";
import {
  ACTION_ADD_UPLOADFILE_PROGRESS_SIZE,
  ACTION_ADD_UPLOADFILE_SIZE,
} from "./postReducer";
export const postRequestHandler = (req) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      console.log("Dfdfd", req)
      Network.post(api[req?.url], req?.data)
        .then((res) => {
          if (req?.action) {
            dispatch({
              type: req?.action,
              payload: res?.data?.responseData,
            });
          }
          resolve(res);
        })
        .catch((error) => reject(error));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const uploadRequestHandler = (req) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      if (req.fileSize)
        dispatch({
          type: ACTION_ADD_UPLOADFILE_SIZE,
          payload: req.fileSize,
        });
      const config = {
        onUploadProgress: (progressEvent) =>
          dispatch({
            type: ACTION_ADD_UPLOADFILE_PROGRESS_SIZE,
            payload: progressEvent.loaded,
          }),
        // console.log("progressEvent",progressEvent.loaded)
      };
      Network.uploadx(api[req?.url], req?.data, config)
        .then((res) => {
          if (req?.action) {
            dispatch({
              type: req?.action,
              payload: res?.data?.responseData,
            });
          }
          resolve(res);
        })
        .catch((error) => reject(error));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const postReqHandlerWithOutDispatch = (options) => {
  return new Promise((resolve, reject) => {
    try {
      Network.post(api[options?.url], options?.data)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    } catch (err) {
      console.log("error", err);
      return reject(err);
    }
  });
};

export const getRequestHandler = (req) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      Network.get(api[req?.url], req?.params)
        .then((res) => {
          if (req?.action) {
            dispatch({
              type: req?.action,
              state: req?.state,
              payload: res?.data?.responseData,
              page: req?.params?.page,
            });
          }
          resolve(res);
        })
        .catch((error) => reject(error));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
export const putRequestHandler = (req) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      Network.put(api[req?.url], req?.data)
        .then((res) => {
          if (req?.action) {
            dispatch({
              type: req?.action,
              payload: res?.data?.responseData,
            });
          }
          resolve(res);
        })
        .catch((error) => reject(error));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
export const putRequestHandler2 = (req) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      Network.put(`${api[req?.url]}?${req?.qs}`, req?.data)
        .then((res) => {
          if (req?.action) {
            dispatch({
              type: req?.action,
              payload: res?.data?.responseData,
            });
          }
          resolve(res);
        })
        .catch((error) => reject(error));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const patchRequestHandler = (req) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      Network.patch(api[req?.url], req?.data)
        .then((res) => {
          if (req?.action) {
            dispatch({
              type: req?.action,
              payload: res?.data?.responseData,
            });
          }
          resolve(res);
        })
        .catch((error) => reject(error));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
export const patchRequestHandler2 = (req) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      Network.patch(`${api[req?.url]}?${req?.qs}`, req?.data)
        .then((res) => {
          if (req?.action) {
            dispatch({
              type: req?.action,
              payload: res?.data?.responseData,
            });
          }
          resolve(res);
        })
        .catch((error) => reject(error));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const deleteRequestHandler = (req) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      Network.delete(api[req?.url], req?.params)
        .then((res) => {
          if (req?.action) {
            dispatch({
              type: req?.action,
              payload: res?.data?.responseData,
            });
          }
          resolve(res);
        })
        .catch((error) => reject(error));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const getRequestHandler2 = (req) => (dispatch) => {
  return new Promise((resolve, reject) => {
    try {
      Network.get(`${api[req?.url]}?${req?.params}`)
        .then((res) => {
          if (req?.action) {
            dispatch({
              type: req?.action,
              state: req?.state,
              payload: res?.data?.responseData,
              page: req?.page,
            });
          }
          resolve(res);
        })
        .catch((error) => reject(error));
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
