import React, { useState } from "react";
import ImageCropper from "../ImageCropper";
import { SubmitButton } from "../AppComponents/Helpers";
import { useDispatch } from "react-redux";
import { postRequestHandler } from "../../redux/reducers/requestHandler.reducer";

function UsersImage(props) {
  const [fileInputState, setFileInputState] = useState("");
  const [previewSource, setPreviewSource] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  // const [isError, setIsError] = useState(false)
  // const [errMsg, setErrMsg] = useState("")
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch();
  const token =
    typeof window !== "undefined" ? localStorage.getItem("token") : "";
  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
    setSelectedFile(file);
    setFileInputState(e.target.value);
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPreviewSource(reader.result);
    };
  };

  const handleSubmitFile = (e) => {
    e.preventDefault();
    console.log("selec =>", selectedFile);
    if (!selectedFile) return;
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onloadend = () => {
      // console.log("url>>", uploadPhotoUrl + props.pathinfo + currentUser.id)
      uploadImage(selectedFile);
      setSelectedFile("");
    };
    reader.onerror = () => {
      console.error("AHHHHHHHH!!");
      // setIsError(true)
      // setErrMsg("something went wrong!")
    };
  };

  const uploadImage = async (image) => {
    try {
      //   return;
      setIsSubmit(true);
      const formData = new FormData();
      if (props?.orgid) {
        formData.append("id", props?.orgid);
        if (props?.uploadapi === "logo")
          formData.append("organiztionLogo", image);
        if (props?.uploadapi === "background")
          formData.append("BackgroundImage", image);
      } else {
        props?.pathinfo === 1
          ? formData.append("profilePhoto", image)
          : formData.append("coverPhoto", image);
      }

      if (props?.orgid) {
        let req = {
          data: formData,
          url: props?.uploadapi === "logo" ? "orgLogo" : "orgBackground",
        };
        await dispatch(postRequestHandler(req));
      } else {
        formData.append("id", token);
        let req = {
          action: "ACTION_UPDATE_AUTH_USER",
          data: formData,
          url: props.pathinfo === 1 ? "profilePhoto" : "coverPhoto",
        };
        const response = await dispatch(postRequestHandler(req));
        if (response?.data?.ack === 1) {
          let data = JSON.parse(localStorage.getItem("currentuser"));
          let edited = {
            ...data,
            user: response.data.responseData,
          };
          localStorage.setItem("currentuser", JSON.stringify(edited));
        }
      }

      setIsSubmit(false);
      setFileInputState("");
      setPreviewSource("");
      props.onHide();
      window.location.reload();
    } catch (err) {
      console.error(err);
      // setIsError(true)
      // setErrMsg("Failed!. Something went wrong!")
    }
  };

  const closeModal = () => {
    setFileInputState("");
    setPreviewSource("");
    props.onHide();
  };

  return (
    <form
      onSubmit={handleSubmitFile}
      encType="multipart/formdata"
      className="form_modal br_5"
    >
      <div className="form_image">
        <div className="form_preview_img">
          {previewSource && props.pathinfo === 1 && (
            <ImageCropper
              src={previewSource}
              selectedFile={setSelectedFile}
              cropData={{
                unit: "px", // Can be 'px' or '%'
                x: 25,
                y: 25,
                width: 300,
                height: 300,
              }}
            ></ImageCropper>
          )}
          {previewSource && props.pathinfo !== 1 && (
            <ImageCropper
              src={previewSource}
              selectedFile={setSelectedFile}
              cropData={{
                unit: "px", // Can be 'px' or '%'
                x: 0,
                y: 0,
                width: 600,
                height: 100,
                // aspect: 16 / 9,
              }}
            ></ImageCropper>
          )}
        </div>
        {/* <img src={previewSource} alt="modal" /> */}

        <div
          className={
            previewSource ? "modal_image_input active" : "modal_image_input"
          }
        >
          {props.pathinfo === 1 ? (
            <div className="text-center max_width">
              We recommend a 256px by 256px image.
            </div>
          ) : (
            <div className="text-center max_width">
              We recommend an image at least 1600px wide and 400px tall.
            </div>
          )}
          <div className="items_center">
            <label className="mt-3">
              {" "}
              Browse
              <input
                type="file"
                name="postImage"
                onChange={handleFileInputChange}
                value={fileInputState}
                // accept=""
                accept="image/*"
                required
              />
            </label>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end gap-3">
        {/* <button type="submit" className="mt-3 btn btn-primary">
						Upload
					</button> */}
        <SubmitButton
          css={"mt-3 btn btn-primary"}
          title={"upload"}
          issubmit={isSubmit}
        />
        <button
          type="button"
          className="mt-3 btn btn-danger"
          onClick={closeModal}
        >
          Cancel
        </button>
      </div>
    </form>
  );
}

export default UsersImage;
