import api from "../../config/api"
import Network from "../../helpers/network"
export const ACTION_GET_FAQ_REQUEST = "ACTION_GET_FAQ_REQUEST"
export const ACTION_GET_FAQ_SUCCESS = "ACTION_GET_FAQ_SUCCESS"
export const ACTION_GET_FAQ_FAILD = "ACTION_GET_FAQ_FAILD"
const initialState = {
  data: null,
  isError: false,
  isProcessing: null,
  isSuccess: null,
  message: null,
}

export const FaqReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_GET_FAQ_REQUEST:
      return {
        ...state,
        isError: false,
        isProcessing: true,
        //    message :
      }
    case ACTION_GET_FAQ_SUCCESS:
      return {
        ...state,
        isError: false,
        isProcessing: false,
        data: action.payload,
        isSuccess: true,
        //    message :
      }
    case ACTION_GET_FAQ_FAILD:
      return {
        ...state,
        isError: true,
        isProcessing: false,
        data: null,
        isSuccess: false,
        //    message :
      }
    default:
      return state
  }
}

export const getFAQ =
  (page = 0) =>
    (dispatch) => {
      return new Promise((resolve, reject) => {
        try {
          dispatch({ type: ACTION_GET_FAQ_REQUEST })
          Network.get(api.getFAQ)
            .then((res) => {
              dispatch({
                type: ACTION_GET_FAQ_SUCCESS,
                payload: res?.data?.responseData,
              })
              resolve({
                status: true,
                message: res?.data?.message,
              })
            })
            .catch((err) => {
              console.log("error", err)
              dispatch({ type: ACTION_GET_FAQ_FAILD })
              reject(err)
            })
        } catch (err) {
          console.log("error", err)
          dispatch({ type: ACTION_GET_FAQ_FAILD })
          reject(err)
        }
      })
    }
