// import Wallet from "./new/components/Settings/Wallet"
// import BaseComponent from "bootstrap/js/dist/base-component";
import {
  About,
  AccountVerification,
  AccountVerificationCodeChoice,
  Blogs,
  HelpCenter,
  Home,
  Login,
  MyCanvas,
  MySpace,
  News,
  Privacy,
  Registration,
  Terms,
  AddNewPassword,
  Creation,
  Contact,
  Members,
  FindAccount,
  FriendsList,
  RequestList,
  SendingList,
  BasicSettings,
  AccountsSettings,
  CompetitionAnalyctics,
  ReferralSettings,
  SinglePostDetails,
  AdvancePostSearch,
  DisplayContents,
  DisplayNewsContents,
  Competition,
  CreateOrganisation,
  OtpVerification,
  AdminLogin,
  AdminDashboard,
  AdminDomainManagement,
  AdminBlogManagement,
  AdminNewsManagement,
  AdminPostManagement,
  AdminSeoManagement,
  AdminFaqManagement,
  AdminReferralManagement,
  CompetitionWinners,
  OrgHtml,
  OrgListing,
  BooksList,
  AdminHomePageManagement,
  Subcriptions,
  AdminSubscriptionManagement,
  SubscriptionsDetails,
  Piechart,
  UsersStorageSettings,
  StorageSubscriptions,
  SinglePrivatePostDetails,
  SinglePrivatePostAnalytics,
  StreamVideos,
  // TopCreations,
  AllCreations,
  EditProfile,
  ReactivateAccount,
  Presents,
  PremiumPageDetails,
  CreatePremiumPage,
  AllPremiumPage,
  AllPremiumPost,
  MyPremiumPost,
  PPVSinglePageAnalytics,
  UnderConstruction,
  NewHome,
  TopCreations,
  // AdminWalletManagement,
} from "./new/containers";
const routeConfig = () => {
  return [
    { path: "/auth/login", component: Login },
    { path: "/auth/registration", component: Registration },
    { path: "/auth/verification", component: OtpVerification },
    {
      path: "/",
      component: Home,
      loadData: Home.loadData,
    },
    {
      path: "/newHome",
      component: NewHome,
      // loadData: Home.loadData,
    },
    { path: "/about-us", component: About },
    { path: "/terms", component: Terms },
    { path: "/privacy", component: Privacy },
    {
      path: "/blogs",
      component: Blogs,
      loadData: Blogs.loadData,
    },
    {
      path: "/blogs/:slug",
      component: DisplayContents,
      loadData: DisplayContents.loadData,
    },
    {
      path: "/help-center",
      component: HelpCenter,
      loadData: HelpCenter.loadData,
    },
    {
      path: "/news",
      component: News,
      loadData: News.loadData,
    },
    {
      path: "/news/:slug",
      component: DisplayNewsContents,
      loadData: DisplayNewsContents.loadData,
    },
    { path: "/contact-us", component: Contact },
    {
      path: "/members",
      component: Members,
      loadData: Members.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/friends-list",
      component: FriendsList,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/request-list",
      component: RequestList,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/sent-list",
      component: SendingList,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/mycanvas",
      component: MyCanvas,
      loadData: MyCanvas.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/creations",
      component: Creation,
      loadData: Creation.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/top-creations",
      component: TopCreations,
      loadData: TopCreations.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/p/:id",
      component: SinglePostDetails,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/search/post",
      component: AdvancePostSearch,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/accounts/password/reset",
      component: FindAccount,
    },
    {
      path: "/accounts/reactivate",
      component: ReactivateAccount,
    },
    {
      path: "/accounts/verify/initiate",
      component: AccountVerificationCodeChoice,
    },
    {
      path: "/accounts/verify/code",
      component: AccountVerification,
    },
    {
      path: "/accounts/password/new",
      component: AddNewPassword,
    },
    {
      path: "/settings/basic",
      component: BasicSettings,
      loadData: BasicSettings.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/settings/account",
      component: AccountsSettings,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/settings/analytics",
      component: CompetitionAnalyctics,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/settings/storage",
      component: UsersStorageSettings,
      loadData: UsersStorageSettings.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/settings/referral",
      component: ReferralSettings,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/settings/edit-profile",
      component: EditProfile,
      loadData: EditProfile.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    // {
    //   path: "/settings/wallet",
    //   component: Wallet,
    //   loadData: Wallet.loadData
    // },
    // settings/account/
    {
      path: "/competition",
      component: Competition,
    },
    {
      path: "/events/result",
      component: CompetitionWinners,
    },
    {
      path: "/admin",
      component: AdminLogin,
    },
    {
      path: "/admin/dashboard",
      component: AdminDashboard,
    },
    {
      path: "/admin/management/domains",
      component: AdminDomainManagement,
    },
    {
      path: "/admin/management/blogs",
      component: AdminBlogManagement,
    },
    {
      path: "/admin/management/news",
      component: AdminNewsManagement,
    },
    {
      path: "/admin/management/posts",
      component: AdminPostManagement,
    },
    {
      path: "/admin/management/faq",
      component: AdminFaqManagement,
    },
    {
      path: "/admin/management/seo",
      component: AdminSeoManagement,
    },
    {
      path: "/admin/management/referral",
      component: AdminReferralManagement,
    },
    {
      path: "/admin/management/homepage",
      component: AdminHomePageManagement,
      loadData: AdminHomePageManagement.loadData,
    },
    {
      path: "/admin/management/subscriptions",
      component: AdminSubscriptionManagement,
      loadData: AdminSubscriptionManagement.loadData,
    },
    // {
    //   path: "/admin/management/wallet",
    //   component: AdminWalletManagement,
    //   loadData: AdminWalletManagement.loadData
    // },
    {
      path: "/org/create",
      component: CreateOrganisation,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/org/:name",
      component: OrgHtml,
      loadData: OrgHtml.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/org/lists",
      component: OrgListing,
      loadData: OrgListing.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/premium/create",
      component: CreatePremiumPage,
      // loadData: PremiumCreators.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/premium-list",
      component: AllPremiumPage,
      loadData: AllPremiumPage.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/premium-list/mypages",
      component: AllPremiumPage,
      loadData: AllPremiumPage.loadData,
      auth: true,
      authPage: "/auth/login/",
    },

    {
      path: "/premium-list/posts",
      component: AllPremiumPost,
      loadData: AllPremiumPost.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/premium-list/mypremiumpost",
      component: MyPremiumPost,
      loadData: MyPremiumPost.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/premium/:name",
      component: PremiumPageDetails,
      loadData: PremiumPageDetails.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/premium/ppv/:id",
      component: PPVSinglePageAnalytics,
      loadData: PPVSinglePageAnalytics.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/:name",
      component: MySpace,
      loadData: MySpace.loadData,
    },
    {
      path: "/:name/:cname",
      component: MySpace,
      loadData: MySpace.loadData,
    },
    {
      path: "/subcriptions",
      component: Subcriptions,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/post/:id",
      component: SinglePrivatePostDetails,
      loadData: SinglePrivatePostDetails.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/post/analytics/:id",
      component: SinglePrivatePostAnalytics,
      loadData: SinglePrivatePostAnalytics.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/watch/:id",
      component: StreamVideos,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/subcriptions/storage/:name/:id",
      component: StorageSubscriptions,
      loadData: StorageSubscriptions.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/books",
      component: BooksList,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/piechart",
      component: Piechart,
    },
    {
      path: "/subsdetails",
      component: SubscriptionsDetails,
      auth: true,
      authPage: "/auth/login/",
    },
    // {
    //   path: "/topcreations",
    //   component: TopCreations
    // },
    {
      path: "/allcreations",
      component: AllCreations,
      loadData: AllCreations.loadData,
      auth: true,
      authPage: "/auth/login/",
    },
    {
      path: "/presents",
      component: Presents,
    },
    {
      path: "/presents",
      component: Presents,
    },
    {
      path: "/underconstruction",
      component: UnderConstruction,
    },
  ];
};

export default routeConfig;
