const data = process.env;
console.log("config data>>", data);
const config = {
  // API_URL: process.env.NODE_ENV == "production" ? "" : data.REACT_APP_API_URL,
  // API_URL: data.REACT_APP_API_URL,
  // API_URL: "http://localhost:8000/api/",
  // API_URL: "https://test-api.allphanes.com/api/",
  API_URL: "https://api.allphanes.com/api/",
  per_page: 10,
  max_file_upload: 6,
  max_video_upload_size: 200 * 1024 * 1024,
  env: process.env.NODE_ENV === "production" ? "prod" : "local",
  APP_URL: "https://allphanes.com",
  // APP_URL: "https://test.allphanes.com",
  // APP_URL: "http://localhost:3000",
};

export default config;
