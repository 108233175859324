import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { CreateNewAccountLink } from '../../components/AppComponents/AppButtons';
import axios from 'axios'
import { config } from '../../constant'
import CustomizedSnackbars from '../../components/Loaders/Snuckbars';

const getUserUrl = config.url.API_URL + "users/"

export const getUser = async (query) => {
    try {
        const response = await axios.get(getUserUrl + query)
        console.log(response)
        return response?.data?.responseData[0]
    } catch (err) {
        console.error(err)
        return err
    }
}

export const AccountsLogo = () => {
    return (
        <a href='/'>
            <div className="head_icon"></div>
        </a>
    )
}



export const ORseparator = () => {
    return (<div className="separator mt-3">
        <div className="line"></div>
        <p>OR</p>
        <div className="line"></div>
    </div>)
}

export const FindAccount = () => {
    const [values, setValues] = useState('')
    const [isSubmit, setIsSubmit] = useState(false)
    const [isError, setIsError] = useState(false)
    const [msg, setMsg] = useState('')
    const navigate = useNavigate()

    const handleFindUserSubmit = async e => {
        try {
            e.preventDefault()
            setIsSubmit(true)
            setMsg('')
            const response = await axios.get(getUserUrl + `getusers?mode=3&match=${values}`)
            console.log("otp response>", response)
            setIsSubmit(false)
            if (response.data.ack === 0) {
                setIsError(true)
                return setMsg('User not found')
            }
            localStorage.clear()
            localStorage.setItem('resetuser', response?.data?.responseData[0]?.id)
            navigate('/accounts/verify/initiate/')

        } catch (err) {
            console.error("catchblock>>", err)
        }

    }

    return (
        <div className='Login'>
            <div className='container-xl'>
                <div class="row align-items-center justify-content-center hxz-100">
                    <div class="col-lg-5 order-lg-3 order-md-3 order-1">
                        <div className="login_col_main">
                            <div className="box forget_password">
                                <a class="main_logo m-hide" href="/"><img src="/assets/web_img/allphance_logoblack.png" alt="allphenes logo" loading="lazy" /></a>
                                {/*<AccountsLogo />*/}
                                <h4 className='mt-2'>Trouble logging in?</h4>
                                <p className='sub_head'>Enter your email, phone, or username to find <br />your account.</p>
                                <form className="login-form" onSubmit={handleFindUserSubmit}>
                                    <div className="field">
                                        <input
                                            id="username"
                                            type="text" className="form-control"
                                            placeholder="Phone number, username, or email"
                                            onChange={e => setValues(e.target.value)}
                                            value={values || ''}
                                            required
                                        />
                                        {/*<label htmlFor="username">Phone number, username, or email</label>*/}
                                    </div>
                                    <br />
                                    <button class="btn cmn-new_btn" issubmit={isSubmit} >Find account <img src="/assets/web_img/arow_new.png" alt="sound" /></button>
                                    <br />
                                    <br />
                                    {/*<SubmitButton title={"Find account"} issubmit={isSubmit} css={"login-button"} />
                                 <button className="login-button" title="Find account" type='submit'>Find account</button> */}
                                    <ORseparator />
                                    <div className="other">
                                        <CreateNewAccountLink />
                                    </div>
                                </form>
                            </div>
                            <div className="box">
                                <Link to="/auth/login" className="fb-login-btn" type="button">
                                    Back to login
                                </Link>
                            </div>

                            {isError && <CustomizedSnackbars type={1} msg={msg} sclose={() => setIsError(false)} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function AccountsLayout() {
    return (
        <FindAccount />
    )
}

