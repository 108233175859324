import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Followers from '../MySpace/Followers'
import Friends from '../MySpace/Friends'

const MembersCard = (props) => {
    const { members } = props
    const [followerOpen, setFollowerOpen] = useState(false)
    const [uid, setUid] = useState("")
    const [friendsOpen, setFriendsOpen] = useState(false)


    const handleFollowClick = (id) => {
        setUid(id)
        setFollowerOpen(true)
    }

    const handleFriendClick = (id) => {
        setUid(id)
        setFriendsOpen(true)
    }
    return (
        <>
            {members?.length > 0 && members?.map((item, index) =>
                <div class="col-lg-3" key={item?._id + index}>
                    <div class="bg-white my_space_col p-3 pb-2 mb-4">
                        <div class="my_space_img-col">
                            <div class="my_space_img_hover p-1 rounded-4">
                                <div
                                    onClick={() => handleFriendClick(item?._id)}
                                    class="mt-1 mb-1 member_hover text-dark member_col">
                                    Friends
                                    <span>{item?.totalFriend || 0}</span>
                                </div>
                            </div>
                            <div class="my_space_img_hover my_space_img_hover3 p-1 rounded-4">
                                <div
                                    onClick={() => handleFollowClick(item?._id)}
                                    class="mt-1 mb-1 member_hover text-dark member_col">
                                    Followers
                                    <span>{item?.totalFollowers || 0}</span>
                                </div>
                            </div>
                            
                            <Link
                                to={`/${item?.userName}/`}
                                class="my-space_img_box member_col"
                                style={{
                                    backgroundImage: `url(${item?.profilePhoto || "/assets/web_img/choto-log-img2.jpg"})`
                                }}>

                            </Link>

                        </div>
                        <div className="text-center">
                            <h5 className="mb-0 mt-1">{item?.showName || item?.userName}</h5>
                            <small>{item?.tagline || "Unleash your potential"}</small>
                        </div>
                    </div>
                </div>
            )}
            {followerOpen && <Followers
                open={followerOpen}
                setOpen={setFollowerOpen}
                uid={uid}
            />}
            {friendsOpen && <Friends
                open={friendsOpen}
                setOpen={setFriendsOpen}
                uid={uid}
            />}
        </>

    )
}

export default MembersCard