import React from 'react'
import { Footer, IndexNavbar } from '../../components'
import Accordion from 'react-bootstrap/Accordion';

function CompetitionWinners() {
  return (
    <>
      <IndexNavbar />


      <div className="container_for_competition_result container">
        <Accordion defaultActiveKey="0" flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>November 2022 Competition Results</Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <section>
                  <div class="win-popup_in competition_result_in">
                    <div class="win_popup_heading">
                      <div class="win_close_btn" title="close">
                        <img src="/assets/web_img/close_btn.png" alt="sound" />
                      </div>

                      <ul class="win_list_blog">
                        <li>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href='javascript:void(0)'>
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 16q1.875 0 3.188-1.312Q16.5 13.375 16.5 11.5q0-1.875-1.312-3.188Q13.875 7 12 7q-1.875 0-3.188 1.312Q7.5 9.625 7.5 11.5q0 1.875 1.312 3.188Q10.125 16 12 16Zm0-1.8q-1.125 0-1.912-.788Q9.3 12.625 9.3 11.5t.788-1.913Q10.875 8.8 12 8.8t1.913.787q.787.788.787 1.913t-.787 1.912q-.788.788-1.913.788Zm0 4.8q-3.65 0-6.65-2.038-3-2.037-4.35-5.462 1.35-3.425 4.35-5.463Q8.35 4 12 4q3.65 0 6.65 2.037 3 2.038 4.35 5.463-1.35 3.425-4.35 5.462Q15.65 19 12 19Zm0-7.5Zm0 5.5q2.825 0 5.188-1.488Q19.55 14.025 20.8 11.5q-1.25-2.525-3.612-4.013Q14.825 6 12 6 9.175 6 6.812 7.487 4.45 8.975 3.2 11.5q1.25 2.525 3.612 4.012Q9.175 17 12 17Z" /></svg>
                              View Post
                            </span>
                          </a>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href="#">
                            <img class="img-responsive" src="/assets/web_img/top_two.png" alt="" />
                            <h2>Dhriti Chattopadhyay
                            </h2>

                            <div class="wnr_profile_img" style={{ backgroundImage: `url(/assets/web_img/dhriti.jpg)` }}></div>

                          </a>
                        </li>
                        <li>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href='javascript:void(0)'>
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 16q1.875 0 3.188-1.312Q16.5 13.375 16.5 11.5q0-1.875-1.312-3.188Q13.875 7 12 7q-1.875 0-3.188 1.312Q7.5 9.625 7.5 11.5q0 1.875 1.312 3.188Q10.125 16 12 16Zm0-1.8q-1.125 0-1.912-.788Q9.3 12.625 9.3 11.5t.788-1.913Q10.875 8.8 12 8.8t1.913.787q.787.788.787 1.913t-.787 1.912q-.788.788-1.913.788Zm0 4.8q-3.65 0-6.65-2.038-3-2.037-4.35-5.462 1.35-3.425 4.35-5.463Q8.35 4 12 4q3.65 0 6.65 2.037 3 2.038 4.35 5.463-1.35 3.425-4.35 5.462Q15.65 19 12 19Zm0-7.5Zm0 5.5q2.825 0 5.188-1.488Q19.55 14.025 20.8 11.5q-1.25-2.525-3.612-4.013Q14.825 6 12 6 9.175 6 6.812 7.487 4.45 8.975 3.2 11.5q1.25 2.525 3.612 4.012Q9.175 17 12 17Z" /></svg>
                              View Post
                            </span>
                          </a>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
                          <a href="#">
                            <img class="img-responsive" src="/assets/web_img/top_winner_bg.png" alt="" />
                            <h2>Debarati  Chakraborty</h2>
                            <div class="wnr_profile_img" style={{ backgroundImage: `url(/assets/web_img/debarati.jpg)` }}></div>
                          </a>
                        </li>
                        <li>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href='javascript:void(0)'>
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 16q1.875 0 3.188-1.312Q16.5 13.375 16.5 11.5q0-1.875-1.312-3.188Q13.875 7 12 7q-1.875 0-3.188 1.312Q7.5 9.625 7.5 11.5q0 1.875 1.312 3.188Q10.125 16 12 16Zm0-1.8q-1.125 0-1.912-.788Q9.3 12.625 9.3 11.5t.788-1.913Q10.875 8.8 12 8.8t1.913.787q.787.788.787 1.913t-.787 1.912q-.788.788-1.913.788Zm0 4.8q-3.65 0-6.65-2.038-3-2.037-4.35-5.462 1.35-3.425 4.35-5.463Q8.35 4 12 4q3.65 0 6.65 2.037 3 2.038 4.35 5.463-1.35 3.425-4.35 5.462Q15.65 19 12 19Zm0-7.5Zm0 5.5q2.825 0 5.188-1.488Q19.55 14.025 20.8 11.5q-1.25-2.525-3.612-4.013Q14.825 6 12 6 9.175 6 6.812 7.487 4.45 8.975 3.2 11.5q1.25 2.525 3.612 4.012Q9.175 17 12 17Z" /></svg>
                              View Post
                            </span>
                          </a>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href="#">
                            <img class="img-responsive" src="/assets/web_img/top_two.png" alt="" />
                            <h2>Apurwa Singh</h2>
                            <div class="wnr_profile_img" style={{ backgroundImage: `url(/assets/web_img/apurwa.jpg)` }}></div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>October 2022 Competition Results</Accordion.Header>
            <Accordion.Body>
              <div className="row">
                <section>
                  <div class="win-popup_in competition_result_in">
                    <div class="win_popup_heading">
                      <div class="win_close_btn" title="close">
                        <img src="/assets/web_img/close_btn.png" alt="sound" />
                      </div>

                      <ul class="win_list_blog">
                        <li>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href='javascript:void(0)'>
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 16q1.875 0 3.188-1.312Q16.5 13.375 16.5 11.5q0-1.875-1.312-3.188Q13.875 7 12 7q-1.875 0-3.188 1.312Q7.5 9.625 7.5 11.5q0 1.875 1.312 3.188Q10.125 16 12 16Zm0-1.8q-1.125 0-1.912-.788Q9.3 12.625 9.3 11.5t.788-1.913Q10.875 8.8 12 8.8t1.913.787q.787.788.787 1.913t-.787 1.912q-.788.788-1.913.788Zm0 4.8q-3.65 0-6.65-2.038-3-2.037-4.35-5.462 1.35-3.425 4.35-5.463Q8.35 4 12 4q3.65 0 6.65 2.037 3 2.038 4.35 5.463-1.35 3.425-4.35 5.462Q15.65 19 12 19Zm0-7.5Zm0 5.5q2.825 0 5.188-1.488Q19.55 14.025 20.8 11.5q-1.25-2.525-3.612-4.013Q14.825 6 12 6 9.175 6 6.812 7.487 4.45 8.975 3.2 11.5q1.25 2.525 3.612 4.012Q9.175 17 12 17Z" /></svg>
                              View Post
                            </span>
                          </a>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href="#">
                            <img class="img-responsive" src="/assets/web_img/top_two.png" alt="" />
                            <h2>Rita Sardar</h2>
                            <div class="wnr_profile_img" style={{ backgroundImage: `url(/assets/web_img/rita.jpg)` }}></div>
                          </a>
                        </li>
                        <li>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href='javascript:void(0)'>
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 16q1.875 0 3.188-1.312Q16.5 13.375 16.5 11.5q0-1.875-1.312-3.188Q13.875 7 12 7q-1.875 0-3.188 1.312Q7.5 9.625 7.5 11.5q0 1.875 1.312 3.188Q10.125 16 12 16Zm0-1.8q-1.125 0-1.912-.788Q9.3 12.625 9.3 11.5t.788-1.913Q10.875 8.8 12 8.8t1.913.787q.787.788.787 1.913t-.787 1.912q-.788.788-1.913.788Zm0 4.8q-3.65 0-6.65-2.038-3-2.037-4.35-5.462 1.35-3.425 4.35-5.463Q8.35 4 12 4q3.65 0 6.65 2.037 3 2.038 4.35 5.463-1.35 3.425-4.35 5.462Q15.65 19 12 19Zm0-7.5Zm0 5.5q2.825 0 5.188-1.488Q19.55 14.025 20.8 11.5q-1.25-2.525-3.612-4.013Q14.825 6 12 6 9.175 6 6.812 7.487 4.45 8.975 3.2 11.5q1.25 2.525 3.612 4.012Q9.175 17 12 17Z" /></svg>
                              View Post
                            </span>
                          </a>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href="#">
                            <img class="img-responsive" src="/assets/web_img/top_winner_bg.png" alt="" />
                            <h2>Tapashi Dhar </h2>
                            <div class="wnr_profile_img" style={{ backgroundImage: `url(/assets/web_img/taposhi.jpg)` }}></div>
                          </a>
                        </li>
                        <li>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href='javascript:void(0)'>
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M12 16q1.875 0 3.188-1.312Q16.5 13.375 16.5 11.5q0-1.875-1.312-3.188Q13.875 7 12 7q-1.875 0-3.188 1.312Q7.5 9.625 7.5 11.5q0 1.875 1.312 3.188Q10.125 16 12 16Zm0-1.8q-1.125 0-1.912-.788Q9.3 12.625 9.3 11.5t.788-1.913Q10.875 8.8 12 8.8t1.913.787q.787.788.787 1.913t-.787 1.912q-.788.788-1.913.788Zm0 4.8q-3.65 0-6.65-2.038-3-2.037-4.35-5.462 1.35-3.425 4.35-5.463Q8.35 4 12 4q3.65 0 6.65 2.037 3 2.038 4.35 5.463-1.35 3.425-4.35 5.462Q15.65 19 12 19Zm0-7.5Zm0 5.5q2.825 0 5.188-1.488Q19.55 14.025 20.8 11.5q-1.25-2.525-3.612-4.013Q14.825 6 12 6 9.175 6 6.812 7.487 4.45 8.975 3.2 11.5q1.25 2.525 3.612 4.012Q9.175 17 12 17Z" /></svg>
                              View Post
                            </span>
                          </a>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url*/}
                          <a href="#">
                            <img class="img-responsive" src="/assets/web_img/top_two.png" alt="" />
                            <h2>Suman Das</h2>
                            <div class="wnr_profile_img" style={{ backgroundImage: `url(/assets/web_img/suman.jpg)` }}></div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>

      <Footer />
    </>

  )
}

export default CompetitionWinners