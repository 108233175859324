import React from 'react'
import { Link } from 'react-router-dom'

export const CreateNewAccountLink = () => {
    return (
        <a href="/auth/registration/" className="fb-login-btn cl_b" type="button">
            <span className="">Create new account</span>
        </a>
    )
}

// export const DefaultSubmitButton = () =>{

//     return(
//         <></>
//     )
// }

function AppButtons() {
    return (
        <div>AppButtons</div>
    )
}

export default AppButtons