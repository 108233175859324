import GA4React from "ga-4-react";
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS; // OUR_TRACKING_ID

// ReactGA.initialize(TRACKING_ID);
const ga4react = new GA4React(TRACKING_ID);
const GAnalytics = () => {
	console.log(
		"Appp11>>>>>>>>>>>>>>>>>",
		window.location,
		window.location.pathname + window.location.search
	);
	if (TRACKING_ID) {
		setTimeout((_) => {
			ga4react
				.initialize()
				.then((ga4) => {
					ga4.pageview(window.location.pathname + window.location.search);
					// ga4.gtag("event", "pageview", "path"); // or your custom gtag event
				})
				.catch((err) => console.error(err));
		}, 4000);
	}
};
export default GAnalytics;
