import React from 'react'
import { Footer, IndexNavbar } from "../../components";
import AccountChangePassword from '../../components/Form/ChangePassword';
import ProfileSettingsNav from "../../components/Settings/ProfileSettingsNav";
// import ChangePassword from '../Form/ChangePassword'

function AccountsSettings() {
  return (
    <>
      <IndexNavbar />
      <div className="container my-4">

        <ProfileSettingsNav />
        <AccountChangePassword />
      </div>
      <Footer />
    </>
  )
}

export default AccountsSettings