import React, { useState } from "react";
import WinnersModal from "../Modals/WinnersModal";
import { Link } from "react-router-dom";

function Footer(props) {
  const [isShow, setIsShow] = useState(false);
  return (
    <>
      <section
        className="footer_bg"
        style={{ backgroundImage: `url(/assets/web_img/background_bg.jpg)` }}
      >
        <div className="footer-end">
          <div className="f-logo">
            <img
              className="img-responsive"
              src="/assets/web_img/footer_logo.png"
              alt="footer logo"
            />
          </div>
          <div className="f-end order-2">
            <a href="/help-center" className="f-links">
              Help Center & FAQ
            </a>
            <a href="/about-us" className="f-links">
              About Us
            </a>
            <a href="/terms" className="f-links">
              Terms&Condition
            </a>
            <a href="/privacy" className="f-links">
              Privacy
            </a>
            <a href="/blogs" className="f-links">
              Blogs
            </a>
            <Link to={`/news/`} className="f-links">
              News
            </Link>

            <a href="/contact-us" className="f-links">
              Contact us
            </a>
            {/* <a href="/competition" className="f-links">
              Monthly Talent Search Rules
            </a>

            <div className="f-links c_pointer" onClick={() => setIsShow(true)}>
              Competition Result
            </div>
            {isShow && (
              <WinnersModal show={isShow} close={() => setIsShow(false)} />
            )} */}

            <a
              href="https://play.google.com/store/apps/details?id=allphanes.com.allphanes"
              className="nav-link mt-3"
            >
              <img
                className="img-responsive"
                src={"/assets/web_img/google_play.png"}
                alt="https://play.google.com/store/apps/details?id=allphanes.com.allphanes"
              />
            </a>
          </div>
          <div className="order-3">
            © 2022{" "}
            <a
              target="_blank"
              className="f-links"
              href="https://allphanes.com/"
              rel="noreferrer"
            >
              Allphanes Inc
            </a>
            . All Rights Reserved.
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
