import React, { useState, useEffect } from "react";
import moment from "moment"
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getRequestHandler } from "../../redux/reducers/requestHandler.reducer";


function LikeModal(props) {
    const [likeUsers, setLikeUsers] = useState([])
    const dispatch = useDispatch()

    const getAllLikeUser = async () => {
        const req = {
            params: {
                pid: props.postid,
                limit: props?.limit
            },
            url: 'likeUser'
        }
        const response = await dispatch(getRequestHandler(req))
        setLikeUsers(response.data.responseData)
        console.log("getAllLikeUser>>", response)
    }

    useEffect(() => {
        getAllLikeUser()
    }, [props.show, props.limit])

    return (
        <div className="user_likes_details">
            <div style={{ position: "relative" }}>
                <div className="row">
                    <div className="col-lg-12">
                        {likeUsers?.map(item => {
                            return (
                                <div className="pro_img d-flex align-items-center mt-1" key={item?._id}>

                                    <img
                                        src={item?.referenceUserId?.profilePhoto ? item?.referenceUserId?.profilePhoto : 'assets/web_img/choto_logo_1.png'}
                                        alt="members profile"
                                    />

                                    <div className="d-flex justify-content-between w-100 px-2">
                                        <div>

                                            <Link
                                                to={`/${item?.referenceUserId?.userName}/`}
                                                className="like_link
                                             ">
                                                {item?.referenceUserId?.showName}
                                            </Link>
                                            <div className="fs_12">{moment(item?.createdAt).fromNow()}</div>
                                        </div>
                                        <div className="like_icon"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z" /></svg></div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>

    );
}

export default LikeModal

